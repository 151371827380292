import React, { useState, ChangeEvent, KeyboardEvent, useEffect } from 'react';
import { TextField, Chip, Paper, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Container, IconButton, Divider, Checkbox } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { MeetingMinute } from '../meetingMinutesSlice';
import { Localized, useLocalization } from '@fluent/react';

interface EmailInputProps {
  meetingMinute: MeetingMinute;
  onListOfRecipientsChange: (listOfRecipients: string[]) => void;
  onAttachNoteAttachmentsChanged: (attach: boolean)=> void;
}

const EmailInput: React.FC<EmailInputProps> = ({ meetingMinute , onListOfRecipientsChange, onAttachNoteAttachmentsChanged }) => {
  const { l10n } = useLocalization()

  const lastMeeting = meetingMinute.meetings.find(meeting => meeting.id === meetingMinute.lastMeetingId);
  const lastMeetingParticipants = lastMeeting ? lastMeeting.attendanceList.map(a => a.email).filter(email => email.trim() !== '') : [];
  const allParticipants = Array.from(new Set(meetingMinute.meetings.flatMap(meeting => meeting.attendanceList.map(a => a.email).filter(email => email.trim() !== ''))));
  const [emailInput, setEmailInput] = useState<string>('');
  const [radioValue, setRadioValue] = useState<string>('allParticipants');
  const [emails, setEmails] = useState<string[]>(allParticipants);
  const [emailError, setEmailError] = useState<string>('');


  useEffect(() => {
    onListOfRecipientsChange(emails);
  }, [emails, onListOfRecipientsChange]);

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRadioValue(value);

    if (value === 'meetingParticipants') {
      setEmails(lastMeetingParticipants);
    } else if (value === 'allParticipants') {
      setEmails(allParticipants);
    }
  };

  const handleAddEmail = () => {
    if (emailInput.trim() !== '') {
      if (!validateEmail(emailInput)) {
        setEmailError( l10n.getString("email-incorrect", null, "email-incorrect"),);
      } else if (emails.includes(emailInput)) {
        setEmailError( l10n.getString("email-already-on-list", null, "email-already-on-list"),);
      } else {
        setEmails([...emails, emailInput]);
        setEmailInput('');
        setEmailError('');
      }
    }
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleAddEmail();
    }
  };

  const handleDelete = (emailToDelete: string) => () => {
    setEmails(emails.filter(email => email !== emailToDelete));
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleAttachChange = (event) => {
    onAttachNoteAttachmentsChanged(event.target.checked);
};

  return (
    <Container>
      <FormControl component="fieldset">
        <RadioGroup value={radioValue} onChange={handleRadioChange}>
          <FormControlLabel 
            value="meetingParticipants" 
            control={<Radio />} 
            label={<Localized id="meeting-minutes-current-meeting-attendees">Uczestnicy bieżącego spotkania</Localized>} 
          />
          <FormControlLabel 
            value="allParticipants" 
            control={<Radio />} 
            label={<Localized id="meeting-minutes-all-attendees">Uczestnicy bieżącego spotkania</Localized>} 
          />
        </RadioGroup>
      </FormControl>

      <Paper
        component="div"
        style={{ padding: '10px', marginBottom: '10px', maxHeight: '500px',  overflowY: 'auto'}}
      >
        {emails.sort((a, b) => a.localeCompare(b)).map((email) => (
          <div key={email}>
            <Chip
              label={email}
              onDelete={handleDelete(email)}
              color="primary"
              style={{ marginLeft: '10px', marginTop:'1px', flex: 1 }}
            />
          </div>
        ))}
        </Paper>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom:'10px'}}>
          <TextField
            value={emailInput}
            size='small'
            onChange={(e) => setEmailInput(e.target.value)}
            onKeyDown={handleKeyDown}
            label={<Localized id="add-email">Dodaj email</Localized>} 
            variant="standard"
            error={!!emailError}
            helperText={emailError}
          />
          <IconButton onClick={handleAddEmail} color="primary" aria-label="add email">
            <AddIcon />
          </IconButton>
        </div>
        <FormControlLabel
              control={<Checkbox defaultChecked={false} onChange={handleAttachChange} />}
              label="dodaj do maila załączniki notatki"
            />
    </Container>
  );
};

export default EmailInput;
