import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../../app/appTypes";
import { isSameRequestId, load } from "../../../app/crud";
import { RootState } from "../../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";
import { logout } from "../../user/userSlice";

export interface MaterialCard {
    id: AppId, 
    contractor: string, 
    cardNumber: string,
    date: string, 
    address: string, 
    name: string, 
    description: string, 
    samples: string, 
    installationPlace: string, 
    guid: string,
    industrySector: string,
    status: string
 }
 
 const adapter = createEntityAdapter<MaterialCard>({
    selectId: (materialCard) => materialCard.id,
})

export type DraftMaterialCard = Omit<MaterialCard, "id"| "date" | "guid" | "status">

export type MaterialCardsState = EntityState<MaterialCard> & { loaded: boolean }

const initialState: MaterialCardsState = adapter.getInitialState({ loaded: false })


export const loadMaterialCards = createAsyncThunk<MaterialCard[], void, AppThunkAPIType>("materialCards/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/MaterialCard"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as MaterialCard[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const createMaterialCard = createAsyncThunk<MaterialCard, DraftMaterialCard, AppThunkAPIType>(
    "materialcard/create", 
    async (materialcard: DraftMaterialCard, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/MaterialCard",
            payload: materialcard,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MaterialCard
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const updateMaterialCard = createAsyncThunk<MaterialCard, MaterialCard, AppThunkAPIType>(
    "materialcard/update", 
    async (materialcard: MaterialCard, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/MaterialCard/${materialcard.id}`,
            payload: materialcard,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return materialcard
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteMaterialCard = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "materialcard/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/MaterialCard/${id}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const sendToApprove = createAsyncThunk<MaterialCard, AppId, AppThunkAPIType>(
    "materialcard/sendToApprove", 
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: `api/MaterialCard/sendToApprove/${id}`,
            payload: {},
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MaterialCard
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const materialCardSlice = createSlice({
    name: "materialcards",
    initialState,
    reducers: {
        clearMaterialCardSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        },
    },  
    extraReducers: (builder) => {
        builder.addCase(createMaterialCard.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteMaterialCard.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateMaterialCard.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<MaterialCard, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(sendToApprove.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<MaterialCard, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        
        builder.addCase(loadMaterialCards.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    },
})


export const { clearMaterialCardSlice } = materialCardSlice.actions

export const selectMaterialCards = (state: RootState): MaterialCardsState => state.materialCards

export const 
    { selectAll: selectAllMaterialCards
    , selectById: selectMaterialCardById 
    , selectTotal: selectTotalMaterialCards
    } = adapter.getSelectors<RootState>(selectMaterialCards)

export default materialCardSlice.reducer;
