import { Localized, useLocalization } from "@fluent/react"
import { Button, Chip, FormControl, FormControlLabel, MenuItem, Skeleton, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Theme } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useEffect, useRef, useState } from "react"
import { AppId } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { ReqCriticalityLabel } from "./ReqCriticalityLabel"
import { compareUrsVersions, loadUrsVersions, UrsHistory, UrsHistoryRequirement, UrsVersion } from "./UrsSlice"
import Switch from '@mui/material/Switch'
import { useNavigate } from "react-router-dom"

export const UrsVersionLabel = ({ version }: { version: UrsVersion}) => {
    const { l10n } = useLocalization()
    let versionName = '';
    switch (version.name) {
        case "CURRENT":
            versionName = l10n.getString("urs-diff-current", null, "Bieżąca") 
            break;
        case "OPINION":
            versionName = l10n.getString("urs-diff-opinion", null, "Zaopiniowania") 
            break;
        case "APPROVED":
            versionName = l10n.getString("urs-diff-approved", null, "Zatwierdzona") 
            break;
        default:
            versionName = version.name
        break;
    }

    return `${versionName} (v${version.mainVersion + 1})`
}

function VersionSlug(props: UrsVersion) {
    const { l10n } = useLocalization()
    let versionName = '';
    switch (props.name) {
        case "CURRENT":
            versionName = l10n.getString("urs-diff-current", null, "Bieżąca")
            break;
        case "OPINION":
            versionName = l10n.getString("urs-diff-opinion", null, "Zaopiniowania")
            break;
        case "APPROVED":
            versionName = l10n.getString("urs-diff-approved", null, "Zatwierdzona")
            break;
        default:
            versionName = props.name
        break;
    }
    return <>{versionName} (v{props.mainVersion + 1})</>
}

function filter_out_unchanged(ursHistory: UrsHistory): UrsHistory {
    const types = ursHistory.types.map(t => {
        const requirements = t.requirements.filter(r => r.status !== 'UNCHANGED')
        const groups = t.groups.map(g => {
            const requirements = g.requirements.filter(r => r.status !== 'UNCHANGED')
            return { ...g, requirements }
        })
        return { ...t, requirements, groups }
    })
    return { ...ursHistory, types }
}

export interface UrsDiffProps {
    ursId: AppId
}

export const UrsDiff = (props: UrsDiffProps) => {
    const { ursId } = props
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [versions, setVersions] = useState<UrsVersion[]>([])
    const [leftVersion, setLeftVersion] = useState<string | null>(null)
    const [rightVersion, setRightVersion] = useState<string | null>(null)
    const [loading, setLoading] = useState<boolean>(false)
    const [ursHistory, setUrsHistory] = useState<UrsHistory | undefined>(undefined)
    const [hideUnchanged, setHideUnchanged] = useState<boolean>(false)
    const [ursHistoryFiltered, setUrsHistoryFiltered] = useState<UrsHistory | undefined>(undefined)

    const lVer = versions.find(v => v.syntheticKey === leftVersion)
    const rVer = versions.find(v => v.syntheticKey === rightVersion)

    useEffect(() => {
        dispatch(loadUrsVersions(ursId))
        .then(unwrapResult)
        .then(vs => {
            setVersions(vs)
            if (vs.length > 1) {
                setLeftVersion(vs[0].syntheticKey)
                setRightVersion(vs[1].syntheticKey)
            }
        })
    }, [dispatch, ursId])

    useEffect(() => {
        if (lVer !== undefined && rVer !== undefined ) {
            setLoading(true)
            dispatch(compareUrsVersions({
                versionId: lVer.versionId,
                subVersionId: lVer.subVersionId,
                origVersionId: rVer.versionId,
                origSubVersionId: rVer.subVersionId,
            }))
                .then(unwrapResult)
                .then(data => {
                    console.log(data)
                    setUrsHistory(data)
                    if (hideUnchanged) {
                        setUrsHistoryFiltered(filter_out_unchanged(data))
                    } else {
                        setUrsHistoryFiltered(data)
                    }
                })
                .finally(() => setLoading(false))
        }
    }, [dispatch, hideUnchanged, ursId, lVer, rVer, versions])

    useEffect(() => {
        if (ursHistory !== undefined) {
            if (hideUnchanged) {
                setUrsHistoryFiltered(filter_out_unchanged(ursHistory))
            } else {
                setUrsHistoryFiltered(ursHistory)
            }
        }
    }, [ursHistory, hideUnchanged])

    return <div className="flex flex-col full-height">
            <div className="flex-none mb-2">
                <div className="flex flex-row">
                    <div className="grow">
                        <FormControl>
                            <FormControlLabel
                                label={<Localized id="urs-diff-current-version"><span>Wybierz aktualną wersje do porównania</span></Localized>}
                                labelPlacement="start"
                                control={
                                    <TextField disabled={loading} value={leftVersion} onChange={e => setLeftVersion(e.target.value)} sx={{ width: 200, ml: 1 }} select size="small">
                                        {versions.map(v => <MenuItem key={v.syntheticKey} value={v.syntheticKey}>
                                            <VersionSlug {...v} />
                                        </MenuItem>)}
                                    </TextField>
                                }
                            />
                        </FormControl>
                    </div>
                    <div className="grow">
                    <FormControl>
                        <FormControlLabel
                            label={<Localized id="urs-diff-previous-version"><span>Wybierz poprzednią wersję do porównania</span></Localized>}
                            labelPlacement="start"
                            control={
                                <TextField disabled={loading} value={rightVersion} onChange={e => setRightVersion(e.target.value)} sx={{ width: 200, ml: 1 }} select size="small">
                                    {versions.map(v => <MenuItem key={v.syntheticKey} value={v.syntheticKey}>
                                        <VersionSlug {...v} />
                                    </MenuItem>)}
                                </TextField>
                            }
                        />
                    </FormControl>
                    </div>
                <div className="grow">
                    <FormControl>
                        <FormControlLabel
                            label={<Localized id="urs-diff-show-changed"><span>Pokaż tylko zmienione</span></Localized>}
                            control={<Switch checked={hideUnchanged} onChange={e => setHideUnchanged(e.target.checked)} color="primary" />}
                        />
                    </FormControl>
                </div>
                </div>
            </div>
            <div className="grow mb-2 overflow-scroll">
                {loading && <Skeleton height="100%" />}
                {!loading && ursHistoryFiltered !== undefined && lVer && rVer && <Draw ursHistory={ursHistoryFiltered} leftVersion={lVer} rightVersion={rVer} />}
            </div>
            <div className="flex-none">
                <Button onClick={() => navigate(-1)} variant="outlined"color="secondary"><Localized id='back'>Wróć</Localized></Button>
            </div>
        </div>
}

const Draw = (props: { ursHistory: UrsHistory, leftVersion: UrsVersion, rightVersion: UrsVersion }) => {
    return <>{props.ursHistory.types.map(type => {
        if (type.requirements.length === 0 && type.groups.length === 0) return null

        if (type.requirements.length > 0) {
            return <div key={type.id}>
                <h5>{type.numeration}. {type.code} {type.name}</h5>
                <Requirements requirements={type.requirements} leftVersion={props.leftVersion} rightVersion={props.rightVersion} />
            </div>
        } else {
            return <div key={type.id}>
                <h5>{type.numeration}. {type.code} {type.name}</h5>
                {type.groups.map(group => {
                    return <div key={group.id}>
                        <h6>{group.numeration}. {group.name}</h6>
                        <Requirements requirements={group.requirements} leftVersion={props.leftVersion} rightVersion={props.rightVersion} />
                    </div>
                })}
            </div>
        }

    })}</>
}

const border: SxProps<Theme> = { borderRightWidth: 2, borderRightStyle: 'solid', borderRightColor: '#e1e1e1' }
interface RequirementsProps {
    requirements: UrsHistoryRequirement[]
    leftVersion: UrsVersion
    rightVersion: UrsVersion
}
const Requirements = (props: RequirementsProps) => {
    return <TableContainer>
        <Table>
            <TableHead>
                <TableRow sx={{ bgcolor: '#e1e1e1' }}>
                    <TableCell sx={{ textAlign: 'center', ...border }} colSpan={2}>
                        <Localized id="lp">Lp.</Localized>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', ...border }} colSpan={2}>
                        <Localized id="urs-diff-requirement-description">Treść wymagania</Localized>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'center', ...border }} colSpan={2}>
                        <Localized id="urs-diff-criticality">Krytyczność</Localized>
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
                <TableRow sx={{ bgcolor: '#e1e1e1' }}>
                    <TableCell sx={{ width: 140 }}>
                        <VersionSlug {...props.leftVersion} />
                    </TableCell>
                    <TableCell sx={{ width: 140, ...border }}>
                        <VersionSlug {...props.rightVersion} />
                    </TableCell>
                    <TableCell>
                        <VersionSlug {...props.leftVersion} />
                    </TableCell>
                    <TableCell sx={{ ...border }}>
                        <VersionSlug {...props.rightVersion} />
                    </TableCell>
                    <TableCell sx={{ width: 140 }}>
                        <VersionSlug {...props.leftVersion} />
                    </TableCell>
                    <TableCell sx={{ width: 140, ...border }}>
                        <VersionSlug {...props.rightVersion} />
                    </TableCell>
                    <TableCell sx={{ width: 140 }}></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {props.requirements.map((item, _) => <Requirement key={item.guid} {...item} />)}
            </TableBody>
        </Table>
    </TableContainer>
}
const Requirement = (requirement: UrsHistoryRequirement) => {
    const { numeration, numeration_orig, name, name_orig, criticality, criticality_orig, status, guid } = requirement
    const prevDiv = useRef<HTMLDivElement | null>(null)
    const currentDiv = useRef<HTMLDivElement | null>(null)

    return <TableRow key={guid}>
            <TableCell sx={{ verticalAlign: 'top' }}>{numeration}</TableCell>
            <TableCell sx={{ verticalAlign: 'top', ...border }}>{numeration_orig}</TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}>
                <div ref={currentDiv} dangerouslySetInnerHTML={{ __html: name }} />
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top', ...border }}>
                <div ref={prevDiv} dangerouslySetInnerHTML={{ __html: name_orig }} />
            </TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}><ReqCriticalityLabel criticality={criticality} /></TableCell>
            <TableCell sx={{ verticalAlign: 'top', ...border }}><ReqCriticalityLabel criticality={criticality_orig} /></TableCell>
            <TableCell sx={{ verticalAlign: 'top' }}><Status status={status as ChangeType} /></TableCell>
        </TableRow>
}

type ChangeType = 'ADDED' | 'MODIFIED' | 'DELETED' | 'UNCHANGED'

const Status = (props: { status: ChangeType }) => {
    switch (props.status) {
        case 'ADDED': return <New />
        case 'MODIFIED': return <Changed />
        case 'DELETED': return <Chip color='error' label={<Localized id='urs-changelog-delete'>Usunięte</Localized>} />
        case 'UNCHANGED': return <Chip label={<Localized id='urs-changelog-unchanged'>Bez zmian</Localized>} />
    }
}

const New = () => {
    return <Chip color='success' label={<Localized id='urs-changelog-add'>Nowe</Localized>} />
}
const Changed = () => {
    return <Chip color='warning' label={<Localized id='urs-changelog-modify'>Zmodyfikowane</Localized>} />
}

