import { Localized } from "@fluent/react"
import { Alert, Button, Container, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Skeleton, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { redirect, useNavigate } from "react-router-dom"
import { LoadModules } from "../../app/AppDataLoader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Module, selectAllModules, selectModule, setCurrentModule } from "./moduleSlice"

export const Modules = () => {
    return <LoadModules component={<Skeleton />}>
        <SelectModule />
    </LoadModules>
}

const SelectModule = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const allModules = useAppSelector(selectAllModules)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const modules = useAppSelector(state => selectModule(state).entities)

    const [module, setModule] = useState<Module | null>(() => currentModule ?? (allModules.length > 0 ? allModules[0] : null))

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const selected = modules[(event.target as HTMLInputElement).value] as Module
        setModule(selected)
    }

    const handleOKClick = () => {
        if (module) {
            dispatch(setCurrentModule(module))
            navigate('/')
        }
    }

    if (allModules.length === 0) return <NoModules />

    return <Container maxWidth='xs' sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
    }}>
        <Stack spacing={2}>
            <FormControl>
                <FormLabel>
                    <Typography variant="caption">
                        <Localized id="module-select">
                            <span>Wybierz moduł</span>
                        </Localized>
                    </Typography>
                </FormLabel>
                <RadioGroup value={module?.code} onChange={handleChange}>
                    {allModules.map(({ code, name }) => <FormControlLabel key={code} value={code} control={<Radio />} label={name} />)}
                </RadioGroup>
            </FormControl>
            <Button disabled={module === null} fullWidth variant="contained" onClick={handleOKClick}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </Button>
        </Stack>
    </Container>
}

const NoModules = () => {
    return <Alert severity='warning'>
        <Localized id="module=no-modules">
            <span>Brak modułów</span>
        </Localized>
    </Alert>
}
