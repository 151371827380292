import { Localized } from "@fluent/react"
import { styled } from '@mui/material/styles'
import { ChangeEvent, useCallback } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Locale, selectSettings, setLocalePersistent } from "./settingsSlice"
import MenuItem from '@mui/material/MenuItem'
import TextField from "@mui/material/TextField"
import Container from '@mui/material/Container'

const PREFIX = 'Settings';

const classes = {
    dropdown: `${PREFIX}-dropdown`
};

const StyledContainer = styled(Container)(( { theme }) => ({
    [`& .${classes.dropdown}`]: {
        width: "100%",
    }
}));

const langs: Record<Locale, string> = {
    "en-US": "English",
    "pl": "Polski"
}

const opts = Object.entries<string>(langs).map(lang => {
    const [value, label] = lang;
    return { 
        value,
        label,
    };
})

export const Settings = () => {
    const dispatch = useAppDispatch();
    const { locale } = useAppSelector(selectSettings);

    const handleLangChange = useCallback((e: ChangeEvent<{value: unknown}>) => {
        dispatch(setLocalePersistent(e.target.value as Locale));
    }, [dispatch])

    return (
        <StyledContainer maxWidth="sm">
            <div>
                <TextField 
                    select 
                    label={<Localized id="settings-lang">Język</Localized>} 
                    className={classes.dropdown}
                    value={locale}
                    onChange={handleLangChange}
                >
                    {opts.map(lang => {
                        const { value, label } = lang;
                        return <MenuItem key={value} value={value}>{label}</MenuItem>;
                    })}
                </TextField>
            </div>
        </StyledContainer>
    );
}