import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { APIError, AppId } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { updateURSRequirement, Urs, URSRequirement } from "../urs/UrsSlice"
import { ValidationsBrowser } from "../validations/ValidationsBrowser"

export interface AssociateTestProps {
    ursRequirement: URSRequirement
    onSuccess: (urs: Urs) => void
    onError: (err: APIError) => void
    onCancel: () => void
}
export const AssociateTest = ({ ursRequirement, onSuccess, onError, onCancel }: AssociateTestProps) => {
    const dispatch = useAppDispatch()
    const excluded = useMemo(() => new Set() as Set<AppId>, [])

    const [running, setRunning] = useState(false)
    const [testId, setTestId] = useState<AppId | undefined>(undefined)

    const testSelected  = useCallback((id: AppId) => {
        setTestId(id)
    }, [setTestId])
    const testUnselected = useCallback(() => {
        setTestId(undefined)
    }, [setTestId])

    const handleOKClick = async () => {
        if (testId) {
            setRunning(true)
            try {
                const urs = await dispatch(updateURSRequirement({...ursRequirement, testDefinitionId: testId, }))
                    .unwrap()
                onSuccess(urs)
            } catch (error) {
                onError(error as APIError)
            } finally {
                setRunning(false)
            }
        }
    }

    const selection: Set<AppId> = useMemo(() => testId ? new Set([testId]) : new Set(), [testId])

    return <Dialog sx={{ maxHeight: '80%' }} open={true} fullWidth maxWidth='lg'>
        <DialogContent>
            <ValidationsBrowser excluded={excluded} selection={selection} itemChecked={testSelected} itemUnchecked={testUnselected}></ValidationsBrowser> 
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={handleOKClick}>
                <Localized id="ok"><span>OK</span></Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
