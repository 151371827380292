import { Localized } from "@fluent/react"
import { DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { useAppDispatch } from "../../app/hooks"
import { LoadingButton } from "@mui/lab"
import { inviteTeamMember } from "./UrsSlice"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { unwrapResult } from "@reduxjs/toolkit"

export interface AddExternalTeamMemberProps {
    ursId: AppId
    onOKClick: (userName: string | undefined) => void
    onCancelClick: () => void,
}
export const AddExternalTeamMember = ({  ursId, onCancelClick, onOKClick }: AddExternalTeamMemberProps) => {
    const dispatch = useAppDispatch()
    const [userRole, setUserRole] = useState<string>("EXT_R")
    const [userEmail, setUserEmail] = useState<string>("")
    
    const [savingInProgress, setSavingInProgress] = useState(false)

    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setUserRole(e.target.value), [setUserRole])
    const handleUserEmailChange: AppTextFieldHandler = useCallback(e => setUserEmail(e.target.value), [setUserEmail])

    const handleOkClick = () => {
        if(userEmail){
            dispatch(inviteTeamMember({ userEmail: userEmail, ursId , role: userRole }))
            .then(unwrapResult)
            .then(() => onOKClick(userEmail))
            .finally(() => setSavingInProgress(false))
        }
    }

    return <>
        <DialogTitle>
            <Localized id="urs-invite-team-member">
                <span>Zaproś użytkownika</span>
            </Localized>
        </DialogTitle>
        <DialogContent sx={{
            padding: 2,
        }}>
            <TextField
                sx={{
                    marginTop: 1,
                }}
                value={userEmail}
                fullWidth
                required={true}
                label={<Localized id="email-address"><span>Email</span></Localized>}
                onChange={handleUserEmailChange}
                >
            </TextField>
            <TextField    
                    sx={{
                        marginTop: 1,
                    }}                                                        
                    value={userRole}
                    fullWidth
                    required={true}
                    select
                    label={<Localized id="urs-user-role"><span>Rola</span></Localized>}
                    onChange={handleUserRoleChange}
                    >
                
                    <MenuItem value={"E"}><Localized id="urs-role-editor"><span>Edytor</span></Localized></MenuItem>    
                    <MenuItem value={"R"}><Localized id="urs-role-reviever"><span>Opiniujący</span></Localized></MenuItem>
                    <MenuItem value={"A"}><Localized id="urs-role-approver"><span>Zatwierdzający</span></Localized></MenuItem>
                    <MenuItem value={"AR"}><Localized id="urs-role-reviever-and-approver"><span>Opiniujący + Zatwierdzający</span></Localized></MenuItem>
                    <MenuItem value={"V"}><Localized id="urs-role-viewer"><span>Obserwator</span></Localized></MenuItem>                       
                    <MenuItem value={"EXT_R"}><Localized id="urs-role-external-reviever"><span>Zewnętrzny opiniujący</span></Localized></MenuItem>                       
                </TextField>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={savingInProgress} onClick={handleOkClick}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={savingInProgress} onClick={onCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}
