import { Localized } from "@fluent/react";

export const ReqCriticalityLabel = ({ criticality }: { criticality: string}) => {
    switch (criticality) {
        case "E":
            return <Localized id="urs-item-criticality-e">
                <span>Essential</span>
            </Localized>        
        case "I":
            return <Localized id="urs-item-criticality-i">
                <span>Optional</span>
            </Localized>
        case "D":
            return <Localized id="urs-item-criticality-d">
                <span>Desired</span>
            </Localized>        
        default:
            return <span></span>
    }
}