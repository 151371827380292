import Container from '@mui/material/Container'
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { Localized } from "@fluent/react"
import SaveIcon from '@mui/icons-material/Save'
import { createDeviceType } from "./deviceTypesSlice"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Autocomplete, Grid, Skeleton } from "@mui/material"
import { AppLoader } from "../../app/AppLoader"
import { LoadAreas } from '../../app/AppDataLoader'
import { Area, selectAllAreas } from '../areas/areaSlice'
import { Link, useNavigate } from 'react-router-dom'

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const CreateDeviceType = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const allAreas = useAppSelector(selectAllAreas)

    const [code, setCode] = useState("")
    const [name, setName] = useState("")    
    const [description, setDescription] = useState("")
    const [validationErrors, setValidationErrors] = useState(false)
    const [area, setArea] = useState<Area | null>(null)
    const [saving, setSaving] = useState(false)

    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [setCode])
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [setDescription])
    const handleAreaChange = useCallback((_, newArea) => setArea(newArea), [])

    const handleSaveClick = async () => {
        setValidationErrors(true)

        if (code && name) {
            setSaving(true)
            try {
                const { id: newId } = await dispatch(createDeviceType({ code, name, description, areaId: area?.id ?? null })).unwrap();
                dispatch(showSuccess("saved"))
                navigate(`/deviceType/edit/${newId}`, { replace: true })
            } catch (error) {
                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    const codeError = validationErrors && code === ""
    const nameError = validationErrors && name === ""

    return <LoadAreas component={<Skeleton height="50vh" variant="rectangular" />}>
        <Container component={Paper} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        error={codeError}
                        helperText={codeError && <RequiredError />}
                        value={code}
                        inputProps={{ maxLength: 20 }}                
                        onChange={handleCodeChange}
                        label={<Localized id="device-type-code">
                            <span>Kod</span>
                        </Localized>}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        error={nameError}
                        helperText={nameError && <RequiredError />}
                        value={name}
                        inputProps={{ maxLength: 200 }}                
                        onChange={handleNameChange}
                        label={<Localized id="device-type-name">
                            <span>Nazwa typu urządzenia</span>
                        </Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        options={allAreas}
                        fullWidth
                        value={area}
                        onChange={handleAreaChange}
                        getOptionLabel={item => item.name}
                        renderInput={params => <TextField {...params} label={<Localized id="area-name">Nazwa obszaru</Localized>} />}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        value={description}
                        inputProps={{ maxLength: 1000 }}                
                        onChange={handleDescriptionChange}
                        label={
                            <Localized id="device-type-description">
                                <span>Opis</span>
                            </Localized>
                        }
                    />
                </Grid>
            </Grid>
            <ButtonGroup sx={{marginTop: 2, marginBottom: 2}} color="secondary" variant="contained">
                <Button startIcon={<SaveIcon />} 
                        onClick={handleSaveClick}>
                    <Localized id="save">Zapisz</Localized>
                </Button>
                <Button  component={Link} to={`/devicetypes`} color="secondary" variant="outlined">
                    <Localized id="back">Wróć</Localized>
                </Button>
            </ButtonGroup>
        </Container>
        <AppLoader open={saving} />
    </LoadAreas>
}
