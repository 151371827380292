import { Localized } from "@fluent/react";


export const TeamActionStatusLabel = ({ status }: { status: string | undefined}) => {
    switch (status) {
        case "Pending":
            return <Localized id="urs-action-status-pending">
                <span>W trakcie</span>
            </Localized>        
        case "Complited":
            return <Localized id="urs-action-status-complited">
                <span>Zakończona</span>
            </Localized>
        case "Closed":
            return <Localized id="urs-action-status-closed">
                <span>Zamknięta</span>
            </Localized>
      
        default:
            return <span>{status}</span>
    }
}