import { Localized } from "@fluent/react";
import { DocumentTeamMemberRole } from "../teamMemberApi";

export const TeamMemberRoleLabel = ({ role }: { role: DocumentTeamMemberRole }) => {
    switch (role) {
        case "C":
            return <Localized id="urs-role-creator">
                <span>Author</span>
            </Localized>
        case "E":
            return <Localized id="urs-role-editor">
                <span>Edytor</span>
            </Localized>
        case "R":
            return <Localized id="urs-role-reviever">
                <span>Opiniujący</span>
            </Localized>
        case "A":
            return <Localized id="urs-role-approver">
                
                <span>Zatwierdzający</span>
            </Localized>
        case "AR":
            return <Localized id="urs-role-reviever-and-approver">
                <span>Opiniujący + Zatwierdzający</span>
            </Localized>
        case "V":
            return <Localized id="urs-role-viewer">
                <span>Obserwator</span>
            </Localized>
        case "EXT_R":
            return <Localized id="urs-role-external-reviever">
                <span>Zewnętrzny opiniujący</span>
            </Localized>
        default:
            return <span></span>
    }
}