import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import {compareNum} from "../../app/arrays";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth} from "../../http";
import {Uuid} from "../urs/ursAttachementsSlice";
import { logout } from "../user/userSlice";


export type ExternalUrsOfferStatus = "N" | "R" | "A"  | "S" |"E" | "F"
export type ExternalRequirementNote = "F" | "D" | "U" | ""

export interface ExternalUrsOffer {
   id: AppId 
   supplierId: AppId  
   supplierName: string
   ursNo: string   
   ursId: AppId
   deviceTypeId: AppId
   deviceTypeName: string
   companyId: AppId
   companyName: string
   status: ExternalUrsOfferStatus  
   createDate: string   
   responseDate: string   
   comment: string
   title: string 
   purpose: string 
   description: string 
   area: string 
   token: string
   ursOfferRequirements: EntityState<ExternalUrsOfferRequirement>
   requirementTypes: EntityState<ExternalRequirementType>
   supplierDeviceId: AppId | null
   guid: Uuid
   comments : ExternalRequirementComment[]
   types: ExternalUrsOfferGroup[]
}

export interface ExternalUrsOfferGroup {
    id: AppId
    name: string
    numeration: string
    code: string
    requirements: ExternalUrsOfferRequirement[]
    groups: ExternalUrsOfferGroup[]
}

export interface ExternalUrsOfferRequirementType {
    id: AppId
    code: string
    name: string
    name_EN: string
    order: number
    numeration: string
    requirements: ExternalUrsOfferRequirement[]
    groups: ExternalUrsOfferGroup[]
    sysSectionId: AppId | null // ID of requirement type in system
}

export function* allExternalOfferRequirements(offer: ExternalUrsOffer) {
    for (const type of offer.types) {
        for (const req of type.requirements) {
            yield req
        }
        for (const group of type.groups) {
            for (const req of group.requirements) {
                yield req
            }
        }
    }
}

export type ExternalUrsOfferHeader = Omit<ExternalUrsOffer, "ursOfferRequirements" | "requirementTypes">


export interface ExternalUrsOfferRequirement {
    id: string
    code: string
    name: string
    description: string
    criticality: string | null
    typeId: string
    ursId: string
    comment: string
    note: ExternalRequirementNote
    supplierDeviceDetailId: AppId | null
    guid: Uuid
    order: number
    numeration: string | null
}

export interface ExternalRequirementType {
    id: AppId, 
    code: string, 
    name: string,
    order: number,
    numeration: string | null
    isSystem: boolean
 }

 export interface NotePayload {
    token: string,
    requirementId: AppId, 
    comment: string, 
    note: string
 }

 export interface ExternalRequirementComment {
    id               : AppId
    ursOfferId       : AppId
    ursRequirementId : AppId
    message          : string
    author           : string | null
    userId           : AppId | null
    date             : string
    status           : string
    parentMessageId  : null | AppId
    subMessages      : string[]
    guid: string
    type: string
 }
 
 export interface FinishPayload {
    token: string,
    option: string,
    email: string,
    emailConfirmation: string,
    firstName: string,
    lastName: string,
    phone: string,
    address: string,
    companyName: string,
    city: string,
    state: string,
    zipcode: string,
    country: string
 }

const adapter = createEntityAdapter<ExternalUrsOfferHeader>({
    selectId: (externalOffer) => externalOffer.id,
})

export const externalOfferRequirementAdapter = createEntityAdapter<ExternalUrsOfferRequirement>({
    selectId: req => req.id,
    sortComparer: (x, y) => compareNum(x.order, y.order),
})

export const { 
    selectAll: selectAllExternalOfferRequirements,
    selectById: selectExternalOfferRequirementById,
} = externalOfferRequirementAdapter.getSelectors()


export const externalRequirementTypeAdapter = createEntityAdapter<ExternalRequirementType>({
    selectId: reqType => reqType.id,
})

export const { 
    selectAll: selectAllExternalRequirementTypes,
    selectById: selectExternalRequirementTypeById,
} = externalRequirementTypeAdapter.getSelectors()



export type ExternalUrsOfferState = EntityState<ExternalUrsOfferHeader> & { state: AppDataState }

const initialState: ExternalUrsOfferState = adapter.getInitialState({
    state: {
        type: "empty",
    }
})


export const loadExternalUrsOfferList = createAsyncThunk<ExternalUrsOfferHeader[], void, AppThunkAPIType>
("externalUrsOffers/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/external-urs-offer"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as ExternalUrsOfferHeader[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

const decode = (data: any): ExternalUrsOffer => {
    const initialRequirements = externalOfferRequirementAdapter.getInitialState()
    const ursOfferRequirements = 
        data.ursOfferRequirements 
            ? externalOfferRequirementAdapter.addMany(initialRequirements, data.ursOfferRequirements) 
            : initialRequirements   

    const initialReqTypes = externalRequirementTypeAdapter.getInitialState()
    const reqTypes = 
        data.requirementTypes 
                ? externalRequirementTypeAdapter.addMany(initialReqTypes, data.requirementTypes) 
                : initialReqTypes   

    return {...data
        , ursOfferRequirements 
        , requirementTypes: reqTypes       
    } as ExternalUrsOffer 
}

const encode = (externalUrsOffer: ExternalUrsOffer): any => {
return {
    ...externalUrsOffer,
    ursOfferRequirements: selectAllExternalOfferRequirements(externalUrsOffer.ursOfferRequirements),    
    requirementTypes: selectAllExternalRequirementTypes(externalUrsOffer.requirementTypes)
    }
}

export const loadExternalUrsOffer = createAsyncThunk<ExternalUrsOffer, AppId, AppThunkAPIType>
("externalUrsOffers/loadForUrs", async (token, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/external-urs-offer/${token}`
    }))
    const { payload } = result 
    if (getWithAuth.fulfilled.match(result)) {
        
        return decode(payload)
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateExternalUrsOffer = createAsyncThunk<ExternalUrsOffer, ExternalUrsOffer, AppThunkAPIType>
("externalUrsOffers/update", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(putWithAuth({
        url: `api/external-urs-offer/${data.token}`,
        payload: encode(data),
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return decode(data)
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const addNote = createAsyncThunk<ExternalUrsOffer, NotePayload, AppThunkAPIType>(
    "externalUrsOffers/addnote", 
    async (note: NotePayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: `api/external-urs-offer/${note.token}`,
            payload: note,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ExternalUrsOffer
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const rejectExternalOffer = createAsyncThunk<ExternalUrsOffer, {token: string, comment: string}, AppThunkAPIType>(
    "externalUrsOffers/reject-offer",
    async ({ token, comment } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/external-urs-offer/reject/${token}`,
            payload: {
                comment,
            }
        }))
        const { payload } = result
        
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }   
    }
)

export const sendExternalToEvaluation = createAsyncThunk<ExternalUrsOffer, {token: string, comment: string}, AppThunkAPIType>(
    "externalUrsOffers/evaluation",
    async ({ token, comment } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/external-urs-offer/evaluation/${token}`,
            payload: {
                comment,
            }
        }))
        const { payload } = result
        
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }   
    }
)


export const finishCommenting = createAsyncThunk<ExternalUrsOffer, FinishPayload, AppThunkAPIType>(
    "externalUrsOffers/finishCommenting",
    async ( finishPayload, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/external-urs-offer/finish/${finishPayload.token}`,
            payload: finishPayload 
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return decode(payload)
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const postExternalUrsRequirementComment = createAsyncThunk<ExternalRequirementComment, { comment: ExternalRequirementComment, token: string }, AppThunkAPIType>(
    "externalUrsOffers/comment-requirement",
    async ({ comment, token }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/external-urs-offer/comment/${token}`,
            payload: comment
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ExternalRequirementComment
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateExternalUrsRequirementComment = createAsyncThunk<ExternalRequirementComment, { comment: ExternalRequirementComment, token: string }, AppThunkAPIType>(
    "externalUrsOffers/update-comment-requirement",
    async ({ comment, token }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/external-urs-offer/comment/${token}/${comment.id}`,
            payload: comment
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as ExternalRequirementComment
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteExternalUrsRequirementComment = createAsyncThunk<AppId, { commentId: AppId, token: string }, AppThunkAPIType>(
    "externalUrsOffers/delete-comment-requirement",
    async ({ commentId, token }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({
            url: `api/external-urs-offer/comment/${token}/${commentId}`,
        }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return commentId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const externalUrsOfferSlice = createSlice({
    name: "externalUrsOffer",
    initialState,
    reducers: {
        clearExternalUrsOfferSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loadExternalUrsOfferList.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadExternalUrsOfferList.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
            }
        })
        builder.addCase(loadExternalUrsOfferList.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(updateExternalUrsOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(rejectExternalOffer.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
         builder.addCase(sendExternalToEvaluation.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(finishCommenting.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectExternalUrsOffers = (state: RootState): ExternalUrsOfferState => state.externalUrsOffers

export const 
    { selectAll: selectAllExternalUrsOffers
    , selectById: selectExternalUrsOfferById 
    , selectTotal: selectTotalExternalUrsOffers
    } = adapter.getSelectors<RootState>(selectExternalUrsOffers)

export default externalUrsOfferSlice.reducer;
