import { createSlice } from "@reduxjs/toolkit";
import { HelpDialogProps } from "./HelpDialog";

export type AppDialogState
    = { tag: 'none' }
    | { tag: 'help' , props: HelpDialogProps }

const initialState = { tag: 'none' } as AppDialogState

export const dialogSlice = createSlice({
    name: "dialog",
    initialState,
    reducers: {
        hideDialog: (state) => {
            state = { tag: 'none' }
        },
        showDialog: (state, action) => {
            state.tag = action.payload.tag
            if (state.tag !== 'none') {
                state.props = action.payload.props
            }
        }
    }
})

export const { showDialog, hideDialog } = dialogSlice.actions

export default dialogSlice.reducer

