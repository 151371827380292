import { DocumentFormHeader } from "../../components/DocumentFormHeader";
import { RiskAnalysisHeader } from "../riskAnalysisApi";

export interface RiskAnalysisFormHeaderProps {
    documentHeader: RiskAnalysisHeader | undefined;
}

export const RiskAnalysisFormHeader = (props: RiskAnalysisFormHeaderProps) => {
    const { documentHeader } = props

    return documentHeader ? <DocumentFormHeader doc={documentHeader} /> : <></>
}
