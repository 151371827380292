import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteWithAuth, getWithAuth, postWithAuth } from "../http"
import { AppDataState, AppId, AppThunkAPIType } from "./appTypes"
import { RootState } from "./store"

export function load<TConfig, TOut>(
    actionName: string,
    getUrl: (config: TConfig) => string,
    selectState: (state: RootState) => AppDataState
) {
    return createAsyncThunk<TOut[], TConfig, AppThunkAPIType>(actionName, async (config, { dispatch, rejectWithValue, getState, requestId }) => {
        const state = selectState(getState())

        if(state.type === "loading" && state.requestId === requestId) {
            const result = await dispatch(getWithAuth({ 
                url: getUrl(config),
            }))
            const { payload } = result

            if(getWithAuth.fulfilled.match(result)) {
                return payload as TOut[]
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
        return []
    })
}

export function loadOne<T>(
    actionName: string,
    getUrl: (id: AppId) => string,
) {
    return createAsyncThunk<T, AppId, AppThunkAPIType>(actionName, async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ 
            url: getUrl(id),
        }))
        const { payload } = result

        if(getWithAuth.fulfilled.match(result)) {
            return payload as T
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
}

export const isSameRequestId = (state: AppDataState, requestId: string): boolean => state.type === "loading" && state.requestId === requestId ? true : false

export function create<TConfig, T extends object>(
    actionName: string,
    getUrl: (config: TConfig) => string,
) {
    return createAsyncThunk<T, [TConfig, T], AppThunkAPIType>(actionName, async ([config, data], { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: getUrl(config) ,
            payload: data,
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as T
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })
}

export function remove<T extends object>(
    actionName: string,
    getUrl: (entity: T) => string,
) {
    return createAsyncThunk<T, T, AppThunkAPIType>(actionName, async (entity, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: getUrl(entity) }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return entity
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }

    })
}
