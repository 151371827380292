import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId, load } from "../../app/crud";
import { RootState } from "../../app/store";
import { deleteWithAuth, postWithAuth, putWithAuth } from "../../http";

export interface Area {
    id: AppId
    code: string
    name: string
    parentAreaId: AppId | null
    subAreaIds: AppId[]
    deviceIds: AppId[]
}

const adapter = createEntityAdapter<Area>({
    selectId: area => area.id,
})

export type AreaState = EntityState<Area> & { state: AppDataState }

const initialState: AreaState = adapter.getInitialState({
    state: { type: "empty" }
})

export const loadAreas = load<void, Area>(
    "areas/load",
    () => `api/areas`,
    state => selectAreas(state).state,
)

export const createArea = createAsyncThunk<Area, Area, AppThunkAPIType>("areas/create", async (area, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: "api/areas",
        payload: area,
    }))
    const { payload } = result 
    if (postWithAuth.fulfilled.match(result)) {
        return payload as Area 
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateArea = createAsyncThunk<Area, Area, AppThunkAPIType>("areas/update", async (area, { dispatch, rejectWithValue }) => {
    const result = await dispatch(putWithAuth({
        url: `api/areas/${area.id}`,
        payload: area,
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return area
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteArea = createAsyncThunk<AppId, AppId, AppThunkAPIType>("areas/delete", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuth({ url: `api/areas/${id}` }))
    const { payload } = result 
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
}) 

export const areasSlice = createSlice({
    name: "areas",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadAreas.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadAreas.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadAreas.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(createArea.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateArea.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteArea.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)    
        })
    },
})

export const selectAreas = (state: RootState) => state.areas

export const 
    { selectAll: selectAllAreas
    , selectById: selectAreaById
    , selectEntities: selectAreasEntities
    , selectTotal: selectAreasTotal
    } = adapter.getSelectors(selectAreas)

export default areasSlice.reducer;