import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { If } from "../../app/If"
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useCallback, useState } from "react"
import { createMailTemplate, createNewCompanyMailTemplate, selectMailTemplateById, updateMailTemplate } from "./mailTemplatesSlice"


export interface MailTemplateDialogProps {
    id: AppId | undefined
    editable: boolean
    onSaveSuccess: () => void
    onCancel: () => void
}

export const MailTemplateDialog = ({ id, editable,  onSaveSuccess, onCancel }: MailTemplateDialogProps) => {
    const dispatch = useAppDispatch()

    const entity = useAppSelector(state => selectMailTemplateById(state, id ?? ""));
    const [type, setType] = useState<string>(entity?.type ?? "");
    const [body, setBody] = useState<string>(entity?.body ?? "");
    const [subject, setSubject] = useState<string>(entity?.subject ?? "");
    const [saving, setSaving] = useState(false)

    const handleBodyChange: AppTextFieldHandler = useCallback(e => setBody(e.target.value), [setBody]);
    const handleSubjectChange: AppTextFieldHandler = useCallback(e => setSubject(e.target.value), [setSubject]);
    const handleTypeChange: AppTextFieldHandler = useCallback(e => setType(e.target.value), [setType]);

    const handleSaveClick = async () => {
        setSaving(true)
        try {
            if(id === undefined && editable) {
                let template = {
                    type, body, subject
                };

                await dispatch(createMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            } else if (editable && entity) {
                let template = {
                    ...entity,
                    body, subject
                };

                await dispatch(updateMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            } else if(entity) {
                let template = {
                    ...entity,
                    body, subject
                };

                await dispatch(createNewCompanyMailTemplate(template)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
            
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    return <>
        <DialogTitle>
            <If condition={id !== undefined} otherwise={
                <Localized id="new-mail-template">
                    <span>Nowy szablon</span>
                </Localized>
            }>
                <Localized id="edit-mail-template">
                    <span>Edycja szablonu</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} padding={2}>
                <TextField
                    fullWidth
                    disabled={id !== undefined || !editable}
                    value={type}
                    inputProps={{ maxLength: 100 }}
                    onChange={handleTypeChange}
                    label={<Localized id="mail-template-type">Typ</Localized>}>
                </TextField>
                <TextField
                    fullWidth
                    // disabled={!editable}
                    value={subject}
                    inputProps={{ maxLength: 100 }}
                    onChange={handleSubjectChange}
                    label={<Localized id="mail-subject">Temat</Localized>}>
                </TextField>
                <TextField
                    fullWidth
                    value={body}
                    // disabled={!editable}
                    minRows={3}
                    maxRows={12}
                    multiline
                    inputProps={{ maxLength: 10000 }}
                    onChange={handleBodyChange}
                    label={<Localized id="mail-body">Treść</Localized>}>
                </TextField>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving} /*disabled={!editable}*/>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}