import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, MenuItem, Select, Stack, TextField } from "@mui/material"
import { useCallback, useEffect, useState } from "react";
import SyncIcon from '@mui/icons-material/Sync';
import { Localized, useLocalization } from "@fluent/react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { format } from "date-fns";
import { getWithAuth } from "../../http";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DateMenuFilter } from "../../app/DateMenuFilter";
import { selectUserCompanyId } from "../user/userSlice";
import { Company, loadCompanies } from "../company/companiesSlice";
import { If } from "../../app/If";
import { selectModule } from "../modules/moduleSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { LoadingContainer } from "../../app/LoadingContainer";
import { selectSettings } from "../settings/settingsSlice";
import { enUS, plPL } from "@mui/x-data-grid/locales";

export interface Mail {
    id: number
    mailBody: string,
    mailTo: string,
    mailType: string,
    mailSubject: string
    sendBy: string,
    sendDate: Date,
    status: string,
    message: string
}

export const MailLogs = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()

    const [rows, setRows] = useState<Mail[]>([]);
    const [showDetailsDialog, setShowDetailsDialog] = useState(false);
    const [currentRow, setCurrentRow] = useState<Mail | null>(null);
    const [companies, setCompanies] = useState<Company[]>([]);
    const [mailType, setMailType] = useState('all');
    const [mailTypes, setMailTypes] = useState<string[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const loggedUserCompanyId = useAppSelector(selectUserCompanyId) as string
    const [company, setCompany] = useState(loggedUserCompanyId ? loggedUserCompanyId : 'all');

    const currentDate = new Date();
    var dateFromFilter = new Date();
    dateFromFilter.setDate(currentDate.getDate() - 30);
    const [dateFrom, setDateFrom] = useState(dateFromFilter);
    const [dateTo, setDateTo] = useState(new Date());

    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const isSuper = currentModule?.code === "super"

    useEffect(() => {
        dispatch(getWithAuth({
            url: `api/MailLogs/types`
        })).then(result => {
            if (getWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setMailTypes(payload as string[])
            }
        })
        dispatch(loadCompanies())
            .then(unwrapResult)
            .then(setCompanies)
            .catch((error) => console.error(error))
    }, [dispatch])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleSubmit = (date, date2) => {
        setDateFrom(date);
        setDateTo(date2);
    }

    const handleMailTypeChange = (event) => {
        setMailType(event.target.value);
    };

    const handleCompanyChange = (event) => {
        setCompany(event.target.value);
    };

    const handleRefreshClick = async () => {
        setIsLoading(true);
        const result = await dispatch(getWithAuth({
            url: `api/MailLogs/filter?${isSuper && company !== "all" ? 'companyId=' + company + '&': ''}mailType=${mailType}&dateFrom=${dateFrom.toISOString()}&dateTo=${dateTo.toISOString()}`
        }))
        if (getWithAuth.fulfilled.match(result)) {
            const { payload } = result
            setRows(payload as Mail[])
            setIsLoading(false);
        }
    };

    const handleShowDetails = useCallback((row: Mail) => {
        setCurrentRow(row)
        setShowDetailsDialog(true)
    }, [setShowDetailsDialog])

    const handleCancelPurposeClick = async () => {
        setCurrentRow(null)
        setShowDetailsDialog(false)
    }

    const columns: GridColDef[] =
        [
            {
                field: 'sendDate',
                headerName: l10n.getString("mail-date", null, "Data"),
                flex: 1,
                renderCell: (params) => {
                    return params ? format(new Date(params.value), 'dd/MM/yyyy HH:mm:ss') : null;  // formatujemy do wyglądu daty i czasu
                }
            },
            {
                field: 'mailType',
                headerName: l10n.getString("mail-type", null, "Typ"),
                flex: 1
            },
            {
                field: 'mailTo',
                headerName: l10n.getString("mail-recipient", null, "Adresat"),
                flex: 1
            },
            {
                field: 'actions', type: 'actions', width: 200,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button color="secondary" variant="outlined" onClick={() => handleShowDetails(params.row)} >
                                <Localized id="details">
                                    <span>Szczegóły</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];

    return (
        <>
            <div>
                <Stack direction="row" spacing={2} sx={{ marginBottom: 2 }}>
                    <If condition={isSuper}>
                        <Select
                            value={company}
                            onChange={handleCompanyChange}
                            label={<Localized id="company">Firma</Localized>}
                            sx={{ width: '200px' }}
                        >
                            <MenuItem value={'all'}>
                                <Localized id="all">All</Localized>
                            </MenuItem>
                            {companies.map((option) =>
                                <MenuItem value={option.id}>{option.name}</MenuItem>
                            )}
                        </Select>
                    </If>
                    <Select
                        value={mailType}
                        onChange={handleMailTypeChange}
                        label={<Localized id="mail-type">Typ</Localized>}
                        sx={{ width: '200px' }}
                    >
                        <MenuItem value={'all'}>
                            <Localized id="all">All</Localized>
                        </MenuItem>
                        {mailTypes.map((option) =>
                            <MenuItem value={option}>{option}</MenuItem>
                        )}
                    </Select>
                    <DateMenuFilter onSubmit={handleSubmit}/>
                    <IconButton
                        onClick={handleRefreshClick}>
                        <SyncIcon />
                    </IconButton>
                </Stack>
            </div >
            <div style={{ flexGrow: 1 }}>
                {isLoading ? (<LoadingContainer/>) : (<DataGrid style={{ minHeight: 100, height: 'calc(100vh - 325px)' }} localeText={getCustomLocaleText()} rows={rows} columns={columns} />)}
            </div>
            <Dialog open={showDetailsDialog} fullWidth maxWidth="lg" >
                <DialogContent>
                    <DialogContentText>
                        <Localized id="mail-details"><span>Mail details</span></Localized>
                    </DialogContentText>
                    <Stack direction="column" spacing={2} padding={2}>
                        <TextField
                            disabled={true}
                            value={currentRow?.mailSubject}
                            label={<Localized id="mail-subject">Temat</Localized>}>
                        </TextField>
                        <TextField
                            multiline
                            minRows={4}
                            maxRows={10}
                            disabled={true}
                            value={currentRow?.mailBody}
                            label={<Localized id="mail-body">Treść</Localized>}>
                        </TextField>
                        <Stack direction="row" >
                            <TextField
                                disabled={true}
                                value={currentRow?.status}
                                label={<Localized id="mail-status">Status</Localized>}>
                            </TextField>
                            <TextField
                                disabled={true}
                                fullWidth
                                value={currentRow?.message}
                                label={<Localized id="mail-message">Message</Localized>}>
                            </TextField>
                        </Stack>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelPurposeClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
