import { styled } from '@mui/material/styles';
import { Backdrop, CircularProgress } from "@mui/material";

const PREFIX = 'AppLoader';

const classes = {
    backdrop: `${PREFIX}-backdrop`
};

const StyledBackdrop = styled(Backdrop)(( { theme }) => ({
    [`&.${classes.backdrop}`]: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    }
}));

export const AppLoader = ({ open }: { open: boolean }) => {

    return (
        <StyledBackdrop className={classes.backdrop} open={open}>
            <CircularProgress color="inherit"></CircularProgress>
        </StyledBackdrop>
    );
}