import { Localized } from '@fluent/react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { useAppDispatch, useAppSelector, useQueryStructureId } from '../../app/hooks'
import { Dispatch, MouseEvent, SetStateAction, useCallback, useMemo, useState } from 'react'
import { Alert, Box, ButtonGroup,Checkbox, Dialog, Link, ListItemIcon, ListItemText, Menu, MenuItem, Stack, Tab, TablePagination, Tabs, Typography, useTheme } from '@mui/material'
import { AppId, AppTextFieldHandler } from '../../app/appTypes'
import { Skeleton } from '@mui/material';
import { Requirement, selectRequirementsForStructureNode} from './RequirementsDictSlice'
import { RequirementType, selectAllRequirementsTypes, selectRequirementTypeById } from './RequirementsTypesSlice'
import { BottomAppBar } from '../../app/BottomAppBar' 
import { LoadRequirements, LoadRequirementsTypes, LoadRequirementTestMappings } from '../../app/AppDataLoader'
import { If } from '../../app/If'
import { toolbarMinHeight } from '../..'
import { StructureTableCell } from '../device/Structure'
import { selectModule } from '../modules/moduleSlice';
import { selectTestIdByRequirementId } from './requirementTestSlice'
import { AppLink } from '../../app/AppLink'
import { CreateUpdateRequirement } from './dialogs/CreateUpdateRequirement'
import { DeleteRequirement } from './dialogs/DeleteRequirement'
import { AssociateTest } from './dialogs/AssociateTest'
import { showError, showSuccess } from '../notifications/notificationsSlice'
import { DeleteRequirementTest } from './dialogs/DeleteRequirementTest'
import JoinInnerIcon from '@mui/icons-material/JoinInner'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import NoteAddIcon from '@mui/icons-material/NoteAdd'
import LinkIcon from '@mui/icons-material/Link'

const RequirementTypeLabel = ({ id }: { id: AppId }) => {
    const { code, name } = useAppSelector(state => selectRequirementTypeById(state, id)) as RequirementType
    return <span>{code} - {name}</span>
}

export const RequirementsDictList = () => {
    const contextStructureId = useQueryStructureId()
    return <LoadRequirementsTypes component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
        <LoadRequirements component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
            <LoadRequirementTestMappings component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
                <If 
                    condition={contextStructureId !== undefined} 
                    otherwise={<Alert severity="warning"><Localized id="device-type-not-selected">Nie wybrano typu urządzenia</Localized></Alert>}
                >
                    <Requirements contextStructureId={contextStructureId ?? ""} />
                </If>
            </LoadRequirementTestMappings>
        </LoadRequirements>
    </LoadRequirementsTypes>
}

type DialogState = 
    | { type: "none" }
    | { type: "delete", requirementId: AppId }
    | { type: "createUpdate", requirementId: AppId | undefined, requirementTypeId: AppId | undefined }
    | { type: "assocTest", requirementId: AppId }
    | { type: "deleteAssoc", requirementId: AppId }

const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])
    const onError = useCallback(() => {
        setter({ type: "none" })
        dispatch(showError("error"))
    }, [setter, dispatch])

    switch (state.type) {
        case "none":
            return null
        case "delete":
            return <DeleteRequirement
                requirementId={state.requirementId}
                onSuccess={onSuccess}
                onCancel={onCancel}
                onError={onError}
            />
        case "createUpdate":
            return <CreateUpdateRequirement
                id={state.requirementId} 
                defaultDeviceTypeId=""
                defaultRequirementTypeId={state.requirementTypeId ?? ""}
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
            />
        case "assocTest":
            return <AssociateTest 
                requirementId= {state.requirementId}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onError={onError}
            />
        case "deleteAssoc":
            return <DeleteRequirementTest
                requirementId={state.requirementId}
                onCancel={onCancel}
                onSuccess={onSuccess}
                onError={onError}
            />
    }
}

export const Requirements = ({ contextStructureId }: { contextStructureId: AppId }) => {
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const isSuper = currentModule?.code === "super"

    const theme = useTheme()
    const allRequirementTypes = useAppSelector(selectAllRequirementsTypes)

    const [contextReqTypeId, setContextReqTypeId] = useState<AppId | undefined>(allRequirementTypes.length > 0 ? allRequirementTypes[0].id : undefined)
    const handleReqTypeTabClick = useCallback((_, newReqTypeId) => {
        setContextReqTypeId(newReqTypeId)
        setPage(0)
    }, [])

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)
    const allRequirements = useAppSelector(state => selectRequirementsForStructureNode(state, contextStructureId))
    const requirements = useMemo(() => {
        if (contextReqTypeId !== undefined) {
            return allRequirements.filter(entity => entity.typeId === contextReqTypeId)
        } else {
            return []
        }

    }, [allRequirements, contextReqTypeId])
    const reqPage = requirements.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
    const reqCount = requirements.length
  
    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [ setPage ])
    
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }, [ setPage, setRowsPerPage ])

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const handleAddNewClick = useCallback(() => {
        setDialog({
            type: "createUpdate",
            requirementId: undefined,
            requirementTypeId: contextReqTypeId,
        })
    }, [setDialog, contextReqTypeId])

    const handleEditRequirementClick = useCallback((requirementId: AppId) => {
        setDialog({
            type: "createUpdate",
            requirementId,
            requirementTypeId: contextReqTypeId,
        })
    }, [setDialog, contextReqTypeId])

    const handleAssociateTest = useCallback((requirementId: AppId) => {
        setDialog({
            type: "assocTest",
            requirementId,
        })
    }, [])
    const handleDeleteAssociation = useCallback((requirementId: AppId) => {
        setDialog({
            type: "deleteAssoc",
            requirementId,
        })
    }, [])

    const tabs = useMemo(() => allRequirementTypes.map(({ id, code, }) => <Tab key={id} value={id} label={code} />), [allRequirementTypes])

    return <>
        <Box sx={{
            height: `calc(100vh - ${theme.spacing(6)} - ${2 * toolbarMinHeight}px)`,
            flexGrow: 1, 
            bgcolor: 'background.paper', 
            display: 'flex',
        }}>
            <Tabs 
                orientation="vertical"
                variant="standard"
                value={contextReqTypeId}
                onChange={handleReqTypeTabClick}
                sx={{
                    borderRight: 1,
                    borderColor: 'divider',
                }}>{tabs}</Tabs>
            <Stack sx={{
                width: '100%',
                paddingLeft: 2,
            }} direction="column" spacing={2}>
                <Typography variant="subtitle2">
                    <If condition={contextReqTypeId !== undefined}
                        otherwise={<></>}
                    >
                        <RequirementTypeLabel id={contextReqTypeId as string} />
                    </If>
                </Typography>
                <TableContainer component={Paper}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                <TableCell sx = {{minWidth:120, width:120, maxWidth:120}}>
                                    <Localized id="requirements-code">Kod</Localized>
                                </TableCell>
                                <TableCell>
                                    <Localized id="requirements-name">Nazwa</Localized>
                                </TableCell>
                                <TableCell>
                                    <Localized id="urs-device-type">Typ urządzenia</Localized>
                                </TableCell>
                                <TableCell>
                                    <Localized id="system-item">
                                        <span>Systemowy</span>
                                    </Localized>
                                </TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {reqPage.map(r =>
                                <TableRow key={r.id}>                        
                                    <TableCell>{r.code}</TableCell>                                   
                                    <TableCell><div dangerouslySetInnerHTML={{ __html: r.name }} /></TableCell>
                                    <TableCell>
                                        <StructureTableCell structureId={r.structureId} />
                                    </TableCell>
                                    <TableCell>
                                        <Checkbox defaultChecked={r.isSystem} disabled />
                                    </TableCell>
                                    <TableCell align="right">
                                        <ButtonGroup>
                                            <TestButtonMenu 
                                                requirement={r}
                                                isSuper={isSuper}
                                                associateTest={handleAssociateTest}
                                                deleteAssociation={handleDeleteAssociation}
                                            />
                                            <Button onClick={() => handleEditRequirementClick(r.id)} color="secondary" variant="outlined" disabled={!isSuper && r.isSystem}>
                                                <Localized id="edit">
                                                    <span>Edytuj</span> 
                                                </Localized>
                                            </Button> 
                                            <Button color="primary"  onClick={() => setDialog({ type: "delete", requirementId: r.id })} disabled={!isSuper && r.isSystem}>
                                                <Localized id="delete">
                                                    <span>Usuń</span>
                                                </Localized>
                                            </Button>                                           
                                        </ButtonGroup>
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Stack>
        </Box>
        <Dialog open={dialog.type !== "none"} fullWidth maxWidth="md" keepMounted={false}>
            <DialogContentDispatcher state={dialog} setter={setDialog} />
        </Dialog>
        <BottomAppBar>
            <Fab onClick={handleAddNewClick} color="secondary">
                <AddIcon />
            </Fab>
            <TablePagination
                sx={{ marginLeft: "auto"}}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={reqCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </BottomAppBar>
    </>
}

interface TestButtonManuProps {
    requirement: Requirement
    isSuper: boolean
    associateTest: (requirementId: AppId) => void
    deleteAssociation: (requirementId: AppId, testId: AppId) => void
}
const TestButtonMenu = ({ requirement, isSuper, associateTest, deleteAssociation  }: TestButtonManuProps) => {
    const { id: requirementId } = requirement
    const mapping = useAppSelector(state => selectTestIdByRequirementId(state, requirementId))
    const noMapping = mapping === undefined 
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])
    const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])
    const handleAssociateTest = useCallback(() => {
        associateTest(requirementId)
        setAnchorEl(null)
    }, [requirementId, setAnchorEl, associateTest])
    const handleDeleteAssoc = useCallback(() => {
        if (mapping) {
            deleteAssociation(requirementId, mapping.testId)
            setAnchorEl(null)
        } 
    }, [requirementId, deleteAssociation, setAnchorEl, mapping])

    if (!isSuper) return null

    return <Box>
        <Button color="secondary" variant={mapping ? "contained" : "outlined"} onClick={handleClick}>Test</Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {[noMapping && <MenuItem key="create">
                    <ListItemIcon><NoteAddIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>
                        <Link 
                            component={AppLink} 
                            to={{ pathname: "/sys/validation/new", 
                                  search: `?requirementId=${requirement.id}`,
                                  state: { defaultName: requirement.name },
                               }}
                        >Utwórz nowy test</Link>
                    </ListItemText>
                </MenuItem>
            , noMapping && <MenuItem key="assoc" onClick={handleAssociateTest}>
                    <ListItemIcon><JoinInnerIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Skojarz z testem</ListItemText>
                </MenuItem>
            , mapping   && <MenuItem key="deleteAssoc" onClick={handleDeleteAssoc}>
                    <ListItemIcon><DeleteForeverIcon fontSize="small" color="error" /></ListItemIcon>
                    <ListItemText>Usuń skojarzenie</ListItemText>
                </MenuItem>
            , mapping   && <MenuItem key="goToTest">
                    <ListItemIcon><LinkIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>
                        <Link component={AppLink} to={{ pathname: `/sys/validation/edit/${mapping.testId}` }}>Przejdź do testu</Link>
                    </ListItemText>
                </MenuItem>
            ]}
        </Menu>
    </Box>
}
