import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { selectUsers, selectAllUsers, loadUsers, User, } from "./usersSlice"
import { useEffect, useState } from "react"
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link } from "react-router-dom"
import { FlexDiv } from "../../app/Utils"
import { LoadCompanies } from "../../app/AppDataLoader"
import { ButtonGroup, Skeleton } from "@mui/material"
import { selectCompanyById } from "../company/companiesSlice"
import { store } from "../../app/store"
import { UserStatusLabel } from "./UserStatusLabel"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../settings/settingsSlice"
import { useNavigate } from "react-router-dom"
import { LoadingContainer } from "../../app/LoadingContainer"

export const CompanyUsers = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const { items } = useAppSelector(selectUsers);
    const { status } = items;
    const allUsers = useAppSelector(selectAllUsers);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => { 
        if (status.type === "empty") {
            setIsLoading(true);
            dispatch(loadUsers());
        }
        
        if(status.type === "loaded") {
            setIsLoading(false)
        }
    }, [dispatch, status]);

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/company/user/edit/${id}`)
    };

    const columns: GridColDef<User, any, any>[] = [
        {
            field: 'userName',
            headerName: l10n.getString("username", null, "username"),
            width: 300
        },
        {
            field: 'firstName',
            headerName: l10n.getString("user-first-name", null, "Imię"),
            flex: 1,
        },
        {
            field: 'lastName',
            headerName: l10n.getString("user-last-name", null, "Nazwisko"),
            flex: 1,
        },
        {
            field: 'companyId',
            headerName: l10n.getString("user-company", null, "Firma"),
            flex: 1,
            valueGetter: (_, row) => {
                const entity = selectCompanyById(store.getState(), row.companyId)

                if (!entity) {
                    return ""
                } else {
                    return entity.shortName
                }
            }
        },
        {
            field: 'position',
            headerName: l10n.getString("user-position", null, "Stanowisko"),
            width: 300
        },
        {
            field: 'status',
            headerName: l10n.getString("user-status", null, "Status"),
            width: 200,

            valueGetter: (_, row) => {
                var status = row.status.toUpperCase();
                switch (status) {
                    case "LOCKED":
                        return l10n.getString("user-status-locked", null, "")
                    case "NOT_CONFIRMED":
                        return l10n.getString("user-status-not-confirmed", null, "")
                    case "ACTIVE":
                        return l10n.getString("user-status-active", null, "")
                    default:
                        return ''
                }
            },
            renderCell: (params) => <UserStatusLabel status={params.row.status} />
        },
        {
            field: 'actions', type: 'actions', width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            onClick={() => handleGoToClick(params.row.id)}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]
    
    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (        
        <LoadCompanies component={<Skeleton variant="rectangular" />}>
             <TableContainer component={Paper}>
                <DataGrid localeText={getCustomLocaleText()}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    rows={allUsers}
                    columns={columns}
                    initialState={{
                        sorting: { sortModel: [{ field: 'userName', sort: 'asc' }] }
                    }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
            </TableContainer>
            <FlexDiv>
                <Fab component={Link} to="/company/user/create" color="secondary"  sx={{marginTop:2}}>
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </LoadCompanies>        
    );
}
