import { useLocalization } from "@fluent/react"
import { Box } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { GridApiCommunity } from "@mui/x-data-grid/internals"
import { MutableRefObject, useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadProjects, selectAllProjects, selectProjects } from "./projectSlice"

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export interface ProjectsBrowserProps {
    gridRef: MutableRefObject<GridApiCommunity>
}

export const ProjectsBrowser = (props: ProjectsBrowserProps) => {
    const { gridRef } = props
    const { loaded } = useAppSelector(selectProjects)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const allProjects = useAppSelector(selectAllProjects)

    useEffect(() => {
        if (!loaded) {
            dispatch(loadProjects())
        }
    }, [dispatch, loaded])

    const handleRowSelection = (params) => {
        gridRef.current.selectRow(params.id, true, true);
    }

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'no',
                headerName: l10n.getString("project-no", null, "Numer projektu"),
                width: 300
            },
            {
                field: 'name',
                headerName: l10n.getString("project-title", null, "Tytuł projektu"),
                flex: 1,
            }
        ]
    }, [])

    return (
        <DataGrid
            apiRef={gridRef}
            columns={columns}
            rows={allProjects.filter(f => f.status !== "C" && f.status !== "F" && f.status !== "H")}
            slots={{ toolbar: QuickSearchToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: false,
                }
            }}
            initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            onRowClick={handleRowSelection}
            getRowClassName={(params) => params.id === gridRef.current.getSelectedRows()[0] ? 'Mui-selected' : ''}
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
        />
    )
}
