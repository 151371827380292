import { useLocalization } from "@fluent/react"
import { Box } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { GridApiCommunity } from "@mui/x-data-grid/internals"
import { MutableRefObject, useEffect, useMemo } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadCompanies, selectAllCompanies, selectCompanies } from "./companiesSlice"

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export interface CompaniesBrowserProps {
    gridRef: MutableRefObject<GridApiCommunity>
}

export const CompaniesBrowser = (props: CompaniesBrowserProps) => {
    const { gridRef } = props
    const { loaded } = useAppSelector(selectCompanies)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const allCompanies = useAppSelector(selectAllCompanies)

    useEffect(() => {
        if (!loaded) {
            dispatch(loadCompanies())
        }
    }, [dispatch, loaded])

    const handleRowSelection = (params) => {
        gridRef.current.selectRow(params.id, true, true);
    }

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'shortName',
                headerName: l10n.getString("companies-short-name", null, "Nazwa skrócona"),
                width: 300
            },
            {
                field: 'name',
                headerName: l10n.getString("companies-name", null, "Nazwa firmy"),
                flex: 1,
            }
        ]
    }, [])

    return (
        <DataGrid
            apiRef={gridRef}
            columns={columns}
            rows={allCompanies}
            slots={{ toolbar: QuickSearchToolbar }}
            slotProps={{
                toolbar: {
                    showQuickFilter: false,
                }
            }}
            initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            onRowClick={handleRowSelection}
            getRowClassName={(params) => params.id === gridRef.current.getSelectedRows()[0] ? 'Mui-selected' : ''}
            hideFooterSelectedRowCount
            disableRowSelectionOnClick
        />
    )
}
