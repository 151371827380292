import { useCallback, useEffect, useRef, useState } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, IconButton, Box, Divider, Tooltip, Link } from '@mui/material';
import { AppId } from '../../../../app/appTypes';
import { unwrapResult } from '@reduxjs/toolkit';
import { Localized } from '@fluent/react';
import { useAppDispatch } from '../../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../../../app/LoadingContainer';
import { ErrorContainer } from '../../../../app/ErrorContainer';
import ForwardIcon from '@mui/icons-material/Forward';
import CircleIcon from '@mui/icons-material/Circle';
import { BusinessCase, BusinessCaseStatus, loadBusinessCasesForProject } from '../../../businessCase/businessCaseSlice';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';

const EmptyWidgetNotice = () => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
                p={3}
                borderRadius={2}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    <Localized id='bc-empty-list'>Brak dokumentów</Localized> 
                </Typography>
                <FolderOpenIcon style={{ fontSize: 80, color: '#a0a0a0' }} />
                <Typography variant="body1" gutterBottom>
                    <Localized id='bc-empty-list-message'>Brak dokumentów</Localized> 
                </Typography>
            </Box>
        </>
    );
};


interface BusinessCaseWidgetProps {
    projectId: AppId;
}

export const BusinessCaseWidget = (props: BusinessCaseWidgetProps) => {
    const { projectId } = props

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [businessCases, setBusinessCases] = useState<BusinessCase[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [addNewDialogVisible, setAddNewDialogVisible] = useState(false);

    const activeItemsCount = businessCases.filter(x => x.status === 'DRAFT').length;
    const newItemGuid = useRef<string | null>(null);
    const newItemRef = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadBusinessCasesForProject(projectId))
            .then(unwrapResult)
            .then(setBusinessCases)
            .catch((error) => { console.log(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    useEffect(() => {
        if (newItemGuid.current && newItemRef.current) {
            newItemRef.current.scrollIntoView({ behavior: 'smooth' });
            newItemGuid.current = null;
        }
    }, [businessCases]);


    const handleAddNewItem = useCallback(() => {
        setAddNewDialogVisible(true)
    }, [setAddNewDialogVisible])

    const handleHideAddNewDialog = useCallback(() => setAddNewDialogVisible(false), [])


    const handleOpenDetails = (id: string) => {
        navigate(`/businessCase/edit/${id}`)
    };

    const generateHref = (projectId) => {
        return `/projects/edit/${projectId}?tab=businessCases`;
    };


    const getStatusColor = (status: BusinessCaseStatus | null) => {
        switch (status) {
            case 'DRAFT':
                return 'lightblue';
            case 'ON_HOLD':
                return 'grey';
            case 'APPROVED':
                return 'green';
            default:
                return 'grey';
        }
    };

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Paper sx={{ flexGrow: 1, padding: 2, height: '60vh', maxHeight: '60vh', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        <Localized id='bc-list'>Lista Business Case</Localized>
                    </Typography>
                </Box>
                <Divider />
                {
                    businessCases.length === 0 ? (<>                       
                        <EmptyWidgetNotice/>
                    </>) : (<></>)
                }
                <List sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: 1 }}>
                    {businessCases.map((bc) => (
                        <ListItem
                            key={bc.id}
                            ref={bc.id === newItemGuid.current ? newItemRef : null}

                            sx={{
                                backgroundColor: '#f5f5f5',
                                marginBottom: 2,
                                '&:hover': { backgroundColor: '#e0e0e0' },
                                borderRadius: 1,
                            }}
                            onDoubleClick={() => handleOpenDetails(bc.id)}
                        >
                            <ListItemText
                                primary={bc.title}
                                primaryTypographyProps={{ fontWeight: 'bold' }}
                                secondary={
                                    <>
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            <CircleIcon sx={{ color: getStatusColor(bc.status), mr: 1, fontSize: 'small' }} />
                                            <Typography component="span" variant="body2" fontWeight="bold">
                                                {bc.status}
                                            </Typography>
                                        </Box>
                                    </>
                                }
                            />
                            <Tooltip title={<Localized id='go'>Przejdz</Localized>}>
                                <IconButton edge="end" aria-label="details"
                                    sx={{
                                        color: 'default',
                                        '&:hover': {
                                            color: 'success.main',
                                        },
                                    }}
                                    onClick={() => handleOpenDetails(bc.id)}
                                >
                                    <ForwardIcon />
                                </IconButton>
                            </Tooltip>
                        </ListItem>
                    ))
                    }

                </List>
                <Divider />

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 }}>
                    <Link href={generateHref(projectId)} variant="body2">
                        <Localized id='go-to-list'>Przejdź do listy</Localized>
                    </Link>
                    <Typography variant="body2">
                        <Localized id='active'>Aktywne</Localized>:  {activeItemsCount} / {businessCases.length}
                    </Typography>
                </Box>
            </Paper>
        </>
    );
};


export default BusinessCaseWidget;