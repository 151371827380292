import { Localized } from "@fluent/react";
import {
    Button,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    Fab,
    TableBody,
    TablePagination,
} from "@mui/material";
import { Paper, Table, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { deleteDeviceType, loadDeviceTypes, selectAllDeviceTypes, selectDeviceTypes, selectTotalDeviceTypes } from "./deviceTypesSlice"
import AddIcon from '@mui/icons-material/Add'
import { useCallback, useEffect, useState } from 'react'
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { Skeleton } from '@mui/material';
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { AppLoader } from "../../app/AppLoader";
import { FlexDiv } from "../../app/Utils";


export const DeviceTypes = () => {
    const { state: deviceTypesState } = useAppSelector(selectDeviceTypes)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const deviceTypesPage = 
        useAppSelector(state => selectAllDeviceTypes(state).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    const deviceTypesCount = useAppSelector(selectTotalDeviceTypes)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (deviceTypesState.type === "empty") {
            dispatch(loadDeviceTypes())
        }
    }, [dispatch, deviceTypesState])
  
    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [ setPage ])
    
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }, [ setPage, setRowsPerPage ])

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                unwrapResult(await dispatch(deleteDeviceType(itemToDelete)))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }

            setDeleting(false)
            setItemToDelete(undefined)
        }
    }


    switch (deviceTypesState.type) {
        case "empty":
        case "loading":
        case "error":
            return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
        case "loaded":
            return (
                <>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx = {{minWidth:200, width:200, maxWidth:200}}>
                                        <Localized id="device-type-code">
                                            <span>Kod</span>
                                        </Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="device-type-name">
                                            <span>Nazwa</span>
                                        </Localized>
                                    </TableCell>
                                    <TableCell ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deviceTypesPage.map(({ id, code, name, }) => 
                                    <TableRow key={id}>
                                        <TableCell>{code}</TableCell>
                                        <TableCell>{name}</TableCell>
                                        <TableCell align="right">
                                            <ButtonGroup>
                                                <Button component={Link} to={`/deviceType/edit/${id}`} color="secondary" variant="outlined" >
                                                    <Localized id="edit">
                                                        <span>Edytuj</span>
                                                    </Localized>
                                                </Button>
                                                <Button onClick={() => handleDeleteClick(id)} >
                                                    <Localized id="delete">
                                                        <span>Usuń</span>
                                                    </Localized>
                                                </Button>
                                            </ButtonGroup>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <FlexDiv>
                        <Fab component={Link} to="/deviceType/new" color="secondary"  sx={{marginTop:2}}>
                            <AddIcon />
                        </Fab>
                        <TablePagination
                             sx={{ marginLeft: "auto"}}
                            rowsPerPageOptions={[5, 10, 25]}
                            component="div"
                            count={deviceTypesCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </FlexDiv>
                    <Dialog open={itemToDelete !== undefined}>
                        <DialogContent>
                            <DialogContentText>
                                <Localized id="confirm-delete">
                                    <span>Czy napewno chcesz usunąć?</span>
                                </Localized>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmDelClick}>
                                <Localized id="yes"><span>Tak</span></Localized>
                            </Button>
                            <Button onClick={handleCancelDelClick}>
                                <Localized id="no"><span>Nie</span></Localized>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <AppLoader open={deleting} />
                </>
            );
    }
}
