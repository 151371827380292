import { Box, Divider, IconButton, Link, Skeleton, Toolbar, Typography } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import { ReactNode, useCallback } from "react"
import MenuIcon from "@mui/icons-material/Menu"
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Authenticate } from "../features/user/Authenticate"
import { Nav } from "./Nav"
import { Localized } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "./hooks";
import { selectSettings, setLeftMenuOpen } from "../features/settings/settingsSlice";
import { LoggedUserCard } from "../features/user/LoggedUserCard";
import { DeviceTypeContext } from "../features/device/DeviceTypeContext";
import { AnyModule, ModuleCode, selectModule } from "../features/modules/moduleSlice";
import { CheckModuleAccess } from "../features/modules/ModuleAccessible";
import { LoadStructure } from "./AppDataLoader";
import { AppDialogDispatcher } from "../features/dialogs/AppDialogDispatcher";
import { If } from "./If";
import NotificationsBell from "./NotificationsBell";

export const drawerWidth = 240

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

type AppContainerProps = {
  module: ModuleCode | AnyModule,
  children: ReactNode,
  title: string;
}

export const AppContainer = (props: AppContainerProps) => {
    const { children, title, module } = props;
    const dispatch = useAppDispatch()

    const theme = useTheme();
    const open = useAppSelector(state => selectSettings(state).leftMenuOpen)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    const handleDrawerClose = useCallback(() => dispatch(setLeftMenuOpen(false)), [dispatch])
    const handleDrawerOpen = useCallback(() => dispatch(setLeftMenuOpen(true)), [dispatch])

    const handleSupportClick = async () => {
      window.open(`${process.env.REACT_APP_SUPPORT}`, '_blank')
  }

    return (
        <Authenticate>
            <CheckModuleAccess code={module}>
              <Box sx={{ display: 'flex',  flexGrow: 1  }}>
                  <AppBar position="fixed" open={open}>
                      <Toolbar>
                          <IconButton
                              color="inherit"
                              edge="start"
                              onClick={handleDrawerOpen}
                              sx={{
                                  marginRight: '36px',
                                  ...(open && { display: 'none' }),
                              }}
                          >
                              <MenuIcon /> 
                          </IconButton>
                          <Box sx={{ flexGrow: 1}}>
                            <Typography variant="h6" noWrap component="div">                          
                                <Localized id={title}>
                                    <span>Page title</span>
                                </Localized>
                            </Typography>
                            <ModuleInfo />
                          </Box>
                          <Box sx={{ flexGrow: 1, }}>
                            <If condition={currentModule !== null && currentModule.code !== 'project'}>
                                <LoadStructure component={<Skeleton variant="text" />}>
                                    <DeviceTypeContext />
                                </LoadStructure>
                            </If>
                          </Box>
                          {/* <Box>
                            <NotificationsBell></NotificationsBell>
                          </Box> */}
                          <LoggedUserCard  />
                        
                      </Toolbar>
                  </AppBar> 
                  <Drawer variant="permanent" open={open} >
                      <DrawerHeader>
                          <IconButton onClick={handleDrawerClose}>
                              {theme.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
                          </IconButton>
                      </DrawerHeader>
                      <Divider />
                      <Nav></Nav>
                      <div style={{display: 'flex', height: '100vh'}}></div>
                      <img  style={{transform: 'scale(0.75)'}}  src={process.env.PUBLIC_URL + '/logoSmall.png'}  alt="Logo" />
                      <Link component="button" variant="body2" underline="hover"  sx={{marginBottom:'10px'}} onClick={handleSupportClick}>SUPPORT</Link>
                  </Drawer>
                  <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                      <DrawerHeader />
                      {children}
                      <AppDialogDispatcher />
                  </Box>
              </Box>
            </CheckModuleAccess>
        </Authenticate>
    );
}

const ModuleInfo = () => {
  const module = useAppSelector(state => selectModule(state).currentModule)

  return <Typography variant="caption" noWrap component="div">                          
        <span>{module?.name ?? ""}</span>
    </Typography>
}
