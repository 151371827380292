import { useAppDispatch, useAppSelector, useQueryStructureId } from "../../app/hooks"
import { copyValidation, copySysValidation, deleteSysValidation, deleteValidation, selectAllValidations, selectAllValidationsFamily, Validation } from "./validationsSlice"
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Localized, useLocalization } from "@fluent/react"
import { Link } from 'react-router-dom'
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useCallback, useState } from "react"
import { Skeleton } from '@mui/material'
import { LoadingButton } from "@mui/lab"
import { AppId } from "../../app/appTypes"
import { FlexDiv } from "../../app/Utils"
import { LoadValidations } from "../../app/AppDataLoader"
import { selectModule } from "../modules/moduleSlice"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { If } from "../../app/If"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { selectStructureNodeById } from "../device/structureSlice"
import { store } from "../../app/store"
import { useNavigate } from "react-router-dom"

export const Validations = () => {
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [itemToCopy, setItemToCopy] = useState<AppId | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)
    const [copying, setCopying] = useState(false)
    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const dispatch = useAppDispatch()
    const navigate = useNavigate();
    const { l10n } = useLocalization()

    const structureId = useQueryStructureId()
    const validations = useAppSelector(state => {
        if (structureId === undefined) {
            return selectAllValidations(state)

        } else {
            return selectAllValidationsFamily(state, structureId)
        }
    })


    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleCopyClick = useCallback((id: AppId) => {
        setItemToCopy(id)
    }, [])

    const handleCopyCancelClick = useCallback(() => {
        setItemToCopy(undefined)
    }, [])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                if (module === "super") {
                    await dispatch(deleteSysValidation(itemToDelete))
                } else {
                    await dispatch(deleteValidation(itemToDelete))
                }

                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    const handleConfirmCopyClick = async () => {
        if (itemToCopy) {
            setCopying(true)
            let newId;
            try {

                if (module === "super") {
                    newId = await (await dispatch(copySysValidation(itemToCopy)).unwrap()).id;
                } else {
                    newId = await (await dispatch(copyValidation(itemToCopy)).unwrap()).id;
                }

                dispatch(showSuccess("copied"))

            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToCopy(undefined)
            setCopying(false)

            let editURL = ""
            if (module === "super") {
                editURL = structureId ? `/sys/validation/edit/${newId}?structureId=${structureId}` : `/sys/validation/edit/${newId}`
            } else {
                editURL = structureId ? `/validation/edit/${newId}?structureId=${structureId}` : `/validation/edit/${newId}`
            }
            
            if (newId) {
                navigate(editURL);
            }
        }
    }


    const urlPrefix = module === "super" ? "/sys" : ""
    const newUrl = structureId ? `${urlPrefix}/validation/new?structureId=${structureId}` : `${urlPrefix}/validation/new`

    const readOnly = useCallback((system: boolean): boolean => module === "super" ? false : system, [module])
      
    const columns: GridColDef<Validation, any, any>[] = [
        {
            field: 'name',
            headerName: l10n.getString("validation-name", null, "Nazwa"),
            flex: 1 ,
            renderCell: (params) => (
                <div dangerouslySetInnerHTML={{ __html: params.value }} />
            ),
        },

        { field: 'stage', 
            headerName: l10n.getString("validation-stage", null, "Etap"), 
            width: 128,
            type: "singleSelect",
            valueOptions:["IQ","OQ","PQ","DQ"]
        },
        {
            field: 'structureId', 
            headerName: l10n.getString("structure-node-device-type", null, "Typ urządzenia"), 
            width: 200,
            valueGetter: (value, row) => {
                const structure = selectStructureNodeById(store.getState(),  row.structureId)
                if (structure === undefined) {
                    return "";
                }
                const { name } = structure
                return name;
            }
        },
        { field: 'isSystem', type: 'boolean', headerName: l10n.getString("validation-system", null, "Systemowy"), width: 160 },
        {
            field: 'actions', type: 'actions', width: 250,
            renderCell: (cell) => {
                return (
                    <ButtonGroup size="small">
                        <If condition={!readOnly(cell.row.isSystem)}>
                            <Button
                                style={{ width: 75 }}
                                component={Link}
                                to={`${urlPrefix}/validation/edit/${cell.row.id}?structureId=${cell.row.structureId}`}
                                color="secondary"
                                variant="outlined" >
                                <Localized id="edit">
                                    <span>Edytuj</span>
                                </Localized>
                            </Button>
                        </If>
                        <If condition={readOnly(cell.row.isSystem)}>
                            <Button 
                                style={{ width: 75 }}
                                color="secondary"
                                variant="outlined"
                                component={Link} to={`${urlPrefix}/validation/preview/${cell.row.id}?structureId=${cell.row.structureId}`}>
                                <Localized id="go-to">
                                    <span>go-to</span>
                                </Localized>
                            </Button>
                        </If>
                        <Button  
                            style={{ width: 75 ,marginRight:4 }}
                            onClick={() => handleCopyClick(cell.row.id)} 
                        >
                            <Localized id="copy">
                                <span>Kopiuj</span>
                            </Localized>
                        </Button>
                        <Button 
                            style={{ width: 75 }}
                            disabled={readOnly(cell.row.isSystem)} 
                            onClick={() => handleDeleteClick(cell.row.id)} 
                            >
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    return <LoadValidations component={<Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>}>

        <TableContainer component={Paper}>
            <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 200px)' }} 
                rows={validations} 
                columns={columns} 
                getRowHeight={() => 'auto'}
            initialState={{
                sorting:{sortModel:[{field:'name', sort:'asc'}]}
            }}
            />
        </TableContainer>
        <FlexDiv>
            <Fab component={Link} to={newUrl} color="secondary" sx={{ marginTop: 2 }} >
                <AddIcon />
            </Fab>
        </FlexDiv>


        <Dialog open={itemToDelete !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={deleting} onClick={handleConfirmDelClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <Dialog open={itemToCopy !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-copy">
                        <span>Czy napewno chcesz utworzyć kopię testu?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={copying} onClick={handleConfirmCopyClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={copying} onClick={handleCopyCancelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </LoadValidations>
}
