import { useAppSelector } from "../../app/hooks"
import { RootState } from "../../app/store"
import { HelpDialog } from "./HelpDialog"

export const AppDialogDispatcher = () => {
    const dialogState = useAppSelector((state: RootState) => state.dialog)

    switch (dialogState.tag) {
        case 'none': return null
        case 'help': return <HelpDialog {...dialogState.props} />
    }
}

