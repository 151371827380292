import { Chip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useAppSelector } from '../../app/hooks';
import { selectValidationById } from './validationsSlice';

export const ValidationTooltip = styled(({ className, ...props }: Omit<TooltipProps, 'title'> & { testId: string }) => {
    const { testId, ...tooltipProps } = props
    const test = useAppSelector(state => selectValidationById(state, testId))
    const title = test ? test.name : '...'
    const stage = test ? test.stage : '...'
    return  ( <Tooltip {...tooltipProps} classes={{ popper: className }} title={<><span>{title}</span>&nbsp;<Chip size='small' label={stage} variant='outlined' /></>} />)
}
)(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 420,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));
