import { ChangeEvent } from "react"
import { RootState } from "./store"


export type Nullable<T> = {
    [P in keyof T] : T[P] | null;
}

export interface Problem {
    detail: string 
    status: number 
    title: string 
    traceId: string 
    type: string
}

export type APIError = 
    | { kind: "http", status: number, body?: string, problem?: Problem, }
    | { kind: "connection" }
    | { kind: "unknown" }

export const unknownError = (): APIError => { 
    return { kind: "unknown" }
}

export type AppDataState = 
    | { type: "empty" }
    | { type: "loaded" }
    | { type: "loading", requestId: string, }
    | { type: "error", error: APIError, }

export type AppId = string

export type AppTextFieldHandler = (e: ChangeEvent<HTMLInputElement>) => void

export type AppThunkAPIType = {
    state: RootState,
    rejectValue: APIError,
}