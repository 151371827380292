import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";
import { ProjectProcessHeader } from "./projectProcess/ProjectProcessApiClient";

export type ProjectStatus = 'D' | 'I' | 'H' | 'F' | 'C'

export interface ProjectHeader {
    id: AppId,
    no: string,
    name: string,
    description: string,
    generalContractor: string,
    investor: string,
    guid: string,
    createdBy: string,
    createDate: Date,
    modifiedBy: string,
    modifyDate: Date,
    companyId: number,
    companyName: string,
    status: string,
}

export type Project = ProjectHeader & {
    process: ProjectProcessHeader[]
    projectTeamMembers: ProjectTeamMember[]
}

export interface ProjectTeamMember {
    id: AppId,
    user: string,
    firstName: string,
    lastName: string,
    position: string,
    companyName: Date,
    role: string,
    projectId: number
}

export interface CreateProjectPayload {
    no: string
    name: string
    generalContractor: string
    investor: string
    businessCaseId: string | null
}


export interface CreateProcessPayload {
    projectId: string | null
    no: string
    name: string
}

export interface CreateProjectTeamMemberPayload {
    user: string,
    projectId: string,
    role: string
}

export interface InviteProjectTeamMemberPayload {
    userEmail: string,
    projectId: string,
    role: string
}

export interface Action {
    id: AppId,
    actionId: string,
    status?: string
    type: string,
    authorize: boolean
}

export interface StatusResponse {
    status: string
}

export interface ExtendedStatusResponse extends StatusResponse {
    id: AppId
}

export interface ProjectActionArgs {
    id: AppId,
    args: { password: string }
}

export interface ProjectStatusActionArgs {
    id: AppId,
    args: { password: string } & StatusResponse
}


const adapter = createEntityAdapter<ProjectHeader>({
    selectId: (project) => project.id,
})

export type ProjectsState = EntityState<ProjectHeader> & { loaded: boolean }

const initialState: ProjectsState = adapter.getInitialState({ loaded: false })


export const loadProjects = createAsyncThunk<ProjectHeader[], void, AppThunkAPIType>(
    "projects/load", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/project" }))
        const { payload } = result

        if (getWithAuth.fulfilled.match(result)) {
            return payload as ProjectHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadProject = createAsyncThunk<Project, AppId, AppThunkAPIType>(
    "projects/loadOne", async (id: AppId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/project/${id}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Project
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const createProject = createAsyncThunk<ProjectHeader, CreateProjectPayload, AppThunkAPIType>(
    "projects/create",
    async (createProjectPayload: CreateProjectPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/project",
            payload: createProjectPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createProcess = createAsyncThunk<ProjectProcessHeader, CreateProcessPayload, AppThunkAPIType>(
    "process/create",
    async (createProcessPayload: CreateProcessPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/project-process",
            payload: createProcessPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectProcessHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const updateProject = createAsyncThunk<ProjectHeader, ProjectHeader, AppThunkAPIType>(
    "projects/update",
    async (project: ProjectHeader, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/project/${project.id}`,
            payload: project,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return project
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export interface DescriptionFieldPayload {
    fieldName: string
    text: string
}

export const updateProjectDescriptionField = createAsyncThunk<ProjectHeader, { id: AppId, data: DescriptionFieldPayload }, AppThunkAPIType>(
    "projects/updateDescriptionField",
    async ({ id, data }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/project/${id}/descriptionfields`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as ProjectHeader
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteProject = createAsyncThunk<AppId, ProjectActionArgs, AppThunkAPIType>(
    "projects/delete",
    async ({ id, args }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ url: `api/project/${id}`, payload: args ?? {} }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateProjectStatus = createAsyncThunk<ExtendedStatusResponse, ProjectStatusActionArgs, AppThunkAPIType>(
    "projects/update-status",
    async ({ id, args }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/project/${id}/status`,
            payload: args ?? {},
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return { id, ...payload } as ExtendedStatusResponse
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createTeamMember = createAsyncThunk<ProjectTeamMember, CreateProjectTeamMemberPayload, AppThunkAPIType>(
    "project-team-member/create",
    async (createPayload: CreateProjectTeamMemberPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/project-team-member",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectTeamMember
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const inviteTeamMember = createAsyncThunk<ProjectTeamMember, InviteProjectTeamMemberPayload, AppThunkAPIType>(
    "project-team-member/invite",
    async (invitePayload: InviteProjectTeamMemberPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/project-team-member/invite",
            payload: invitePayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProjectTeamMember
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const deleteTeamMember = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "project-team-member/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/project-team-member/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteProcess = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "process/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/project/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const projectSlice = createSlice({
    name: "projects",
    initialState,
    reducers: {
        clearProjectsSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createProject.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })

        builder.addCase(deleteProject.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })

        builder.addCase(updateProject.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<ProjectHeader, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(updateProjectDescriptionField.fulfilled, (state, action) => {
            const projectToUpdate = state.entities[action.payload.id]
            if (projectToUpdate) {
                projectToUpdate.description = action.payload.description
                projectToUpdate.generalContractor = action.payload.generalContractor
                projectToUpdate.investor = action.payload.investor
                projectToUpdate.modifiedBy = action.payload.modifiedBy
                projectToUpdate.modifyDate = action.payload.modifyDate
            }
        })

        builder.addCase(updateProjectStatus.fulfilled, (state, action) => {
            const projectToUpdate = state.entities[action.payload.id]
            if (projectToUpdate) {
                projectToUpdate.status = action.payload.status;
            }
        })

        // builder.addCase(createProcess.fulfilled, (state, action) => {
        //     const projectToUpdate = state.entities[action.payload.projectId.toString()]
        //     if (projectToUpdate) {
        //         projectToUpdate.process.push(action.payload)
        //     }
        // })

        // builder.addCase(createTeamMember.fulfilled, (state, action) => {
        //     const projectToUpdate = state.entities[action.payload.projectId.toString()]
        //     if (projectToUpdate) {
        //         projectToUpdate.projectTeamMembers.push(action.payload)
        //     }
        // })


        builder.addCase(loadProjects.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    }
})


export const { clearProjectsSlice } = projectSlice.actions

export const selectProjects = (state: RootState): ProjectsState => state.projects

export const
    { selectAll: selectAllProjects
        , selectById: selectProjectById
        , selectTotal: selectTotalProjects
    } = adapter.getSelectors<RootState>(selectProjects)

export default projectSlice.reducer;
