
export function groupBy<T>(xs: T[], fn: (x: T) => string): { [group: string]: T[] } {
    const xs_v1 = xs.map(x => { return { group: fn(x), value: x} })

    let bucket: { [group: string]: T[] } = {}
    xs_v1.map(({ group, value }) => {
        if (bucket[group] !== undefined) {
            const items = bucket[group] as T[] // forcing T beacuse we are sure key is in Map
            items.push(value)
            bucket[group] = items
        } else {
            bucket[group] = [value]
        }
    })
    return bucket
}

export function nub<T>(xs: T[]): T[] {
    return Array.from(xs.reduce((acc, x) => acc.add(x), new Set<T>()).values())
}

export function compareNum(a: number, b: number) {
    if (a < b) return -1
    if (a > b) return  1
               return  0
}
