import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, Container, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit"
import { useCallback, useEffect, useState } from "react"
import { LoadCompanies } from "../../app/AppDataLoader"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { Company, selectAllCompanies } from "../company/companiesSlice"
import { showError } from "../notifications/notificationsSlice"
import { activateModule, deactivateModule, loadAllCompanyModules, ModuleActive } from "./moduleSlice"
import produce from "immer"
import { LoadingContainer } from "../../app/LoadingContainer"

const adapter = createEntityAdapter<ModuleActive>({
    selectId: m => m.moduleId,
})
const { selectAll } = adapter.getSelectors()

interface ModuleAccessTableProps {
    companyId: string | undefined;
}

export const ModuleAccessTable = (props: ModuleAccessTableProps) => {
    const dispatch = useAppDispatch()

    const [activeModules, setActiveModules] = useState<EntityState<ModuleActive>>(adapter.getInitialState())
    const [activating, setActivating] = useState<Set<AppId>>(new Set())
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const loadActiveModules = async () => {
            if (props.companyId !== undefined) {
                setLoading(true)
                try {
                    return await dispatch(loadAllCompanyModules(props.companyId)).unwrap()
                } catch (error) {
                    return []        
                } finally {
                    setLoading(false)
                }
            }
            return []
        }
        loadActiveModules().then(x => setActiveModules(prev => adapter.setAll(prev, x)))
    }, [dispatch, props.companyId])

    const handleActivateClick = useCallback(async (moduleId: AppId, moduleCode: string) => {
        if (props.companyId !== undefined) {
            setActivating(produce(draft => {
                draft.add(moduleId)
            }))
            try {
                await dispatch(activateModule({
                    companyId: props.companyId,
                    moduleId,
                }))
            } catch (error) {
                showError("error")    
            }
            setActivating(produce(draft => {
                draft.delete(moduleId)
            }))
            setActiveModules(state => adapter.updateOne(state, {
                id: moduleId,
                changes: {
                    active: true,
                },
            }))
        }
    }, [dispatch, props.companyId])

    const handleDeactivateClick = useCallback(async (moduleId: AppId, moduleCode: string) => {
        if (props.companyId !== undefined) {
            setActivating(produce(draft => {
                draft.add(moduleId)
            }))
            try {
                await dispatch(deactivateModule({
                    companyId: props.companyId,
                    moduleId,
                }))
            } catch (error) {
                showError("error")    
            }
            setActivating(produce(draft => {
                draft.delete(moduleId)
            }))
            setActiveModules(state => adapter.updateOne(state, {
                id: moduleId,
                changes: {
                    active: false,
                },
            }))
        }
    }, [dispatch, props.companyId])

    return (
        <If condition={!loading} otherwise={<LoadingContainer/>}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Localized id="module-name">Nazwa modułu</Localized>
                                </TableCell>
                                <TableCell sx={{ width: '100px', }}>
                                    <Localized id="module-is-active">Atywny</Localized>
                                </TableCell>
                                <TableCell sx={{ width: '160px', }}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {selectAll(activeModules).map(({ moduleId: id, description, active, code }) => <TableRow key={id}>
                                <TableCell>{description}</TableCell>
                                <TableCell>
                                    <If condition={active} otherwise={<Localized id="no">Nie</Localized>}>
                                        <Localized id="yes">Tak</Localized>
                                    </If>
                                </TableCell>
                                <TableCell>
                                    <If condition={active} 
                                        otherwise={<LoadingButton 
                                                        loading={activating.has(id)} 
                                                        variant="outlined" 
                                                        color="success"
                                                        onClick={() => handleActivateClick(id, code)}>
                                                        <Localized id="module-activate"><span>Aktywuj</span></Localized>
                                                    </LoadingButton>}>
                                        <LoadingButton 
                                            loading={activating.has(id)} 
                                            variant="outlined" 
                                            color="error" 
                                            onClick={() => handleDeactivateClick(id, code)}>
                                            <Localized id="module-deactivate">
                                                <span>Deaktywuj</span>
                                            </Localized>
                                        </LoadingButton>
                                    </If>
                                </TableCell>
                            </TableRow>)}
                        </TableBody>
                    </Table>
                </TableContainer>
            </If>
    )
}

export const ModuleAccess = () => {
    const allCompanies = useAppSelector(selectAllCompanies)

    const [company, setCompany] = useState<Company | undefined>(undefined)

    const handleCompanyChange = useCallback((_: any, newCompany: Company | null) => {
        if (newCompany) {
            setCompany(newCompany)
        }
    }, [])

    return <Container component={Paper} sx={{
        padding: 4,
    }}>
        <Stack spacing={4}>
            <LoadCompanies component={<Skeleton />}>
                <Autocomplete 
                    sx={{
                        width: "400px",
                    }}
                    fullWidth={false}
                    options={allCompanies}
                    getOptionLabel={({ name }) => name}
                    value={company ?? null}
                    onChange={handleCompanyChange}
                    renderInput={params =>
                        <TextField {...params} />
                    }
                />
            </LoadCompanies> 
            <ModuleAccessTable companyId={company?.id} />
        </Stack>
    </Container>
}
