import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks"
import { updateDocumentNumber } from "../documentsApi";

export interface EditDocumentNumberDialogProps {
    documentId: AppId
    value: string
    onSuccess: (newValue: string) => void
    onCancel: () => void
}

export const EditDocumentNumberDialog = (props: EditDocumentNumberDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState(props.value)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateDocumentNumber({
            documentId: props.documentId,
            documentNumber: value,
        }))
            .then(unwrapResult)
            .then(() => { props.onSuccess(value) })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setSaving(false)
            })
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <TextField
                sx={{ mt: 2 }}
                fullWidth
                value={value}
                onChange={e => setValue(e.target.value)}
                label={<Localized id=''>Numer dokumentu</Localized>}
            />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}><Localized id='ok'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
