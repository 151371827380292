import { LocalizationProvider, ReactLocalization } from "@fluent/react"
import { FluentBundle, FluentResource } from "@fluent/bundle"
import { ReactNode, useEffect, useState } from "react"
import { useAppSelector } from "./hooks"
import { Locale, selectSettings } from "../features/settings/settingsSlice"
import { Empty } from "../Empty"

interface AppLocalizationProviderProps {
    children: ReactNode;
}

export const loadMessages = async (locale: Locale) => {
    const response = await fetch(`${process.env.PUBLIC_URL}/l10n/${locale}.ftl`, {
        cache: "no-cache",
    });
    const messages = await response.text();
    const resource = new FluentResource(messages);
    const bundle = new FluentBundle(locale);
    bundle.addResource(resource);

    return new ReactLocalization([bundle]);
}

export const AppLocalizationProvider = (props: AppLocalizationProviderProps) => {
    const [localization, setLocalization] = useState<ReactLocalization | undefined>(undefined);
    const { locale } = useAppSelector(selectSettings);

    useEffect(() => {
        loadMessages(locale).then(setLocalization);
    }, [locale]);

    if (localization === undefined) return (<Empty />);

    return ( <LocalizationProvider l10n={localization}>{props.children}</LocalizationProvider>);
} 