import {Localized, useLocalization} from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import {Link} from 'react-router-dom'
import {useAppDispatch, useAppSelector, useQueryStructureId} from '../../app/hooks'
import {useCallback, useMemo, useState} from 'react'
import {ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material'
import {AppId} from '../../app/appTypes'
import {Skeleton} from '@mui/material'
import {selectAllUrsTemplates, deleteUrsTemplate, deleteSysUrsTemplate} from './ursTemplatesSlice'
import {showError, showSuccess} from '../notifications/notificationsSlice'
import {unwrapResult} from "@reduxjs/toolkit"
import {AppLoader} from '../../app/AppLoader'
import {FlexDiv} from '../../app/Utils'
import {LoadStructure, LoadURSTemplates} from '../../app/AppDataLoader'
import {selectModule} from "../modules/moduleSlice"
import {selectStructureAndChildren} from '../device/structureRelationshipSlice'
import {DataGrid, GridColDef} from '@mui/x-data-grid'
import {selectStructureNodeById} from '../device/structureSlice'
import {store} from '../../app/store'
import { Urs } from '../urs/UrsSlice'

export const UrsTemplatesList = () => {
    return <LoadStructure component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
        <LoadURSTemplates component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
            <UrsTemplatesListInternal />
        </LoadURSTemplates>
    </LoadStructure>
}

const UrsTemplatesListInternal = () => {
    const dispatch = useAppDispatch()
    const { l10n } = useLocalization()
    const allURSTemplates = 
        useAppSelector(state => selectAllUrsTemplates(state))
    const structureId = useQueryStructureId()
    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const urlPrefix = module === "super" ? "/sys" : ""
    const isSuperModule = module === "super";
    const relationship = useAppSelector(state => selectStructureAndChildren(state, structureId ?? ""))

    const ursTemplates = useMemo(() => {
        if (structureId === undefined) {
            return allURSTemplates
        } else {
            return allURSTemplates.filter(({ deviceTypeId }) => relationship.has(deviceTypeId))
        }
    }, [allURSTemplates, relationship, structureId])
    
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {    
            setDeleting(true)

            try {
                if (isSuperModule){
                    unwrapResult(await dispatch(deleteSysUrsTemplate(itemToDelete)))
                }
                else {
                    unwrapResult(await dispatch(deleteUrsTemplate(itemToDelete)))
                }
               
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }

            setDeleting(false)
            setItemToDelete(undefined)
        }
    }

    const readOnly = useCallback((system: boolean): boolean => module === "super" ? false : system, [module])
    
    const columns: GridColDef<Urs, any, any>[] = [
        { 
            field: 'ursNo', 
            headerName: l10n.getString("urs-template-no", null, "Numer szablonu URS"), 
            width:200
        },
        {
            field: 'deviceTypeId', 
            headerName: l10n.getString("urs-device-type", null, "Typ urządzenia"), 
            width: 300,
            valueGetter: (_, row) => {
                const structure = selectStructureNodeById(store.getState(),  row.deviceTypeId)
                if (structure === undefined) {
                    return "";
                }
                const { name } = structure
                return name;
            }
        },
        { 
            field: 'docDesignation', 
            headerName: l10n.getString("urs-template-description", null, "Opis"), 
            flex:1
        },
        { 
            field: 'isSystem', 
            type: 'boolean', 
            headerName: l10n.getString("validation-system", null, "Systemowy"), 
            width: 160 
        },
        {
            field: 'actions', type: 'actions', width: 250,
            renderCell: (cell) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`${urlPrefix}/urstemplates/edit/${cell.row.id}?structureId=${cell.row.deviceTypeId}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        {/* <Button  onClick={() => handleCopyClick(cell.row.id)} >
                            <Localized id="copy">
                                <span>Kopiuj</span>
                            </Localized>
                        </Button> */}
                        <Button disabled={readOnly(cell.row.isSystem)} onClick={() => handleDeleteClick(cell.row.id)} >
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];
    
    return <>
        <TableContainer component={Paper}>
            <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 200px)' }} 
                rows={ursTemplates} columns={columns} 
                initialState={{
                sorting:{sortModel:[{field:'name', sort:'asc'}]}
            }}
            />
        </TableContainer>     
        <FlexDiv>
            <Fab 
                component={Link} 
                to={structureId !== undefined ? `${urlPrefix}/urstemplates/new?structureId=${structureId}` : `${urlPrefix}/urstemplates/new`} 
                color="secondary" 
                sx={{marginTop:2}}
            >
                <AddIcon />
            </Fab>
        </FlexDiv>
        <Dialog open={itemToDelete !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="urs-template-delete-question">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleConfirmDelClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </Button>
                <Button onClick={handleCancelDelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </Button>
            </DialogActions>
        </Dialog>
        <AppLoader open={deleting} />
    </>
}
