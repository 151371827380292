import { useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { selectAllUsers, selectUserById } from "./usersSlice"

export const FullName = ({ userName }: { userName: string }) => {
    const allUsers = useAppSelector(selectAllUsers)
    const user = allUsers.find(x => x.userName === userName)

    if (!userName) return null 

    return <If condition={user !== undefined} otherwise={<span>User not found</span>}>
            <span>{user?.firstName}&nbsp;{user?.lastName}</span>
        </If>
}

export const FullNameByUserId = ({ userId }: { userId: string }) => {
    const user = useAppSelector(state => selectUserById(state, userId))
    if (!userId) 
        return null
    else if (!user) 
        return <>User not found</>
    else 
        return <span>{user.firstName}&nbsp;{user.lastName}</span>
}

