import { Localized } from "@fluent/react";

export const ProjectStatusLabel = ({ status }: { status: string}) => {
    switch (status) {
        case "D":
            return <Localized id="project-status-d">
                <span>Szkic</span>
            </Localized>        
        case "I":
            return <Localized id="project-status-i">
                <span>W trakcie</span>
            </Localized>
        case "H":
            return <Localized id="project-status-h">
                <span>Wstrzymany</span>
            </Localized>
        case "F":
            return <Localized id="project-status-f">
                <span>Zakończony</span>
            </Localized>   
        case "C":
            return <Localized id="project-status-c">
                <span>Anulowany</span>
            </Localized>          
        default:
            return <span></span>
    }
}