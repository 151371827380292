import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Localized } from "@fluent/react"
import { Button, Stack, Typography } from "@mui/material"
import { StructureNodeSelector } from './Structure';
import { Box } from '@mui/system';
import { useAppSelector, useQueryStructureId } from '../../app/hooks';
import { selectStructure, selectStructureNodeById } from './structureSlice';
import { If } from '../../app/If';
import { useLocation, useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const DeviceTypeContext = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const [open, setOpen] = React.useState(false)
    const contextStructureId = useQueryStructureId()
    const structure = useAppSelector(state => selectStructureNodeById(state, contextStructureId ?? ""))
    const { selected: selectedStructure } = useAppSelector(state => selectStructure(state).tree)

    const handleClickOpen = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleOkClick = () => {
        if (selectedStructure !== undefined) {
            const { search } = location
            const newSearch = new URLSearchParams(search)
            newSearch.set("structureId", selectedStructure)
            navigate({...location, search: newSearch.toString() }, { replace: true })
        }
        setOpen(false)
    }

    return <>
        <Stack spacing={2} direction="row-reverse" sx={{ 
                display: 'flex', 
                alignItems: 'center', 
                marginRight: 8,
            }}>
            <Button variant="outlined" color="inherit" onClick={handleClickOpen}>
                <Localized id="select">
                    <span>Wybierz</span>
                </Localized>
            </Button>
            <If 
                condition={structure !== undefined} 
                otherwise={<Typography component="div">
                        <Localized id="device-type-not-selected">Nie wybrano typu urządzenia</Localized>
                    </Typography>}>
                <Typography component="div">{structure?.name} ({structure?.code})</Typography>
            </If>
        </Stack>
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
                <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
                >
                <CloseIcon />
                </IconButton>
                <Button autoFocus color="inherit" onClick={handleOkClick}>
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </Button>
            </Toolbar>
            </AppBar>
            <Box sx={{ padding: 4, }}>
                <StructureNodeSelector />
            </Box>
        </Dialog>
    </>
}
