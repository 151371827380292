import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { Localized } from "@fluent/react"
import { Process } from '../processSlice';
import { ValidationStage } from '../../validations/validationsSlice';
import { StageStatusLabel } from '../StageStatusLabel';

const PREFIX = 'StageStatusTeamInfo';

const classes = {
  root: `${PREFIX}-root`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`
};

const StyledCard = styled(Card)(( { theme }) => ({
  [`&.${classes.root}`]: {
    width:"100%",
    flexGrow: 1,
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.expand}`]: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
      transform: 'rotate(180deg)',
  }
}));

export const StageStatusTeamInfo = (props: { process: Process, stage: ValidationStage }) => {
    const { processOwnerFullName, stages } = props.process
    const stage = stages.find(s => s.stage === props.stage)
    const status = stages.find(s => s.stage === props.stage)?.status ?? "P"

    let user = "";
    
    const [teamDetailsExpanded, setTeamDetailsExpanded] = React.useState(false);
  
    const handleTeamDetailsClicked = () => {
      setTeamDetailsExpanded(!teamDetailsExpanded);
    };  
  
    switch (status) {
      case "V":
      case 'F':
        user = stage?.finalApproverFullName ?? ""
        break;
  
      case "P":
      case "R":
          user = stage?.plannerFullName ?? ""
          break;
  
      case "A":
          user = stage?.approverFullName ?? ""
          break;
  
      default :
          user = processOwnerFullName ?? ""
        break;
    }
    
    return (
      <StyledCard className={classes.root} >
        <CardHeader
          action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: teamDetailsExpanded,
            })}
            onClick={handleTeamDetailsClicked}
            size="large">
            <ExpandMoreIcon />
          </IconButton>
        } 
          title={<StageStatusLabel status={status} />}
          subheader={user}
        />           
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <Localized id="stage-team">Team</Localized>        
          </Typography>        
        </CardContent>          
             
        <Collapse in={teamDetailsExpanded} timeout="auto" unmountOnExit>
          <CardContent>                
            <Typography variant="body2" color="textSecondary" component="p">
              <Localized id="stage-status-p">Planowanie</Localized>
            </Typography>
            <Typography>          
              {stage?.plannerFullName ?? " - "}
            </Typography>       
            <Typography variant="body2" color="textSecondary" component="p">
              <Localized id="stage-status-a">Zatwierdzanie</Localized>
            </Typography>
            <Typography>          
              {stage?.approverFullName ?? " - "}
            </Typography>       
            <Typography variant="body2" color="textSecondary" component="p">
              <Localized id="stage-status-v">Weryfikacja</Localized>
            </Typography>  
            <Typography>          
              {stage?.finalApproverFullName ?? " - "}
            </Typography>        
            <Typography variant="body2" color="textSecondary" component="p">
              <Localized id="process-manager">Proces manager</Localized>         
            </Typography>
            <Typography>          
              {processOwnerFullName ?? " - "}
            </Typography>                           
          </CardContent>
        </Collapse>
      </StyledCard>
    );
  }