import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material"
import { LoadValidations } from "../../app/AppDataLoader"
import { useAppSelector } from "../../app/hooks"
import { LoadingContainer } from "../../app/LoadingContainer"
import { selectAllValidations } from "../validations/validationsSlice"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const SpecTests = () => {
    const allValidations = useAppSelector(selectAllValidations)

    return <LoadValidations component={<LoadingContainer />}>
        {allValidations.slice(0, 5).map(({ name, description }, idx) => <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>{name}</AccordionSummary>
            <AccordionDetails>{description}</AccordionDetails>
        </Accordion>)}
    </LoadValidations>
}
