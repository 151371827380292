import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { getWithAuth } from "../../http";
import { logout } from "../user/userSlice";

export type TaskId = string;
export type TaskType = "P" | "R" | "A" | "V" | "T" | "S" | "FinishOpinion" | "Approvement" | "FinishEdition"

export interface MyTask {
   id: TaskId, 
   taskType: TaskType, 
   stage: string,
   deviceName: string,
   deadline: string,
   processId: string ,
   testId: string ,
   taskDescription: string ,
   ursId: string | null,
   link: string
}

const adapter = createEntityAdapter<MyTask>({
    selectId: (myTask) => myTask.id,
})


export type MyTaskState = EntityState<MyTask> & { state: AppDataState }

const initialState: MyTaskState = adapter.getInitialState({
    state: {
        type: "empty"
    }
})

export const loadMyTasks = createAsyncThunk<MyTask[], void, AppThunkAPIType>("mytasks/load", async (_, { 
    dispatch, 
    rejectWithValue,
    getState,
    requestId,
}) => {
    const state = getState().myTasks.state
    if (state.type === "loading" && state.requestId === requestId) {
        const result = await dispatch(getWithAuth({ url: "api/mytasks" }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MyTask[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
    return []
})

export const myTaskSlice = createSlice({
    name: "myTask",
    initialState,
    reducers: {
        clearMyTasksSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        } 
    },
    extraReducers: (builder) => {
        builder.addCase(loadMyTasks.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadMyTasks.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })        
        builder.addCase(loadMyTasks.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { type: "error", error: action.payload ?? unknownError(), }
            }
        })
        builder.addCase(logout.fulfilled, (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectMyTasks = (state: RootState): MyTaskState => state.myTasks

export const { clearMyTasksSlice } = myTaskSlice.actions

export const 
    { selectAll: selectAllMyTasks
    , selectById: selectMyTasksById 
    } = adapter.getSelectors<RootState>(selectMyTasks)

export default myTaskSlice.reducer;
