import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Action, loadProject, Project, ProjectTeamMember } from "./projectSlice";
import { Alert, Button, ButtonGroup, Card, CardHeader, Container, Dialog, Divider, Fab, Grid, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Paper, Skeleton, Tab, Typography } from "@mui/material";
import { Localized, useLocalization } from "@fluent/react";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import { BottomAppBar } from "../../app/BottomAppBar";
import { LoadProjects } from "../../app/AppDataLoader";
import { CreateProjectProcess } from "./Dialogs/CreateProjectProcess";
import { EditDescriptionField, EditDescriptionFieldProps } from "./EditDescriptionField";
import { If } from "../../app/If";
import { EditSimpleDescriptionField, EditSimpleDescriptionFieldProps } from "./EditSimpleDescriptionField";
import { ProjectStatusLabel } from "./ProjectStatusLabel";
import { Attachements } from "../urs/Attachements";
import { loadManyBlobs } from "../urs/ursAttachementsSlice";
import { AppId } from "../../app/appTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import produce from "immer";
import { DeleteConfirmationDialog, DeleteConfirmationDialogProps } from "./Dialogs/DeleteConfirmation";
import { ProjectTeamMemberDialog, ProjectTeamMemberDialogProps } from "./Dialogs/ProjectTeamMemberDialog";
import { ProjectProcessStatusLabel } from "./projectProcess/ProjectProcessStatusLabel";
import { NotesList } from "./Notes/NotesList";
import { ProjectProcessHeader } from "./projectProcess/ProjectProcessApiClient";
import ShareIcon from '@mui/icons-material/Share';
import { InviteProjectTeamMemberDialog, InviteProjectTeamMemberDialogProps } from "./Dialogs/InviteProjectTeamMemberDialog";
import { RolesLabel } from "./Labels/ProjectRolesLabel";
import { selectLoggedUser } from "../user/userSlice";
import { MeetingMinutesList } from "./mettingMinutes/MeetingMinutesList";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ActionRunner } from "./ActionRunner";
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import DoneIcon from '@mui/icons-material/Done';
import { BusinessCaseListForProject } from "../businessCase/BusinessCaseListForProject";
import ProjectOverview from "./Components/ProjectOverview";
import DashboardIcon from '@mui/icons-material/Dashboard';
import ProjectOverviewDashboard from "./dashboard/ProjectOverviewDashboard";
import { selectSettings } from "../settings/settingsSlice";
import { enUS, plPL } from "@mui/x-data-grid/locales";

type FormTabs = "process" | "management" | "notes" | "attachments" | "conversations" | "meetingMinutes" | "businessCases" | "overview"


export const ProjectForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    return <>
        {/* *To potzrebne jeszcze ? */}
        <LoadProjects component={<Skeleton />}>
            <LoadProjectDeatails projectId={id} />
        </LoadProjects>
    </>
}

export const LoadProjectDeatails = ({ projectId }: { projectId: AppId }) => {
    const dispatch = useAppDispatch()
    const [project, setProject] = useState<Project | undefined>(undefined)

    useEffect(() => {
        dispatch(loadProject(projectId))
            .then(unwrapResult)
            .then(project => setProject(project))
            .catch(err => console.error(err))
    }, [])

    if (project === undefined) return <Skeleton variant='rectangular' sx={{ minHeight: '400px' }} />

    return <EditForm project={project} setProject={setProject} />
}

type DialogState
    = { tag: 'none' }
    | { tag: 'confirmDelete', props: DeleteConfirmationDialogProps }
    | { tag: 'editTeamMember', props: ProjectTeamMemberDialogProps }
    | { tag: 'inviteTeamMember', props: InviteProjectTeamMemberDialogProps }
    | { tag: 'editField', props: EditDescriptionFieldProps }
    | { tag: 'editSimpleField', props: EditSimpleDescriptionFieldProps }


interface EditFormProps {
    project: Project
    setProject: React.Dispatch<React.SetStateAction<Project | undefined>>
}
const EditForm = (props: EditFormProps) => {
    const { project, setProject } = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const processRows = project?.process || [];
    const allTeamMembers = project?.projectTeamMembers || []
    const teamMembersSelection = useMemo(() => new Set(allTeamMembers.map(u => u.user)), [allTeamMembers])
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);

    const [actionToRun, setActionToRun] = useState<Action & { name: string, description: string } | null>(null)

    const currentUser = useAppSelector(selectLoggedUser)
    const foundUser = allTeamMembers.find(x => x.user === currentUser);
    const access = foundUser ? foundUser.role !== "O" : false;

    const [teamMemberToEdit, setTeamMemberToEdit] = useState<ProjectTeamMember | undefined>(undefined)

    const [createProcessDialogVisible, setCreateProcessDialogVisible] = useState(false);

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState(null);

    const [params, setParams] = useSearchParams()
    const tab = params.get('tab') as FormTabs || 'overview'
    const setTab = (tab: FormTabs) => {
        params.set('tab', tab)
        setParams(params, { replace: true })
    }

    const [dialog, setDialog] = useState<DialogState>({ tag: 'none' })

    const createdDate = project?.createDate ? new Date(project.createDate).toISOString().split('T')[0] : "";

    const isProjectFinished = project?.status === "F";
    const isProjectCanceled = project?.status === "C";
    const isProjectPaused = project?.status === "H";

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleHideDialog = useCallback(() => setCreateProcessDialogVisible(false), [])

    const onSuccess = useCallback((proc: ProjectProcessHeader) => {
        setProject(produce(draft => {
            if (draft) {
                draft.process.push(proc)
            }
        }))
        setCreateProcessDialogVisible(false)
    }, [dispatch])

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const openCreateProcessDialog = useCallback(() => {
        setCreateProcessDialogVisible(true)
    }, [setCreateProcessDialogVisible])

    const handleGoToClick = (id) => {
        navigate(`/projectprocess/edit/${id}`)
    };

    const teamcolumns: GridColDef[] = [
        {
            field: 'firstName',
            headerName: l10n.getString('user-first-name', null, 'Imię'),
            width: 300
        },
        {
            field: 'lastName',
            headerName: l10n.getString('user-last-name', null, 'Nazwisko'),
            flex: 1
        },
        {
            field: 'position',
            headerName: l10n.getString('user-position', null, 'Stanowisko'),
            width: 200
        },
        {
            field: 'companyName',
            headerName: l10n.getString('user-company', null, 'Firma'),
            flex: 1
        },
        {
            field: 'role',
            headerName: l10n.getString('project-role', null, 'Rola w Projekcie'),
            flex: 1,
            renderCell: (params) => (
                <div>
                    <RolesLabel role={params.value ?? ""} />
                </div>
            )
        },
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <If condition={params.row.role != "M" && access}>
                            <Button onClick={() => handleTeamMemberDeleteClick(params.row.id)} color="secondary" variant="outlined">
                                <Localized id="delete">
                                    <span>delete</span>
                                </Localized>
                            </Button>
                        </If>
                    </ButtonGroup>
                )
            }
        }
    ];

    const processColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: l10n.getString('project-process', null, 'Proces'),
            flex: 1
        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),
            width: 200,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <ProjectProcessStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 300,
            renderCell: (params) => {
                return (
                    <>
                        <ButtonGroup>
                            <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Edytuj</span>
                                </Localized>
                            </Button>
                            <Button disabled={!access || params.row.status === 'F'} onClick={(event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); setOpenMenu(params.row.id) }} color="secondary" variant="outlined">
                                <Localized id="actions">Akcje</Localized>
                            </Button>
                        </ButtonGroup>
                        <Menu anchorEl={anchorEl} open={openMenu === params.row.id} onClose={() => { setAnchorEl(null); setOpenMenu(null); } }>
                        <MenuList>
                            <If condition={params.row.status !== 'I'}>
                                <MenuItem onClick={() => { setActionToRun({ id: params.row.id, status: "I", type: "PUT", authorize: false, actionId: "startProjectProcess", name: params.row.name, description: "" }); setAnchorEl(null); setOpenMenu(null); } }>
                                    <ListItemIcon>
                                        <PlayArrowIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Localized id="project-process-start">Start the process</Localized>
                                    </ListItemText>
                                </MenuItem>
                            </If>
                            <If condition={params.row.status !== 'H'}>
                                <MenuItem onClick={() => { setActionToRun({ id: params.row.id, status: "H", type: "PUT", authorize: false, actionId: "pauseProjectProcess", name: params.row.name, description: "" }); setAnchorEl(null); setOpenMenu(null); } }>
                                    <ListItemIcon>
                                        <PauseIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Localized id="project-process-pause">Pause the process</Localized>
                                    </ListItemText>
                                </MenuItem>
                            </If>
                            <MenuItem onClick={() => { setActionToRun({ id: params.row.id, status: "F", type: "PUT", authorize: true, actionId: "finishProjectProcess", name: params.row.name, description: "" }); setAnchorEl(null); setOpenMenu(null); } }>
                                <ListItemIcon>
                                    <DoneIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Localized id="project-process-finish">Finish the process</Localized>
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { setActionToRun({ id: params.row.id, type: "POST", authorize: true, actionId: "deleteProjectProcess", name: params.row.name, description: "" }); setAnchorEl(null); setOpenMenu(null); } }>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Localized id="delete">Delete</Localized>
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </>
                )
            }
        }
    ];

    const handleEditDescriptionClick = useCallback(() => {
        if (project) {
            setDialog({
                tag: 'editField',
                props: {
                    projectId: project.id,
                    field: 'description',
                    value: project.description,
                    onSave: (ph) => {
                        setDialog({ tag: 'none' })
                        setProject(produce(draft => {
                            if (draft) {
                                draft.description = ph.description
                            }
                        }))
                    },
                    onCancel: () => setDialog({ tag: 'none' })
                }
            })
        }
    }, [project])
    const handleEditSimpleDescriptionClick = (fieldName: string) => {
        if (project) {
            setDialog({
                tag: 'editSimpleField',
                props: {
                    projectId: project.id,
                    field: fieldName,
                    value: project[`${fieldName}`] || '',
                    onSave: (ph) => {
                        setDialog({ tag: 'none' })
                        setProject(produce(draft => {
                            if (draft) {
                                draft[`${fieldName}`] = ph[`${fieldName}`]
                            }
                        }))
                    },
                    onCancel: () => setDialog({ tag: 'none' })
                }
            })
        }
    }

    const handleInviteTeamMemberClick = useCallback(() => {
        if (project) {
            setDialog({
                tag: 'inviteTeamMember',
                props: {
                    projectId: project?.id,
                    onSave: (tm) => {
                        setDialog({ tag: 'none' })
                        setProject(produce(draft => {
                            if (draft !== undefined) {
                                const index = draft.projectTeamMembers.findIndex(u => u.id === tm.id)
                                if (index >= 0) {
                                    draft.projectTeamMembers[index] = tm
                                } else {
                                    draft.projectTeamMembers.push(tm)
                                }
                            }
                        }))
                    },
                    onCancel: () => setDialog({ tag: 'none' })
                }
            })
        }
    }, [project])

    const handleEditTeamMemberClick = useCallback(() => {
        if (project) {
            setDialog({
                tag: 'editTeamMember',
                props: {
                    projectId: project?.id,
                    mode: teamMemberToEdit ? "edit" : "create",
                    excludedUsers: teamMembersSelection,
                    defaultUserName: teamMemberToEdit?.user ?? "",
                    onSave: (tm) => {
                        setDialog({ tag: 'none' })
                        setProject(produce(draft => {
                            if (draft !== undefined) {
                                const index = draft.projectTeamMembers.findIndex(u => u.id === tm.id)
                                if (index >= 0) {
                                    draft.projectTeamMembers[index] = tm
                                } else {
                                    draft.projectTeamMembers.push(tm)
                                }
                            }
                        }))
                    },
                    onCancel: () => setDialog({ tag: 'none' })
                }
            })
        }
    }, [project])

    const handleTeamMemberDeleteClick = (id: AppId) => {
        if (project) {
            setDialog({
                tag: 'confirmDelete',
                props: {
                    objectId: id,
                    mode: 'deleteProjectTeamMember',
                    onSave: (teamMemberId: AppId) => {
                        setProject(produce(draft => {
                            if (draft !== undefined) {
                                draft.projectTeamMembers = draft.projectTeamMembers.filter(u => u.id !== teamMemberId)
                            }
                        }))
                        setDialog({ tag: 'none' })
                    },
                    onCancel: () => setDialog({ tag: 'none' })
                }
            })
        }
    }


    const handleDialogCancelClick = useCallback(() => {
        setDialog({ tag: 'none' })
    }, [])


    useEffect(() => {
        if (project) {
            dispatch(loadManyBlobs([{ docType: 'project', docId: project.id }, [project.guid]]))
        }
    }, [])

    if (project === undefined) return <Alert severity="error">Project not found</Alert>

    return <>
        <Container component={Paper} maxWidth={false} sx={{ paddingTop: 2 }}>
            <Grid container spacing={1} sx={{ maxWidth: '100%' }} // Ustaw maksymalną szerokość na 100%
            >
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={project?.no}
                            subheader={<Localized id="project-no"><span>Numer projektu</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={project?.name}
                            subheader={<Localized id="project-title"><span>Tytuł projektu</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={project?.companyName}
                            subheader={<Localized id="project-company-name"><span>Nazwa firmy</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={createdDate}
                            subheader={<Localized id="project-create-date"><span>Data utworzenia</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={<ProjectStatusLabel status={project?.status}></ProjectStatusLabel>}
                            subheader={<Localized id="project-status"><span>Status</span></Localized>}
                        />
                    </Card>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={1}
                                value={"overview"}
                                icon={<DashboardIcon/>}
                                iconPosition="start"
                                label={<Localized id="project-overview">Dashboard</Localized>} />
                            <Tab
                                key={2}
                                value={"management"}
                                label={<Localized id="project-management">Zarządzanie</Localized>} />
                            <Tab
                                key={3}
                                value={"team"}
                                label={<Localized id="project-team">Zespół</Localized>} />
                            <Tab
                                key={4}
                                value={"attachments"}
                                label={<Localized id="project-attachments">Załaczniki</Localized>} />
                            <Tab
                                key={5}
                                value={"process"}
                                label={<Localized id="project-processes-list">Lista procesów</Localized>} />
                            <Tab
                                key={6}
                                value={"meetingMinutes"}
                                label={<Localized id="meeting-minutes-minute-list">Lista Notatek</Localized>} />
                            <Tab
                                key={7}
                                value={"businessCases"}
                                label={<Localized id="project-business-cases-list">Business cases</Localized>} />
                            <Tab
                                key={8}
                                value={"notes"}
                                label={<Localized id="project-notes">notes</Localized>} />
                           
                        </TabList>
                        <TabPanel value="overview">
                            <ProjectOverviewDashboard projectGuid={project?.guid} projectId={project?.id}></ProjectOverviewDashboard>
                        </TabPanel>
                        <TabPanel value="process">
                            <DataGrid 
                                style={{ minHeight: 100, height: 'calc(100vh - 425px)' }}
                                localeText={getCustomLocaleText()}
                                rows={processRows}
                                columns={processColumns}
                                onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                            <If condition={access}>
                                <Fab onClick={openCreateProcessDialog} color="secondary" sx={{ marginTop: 2 }} disabled={isProjectFinished || isProjectCanceled || isProjectPaused}>
                                    <AddIcon />
                                </Fab>
                            </If>

                        </TabPanel>
                        <TabPanel value="team">
                            <DataGrid 
                                style={{ minHeight: 100, height: 'calc(100vh - 400px)' }}
                                rows={allTeamMembers}
                                columns={teamcolumns}
                            />
                            <If condition={access}>
                                <Button sx={{ marginTop: "5px" }} variant="contained" color="secondary" onClick={handleEditTeamMemberClick} startIcon={<AddIcon />} >
                                    <Localized id="add">
                                        <span>Dodaj</span>
                                    </Localized>
                                </Button>
                                <Button sx={{ marginLeft: "5px", marginTop: "6px" }} onClick={handleInviteTeamMemberClick} variant="outlined" startIcon={<ShareIcon />}  >
                                    <Localized id="invite">
                                        <span>Zaproś</span>
                                    </Localized>
                                </Button>
                            </If>

                        </TabPanel>
                        <TabPanel value="attachments">
                            <Attachements resourceId={project?.guid} canDelete={access} canUpload={access} />
                        </TabPanel>
                        <TabPanel value="meetingMinutes">
                            <MeetingMinutesList
                                objectId={project.guid}
                                objectType = 'PROJECT'
                                canEdit = {access}
                            />
                        </TabPanel>
                        <TabPanel value="notes">
                            <NotesList
                                objectId={project.guid}
                                canEdit = {access}
                            />
                        </TabPanel>
                        <TabPanel value="management">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 1 }}>
                                        <Typography component="div" color="text.secondary" gutterBottom>
                                            <Localized id='project-no' />
                                        </Typography>
                                        <If condition={project.no !== null && project.no !== ''} otherwise={<Alert severity="warning"><Localized id='missing'>Brak</Localized></Alert>}>
                                            <Typography sx={{ ml: 4 }} variant="h5">{project.no}</Typography>
                                        </If>
                                        <Button size="small" variant="text" onClick={() => handleEditSimpleDescriptionClick('no')}><Localized id='edit' /></Button>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 1 }}>
                                        <Typography component="div" color="text.secondary" gutterBottom>
                                            <Localized id='project-title' />
                                        </Typography>
                                        <If condition={project.name !== null && project.name !== ''} otherwise={<Alert severity="warning"><Localized id='missing'>Brak</Localized></Alert>}>
                                            <Typography sx={{ ml: 4 }} variant="h5">{project.name}</Typography>
                                        </If>
                                        <If condition={access}>
                                            <Button size="small" onClick={() => handleEditSimpleDescriptionClick('name')}><Localized id='edit' /></Button>
                                        </If>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 1 }}>
                                        <Typography component="div" color="text.secondary" gutterBottom>
                                            <Localized id='project-general-contractor' />
                                        </Typography>
                                        <Typography sx={{ ml: 4 }} variant="h5">{project.generalContractor}</Typography>
                                        <If condition={access}>
                                            <Button size="small" onClick={() => handleEditSimpleDescriptionClick('generalContractor')}><Localized id='edit' /></Button>
                                        </If>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 1 }}>
                                        <Typography component="div" color="text.secondary" gutterBottom>
                                            <Localized id='project-investor' />
                                        </Typography>
                                        <Typography sx={{ ml: 4 }} variant="h5">{project?.investor}</Typography>
                                        <If condition={access}>
                                            <Button size="small" onClick={() => handleEditSimpleDescriptionClick('investor')}><Localized id='edit' /></Button>

                                        </If>
                                    </Paper>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{ p: 1 }}>
                                        <Typography component="div" color="text.secondary" gutterBottom>
                                            <Localized id='project-description' />
                                        </Typography>
                                        <Typography sx={{ ml: 4 }} variant="body1">
                                            <div dangerouslySetInnerHTML={{ __html: project.description }}></div>
                                        </Typography>
                                        <If condition={access}>
                                            <Button size="small" onClick={handleEditDescriptionClick}><Localized id='edit' /></Button>
                                        </If>
                                    </Paper>
                                </Grid>
                                <If condition={access && !isProjectFinished}>
                                    <Grid item xs={12}>
                                        <Paper sx={{ p: 1 }}>
                                            <Typography component="div" color="text.secondary" gutterBottom>
                                                <Localized id='project-management'/>
                                            </Typography>
                                            <ButtonGroup>
                                            <Button onClick={() => setActionToRun({ id: project?.id, type: "POST", authorize: true, actionId: "deleteProject", name: "", description: "" })} color="error" variant="outlined">
                                                <Localized id="delete">Usuń</Localized>
                                            </Button>
                                            {!isProjectCanceled ? (<>
                                                <If condition={project?.status !== 'I'}>
                                                    <Button onClick={() => setActionToRun({ id: project?.id, status: "I", type: "PUT", authorize: false, actionId: "startProject", name: "", description: "" })} color="secondary" variant="outlined">
                                                        <Localized id="project-start">Rozpocznij projekt</Localized>
                                                    </Button>
                                                </If>
                                                <If condition={!isProjectPaused}>
                                                    <Button onClick={() => setActionToRun({ id: project?.id, status: "H", type: "PUT", authorize: false, actionId: "pauseProject", name: "", description: "" })} color="warning" variant="outlined">
                                                        <Localized id="project-pause">Wstrzymaj projekt</Localized>
                                                    </Button>
                                                </If>
                                                <Button onClick={() => setActionToRun({ id: project?.id, status: "C", type: "PUT", authorize: true, actionId: "cancelProject", name: "", description: "" })} color="error" variant="outlined">
                                                    <Localized id="project-cancel">Anuluj projekt</Localized>
                                                </Button>
                                                <Button onClick={() => setActionToRun({ id: project?.id, status: "F", type: "PUT", authorize: true, actionId: "finishProject", name: "", description: "" })} color="secondary" variant="outlined">
                                                    <Localized id="project-finish">Zakończ projekt</Localized>
                                                </Button></>)
                                            : null }
                                            </ButtonGroup>
                                        </Paper>
                                    </Grid>
                                </If>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="businessCases">
                            <BusinessCaseListForProject projectId={project.id} projectStatus={project.status}/>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            <BottomAppBar>
                <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
                    <Localized id="back">Wróć</Localized>
                </Button>
            </BottomAppBar>
        </Container >
        <Dialog open={createProcessDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
            <CreateProjectProcess
                projectId={String(project?.id)}
                no={""}
                name={""}
                onSucces={onSuccess}
                onCancel={handleHideDialog}
            />
        </Dialog>
        <>{actionToRun && <ActionRunner
                action={actionToRun}
                onError={() => { }}
                onSuccess={result => {
                    setActionToRun(null)
                    if(result.actionId === "startProject" || result.actionId === "pauseProject" || result.actionId === "cancelProject" || result.actionId === "finishProject") {
                        setProject(produce(draft => {
                            if (draft) {
                                draft.status = result.status;
                            }
                        }))
                    } else if(result.actionId === "startProjectProcess" || result.actionId === "pauseProjectProcess" || result.actionId === "finishProjectProcess") {
                        setProject(produce(draft => {
                            if (draft) {
                                let x = draft.process.find(f => f.id === result.id);
                                if(x) x.status = result.status;
                            }
                        }))
                    } else if(result.actionId === "deleteProjectProcess") {
                        setProject(produce(draft => {
                            if (draft) {
                                let x = draft.process.findIndex(f => f.id === result.id);
                                if(x !== -1) draft.process.splice(x, 1);
                            }
                        }))
                    }
                }}
                onCancel={() => setActionToRun(null)} />}</>
        <DialogDispatcher dialog={dialog} />
    </>
}

const DialogDispatcher = ({ dialog }: { dialog: DialogState }) => {
    switch (dialog.tag) {
        case 'none':
            return null

        case 'confirmDelete':
            return <DeleteConfirmationDialog {...dialog.props} />

        case 'editField':
            return <EditDescriptionField {...dialog.props} />

        case 'editSimpleField':
            return <EditSimpleDescriptionField {...dialog.props} />

        case 'editTeamMember':
            return <ProjectTeamMemberDialog {...dialog.props} />

        case 'inviteTeamMember':
            return <InviteProjectTeamMemberDialog {...dialog.props} />

    }
}

