import { Localized, useLocalization } from "@fluent/react"
import { ChangeEvent, SetStateAction, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react"
import { Link, useParams } from "react-router-dom"
import { MeetingMinute, MeetingMinuteItem, closeMeeting, deleteMeetingMinuteItem, loadMeetingMinutes, sendGeneralReport, updateMeetingMinuteItem, updateMeetingMinuteItemTask } from "./meetingMinutesSlice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { unwrapResult } from "@reduxjs/toolkit"
import { APIError, AppTextFieldHandler } from "../../../app/appTypes"
import { Box, Button, ButtonGroup, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, MenuItem, Paper, Skeleton, Stack, Switch, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, debounce, List, ListItem, ListItemText, FormGroup, Checkbox, ListSubheader, DialogContentText } from "@mui/material"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid"
import { If } from "../../../app/If"
import AddIcon from '@mui/icons-material/Add'
import { BottomAppBar } from "../../../app/BottomAppBar"
import produce from "immer"
import { Attachements } from "../../urs/Attachements"
import { loadManyBlobs } from "../../urs/ursAttachementsSlice"
import { MeetingMinutesItemStatusLabel } from "./MeetingMinutesItemStatusLabel"
import { CreateMeetingMinutesItem } from "./dialogs/CreateMeetingMinutesItem"
import { MeetingMinutesItemPriorityLabel } from "./MeetingMinutesItemPriorityLabel"
import clsx from "clsx"
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import React from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import TaskIcon from '@mui/icons-material/Task'
import ImportContactsIcon from '@mui/icons-material/ImportContacts'
import { DatePicker } from "@mui/x-date-pickers"
import AddTaskIcon from '@mui/icons-material/AddTask'
import { AddItemTaskDialog } from "./dialogs/AddItemTask"
import { addAttendeeToMeeting, Attendee, deleteAttendeeFromMeeting, Meeting } from "./meeting"
import { format, parseISO } from "date-fns"
import { MeetingStatusLabel } from "./MeetingStatusLabel"
import { parseAndFormatISODate, parseAndFormatISODateWithTodayCompare, parseISODate } from "../../../app/Utils"
import FlagIcon from '@mui/icons-material/Flag'
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { selectSettings } from "../../settings/settingsSlice"
import DeleteIcon from '@mui/icons-material/Delete'
import { Email, UsersBrowser } from "../../users/UsersBrowser"
import { selectCompanyById } from "../../company/companiesSlice"
import { store } from "../../../app/store"
import { SimpleTimer } from "./components/SimpleTimer"
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled'
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk'
import RemoveIcon from '@mui/icons-material/Remove'
import { MeetingMinutesStatistics } from "./MeetingMinutesStatistics"
import { ProjectProcessHeader, loadForProject } from "../projectProcess/ProjectProcessApiClient"
import { RichTextContainer, mkTipTapExtWithMention } from "../../../app/RichTextEditor"
import { useEditor } from "@tiptap/react"
import { ServerErrorMsg } from "../../../app/ServerErrorMsg"
import EmailInput from "./components/EmailInput"
import { useNavigate } from "react-router-dom"

type FormTabs = "items" | "attachments" | "meetings" | "attendees" | "statistics"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const MeetingMinutesForm = () => {
    const dispatch = useAppDispatch()
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const [meetingMinute, setMeetingMinute] = useState<MeetingMinute>()
    const [error, setError] = useState<APIError | null>(null)

    useEffect(() => {
        dispatch(loadMeetingMinutes(id))
        .then(unwrapResult)
        .then(setMeetingMinute)
        .catch((err) => setError(err))
    }, [])

    if (meetingMinute === undefined) {
        return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
    }
    if (error) {
        return <ServerErrorMsg err={error} />
    }

    return <>
        <EditForm id={id} meetingMinute={meetingMinute} setMeetingMinute={setMeetingMinute} />
    </>
}

type DeleteState
    = { tag: 'none' }
    | { tag: 'delete', id: number }


const EditForm = (props: { id: string, meetingMinute: MeetingMinute, setMeetingMinute: React.Dispatch<SetStateAction<MeetingMinute | undefined>> }) => {
  const { meetingMinute, setMeetingMinute } = props
  const { l10n } = useLocalization()
  const dispatch = useAppDispatch() 
  const navigate = useNavigate()
  const { locale } = useAppSelector(selectSettings);
  
  const [processes, setProcesses] = useState<ProjectProcessHeader[]>([])
 
  const isEditable = meetingMinute.status === "INMEETING"

  const allItems = meetingMinute.items || []
  const lastMeeting = meetingMinute.meetings.find(x => x.id === meetingMinute.lastMeetingId)
  const lastMeetingStartDate = lastMeeting ? format(parseISO(lastMeeting.startDate as string), 'yyyy-MM-dd HH:mm') : "";
  const lastMeetingEndDate = lastMeeting?.endDate ? format(parseISO(lastMeeting.endDate as string), 'yyyy-MM-dd HH:mm') : "";

  const [error, setError] = useState<APIError | null>(null)
  const createdDate = meetingMinute.createDate ? new Date(meetingMinute.createDate).toISOString().split('T')[0] : "";

  const [tab, setTab] = useState<FormTabs>("items");
  const [createItemDialogVisible, setCreateItemDialogVisible] = useState(false);
  const [showHighPriority, setShowHighPriority] = useState(false);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const [selectedProcessId, setSelectedProcessId] = useState<string | undefined>(undefined)

  const [attachmentLoaded, setAttachmentLoaded] = useState(false);
  const [processesLoaded, setProcessesLoaded] = useState(false);
  
  const [listOfRecipients, setListOfRecipients] = useState<string[]>([]);
  const [attachNoteAttachments, setAttachNoteAttachments] = useState(false);

  const handleListOfRecipientsChanged = (listOfRecipients: string[]) => {
    setListOfRecipients(listOfRecipients);
  };

  const handleAttachNoteAttachmentsChanged = (attach: boolean) => {
    setAttachNoteAttachments(attach);
  };

  const [saving, setSaving] = useState(false)
  const [validationErrors, setValidationErrors] = useState(false)
  const [person, setPerson] = useState('')
  const [company, setCompany] = useState('')
  const [email, setEmail] = useState('')
  const [deleteAttendee, setDeleteAttendee] = useState<DeleteState>({ tag: 'none' })

  const [appUsersDialogOpen, setAppUsersDialogOpen] = useState(false)
  const [sendGeneralReportCheckbox, setSendGeneralReportCheckbox] = React.useState(false);
  const [clearDescription, setClearDescription] = React.useState(false)

  const handleSendGeneralReportChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendGeneralReportCheckbox(event.target.checked);
  };
    
  const attendees = meetingMinute.meetings.find(x => x.id === meetingMinute.lastMeetingId)?.attendanceList ?? []
  const editor = useEditor({
      extensions: mkTipTapExtWithMention(attendees.map(a => a.person)),
      content: '<p></p>',   // initial value
      onUpdate: (e) => {                   // callback to get the updated value
          if (selectedRow) {
              const value = e.editor.getHTML()
              setItemDescription(value)
              debounceTest("description", value)
              debounceSave({
                  ...selectedRow,
                  name: itemName,
                  description: value,
                  priority: itemPriority,
                  responsiblePerson: itemResponsiblePerson,
                  deadline: itemDeadline ? itemDeadline.toISOString() : null,
                  status: itemStatus,
                  projectProcessId: parseInt(itemProjectProcessId)
                })
          }
      },
  })

    useEffect(() => {
        if (meetingMinute && editor) {
            editor.setOptions({
                editable: meetingMinute.status === "INMEETING",
            })
        }
    }, [meetingMinute, editor])

    useLayoutEffect(() => {
        if (editor && clearDescription) {
            editor.commands.clearContent()
            setClearDescription(false)
        }
    }, [clearDescription])

    const usersGridRef = useGridApiRef()

    const attendeesPersonError = validationErrors && (person === "" || person === null)
    const attendeesCompanyError = validationErrors && (company === "" || company === null)

    const findProcessName = (projectProcessId) => {
      if(processes && projectProcessId){
        const process = processes.find(p => p.id.toString() === projectProcessId.toString());
        return process ? process.name : '';
      }
      return '';
    };

    const handleConfirmDeleteAttendee = () => {
        if (deleteAttendee.tag === 'delete') {
            const { id } = deleteAttendee
            dispatch(deleteAttendeeFromMeeting(id))
                .then(unwrapResult)
                .then(() => {
                    setMeetingMinute(produce(draft => {
                        const meeting = draft?.meetings.find(x => x.id === draft?.lastMeetingId)
                        if (meeting) {
                            meeting.attendanceList = meeting.attendanceList.filter(x => x.id !== id)
                        }
                    }))
                    setDeleteAttendee({ tag: 'none' })
                })
        }
    }
    const handleAddAttendeeToMeeting = () => {
        if(person && company) {
          setSaving(true);
          setValidationErrors(true);

          dispatch(addAttendeeToMeeting({
              id: 0,
              person,
              company,
              email,
              meetingId: meetingMinute.lastMeetingId ?? 0
          }))
          .then(unwrapResult)
          .then((attendee) => {
            setMeetingMinute(produce(draft => {
              const meeting = draft?.meetings.find(x => x.id === draft?.lastMeetingId)
              if (meeting) {
                  meeting.attendanceList.push(attendee)
              }
              }
                 
                ))
                  setPerson('')
                  setCompany('')
                  setEmail('')
              }).finally( () => {
                  setSaving(false);
                  setValidationErrors(false);
              })
        }

    }
    const handleAddAppUsers = () => {
        setAppUsersDialogOpen(false)

        if (usersGridRef.current) {
            setSaving(true)
            usersGridRef.current.getSelectedRows().forEach((row) => {
                dispatch(addAttendeeToMeeting({
                    id: 0,
                    person: row.firstName + ' ' + row.lastName,
                    company: selectCompanyById(store.getState(), row.companyId)?.name ?? 'not found',
                    email: row.email,
                    meetingId: meetingMinute.lastMeetingId ?? 0,
                })).then(unwrapResult)
                .then((attendee) => {
                    setMeetingMinute(produce(draft => {
                        const meeting = draft?.meetings.find(x => x.id === draft?.lastMeetingId)
                        if (meeting) {
                            meeting.attendanceList.push(attendee)
                        }
                    }))
                }).finally(()=>{
                  setSaving(false)
                  dispatch(showSuccess("saved"));
                })
            })
        }
    }

    
  const handleSelectedProcessChange: (e: ChangeEvent<HTMLInputElement>) => void = 
    useMemo(() => 
        (e) => setSelectedProcessId(e.target.value), [setSelectedProcessId]);  

    
  const handleShowHighPriorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowHighPriority(event.target.checked);
  };

  const handleShowActiveChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowActiveOnly(event.target.checked);
  };

  const [showSendGeneralReportDialog, setShowSendGeneralReportDialog] = useState(false);

  const handleShowSendReportDialog = useCallback(() => {
    setShowSendGeneralReportDialog(true)
  }, [setShowSendGeneralReportDialog])

  const handleCancelSendGeneralReportClick = useCallback(() => {
    setShowSendGeneralReportDialog(false)
  }, [])

  const handleConfirmSendGeneralReport= async () => {
    if (meetingMinute) {
      setSaving(true)
      try {
        const result =
          await dispatch(sendGeneralReport(
            {
              meetingMinuteId: meetingMinute.id,
              listOfRecipients: listOfRecipients,
              attachNoteAttachments: attachNoteAttachments,
              language: locale
            }
          ))
          dispatch(showSuccess("sent"))
        }
        catch (error) {
          dispatch(showError("error"))
        }
        finally{
          setSaving(false)

          setShowSendGeneralReportDialog(false)
        }
    }
  }

  const [showCloseMeetingDialog, setShowCloseMeetingDialog] = useState(false);
  
  const handleCloseMeetingClick = useCallback(() => {
    setShowCloseMeetingDialog(true)
  }, [setShowCloseMeetingDialog])

  const handleConfirmCloseMeetingClick = async () => {
    setSaving(true)
    if (meetingMinute) {
      try {
        const result =  
          await dispatch(closeMeeting(
            {
              meetingMinuteId: meetingMinute.id, 
              sendGeneralReport: sendGeneralReportCheckbox, 
              listOfRecipients: listOfRecipients,
              attachNoteAttachments: attachNoteAttachments,
              language: locale
            }
          ))

        if (closeMeeting.fulfilled.match(result)) {
          const a = result.payload as MeetingMinute;
          setMeetingMinute(a)
          dispatch(showSuccess("meeting-minutes-closed-meeting"))
        }
        else {
          let errorMsg = "error"
          if (result.payload) {
            if (result.payload.kind === 'http') {
              const problem = result.payload.problem
              if (problem) {
                errorMsg = problem.title
              }
            }
          }
          dispatch(showError(errorMsg))
        }
      }
      catch (error) {
        dispatch(showError("error"))
      }
      finally{
        setSaving(false)
      }
    }
    setShowCloseMeetingDialog(false)
    setSaving(false)
}


  const handleCancelCloseMeetingClick = useCallback(() => {
    setShowCloseMeetingDialog(false)
  }, [])

  const handlePrintReportClick = async () => {
    if (meetingMinute) {
        window.open(`${process.env.REACT_APP_REST_API}api/reports/meeting-minutes/general/${meetingMinute.id}?language=${locale}`, '_blank')
    }
  }

  const handlePrintMeetingReportClick = async () => {
    if (meetingMinute) {
        window.open(`${process.env.REACT_APP_REST_API}api/reports/meeting-minutes/lastmeeting/${meetingMinute.id}?language=${locale}`, '_blank')
    }
  }

  const handleTabChange = useCallback((_, newTab) => {
    setTab(newTab)
  }, [])

  useEffect(() => {
    if(meetingMinute.parentObjectGuid && !processesLoaded){
      dispatch(loadForProject(meetingMinute.parentObjectGuid))
      .then(unwrapResult)
      .then(setProcesses)
      .then(() => { setProcessesLoaded(true) })
      .catch((err) => setError(err))
    }
  }, [meetingMinute])
  
  useEffect(() => {
    if (!attachmentLoaded && meetingMinute) {
      dispatch(loadManyBlobs([{ docType: 'meetingMinute', docId: meetingMinute.id }, [meetingMinute.id]]))
        .then(unwrapResult)
        .then(() => { setAttachmentLoaded(true) })
    }
  }, [meetingMinute, attachmentLoaded])

  const openCreateItemDialog = useCallback(() => {
    setCreateItemDialogVisible(true)
  }, [setCreateItemDialogVisible])

  const handleBackButtonClick = useCallback(() => {
    navigate(-1)
  }, [navigate])

  const onSuccess = useCallback((item: MeetingMinuteItem) => {
    setMeetingMinute(produce(draft => {
      if (draft !== undefined) {
        if (item.itemType == "TASK") {
          const index = draft.items.findIndex(u => u.id === item.parentItemId)
          if (index >= 0) {
            const taskIndex = draft.items[index].tasks.findIndex(x => x.id == item.id)
            if (taskIndex >= 0) {
              draft.items[index].tasks[taskIndex] = item
            }
            else {
              draft.items[index].tasks.push(item)
            }
          }
        } else {
          const index = draft.items.findIndex(u => u.id === item.id)
          if (index >= 0) {
            draft.items[index] = item
          } else {
            draft.items.push(item)
          }
        }
      }
    }))
    setCreateItemDialogVisible(false)
    setCreateTaskDialogVisible(false)
    handleRowClick(item)
    setClearDescription(true)
  }, [dispatch])


  const handleHideCreateDialog = useCallback(() => setCreateItemDialogVisible(false), [])

  // -----------------------------
  // Edit Item SECTION
  // -----------------------------
  const [detailPanelOpen, setDetailPanelOpen] = useState(true)

  const [expandedRows, setExpandedRows] = useState<string[]>([]);
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null);
  const [selectedRow, setSelectedRow] = useState<MeetingMinuteItem>();
  const [itemName, setItemName] = useState("")
  const [itemProjectProcessId, setItemProjectProcessId] = useState("")
  const [itemDescription, setItemDescription] = useState("")
  const [itemDeadline, setItemDeadline] = useState<Date | null>(null)
  const [itemPriority, setItemPriority] = useState("M")
  const [itemStatus, setItemStatus] = useState("NEW")
  const [itemResponsiblePerson, setItemResponsiblePerson] = useState("")
  const rowActionsAvailable = isEditable && !!selectedRow 

  const debounceTest = useCallback(debounce(async (field: string, value: string) => {
    setMeetingMinute(produce(draft => {
      if (draft !== undefined && selectedRow) {
        if (selectedRow.itemType === "TASK") {
          const index = draft.items.findIndex(u => u.id === selectedRow.parentItemId)
          if (index >= 0) {
            const taskIndex = draft.items[index].tasks.findIndex(x => x.id == selectedRow.id)
            draft.items[index].tasks[taskIndex][field] = value
          }

        }
        else {
          const index = draft.items.findIndex(u => u.id === selectedRow.id)
          if (index >= 0) {
            draft.items[index][field] = value
          }
        }
      }
    }))
  }, 1), [selectedRow])


  const debounceSave = useCallback(debounce(async (entity: MeetingMinuteItem
  ) => {
    if (entity) {
      try {
        const result =
          await dispatch(updateMeetingMinuteItem(
            entity

          ))

        if (updateMeetingMinuteItemTask.fulfilled.match(result)) {
          const itemTask = result.payload as MeetingMinuteItem;
          //dispatch(showSuccess("saved"))

        }
        else {
          let errorMsg = "error"
          if (result.payload) {
            if (result.payload.kind === 'http') {
              const problem = result.payload.problem
              if (problem) {
                errorMsg = problem.title
              }
            }
          }
          // dispatch(showError(errorMsg))
        }
      }
      catch (error) {
        //dispatch(showError("error"))
      }
    }
  }, 400), [selectedRow])

  const handleItemNameChange: AppTextFieldHandler = e => {
    setItemName(e.target.value)

    if (selectedRow) {
      debounceTest("name", e.target.value)
      debounceSave({
        ...selectedRow,
        name: e.target.value,
        description: itemDescription,
        priority: itemPriority,
        responsiblePerson: itemResponsiblePerson,
        deadline: itemDeadline ? itemDeadline.toISOString() : null,
        status: itemStatus,
        projectProcessId: parseInt(itemProjectProcessId)
      })
    }
  }

  const handleItemProcessChange: AppTextFieldHandler = e => {
    setItemProjectProcessId(e.target.value)

    if (selectedRow) {
      debounceTest("projectProcessId", e.target.value)
      console.log(e.target.value)
      debounceSave({
        ...selectedRow,
        name: itemName,
        description: itemDescription,
        priority: itemPriority,
        responsiblePerson: itemResponsiblePerson,
        deadline: itemDeadline ? itemDeadline.toISOString() : null,
        status: itemStatus,
        projectProcessId: parseInt(e.target.value)
      })
    }
  }

  const handleresponsiblePersonChange: AppTextFieldHandler = e => {
    setItemResponsiblePerson(e.target.value)

    if (selectedRow) {
      debounceTest("responsiblePerson", e.target.value)

      debounceSave({
        ...selectedRow,
        name: itemName,
        description: itemDescription,
        priority: itemPriority,
        responsiblePerson: e.target.value,
        deadline: itemDeadline ? itemDeadline.toISOString() : null,
        status: itemStatus,
        projectProcessId: parseInt(itemProjectProcessId)
      })
    }
  }


  const handleItemPriorityChange: AppTextFieldHandler = e => {
    setItemPriority(e.target.value)

    if (selectedRow) {
      debounceTest("priority", e.target.value)
      debounceSave({
        ...selectedRow,
        name: itemName,
        description: itemDescription,
        priority: e.target.value,
        responsiblePerson: itemResponsiblePerson,
        deadline: itemDeadline ? itemDeadline.toISOString() : null,
        status: itemStatus,
        projectProcessId: parseInt(itemProjectProcessId)
      })
    }
  }

  const handleItemStatusChange: AppTextFieldHandler = e => {
    setItemStatus(e.target.value)

    if (selectedRow) {
      debounceTest("status", e.target.value)
      debounceSave({
        ...selectedRow,
        name: itemName,
        description: itemDescription,
        priority: itemPriority,
        responsiblePerson: itemResponsiblePerson,
        deadline: itemDeadline ? itemDeadline.toISOString() : null,
        status: e.target.value,
        projectProcessId: parseInt(itemProjectProcessId)
      })
    }
  }

  const handleDeadlineChange = (newDate: Date) => {
    setItemDeadline(newDate);

    if (selectedRow) {
      debounceTest("deadline", newDate.toISOString())
      debounceSave({
        ...selectedRow,
        name: itemName,
        description: itemDescription,
        priority: itemPriority,
        responsiblePerson: itemResponsiblePerson,
        deadline: newDate.toISOString(),
        status: itemStatus,
        projectProcessId: parseInt(itemProjectProcessId)
      })
    }

  };

    const handleRowClick = (row: MeetingMinuteItem) => {
        setSelectedRowId(row.id)
        setSelectedRow(row)

        setItemName(row.name)
        setItemDescription(row.description);
        setItemResponsiblePerson(row.responsiblePerson);
        setItemDeadline(row.deadline ? parseISODate(row.deadline) : null)
        setItemPriority(row.priority)
        setItemStatus(row.status)
        setItemProjectProcessId(row.projectProcessId != null ? String(row.projectProcessId) : "")
        if (editor) {
            editor.commands.setContent(row.description)
            editor.commands.focus()
        }
    };

  // Funkcja do obsługi kliknięcia przycisku expand/collapse
  const handleExpandClick = (id: string) => {
    const isRowCurrentlyExpanded = expandedRows.includes(id);
    setExpandedRows(isRowCurrentlyExpanded ?
      expandedRows.filter(expandedId => expandedId !== id) : // Usuwa ID z tablicy, jeśli jest już rozwinięte
      [...expandedRows, id] // Dodaje ID do tablicy, jeśli jest zwiniete
    );
  };

  const [createTaskDialogVisible, setCreateTaskDialogVisible] = useState(false)
  const [deleteItemDialogVisible, setDeleteItemDialogVisible] = useState(false)
  
  const handleAddTaskClick = useCallback(() => {
    setCreateTaskDialogVisible(true)
  }, [setCreateTaskDialogVisible])

  const handleDeleteItemClick =  useCallback(() => {
    setDeleteItemDialogVisible(true)
  }, [setDeleteItemDialogVisible])

  const handleCancelDelClick = useCallback(() => {
    setDeleteItemDialogVisible(false)
  }, [setDeleteItemDialogVisible])


  const handleConfirmDelItemClick = async () => {
    if (selectedRow) {
        setSaving(true)
        try {            
            const result = await dispatch(deleteMeetingMinuteItem(selectedRow.id))

            if (deleteMeetingMinuteItem.fulfilled.match(result)) {
              dispatch(showSuccess("deleted"))
              setSelectedRowId(null)
              setSelectedRow(undefined)
              setItemName("")
              setItemDescription("");
              setItemResponsiblePerson("");
              setItemPriority("")
              setItemStatus("")
              setItemProjectProcessId("")

              setMeetingMinute(produce(draft => {
                if (draft !== undefined && selectedRow) {
                  if (selectedRow.itemType === "TASK") {
                    const index = draft.items.findIndex(u => u.id === selectedRow.parentItemId)
                    if (index >= 0) {
                      const taskIndex = draft.items[index].tasks.findIndex(x => x.id == selectedRow.id)
                      if (taskIndex >= 0){
                        draft.items[index].tasks.splice(taskIndex, 1)
                      }
                    }
          
                  }
                  else {
                    const index = draft.items.findIndex(u => u.id === selectedRow.id)
                    if (index >= 0) {
                      draft.items.splice(index, 1)
                    }
                  }
                }
              }))
            }
            else {
              let errorMsg = "error"
              if (result.payload) {
                if (result.payload.kind === 'http') {
                  const problem = result.payload.problem
                  if (problem) {
                    errorMsg = problem.title
                  }
                }
              }
              dispatch(showError(errorMsg))
            }
          }
          catch (error) {
            dispatch(showError("error"))
          }
          finally{
            setSaving(false)
            setDeleteItemDialogVisible(false)
          }

        setSaving(false)
        setDeleteItemDialogVisible(false)
    }
  }


  const handleAddTaskCancel = useCallback(() => setCreateTaskDialogVisible(false), [])


  // -----------------------------
  // Demo SECTION
  // -----------------------------
  const [attendeanceDialogVisible, setAttendeanceDialogVisible] = useState(false);
  const [selectedMeeting, setSelectedMeeting] = useState<Meeting>();

  const attendateDialogClick = useCallback((entity: Meeting) => {
    setSelectedMeeting(entity)
    setAttendeanceDialogVisible(true)
  }, [setAttendeanceDialogVisible])


  const attendeeColumns = [
    { field: 'person', headerName: 'Imię', flex: 1 },
    { field: 'company', headerName: 'Nazwisko', width: 300 },
    { field: 'email', headerName: 'Email', width: 250 }
  ];

  const meetingColumns: GridColDef[] = [
    {
      width: 20,
      sortable: false,
      hideable: false,
      field: 'lineNo',
      headerName: '#',
      flex: 0,
      filterable: false,
      editable: false,
      renderCell: (index) =>
        index.api.getRowIndexRelativeToVisibleRows(index.row.id) + 1,
    },
    { 
      field: 'name',
      headerName: l10n.getString("meeting-minutes-meeting-title", null, "Tytuł spotkania"),
      flex: 1, 
      minWidth: 200 
    },
    {
      field: 'startDate', 
      headerName: l10n.getString("meeting-minutes-meeting-start-time", null, "Start spotkania"),
      width: 200, 
      type: 'date',
      valueFormatter: (value) => {
        if (!value) {
          return "";
        }
        return format(parseISO(value), 'dd-MM-yyyy HH:mm:ss');
      }
    },
    {
      field: 'endDate', 
      headerName: l10n.getString("meeting-minutes-meeting-end-time", null, "Koniec spotkania"),
      width: 200, 
      type: 'date',
      valueFormatter: (value) => {
        if (!value) {
          return "";
        }
        return format(parseISO(value), 'dd-MM-yyyy HH:mm:ss');
      }
    },
    { 
      field: 'plannedMeetingTime', 
      headerName: l10n.getString("meeting-minutes-meeting-planned-time", null, "Planowany czas (minuty)"),
      width: 200 
    },
    {
      field: 'duration',
      headerName: l10n.getString("meeting-minutes-meeting-time", null, "Czas (minuty)"),
      width: 150,
      renderCell:  (params) => {
        const row = params.row;

        if (!row.startDate || !row.endDate){
          return '-'
        }
        const start = new Date(row.startDate);
        const end = new Date(row.endDate);
        const meetingLength = end.getTime() - start.getTime(); 
        const minutes = Math.floor(meetingLength / 60000); 
        const seconds = Math.floor((meetingLength % 60000) / 1000); 
      
        const difference = row.plannedMeetingTime * 60000 - meetingLength; 
        const diffminutes = Math.floor(Math.abs(difference) / 60000); 
        const diffseconds = Math.floor((Math.abs(difference) % 60000) / 1000); //

        const sign = difference > 0 ? "+" : "-"
        const color = difference > 0 ? 'green' : 'red';
        const formattedDuration = `${minutes}:${seconds.toString().padStart(2, '0')}`;
        const formattedDifference = `${sign}${diffminutes}:${diffseconds.toString().padStart(2, '0')}`;

        return (
          <div>
          {formattedDuration} <span style={{ color: color }}>({formattedDifference})</span>
        </div>
        );
    }
    },
    { 
      field: 'place', 
      headerName: l10n.getString("meeting-minutes-meeting-place", null, "Miejsce spotkania"),
      width: 200 
    },
    { 
      field: 'onDemand', 
      headerName: l10n.getString("meeting-minutes-on-demand", null, "Na żądanie"),
      width: 100 
    },
    {
      field: 'actions', type: 'actions', width: 200,
      renderCell: (params) => {
        return (
          <ButtonGroup size="small">
            <Button onClick={() => attendateDialogClick(params.row)} color="secondary">
              <Localized id="meeting-minutes-attendees-list">
                <span>Lista obecności</span>
              </Localized>
            </Button>
          </ButtonGroup>
        )
      }
    }
  ];

  const handleCancel = useCallback(() => setAttendeanceDialogVisible(false), [])


  const filteredRows = allItems.filter(row =>
    (!showHighPriority || row.priority === 'H') &&
    (!showActiveOnly || row.status === 'ACTIVE' || row.status === 'NEW') &&
    (!selectedProcessId || row.projectProcessId  && row.projectProcessId.toString() === selectedProcessId)
  ).sort((a, b) => a.no - b.no);

  if (meetingMinute === undefined) {
    return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
  }

    const emails: Set<Email> = new Set(attendees.map(a => a.email))

  return <>
    <Container maxWidth={false} sx={{padding:"2px", paddingLeft:"2px!important",  paddingRight:"2px!important"}}>
      <Stack padding="none">
        <Grid container padding="none">
          <Grid item xs={4}>
            <Paper sx={{height:"60px", padding:"5px"}}>
              <Typography variant="button">
                <span>{meetingMinute.title}</span>
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                <span>{createdDate} {meetingMinute.createdByFullName}</span>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={4}>
            <Paper sx={{height:"60px", padding:"5px"}}>
              <Typography variant="button" gutterBottom>
                <span>{lastMeeting?.name} {lastMeeting?.place}</span>
              </Typography>
              <Typography variant="subtitle1"  color="textSecondary" gutterBottom>
                <span>{lastMeetingStartDate}</span>
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper sx={{height:"60px", padding:"5px"}}>
              
              <If condition={meetingMinute.status == "INMEETING"} otherwise={
                <div>
                  <Typography variant="button" gutterBottom>
                    <span><MeetingStatusLabel status={meetingMinute.status}></MeetingStatusLabel></span>
                  </Typography>
                  <Typography variant="subtitle1"  color="textSecondary" gutterBottom>
                    <span>{lastMeetingEndDate}</span>
                  </Typography>
                </div>
              }>
                <div><SimpleTimer 
                  startDate={lastMeeting?.startDate as string} 
                  plannedMinutes={ lastMeeting?.plannedMeetingTime as number} 
                  status={meetingMinute.status}/></div>
              </If>
            </Paper>
          </Grid>
          <Grid item xs={2}>
            <Paper sx={{height:"60px", padding:"5px"}}>
              <If condition={meetingMinute.status == "INMEETING"} otherwise={
                  <div>
                    <Button
                      className={'mb-2'}
                      variant="text"
                      size="large"
                      color="success"
                      component={Link}
                      to={`/meeting/create/${meetingMinute.id}`}
                      startIcon={<PhoneInTalkIcon fontSize="large" />}>
                        Rozpocznij spotkanie
                    </Button>
                  </div>
                }>  
                <Button
                  className={'mb-2'}
                  variant="text"
                  size="large"
                  color="error"
                  onClick={() => handleCloseMeetingClick()}
                  startIcon={<PhoneDisabledIcon fontSize="large" />}>
                    Zakończ spotkanie
                </Button>
              </If>
            </Paper>
          </Grid>
        </Grid>
        <Grid container >
          <Grid item xs={12}>
            <TabContext value={tab}>
              <TabList onChange={handleTabChange}>
                <Tab
                  key={0}
                  value="items"
                  label={<Localized id="meeting-minutes-topics">Problemy</Localized>} />
                <Tab
                  key={1}
                  value="attendees"
                  label={<Localized id="meeting-minutes-attendees-list">Lista obecności</Localized>} />
               
                <Tab
                  key={2}
                  value="attachments"
                  label={<Localized id="attachments">Załaczniki</Localized>} />
                <Tab
                  key={3}
                  value="meetings"
                  label={<Localized id="meeting-minutes-meetings-history">Historia spotkań</Localized>} />
                <Tab
                  key={4}
                  value="statistics"
                  label={<Localized id="meeting-minutes-meetings-statistics">Statystyki</Localized>} />
                
              </TabList>
              <TabPanel value="items">
                <div className="flex flex-row gap-4">
                  <div className={clsx(detailPanelOpen ? 'basis-1/2' : 'basis-full')}>
                    <Box   
                      sx={{
                          border: '1px solid',
                          borderColor: 'grey.300', 
                          paddingLeft: '16px',
                          borderRadius: '4px', 
                          backgroundColor: "grey.100"
                      }} display="flex" alignItems="center">
                      <Typography variant="body1" sx={{ marginRight: '4px' }}>
                        Proces
                      </Typography>
                      <TextField
                            select
                            variant="standard"
                            size="small"
                            value={selectedProcessId}
                            onChange={handleSelectedProcessChange}
                            sx={{minWidth:"200px", marginRight:"20px"}}
                            >
                                <MenuItem value="">
                                    <span><Localized id="all">Wszystkie</Localized></span>
                                </MenuItem>
                                <Divider></Divider>
                                <ListSubheader><Localized id="processes">processes</Localized></ListSubheader>
                                {processes.map((process) => <MenuItem key={process.id} value={process.id}>{process.name}</MenuItem>)}
                      </TextField>
                      <FormControlLabel
                        control={
                          <Switch checked={showActiveOnly} onChange={handleShowActiveChange} size="small" />
                        }
                        label={<Localized id="meeting-minutes-show-all-active">W trakcie</Localized>}
                      />
                      <FormControlLabel
                        control={
                          <Switch checked={showHighPriority} onChange={handleShowHighPriorChange} size="small" />
                        }
                        sx={{ marginRight:"20px"}}
                        label={<Localized id="high-priority">Wysoki priorytet</Localized>}
                      />
                     
                    </Box>

                    <TableContainer component={Paper}
                      sx={{
                        maxHeight: 'calc(100vh - 400px)',
                      }}>
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>

                            <TableCell id="bt"  />
                            <TableCell id="type" padding="none" >
                              <Localized id='type'>Typ</Localized>
                            </TableCell>
                            <TableCell id="title" sx={{paddingLeft:"12px"}}>
                              <Localized id='meeting-minutes-topic-issue'>Temat/Problem</Localized>
                            </TableCell>
                            <TableCell id="process" sx={{paddingLeft:"12px"}}>
                              <Localized id='process'>Proces</Localized>
                            </TableCell>
                            <TableCell id="Termin" sx={{ minWidth:"150px", width: "150px", maxWidth: "150px" }}>
                              <Localized id='deadline'>Termin</Localized>
                            </TableCell>
                            <TableCell id="Odpowiedzialny" sx={{ minWidth:"150px", width: "150px", maxWidth: "150px" }} >
                              <Localized id='responsible'>Odpowiedzialny</Localized>
                            </TableCell>
                            <TableCell id="priority" sx={{ width: "30px", maxWidth: "30px", paddingLeft:"2px!important"}}>
                              S
                            </TableCell>
                            <TableCell id="status" sx={{ width: "30px", maxWidth: "30px" , paddingLeft:"8px!important"}}>
                              P
                            </TableCell>

                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredRows.map((row) =>
                            <React.Fragment key={row.id}>
                              <TableRow hover key={row.id} selected={selectedRowId === row.id} onClick={() => handleRowClick(row)}
                                sx={{
                                  backgroundColor: row.status === 'FINISHED' ? 'grey.100' : 'inherit',
                                }}>
                                <TableCell padding="none" sx={{ width: "10px", maxWidth: "10px" }}>
                                  <IconButton onClick={() => handleExpandClick(row.id)} >
                                    {row.tasks.length > 0 ? 
                                    (expandedRows.includes(row.id) ?  <ExpandMoreIcon /> : <ChevronRightIcon />) : 
                                    <RemoveIcon fontSize="small"/>}
                                  </IconButton>
                                </TableCell>
                                <TableCell padding="none" sx={{ width: "20px", maxWidth: "20px" }}>
                                  <ImportContactsIcon fontSize="small" color={row.status === 'FINISHED' ?  'disabled': 'success' }/>
                                </TableCell>
                                <TableCell sx={{paddingLeft:"12px"}}>
                                  {row.name}
                                </TableCell>
                                <TableCell>
                                  {findProcessName(row.projectProcessId)}
                                </TableCell>
                                <TableCell>
                                  {row.deadline ? parseAndFormatISODateWithTodayCompare(row.deadline, false, row.status !== 'FINISHED') : ''}
                                </TableCell>
                                <TableCell>
                                  {row.responsiblePerson}
                                </TableCell>
                                <TableCell sx={{ width: "30px", maxWidth: "30px" , padding:"2px!important"}}>
                                  <MeetingMinutesItemStatusLabel status={row.status} />
                                </TableCell >
                                <TableCell sx={{ width: "30px", maxWidth: "30px" , padding:"2px!important"}}>
                                  <MeetingMinutesItemPriorityLabel priority={row.priority} />
                                </TableCell>
                              </TableRow>
                              {expandedRows.includes(row.id) && (
                                <>
                                  {
                                    row.tasks
                                    .filter(row =>
                                      (!showHighPriority || row.priority === 'H') &&
                                      (!showActiveOnly || row.status === 'ACTIVE' || row.status === 'NEW')                                      
                                    ).sort((a, b) => a.no - b.no)
                                    .map((task, index) => (
                                      <TableRow hover selected={selectedRowId === task.id} onClick={() => handleRowClick(task)} key={task.id + '-task-' + index} 
                                        sx={{
                                          backgroundColor: task.status === 'FINISHED' ? 'grey.100' : 'inherit',
                                        }}>
                                        <TableCell padding="none" sx={{ width: "10px", maxWidth: "10px" }}></TableCell>
                                        <TableCell padding="none" sx={{ width: "20px", maxWidth: "20px" }}>
                                          <TaskIcon fontSize="small" color={task.status === 'FINISHED' ?  'disabled': 'info'} />
                                        </TableCell>
                                        <TableCell>{task.name}</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell>
                                          {task.deadline ? parseAndFormatISODateWithTodayCompare(task.deadline, false, task.status !== 'FINISHED') : ''}
                                        </TableCell>
                                        <TableCell>{task.responsiblePerson}</TableCell>
                                        <TableCell sx={{ width: "30px", maxWidth: "30px" , padding:"2px!important"}}>
                                          <MeetingMinutesItemStatusLabel status={task.status} />
                                        </TableCell>

                                        <TableCell sx={{ width: "30px", maxWidth: "30px" , padding:"2px!important"}}>
                                          <MeetingMinutesItemPriorityLabel priority={task.priority} />
                                        </TableCell>
                                      </TableRow>
                                    ))
                                  }
                                </>
                              )}
                            </React.Fragment>
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Button size="small"  disabled={!isEditable} startIcon={<AddIcon fontSize="small" />} variant="outlined" onClick={openCreateItemDialog} color="secondary" sx={{ marginTop: 2 }}>
                      <Localized id='meeting-minutes-add-topic'>Dodaj temat</Localized>
                    </Button>
                    <Button 
                      size="small"  
                      startIcon={<DeleteIcon fontSize="small" />} 
                      variant="outlined" 
                      onClick={handleDeleteItemClick}
                      disabled={!rowActionsAvailable}
                      sx={{ marginTop: 2, marginLeft: 1}}>
                      <Localized id='delete'>delete</Localized>
                    </Button>
                  </div>
                  <div className={clsx(detailPanelOpen ? 'basis-1/2' : 'basis-1')}>
                    <Paper className="p-2">
                      <div>
                        <Stack direction="row" sx={{ backgroundColor: "grey.100" }}>
                          <IconButton size="small" className={clsx({ 'hidden': !detailPanelOpen })} onClick={() => setDetailPanelOpen(false)}><ChevronRightIcon /></IconButton>
                          <IconButton size="small" className={clsx({ 'hidden': detailPanelOpen })} onClick={() => setDetailPanelOpen(true)}><ChevronLeftIcon /></IconButton>
                          <If condition={selectedRow?.itemType == "TASK"}>
                            <TaskIcon sx={{ marginTop: "7px" }} fontSize="small"  color={selectedRow?.status === 'FINISHED' ?  'disabled': 'success'} />
                            <Typography component="div" variant="subtitle2" sx={{ marginTop: "8px", marginLeft: "2px" }}>
                              <Localized id='meeting-minutes-task-details'>Szczegóły zadania</Localized>
                            </Typography>
                          </If>
                          <If condition={selectedRow?.itemType == "ITEM"}>
                            <ImportContactsIcon fontSize="small" color={selectedRow?.status === 'FINISHED' ?  'disabled': 'success' } sx={{ marginTop: "7px" }}></ImportContactsIcon>
                            <Typography component="div" variant="subtitle2" sx={{ marginTop: "8px", marginLeft: "2px" }}>
                              <Localized id='meeting-minutes-topic-description'>Opis problemu</Localized>
                            </Typography>
                          </If>

                        </Stack>
                        <Stack direction="row" spacing={1} justifyContent="flex-end" sx={{ marginBottom: "2px", backgroundColor: "grey.100" }}>
                          <Button
                            className={'mb-2'}
                            variant="outlined"
                            size="small"
                            onClick={handleAddTaskClick}
                            disabled={!rowActionsAvailable}
                            startIcon={<AddTaskIcon fontSize="small" />}>
                            <Localized id='meeting-minutes-task'>Zadanie</Localized>
                            
                          </Button>

                          <Button
                            variant="outlined"
                            className={'mb-2'}
                            size="small"
                            onClick={handleDeleteItemClick}
                            disabled={!rowActionsAvailable}
                            startIcon={<DeleteIcon fontSize="small" />}>
                            <Localized id='delete'>Usuń</Localized>
                          </Button>
                        </Stack>
                      </div>

                      <Divider />
                      <TextField size="small"
                        InputLabelProps={{
                          shrink: true
                        }}
                        fullWidth
                        onChange={handleItemNameChange}
                        disabled={!isEditable}
                        sx={{ marginTop: "10px" }}
                        value={itemName}
                        label={<Localized id='meeting-minutes-topic-issue'>Temat</Localized>}>
                      </TextField>
                      <If condition={selectedRow?.itemType == "ITEM"}>
                        <TextField
                            select
                            size="small"
                            disabled={!isEditable}
                            InputLabelProps={{
                              shrink: true
                            }}
                            value={itemProjectProcessId}
                            onChange={handleItemProcessChange}
                            label={<Localized id='process'>Proces</Localized>}
                            fullWidth
                            sx={{marginTop: "10px" }}
                            >
                                <MenuItem value="noprocess">
                                    <span><Localized id="noprocess">Bez procesu</Localized></span>
                                </MenuItem>
                                <Divider></Divider>
                                <ListSubheader><Localized id="processes">processes</Localized></ListSubheader>
                                {processes.map((process) => <MenuItem key={process.id} value={process.id}>{process.name}</MenuItem>)}
                        </TextField>
                      </If>
                      <Stack direction="row">
                        <TextField size="small"
                          InputLabelProps={{
                            shrink: true
                          }}
                          sx={{ marginTop: "10px" }}
                          value={itemResponsiblePerson ?? ""}
                          disabled={!isEditable}
                          onChange={handleresponsiblePersonChange}
                          label={<Localized id='responsible'>Odpowiedzialny</Localized>}>
                        </TextField>
                        <DatePicker
                          label={<Localized id='deadline'>Termin</Localized>}
                          views={['year', 'day']}
                          value={itemDeadline}
                          disabled={!isEditable}
                          onChange={(newVal) => newVal && handleDeadlineChange(newVal)}
                          slotProps={{
                              textField: {
                                  size: 'small',
                                  sx: { marginTop: "10px", maxWidth: "150px" },
                                  InputLabelProps: { shrink: true },
                              },
                          }}
                        />
                        <TextField
                          value={itemPriority}
                          select
                          size="small"
                          disabled={!isEditable}
                          label={<Localized id="priority"><span>Priorytet</span></Localized>}
                          onChange={handleItemPriorityChange}
                          sx={{ marginTop: "10px", minWidth: "140px" }}
                        >
                          <MenuItem value={"L"}>
                            <Box display="flex" alignItems="center">
                              <FlagIcon fontSize="small" color="disabled"></FlagIcon>
                              <Localized id="meeting-minutes-prior-low"><span>Niski</span></Localized>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"M"}>
                            <Box display="flex" alignItems="center">
                              <FlagIcon fontSize="small" color="info"></FlagIcon>
                              <Localized id="meeting-minutes-prior-medium"><span>Normalny</span></Localized>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"H"}>
                            <Box display="flex" alignItems="center">
                              <FlagIcon fontSize="small" color="error"></FlagIcon>
                              <Localized id="meeting-minutes-prior-high"><span>Wysoki</span></Localized>
                            </Box>
                          </MenuItem>
                        </TextField>
                        <TextField
                          value={itemStatus}
                          select
                          size="small"
                          disabled={!isEditable}
                          label={<Localized id="status"><span>Status</span></Localized>}
                          onChange={handleItemStatusChange}
                          sx={{ marginTop: "10px", minWidth: "150px" }}
                        >
                          <MenuItem value={"NEW"}>
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  marginRight: 1,
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                  backgroundColor: 'lightblue',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                              <Localized id="meeting-minutes-status-new"><span>Nowe</span></Localized>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"ACTIVE"}>
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  marginRight: 1,
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                  backgroundColor: 'green',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                              <Localized id="meeting-minutes-status-active"><span>W trakcie</span></Localized>
                            </Box>
                          </MenuItem>
                          <MenuItem value={"FINISHED"}>
                            <Box display="flex" alignItems="center">
                              <Box
                                sx={{
                                  marginRight: 1,
                                  width: 10,
                                  height: 10,
                                  borderRadius: '50%',
                                  backgroundColor: 'grey',
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                              <Localized id="meeting-minutes-status-finished"><span>Zakończone</span></Localized>
                            </Box>
                          </MenuItem>
                        </TextField>
                      </Stack>
                      <RichTextContainer label={<Localized id="meeting-minutes-task-current-status"><span>Opis aktualnego stanu</span></Localized>} editor={editor} />
                      <Divider className={'mb-2, mt-2'} />
                      <Typography component="div" variant="subtitle2" className={'mb-1, mt-2'} sx={{ marginBottom: "2px" }}>
                        <Localized id="meeting-minutes-task-history">Historia przebiegu</Localized>
                      </Typography>
                      <TableContainer component={Paper}>
                        <Table size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell id="date" sx={{
                                width: '120px',
                              }}>
                                <Localized id="date">Data</Localized>
                              </TableCell>
                              <TableCell id="entry">
                                <Localized id="entry">Wpis</Localized>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {selectedRow?.history.map(({ id, createDate, description, createdByFullName }) =>
                              <TableRow key={id}>
                                <TableCell sx={{ minWidth: 80, width: 80, maxWidth: 120 }}>
                                  <Typography color="text.secondary" component="div" variant="caption">
                                    <span>  {createDate ? new Date(createDate).toISOString().split('T')[0] : ""} </span><span>{createdByFullName}</span>
                                  </Typography>
                                </TableCell>
                                <TableCell>
                                  <div dangerouslySetInnerHTML={{ __html: description }} />
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Paper>
                  </div>
                </div>
              </TabPanel>
              <TabPanel value="meetings">
                <DataGrid
                  autoHeight
                  rows={meetingMinute.meetings}
                  columns={meetingColumns}
                  initialState={{
                    sorting: { sortModel: [{ field: 'startDate', sort: 'desc' }] }
                  }}
                />
              </TabPanel>
              <TabPanel value="attachments">
                <Attachements resourceId={meetingMinute.id} canDelete={true} canUpload={true} />
              </TabPanel>
              <TabPanel value="statistics">
                <MeetingMinutesStatistics
                  meetingMinuteId={meetingMinute.id}
                />
              </TabPanel>
              <TabPanel value="attendees">
                <div className="flex flex-row gap-8">
                    <div className="basis-1/2">
                        <List sx={{ height: 'calc(100vh - 400px)', overflowY: 'auto' }}>
                            {attendees.map((attendee, index) => <ListItem divider key={attendee.id} secondaryAction={<If condition={meetingMinute.status === 'INMEETING'}>
                                    <IconButton disabled={attendee.email === meetingMinute.meetings.find(x => x.id === meetingMinute.lastMeetingId)?.createdBy} onClick={() => setDeleteAttendee({ tag: 'delete', id: attendee.id })} edge='end' ><DeleteIcon /></IconButton>
                                </If>}>
                                <ListItemText primary={`${index + 1}. ${attendee.person}`} secondary={`${attendee.company}, ${attendee.email}`}></ListItemText>
                            </ListItem>)}
                        </List>
                    </div>
                    <div className="basis-1/2">
                        <If condition={meetingMinute.status === "INMEETING"}>
                            <Stack component={Paper}sx={{ p: 4 }} direction="column" spacing={2}>
                                <TextField
                                  fullWidth
                                  helperText={attendeesPersonError && <RequiredError />}
                                  error={attendeesPersonError}
                                  label="Imię i nazwisko"
                                  value={person}
                                  onChange={e => { setPerson(e.target.value); setSaving(false); } }
                                />
                                <Stack direction="row" spacing={2}>
                                    <TextField
                                      fullWidth
                                      helperText={attendeesCompanyError && <RequiredError />}
                                      error={attendeesCompanyError}
                                      label="Firma"
                                      value={company}
                                      onChange={e => { setCompany(e.target.value); setSaving(false); } }
                                    />
                                    <TextField fullWidth label="Email" value={email} onChange={e => setEmail(e.target.value)} />
                                    <LoadingButton loading={saving} onClick={() => handleAddAttendeeToMeeting()}>Dodaj</LoadingButton>
                                </Stack>
                                <Box><span>lub </span> <LoadingButton loading={saving} onClick={() => setAppUsersDialogOpen(true)}>dodaj z listy użytkowników aplikacji</LoadingButton></Box>
                            </Stack>
                        </If>
                    </div>
                </div>
              </TabPanel>
            </TabContext>
          </Grid>
        </Grid>
        <BottomAppBar>
          <Button key="back" onClick={handleBackButtonClick} color="secondary" variant="outlined">
            <Localized id="back">Wróć</Localized>
          </Button>
          <Button color="primary" onClick={() => handlePrintReportClick()} variant="outlined"  >
            <Localized id="report-general">
              <span>Wydruk zbiorczy</span>
            </Localized>
          </Button>
          <Button color="primary" onClick={() => handlePrintMeetingReportClick()} variant="outlined"  >
            <Localized id="report-meeting">
              <span>Wydruk spotkanie</span>
            </Localized>
          </Button>
          <Button color="primary" onClick={() => handleShowSendReportDialog()} variant="outlined"  >
            <Localized id="report-send-to-attendees">
              <span>Wyślij raport do uczestników</span>
            </Localized>
          </Button>
        </BottomAppBar>
      </Stack>
    </Container>
    <Dialog open={createItemDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
      <CreateMeetingMinutesItem
        meetingMinutesId={String(meetingMinute.id)}
        processes={processes}
        onSucces={onSuccess}
        onCancel={handleHideCreateDialog}
      />
    </Dialog>
    <Dialog open={attendeanceDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
      <DialogTitle>Lista obecnosci</DialogTitle>
      <DialogContent>
        <DataGrid
          rows={selectedMeeting?.attendanceList as Attendee[]}
          autoHeight
          columns={attendeeColumns}
        />
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleCancel}>
          <Localized id='save'>OK</Localized>
        </LoadingButton>
        <LoadingButton onClick={handleCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
      </DialogActions>
    </Dialog>
    <Dialog open={createTaskDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
      <AddItemTaskDialog
        parentRow={selectedRow as MeetingMinuteItem}
        onSucces={onSuccess}
        onCancel={handleAddTaskCancel}
      />
    </Dialog>
    <Dialog open={deleteItemDialogVisible}>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmDelItemClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton  loading={saving} onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
    <Dialog open={showSendGeneralReportDialog} fullWidth maxWidth='sm'>
        <DialogTitle>
            <Localized id='meeting-minutes-send-report-meeting'>meeting-minutes-send-report-meeting</Localized>
        </DialogTitle>
        <DialogContent>
          <EmailInput 
              meetingMinute={meetingMinute} 
              onListOfRecipientsChange={handleListOfRecipientsChanged}
              onAttachNoteAttachmentsChanged={handleAttachNoteAttachmentsChanged}></EmailInput>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmSendGeneralReport}><Localized id='send'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelSendGeneralReportClick}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
    <Dialog open={showCloseMeetingDialog} fullWidth maxWidth='xs'>
        <DialogTitle>
            <Localized id='meeting-minutes-close-meeting-question'>Czy zakończyć spotkanie?</Localized>
        </DialogTitle>
        <DialogContent>
        <FormGroup>
          <FormControlLabel control={<Checkbox 
           checked={sendGeneralReportCheckbox}
           onChange={handleSendGeneralReportChange}
          />} label={<Localized id='meeting-minutes-send-report-meeting'>meeting-minutes-send-report-meeting</Localized>} />
          <If condition={sendGeneralReportCheckbox}>
            <EmailInput meetingMinute={meetingMinute} 
                onListOfRecipientsChange={handleListOfRecipientsChanged}
                onAttachNoteAttachmentsChanged={handleAttachNoteAttachmentsChanged}></EmailInput>
          </If> 
        </FormGroup>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmCloseMeetingClick}><Localized id='ok'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelCloseMeetingClick}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
    <Dialog open={deleteAttendee.tag === 'delete'}>
      <DialogTitle>
          <Localized id='delete'>Usuń</Localized>
      </DialogTitle>
      <DialogContent>
          <Localized id='confirm-delete'>Czy napewno chcesz usunąć?</Localized>
      </DialogContent>
      <DialogActions>
          <Button onClick={handleConfirmDeleteAttendee}>
              <Localized id='ok'>Ok</Localized>
          </Button>
          <Button onClick={() => setDeleteAttendee({ tag: 'none' })}>
              <Localized id='cancel'>Anuluj</Localized>
          </Button>
      </DialogActions>
    </Dialog>
    <Dialog open={appUsersDialogOpen} fullWidth maxWidth='lg'>
      <DialogTitle>Użytkownicy</DialogTitle>
      <DialogContent>
          <UsersBrowser gridRef={usersGridRef} disabled={emails} />
      </DialogContent>
      <DialogActions>
          <Button onClick={handleAddAppUsers}>
              <Localized id='ok'>OK</Localized>
          </Button>
          <Button onClick={() => setAppUsersDialogOpen(false)}>
              <Localized id='cancel'>Anuluj</Localized>
          </Button>
      </DialogActions>
    </Dialog>
  </>
}
