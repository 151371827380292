import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { updateUrsGroup } from "../UrsSlice"

export interface EditGroupDialogProps {
    groupId: AppId
    name: string
    code: string
    onSucces: () => void
    onCancel: () => void
}

export const EditGroupDialog = (props: EditGroupDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [name, setName] = useState(props.name)
    const [serverError, setServerError] = useState<string | null>(null)
    const [requiredError, setRequiredError] = useState<boolean | null>(null)

    const handleOk = () => {
        name ? setRequiredError(false) : setRequiredError(true)
        if (name) {
            setSaving(true)
            dispatch(updateUrsGroup({id: props.groupId, name, code: props.code, }))
                .then(unwrapResult)
                .then(() => {
                    props.onSucces()
                })
                .catch(err => {
                    setServerError(err.kind + ': ' + err.status.toString())
                })
                .finally(() => setSaving(false))
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='urs-edit-main-group'>Edytuj grupę główną</Localized></DialogTitle>
        <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
                <TextField 
                    fullWidth
                    autoFocus
                    error={requiredError === true}
                    helperText={requiredError && <Localized id='required'>To pole jest wymagane</Localized>}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={<Localized id='urs-name'>Nazwa</Localized>}
                />
                {serverError && <Alert severity='error'>{serverError}</Alert>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOk}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
