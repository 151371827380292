import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, MenuItem, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch } from "../../../../app/hooks"
import { useCallback, useState } from "react"
import { showError, showSuccess } from "../../../notifications/notificationsSlice"
import { MeetingMinuteItem, createMeetingMinuteItemTask } from "../meetingMinutesSlice"
import { AppTextFieldHandler } from "../../../../app/appTypes"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface AddItemTaskDialogProps {
    parentRow: MeetingMinuteItem,
    onSucces: (item: MeetingMinuteItem) => void
    onCancel: () => void
}

export const AddItemTaskDialog = (props: AddItemTaskDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [priority, setPriority] = useState("M")
    const [responsiblePerson, setResponsiblePerson] = useState("")
    const [responsibleCompany, setResponsibleCompany] = useState("")
    const meetingMinutesId = props.parentRow.meetingMinutesId;
    const parentItemId = props.parentRow.itemType == "TASK" ?  props.parentRow.parentItemId : props.parentRow.id;

    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset w milisekundach
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0, -1);
        return localISOTime.split('T')[0];
    }
    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [deadline, setDeadline] = useState(today)


    const [validationErrors, setValidationErrors] = useState(false)
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handlePriorityChange: AppTextFieldHandler = useCallback(e => setPriority(e.target.value), [setPriority])
    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setDeadline(e.target.value)
    }, [])
    const handleresponsiblePersonChange: AppTextFieldHandler = useCallback(e => setResponsiblePerson(e.target.value), [setResponsiblePerson])

    const handleOk = async () => {
        setValidationErrors(true)
        setSaving(true)
        try {
            const result =
                await dispatch(createMeetingMinuteItemTask({
                    name: name,
                    description: description,
                    priority: priority,
                    meetingMinutesId: meetingMinutesId,
                    responsiblePerson: responsiblePerson,
                    responsibleCompany: responsibleCompany,
                    deadline: deadline,
                    parentItemId: parentItemId
                }))

            if (createMeetingMinuteItemTask.fulfilled.match(result)) {
                const itemTask = result.payload as MeetingMinuteItem;
                dispatch(showSuccess("saved"))
                props.onSucces(itemTask)
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setSaving(false)
        }
    }

    return <>
        <Dialog open={true} fullWidth maxWidth='sm'>
            <DialogTitle>
                <Localized id=''>Nowe Zadanie</Localized>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12} >
                        <TextField
                            error={name === ""}
                            label={<Localized id="">
                                <span>Zadanie</span>
                            </Localized>}
                            inputProps={{ maxLength: 1000 }}
                            fullWidth
                            value={name}
                            onChange={handleNameChange}
                            sx={{ marginTop: "10px" }}
                        />
                    </Grid>
                    <Grid item xs={12} >
                        <TextField
                            label={<Localized id="">
                                <span>Osoba odpowiedzialna</span>
                            </Localized>}
                            inputProps={{ maxLength: 1000 }}
                            fullWidth
                            value={responsiblePerson}
                            onChange={handleresponsiblePersonChange}
                            sx={{ marginTop: "10px" }}
                        />
                    </Grid>
                    <Grid item xs={6} >
                        <TextField
                            sx={{ marginTop: "10px" }}
                            type="date"
                            fullWidth
                            value={deadline}
                            InputLabelProps={{ shrink: true }}
                            inputProps={{
                                min: getLocalISODate(new Date())
                            }}
                            onChange={handleDeadlineFieldChange}
                            label={<Localized id="deadline"><span>Termin:</span></Localized>}
                        />
                    </Grid>

                    <Grid item xs={6} >
                        <TextField
                            value={priority}
                            fullWidth
                            required={true}
                            select
                            label={<Localized id=""><span>Priorytet</span></Localized>}
                            onChange={handlePriorityChange}
                            sx={{ marginTop: "10px" }}
                        >

                            <MenuItem value={"L"}><Localized id="L"><span>Niski</span></Localized></MenuItem>
                            <MenuItem value={"M"}><Localized id="M"><span>Normalny</span></Localized></MenuItem>
                            <MenuItem value={"H"}><Localized id="H"><span>Wysoki</span></Localized></MenuItem>
                        </TextField>
                    </Grid>

                </Grid>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} onClick={handleOk}>
                    <Localized id='save'>OK</Localized>
                </LoadingButton>
                <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
