import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import { Button, ButtonGroup, Grid, MenuItem, Paper } from "@mui/material"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Link } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { ChangeEvent, useMemo, useState } from "react"
import SaveIcon from '@mui/icons-material/Save'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Skeleton } from '@mui/material'
import { selectAllCompanies } from '../company/companiesSlice'
import { LoadDeviceTypes } from '../../app/AppDataLoader'
import { AppLoader } from '../../app/AppLoader'
import { selectAllDeviceTypes } from '../device/deviceTypesSlice'
import { createSupplierDevice } from './supplierDeviceSlice'
import { useNavigate } from 'react-router-dom'


const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const CreateCostEstimationComponents = () => {
    const [name, setName] = useState<string>("");    
    const [description, setDescription] = useState<string>("");
    const [productionYear, setProductionYear] = useState<number>( 0);
    const [serialNo, setSerialNo] = useState<string>("");
    const [structureId, setDeviceTypeId] = useState<string>("");
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const allDeviceTypes = useAppSelector(selectAllDeviceTypes)
    const allCompanies = useAppSelector(selectAllCompanies)

    
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if(name && structureId){
                try {
                    const actionResult = await dispatch(createSupplierDevice({                     
                        name,  
                        serialNo, 
                        productionYear, 
                        description, 
                        structureId
                    }));
                    const { id: newId } = unwrapResult(actionResult);

                    dispatch(showSuccess("saved"));

                    navigate(`/costestimationcomponents/edit/${newId}`, { replace: true });
                } catch (error) {
                    dispatch(showError("error"));
                }
            } 
        setSaving(false)
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setName(e.target.value), [setName]);

    const handleProductionYearChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setProductionYear(parseInt(e.target.value)), [setProductionYear]);

    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setDescription(e.target.value), [setDescription]);

    const handleSerialNoChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setSerialNo(e.target.value), [setSerialNo]);   

    const handleTypeChange: (e: ChangeEvent<HTMLInputElement>) => void = 
            useMemo(() => 
                (e) => setDeviceTypeId(e.target.value), [setDeviceTypeId]);  


    const nameError = validationErrors && name === ""
    const deviceTypeIdError = validationErrors && structureId === ""

    return (
        <>
            <Container maxWidth="md" sx={{paddingTop: 2, paddingBottom: 2}}>
                <Paper >
                <Grid container spacing={2} sx={{padding:2}}>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={nameError}
                            helperText={nameError && <RequiredError />}
                            multiline={true}       
                            inputProps={{ maxLength: 200 }}                                 
                            value={name}   
                            onChange={handleNameChange}                        
                            label={<Localized id="">Nazwa</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}>
                        <LoadDeviceTypes component={<Skeleton variant="rectangular"/>}>
                            <TextField 
                                select 
                                required
                                error={deviceTypeIdError}
                                helperText={deviceTypeIdError && <RequiredError />}
                                fullWidth                               
                                value={structureId}  
                                onChange={handleTypeChange} 
                                label={<Localized id="devices-type">Typ</Localized>}>
                                    {allDeviceTypes.map((devType) => <MenuItem key={devType.id} value={devType.id}>{devType.name}</MenuItem>)}
                            </TextField>
                        </LoadDeviceTypes>
                        </Grid>

                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={description}                 
                            onChange={handleDescriptionChange}                 
                            multiline     
                            minRows={4}                     
                            label={<Localized id="devices-description">Opis</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <ButtonGroup color="secondary" variant="contained">
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                            <Button  component={Link} to={`/supplierdevices`} color="secondary" variant="outlined">
                                <Localized id="back">Wróć</Localized>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>                    
                </Paper>
            </Container>
            <AppLoader open={saving} />
        </>
    );
}
