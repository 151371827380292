import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { createUrsMainGroup, Urs } from "../UrsSlice"

export interface CreateMainGroupDialogProps {
    ursId: AppId
    onSucces: (data: Urs) => void
    onCancel: () => void
}

export const CreateMainGroupDialog = (props: CreateMainGroupDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [name, setName] = useState('')
    const [serverError, setServerError] = useState<string | null>(null)
    const [requiredError, setRequiredError] = useState<boolean | null>(null)
    const [code, setCode] = useState<string>('')
    const [codeRequired, setCodeRequired] = useState<null | boolean>(null)

    const handleOk = () => {
        name ? setRequiredError(false) : setRequiredError(true)
        code ? setCodeRequired(false) : setCodeRequired(true)
        if (name) {
            setSaving(true)
            dispatch(createUrsMainGroup({ursId: props.ursId, name, code, systemTypeId: null}))
                .then(unwrapResult)
                .then(data => {
                    props.onSucces(data)
                })
                .catch(err => {
                    setServerError(err.kind + ': ' + err.status.toString())
                })
                .finally(() => setSaving(false))
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id='urs-create-main-group'>Utwórz grupę główną</Localized></DialogTitle>
        <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
                <TextField
                    sx={{ maxWidth: 200 }}
                    value={code}
                    onChange={e => setCode(e.target.value)}
                    label={<Localized id='urs-code'>Kod</Localized>}
                    error={codeRequired === true}
                    helperText={codeRequired && <Localized id='required'>To pole jest wymagane</Localized>}
                    inputProps={{ maxLength: 100 }}
                />
                <TextField 
                    fullWidth
                    autoFocus
                    error={requiredError === true}
                    helperText={requiredError && <Localized id='required'>To pole jest wymagane</Localized>}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={<Localized id='urs-name'>Nazwa</Localized>}
                />
                {serverError && <Alert severity='error'>{serverError}</Alert>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOk}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
