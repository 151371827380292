import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogTitle, DialogContent, Grid, TextField, Alert, DialogActions } from "@mui/material"
import { useState, useCallback } from "react"
import { AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { If } from "../../../app/If"
import { showSuccess } from "../../notifications/notificationsSlice"
import { Activity } from "../activitiesSlice"
import { CreateStepAction } from "../types"

export interface CreateStepDialogFormProps {
    data: Pick<Activity, "validationId">
    close: () => void
    create: CreateStepAction
}

export const CreateStepDialogForm = ({ data, create, close }: CreateStepDialogFormProps) => {
    const dispatch = useAppDispatch()
    const { validationId } = data
    const [name, setName] = useState("")

    const [error, setError] = useState<string | undefined>(undefined)
    const [saving, setSaving] = useState(false)

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])

    const handleOKClick = async () => {
        if (name) {
            try {
                setSaving(true)
                await dispatch(create({ validationId, name, acceptanceCriteria: [] })).unwrap()
                close()
                dispatch(showSuccess("saved"))
            } catch (error) {
                setError("error")
                setSaving(false)
            }
        }
    }

    return <>
        <DialogTitle>
            <Localized id="validation-activity-new">Nowy kroku testu</Localized>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        sx={{ marginTop: 2 }}
                        value={name}
                        inputProps={{ maxLength: 200 }}
                        onChange={handleNameChange}
                        label={<Localized id="activity-name">Nazwa kroku</Localized>}
                    />
                </Grid>
                <If condition={error !== undefined}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <Localized id="error">Błąd</Localized>
                        </Alert>
                    </Grid>
                </If>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOKClick}>
                <Localized id="ok">OK</Localized>
            </LoadingButton> 
            <LoadingButton loading={saving} onClick={close}>
                <Localized id="cancel">Anuluj</Localized>
            </LoadingButton> 
        </DialogActions>
    </>
}
