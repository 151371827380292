import { Localized } from "@fluent/react"
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Skeleton, TextField } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { LoadUsers } from "../../../app/AppDataLoader"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { If } from "../../../app/If"
import { store } from "../../../app/store"
import { selectAllUsers, selectUserByName, User } from "../../users/usersSlice"
import { LoadingButton } from "@mui/lab"
import { APIError, AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { unwrapResult } from "@reduxjs/toolkit"
import { createTeamMember, ProjectTeamMember } from "../projectSlice"
import { ServerErrorMsg } from "../../../app/ServerErrorMsg"

export interface ProjectTeamMemberDialogProps {
    projectId: AppId
    mode: "create" | "edit"
    defaultUserName: string
    excludedUsers: Set<string>
    onSave: (tm: ProjectTeamMember) => void
    onCancel: () => void,
}

export const ProjectTeamMemberDialog = ({ projectId, mode, defaultUserName, onSave, onCancel, excludedUsers }: ProjectTeamMemberDialogProps) => {
    const dispatch = useAppDispatch()
    const allUsers = useAppSelector(selectAllUsers)
    const [user, setUser] = useState<User | null>(() => selectUserByName(store.getState(), defaultUserName) ?? null)
    const [userRole, setUserRole] = useState<string>("")
    const [error, setError] = useState<APIError | null>(null)

    const [savingInProgress, setSavingInProgress] = useState(false)
    const handleUserChange = useCallback((_, newUser) => {
        setUser(newUser)
    }, [])

    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setUserRole(e.target.value), [setUserRole])

    const availableUsers =
        useMemo(() => allUsers.filter(u => !excludedUsers.has(u.userName) || u.userName === defaultUserName), [allUsers, excludedUsers, defaultUserName])

    const handleOkClick = () => {
        if (user !== null) {
            setSavingInProgress(true)
            switch (mode) {
                case "create":
                    dispatch(createTeamMember({ user: user.userName, projectId, role: userRole }))
                        .then(unwrapResult)
                        .then((tm) => onSave(tm))
                        .catch((err) => setError(err))
                        .finally(() => setSavingInProgress(false))
                    break
                case "edit":
                    break
            }
        }
    }

    return <>
        <Dialog open={true} fullWidth maxWidth='md'>
            <DialogTitle>
                <If condition={mode === "create"}>
                    <Localized id="urs-add-team-member">
                        <span>Nowy członek zespołu</span>
                    </Localized>
                </If>
                <If condition={mode === "edit"}>
                    <Localized id="urs-edit-team-member">
                        <span>Edycja członka zespołu</span>
                    </Localized>
                </If>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
            }}>
                <LoadUsers component={<Skeleton />}>
                    <Autocomplete
                        sx={{
                            marginTop: 1,
                        }}
                        options={availableUsers}
                        value={user}
                        onChange={handleUserChange}
                        getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                        renderInput={params =>
                            <TextField {...params}
                                helperText={user?.position ?? " "}
                                label={
                                    <Localized id="username">
                                        <span>Nazwa użytkownika</span>
                                    </Localized>
                                }
                            />
                        }
                    />
                </LoadUsers>
                <TextField
                    sx={{
                        marginTop: 1,
                    }}
                    value={userRole}
                    fullWidth
                    required={true}
                    select
                    label={<Localized id="urs-user-role"><span>Rola</span></Localized>}
                    onChange={handleUserRoleChange}
                >

                    <MenuItem value={"C"}><Localized id="project-role-collaborator"><span>Collaborator</span></Localized></MenuItem>
                    <MenuItem value={"O"}><Localized id="project-role-observer"><span>Observer</span></Localized></MenuItem>
                </TextField>
                <ServerErrorMsg err={error} />
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={savingInProgress} onClick={handleOkClick}>
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton loading={savingInProgress} onClick={onCancel}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
