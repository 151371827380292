import { Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch } from "../../../app/hooks"
import { useState } from "react"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { createProcess } from "../projectSlice"
import { ProjectProcess } from "../projectProcess/ProjectProcessApiClient"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface CreateProjectProcessDialogProps {
    projectId: string | null;
    no: string
    name: string
    onSucces: (projectProcess: ProjectProcess) => void
    onCancel: () => void
}

export const CreateProjectProcess = (props: CreateProjectProcessDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [name, setName] = useState(props.name)
    const [no, setNo] = useState(props.no)
    const [validationErrors, setValidationErrors] = useState(false)

    const noError = validationErrors && no === ""
    const nameError = validationErrors && name === ""

    const handleOk = async () => {
        setValidationErrors(true)

        try {
            const result =
                await dispatch(createProcess({
                    no: no,
                    name: name,
                    projectId: props.projectId
                }))

            if (createProcess.fulfilled.match(result)) {
                const proc = result.payload as ProjectProcess;
                dispatch(showSuccess("saved"))
                props.onSucces(proc)
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setSaving(false)
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle><Localized id=''>Nowy process</Localized></DialogTitle>
        <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
            <TextField
                    fullWidth
                    autoFocus
                    error={noError}
                    helperText={noError && <RequiredError />}
                    inputProps={{ maxLength: 100 }}
                    value={no}
                    onChange={e => setNo(e.target.value)}
                    label={<Localized id='process-no'>Numer procesu</Localized>}
                />
                <TextField
                    fullWidth
                    autoFocus
                    error={nameError}
                    helperText={nameError && <RequiredError />}
                    inputProps={{ maxLength: 500 }}
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={<Localized id='name'>Nazwa</Localized>}
                />
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOk}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
