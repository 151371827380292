import {Localized} from '@fluent/react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import {Link} from 'react-router-dom'
import {useAppDispatch, useAppSelector} from '../../app/hooks'
import {useCallback, useEffect, useState} from 'react'
import {Box, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText} from '@mui/material'
import {AppId, AppTextFieldHandler} from '../../app/appTypes'
import {Skeleton} from '@mui/material'
import {loadRequirementsTypes, selectAllRequirementsTypes, selectTotalRequirementsTypes, deleteRequirementType, selectRequirementsTypes, deleteSysRequirementType, loadSysRequirementsTypes} from './RequirementsTypesSlice'
import {unwrapResult} from '@reduxjs/toolkit'
import {showError, showSuccess} from '../notifications/notificationsSlice'
import {AppLoader} from '../../app/AppLoader'
import {FlexDiv} from '../../app/Utils'
import {selectModule} from '../modules/moduleSlice'
import {DataGrid, GridColDef} from '@mui/x-data-grid'

export const RequirementsTypesList = () => {
    const dispatch = useAppDispatch()
    const { state: requirementsTypesState } = useAppSelector(selectRequirementsTypes)
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(5)
    const rows = useAppSelector(state => selectAllRequirementsTypes(state))
    const reqTypesPage = 
        useAppSelector(state => selectAllRequirementsTypes(state).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    const reqTypesCount = useAppSelector(selectTotalRequirementsTypes)
    const [deleting, setDeleting] = useState(false)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [ setPage ])
    
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }, [ setPage, setRowsPerPage ])

    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) { 
            setDeleting(true)           
            try {
                if (currentModule?.code === "super"){
                    unwrapResult(await dispatch(deleteSysRequirementType(itemToDelete)))
                }
                else {
                    unwrapResult(await dispatch(deleteRequirementType(itemToDelete)))
                }
               
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setDeleting(false)
            setItemToDelete(undefined)
        }
    }
    
    useEffect(() => {
        if (requirementsTypesState.type === "empty") {
            if (currentModule?.code === "super"){
                dispatch(loadSysRequirementsTypes())
            }
            else {
                dispatch(loadRequirementsTypes())
            }
        }       
    }, [dispatch, requirementsTypesState, currentModule])


    const columns: GridColDef[] = 
         [
        { field: 'code', headerName: 'Kod',   width:100, maxWidth:100 },
        { field: 'name', headerName: 'Nazwa',  flex:1},
        { field: 'order', headerName: 'Kolejność',  width:100, maxWidth:100},
        { 
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return(
                <ButtonGroup>
                    <Button component={Link} to={`/requirementsTypes/edit/${params.id}`} color="secondary" variant="outlined">
                         <Localized id="edit">
                            <span>Edytuj</span> 
                        </Localized>
                    </Button>
                    <Button color="primary" onClick={() => handleDeleteClick(params.row.id)}>
                        <Localized id="delete">
                            <span>Usuń</span>
                        </Localized>
                    </Button>
                </ButtonGroup>
                )
            }
        }
      ];

      
    switch (requirementsTypesState.type) {
        case "empty":
        case "loading":
        case "error":
            return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
        case "loaded":
            return (
                <>
                <Box sx={{ width: '100%' }}>
                <div style={{ display: 'flex', height: '100%' }}>
                    <div style={{ flexGrow: 1 }}>
                    <DataGrid autoHeight rows={rows} columns={columns} />
                    </div>
                </div>


                    <FlexDiv>
                        <Fab component={Link} to="/requirementsTypes/new" color="secondary" sx={{marginTop:2}}>
                            <AddIcon />
                        </Fab>
                    </FlexDiv>
                    <Dialog open={itemToDelete !== undefined}>
                        <DialogContent>
                            <DialogContentText>
                                <Localized id="confirm-delete">
                                    <span>Czy napewno chcesz usunąć?</span>
                                </Localized>
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleConfirmDelClick}>
                                <Localized id="yes"><span>Tak</span></Localized>
                            </Button>
                            <Button onClick={handleCancelDelClick}>
                                <Localized id="no"><span>Nie</span></Localized>
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <AppLoader open={deleting} />
                    </Box>
                </>
            );
    }
}
