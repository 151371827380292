import { MenuItem, Skeleton, TextField, TextFieldProps } from "@mui/material"
import { useCallback } from "react"
import { LoadDeviceTypes } from "../../app/AppDataLoader"
import { AppRequiredMessage } from "../../app/AppRequiredMessage"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppSelector, useQueryStructureId } from "../../app/hooks"
import { StructureMenuItem } from "./Structure"
import { selectAllStructure, selectAllStructureLeafs, selectStructureAndChildren, selectStructureLeafs } from "./structureRelationshipSlice"

export type DeviceTypeComboboxProps  = {
    onChange: (id: AppId) => void
    onlyLeafs: boolean
    error: boolean
} & Omit<TextFieldProps, "onChange" | "select">

export const DeviceTypeCombobox = ({ onChange, error, onlyLeafs, ...props}: DeviceTypeComboboxProps) => {
    const structureId = useQueryStructureId()
    const structureIds = useAppSelector(state => {
        if (structureId) {
            if (onlyLeafs) {
                return selectStructureLeafs(state, structureId)
            } else {
                return selectStructureAndChildren(state, structureId)
            }
        } else {
            if (onlyLeafs) {
                return selectAllStructureLeafs(state)
            } else {
                return selectAllStructure(state)    
            }
        }
    })
    const deviceTypes = Array.from(structureIds).map(id => <MenuItem key={id} value={id}><StructureMenuItem id={id} /></MenuItem>)

    const handleDeviceTypeIdChange: AppTextFieldHandler = useCallback(e => onChange(e.target.value), [onChange])

    return <LoadDeviceTypes component={<Skeleton variant="text"/>}>
        <TextField 
            onChange={handleDeviceTypeIdChange} 
            error={error}
            helperText={error && <AppRequiredMessage />}
            select
            {...props}>{deviceTypes}</TextField>
    </LoadDeviceTypes>
}
