import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Alert, Box, Button, Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel, Paper, styled, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"
import { useState } from "react"
import { useParams } from "react-router-dom"
import { useAppSelector } from "../../app/hooks"
import { RichTextEditor } from "../../app/RichTextEditor"
import { selectChcById } from "./chcSlice"

type TabValue = 'info' | 'team' | 'assesment'

const AppTabPanel = styled(TabPanel)(({ theme }) => ({
    paddingLeft: 0,
    paddingRight: 0,
}))

export const Chc = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectChcById(state, id))

    const [tabValue, setTabValue] = useState<TabValue>('info')
    const handleTabChange = (event: React.SyntheticEvent, newValue: TabValue) => {
        setTabValue(newValue);
    }

    if (!entity) return <Alert severity='error'>{`Entity with id ${id} deos not exist.`}</Alert>

    const { requestNumber, department, applicant, requestDate, status } = entity
    const { changeDescription, additionalInformation } = entity

    return (
        <section className="w-full max-w-6xl mx-auto py-1 md:py-1 lg:py-1">
            <div className="space-y-2">
                <div className="flex items-center justify-between">
                    <div className="space-y-2">
                        <h2 className="text-2xl font-bold">Change Control Request</h2>
                    </div>
                </div>
                <div className='flex space-x-2'>
                    <TextField sx={{ minWidth: '160px' }} label='Request number' value={requestNumber} />
                    <TextField sx={{ minWidth: '160px' }} label='Department' value={department} />
                    <TextField fullWidth label='Applicant' value={applicant} />
                    <TextField sx={{ minWidth: '120px' }} label='Date' value={requestDate} />
                    <TextField sx={{ minWidth: '200px' }} label='Status' value={status} />
                </div>
                <div>
                    <TabContext value={tabValue}>
                        <TabList onChange={handleTabChange}>
                            <Tab label='Information' value='info' />
                            <Tab label='Team' value='team' />
                            <Tab label='Assesment' value='assesment' />
                        </TabList>
                        <AppTabPanel value='info'>
                            <div className='space-y-8'>
                                <FormControl fullWidth variant='standard' component='fieldset'>
                                    <FormLabel required component='legend'>Request from contractor?</FormLabel>
                                    <FormGroup row>
                                        <FormControlLabel control={<Checkbox checked={true} />} label='Yes' />
                                        <FormControlLabel control={<Checkbox checked={true} />} label='No' />
                                    </FormGroup>
                                </FormControl>
                                <RichTextEditor value={changeDescription} onValueChange={() => {}} label='Change description' />
                                <div className='space-y-4'>
                                    <h5 className='m-0'>Change applies to</h5>
                                    <ChangeApplyToSection />
                                </div>
                                <RichTextEditor value={additionalInformation} onValueChange={() => {}} label='Additional information' />
                            </div>
                        </AppTabPanel>
                        <AppTabPanel value='team'>
                            <TeamSection />
                        </AppTabPanel>
                        <AppTabPanel value='assesment'>
                            assesment
                        </AppTabPanel>
                    </TabContext>
                </div>
            </div>
        </section>
    )
}

type FakeUser = {
    firstName: string
    lastName: string
    department: string
    role: string
}

const users: FakeUser[] = [
{ firstName: 'John', lastName: 'Doe', department: 'Production', role: 'Opinion' },
{ firstName: 'Jane', lastName: 'Doe', department: 'Production', role: 'Opinion' },
{ firstName: 'John', lastName: 'Smith', department: 'Production', role: 'Opinion' },
{ firstName: 'Jane', lastName: 'Smith', department: 'Production', role: 'Opinion' },
{ firstName: 'John', lastName: 'Johnson', department: 'Production', role: 'Opinion' },
{ firstName: 'Jane', lastName: 'Johnson', department: 'Production', role: 'Approval' },
{ firstName: 'John', lastName: 'Doe', department: 'Production', role: 'Approval' },
]


const TeamSection = () => {
    return <div className="space-y-4">
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Seq. no.</TableCell>
                        <TableCell>First name</TableCell>
                        <TableCell>Last name</TableCell>
                        <TableCell>Department</TableCell>
                        <TableCell>Role</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, index) => <TableRow key={index}>
                        <TableCell>{index + 1}</TableCell>
                        <TableCell>{user.firstName}</TableCell>
                        <TableCell>{user.lastName}</TableCell>
                        <TableCell>{user.department}</TableCell>
                        <TableCell>{user.role}</TableCell>
                    </TableRow>)}
                </TableBody>
            </Table>
        </TableContainer>
        <Button>Add team memeber</Button>
    </div>
}

const ChangeApplyToSection = () => {
    return (
    <div className='grid grid-cols-3 gap-4'>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Company name</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Adamed Pharma S.A. - zakład Pabianice' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Adamed Pharma S.A. - zakład Pabianice' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Adamed Pharma S.A. - zakład Pabianice' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Other' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Equipment</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Production' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Laboratory' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Other' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p: 2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Product</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Therapeutic' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Cosmetic' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Bulk' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Medical product' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Foodtuff' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Expiration date' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Package size' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Appearence, tablet size, shape' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Transport conditions' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Packaging material</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Direct' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Printed' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Packaging type' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Packaging size' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Other' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Starting material</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='API' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Supporting material' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Technology</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Packaging' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='at Adamed Pharma' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='out of Adamed Pharma' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Manufacturing' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Adamed Pharma S.A. - zakład Pabianice' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Adamed Pharma S.A. - zakład Ksawerów' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='out of Adamed Pharma S.A.' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='In process control (IPC)' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Batch size' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Other</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Business-quality process' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Labeling' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Environmental conditions (T, RH, Δp)' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Release place' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Change control location' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Supporting system</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Compressed air' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Purified water' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Heating, ventilation, air conditioning' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Other' />
                </FormGroup>
            </FormControl>
        </Paper>
        <Paper sx={{ p:2 }}>
            <FormControl fullWidth variant='standard' component='fieldset'>
                <FormLabel required component='legend'>Research</FormLabel>
                <FormGroup>
                    <FormControlLabel control={<Checkbox checked={true} />} label='Analytical methods' />
                    <FormControlLabel control={<Checkbox checked={true} />} label='Specifications' />
                </FormGroup>
            </FormControl>
        </Paper>
    </div>
    )
}
