import TextField from "@mui/material/TextField"
import { useCallback, useMemo, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { Localized } from "@fluent/react"
import { selectRequirementById, createRequirement, updateRequirement, createSysRequirement, updateSysRequirement } from '../RequirementsDictSlice'
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ButtonGroup, CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, IconButton, InputAdornment, MenuItem, Skeleton, Stack, Tooltip } from "@mui/material"
import { selectAllRequirementsTypes } from "../RequirementsTypesSlice"
import { If } from '../../../app/If'
import { LoadRequirementsTypes } from '../../../app/AppDataLoader'
import { LoadingButton } from '@mui/lab'
import { selectModule } from '../../modules/moduleSlice';
import { DeviceTypeCombobox } from "../../device/DeviceTypeCombobox"
import { postWithAuth } from "../../../http"
import CloseIcon from '@mui/icons-material/Close';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import TranslateIcon from '@mui/icons-material/Translate';
import { RichTextEditor } from "../../../app/RichTextEditor"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface CreateUpdateRequirementProps {
    id: AppId | undefined
    defaultRequirementTypeId: AppId
    defaultDeviceTypeId: AppId
    onSaveSuccess: () => void
    onCancel: () => void
}

export const CreateUpdateRequirement = ({ id, defaultDeviceTypeId, defaultRequirementTypeId, onSaveSuccess, onCancel }: CreateUpdateRequirementProps) => {
    const dispatch = useAppDispatch()
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    const entity = useAppSelector(state => selectRequirementById(state, id ?? ""));
    const allRequirementsTypes = useAppSelector(selectAllRequirementsTypes)

    const [code, setCode] = useState<string>(entity?.code ?? "");
    const [name, setName] = useState<string>(entity?.name ?? "");
    const [name_EN, setName_EN] = useState<string>(entity?.name_EN ?? "");
    const [altName, setAltName] = useState<string>("");
    const [altName_EN, setAltName_EN] = useState<string>("");
    const [description] = useState<string>(entity?.description ?? "");
    const [typeId, setTypeId] = useState<string>(entity?.typeId ?? defaultRequirementTypeId);
    const [deviceTypeId, setDeviceTypeId] = useState<AppId>(entity !== undefined ? entity.structureId : defaultDeviceTypeId)

    const [validationErrors, setValidationErrors] = useState(false)
    const [altNameVisible, setAltNameVisible] = useState(false)
    const [altName_ENVisible, setAltName_ENVisible] = useState(false)
    const [saving, setSaving] = useState(false)
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);

    const handleAltNameChange: AppTextFieldHandler = useCallback(e => setAltName(e.target.value), [setAltName])
    const handleAltName_ENChange: AppTextFieldHandler = useCallback(e => setAltName_EN(e.target.value), [setAltName_EN])
    const handleRequirementTypeChange: AppTextFieldHandler = useCallback(e => setTypeId(e.target.value), [setTypeId])
    const handleDeviceTypeIdChange = useCallback(structureId => setDeviceTypeId(structureId), [])
    const [loadingName, setLoadingName] = useState(false);
    const [loadingEnName, setLoadingEnName] = useState(false);

      
    const AcceptAnswerClick = async () => {
        setName(altName)
        setAltNameVisible(false)
    }
    const AcceptAnswer_ENClick = async () => {
        setName_EN(altName_EN)
        setAltName_ENVisible(false)
    }

    const CloseAnswerClick = async () => {
        setAltNameVisible(false)
    }
    const CloseAnswer_ENClick = async () => {
        setAltName_ENVisible(false)
    }

    const handleRenameClick = async () => {
        setAltNameVisible(true)
        setWaitingForAnswer(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask`,
                payload: {
                    Type: 'change-requirement',
                    Question: name
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setAltName(payload.answer)
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    const handleRename_ENClick = async () => {
        setAltName_ENVisible(true)
        setWaitingForAnswer(true)
        
        try {
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask`,
                payload: {
                    Type: 'change-requirement',
                    Question: name_EN
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setAltName_EN(payload.answer)
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    
    const handleTranslateToPLClick = async () => {
        setLoadingName(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name_EN,
                    LanguageTo: 'pl'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName(payload.text)
               
            }
        }
        finally {
            setLoadingName(false)
        }
    }

    const handleTranslateToENClick = async () => {
        setLoadingEnName(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: name,
                    LanguageTo: 'en-US'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
              
                const { payload } = result
                setName_EN(payload.text)
            }
        }
        finally {
            setLoadingEnName(false)
        }
    }

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (name && name_EN && typeId && currentModule && deviceTypeId) {
            setSaving(true)
            try {
                if (!entity) {
                    if (currentModule.code === "super") {
                        await dispatch(createSysRequirement({
                            id: id ?? "",
                            code,
                            name,
                            name_EN,
                            description,
                            typeId,
                            deviceTypeId: null,
                            structureId: deviceTypeId,
                            isSystem: true
                        })).unwrap()
                    }
                    else {
                        await dispatch(createRequirement({
                            id: id ?? "",
                            code,
                            name,
                            name_EN,
                            description,
                            typeId,
                            deviceTypeId: null,
                            structureId: deviceTypeId,
                            isSystem: false
                        })).unwrap()
                    }
                } else {
                    let requirement = {
                        ...entity,
                        code, name, name_EN, description, typeId
                    };
                    if (currentModule.code === "super") {
                        await dispatch(updateSysRequirement(requirement)).unwrap()
                    } else {
                        await dispatch(updateRequirement(requirement)).unwrap()
                    }
                }
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            } catch (error) {
                dispatch(showError("error"));
            } finally {
                setSaving(false)
            }
        }
    }

    const typeIdError = validationErrors && typeId === ""
    const deviceTypeIdError = validationErrors && deviceTypeId === ""
    const requirementsTypes = useMemo(() => allRequirementsTypes.map((reqType) => <MenuItem key={reqType.id} value={reqType.id}>({reqType.code}) {reqType.name}</MenuItem>), [allRequirementsTypes])

    return <>
        <DialogTitle>
            <If condition={id !== ""} otherwise={
                <Localized id="requirements-new">
                    <span>Nowe wymaganie</span>
                </Localized>
            }>
                <Localized id="requirements-edit">
                    <span>Edycja wymagania</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} padding={2}>
                <Grid container>
                    <Grid item xs={11} >
                        <If condition={!loadingName} otherwise={<CircularProgress />}>
                            <RichTextEditor 
                                label={<Localized id="requirements-name-pl"><span>Nazwa wymagania (PL)</span></Localized>} 
                                value={name} 
                                onValueChange={setName} 
                            />
                        </If>
                       
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonGroup orientation="vertical" sx={{marginTop:"40px"}}>
                            <Tooltip title={<Localized id="requirements-ask-ai-rename">zaproponuj inny opis</Localized>}>
                                <IconButton onClick={handleRenameClick}>
                                    <AutoFixHighIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<Localized id="translate-to-en">Przetłumacz</Localized>}>
                                <IconButton onClick={handleTranslateToENClick}>
                                    <TranslateIcon />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <If condition={altNameVisible}>
                    <TextField
                        fullWidth
                        value={altName}
                        minRows={3}
                        maxRows={12}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <If condition={!waitingForAnswer} otherwise={
                                        <CircularProgress />
                                    }>
                                        {/* <Tooltip title={<Localized id="accept">Akceptuj</Localized>}>
                                            <IconButton onClick={AcceptAnswerClick}>
                                                <DoneIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                        <Tooltip title={<Localized id="close">Zamknij</Localized>}>
                                            <IconButton onClick={CloseAnswerClick}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </If>
                                </InputAdornment>
                            )

                        }}
                        onChange={handleAltNameChange}
                        label={<Localized id="requirements-ai-name"><span>AI sugestia</span></Localized>}
                    />
                </If>
                <Grid container>
                    <Grid item xs={11}>
                        <If condition={!loadingEnName} otherwise={<CircularProgress />}>
                            <RichTextEditor 
                                label={<Localized id="requirements-name-en"><span>Nazwa wymagania (EN)</span></Localized>} 
                                value={name_EN} 
                                onValueChange={setName_EN} 
                            />
                        </If>
                    </Grid>
                    <Grid item xs={1}>
                        <ButtonGroup orientation="vertical" sx={{marginTop:"40px"}}>
                            <Tooltip title={<Localized id="requirements-ask-ai-rename">zaproponuj inny opis</Localized>}>
                                <IconButton onClick={handleRename_ENClick}>
                                    <AutoFixHighIcon />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={<Localized id="translate-to-pl">Przetłumacz</Localized>}>
                                <IconButton onClick={handleTranslateToPLClick}>
                                    <TranslateIcon />
                                </IconButton>
                            </Tooltip>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                
                <If condition={altName_ENVisible}>
                    <TextField
                        fullWidth
                        value={altName_EN}
                        minRows={3}
                        maxRows={12}
                        multiline
                        inputProps={{ maxLength: 1000 }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <If condition={!waitingForAnswer} otherwise={
                                        <CircularProgress />
                                    }>
                                        {/* <Tooltip title={<Localized id="accept">Akceptuj</Localized>}>
                                            <IconButton onClick={AcceptAnswer_ENClick}>
                                                <DoneIcon />
                                            </IconButton>
                                        </Tooltip> */}
                                        <Tooltip title={<Localized id="close">Zamknij</Localized>}>
                                            <IconButton onClick={CloseAnswer_ENClick}>
                                                <CloseIcon />
                                            </IconButton>
                                        </Tooltip>

                                    </If>
                                </InputAdornment>
                            )

                        }}
                        onChange={handleAltName_ENChange}
                        label={<Localized id="requirements-ai-name"><span>AI sugestia</span></Localized>}
                    />
                </If>

                <LoadRequirementsTypes component={<Skeleton variant="text" />}>
                    <TextField
                        fullWidth
                        error={typeIdError}
                        helperText={typeIdError && <RequiredError />}
                        select
                        required={true}
                        value={typeId}
                        onChange={handleRequirementTypeChange}
                        label={<Localized id="requirements-type">Typ</Localized>}>{requirementsTypes}</TextField>
                </LoadRequirementsTypes>
                <DeviceTypeCombobox
                    onlyLeafs={false}
                    error={deviceTypeIdError}
                    fullWidth
                    required={true}
                    value={deviceTypeId}
                    onChange={handleDeviceTypeIdChange}
                    label={<Localized id="urs-device-type">Typ</Localized>}
                />               
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} color="secondary" variant="outlined" loading={saving}>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} variant="outlined" onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}
