import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogActions, DialogContent } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { ValidationsBrowser } from "../../validations/ValidationsBrowser"
import { createSysRequirementTest, selectAllAssociatedTests } from "../requirementTestSlice"

export interface AssociateTestProps {
    requirementId: AppId
    onSuccess: () => void
    onError: () => void
    onCancel: () => void
}
export const AssociateTest = ({ requirementId, onSuccess, onError, onCancel }: AssociateTestProps) => {
    const dispatch = useAppDispatch()
    const allAssociatedTests = useAppSelector(selectAllAssociatedTests)
    const excluded = useMemo(() => new Set(allAssociatedTests) as Set<AppId>, [allAssociatedTests])

    const [running, setRunning] = useState(false)
    const [testId, setTestId] = useState<AppId | undefined>(undefined)

    const testSelected  = useCallback((id: AppId) => {
        setTestId(id)
    }, [setTestId])
    const testUnselected = useCallback(() => {
        setTestId(undefined)
    }, [setTestId])

    const handleOKClick = async () => {
        if (testId) {
            setRunning(true)
            try {
                await dispatch(createSysRequirementTest([undefined, {
                    requirementId,
                    testId,
                    isSystem: true,
                }])).unwrap()
                onSuccess()
            } catch (error) {
                onError()
            }
        }
    }

    const selection: Set<AppId> = useMemo(() => testId ? new Set([testId]) : new Set(), [testId])

    return <>
        <DialogContent>
            <ValidationsBrowser excluded={excluded} selection={selection} itemChecked={testSelected} itemUnchecked={testUnselected}></ValidationsBrowser> 
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={handleOKClick}>
                <Localized id="ok"><span>OK</span></Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel"><span>Anuluj</span></Localized>
            </LoadingButton>
        </DialogActions>
    </>
}
