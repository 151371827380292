import { Localized } from "@fluent/react"
import { Button, ButtonGroup, CircularProgress, Container, Grid, IconButton, InputAdornment, MenuItem, Paper, Skeleton, TextField } from "@mui/material"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import { AppLoader } from "../../app/AppLoader"
import { AppRequiredMessage } from "../../app/AppRequiredMessage"
import { AppId } from "../../app/appTypes"
import { useAppDispatch, useQueryStructureId } from "../../app/hooks"
import { DeviceTypeCombobox } from "../device/DeviceTypeCombobox"
import { showSuccess, showError } from "../notifications/notificationsSlice"
import SaveIcon from '@mui/icons-material/Save'
import { LoadDeviceTypes, LoadValidations } from "../../app/AppDataLoader"
import { createSysValidation, ValidationStage } from "./validationsSlice"
import { createSysRequirementTest } from "../requirements/requirementTestSlice"
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { postWithAuth } from "../../http"
import { If } from "../../app/If"
import { useLocation, useNavigate } from "react-router-dom"

export const CreateSysValidation = () => {
    return <LoadDeviceTypes component={<Skeleton variant="rectangular" animation="wave" height="75vh" />}>
        <LoadValidations component={<Skeleton variant="rectangular" animation="wave" height="75vh" />}>
            <Form />
        </LoadValidations>
    </LoadDeviceTypes>
}

const Form = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const structureId = useQueryStructureId()
    const { search, state } = useLocation()

    const [name, setName] = useState(() => {
        if (state && state[`defaultName`]) {
            return state[`defaultName`]
        } else {
            return ""
        }
    })
    const [description, setDescription] = useState("")
    const [stage, setStage] = useState<ValidationStage>("IQ")
    const [deviceTypeId, setDeviceTypeId] = useState<AppId | undefined>(undefined)
    const [validationErrors, setValidationErrors] = useState(false)
    const [saving, setSaving] = useState(false)
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);    

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (name) {
            setSaving(true)
            try {
                const result = await dispatch(createSysValidation({ 
                    name, 
                    description, 
                    stage, 
                    structureId: deviceTypeId ?? "",
                    isSystem: true,
                }))

                if (createSysValidation.fulfilled.match(result)) {
                    const { id: newId } = result.payload;
                 
                    if (search) {
                        const params = new URLSearchParams(search)
                        if (params.has("requirementId")) {
                            const requirementId = params.get("requirementId") ?? ""
                            await dispatch(createSysRequirementTest([undefined, {
                                requirementId,
                                testId: newId,
                                isSystem: true,
                            }]))
                        }
                    }
                    dispatch(showSuccess("saved"))
                    const editURL = structureId ? `/sys/validation/edit/${newId}?structureId=${structureId}` : `/sys/validation/edit/${newId}`
                    navigate(editURL, { replace: true })
                } else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                            dispatch(showError(errorMsg))
                        }
                    }
                }

               
            } catch (error) {
                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    const handleGenerateDescriptionClick = async () => {
        setWaitingForAnswer(true)
        try{
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask`,
                payload: {
                    Type : 'test-for-requirement',
                    Question: name
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setDescription(payload.answer)
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setName(e.target.value), [setName]);
    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setDescription(e.target.value), [setDescription]);
    const handleStageChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setStage(e.target.value as ValidationStage), [setStage]);

    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const nameError = validationErrors && name === ""
    const deviceError = validationErrors && deviceTypeId === undefined

    return <>
        <Container component={Paper} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        error={nameError}
                        helperText={nameError && <AppRequiredMessage />}
                        value={name}
                        inputProps={{ maxLength: 200 }}
                        multiline={true}
                        onChange={handleNameChange}
                        label={<Localized id="validation-name">Nazwa</Localized>}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        value={description}
                        onChange={handleDescriptionChange}
                        multiline={true}
                        InputProps={{ 
                            endAdornment: (
                                <InputAdornment position="end">
                                    <If condition={!waitingForAnswer} otherwise={
                                                <CircularProgress />
                                                }>
                                        <IconButton onClick={handleGenerateDescriptionClick}>
                                            <AutoFixHighIcon />
                                        </IconButton>
                                    </If>
                                </InputAdornment>
                              )
                        
                        }}
                        inputProps={{ maxLength: 1000 }}
                        minRows={2}
                        maxRows={16}
                        label={<Localized id="validation-description">Opis</Localized>}
                    />

                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        select
                        value={stage}
                        onChange={handleStageChange}
                        label={<Localized id="validation-stage">Etap</Localized>} >
                            {["IQ", "OQ", "DQ", "PQ"].map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)}
                    </TextField>

                </Grid>
                <Grid item xs={10}>
                    <DeviceTypeCombobox 
                        fullWidth
                        error={deviceError}
                        onlyLeafs={false}
                        value={deviceTypeId ?? ""}
                        onChange={setDeviceTypeId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup sx={{
                        marginBottom: 2,
                    }} color="secondary" variant="contained">
                        <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                        </Button>
                        <Button onClick={handleBackClick} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>

                </Grid>
            </Grid>
        </Container> 
        <AppLoader open={saving} />
    </>
}
