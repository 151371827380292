import { createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { AppDataState, AppId, unknownError } from "../../app/appTypes";
import { create, load, remove } from "../../app/crud";
import { RootState } from "../../app/store";
import { logout } from "../user/userSlice";

export interface RequirementTest {
    testId: AppId
    requirementId: AppId
    isSystem: boolean
}

const reqTestAdapter = createEntityAdapter<RequirementTest>({
    selectId: x => x.requirementId,
})

const testReqAdapter = createEntityAdapter<RequirementTest>({
    selectId: x => x.testId,
})

export const selectRequirementTestState = (state: RootState) => state.requirementTest.state
export const selectRequirementTest      = (state: RootState) => state.requirementTest.requirementTest
export const selectTestRequirement      = (state: RootState) => state.requirementTest.testRequirement

export const {
    selectById: selectTestIdByRequirementId
} = reqTestAdapter.getSelectors<RootState>(selectRequirementTest)

export const {
    selectById: selectRequirementIdByTestId,
    selectIds: selectAllAssociatedTests,
} = testReqAdapter.getSelectors(selectTestRequirement)

export const loadRequirementTest = load<void, RequirementTest>(
    "requirementTest/load",
    () => `api/RequirementTest`,
    selectRequirementTestState,
)

export const loadSysRequirementTest = load<void, RequirementTest>(
    "sysRequirementTest/load",
    () => `api/SysRequirementTest`,
    selectRequirementTestState,
)

export const createSysRequirementTest = create<void, RequirementTest>(
    "sysRequirementTest/create",
    () => "api/SysRequirementTest",
)

export const deleteSysRequirementTest = remove<RequirementTest>(
    "sysRequirementTest/delete",
    ({ requirementId, testId }) => `api/SysRequirementTest/${requirementId}/${testId}`,
)

export type RequirementTestState = 
    { state: AppDataState
    , requirementTest: EntityState<RequirementTest>
    , testRequirement: EntityState<RequirementTest>
    }

const initialState: RequirementTestState = {
    state: { type: "empty" },
    requirementTest: reqTestAdapter.getInitialState(),
    testRequirement: testReqAdapter.getInitialState(),
}

export const requirementTestSlice = createSlice({
    name: "requirementTest",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(createSysRequirementTest.fulfilled, (state, action) => {
            reqTestAdapter.addOne(state.requirementTest, action.payload)
            testReqAdapter.addOne(state.testRequirement, action.payload)
        })
        builder.addCase(logout.fulfilled, (state) => {
            state.state = { type: "empty" }
            reqTestAdapter.removeAll(state.requirementTest)
            testReqAdapter.removeAll(state.testRequirement)
            
        })
        builder.addCase(deleteSysRequirementTest.fulfilled, (state, action) => {
            reqTestAdapter.removeOne(state.requirementTest, action.payload.requirementId)
            testReqAdapter.removeOne(state.testRequirement, action.payload.testId)
        })
        builder.addMatcher(isAnyOf(loadRequirementTest.pending, loadSysRequirementTest.pending), (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addMatcher(isAnyOf(loadRequirementTest.fulfilled, loadSysRequirementTest.fulfilled), (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                reqTestAdapter.setAll(state.requirementTest, action.payload)
                testReqAdapter.setAll(state.testRequirement, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addMatcher(isAnyOf(loadRequirementTest.rejected, loadSysRequirementTest.rejected), (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
    },
})

export default requirementTestSlice.reducer
