import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./app/hooks";
import { logout, selectUser } from "./features/user/userSlice";

export const Logout = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);
    const navigate = useNavigate();

    useEffect(() => {
        if (user.tokenState !== undefined) {
            dispatch(logout());
        } else {
            navigate("/login");
        }     
    });

    return (<div>Wylogowywanie...</div>);
}
