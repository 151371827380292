import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useCallback, useEffect, useState } from 'react';
import { AppId } from "../../app/appTypes";
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import { Link } from 'react-router-dom';
import { Box, ButtonGroup, Container, Dialog, DialogActions, DialogContent, DialogContentText, Tab } from '@mui/material';
import { Localized, useLocalization } from '@fluent/react';
import { AppLoader } from '../../app/AppLoader';
import { FlexDiv } from '../../app/Utils';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { deleteSupplierDevice, loadSupplierDevices, selectAllSupplierDevices, selectSupplierDevices } from "./supplierDeviceSlice";
import { selectStructureNodeById } from "../device/structureSlice";
import { store } from '../../app/store';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { ImportPanel } from './ImportPanel';
import { useNavigate } from 'react-router-dom';

export const SupplierDevices = () => {
    const { loaded } = useAppSelector(selectSupplierDevices)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const rows = useAppSelector(state => selectAllSupplierDevices(state))
    const [deleting, setDeleting] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                unwrapResult(await dispatch(deleteSupplierDevice(itemToDelete)))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    useEffect(() => {
        if (!loaded) {
            dispatch(loadSupplierDevices())
        }
    }, [dispatch, loaded])


    type FormTabs = "list" | "attachments"
    const [tab, setTab] = useState<FormTabs>("list");
    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handleGoToClick = (id) => {
        navigate(`/supplierdevices/edit/${id}`)
    };


    const columns: GridColDef[] =
        [
            {
                field: 'name',
                headerName: l10n.getString('devices-name', null, 'Nazwa'),
                flex: 1
            },
            {
                field: 'structureId',
                flex: 1,
                headerName: l10n.getString('devices-type', null, 'Typ urządzenia'),
                valueGetter: (_, row) => {
                    const structure = selectStructureNodeById(store.getState(), row.structureId)
                    if (structure === undefined) {
                        return "";
                    }
                    const { name } = structure
                    return name;
                }
            },
            {
                field: 'actions',
                width: 200,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button onClick={() => handleGoToClick(params.row.id)} color="secondary" variant="outlined">
                                <Localized id="edit">
                                    <span>Edytuj</span>
                                </Localized>
                            </Button>
                            <Button color="primary" onClick={() => handleDeleteClick(params.row.id)}>
                                <Localized id="delete">
                                    <span>Usuń</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];
    return (
        <>
            <Container maxWidth={false}>
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        <Tab
                            key={0}
                            value={"list"}
                            label={<Localized id="">Lista urządzeń</Localized>} />
                        <Tab
                            key={1}
                            value={"attachments"}
                            label={<Localized id="">Import</Localized>} />
                    </TabList>

                    <TabPanel value="list">
                        <Box sx={{ width: '100%' }}>

                            <div style={{ flexGrow: 1 }}>
                                <DataGrid autoHeight rows={rows} columns={columns} onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                            </div>
                            <FlexDiv>
                                <Fab component={Link} to="/supplierdevices/create" color="secondary" sx={{ marginTop: 2 }}>
                                    <AddIcon />
                                </Fab>
                            </FlexDiv>
                        </Box>
                    </TabPanel>

                    <TabPanel value="attachments">
                        <ImportPanel />
                    </TabPanel>

                </TabContext>
                <Dialog open={itemToDelete !== undefined}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="confirm-delete">
                                <span>Czy napewno chcesz usunąć?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmDelClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelDelClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
                </Dialog>
                <AppLoader open={deleting} />

            </Container>
        </>
    );
}
