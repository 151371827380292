import { Localized, useLocalization } from "@fluent/react";
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { Button, ButtonGroup, Container, Dialog, Grid, IconButton, Paper, Stack, Tab, TextField } from "@mui/material"
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { AiQuestion, loadAiQuestions, selectAiQuestions, selectAllAiQuestions, Statistics } from "./aiQuestionsSlice";
import { AppId } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { AiQuestionEditDialog } from "./AiQuestionEditDialog";
import SyncIcon from '@mui/icons-material/Sync';
import { getWithAuth } from "../../http";
import { AIAccessGrid } from "./AIAccessGrid";
import { PieChart } from "@mui/x-charts";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LoadingContainer } from "../../app/LoadingContainer";
import { selectSettings } from "../settings/settingsSlice";
import { enUS, plPL } from "@mui/x-data-grid/locales";
import { ErrorContainer } from "../../app/ErrorContainer";

type FormTabs = "questions" | "statistics" | "access"

type DialogState = 
    | { type: "none" }
    | { type: "createUpdate", questionId: AppId | undefined }


const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])
    const onError = useCallback(() => {
        setter({ type: "none" })
        dispatch(showError("error"))
    }, [setter, dispatch])

    switch (state.type) {
        case "none":
            return null

        case "createUpdate":
            return <AiQuestionEditDialog
                id={state.questionId} 
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
    />
    }
}

export const AiSettings = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const [tab, setTab] = useState<FormTabs>("questions");
    const allQuestions = useAppSelector(selectAllAiQuestions)
    const { state } = useAppSelector(selectAiQuestions)
    const dispatch = useAppDispatch()
    const [totalTokens, setTotalTokens] = useState<number>(0);    
    const [promptTokens, setPromptTokens] = useState<number>(0);    
    const [completionTokens, setCompletionTokens] = useState<number>(0);    
    const [loading, setLoading] = useState<boolean>(false);    
    const [pieChartData, setPieChartData] = useState();
    const [stats, setStats] = useState<Statistics[]>([]);

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    useEffect(() => {
        if (state.type === "empty") {
            dispatch(loadAiQuestions())
        }
    }, [dispatch, state])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const handleEditQuestionClick = useCallback((questionId: AppId) => {
        setDialog({
            type: "createUpdate",
            questionId
        })
    }, [setDialog])


    const handleRefreshClick = async () => {
        setLoading(true)

        try{
            const result = await dispatch(getWithAuth({
                url: `api/ai/statistics`
            }))
            if (getWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setTotalTokens(payload.totalTokens)
                setPromptTokens(payload.promptTokens)
                setCompletionTokens(payload.completionTokens)
                const data = payload.tokenPerClient.map(item => ({
                    id: item.client,
                    label: item.companyName,
                    value: item.totalTokens
                }))
                setPieChartData(data)
                setStats(data);
            }
        }
       finally{
        setLoading(false)
       }
    }

    const columnsForQuestions: GridColDef<AiQuestion, any, any>[] = [
        {
            field: 'code',
            headerName: l10n.getString("question-code", null, "Kod"),
            width: 300
        },
        {
            field: "question",
            headerName: l10n.getString("question", null, "Pytanie"),
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            width: 150,
            renderCell: (params) => {
                return (
                    <ButtonGroup>                                              
                        <Button color="primary" onClick={() => handleEditQuestionClick(params.row.id)} >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    const columnsForCompanies: GridColDef<Statistics, any, any>[] = [
        {
            field: 'label',
            headerName: l10n.getString("company", null, "Firma"),
            width: 300
        },
        {
            field: "value",
            headerName: l10n.getString("ai-settings-total-tokens", null, "Całkowita liczba tokenów"),
            flex: 1
        }
    ]

    switch(state.type) {
        case "empty":
        case "loading":
            return <LoadingContainer/>
        case "error":
            return <ErrorContainer error={state.error}/>
        case "loaded":
            return (
                <>
                <Container component={Paper} maxWidth={false} sx={{paddingTop:2}}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                                <Tab 
                                    key={0}
                                    value={"questions"}
                                    label={<Localized id="ai-settings-questions">Pytania</Localized>} />
                                <Tab 
                                    key={1}
                                    value={"statistics"}
                                    label={<Localized id="ai-settings-statistics">Dane statystyczne</Localized>} />
                                <Tab 
                                    key={2}
                                    value={"access"}
                                    label={<Localized id="ai-settings-access">Dostęp do AI</Localized>} />
                            </TabList>
                            <TabPanel value="questions">
                                <DataGrid
                                    style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                                    localeText={getCustomLocaleText()}
                                    rows={allQuestions}
                                    columns={columnsForQuestions}
                                />
                            </TabPanel>
                            <TabPanel value="statistics">
                                <Stack direction="row" spacing={2}>
                                    <TextField 
                                        fullWidth
                                        value={totalTokens}
                                        disabled={true}
                                        label={<Localized id="ai-settings-total-tokens">Total Tokens</Localized>}>
                                    </TextField>
                                    <TextField 
                                        fullWidth
                                        value={promptTokens}
                                        disabled={true}
                                        label={<Localized id="ai-settings-prompt-tokens">Prompt Tokens</Localized>}>
                                    </TextField>
                                    <TextField 
                                        fullWidth
                                        value={completionTokens}
                                        disabled={true}
                                        label={<Localized id="ai-settings-completion-tokens">Completion Tokens</Localized>}>
                                    </TextField>
                                    <IconButton onClick={handleRefreshClick}>
                                        <SyncIcon />
                                    </IconButton>
                                </Stack>
                                <Grid container spacing={2} sx={{ marginTop: "25px" }}>
                                    <Grid item xs={6}>
                                        <DataGrid
                                            style={{ minHeight: 100, height: 'calc(100vh - 325px)' }}
                                            localeText={getCustomLocaleText()}
                                            rows={stats}
                                            columns={columnsForCompanies}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <PieChart
                                            sx={{marginTop: "25px", marginRight: "75px" }}
                                            series={[
                                                {
                                                    arcLabel: (item) => `${item.value}`,
                                                    arcLabelMinAngle: 45,
                                                    data: pieChartData ?? []
                                                },
                                            ]}
                                            width={900}
                                            height={200}
                                        />
                                    </Grid>
                                </Grid>
                            </TabPanel>
                            <TabPanel value="access">
                                <AIAccessGrid/>
                            </TabPanel>
                    </TabContext>
                    <Dialog open={dialog.type !== "none"} fullWidth maxWidth="md" keepMounted={false}>
                        <DialogContentDispatcher state={dialog} setter={setDialog} />
                    </Dialog>
                </Container>
                
                </>
            )
    }
}