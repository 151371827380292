import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";
import { Company } from "./companiesSlice"

export interface CompaniesFormState {
    shortName: string;
    name: string;
}

const initialState: CompaniesFormState = {
    shortName: "",
    name: "",
}

export const companiesFormSlice = createSlice({
    name: "companiesForm",
    initialState,
    reducers: {
        copyFieldsFromCompanyEntity: (state, action: PayloadAction<Company>) => {
            const { shortName, name } = action.payload;
            state.shortName = shortName;
            state.name = name;
        },
        setCompanyShortName: (state, action) => {
            state.shortName = action.payload;
        },
        setCompanyName: (state, action) => {
            state.name = action.payload;
        },
    },
})

export const 
                { copyFieldsFromCompanyEntity
                , setCompanyShortName
                , setCompanyName
                } = companiesFormSlice.actions

export const selectCompaniesForm = (state: RootState) => state.companiesForm

export default companiesFormSlice.reducer
