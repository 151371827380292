import { useLocalization } from "@fluent/react"
import { Box } from "@mui/material"
import { DataGrid, GridColDef, GridRowParams, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { GridApiCommunity } from "@mui/x-data-grid/internals"
import { MutableRefObject, useMemo } from "react"
import { LoadUsers } from "../../app/AppDataLoader"
import { useAppSelector } from "../../app/hooks"
import { selectAllUsers } from "./usersSlice"

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export type Email = string

export interface UsersBrowserProps {
    gridRef: MutableRefObject<GridApiCommunity>
    disabled: Set<Email>
}

export const UsersBrowser = (props: UsersBrowserProps) => {
    const { gridRef } = props
    const { l10n } = useLocalization()
    const allUsers = useAppSelector(selectAllUsers)

    const columns: GridColDef[] = useMemo(() => {
        return [
            {
                field: 'lastName',
                headerName: l10n.getString("user-last-name", null, "Nazwisko"),
                flex: 1,
            },
            {
                field: 'firstName',
                headerName: l10n.getString("user-first-name", null, "Imię"),
                flex: 1,
            },
            {
                field: 'position',
                headerName: l10n.getString("user-position", null, "Stanowisko"),
                width: 300
            },
            {
                field: 'email',
                headerName: l10n.getString("user-email", null, "Email"),
                flex: 1,
            },
            {
                field: 'phone',
                headerName: l10n.getString("user-phone", null, "Telefon"),
                flex: 1,
            },
        ]
    }, [])

    return <LoadUsers component={<span>Ładowanie użytkowników...</span>}>
        <DataGrid
            apiRef={gridRef}
            columns={columns}
            rows={allUsers}
            checkboxSelection={true}
            slots={{ toolbar: QuickSearchToolbar }}
            isRowSelectable={(params: GridRowParams) => !props.disabled.has(params.row.email)}
            slotProps={{
                toolbar: {
                    showQuickFilter: false,
                }
            }}
            initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
        />
    </LoadUsers>
}
