import { AppBar, Toolbar } from "@mui/material"
import { ReactNode, useMemo } from "react"
import { useTheme, Theme, CSSObject } from '@mui/material/styles';
import { drawerWidth } from "./AppContainer";
import { useAppSelector } from "./hooks";
import { selectSettings } from "../features/settings/settingsSlice";

const openedMixin = (theme: Theme): CSSObject => ({
  paddingLeft: `${drawerWidth}px`,
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('padding-left', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  paddingLeft: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    paddingLeft: `calc(${theme.spacing(9)} + 1px)`,
  },
});

export const BottomAppBar = ({ children }: { children: ReactNode }) => {
    const leftMenuOpen = useAppSelector(state => selectSettings(state).leftMenuOpen)

    const theme = useTheme()
    const mixin = useMemo(() => {
        if (leftMenuOpen) {
            return openedMixin(theme)
        } else {
            return closedMixin(theme)
        }
    }, [theme, leftMenuOpen])

    return <AppBar color="transparent" position="fixed" sx={{
        top: 'auto',
        bottom: 0,
        boxShadow: 'none',
        ...mixin,
    }}>
        <Toolbar>{children}</Toolbar>
    </AppBar>
}