import { forwardRef } from "react"
import { Link, LinkProps } from "react-router-dom"
import { AppId } from "./appTypes"
import { useQueryStructureId } from "./hooks"

export interface AppLinkProps {
    to: {
        pathname: string,
        search?: string | undefined
        hash?: string | undefined
        state?: object
    },
    structureId?: AppId | undefined
}

export const AppLink = forwardRef<any, Omit<LinkProps, "to"> & AppLinkProps>(
    (props, ref) => {
        const { to, structureId: propStructureId, ...rest } = props
        const { pathname, search: querySearch, hash, state } = to
        const queryStructureId = useQueryStructureId()

        const structureId = propStructureId ?? queryStructureId

        if (structureId) {
            return <Link ref={ref} {...rest} state={state} to={{
                pathname,
                search: querySearch ? querySearch + `&structureId=${structureId}` : `?structureId=${structureId}`,
                hash,
            }} />
        } else {
            return <Link ref={ref} state={state} {...rest} to={{
                pathname,
                search: querySearch,
                hash,
            }} />
        }
    }
)
