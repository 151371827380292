import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit"
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes"
import { load } from "../../app/crud"
import { RootState } from "../../app/store"
import { getWithAuth, postWithAuth, putWithAuth } from "../../http"
import { logout } from "../user/userSlice"

export interface TestStep {
    id: AppId
    name: string
    testId: AppId
    testCriterias: TestCriteria[]
}

export type TestCriteriaStatus = "N" | "P" | "R" | "W"

export interface TestCriteria {
    id: AppId
    name: string
    comment: string
    status: TestCriteriaStatus 
    stepId: string
}

const adapter = createEntityAdapter<TestStep>({
    selectId: step => step.id,
})

export type TestStepState = EntityState<TestStep> & { status: AppDataState, testId?: AppId }

const initialState: TestStepState = adapter.getInitialState({
    status: { type: "empty" },
    testId: undefined,
})

export const loadProcessTestSteps = load<AppId, TestStep>(
    "processTestSteps/load",
    testId => `api/ProcessTestStep/test/${testId}`,
    state => selectProcessTestSteps(state).status)

export const createTestStep = createAsyncThunk<TestStep, TestStep, AppThunkAPIType>
    ("processTestStep/create", async (step, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTestStep`,
            payload: step,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as TestStep
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateTestStep = createAsyncThunk<TestStep, TestStep, AppThunkAPIType>
    ("processTestStep/update", async (step, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/ProcessTestStep/${step.id}`,
            payload: step,
        }))
        if (putWithAuth.fulfilled.match(result)) {
            const readResult = await dispatch(getWithAuth({ url: `api/ProcessTestStep/${step.id}` }))
            const { payload } = readResult
            if (getWithAuth.fulfilled.match(readResult)) {
                return payload as TestStep
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }
    })

export const processTestStepsSlice = createSlice({
    name: "processTestSteps",
    initialState,
    reducers: {
        clearProcessTestSteps: (state) => {
            state.status.type = "empty"
            state.testId = undefined
            adapter.removeAll(state)
        },
    },
    extraReducers: builder => {
        builder.addCase(loadProcessTestSteps.pending, (state, action) => {
            if (state.status.type === "empty") {
                state.status = { 
                    type:"loading",
                    requestId: action.meta.requestId,
                }
                state.testId = action.meta.arg
            }
        })
        builder.addCase(loadProcessTestSteps.fulfilled, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                adapter.setMany(state, action.payload)
                state.status = { type: "loaded" }
            }
        })
        builder.addCase(loadProcessTestSteps.rejected, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                state.status = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(createTestStep.fulfilled, (state, action) => {
            if (state.testId === action.payload.testId && state.status.type === "loaded") {
                adapter.upsertOne(state, action.payload)
            }
        })
        builder.addCase(updateTestStep.fulfilled, (state, action) => {
            if (state.testId === action.payload.testId && state.status.type === "loaded") {
                adapter.upsertOne(state, action.payload)
            }
        })
        builder.addCase(logout.fulfilled, (state) => {
            adapter.removeAll(state)
            state.status = { type: "empty" }
            state.testId = undefined
        })
    },
})

export const { clearProcessTestSteps } = processTestStepsSlice.actions

export const selectProcessTestSteps = (state: RootState) => state.processTestSteps

export const {
    selectAll: selectAllProcessTestSteps,
    selectById: selectProcessTestStepById,
} = adapter.getSelectors(selectProcessTestSteps)

export default processTestStepsSlice.reducer