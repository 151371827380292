import { unwrapResult } from "@reduxjs/toolkit"
import { ReactElement, useCallback, useEffect, useState } from "react"
import { BlobMeta, loadBlobs, uploadBlobs } from "../features/urs/ursAttachementsSlice"
import { useAppDispatch } from "./hooks"

export interface BlobsProps {
    guid: string
    blobs: BlobMeta[]
    upload: (files: File[]) => void
    uploading: boolean
}

export interface AppBlobsProviderProps {
    guid: string | null
    component: (props: BlobsProps) => ReactElement
}

export function AppBlobsProvider(props: AppBlobsProviderProps) {
    const { guid } = props
    const dispatch = useAppDispatch()

    const [blobs, setBlobs] = useState<BlobMeta[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [uploading, setUploading] = useState<boolean>(false)

    const upload = useCallback((files: File[]) => {
        if (guid) {
            setUploading(true)
            dispatch(uploadBlobs([guid, files]))
                .then(unwrapResult)
                .then(bs => setBlobs(bs))
                .finally(() => setUploading(false))
        }
    }, [guid])

    useEffect(() => {
        if (guid) {
            setBlobs([])
            setLoading(true)
            dispatch(loadBlobs(guid))
                .then(unwrapResult)
                .then(bs => setBlobs(bs))
                .finally(() => setLoading(false))
        }
    }, [guid])

    const Component = props.component

    if (!guid) return null

    if (loading) return <>Loading blobs...</>

    return <Component blobs={blobs} upload={upload} uploading={uploading} guid={guid} />
}
