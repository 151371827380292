import { Localized } from "@fluent/react"
import { Autocomplete, Skeleton, TextField } from "@mui/material"
import { useState } from "react"
import { LoadUsers } from "../../app/AppDataLoader"
import { AppId } from "../../app/appTypes"
import { useAppSelector } from "../../app/hooks"
import { selectAllUsers, selectUserById, User } from "./usersSlice"

export const SelectUser = ({ initialUserId, onUserIdChange }: { initialUserId: AppId | null, onUserIdChange : (userId : AppId) => void }) => {
    const allUsers = useAppSelector(selectAllUsers)
    const initialUser = useAppSelector(state => selectUserById(state, initialUserId ?? '') ?? null)
    const [user, setUser] = useState<User | null>(initialUser)
    const handleUserChange = (_, newUser) => {
        setUser(newUser ?? null)
        onUserIdChange(newUser?.id ?? '')
    }
    return <LoadUsers component={<Skeleton variant="text" />}>
                <Autocomplete 
                    sx={{
                        marginTop: 1,
                    }}
                    options={allUsers}
                    value={user}
                    onChange={handleUserChange}
                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                    renderInput={params =>
                        <TextField {...params}
                            helperText={user?.position ?? " "}
                            label={
                                <Localized id="username">
                                    <span>Nazwa użytkownika</span>
                                </Localized>
                            }
                        />
                    }
                />
            </LoadUsers>
}
