import { Localized } from "@fluent/react"
import { TaskType } from "./myTasksSlice"

export const TaskTypeLabel = ({ type }: { type: TaskType}) => {
    switch (type) {
        case "P":
            return <Localized id="stage-status-p">
                <span>Planowanie</span>
            </Localized>
        case "R":
            return <Localized id="stage-status-r">
                <span>Ponowane planowanie</span>
            </Localized>
        case "V":
            return <Localized id="stage-status-v">
                <span>Weryfikacja</span>
            </Localized>
        case "A":
            return <Localized id="stage-status-a">
                <span>Zatwierdzanie</span>
            </Localized>
        case "T":
            return <Localized id="stage-status-t">
                <span>Testowanie</span>
            </Localized>
        case "S":
            return <Localized id="task-type-s">
                <span>Weryfikacja testu</span>
            </Localized>
        case "Approvement":
            return <Localized id="task-urs-approve">
                <span>Zatwierdź dokument URS</span>
            </Localized>
        case "FinishOpinion":
            return <Localized id="task-urs-opinion">
                <span>Zaopiniuj dokument URS</span>
            </Localized>
        case "FinishEdition":
            return <Localized id="task-urs-edition">
                <span>Zakończ edytowanie</span>
            </Localized>
        default : 
            return <></>
    }
}