import { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PrintIcon from '@mui/icons-material/Print';
import CopyIcon from '@mui/icons-material/FileCopy';
import HistoryIcon from '@mui/icons-material/History';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DownloadIcon from '@mui/icons-material/Download';
import { AppId } from '../../../app/appTypes';
import { DocumentReportPrinter, PrinterMode } from './DocumentReportPrinter';
import { Localized } from '@fluent/react';

export interface DocumentMenuProps {
    documentId: AppId
}

export const DocumentToolbarMenu = ({ documentId }: DocumentMenuProps) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
    const [mode, setMode] = useState<PrinterMode>('download');

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleMenuItemClick = (selectedMode: PrinterMode) => {
        setMode(selectedMode);
        setIsDialogOpen(true);
        handleClose();
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    return (
        <div>
            <Button
                aria-controls="document-menu"
                aria-haspopup="true"
                onClick={handleClick}
                variant="contained"
                color="primary"
                size="small"
                endIcon={<ArrowDropDownIcon />}
            >
                <Localized id="Document">
                    <span>Document</span>
                </Localized>
            </Button>
            <Menu
                id="document-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleMenuItemClick('download')}>
                    <DownloadIcon sx={{ mr: 1 }} />
                    <Localized id="report-download">
                        <span>Download Report</span>
                    </Localized>
                </MenuItem>
                <MenuItem onClick={() => handleMenuItemClick('open')}>
                    <PrintIcon sx={{ mr: 1 }} />
                    <Localized id="report-open">
                        <span>open report</span>
                    </Localized>
                </MenuItem>
                <MenuItem disabled={true} >
                    <CopyIcon sx={{ mr: 1 }} />
                    <Localized id="document-create-copy">
                        <span>Copy</span>
                    </Localized>

                </MenuItem>
                <MenuItem disabled={true} >
                    <HistoryIcon sx={{ mr: 1 }} />
                    <Localized id="document-history">
                        <span>Historia dokumentu</span>
                    </Localized>
                   
                </MenuItem>
            </Menu>
            <DocumentReportPrinter
                documentId={documentId}
                language=""
                mode={mode}
                isDialogOpen={isDialogOpen}
                closeDialog={handleCloseDialog}
            />
        </div>
    );
};