import { useCallback, useEffect, useState } from "react"
import { NoteHistory, Notes, createNote, loadAllObjectNotes, updateNote } from "./NotesSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { Box, Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControlLabel, Grid, Stack, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material"
import { RichTextEditor } from "../../../app/RichTextEditor"
import AddIcon from '@mui/icons-material/Add';
import { LoadingButton } from "@mui/lab"
import { APIError } from "../../../app/appTypes"
import produce from "immer"
import { If } from "../../../app/If"
import { CompareNotes } from "./CompareNotes"
import { ErrorContainer } from "../../../app/ErrorContainer"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const NotesList = (props: { objectId: string, canEdit: boolean }) => {
    const { objectId, canEdit } = props
    const dispatch = useAppDispatch()
    const { locale } = useAppSelector(selectSettings);
    const [notes, setNotes] = useState<Notes[]>([]);
    const { l10n } = useLocalization()
    const [noteDialogVisible, setNoteDialogVisible] = useState(false);
    const [validationErrors, setValidationErrors] = useState(false)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState<APIError | null>(null)
    const [noteToEdit, setNoteToEdit] = useState<Notes | null>(null)
    const [showHistory, setShowHistory] = useState(false);
    const [title, setTitle] = useState("")
    const [content, setContent] = useState("")
    const [saving, setSaving] = useState(false)
    const titleError = validationErrors && title === ""
    const [selectedRow, setSelectedRow] = useState(null);
    const [historyRow, setHistoryRow] = useState<NoteHistory | null>(null);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAllObjectNotes(objectId))
            .then(unwrapResult)
            .then(setNotes)
            .catch((error) => { console.error(error); setIsError(true); setError(error) })
            .finally(() => setIsLoading(false));
    }, [])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleSelectionChange = (selectionModel) => {

        if (selectionModel.length === 1) {
            setSelectedRow(selectionModel[0]);

            const index = noteToEdit?.history.findIndex(u => u.id === selectionModel[0])
            if (noteToEdit && index != undefined && index >= 0) {
                setHistoryRow(noteToEdit.history[index])
            }
        } else {
            setSelectedRow(null);
        }
    };

    const handleEditNote = useCallback((entity: Notes) => {
        setShowHistory(false)
        setSelectedRow(null)
        setHistoryRow(null)
        setNoteToEdit(entity)
        setTitle(entity.title)
        setContent(entity.content)
        setNoteDialogVisible(true)
    }, [setNoteToEdit])


    const handleOpenNoteDialogClick = useCallback(() => {
        setShowHistory(false)
        setSelectedRow(null)
        setHistoryRow(null)

        setTitle("")
        setContent("")
        setNoteToEdit(null)
        setNoteDialogVisible(true)
    }, [setNoteDialogVisible])

    const handleCancelDialog = useCallback(() => setNoteDialogVisible(false), [])

    const handleChangeShowHistory = (event: React.ChangeEvent<HTMLInputElement>) => {
        setShowHistory(event.target.checked);
    };


    const handleClickSave = async () => {
        setValidationErrors(true)
        setSaving(true)

        if (noteToEdit === null) {
            await dispatch(createNote({
                title: title,
                content: content,
                parentObjectGuid: objectId
            }))
                .then(unwrapResult)
                .then((note) => {
                    setNoteDialogVisible(false)
                    setNotes(produce(draft => {
                        draft.push(note)
                    }))
                })
                .catch((err) => setError(err))
                .finally(() => setSaving(false))
        } else {

            await dispatch(updateNote({
                ...noteToEdit,
                title: title,
                content: content,
                parentObjectGuid: objectId
            }))
                .then(unwrapResult)
                .then((note) => {
                    setNoteDialogVisible(false)
                    setNotes(produce(draft => {
                        if (draft !== undefined) {
                            const index = draft.findIndex(u => u.id === note.id)
                            if (index >= 0) {
                                draft[index] = note
                            } else {
                                draft.push(note)
                            }
                        }
                    }))
                })
                .catch((err) => setError(err))
                .finally(() => setSaving(false))
        }
    }

    const columns: GridColDef[] =
        [
            {
                field: 'title',
                flex: 1,
                headerName: l10n.getString('notes-title', null, 'Tytuł notatki'),

            },

            {
                field: 'modifiedByFullName',
                headerName: l10n.getString('notes-last-update-by', null, 'Ostatnia edycja'),
                width: 400
            },
            {
                field: 'modifyDate',
                type: 'date',
                width: 150,
                headerName: l10n.getString('notes-last-update-date', null, 'Data'),
                valueGetter: (_, row) => new Date(row.createDate)
            },
            {
                field: 'actions', type: 'actions', width: 200,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button onClick={() => handleEditNote(params.row)} color="secondary" variant="outlined">
                                <Localized id="open">
                                    <span>Podglad</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>

                    )
                }
            }
        ];


    const historyColumns: GridColDef[] =
        [
            {
                field: 'createdByFullName',
                headerName: l10n.getString('notes-last-update-by', null, 'Ostatnia edycja'),
                flex: 1
            },
            {
                field: 'createDate',
                flex: 1,
                headerName: l10n.getString('notes-last-update-date', null, 'Data'),
                valueGetter: (_, row) => new Date(row.createDate).toLocaleString()
            }
        ];

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return <>
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid
                    style={{ minHeight: 100, height: 'calc(100vh - 425px)' }}
                    localeText={getCustomLocaleText()}
                    rows={notes}
                    columns={columns}
                />
                <If condition={canEdit}>
                    <Fab onClick={handleOpenNoteDialogClick} color="secondary" sx={{ marginTop: 2 }}>
                        <AddIcon />
                    </Fab>
                </If>
            </div>
        </div>
        <Dialog open={noteDialogVisible === true} maxWidth='xl' sx={{ minHeight: '80%' }} fullWidth keepMounted={false}>
            <DialogTitle>
                <If condition={noteToEdit !== null}>
                    <Localized id='notes-edit'>Edycja notatki</Localized>
                </If>
                <If condition={noteToEdit === null}>
                    <Localized id='notes-new'>Nowa notatka</Localized>
                </If>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <If condition={!showHistory}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                <Localized id="notes-current-version">
                                    <span>Aktualna wersja</span>
                                </Localized>
                            </Typography>
                        </Grid>

                        <Grid item xs={12}>
                            <Stack sx={{ mt: 2 }} spacing={2}>
                                <TextField
                                    fullWidth
                                    autoFocus
                                    error={titleError}
                                    InputLabelProps={{
                                        shrink: true
                                    }}
                                    helperText={titleError && <RequiredError />}
                                    inputProps={{ maxLength: 500 }}
                                    value={title}
                                    onChange={e => setTitle(e.target.value)}
                                    label={<Localized id='notes-title'>Tytuł</Localized>}
                                />
                                <Box sx={{ height: "500px", overflowY: "auto" }}>
                                    <RichTextEditor
                                        label={<Localized id='notes-content'>Treść notatki</Localized>}
                                        value={content}
                                        onValueChange={setContent} />
                                </Box>

                            </Stack>
                        </Grid>
                    </If>
                    <If condition={false && showHistory}>
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                                <Localized id="">
                                    <span>Historyczna wersja</span>
                                </Localized>
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            {noteToEdit && noteToEdit.history && noteToEdit.history.length > 0 ? (
                                <div>
                                    <Stack sx={{ mt: 2 }} spacing={2}>
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            inputProps={{ maxLength: 500 }}
                                            value={historyRow?.title}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            label={<Localized id='title'>Tytuł</Localized>}
                                        />
                                        <Box sx={{ height: "500px", overflowY: "auto" }}>
                                            <div dangerouslySetInnerHTML={{ __html: historyRow?.content ?? noteToEdit.content }}></div>
                                        </Box>
                                    </Stack>

                                </div>
                            ) : (
                                <p></p>
                            )}
                        </Grid>
                        <Grid item xs={6}>
                            {noteToEdit && noteToEdit.history && noteToEdit.history.length > 0 ? (
                                <DataGrid
                                    autoHeight
                                    rows={noteToEdit.history}
                                    columns={historyColumns}
                                    onRowSelectionModelChange={handleSelectionChange}
                                    rowSelectionModel={selectedRow ? [selectedRow] : []}
                                />
                            ) : (
                                <p>
                                    <Localized id='notes-no-history-data'>No history data available</Localized>
                                </p>
                            )}
                        </Grid>
                    </If>
                    <If condition={showHistory}>
                        {noteToEdit !== null && <CompareNotes note={noteToEdit} leftNoteId={noteToEdit.id} rightNoteId={noteToEdit.history.length > 0 ? noteToEdit.history[0].id : noteToEdit.id} />}
                    </If>

                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Switch checked={showHistory} onChange={handleChangeShowHistory} />
                            }
                            label={<Localized id='notes-history'>Historia edycji</Localized>}
                        />
                    </Grid>

                </Grid>

            </DialogContent>
            <DialogActions>
                <If condition={canEdit}>
                    <LoadingButton loading={saving} onClick={handleClickSave}>
                        <Localized id='save'>Save</Localized>
                    </LoadingButton>
                </If>
                <LoadingButton loading={saving} onClick={handleCancelDialog}>
                    <Localized id='cancel'>Anuluj</Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog >
    </>;
}
