import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Alert, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { createUrsSubGroup } from "../UrsSlice"

export interface CreateSubGroupDialogProps {
    ursId: AppId
    parentGroupId: string
    parentGroupName: string
    onSucces: () => void
    onCancel: () => void
}

export const CreateSubGroupDialog = (props: CreateSubGroupDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [name, setName] = useState('')
    const [serverError, setServerError] = useState<string | null>(null)
    const [requiredError, setRequiredError] = useState<boolean | null>(null)

    const handleOk = () => {
        setRequiredError(name ? false : true)
        if (name) {
            setSaving(true)
            dispatch(createUrsSubGroup({ursId: props.ursId, name, code: '', parentGroupGuid: props.parentGroupId}))
                .then(unwrapResult)
                .then(() => {
                    props.onSucces()
                })
                .finally(() => setSaving(false))
                .catch(err => { setServerError(err.kind + ': ' + err.status.toString()) })
        }
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='urs-create-group'>Utwórz grupę</Localized>
        </DialogTitle>
        <DialogContentText sx={{ ml: 4 }}>{props.parentGroupName}</DialogContentText>
        <DialogContent>
            <Stack spacing={2}>
                <TextField
                    fullWidth
                    value={name}
                    onChange={e => setName(e.target.value)}
                    label={<Localized id='urs-name'>Nazwa</Localized>}
                    error={requiredError === true}
                    helperText={requiredError && <Localized id='required'>To pole jest wymagane</Localized>}
                />
                {serverError && <Alert severity="error"></Alert>}
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOk}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
