import { useCallback, useEffect, useState } from "react"
import { MeetingMinute, loadMeetingMinutesForObject } from "./meetingMinutesSlice"
import { unwrapResult } from "@reduxjs/toolkit"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { Localized, useLocalization } from "@fluent/react"
import { Button, ButtonGroup, Dialog, Fab } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import produce from "immer"
import { If } from "../../../app/If"
import { Link } from "react-router-dom"
import { MeetingStatusLabel } from "./MeetingStatusLabel"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { ErrorContainer } from "../../../app/ErrorContainer"
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { AddMeetingMinute } from "./dialogs/AddMeetingMinute"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"

export const MeetingMinutesList = (props: { objectId: string, objectType: string, canEdit: boolean }) => {
    const { objectId, objectType, canEdit } = props
    const dispatch = useAppDispatch()
    const { locale } = useAppSelector(selectSettings);
    const [notes, setNotes] = useState<MeetingMinute[]>([]);
    const { l10n } = useLocalization()
    const [noteDialogVisible, setNoteDialogVisible] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadMeetingMinutesForObject({objectId, objectType}))
            .then(unwrapResult)
            .then(setNotes)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleOpenNoteDialogClick = useCallback(() => {
        setNoteDialogVisible(true)
    }, [setNoteDialogVisible])

    const handleCancelDialog = useCallback(() => setNoteDialogVisible(false), [])

    const onSuccess = useCallback((note: MeetingMinute) => {
        setNotes(produce(draft => {
            draft.push(note)
        }))
        setNoteDialogVisible(false)
    }, [dispatch])

    const columns: GridColDef[] =
        [
            {
                field: 'title',
                flex: 1,
                headerName: l10n.getString('meeting-minutes-title', null, 'Tytuł'),

            },

            {
                field: 'createdByFullName',
                headerName: l10n.getString('meeting-minutes-author', null, 'Autor'),
                width: 400
            },
            {
                field: 'createDate',
                type: 'date',
                width: 150,
                headerName: l10n.getString('meeting-minutes-create-date', null, 'Data utworzenia'),
                valueGetter: (_, row) => new Date(row.createDate),
            },
            {
                field: 'status',
                headerName: l10n.getString('meeting-minutes-status', null, 'Status'),
                width: 400,
                renderCell: (params) => {
                    return (
                    <div>
                        <MeetingStatusLabel status={params.row.status}/>
                        <If condition={params.row.status === "INMEETING"}>
                            <PriorityHighIcon fontSize="small" color="error"/>
                        </If>
                    </div>
                    )
                }
            },
            {
                field: 'actions', type: 'actions', width: 400,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button disabled={params.row.status == "NEW"} component={Link} to={`/meetingMinutes/edit/${params.id}`} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Przejdz</span>
                                </Localized>
                            </Button>
                            <Button disabled={params.row.status === "INMEETING" }
                                component={Link} to={`/meeting/create/${params.id}`} color="secondary" variant="outlined">
                                <Localized id="meeting-minutes-start-meeting">
                                    <span>Rozpocznij spotkanie</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];


    if (isError){
        return <>
            <ErrorContainer error={error}/>
        </>
    }

    if (isLoading){
        return <>
            <LoadingContainer/>
        </>
    }
    return <>
        <div style={{ display: 'flex', height: '100%' }}>
            <div style={{ flexGrow: 1 }}>
                <DataGrid
                    style={{ minHeight: 100, height: 'calc(100vh - 425px)' }}
                    localeText={getCustomLocaleText()}
                    rows={notes}
                    columns={columns}
                />
                <If condition={canEdit}>
                    <Fab onClick={handleOpenNoteDialogClick} color="secondary" sx={{ marginTop: 2 }}>
                        <AddIcon />
                    </Fab>
                </If>
            </div>
        </div>
        <Dialog open={noteDialogVisible === true} maxWidth='sm' sx={{ minHeight: '80%' }} fullWidth keepMounted={false}>
            <AddMeetingMinute
                objectId={objectId}
                objectType={objectType}
                canEdit={canEdit}
                onSucces={onSuccess}
                onCancel={handleCancelDialog}/>
        </Dialog >
    </>
}
