import Container from '@mui/material/Container'
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { Localized } from "@fluent/react"
import SaveIcon from '@mui/icons-material/Save'
import { selectRequirementTypeById, createRequirementType, updateRequirementType, createSysRequirementType, updateSysRequirementType } from './RequirementsTypesSlice'
import { Link, useParams } from "react-router-dom"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { Stack } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { AppLoader } from "../../app/AppLoader"
import { selectModule } from '../modules/moduleSlice'
import { useNavigate } from 'react-router-dom'


const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const RequirementsTypesForm = () => {

    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectRequirementTypeById(state, id));    
        
    const [code, setCode] = useState<string>(entity?.code ?? "");    
    const [name, setName] = useState<string>(entity?.name ?? "");        
    const [name_EN, setName_EN] = useState<string>(entity?.name_EN ?? "");        
    const [order, setOrder] = useState<number>(entity?.order ?? 0);        
     
    const [validationErrors, setValidationErrors] = useState(false)
    const [saving, setSaving] = useState(false)

    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [setCode])
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])    
    const handleName_ENChange: AppTextFieldHandler = useCallback(e => setName_EN(e.target.value), [setName_EN])    
    const handleOrderChange: AppTextFieldHandler = useCallback(e => setOrder(Number.parseInt(e.target.value)), [setName])    
    
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if (code && name && name_EN) {
            if (!entity) {                
                try {
                    if (currentModule?.code === "super"){
                        const actionResult = await dispatch(createSysRequirementType({                     
                            id, code, name, name_EN, isSystem: true , order
                        }));
                        const { id: newId } = unwrapResult(actionResult);

                        dispatch(showSuccess("saved"));

                        navigate(`/requirementsTypes/edit/${newId}`, { replace: true });
                    } 
                    else{
                        const actionResult = await dispatch(createRequirementType({                     
                            id, code, name, name_EN, isSystem: false, order
                        }));
                        const { id: newId } = unwrapResult(actionResult);

                        dispatch(showSuccess("saved"));

                        navigate(`/requirementsTypes/edit/${newId}`, { replace: true });
                    }
                    
                } catch (error) {
                    dispatch(showError("error"));
                }
            } else {
                try {
                    if (currentModule?.code === "super"){
                        await dispatch(updateSysRequirementType({...entity, 
                            code, name, name_EN, order
                        }));
                    }
                    else{
                        await dispatch(updateRequirementType({...entity, 
                            code, name, name_EN, order
                        }));
                    }
                    
                    dispatch(showSuccess("saved"));
                } catch (error) {
                    dispatch(showError("error"));
                }
            }
        }
        
        setSaving(false)
    }

    const codeError = validationErrors && code === ""
    const nameError = validationErrors && name === ""
    const nameError_EN = validationErrors && name_EN === ""

    return (
            <Container 
                component={Paper} 
                maxWidth="lg" 
                sx = {{
                    paddingTop:2,
                    paddingBottom:2
                }}>
                <Stack direction="column" spacing={2}>
                    <TextField 
                        error={codeError}
                        helperText={codeError && <RequiredError />}                        
                        value={code}
                        inputProps={{ maxLength: 20 }}
                        onChange={handleCodeChange}
                        label={
                            <Localized id="requirement-type-code">
                                <span>Kod</span>
                            </Localized>
                        }
                    />
                    <TextField 
                        error={nameError}
                        helperText={nameError && <RequiredError />}                        
                        value={name}
                        sx = {{width: "100%"}}
                        multiline
                        inputProps={{ maxLength: 200 }}
                        onChange={handleNameChange}
                        label={
                            <Localized id="requirement-type-name-pl">
                                <span>Nazwa typu wymagania (PL)</span>
                            </Localized>
                        }
                    />  
                    <TextField 
                        error={nameError_EN}
                        helperText={nameError_EN && <RequiredError />}                        
                        value={name_EN}
                        sx = {{width: "100%"}}
                        multiline
                        inputProps={{ maxLength: 200 }}
                        onChange={handleName_ENChange}
                        label={
                            <Localized id="requirement-type-name-en">
                                <span>Nazwa typu wymagania (EN)</span>
                            </Localized>
                        }
                    />  
                     <TextField   
                        value={order}
                        sx = {{width: "10%"}}
                        inputProps={{ maxLength: 200 }}
                        onChange={handleOrderChange}
                        type="number"
                        label={
                            <Localized id="requirement-type-order">
                                <span>Kolejność</span>
                            </Localized>
                        }
                    />                    
                </Stack>
                <ButtonGroup color="secondary" variant="contained"  sx={{marginTop:2}}> 
                    <Button startIcon={<SaveIcon />} 
                            onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                    </Button>
                    <Button component={Link} to={`/requirementsTypes`} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                    </Button>
                </ButtonGroup>
                <AppLoader open={saving} />
            </Container>
    );
}
