import { Localized } from "@fluent/react";
import { DocumentStatus } from "../documents/documentsApi";

export const DocumentStatusLabel = ({ status }: { status: DocumentStatus }) => {
    switch (status) {
        case "N":
            return <Localized id="document-status-n">
                <span>Nowy</span>
            </Localized>        
        case "I":
            return <Localized id="document-status-i">
                <span>Do zatwierdzenia</span>
            </Localized>
        case "C":
            return <Localized id="document-status-c">
                <span>Korekta</span>
            </Localized>
        case "A":
            return <Localized id="document-status-a">
                <span>Zatwierdzony</span>
            </Localized>
        case "F":
            return <Localized id="document-status-f">
                <span>Zakończony</span>
            </Localized>
        case "P":
            return <Localized id="document-status-p">
                <span>Opublikowany</span>
            </Localized>
        case "O":
            return <Localized id="document-status-o">
                <span>Opiniowanie</span>
            </Localized>
        case "D":
            return <Localized id="document-status-d">
                <span>Zaopiniowany</span>
            </Localized>
        case "E":
            return <Localized id="document-status-e">
                <span>Zaimportowany</span>
            </Localized>
        default:
            return <span>{status}</span>
    }
}