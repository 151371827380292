import {Localized} from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material"
import { useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { AppId } from "../../app/appTypes"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { deleteClientQuestion, selectAiQuestionById } from "./aiQuestionsSlice"

export interface RestoreSysVersionDialogProps {
    id: AppId,
    onSaveSuccess : () => void
    onCancel  : () => void
}

export const RestoreSysVersionDialog = ({ id, onSaveSuccess, onCancel }: RestoreSysVersionDialogProps) => {
    const dispatch = useAppDispatch()
    const entity = useAppSelector(state => selectAiQuestionById(state, id ?? ""));
    const [saving, setSaving] = useState(false)
    
    const handleConfirmDelClick = async () => {
        setSaving(true)
        try {
            if (entity) {
                await dispatch(deleteClientQuestion(id)).unwrap()

                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    const handleCancelDelClick = () => {
        onCancel()
    }

    return <Dialog open={true}>
        <DialogContent>
            <DialogContentText>
                <Localized id="ai-restore-version">
                    <span>Czy napewno chcesz przywrócić systemową wersje?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmDelClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
