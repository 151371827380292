import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType, AppId } from "../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";

export type SystemSettingsId = string;

export interface SystemSettings {
    id: SystemSettingsId;
    email: string;
    notificationType: string;
    createDate: Date
}

export type DraftSystemSettings = Omit<SystemSettings, "id" | 'createDate'>

export const loadSystemSettings = createAsyncThunk<SystemSettings[], string, AppThunkAPIType>(
    "system-settings/load",
    async (query, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-settings/notifications${query}` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemSettings[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const getSystemNotificationTypes = createAsyncThunk<string[], void, AppThunkAPIType>(
    "system-settings/load-types",
    async (_, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-settings/notifications/types` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as string[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createSystemNotification = createAsyncThunk<SystemSettings, DraftSystemSettings, AppThunkAPIType>(
    "system-settings/create",
    async (createPayload: DraftSystemSettings, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/system-settings/notifications",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as SystemSettings
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const editSystemNotification = createAsyncThunk<SystemSettings, Omit<SystemSettings, 'createDate'>, AppThunkAPIType>(
    "system-settings/edit",
    async (createPayload: DraftSystemSettings, { rejectWithValue, dispatch }) => {
        const result = await dispatch(putWithAuth({
            url: "api/system-settings/notifications",
            payload: createPayload,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return payload as SystemSettings
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSystemNotification = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "system-settings/delete",
    async (id, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({
            url: `api/system-settings/notifications/${id}`
        }))
        const { payload } = result

        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)