import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { getWithAuth, postWithAuth } from "../../../http"

export type DocumentTeamMemberRole = 'C' | 'E' | 'R' | 'A' | 'AR' | 'V' | 'EXT_R'


export interface DocumentTeamMember {
    id: AppId
    userName: string
    firstName: string
    surname: string
    companyId: AppId
    companyName: string
    position: string
    role: DocumentTeamMemberRole
    substitutionName: string
    documentId: AppId
    approvementDate: string | null
    opinionDate: string | null
    finishEditionDate: string | null
    opinionAction: DocumentTeamMemberAction | null
    approveAction: DocumentTeamMemberAction | null
    opinionClosed: DocumentTeamMemberAction | null
    finishEditionAction: DocumentTeamMemberAction | null
}

export interface DocumentTeamMemberAction {
    deadline: Date
    execDate: Date
    status: string
}

export const loadDocumentTeamMembers = createAsyncThunk<DocumentTeamMember[], string, AppThunkAPIType>("document-team-member/load",
    async (documentId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/document-team-members/${documentId}` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as DocumentTeamMember[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const addDocumentTeamMember = createAsyncThunk<DocumentTeamMember, { documentId: AppId, userName: string, role: string }, AppThunkAPIType>("document-team-member/addTeamMember", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/document-team-members/${data.documentId}/add`,
        payload: {
            documentId: data.documentId,
            userName: data.userName,
            role: data.role
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as DocumentTeamMember
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const substituteDocumentTeamMember = createAsyncThunk<DocumentTeamMember, { documentId: AppId, userName: string, substitution: string }, AppThunkAPIType>("document-team-member/substitute",
    async (data, { dispatch, rejectWithValue }) => {

        const result = await dispatch(postWithAuth({
            url: `api/document-team-members/${data.documentId}/substitute`,
            payload: data,
        }))

        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const inviteDocumentTeamMember = createAsyncThunk<DocumentTeamMember, { documentId: AppId, userEmail: string, role: string }, AppThunkAPIType>("document-team-member/inviteTeamMember", async (data, { dispatch, rejectWithValue }) => {
    const result = await dispatch(postWithAuth({
        url: `api/document-team-members/${data.documentId}/invite`,
        payload: {
            documentId: data.documentId,
            userEmail: data.userEmail,
            role: data.role
        },
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as DocumentTeamMember
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteDocumentTeamMember = createAsyncThunk<AppId, { documentId: AppId, teamMemberId: AppId }, AppThunkAPIType>("document-team-member/deleteTeamMember",
    async (data, { dispatch, rejectWithValue }) => {

        const result = await dispatch(postWithAuth({
            url: `api/document-team-members/${data.documentId}/delete/${data.teamMemberId}`,
            payload: {}
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return data.teamMemberId

        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })