import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit"
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes"
import { RootState } from "../../app/store"
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http"
import { selectModule } from "../modules/moduleSlice"
import { selectAllStructureFamily, selectStructureAndChildren, selectStructureAndParents } from "../device/structureRelationshipSlice"
import { logout } from "../user/userSlice"

export type ValidationStage = "IQ" | "OQ" | "DQ" | "PQ"

export interface Validation {
    id: AppId
    name: string
    description: string
    stage: ValidationStage
    structureId: AppId
    isSystem: boolean
}

export const loadValidations = 
    createAsyncThunk<Validation[], void, AppThunkAPIType>("validations/load", async (_, { dispatch, rejectWithValue, getState, requestId }) => {
        const module = selectModule(getState()).currentModule
        const { state } = getState().validations

        switch (state.type) {
            case "loading":
                if (state.requestId === requestId) {
                    const result = await dispatch(getWithAuth({ url: `api/tests-definitions?moduleId=${module?.sysModuleId ?? "undefined"}` }))

                    const { payload } = result
                    if (getWithAuth.fulfilled.match(result)) {
                        return payload as Validation[]
                    } else {
                        return rejectWithValue(payload ?? { kind: 'unknown' })
                    }   
                } else { 
                    return []
                }
            default:
                return []
        }
    }
)

export const loadSysValidations = 
    createAsyncThunk<Validation[], void, AppThunkAPIType>("sysValidations/load", async (_, { dispatch, rejectWithValue, getState, requestId }) => {
        const { state } = getState().validations
        
        switch (state.type) {
            case "loading":
                if (state.requestId === requestId) {
                    const result = await dispatch(getWithAuth({ url: "api/sys/tests-definitions" }))

                    const { payload } = result
                    if (getWithAuth.fulfilled.match(result)) {
                        return payload as Validation[]
                    } else {
                        return rejectWithValue(payload ?? { kind: 'unknown' })
                    }   
                } else {
                    return []
                }
            default:
                return []
        }
    }
)

export const createValidation = createAsyncThunk<Validation, Omit<Validation, "id">, AppThunkAPIType>(
    "validations/create", 
    async (validation, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/tests-definitions",
            payload: validation,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createSysValidation = createAsyncThunk<Validation, Omit<Validation, "id">, AppThunkAPIType>(
    "sysValidations/create", 
    async (validation, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/sys/tests-definitions",
            payload: validation,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const copySysValidation = createAsyncThunk<Validation, AppId, AppThunkAPIType>(
    "sysValidations/copy", 
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/sys/tests-definitions/copy/${id}`,
            payload: {}
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const copyValidation = createAsyncThunk<Validation, AppId, AppThunkAPIType>(
    "Validations/copy", 
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/tests-definitions/copy/${id}`,
            payload: {}
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateValidation = createAsyncThunk<Validation, Validation, AppThunkAPIType>(
    "validations/update", 
    async (validation: Validation, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/tests-definitions/${validation.id}`,
            payload: validation,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSysValidation = createAsyncThunk<Validation, Validation, AppThunkAPIType>(
    "sysValidations/update", 
    async (validation: Validation, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/sys/tests-definitions/${validation.id}`,
            payload: validation,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return validation
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteValidation = createAsyncThunk<AppId, AppId, AppThunkAPIType>("validations/delete", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuth({ url: `api/tests-definitions/${id}` }))
    const { payload } = result 
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteSysValidation = createAsyncThunk<AppId, AppId, AppThunkAPIType>("sysvalidations/delete", 
    async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuth({ url: `api/sys/tests-definitions/${id}` }))

    const { payload } = result 
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

const validationAtapter = createEntityAdapter<Validation>({
    selectId: (validation) => validation.id,
})

export type ValidationsState = EntityState<Validation> & { state: AppDataState, }

const initialState: ValidationsState = 
    validationAtapter.getInitialState({
        state: { type: "empty" },
    })

export const validationsSlice = createSlice({
    name: "validations",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createValidation.fulfilled, (state, action) => {
            validationAtapter.addOne(state, action.payload)
        })
        builder.addCase(createSysValidation.fulfilled, (state, action) => {
            validationAtapter.addOne(state, action.payload)
        })
        builder.addCase(copySysValidation.fulfilled, (state, action) => {
            validationAtapter.addOne(state, action.payload)
        })
        builder.addCase(copyValidation.fulfilled, (state, action) => {
            validationAtapter.addOne(state, action.payload)
        })
        builder.addCase(updateValidation.fulfilled, (state, action) => {
            validationAtapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateSysValidation.fulfilled, (state, action) => {
            validationAtapter.upsertOne(state, action.payload)
        })
        builder.addCase(loadValidations.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = { type: "loading", requestId: action.meta.requestId }
            }
        })
        builder.addCase(loadValidations.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                validationAtapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadValidations.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadSysValidations.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = { type: "loading", requestId: action.meta.requestId, }
            }
        })
        builder.addCase(loadSysValidations.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                validationAtapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadSysValidations.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(deleteValidation.fulfilled, (state, action) => {
            validationAtapter.removeOne(state, action.payload)
        })
        builder.addCase(deleteSysValidation.fulfilled, (state, action) => {
            validationAtapter.removeOne(state, action.payload)
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            validationAtapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectValidations = (state: RootState): ValidationsState => state.validations

export const {
    selectAll: selectAllValidations,
    selectById: selectValidationById,
    selectTotal: selectTotalValidations,
    selectEntities: selectValidationEntities,
} = validationAtapter.getSelectors<RootState>(state => state.validations)

export const selectDevicesValidationsMap = 
    createSelector(
        selectAllValidations, 
        (validations) => {
            const m = new Map<AppId, Set<AppId>>()
            for (const { id, structureId } of validations) {
                m.set(structureId, (m.get(structureId) ?? new Set()).add(id))
            }
            return m
        }
    )

export const selectAllValidationsFamily = createSelector(
    state => selectAllValidations(state),
    (state, structureId: AppId) => selectAllStructureFamily(state, structureId),
    (validations: Validation[], structure) => validations.filter(({ structureId }) => structure.has(structureId))
)

export const selectAllValidationsAndChildren = createSelector(
    (state) => selectAllValidations(state),
    (state, structureId: AppId) => selectStructureAndChildren(state, structureId),
    (validations: Validation[], structure) => validations.filter(({ structureId }) => structure.has(structureId))
)

export const selectAllValidationsAndParents = createSelector(
    (state) => selectAllValidations(state),
    (state, structureId: AppId) => selectStructureAndParents(state, structureId),
    (validations: Validation[], structure) => validations.filter(({ structureId }) => structure.has(structureId))
)

export default validationsSlice.reducer
