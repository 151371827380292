import { Localized } from "@fluent/react"
import { Autocomplete, DialogActions, DialogContent, DialogTitle, MenuItem, Skeleton, TextField } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { LoadUsers } from "../../app/AppDataLoader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { store } from "../../app/store"
import { selectAllUsers, selectUserByName, User } from "../users/usersSlice"
import { LoadingButton } from "@mui/lab"
import { addUrsTeamMember, substituteUrsTeamMember } from "./UrsSlice"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { unwrapResult } from "@reduxjs/toolkit"

export interface TeamMemberDialogFormProps {
    ursId: AppId
    mode: "create" | "edit" | "substitute"
    defaultUserName: string
    excludedUsers: Set<string>
    onOKClick: (userName: string | undefined) => void
    onCancelClick: () => void,
}
export const TeamMemberDialogForm = ({  ursId, mode, defaultUserName, onCancelClick, onOKClick, excludedUsers }: TeamMemberDialogFormProps) => {
    const dispatch = useAppDispatch()
    const allUsers = useAppSelector(selectAllUsers)
    const [user, setUser] = useState<User | null>(() => mode !="substitute" ? (selectUserByName(store.getState(), defaultUserName) ?? null) : null)
    const [userRole, setUserRole] = useState<string>("")
    
    const [savingInProgress, setSavingInProgress] = useState(false)
    const handleUserChange = useCallback((_, newUser) => {
        setUser(newUser)
    }, [])

    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setUserRole(e.target.value), [setUserRole])


    const availableUsers = 
        useMemo(() => allUsers.filter(u => !excludedUsers.has(u.userName) || u.userName === defaultUserName), [allUsers, excludedUsers, defaultUserName])

    const handleOkClick = () => {
        if (user !== null) {
            setSavingInProgress(true)
            switch (mode) {
                case "create":
                    dispatch(addUrsTeamMember({ userName: user.userName, ursId , role: userRole }))
                        .then(unwrapResult)
                        .then(() => onOKClick(user.userName))
                        .finally(() => setSavingInProgress(false))
                    break
                case "edit":
                    break
                case "substitute":
                    dispatch(substituteUrsTeamMember({ userName: defaultUserName, ursId, substitution:  user.userName}))
                    .then(unwrapResult)
                    .then(() => onOKClick(user.userName))
                    .finally(() => setSavingInProgress(false))
                    break

               
            }
           
        }
    }

    return <>
        <DialogTitle>
            <If condition={mode === "create"}>  
                <Localized id="urs-add-team-member">
                    <span>Nowy członek zespołu</span>
                </Localized>
            </If>
            <If condition={mode === "edit"}>
               <Localized id="urs-edit-team-member">
                    <span>Edycja członka zespołu</span>
               </Localized>
            </If>
            <If condition={mode === "substitute"}>
               <Localized id="urs-appoint-substitution">
                    <span>Wyznacz zastępstwo</span>
               </Localized>
            </If>
        </DialogTitle>
        <DialogContent sx={{
            padding: 2,
        }}>
            <LoadUsers component={<Skeleton />}>
                <Autocomplete 
                    sx={{
                        marginTop: 1,
                    }}
                    options={availableUsers}
                    value={user}
                    onChange={handleUserChange}
                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                    renderInput={params =>
                        <TextField {...params}
                            helperText={user?.position ?? " "}
                            label={
                                <Localized id="username">
                                    <span>Nazwa użytkownika</span>
                                </Localized>
                            }
                        />
                    }
                />
            </LoadUsers>
            <TextField    
                    sx={{
                        marginTop: 1,
                    }}                                                        
                    value={userRole}
                    fullWidth
                    required={true}
                    select
                    label={<Localized id="urs-user-role"><span>Rola</span></Localized>}
                    onChange={handleUserRoleChange}
                    >
                
                    <MenuItem value={"E"}><Localized id="urs-role-editor"><span>Edytor</span></Localized></MenuItem>    
                    <MenuItem value={"R"}><Localized id="urs-role-reviever"><span>Opiniujący</span></Localized></MenuItem>
                    <MenuItem value={"A"}><Localized id="urs-role-approver"><span>Zatwierdzający</span></Localized></MenuItem>
                    <MenuItem value={"AR"}><Localized id="urs-role-reviever-and-approver"><span>Opiniujący + Zatwierdzający</span></Localized></MenuItem>
                    <MenuItem value={"V"}><Localized id="urs-role-viewer"><span>Obserwator</span></Localized></MenuItem>                       
                </TextField>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={savingInProgress} onClick={handleOkClick}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={savingInProgress} onClick={onCancelClick}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}