import { Localized, useLocalization } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Action, deleteProject, updateProjectStatus } from "./projectSlice"
import { deletepProcess, updateProjectProcessStatus } from "./projectProcess/ProjectProcessApiClient"
import { useNavigate } from "react-router-dom"


interface StatusResponse {
    id: string;
    actionId: string;
    status: string;
}

export interface ActionRunnerProps {
    action: Action & { name: string, description: string } 
    onSuccess: (data: StatusResponse) => void
    onError: () => void
    onCancel: () => void
}

export const ActionRunner = ({ action, onSuccess, onError, onCancel }: ActionRunnerProps) => {
    const dispatch = useAppDispatch()
    const [password, setPassword] = useState("")
    const [running, setRunning] = useState(false)
    const { id, authorize } = action
    const navigate = useNavigate()
    const { l10n } = useLocalization();

    const run = async() => {
        if (action && action.type === "POST") {
            setRunning(true)


            if (action.actionId == "deleteProject"){
                const result = await dispatch(deleteProject({
                    id: id,
                    args: authorize ? { password } : { password:''} 
                }))

                if (deleteProject.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    navigate(-1)

                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            } else if (action.actionId == "deleteProjectProcess"){
                const result = await dispatch(deletepProcess({
                    id: id,
                    args: authorize ? { password } : { password:''} 
                }))

                if (deletepProcess.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    // navigate(-1)
                    let response:StatusResponse = { id: action.id, actionId: action.actionId, status: "" };
                    onSuccess(response);
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }
        } else if(action && action.type === "PUT") {
            if(action.actionId == "startProject" || action.actionId == "pauseProject" || action.actionId == "cancelProject" || action.actionId == "finishProject") {
                const result = await dispatch(updateProjectStatus({ 
                    id: id,
                    args: authorize ? { password, status: action.status ?? "" } : { password:'', status: action.status ?? "" } 
                }))

                if (updateProjectStatus.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    let response:StatusResponse = { id: action.id, actionId: action.actionId, status: result.payload.status };
                    onSuccess(response);
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            } else if(action.actionId == "startProjectProcess" || action.actionId == "pauseProjectProcess" || action.actionId == "finishProjectProcess") {
                const result = await dispatch(updateProjectProcessStatus({ 
                    id: id,
                    args: authorize ? { password, status: action.status ?? "" } : { password:'', status: action.status ?? "" } 
                }))

                if (updateProjectProcessStatus.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    let response:StatusResponse = { id: action.id, actionId: action.actionId, status: result.payload.status };
                    onSuccess(response)
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }
        }
    }

    let title = <></>
    let content = <></>
    switch (action?.actionId) {
        case "deleteProject":
            title = <Localized id="project-delete-question">
                <span>Czy na pewno chcesz usunąć projekt?</span>
            </Localized>
            break;
        case "startProject":
            title = <Localized id="project-start-question">
                <span>Czy na pewno chcesz rozpocząć projekt?</span>
            </Localized>
            break;
        case "pauseProject":
            title = <Localized id="project-pause-question">
                <span>Czy na pewno chcesz wstrzymać projekt?</span>
            </Localized>
            break;
        case "cancelProject":
            title = <Localized id="project-cancel-question">
                <span>Czy na pewno chcesz anulować projekt?</span>
            </Localized>
            break;
        case "finishProject":
            title = <Localized id="project-finish-question">
                <span>Czy na pewno chcesz zakończyć projekt?</span>
            </Localized>
            break;
        case "deleteProjectProcess":
            title = <>{ l10n.getString("project-process-delete-question", { processName: action?.name }, "Czy na pewno chcesz usunąć proces?") }</>
            break;
        case "startProjectProcess":
            title = <>{ l10n.getString("project-process-start-question", { processName: action?.name }, "Czy na pewno chcesz rozpocząć proces?") }</>
            break;
        case "pauseProjectProcess":
            title = <>{ l10n.getString("project-process-pause-question", { processName: action?.name }, "Czy na pewno chcesz wstrzymać proces?") }</>
            break;
        case "finishProjectProcess":
            title = <>{ l10n.getString("project-process-finish-question", { processName: action?.name }, "Czy na pewno chcesz zakończyć proces?") }</>
            break;
        default:
            title = <></>
            break;
    }

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])

    return <Dialog open={true} maxWidth="xs" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{content}</DialogContentText>
            <Typography>{action?.description}</Typography>
            {authorize && <TextField 
                sx={{
                    marginTop: 1
                }}
                fullWidth
                autoFocus
                type="password"
                value={password}
                onChange={handlePasswordFieldChange}
                label={<Localized id="password"><span>Hasło</span></Localized>}
                helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}
                autoComplete="off"
            />}
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={run}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

