import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useAppDispatch } from "../../../app/hooks"
import { AppId } from "../../../app/appTypes"
import { Localized } from "@fluent/react"
import { useState } from "react"
import { unwrapResult } from "@reduxjs/toolkit"
import { deleteProject, deleteTeamMember } from "../projectSlice"
import { LoadingButton } from "@mui/lab"
import { deletepProcess, deletepProcessTeamMember } from "../projectProcess/ProjectProcessApiClient"

export interface DeleteConfirmationDialogProps {
    objectId: AppId
    mode: "deleteprocess" | "deleteProjectTeamMember" | "deleteProcessTeamMember" | "deleteProject"

    onSave: (teamMemberId: AppId) => void
    onCancel: () => void,
}

export const DeleteConfirmationDialog = ({ objectId, mode, onSave, onCancel }: DeleteConfirmationDialogProps) => {
    const dispatch = useAppDispatch()
    const [savingInProgress, setSavingInProgress] = useState(false)


    const handleConfirmDeleteClick = () => {

        setSavingInProgress(true)
        switch (mode) {
            case "deleteProjectTeamMember":
                dispatch(deleteTeamMember(objectId))
                    .then(unwrapResult)
                    .then(() => onSave(objectId))
                    .finally(() => setSavingInProgress(false))
                break
            case "deleteProcessTeamMember":
                dispatch(deletepProcessTeamMember(objectId))
                    .then(unwrapResult)
                    .then(() => onSave(objectId))
                    .finally(() => setSavingInProgress(false))
                break

            // case "deleteprocess":
            //     dispatch(deletepProcess(objectId))
            //         .then(unwrapResult)
            //         .then(() => onSave(objectId))
            //         .finally(() => setSavingInProgress(false))
            //     break

            // case "deleteProject":
            //     dispatch(deleteProject(objectId))
            //         .then(unwrapResult)
            //         .then(() => onSave(objectId))
            //         .finally(() => setSavingInProgress(false))
            //     break
        }

    }

    return <>
        <Dialog open={true} fullWidth maxWidth='md'>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={savingInProgress} onClick={handleConfirmDeleteClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={savingInProgress} onClick={onCancel}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
