import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogTitle, DialogContent, Grid, TextField, MenuItem, Alert, DialogActions } from "@mui/material"
import { useState, useCallback } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch } from "../../../app/hooks"
import { If } from "../../../app/If"
import { DeviceTypeCombobox } from "../../device/DeviceTypeCombobox"
import { showSuccess } from "../../notifications/notificationsSlice"
import { UpdateTestAction } from "../types"
import { Validation, ValidationStage } from "../validationsSlice"

export interface UpdateTestDialogFormProps {
    data: Validation
    close: () => void
    udpate: UpdateTestAction
}

export const UpdateTestDialogForm = ({ data, udpate, close }: UpdateTestDialogFormProps) => {
    const dispatch = useAppDispatch()
    const { name: origName, description: origDescription, stage: origStage, structureId: origStructureId } = data
    const [name, setName] = useState(origName)
    const [description, setDescription] = useState(origDescription)
    const [stage, setStage] = useState<ValidationStage>(origStage)
    const [structureId, setStructureId] = useState<AppId>(origStructureId)

    const [error, setError] = useState<string | undefined>(undefined)
    const [saving, setSaving] = useState(false)

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [])
    const handleStageChange: AppTextFieldHandler = 
        useCallback(e => setStage(e.target.value as ValidationStage), [])

    const handleOKClick = async () => {
        if (name && description) {
            try {
                setSaving(true)
                await dispatch(udpate({...data, name, description, stage, structureId})).unwrap()
                close()
                dispatch(showSuccess("saved"))
            } catch (error) {
                setError("error")
                setSaving(false)
            }
        }
    }

    return <>
        <DialogTitle>
            <Localized id="validation-edit">
                Edycja testu globalnego
            </Localized>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        sx={{ marginTop: 2 }}
                        fullWidth
                        value={name}
                        inputProps={{ maxLength: 200 }}
                        onChange={handleNameChange}
                        label={<Localized id="validation-name">Nazwa</Localized>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        value={description}
                        inputProps={{ maxLength: 1000 }}
                        onChange={handleDescriptionChange}
                        multiline={true}
                        maxRows={16}
                        label={<Localized id="validation-description">Opis</Localized>}
                    />
                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        select
                        value={stage}
                        onChange={handleStageChange}
                        label={<Localized id="validation-stage">Etap</Localized>} >
                            {["IQ", "OQ", "DQ", "PQ"].map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)}
                    </TextField>
                </Grid>
                <Grid item xs={10}>
                    <DeviceTypeCombobox 
                        fullWidth
                        error={false}
                        onlyLeafs={false}
                        value={structureId ?? ""}
                        onChange={setStructureId}
                    />
                </Grid>
                <If condition={error !== undefined}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <Localized id="error">Błąd</Localized>
                        </Alert>
                    </Grid>
                </If>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOKClick}>
                <Localized id="ok">OK</Localized>
            </LoadingButton> 
            <LoadingButton loading={saving} onClick={close}>
                <Localized id="cancel">Anuluj</Localized>
            </LoadingButton> 
        </DialogActions>
    </>
}
