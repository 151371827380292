import { Localized } from "@fluent/react"
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Skeleton, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { useAppDispatch } from "../../../app/hooks"
import { LoadingButton } from "@mui/lab"
import { APIError, AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { unwrapResult } from "@reduxjs/toolkit"
import { inviteTeamMember, ProjectTeamMember } from "../projectSlice"
import { ServerErrorMsg } from "../../../app/ServerErrorMsg"

export interface InviteProjectTeamMemberDialogProps {
    projectId: AppId
    onSave: (tm: ProjectTeamMember) => void
    onCancel: () => void,
}

export const InviteProjectTeamMemberDialog = ({ projectId, onSave, onCancel }: InviteProjectTeamMemberDialogProps) => {
    const dispatch = useAppDispatch()
    const [userRole, setUserRole] = useState<string>("")
    const [error, setError] = useState<APIError | null>(null)
    const [userEmail, setUserEmail] = useState<string>("")

    const [savingInProgress, setSavingInProgress] = useState(false)

    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setUserRole(e.target.value), [setUserRole])
    const handleUserEmailChange: AppTextFieldHandler = useCallback(e => setUserEmail(e.target.value), [setUserEmail])


    const handleOkClick = () => {
        if (userEmail !== null) {
            setSavingInProgress(true)
            dispatch(inviteTeamMember({ userEmail: userEmail, projectId, role: userRole }))
                .then(unwrapResult)
                .then((tm) => onSave(tm))
                .catch((err) => setError(err))
                .finally(() => setSavingInProgress(false))
        }
    }

    return <>
        <Dialog open={true} fullWidth maxWidth='md'>
            <DialogTitle>
                <Localized id="invite">
                    <span>Zaproś</span>
                </Localized>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
            }}>
                <TextField
                    sx={{
                        marginTop: 1,
                    }}
                    value={userEmail}
                    fullWidth
                    required={true}
                    label={<Localized id="email-address"><span>Email</span></Localized>}
                    onChange={handleUserEmailChange}
                >
                </TextField>
                <TextField
                    sx={{
                        marginTop: 1,
                    }}
                    value={userRole}
                    fullWidth
                    required={true}
                    select
                    label={<Localized id="urs-user-role"><span>Rola</span></Localized>}
                    onChange={handleUserRoleChange}
                >

                    <MenuItem value={"C"}><Localized id="project-role-collaborator"><span>Collaborator</span></Localized></MenuItem>
                    <MenuItem value={"O"}><Localized id="project-role-observer"><span>Observer</span></Localized></MenuItem>
                </TextField>
                <ServerErrorMsg err={error} />
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={savingInProgress} onClick={handleOkClick}>
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton loading={savingInProgress} onClick={onCancel}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
