import { createAsyncThunk } from "@reduxjs/toolkit"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { deleteWithAuth, getWithAuth, postWithAuth } from "../../../http"

export interface Attendee {
    id: number
    email: string
    person: string
    company: string
    meetingId: number
}

export interface Meeting {
    id: number
    name: string
    startDate: string | null
    endDate: string | null
    place: string
    onDemand: string
    meetingMinuteId: string,
    plannedMeetingTime: number,
    createdBy: string,
    attendanceList: Attendee[]
}

export interface CreateMeetingPayload {
    meetingMinuteId: string
    meeting: Meeting
}

export const createMeeting = createAsyncThunk<AppId, CreateMeetingPayload, AppThunkAPIType>(
    'meeting/create',
    async (meeting, { dispatch, rejectWithValue }) => {
        const response = await dispatch(postWithAuth({
            url: `api/meeting-minutes/start-meeting/${meeting.meetingMinuteId}`,
            payload: meeting
        }))
        const { payload } = response
        if (postWithAuth.fulfilled.match(response)) {
            return payload?.id ?? ""
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const getLastMeeting = createAsyncThunk<Meeting, string, AppThunkAPIType>(
    'meeting/getLastMeeting',
    async (meetingMinuteId, { dispatch, rejectWithValue }) => {
        const response = await dispatch(getWithAuth({
        url: `api/meeting-minutes/last-meeting/${meetingMinuteId}`
                }))
        const { payload } = response
        if (getWithAuth.fulfilled.match(response)) {
            return payload as Meeting
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const addAttendeeToMeeting = createAsyncThunk<Attendee, Attendee, AppThunkAPIType>(
'meeting/addAttendeeToMeeting',
async (attendee, { dispatch, rejectWithValue }) => {
    const response = await dispatch(postWithAuth({
        url: `api/meeting-minutes/meeting/add-attendance`,
        payload: attendee
    }))
    const { payload } = response
    if (postWithAuth.fulfilled.match(response)) {
        return payload as Attendee
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteAttendeeFromMeeting = createAsyncThunk<number, number, AppThunkAPIType>(
'meeting/deleteAttendeeFromMeeting',
async (id, { dispatch, rejectWithValue }) => {
    const response = await dispatch(deleteWithAuth({
        url: `api/meeting-minutes/meeting/delete-attendance/${id}`,
    }))
    const { payload } = response
    if (deleteWithAuth.fulfilled.match(response)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
}})
