import { Localized } from "@fluent/react";
import { RemarkStatus } from "./processStageRemarkSlice";

export const RemarkStatusLabel = ({ status }: { status: RemarkStatus }) => {
    switch (status) {
        case "N":
            return <Localized id="remark-status-n">
                <span>Nowa</span>
            </Localized>
        case "R":
            return <Localized id="remark-status-r">
                <span>Ponowanie zgłoszona</span>
            </Localized>
        case "C":
            return <Localized id="remark-status-c">
                <span>Zamknięta</span>
            </Localized>        
        default:
            return <span>{status}</span>
    }
}