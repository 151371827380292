import { Localized } from "@fluent/react"
import { Button, ButtonGroup, Container, Grid, Paper, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector, useQueryBusinessCaseId } from "../../app/hooks"
import SaveIcon from '@mui/icons-material/Save'
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { ProjectHeader, createProject } from "./projectSlice"
import { selectBusinessCaseById } from "../businessCase/businessCaseSlice"
import { If } from "../../app/If"
import { useNavigate } from "react-router-dom"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>


export const CreateProject = () => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()

    const [saving, setSaving] = useState(false)
    const [projectNumber, setProjectNumber] = useState("")
    const [projectName, setProjectName] = useState("")
    const [generalContractor, setGeneralContractor] = useState("")
    const [investor, setInvestor] = useState("")
    const businessCaseId = useQueryBusinessCaseId();
    const businessCase = useAppSelector(state => businessCaseId && !isNaN(Number(businessCaseId)) ? selectBusinessCaseById(state, businessCaseId) : null);

    const [validationErrors, setValidationErrors] = useState(false)

    const projectNumberError = validationErrors && projectNumber === ""
    const projectNameError = validationErrors && projectName === ""

    const handleNumberChange: AppTextFieldHandler = useCallback(e => {
        setSaving(false);
        setProjectNumber(e.target.value)
    }, [])

    const handleNameChange: AppTextFieldHandler = useCallback(e => {
        setSaving(false);
        setProjectName(e.target.value)
    }, [])

    const handleGeneralContractorChange: AppTextFieldHandler = useCallback(e => {
        setGeneralContractor(e.target.value)
    }, [])

    const handleInvestorChange: AppTextFieldHandler = useCallback(e => {
        setInvestor(e.target.value)
    }, [])

    const handleSaveButtonClick = async () => {
        setValidationErrors(true)
        setSaving(true)

        if (projectNumber && projectName) {
            try {
                const result =
                    await dispatch(createProject({
                        no: projectNumber,
                        name: projectName,
                        generalContractor: generalContractor,
                        investor: investor,
                        businessCaseId: businessCaseId ?? null
                    }))

                if (createProject.fulfilled.match(result)) {
                    const newUrs = result.payload as ProjectHeader;
                    dispatch(showSuccess("saved"))
                    navigate(`/projects/edit/${newUrs.id}`, { replace: true })
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <Container sx={{
            paddingTop: 2,
            paddingBottom: 2,
        }} component={Paper} maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        helperText={projectNumberError && <RequiredError />}
                        required
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={projectNumber}
                        error={projectNumberError}
                        inputProps={{ maxLength: 100 }}
                        onChange={handleNumberChange}
                        label={<Localized id="project-no"><span>Numer</span></Localized>}
                    />
                    <TextField
                        helperText={projectNameError && <RequiredError />}
                        required
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={projectName}
                        error={projectNameError}
                        inputProps={{ maxLength: 500 }}
                        onChange={handleNameChange}
                        label={<Localized id="project-title"><span>Tytuł</span></Localized>}
                    />

                    <TextField
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={generalContractor}
                        inputProps={{ maxLength: 500 }}
                        onChange={handleGeneralContractorChange}
                        label={<Localized id="project-general-contractor"><span>General Contractor</span></Localized>}
                    />

                    <TextField
                        sx={{ marginTop: "10px" }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={investor}
                        inputProps={{ maxLength: 500 }}
                        onChange={handleInvestorChange}
                        label={<Localized id="project-investor"><span>Investor</span></Localized>}
                    />

                    <If condition={businessCase !== null && businessCase !== undefined}>
                        <TextField
                            sx={{ marginTop: "10px" }}
                            fullWidth
                            aria-readonly
                            value={businessCase?.title}
                            label={<Localized id=""><span>Business case</span></Localized>}
                        />
                    </If>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="secondary">
                        <Button onClick={handleSaveButtonClick} disabled={saving} startIcon={<SaveIcon />}>
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                        <Button onClick={handleBackButtonClick} variant="outlined">
                            <Localized id="cancel">Anuluj</Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </Container>
    )
}
