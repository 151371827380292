import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { getWithAuth, postWithAuth, putWithAuth } from "../../http";

export interface SolairEntryLocationMapping {
    id: number;
    name: string;
}
  
export interface MeasurementHeader {
    id: AppId,
    name: string,
    notes: string,
    location: string,
    performedBy: string,
    performedByUserId: string,
    measuringDevice: string,
    guid: string,
    companyId: number,
    clientCompany: string,
    clientCompanyId: string,
    companyName: string,
    date: string,
    status: string,
    createdBy: string,
    createdByFullName: string,
    updatedBy: string,
    updatedByFullName: string,
}

export type Measurement = MeasurementHeader & {
    solairEntries: MeasurementSolairEntry[]
}

export interface MeasurementSolairEntry {
    id: AppId,
    timestamp: string,
    locationId: number,
    locationName: string,
    sampleTime: number,
    volume: number,
    count: number,
    alarm: string,
    flow: string,
    laser: string,
    service: string,
}

export interface AddEditMeasurementPayload {
    name: string,
    location: string,
    performedBy: string,
    measuringDevice: string,
    clientCompany: string,
    date: string
}

export const loadAllMeasurements = createAsyncThunk<MeasurementHeader[], void, AppThunkAPIType>(
    "/measurements/load-all",
    async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: `api/measurements` }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MeasurementHeader[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadMeasurement = createAsyncThunk<Measurement, AppId, AppThunkAPIType>(
    "/measurements/load-measurement",
    async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/measurements/${id}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Measurement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const createMeasurement = createAsyncThunk<Measurement, AddEditMeasurementPayload, AppThunkAPIType>(
    "measurements/create",
    async (createPayload: AddEditMeasurementPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/measurements",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Measurement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateMeasurementHeader = createAsyncThunk<Measurement, {id: AppId, header: AddEditMeasurementPayload}, AppThunkAPIType>(
    "measurements/update",
    async ({id, header}, { rejectWithValue, dispatch }) => {
        const result = await dispatch(putWithAuth({
            url:`api/measurements/${id}`,
            payload: header,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return payload as Measurement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSolairLocationMapping = createAsyncThunk<Measurement, {id: AppId, mapping: SolairEntryLocationMapping[]}, AppThunkAPIType>(
    "measurements/updatelocationmapping",
    async ({id, mapping}, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: `api/measurements/${id}/solair/update-mapping`,
            payload: mapping,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Measurement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)