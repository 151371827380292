import { Localized, useLocalization } from "@fluent/react"
import { Button, Paper } from "@mui/material"
import { useLocation } from "react-router-dom"
import { APIError } from "./appTypes"

const ErrorStatus = (props: { status: number }) => {
    const location = useLocation();
    const { l10n } = useLocalization();

    const redirectToSupportPage = () => {
        window.open(`${process.env.REACT_APP_SUPPORT}/UserBugs/CreateBug?t=${l10n.getString("error-unexpected-problem", null, "Wystąpił nieoczekiwany problem")}&d=${l10n.getString("error-url", null, "URL")}: ${window.location.origin}${location.pathname}${location.search}${location.hash} Status: ${props.status}`, "_blank")
    }

    switch(props.status) {
        case 403:
            return <><p><Localized id="error-access-denied">Brak dostępu</Localized></p><p><Localized id="error-solve-problem">Jeżeli nie wiesz jak rozwiązać powyższy problem - prosimy o kontakt ze wsparciem</Localized><br/><Button onClick={redirectToSupportPage}><Localized id="contact-support">Kontakt ze wsparciem</Localized></Button></p></>
        case 500:
            return <p><Localized id="error-unknown">Wystąpił nieoczekiwany problem, odśwież aplikacje. Jeżeli błąd się powtarza zgłoś go nam</Localized><br/><Button onClick={redirectToSupportPage}><Localized id="contact-support">Kontakt ze wsparciem</Localized></Button></p>;
        default:
            return null;
    }
}

const ErrorDetails = (props: { error: APIError }) => {
    const location = useLocation();
    const { l10n } = useLocalization();

    const redirectToSupportPage = () => {
        window.open(`${process.env.REACT_APP_SUPPORT}/UserBugs/CreateBug?t=${l10n.getString("error-unexpected-problem", null, "Wystąpił nieoczekiwany problem")}&d=${l10n.getString("error-url", null, "URL")}: ${window.location.origin}${location.pathname}${location.search}${location.hash}`, "_blank")
    }

    if(props.error === null || props.error.kind === "unknown") return <p><Localized id="error-unknown">Wystąpił nieoczekiwany problem, odśwież aplikacje. Jeżeli błąd się powtarza zgłoś go nam</Localized><br/><Button onClick={redirectToSupportPage}><Localized id="contact-support">Kontakt ze wsparciem</Localized></Button></p>

    switch (props.error.kind) {
        case 'http':
            if (props.error.problem) {
                return <><p><Localized id={props.error.problem.title}>{props.error.problem.detail}</Localized></p><p><Localized id="error-solve-problem">Jeżeli nie wiesz jak rozwiązać powyższy problem - prosimy o kontakt ze wsparciem</Localized><br/><Button onClick={redirectToSupportPage}><Localized id="contact-support">Kontakt ze wsparciem</Localized></Button></p></> // tutaj ten komunikat
            } else {
                return <ErrorStatus status={props.error.status}/>
            }
        case 'connection':
            return <p><Localized id="error-please-refresh">Sprawdź swoje połączenie z internetem i spróbuj odświeżyć aplikacje</Localized></p>
        default:
            return <p><Localized id="error-unknown">Wystąpił nieoczekiwany problem, odśwież aplikacje. Jeżeli błąd się powtarza zgłoś go nam</Localized><br/><Button onClick={redirectToSupportPage}><Localized id="contact-support">Kontakt ze wsparciem</Localized></Button></p>
    }
}

export const ErrorContainer = ({ error }) => {

    return <Paper sx={{
        backgroundColor: '#f8d7da',
        color: '#721c24',
        border: '1px solid #f5c6cb',
        borderRadius: '8px',
        textAlign: 'center' }}>
    <h4>
        <Localized id="error-content">Coś poszło nie tak... Wystąpił błąd podczas ładowania danych</Localized>
    </h4>
    <ErrorDetails error={error}/>
</Paper>
}
