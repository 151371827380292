import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { AppThunkAPIType } from "../../app/appTypes"
import { RootState } from "../../app/store"

export type Locale = "en-US" | "pl"

export interface SettingsState {
    locale: Locale
    leftMenuOpen: boolean
}

export const setLocalePersistent = createAsyncThunk<Locale, Locale, AppThunkAPIType>(
    "settings/setLocalePersistent", 
    async (locale) => {
        localStorage.setItem("Settings_Locale", locale)
        return locale
})

/*export const setLeftMenuOpenPersistent = createAsyncThunk<boolean, boolean, AppThunkAPIType>(
    "settings/setLeftMenuOpenPersistent", 
    async (open) => {
        localStorage.setItem("Settings_LeftMenuOpen", JSON.stringify(open))
        return open
})*/

export const settingsSlice = createSlice({
    name: 'settings',
    initialState: () => {
        return {
            locale: (localStorage.getItem("Settings_Locale") ?? "pl") as Locale,
            leftMenuOpen: false,
        }
    },
    reducers: {
        setLocale: (state, action) => {
            state.locale = action.payload
        },
        setLeftMenuOpen: (state, action) => {
            state.leftMenuOpen = action.payload
        },
    },
    extraReducers: builder => {
        builder.addCase(setLocalePersistent.fulfilled, (state, action) => {
            state.locale = action.payload
        })
        //builder.addCase(setLeftMenuOpenPersistent.fulfilled, (state, action) => {
            //state.leftMenuOpen = action.payload
        //})
    }
})

export const { setLocale, setLeftMenuOpen } = settingsSlice.actions

export const selectSettings = (state: RootState) => state.settings

export default settingsSlice.reducer