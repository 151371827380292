import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container'
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Alert from '@mui/material/Alert'
import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { Localized } from "@fluent/react"
import SaveIcon from '@mui/icons-material/Save'
import { updateDeviceType, selectDeviceTypeById, DeviceType } from "./deviceTypesSlice"
import { Link, useParams } from "react-router-dom"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadValidations, selectAllValidations, selectValidationById, selectValidations, updateValidation } from "../validations/validationsSlice"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TablePagination, Typography } from "@mui/material"
import { ValidationsBrowser } from "../validations/ValidationsBrowser"
import { AppLoader } from "../../app/AppLoader"
import { unwrapResult } from "@reduxjs/toolkit"
import { Autocomplete, Skeleton } from '@mui/material';
import { store } from "../../app/store"
import produce from "immer"
import { LoadAreas, LoadDeviceTypes } from "../../app/AppDataLoader";
import { selectAllAreas, selectAreaById } from "../areas/areaSlice";

const PREFIX = 'EditDeviceType';

const classes = {
    root: `${PREFIX}-root`,
    box: `${PREFIX}-box`,
    field: `${PREFIX}-field`,
    nameField: `${PREFIX}-nameField`,
    codeField: `${PREFIX}-codeField`,
    buttons: `${PREFIX}-buttons`,
    flex: `${PREFIX}-flex`,
    pushRight: `${PREFIX}-pushRight`,
    gridButtons: `${PREFIX}-gridButtons`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(( { theme }) => ({
    [`& .${classes.root}`]: {
        paddingTop: theme.spacing(2),
    },

    [`& .${classes.box}`]: {
        paddingBottom: theme.spacing(2),
    },

    [`& .${classes.field}`]: {
        width: "100%",
    },

    [`& .${classes.nameField}`]: {
        width: "50%",
    },

    [`& .${classes.codeField}`]: {
        width: "25%",
    },

    [`& .${classes.buttons}`]: {
        marginTop: theme.spacing(4),
    },

    [`& .${classes.flex}`]: {
        display: "flex" ,
        alignItems: "center",
    },

    [`& .${classes.pushRight}`]: {
        marginLeft: "auto",
    },

    [`& .${classes.gridButtons}`]: {
        marginLeft: theme.spacing(1),
    }
}));

const ValidationsLoader = ({ children }: { children: ReactNode }) => {
    const dispatch = useAppDispatch()
    const { state: validationsState } = useAppSelector(selectValidations)

    useEffect(() => {
        if (validationsState.type === "empty") {
            dispatch(loadValidations())
        }
    }, [dispatch, validationsState])

    if (validationsState.type !== "loaded") {
        return <Skeleton variant="rectangular" animation="wave" height="25vh" />;
    } else {
        return <Root>{children}</Root>;
    }
}

const Validations = ({ deviceTypeId }: { deviceTypeId: AppId }) => {
    const dispatch = useAppDispatch()
    const allValidations = useAppSelector(selectAllValidations)
    const validationsMap = useAppSelector(state => {
        const m = new Set<AppId>()
        for (const { id: validationId, structureId } of selectAllValidations(state)) {
            if (structureId === deviceTypeId ) {
                m.add(validationId)
            }
        }
        return m
    })
    const [tab, setTab] = useState<0 | 1 | 2 | 3>(0)
    const [selection, setSelection] = useState<Set<AppId>>(new Set())
    const [showDialog, setShowDialog] = useState(false)
    const [copying, setCopying] = useState(false)
    const [page, setPage] = useState(0)

    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [])
    const dqTests = useMemo(() => {
        return allValidations.filter(v => v.stage === "DQ" && validationsMap.has(v.id))
    }, [allValidations, validationsMap])
    const iqTests = useMemo(() => {
        return allValidations.filter(v => v.stage === "IQ" && validationsMap.has(v.id))
    }, [allValidations, validationsMap])
    const oqTests = useMemo(() => {
        return allValidations.filter(v => v.stage === "OQ" && validationsMap.has(v.id))
    }, [allValidations, validationsMap])
    const pqTests = useMemo(() => {
        return allValidations.filter(v => v.stage === "PQ" && validationsMap.has(v.id))
    }, [allValidations, validationsMap])

    const [currentCount, setCurrentCount] = useState(dqTests.length)

    const tests = useMemo(() => {
        switch (tab) {
            case 0:
                return dqTests
            case 1:
                return iqTests
            case 2:
                return oqTests
            case 3:
                return pqTests
        }
    }, [tab, dqTests, iqTests, oqTests, pqTests])
    
    const handleTabChange = useCallback((_: ChangeEvent<{}>, i: 0 | 1 | 2 | 3 ) => {
        setTab(i)
        setPage(0)
        switch (i) {
            case 0:
                setCurrentCount(dqTests.length)
                break
            case 1:
                setCurrentCount(iqTests.length)
                break
            case 2:
                setCurrentCount(oqTests.length)
                break
            case 3:
                setCurrentCount(pqTests.length)
                break
        }
    }, [dqTests, iqTests, pqTests, oqTests, setTab])
    const handleAddValidationFromListClick = useCallback(() => {
        setSelection(
            produce(draft => {
                draft.clear()
            })
        )
        setShowDialog(true)
    }, [ setShowDialog ])
    const handleCancelDialogClick = useCallback(() => {
        setShowDialog(false)
    }, [ setShowDialog ])
    const handleSelectBrowserItem = useCallback((validationId: AppId) => {
        setSelection(
            produce(draft => {
                draft.add(validationId)
            })
        )
    }, [])
    const handleDeselectBrowserItem = useCallback((validationId: AppId) => {
        setSelection(
            produce(draft => {
                draft.delete(validationId)
            })
        )
    }, [])
    const handleCopyValidationsClick = async () => {
        if (selection.size === 0) return

        setCopying(true)
        for (const validationId of selection) {
            const entity = selectValidationById(store.getState(), validationId)
            if (entity) {
                try {
                    unwrapResult(
                        await dispatch(updateValidation(
                            produce(entity, draft => {
                                draft.structureId = deviceTypeId
                            })
                        ))
                    )
                } catch (error) {
                    dispatch(showError("error"))
                }
            }
        }
        setCopying(false)
        setShowDialog(false)
    }

    return <>
        <Tabs   value={tab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="secondary"
                centered >
            <Tab label="DQ"></Tab>
            <Tab label="IQ"></Tab>
            <Tab label="OQ"></Tab>
            <Tab label="PQ"></Tab>
        </Tabs>
        <TableContainer component={Paper} className={classes.box}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography variant="subtitle2" color="textSecondary">
                                <Localized id="device-type-test-name">
                                    <span>Nazwa testu</span>
                                </Localized>
                            </Typography>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tests.slice(page * 5, page * 5 + 5).map(({ id, name }) => 
                        <TableRow key={id}>
                            <TableCell>
                                <span>{name}</span>
                            </TableCell>
                        </TableRow> 
                    )}

                </TableBody>
            </Table>
            <div className={classes.flex}>
                <ButtonGroup className={classes.gridButtons} color="secondary" size="small" variant="outlined">
                    <Button 
                        component={Link} 
                        state={{ deviceTypeId }}
                        to={{
                            pathname: "/validation/new",
                        }}>
                        <Localized id="validation-new">
                            <span>Nowy test globalny</span>
                        </Localized>
                    </Button>
                    <Button onClick={handleAddValidationFromListClick}>
                        <Localized id="validation-add-from-list">
                            <span>Dodaj test z listy</span>
                        </Localized>
                    </Button>
                </ButtonGroup>
                <TablePagination 
                    className={classes.pushRight}
                    rowsPerPageOptions={[5]}
                    component="div"
                    count={currentCount}
                    rowsPerPage={5}
                    page={page}
                    onPageChange={handleChangePage}
                />
            </div>
        </TableContainer>
        <Dialog open={showDialog} maxWidth="lg" fullWidth={true} >
            <DialogTitle>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom >
                    <Localized id="menu-validations">
                        <span>Katalog testów</span>
                    </Localized>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <ValidationsBrowser 
                    selection={selection} 
                    excluded={validationsMap} 
                    itemChecked={handleSelectBrowserItem} 
                    itemUnchecked={handleDeselectBrowserItem}
                    stage={undefined} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCopyValidationsClick} disabled={copying} color="secondary" variant="contained">
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </Button>
                <Button disabled={copying} onClick={handleCancelDialogClick}>
                    <Localized id="cancel"><span>Anuluj</span></Localized>
                </Button>
            </DialogActions>
        </Dialog>
    </>
}

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

const Skel = () => <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>

const Form = ({ id }: { id: AppId }) => {
    const dispatch = useAppDispatch()
    const entity = useAppSelector(state => selectDeviceTypeById(state, id)) as DeviceType
    const allAreas = useAppSelector(selectAllAreas)
    const initialArea = useAppSelector(state => selectAreaById(state, entity.areaId ?? ""))

    const [code, setCode] = useState(entity.code)
    const [name, setName] = useState(entity.name)
    const [description, setDescription] = useState(entity.description)
    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [area, setArea] = useState(initialArea ?? null)

    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [setCode])
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [setDescription])
    const handleAreaChange = useCallback((_, newArea) => setArea(newArea), [])

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (code && name) {
            setSaving(true)
            try {
                unwrapResult(await dispatch(updateDeviceType({ 
                    id, 
                    code, 
                    name,
                    description,
                    areaId: area?.id ?? null,
                })))
                dispatch(showSuccess("saved"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    const codeError = validationErrors && code === ""
    const nameError = validationErrors && name === ""

    return <> 
        <Container component={Paper} maxWidth="lg" className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField 
                        fullWidth
                        error={codeError}
                        helperText={codeError && <RequiredError />}
                        className={classes.codeField}
                        value={code}
                        onChange={handleCodeChange}
                        inputProps={{ maxLength: 20 }}    
                        label={
                            <Localized id="device-type-code">
                                <span>Kod typu urządzenia</span>
                            </Localized>
                        }
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField 
                        fullWidth
                        error={nameError}
                        helperText={nameError && <RequiredError />}
                        className={classes.nameField}
                        value={name}
                        inputProps={{ maxLength: 200 }}    
                        onChange={handleNameChange}
                        label={
                            <Localized id="device-type-name">
                                <span>Nazwa typu urządzenia</span>
                            </Localized>
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <Autocomplete
                        disablePortal
                        options={allAreas}
                        fullWidth
                        value={area}
                        onChange={handleAreaChange}
                        getOptionLabel={item => item.name}
                        renderInput={params => <TextField {...params} label={<Localized id="area-name">Nazwa obszaru</Localized>} />}
                    />
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        multiline
                        className={classes.field}
                        value={description}
                        inputProps={{ maxLength: 1000 }}    
                        onChange={handleDescriptionChange}
                        label={
                            <Localized id="device-type-description">
                                <span>Opis</span>
                            </Localized>
                        }
                    />
                </Grid>
            </Grid>
            <ValidationsLoader>
                <Validations deviceTypeId={id} />
            </ValidationsLoader>
                <Button sx={{
                    marginTop: 2,
                    marginBottom: 2,
                }}  color="secondary" variant="contained" startIcon={<SaveIcon />} 
                        onClick={handleSaveClick}>
                    <Localized id="save">Zapisz</Localized>
                </Button>
                <Button  component={Link} to={`/devicetypes`} color="secondary" variant="outlined">
                    <Localized id="back">Wróć</Localized>
                </Button>
        </Container>
        <AppLoader open={saving} />
    </>
}

export const EditDeviceType = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const entity = useAppSelector(state => selectDeviceTypeById(state, id))

    if (!entity) return <Alert severity="warning">
        <Localized id="error-entity-not-exists">
            <div>Obiekt nie istnieje</div>
        </Localized>
    </Alert>

    return <LoadDeviceTypes component={<Skel />}>
        <LoadAreas component={<Skel />}>
            <Form id={id} />
        </LoadAreas>
    </LoadDeviceTypes>
}
