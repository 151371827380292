import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Box, Button, ButtonGroup, Dialog, DialogContent, DialogTitle, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Skeleton, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import { DragEvent, useCallback, useRef, useState } from "react"
import { If } from "../../../app/If"
import { AppId } from "../../../app/appTypes"
import { Measurement } from "../measurementsApi"

export interface ImportSolairDataDialogProps {
    measureId: AppId
    onSucces: (measurement: Measurement) => void
    onCancel: () => void
}


export const ImportSolairDataDialog = (props: ImportSolairDataDialogProps) => {

    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)
    const dropboxRef = useRef<HTMLDivElement | null>(null)
    const [uploading, setUploading] = useState(false)
    const [selectedFiles, setSelectedFiles] = useState<FileList | never[] | null>(null)
    const [metadata, setMetadata] = useState<{ key: string; value: string }[]>([]);

    const anyFiles: boolean = selectedFiles !== null && selectedFiles.length > 0 ? true : false

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])

    const handleSelectedFilesChange = useCallback(() => {
        if (fileInputRef) {
            setSelectedFiles(fileInputRef.current?.files ?? null)
        }
    }, [fileInputRef, setSelectedFiles])


    const handleUploadClick = async () => {
        if (selectedFiles && selectedFiles.length > 0) {
            setUploading(true)
            let files: File[] = []
            for (const file of selectedFiles) {
                files.push(file)
            }
            try {

                const fd = new FormData()
                files.forEach(file => {
                    fd.append("file", file)
                    fd.append('metadata', JSON.stringify(metadata));
                })

                try {
                    const response = await fetch(`${process.env.REACT_APP_REST_API}api/measurements/${props.measureId}/solair/import`, {
                        method: 'POST',
                        mode: 'cors',
                        body: fd,
                    })
                    if (response.ok) {
                        const result =  await response.json()
                        props.onSucces(result as Measurement)


                    }
                } catch (error) {
                    console.log(error)
                }

                if (formRef) {
                    formRef.current?.reset()
                }
                setSelectedFiles(null)
            } catch (error) {
                console.log(error)
            } finally {
                setUploading(false)
            }
        }
    }

    const handleDragEnter = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDragOver = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
    }, [])
    const handleDrop = useCallback((e: DragEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()
        const dt = e.dataTransfer
        if (dt) {
            setSelectedFiles(dt.files)
        }
    }, [])


    return <>
        <Dialog open={true} fullWidth maxWidth='sm'>
            <DialogTitle>
                <Localized id=''>Import solair 3100</Localized>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Box>
                        <form ref={formRef}>
                            <input
                                ref={fileInputRef}
                                style={{ display: 'none' }}
                                type="file"
                                onChange={handleSelectedFilesChange}
                                accept="*,.csv,"
                            />
                        </form>
                        <Button size="small" variant="contained" onClick={handleSelectFilesClick}>
                            <Localized id="select-file">Wybierz plik</Localized>
                        </Button>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        minHeight: 60,
                        border: 'dashed grey',
                        marginTop: 2,
                        padding: 1,
                    }} ref={dropboxRef} component="div" onDragEnter={handleDragEnter} onDragOver={handleDragOver} onDrop={handleDrop}>
                        <Typography variant="body1" color="GrayText" gutterBottom>
                            <Localized id="drop-files">or drop here...</Localized>
                        </Typography>
                        <If condition={anyFiles}>
                            <FileList files={selectedFiles} />
                        </If>
                    </Box>
                        <ButtonGroup>
                            <LoadingButton loading={uploading} disabled={!anyFiles} size="small" variant="contained" onClick={handleUploadClick}>
                                <Localized id="import">Import</Localized>
                            </LoadingButton>
                            <LoadingButton loading={uploading} size="small" variant="outlined" onClick={props.onCancel}>
                                <Localized id="cancel">Anuluj</Localized>
                            </LoadingButton>
                        </ButtonGroup>
                </Stack>
            </DialogContent>

        </Dialog>
    </>
}

const FileList = ({ files }: { files: FileList | never[] | null }) => {
    if (files === null) {
        return <span>No files</span>
    }

    let idx: number[] = []
    for (let i = 0; i < files.length; i++) {
        idx.push(i)
    }

    return <List>{idx.map(j => {
        const file = files[j]
        const url = URL.createObjectURL(file)
        return <ListItem>
            <ListItemAvatar>
                <img
                    style={{ width: '90px' }}
                    src={url}
                    onLoad={() => URL.revokeObjectURL(url)}
                />
            </ListItemAvatar>
            <ListItemText primary={file.name} secondary={`size: ${file.size}B`}></ListItemText>
        </ListItem>
    })}</List>
}

