import { Localized } from "@fluent/react";
import { Alert } from "@mui/material";
import { APIError } from "./appTypes";

export const ServerErrorMsg = ({ err }: { err: APIError | null }) => {
    if (err === null) return null

    switch (err.kind) {
        case 'http':
            if (err.problem) {
                return <Alert severity="error">
                    <Localized id={err.problem.title}>{err.problem.detail}</Localized>
                </Alert>
            } else {
            return <Alert severity="error">{`HTTP error ${err.status}`}</Alert>
            }
        case 'connection':
            return <Alert severity="error">Network error!</Alert>
        case 'unknown':
            return <Alert severity="error">Uknown error!</Alert>
    }
}
