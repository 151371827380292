import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { useEffect } from 'react'
import { loadDevices, selectAllDevices, selectDevices } from './deviceSlice'
import { ButtonGroup } from '@mui/material'
import { loadDeviceTypes, selectDeviceTypeById, selectDeviceTypes } from './deviceTypesSlice'
import { Skeleton } from '@mui/material';
import { store } from '../../app/store'
import { FlexDiv } from '../../app/Utils'
import { selectModule } from "../modules/moduleSlice";
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef} from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectCompanyById } from '../company/companiesSlice'

export const Devices = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const { state: devicesState } = useAppSelector(selectDevices)
    const { state: deviceTypesState } = useAppSelector(selectDeviceTypes)
    const currentModule =
        useAppSelector(state => selectModule(state).currentModule)

    const allDevices = useAppSelector(selectAllDevices)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (devicesState.type === "empty" && currentModule !== null) {
            dispatch(loadDevices(currentModule.sysModuleId))
        }
        if (deviceTypesState.type === "empty") {
            dispatch(loadDeviceTypes())
        }

    }, [dispatch, devicesState, deviceTypesState, currentModule])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: l10n.getString("devices-name", null, "Nazwa"),
            flex: 1
        },
        {
            field: 'deviceTypeId',
            headerName: l10n.getString("devices-type", null, "Typ"),
            flex: 1,
            valueGetter: (_, row) => {
                const entity = selectDeviceTypeById(store.getState(), row.deviceTypeId)

                if (!entity) {
                    return ""
                } else {
                    return entity.name
                }
            }
        },
        {
            field: 'producerId',
            headerName: l10n.getString("devices-producer", null, "Producent"),
            width: 400,
            valueGetter: (_, row) => {
                const entity = selectCompanyById(store.getState(), row.producerId)

                if (!entity) {
                    return ""
                } else {
                    return entity.shortName
                }
            }
        },
        {
            field: 'model',
            headerName: l10n.getString("devices-model", null, "Model"),
            width: 200
        },
        {
            field: 'serialNo',
            headerName: l10n.getString("devices-serial-number", null, "Nr fabryczny"),
            minWidth: 200
        },
        {
            field: 'productionYear',
            headerName: l10n.getString("devices-production-year", null, "Rok produkcji"),
            width: 150
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup size="small">
                        <Button
                            style={{ width: 75 }}
                            component={Link}
                            to={`/devices/edit/${params.row.id}`}
                            color="secondary"
                            variant="outlined" >
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                        <Button component={Link}
                            state={{ deviceId: params.row.id }}
                            to={{
                                pathname: "/process/new",
                            }}
                            color="secondary"
                            variant="outlined">
                            <Localized id="devices-run-validation">
                                <span>Waliduj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    switch (devicesState.type) {
        case "empty":
        case "loading":
        case "error":
            return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
        case "loaded":
            return (
                <>
                    <TableContainer component={Paper}>
                        <DataGrid localeText={getCustomLocaleText()}
                            style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                            rows={allDevices}
                            columns={columns}
                            initialState={{
                                sorting: { sortModel: [{ field: 'name', sort: 'asc' }] }
                            }}
                        />
                    </TableContainer>
                    <FlexDiv>
                        <Fab component={Link} to="/devices/create" color="secondary" sx={{ marginTop: 2 }} >
                            <AddIcon />
                        </Fab>
                    </FlexDiv>
                </>
            );
    }
}
