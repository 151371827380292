
import { Button, ButtonGroup, Fab, Paper, TableContainer } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { useCallback, useEffect, useState } from "react";
import { LoadingContainer } from "../../app/LoadingContainer";
import { selectSettings } from "../settings/settingsSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Localized, useLocalization } from "@fluent/react";
import { useNavigate } from "react-router-dom";
import { loadAllMeasurements, Measurement, MeasurementHeader } from "./measurementsApi";
import { unwrapResult } from "@reduxjs/toolkit";
import { ErrorContainer } from "../../app/ErrorContainer";
import { FlexDiv } from "../../app/Utils";
import AddIcon from '@mui/icons-material/Add'
import { AddEditMeasurementDialog } from "./AddEditMeasurementDialog";

export const MeasurementsList = () => {
    const [measurements, setMeasurements] = useState<MeasurementHeader[]>([]);

    const { locale } = useAppSelector(selectSettings);
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [addDialogVisible, setAddDialogVisible] = useState<boolean>(false)


    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadAllMeasurements())
            .then(unwrapResult)
            .then(setMeasurements)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])

    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const handleGoToClick = (id) => {
        navigate(`/measurements/edit/${id}`)
    };

    const handleAddClick = useCallback(() => {
        setAddDialogVisible(true)
    }, [])

    const onSucces = (measurement: Measurement) => {
        setAddDialogVisible(false)
        handleGoToClick(measurement.id)
    };

    const onCancel = () => {

        setAddDialogVisible(false)
    };


    const columns: GridColDef[] =
        [
            {
                field: 'name',
                width: 200,
                headerName: l10n.getString('', null, 'Nazwa pomiaru'),

            },
            {
                field: 'location',
                headerName: l10n.getString('', null, 'Miejsce pomiaru'),
                flex: 1
            },
            {
                field: 'clientCompany',
                headerName: l10n.getString('', null, 'Klient'),
                width: 400
            },
            {
                field: 'date',
                type: 'date',
                headerName: l10n.getString('', null, 'Data pomiaru'),
                width: 150,
                valueGetter: (_, row) => new Date(row.date),
            },
            {
                field: 'actions', type: 'actions', width: 200,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            <Button onClick={() => handleGoToClick(params.id)} color="secondary" variant="outlined">
                                <Localized id="go-to">
                                    <span>Przejdź</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }


    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    return (
        <>
            <TableContainer component={Paper}>
                <DataGrid
                    localeText={getCustomLocaleText()}
                    rows={measurements}
                    columns={columns}
                    style={{ minHeight: 100, height: 'calc(100vh - 200px)' }}
                    onRowDoubleClick={({ row }) => handleGoToClick(row.id)}
                />
            </TableContainer>
            <FlexDiv>
                <Fab color="secondary" sx={{ marginTop: 2 }} onClick={handleAddClick}>
                    <AddIcon />
                </Fab>
            </FlexDiv>
            {
                addDialogVisible &&
                <AddEditMeasurementDialog mode={"ADD"}  measurement={null} onSucces={onSucces} onCancel={onCancel} />
            }
        </>
    )
}
