import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";

export interface SupplierDeviceDetail {
    id: AppId, 
    supplierDeviceId: AppId
    name: string, 
    description: string,
    position: string
    price: number
}

const adapter = createEntityAdapter<SupplierDeviceDetail>({
    selectId: (supplierDeviceDetail) => supplierDeviceDetail.id,
})

export type DraftSupplierDeviceDetail = Omit<SupplierDeviceDetail, "id">

export type SupplierDeviceDetailsState = EntityState<SupplierDeviceDetail> & { loaded: boolean }

const initialState: SupplierDeviceDetailsState = adapter.getInitialState({ loaded: false })


export const loadSupplierDeviceDetails = createAsyncThunk<SupplierDeviceDetail[], void, AppThunkAPIType>("supplierdevicedetail/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/SupplierDeviceDetail"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SupplierDeviceDetail[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createSupplierDeviceDetail = createAsyncThunk<SupplierDeviceDetail, DraftSupplierDeviceDetail, AppThunkAPIType>(
    "supplierdevicedetail/create", 
    async (detail: DraftSupplierDeviceDetail, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/SupplierDeviceDetail",
            payload: detail,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as SupplierDeviceDetail
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSupplierDeviceDetail = createAsyncThunk<SupplierDeviceDetail, SupplierDeviceDetail, AppThunkAPIType>(
    "supplierdevicedetail/update", 
    async (device: SupplierDeviceDetail, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/SupplierDeviceDetail/${device.id}`,
            payload: device,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return device
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteSupplierDeviceDetail = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "supplierdevicedetail/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/SupplierDeviceDetail/${id}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const supplierDeviceDetailsSlice = createSlice({
    name: "supplierdevicedetails",
    initialState,
    reducers: {
        clearSupplierDeviceDetailsSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createSupplierDeviceDetail.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteSupplierDeviceDetail.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateSupplierDeviceDetail.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<SupplierDeviceDetail, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(loadSupplierDeviceDetails.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    },
})

export const { clearSupplierDeviceDetailsSlice } = supplierDeviceDetailsSlice.actions

export const selectSupplierDeviceDetails = (state: RootState): SupplierDeviceDetailsState => state.supplierDeviceDetails

export const 
    { selectAll: selectAllSupplierDeviceDetail
    , selectById: selectSupplierDeviceDetailById 
    , selectTotal: selectTotalSupplierDeviceDetail
    } = adapter.getSelectors<RootState>(selectSupplierDeviceDetails)

export default supplierDeviceDetailsSlice.reducer;
