import React from 'react'
import App from './App'
import { store } from './app/store'
import { Provider } from 'react-redux'
import * as serviceWorker from './serviceWorker'
import { AppLocalizationProvider } from './app/L10n'
import {
  createTheme,
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
} from '@mui/material';
import { enableMapSet } from 'immer'
import plLocale from 'date-fns/locale/pl'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { createRoot } from 'react-dom/client'
import "./styles.css";

export const toolbarMinHeight = 64

const theme = createTheme({
  palette: {
    primary: {
      main: 'rgba(65, 85, 99, 1)',
      contrastText: '#fff',
    },
    secondary: {
      main: 'rgba(71, 184, 172, 1)',
      contrastText: '#fff'
    },
  },
  mixins: {
    toolbar: {
      minHeight: toolbarMinHeight,
    }
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: '#f2f2f2',
          },
          marginTop: '5px',
          height: '40px',
          minHeight: '40px',
          maxHeight: '40px',
        },
      },
    },
  }
})

enableMapSet()

const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <CssBaseline />
    <Provider store={store}>
      <AppLocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={plLocale}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </StyledEngineProvider>
        </LocalizationProvider>
      </AppLocalizationProvider>
    </Provider>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
