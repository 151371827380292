import { Localized } from "@fluent/react";
import SaveIcon from '@mui/icons-material/Save';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    ButtonGroup,
    Card,
    CardActions,
    CardContent,
    Chip,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Fab,
    Grid,
    IconButton,
    Paper,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { Autocomplete, Skeleton } from '@mui/material';
import DatePicker from "@mui/lab/DatePicker";
import { formatISO, parseJSON } from "date-fns";
import { useCallback, useMemo, useState } from "react";
import { LoadProcesses, LoadProcessTests, LoadProcessTestSteps, LoadUsers } from "../../app/AppDataLoader";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { selectAllUsers, User } from "../users/usersSlice";
import { ValidationStage } from "../validations/validationsSlice";
import { createProcessTest, selectProcessTestById, updateProcessTest } from "./processTestsSlice";
import { AppLoader } from "../../app/AppLoader";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { unwrapResult } from "@reduxjs/toolkit";
import { createTestStep, selectAllProcessTestSteps, TestCriteria, TestStep, updateTestStep } from "./processTestStepSlice";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import { TestStatusLabel } from "./TestStatusLabel";
import DeleteForever from '@mui/icons-material/DeleteForever';
import produce from "immer";
import { useNavigate, useParams } from "react-router-dom";

export const TestCreate = ({ stage }: { stage: ValidationStage }) => {
    const { processId } = useParams<{ processId: AppId }>()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const allUsers = useAppSelector(selectAllUsers)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [testUser, setTestUser] = useState<string | null>(null)
    const [resultApproverUser, setResulApproverUSer] = useState<string | null>(null)
    const [plannedDate, setPlannedDate] = useState<Date>(new Date())
    const [saving, setSaving] = useState(false)

    const testUserEntity = useMemo(() => {
        return allUsers.find(i => i.userName === testUser)
    }, [allUsers, testUser])
    const resultApproverUserEntity = useMemo(() => {
        return allUsers.find(i => i.userName === resultApproverUser)
    }, [resultApproverUser, allUsers])

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [])
    const handleTestUserChange = useCallback((_: any, newUser: User | null) => {
        setTestUser(newUser?.userName ?? null)
    }, [])
    const handleResultApproverUserChange = useCallback((_: any, newUser: User | null) => {
        setResulApproverUSer(newUser?.userName ?? null)
    }, [])
    const handleSaveClick = async () => {
        setSaving(true)
        try {
            const { id: newTestId } = unwrapResult(
                await dispatch(createProcessTest({
                    id: "",
                    name,
                    description,
                    stage,
                    status: "P",
                    testUser: testUser ?? "",
                    resultApproverUser: resultApproverUser ?? "",
                    plannedDate: formatISO(plannedDate),
                    processId: processId ?? '',
                    hasErrors: false
                }))
            )
            dispatch(showSuccess("saved"))
            navigate(`/${stage}/test/edit/${processId}/${newTestId}`, { replace: true })
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
    }
    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <LoadProcesses component={<Skeleton variant="rectangular" height={400} />}>
            <Container component={Paper} maxWidth="lg">
                <Grid container spacing={2}>
                    <Grid item xs={8}>
                        <TextField
                            fullWidth
                            value={name}
                            onChange={handleNameChange}
                            label={<Localized id="validation-name">Nazwa</Localized>}
                        />
                    </Grid>
                    <Grid item xs={4}>
                            <LoadUsers component={<Skeleton variant="rectangular" />}>
                                <Autocomplete 
                                    fullWidth
                                    options={allUsers}
                                    value={testUserEntity}
                                    onChange={handleTestUserChange}
                                    getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                    renderInput={params =>
                                        <TextField {...params}
                                            fullWidth
                                            label={
                                                <Localized id="test-tester-name">
                                                    <span>Test przeprowadza</span>
                                                </Localized>
                                        }
                                        />
                                    }
                                />
                            </LoadUsers>
                    </Grid>
                    <Grid item xs={4}>
                        <DatePicker
                            views={["year", "month"]}
                            label={<Localized id="test-planned-date"><span>Planowana data</span></Localized>}
                            value={plannedDate}
                            onChange={date => setPlannedDate(date as Date)}
                            renderInput={(params) => <TextField {...params} helperText={null} />}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Chip label={stage} />
                        <Chip label={<TestStatusLabel status="P" />} color="primary" />
                    </Grid>
                    <Grid item xs={4}>
                        <LoadUsers component={<Skeleton variant="rectangular" />}>
                            <Autocomplete 
                                fullWidth
                                options={allUsers}
                                value={resultApproverUserEntity}
                                onChange={handleResultApproverUserChange}
                                getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                                renderInput={params =>
                                    <TextField {...params}
                                        fullWidth
                                        label={
                                            <Localized id="test-approver-name">
                                                <span>Test zatwierdza</span>
                                            </Localized>
                                    }
                                    />
                                }
                            />
                        </LoadUsers>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            multiline
                            inputProps={{ maxLength: 1000 }} 
                            minRows={3}
                            maxRows={12}
                            value={description}
                            onChange={handleDescriptionChange}
                            label={<Localized id="validation-description">Opis</Localized>}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ButtonGroup color="secondary" variant="contained">
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                            <Button onClick={handleBackClick}>
                                <Localized id="back">Wróć</Localized>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>
                <AppLoader open={saving} />
            </Container>
        </LoadProcesses>
    );
    
}

export const TestEdit = ({ stage }: { stage: ValidationStage }) => {
    const { id, processId } = useParams<{ id: AppId, processId: AppId, }>()

    return (
        <LoadProcesses component={<Skeleton variant="rectangular" />}>
            <LoadProcessTests stage={stage} processId={processId ?? ''} component={<Skeleton variant="rectangular" />}>
                <EditForm stage={stage} testId={id ?? ''} />
            </LoadProcessTests>
        </LoadProcesses>
    );
}

const EditForm = ({ testId, stage }: { testId: AppId, stage: ValidationStage, }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const test = useAppSelector(state => selectProcessTestById(state, testId))
    const allUsers = useAppSelector(selectAllUsers)

    const [name, setName] = useState(test?.name ?? "")
    const [description, setDescription] = useState(test?.description ?? "")
    const [testUser, setTestUser] = useState(test?.testUser ?? null)
    const [resultApproverUser, setResulApproverUSer] = useState(test?.resultApproverUser ?? null)
    const [plannedDate, setPlannedDate] = useState<Date>(test?.plannedDate ? parseJSON(test.plannedDate) : new Date())
    const [saving, setSaving] = useState(false)

    const testUserEntity = useMemo(() => {
        return allUsers.find(i => i.userName === testUser)
    }, [allUsers, testUser])
    const resultApproverUserEntity = useMemo(() => {
        return allUsers.find(i => i.userName === resultApproverUser)
    }, [resultApproverUser, allUsers])

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [])
    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [])
    const handleTestUserChange = useCallback((_: any, newUser: User | null) => {
        setTestUser(newUser?.userName ?? null)
    }, [])
    const handleResultApproverUserChange = useCallback((_: any, newUser: User | null) => {
        setResulApproverUSer(newUser?.userName ?? null)
    }, [])
    const handleSaveClick = async () => {
        setSaving(true)
        try {
            unwrapResult(
                await dispatch(updateProcessTest({
                    id: testId,
                    name,
                    description,
                    stage,
                    status: test?.status ?? "P",
                    testUser: testUser ?? "",
                    resultApproverUser: resultApproverUser ?? "",
                    plannedDate: formatISO(plannedDate),
                    processId: test?.processId ?? "",
                    hasErrors: false
                }))
            )
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
    }
    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    return (
        <Container component={Paper} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        label={<Localized id="validation-name">Nazwa</Localized>}
                    />
                </Grid>
                <Grid item xs={4}>
                    <LoadUsers component={<Skeleton variant="rectangular" />}>
                        <Autocomplete 
                            fullWidth
                            options={allUsers}
                            value={testUserEntity}
                            onChange={handleTestUserChange}
                            getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                            renderInput={params =>
                                <TextField {...params}
                                    fullWidth
                                    label={
                                        <Localized id="test-tester-name">
                                            <span>Test przeprowadza</span>
                                        </Localized>
                                }
                                />
                            }
                        />
                    </LoadUsers>
                </Grid>
                <Grid item xs={4}>
                    <DatePicker
                        views={["year", "month"]}
                        label={<Localized id="test-planned-date"><span>Planowana data</span></Localized>}
                        value={plannedDate}
                        onChange={date => setPlannedDate(date as Date)}
                        renderInput={(params) => <TextField {...params} helperText={null} />}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Chip label={stage} />
                    <Chip label={<TestStatusLabel status={test?.status ?? "P"} />} color="primary" />
                </Grid>
                <Grid item xs={4}>
                    <LoadUsers component={<Skeleton variant="rectangular" />}>
                        <Autocomplete 
                            fullWidth
                            options={allUsers}
                            value={resultApproverUserEntity}
                            onChange={handleResultApproverUserChange}
                            getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                            renderInput={params =>
                                <TextField {...params}
                                    fullWidth
                                    label={
                                        <Localized id="test-approver-name">
                                            <span>Test zatwierdza</span>
                                        </Localized>
                                }
                                />
                            }
                        />
                    </LoadUsers>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        multiline
                        inputProps={{ maxLength: 1000 }} 
                        minRows={3}
                        maxRows={12}
                        value={description}
                        onChange={handleDescriptionChange}
                        label={<Localized id="validation-description">Opis</Localized>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TestSteps testId={testId} />
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup sx={{
                        marginBottom: 2,
                    }} color="secondary" variant="contained">
                        <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                        </Button>
                        <Button onClick={handleBackClick}>
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <AppLoader open={saving} />
        </Container>
    );
}

const TestSteps = ({ testId }: { testId: AppId }) => {
    const dispatch = useAppDispatch()
    const allSteps = useAppSelector(selectAllProcessTestSteps)
    const [dialog, setDialog] = useState(false)
    const [saving, setSaving] = useState(false)
    const [stepId, setStepId] = useState<AppId | null>(null)
    const [name, setName] = useState("")
    const [testCriterias, setTestCriterias] = useState<TestCriteria[]>([])

    const handleCancelDialogClick = useCallback(() => setDialog(false), [])
    const handleNameChange: AppTextFieldHandler = 
        useCallback(e => setName(e.target.value), [])

    const handleAddStepClick = useCallback(() => {
        setStepId("")
        setName("")
        setTestCriterias([])
        setDialog(true)
    }, [])
    const handleEditStepClick = ({ id, name, testCriterias }: TestStep) => {
        setStepId(id)
        setName(name)
        setTestCriterias(testCriterias)
        setDialog(true)
    }
    const handleCriteriaChange = useCallback((i: number, value: string) => {
        setTestCriterias(
            produce((draft) => {
                draft[i].name = value
            })
        )
    }, [])
    const handleCriteriaDeleteClick = useCallback((i: number) => {
        setTestCriterias(
            produce((draft) => {
                draft.splice(i, 1)
            })
        )
    }, [])
    const handleCriteriaAddClick = useCallback(() => {
        setTestCriterias(
            produce((draft) => {
                draft.push({
                    id: "",
                    name: "",
                    comment: "",
                    status: "N",
                    stepId: stepId ?? "",
                })
            })
        )
    }, [stepId])
    const handleSaveStepClick = async () => {
        setSaving(true)

        try {
            if (stepId) {
                unwrapResult(await dispatch(updateTestStep({
                    id: stepId ?? "",
                    name,
                    testCriterias,
                    testId,
                })))
            } else {
                unwrapResult(await dispatch(createTestStep({
                    id: stepId ?? "",
                    name,
                    testCriterias,
                    testId,
                })))
            }
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }

        setDialog(false)
        setSaving(false)
    }



    return (
        <LoadProcessTestSteps testId={testId} component={<Skeleton variant="rectangular" height={256} />}>
            <Card elevation={4}>
                <CardContent>
                    <Typography variant="subtitle2" component="div" color="textSecondary">
                        <Localized id="test-steps">
                            <span>Kroki testu</span>
                        </Localized>
                    </Typography>
                    <>
                        {allSteps.map(step => {
                            const { id, name, testCriterias } = step
                            return <Accordion key={id}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography variant="subtitle2">{name}</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <TestCriterias items={testCriterias} />
                                </AccordionDetails>
                                <AccordionActions>
                                    <Button 
                                        startIcon={<EditIcon />}
                                        onClick={() => handleEditStepClick(step)}
                                    >
                                        <Localized id="edit">
                                            <span>Edytuj</span>
                                        </Localized>
                                    </Button>
                                </AccordionActions>
                            </Accordion>
                        })}
                    </>
                </CardContent>
                <CardActions>
                    <Fab size="small" onClick={handleAddStepClick}>
                        <AddIcon />
                    </Fab>
                </CardActions>
            </Card>
            <Dialog open={dialog} maxWidth="md" fullWidth={true}>
                <DialogTitle>
                    <Typography variant="subtitle2" color="textSecondary" gutterBottom >
                        <Localized id="test-step">
                            <span>Krok</span>
                        </Localized>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2}>
                        <TextField fullWidth onChange={handleNameChange} value={name}></TextField>
                        <Card component="div">
                            <CardContent>
                                <Typography sx={{
                                    marginBottom: 2,
                                }} variant="subtitle2" component="div" color="textSecondary">
                                    <Localized id="validation-acceptance-criteria">
                                        <span>Kryteria akceptacji</span>
                                    </Localized>
                                </Typography>
                                <Stack direction="column" spacing={2}>
                                    {testCriterias.map(({ name }, idx) => {
                                        return <Box sx={{
                                                display: 'flex',
                                            }}>
                                            <TextField 
                                                multiline
                                                sx={{
                                                    width: "100%",
                                                }}
                                                value={name}
                                                onChange={e => handleCriteriaChange(idx, e.target.value)}
                                                label={
                                                    <Localized id="activity-criteria">
                                                        <span>Kryterium</span>
                                                    </Localized>
                                                } 
                                            />
                                            <IconButton onClick={() => handleCriteriaDeleteClick(idx)} size="large">
                                                <DeleteForever />
                                            </IconButton>
                                        </Box>
                                    })}
                                </Stack>
                            </CardContent>
                            <CardActions>
                                <Fab size="small" onClick={handleCriteriaAddClick}>
                                    <AddIcon />
                                </Fab>
                            </CardActions>
                        </Card>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button disabled={saving} onClick={handleSaveStepClick}>
                        <Localized id="ok">
                            <span>OK</span>
                        </Localized>
                    </Button>
                    <Button disabled={saving} onClick={handleCancelDialogClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </LoadProcessTestSteps>
    );
}

const TestCriterias = (props: { items: TestCriteria[] }) => {
    const { items } = props
    return <div>
        <Typography variant="subtitle2" color="textSecondary" component="div">
            <Localized id="validation-acceptance-criteria">
                <span>Kryteria akceptacji</span>
            </Localized>
        </Typography>
        <div>
            <ul>
                {items.map(({ name }, idx) => <li key={idx}><Typography gutterBottom variant="body1">{name}</Typography></li>)}
            </ul>
        </div>
    </div>
}
