import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, MenuItem, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { If } from "../../app/If"
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { useCallback, useState } from "react"
import {  createClientQuestion, selectAiQuestionById, updateAiQuestions } from "./aiQuestionsSlice"
import { postWithAuth } from "../../http"
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { selectModule } from "../modules/moduleSlice"

export interface CreateUpdateQuestionProps {
    id: AppId | undefined
    onSaveSuccess: () => void
    onCancel: () => void
}

export const AiQuestionEditDialog = ({ id,  onSaveSuccess, onCancel }: CreateUpdateQuestionProps) => {
    const dispatch = useAppDispatch()
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    
    const [saving, setSaving] = useState(false)

    const entity = useAppSelector(state => selectAiQuestionById(state, id ?? ""));

    const [code, setCode] = useState<string>(entity?.code ?? "");    
    const [question, setQuestion] = useState<string>(entity?.question ?? "");    
    const [role, setRole] = useState<string>(entity?.role ?? "");    
    const [maxTokens, setMaxTokens] = useState<number>(entity?.maxTokens ?? 100);    
    const [temperature, setTemperature] = useState<number>(entity?.temperature ?? 1);    
    const [topP, setTopP] = useState<number>(entity?.topP ?? 1);    
    const [answer, setAnswer] = useState<string>("");    
    const [phrase, setPhrase] = useState<string>("");    
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);    
    
    const isSuper = currentModule?.code === "super"
    
    const handleCodeChange: AppTextFieldHandler = useCallback(e => setCode(e.target.value), [setCode]);
    const handleQuestionChange: AppTextFieldHandler = useCallback(e => setQuestion(e.target.value), [setQuestion]);
    const handlePhraseChange: AppTextFieldHandler = useCallback(e => setPhrase(e.target.value), [setPhrase]);
    const handleRoleChange: AppTextFieldHandler = useCallback(e => setRole(e.target.value), [setRole]);
    
    const handleTemperatureChange: AppTextFieldHandler = 
        useCallback(e => { setTemperature(Number.parseFloat(e.target.value)) }, [setTemperature])
    
    const handleTopPChange: AppTextFieldHandler = 
        useCallback(e => { setTopP(Number.parseFloat(e.target.value)) }, [setTopP])
    
    const handleMaxTokensChange: AppTextFieldHandler = 
        useCallback(e => { setMaxTokens(Number.parseFloat(e.target.value)) }, [setMaxTokens])

        
    const handleSaveClick = async () => {
        setSaving(true)
        try {
            if (entity){
                let questionToSave ={...entity, 
                    code, question, role, maxTokens, temperature, topP
                };
        
                await dispatch(isSuper ? updateAiQuestions(questionToSave) : createClientQuestion(questionToSave)).unwrap()
                
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
        }
        catch (error) {
                dispatch(showError("error"));
            } 
        finally {
                setSaving(false)
            }
    }

    const handleTestClick = async () => {
        setWaitingForAnswer(true)
        setAnswer("")
        try{
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask/test`,
                payload: {
                    Code : code,
                    Question: question,
                    Phrase : phrase,
                    Role: role,
                    MaxTokens: maxTokens,
                    Temperature: temperature,
                    TopP: topP
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setAnswer(payload.answer)
            }
        }
        finally{
            setWaitingForAnswer(false)
        }
    }

    return <>
        <DialogTitle>
            <If condition={id !== ""} otherwise={
                <Localized id="ai-question-new">
                    <span>Nowe pytanie</span>
                </Localized>
                }>
                <Localized id="ai-question-edit">
                    <span>Edycja pytania</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} padding={2}>
                <TextField 
                    fullWidth
                    disabled={true}
                    value={code}
                    inputProps={{ maxLength: 20 }}
                    onChange={handleCodeChange}
                    label={<Localized id="ai-question-code">Kod</Localized>}>
                </TextField>
                <TextField 
                    fullWidth
                    value={question}
                    minRows={3}
                    maxRows={12}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    onChange={handleQuestionChange}
                    InputProps={{ 
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleTestClick}>
                                    <AutoFixHighIcon />
                                </IconButton>
                            </InputAdornment>
                          )
                    }}
                    label={<Localized id="ai-question">Pytanie</Localized>}>
                </TextField>
                <If condition={isSuper}>
                    <Stack direction="row" spacing={2}>
                        <TextField 
                            fullWidth
                            type="number"
                            value={temperature}
                            inputProps={{
                                step: '0.1', 
                                min: '0',
                                max: '2', // Ustawienie wartości, o której będzie zmieniać się liczba po przecinku (w tym przypadku 2 miejsca po przecinku)
                                pattern: '[0-9]+([,.][0-9]+)?' // Wzorzec dla liczb z wartościami po przecinku
                            }}
                            onChange={handleTemperatureChange}
                            label={<Localized id="">Temperature</Localized>}>
                        </TextField>  
                        <TextField 
                            fullWidth
                            type="number"
                            value={topP}
                            inputProps={{
                                step: '0.1', 
                                min: '0',
                                max: '1', // Ustawienie wartości, o której będzie zmieniać się liczba po przecinku (w tym przypadku 2 miejsca po przecinku)
                                pattern: '[0-9]+([,.][0-9]+)?' // Wzorzec dla liczb z wartościami po przecinku
                            }}
                            onChange={handleTopPChange}
                            label={<Localized id="">TopP</Localized>}>
                        </TextField>
                        <TextField 
                            fullWidth
                            type="number"
                            value={maxTokens}
                            inputProps={{
                                step: '1', 
                                min: '0',
                                max: '1000', // Ustawienie wartości, o której będzie zmieniać się liczba po przecinku (w tym przypadku 2 miejsca po przecinku)
                                pattern: '[0-9]+([,.][0-9]+)?' // Wzorzec dla liczb z wartościami po przecinku
                            }}
                            onChange={handleMaxTokensChange}
                            label={<Localized id="">Max tokens</Localized>}>
                        </TextField>
                        <TextField 
                                select
                                required 
                                sx={{ minWidth: 256}}
                                value={role}   
                                onChange={handleRoleChange}         
                                label={<Localized id="user-role">Rola</Localized>} >
                                    {["system", "user", "assistant", "function"].map(role => <MenuItem key={role} value={role}>{role}</MenuItem>)}
                            </TextField>  
                    </Stack>
                </If>
                <TextField 
                    fullWidth
                    value={phrase}
                    minRows={3}
                    maxRows={12}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    onChange={handlePhraseChange}
                    label={<Localized id="ai-question">Fraza</Localized>}>
                </TextField>  
                <TextField 
                    fullWidth
                    value={answer}
                    disabled={true}
                    minRows={3}
                    maxRows={12}
                    multiline
                    inputProps={{ maxLength: 1000 }}
                    InputProps={{ 
                        endAdornment: (
                            <InputAdornment position="end">
                                <If condition={waitingForAnswer}>
                                    <CircularProgress />
                                </If>
                            </InputAdornment>
                          )
                    
                    }}
                    label={<Localized id="ai-answer">Odpowiedź</Localized>}>
                </TextField> 
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving}>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}