import { Box, Container, Paper, Fab, Dialog, DialogTitle, DialogActions, Button, DialogContent, TextField } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadBusinessCases, selectAllBusinessCases, selectBusinessCases } from "./businessCaseSlice"
import AddIcon from '@mui/icons-material/Add'
import { Localized } from "@fluent/react"
import { LoadingContainer } from "../../app/LoadingContainer"
import { useNavigate } from "react-router-dom"
import { selectSettings } from "../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"

const columns: GridColDef[] = [
    { field: 'title'
    , headerName: 'Tytuł'
    , flex: 1
    },
    { field: 'status'
    , headerName: 'Status'
    },
    {
        field: 'createDate', 
        headerName: 'Utworzono',
        valueGetter: (_, row) => {
            return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
        }
    },
    { field: 'createdBy'
    , headerName: 'Utworzył'
    },
]

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export const BusinessCaseList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const { state } = useAppSelector(selectBusinessCases)
    const allBusinessCases = useAppSelector(selectAllBusinessCases)

    const [open, setOpen] = useState<boolean>(false)
    const [title, setTitle] = useState<string>('')

    const handleCreateNewBusinessCaseClick = async () => {
        navigate(`/businessCase/create`)
    }

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadBusinessCases())
        }
    }, [state])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <LoadingContainer/>
        case 'error':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Data not loaded due to error!</h5>
            </Container>
        case 'loaded':
            return <><Container maxWidth='xl'>
                <h5>Business Case list</h5>
                <DataGrid
                    style={{ minHeight: 100, height: 'calc(100vh - 250px)' }}
                    localeText={getCustomLocaleText()}
                    columns={columns}
                    rows={allBusinessCases}
                    slots={{ toolbar: QuickSearchToolbar }}
                    onRowDoubleClick={({ row }) => navigate(`/businessCase/edit/${row.id}`)}
                    slotProps={{
                        toolbar: {
                            showQuickFilter: false,
                        }
                    }}
                    initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
                />
                <Fab sx={{ mt: 2 }} color="secondary" onClick={handleCreateNewBusinessCaseClick}>
                    <AddIcon />
                </Fab>
            </Container>
            <Dialog open={open} fullWidth maxWidth='lg'>
                <DialogTitle>
                    <Localized id='new-business-case'>Nowy Business Case</Localized>
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ pt: 2 }}>
                        <TextField
                            label={<Localized id='title'>Tytuł</Localized>}
                            placeholder='Tytuł'
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            fullWidth
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {}}>
                        <Localized id='save'>Zapisz</Localized>
                    </Button>
                    <Button onClick={() => setOpen(false)}>
                        <Localized id='cancel'>Anuluj</Localized>
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    }
}
