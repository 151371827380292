import { Localized } from "@fluent/react"
import { styled } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Collapse, IconButton, Typography } from "@mui/material";
import clsx from "clsx"
import { useCallback, useState } from "react"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ProcessTest } from "./processTestsSlice"

const PREFIX = 'TestInfo';

const classes = {
    root: `${PREFIX}-root`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`
};

const StyledCard = styled(Card)(( { theme }) => ({
    [`&.${classes.root}`]: {
      width:"100%",
      flexGrow: 1,
      marginBottom: theme.spacing(1)
    },

    [`& .${classes.expand}`]: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
      }),
    },

    [`& .${classes.expandOpen}`]: {
        transform: 'rotate(180deg)',
    }
}));

export const TestInfo = ({ name, description }: ProcessTest) => {
    const [expanded, setExpanded] = useState(false)

    const handleExpandClick = useCallback(() => {
        setExpanded(value => !value)
    }, [])



    return (
        <StyledCard className={classes.root}>
                
            <CardHeader
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        size="large">
                        <ExpandMoreIcon />
                    </IconButton>
                }

                title={name}
                subheader={<Localized id="validation-name">Nazwa</Localized>} 
            />

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                {<Localized id="validation-description">Opis</Localized>}                               
                </Typography>
            </CardContent>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
                <CardContent>
                    <Typography paragraph>
                        {description}
                    </Typography>
                </CardContent>
            </Collapse>
        </StyledCard>
    );
}