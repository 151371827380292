import { useCallback, useState } from 'react';
import { Box, Paper, TextField, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { DocumentDescriptionField, DocumentHeader } from '../documentsApi';
import { AppId } from '../../../app/appTypes';
import { EditDescriptionField } from './EditDescriptionField';
import { EditDocumentNumberDialog } from './EditDocumentNumberDialog';

type DialogState
    = "none"
    | "editDocumentNumber"
    | { tag: 'editDescriptionField', field: DocumentDescriptionField, value: string, documentId: AppId }

export const DocumentDetailsPanel = ({ document }: { document: DocumentHeader }) => {
    const [dialog, setDialog] = useState<DialogState>("none")
    const [documentNumber, setDocumentNumber] = useState<string>(document.no);
    const [title, setTitle] = useState<string>(document.title);
    const [purpose, setPurpose] = useState<string>(document.purpose);
    const [description, setDescription] = useState<string>(document.description);

    const handleEditDescriptionFieldClick = useCallback((field: 'title' | 'purpose' | 'description' | 'area', value: string) => {
        setDialog({ tag: 'editDescriptionField', field, value, documentId: document.id })
    }, [])

    const handleEditDocumentNumber = useCallback(() => {
        setDialog("editDocumentNumber")
    }, [])

    const editDocumentNumberSuccess = (newValue: string) => {
        setDialog("none")
        setDocumentNumber(newValue)
    }

    const editDescriptionFieldSuccess = (fieldName: DocumentDescriptionField, newValue: string) => {
        setDialog("none")
        switch (fieldName) {
            case 'title':
                setTitle(newValue)
                break;
            case 'description':
                setDescription(newValue)
                break;
            case 'purpose':
                setPurpose(newValue)
                break;
        }
    }

    return <>
        <Paper elevation={3} sx={{ padding: 1, marginBottom: 1 }}>
            <Box display="flex" alignItems="center" marginBottom={3}>
                <TextField
                    label="Document Number"
                    fullWidth
                    value={documentNumber}
                    InputProps={{
                        readOnly: true
                    }}
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                />
                <IconButton onClick={() => handleEditDocumentNumber()}>
                    <EditIcon />
                </IconButton>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={3}>
                <TextField
                    label="Title"
                    fullWidth
                    value={title}
                    InputProps={{
                        readOnly: true
                    }}
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                />
                <IconButton onClick={() => handleEditDescriptionFieldClick("title", title)}>
                    <EditIcon />
                </IconButton>
            </Box>
            <Box display="flex" alignItems="center" marginBottom={3}>
                <TextField
                    label="Description"
                    fullWidth
                    multiline={true}
                    minRows={4}
                    value={description}
                    InputProps={{
                        readOnly: true
                    }}
                    variant="outlined"
                    sx={{ marginRight: 2 }}
                />
                <IconButton onClick={() => handleEditDescriptionFieldClick("description", description)}>
                    <EditIcon />
                </IconButton>
            </Box>
        </Paper>
        {dialog === 'editDocumentNumber' &&
            <EditDocumentNumberDialog
                documentId={document.id}
                value={documentNumber}
                onSuccess={editDocumentNumberSuccess}
                onCancel={() => setDialog('none')} />
        }
        {typeof dialog === 'object' && dialog.tag === 'editDescriptionField' && <EditDescriptionField
            documentId={document.id}
            fieldName={dialog.field}
            value={dialog.value}
            onSuccess={editDescriptionFieldSuccess}
            onCancel={() => setDialog("none")}
        />}

    </>
};