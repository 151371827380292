import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../../app/appTypes";
import { RootState } from "../../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";
import { logout } from "../../user/userSlice";

export interface MaterialCardItem {
    id: AppId, 
    producer: string
    name: string, 
    description: string, 
    guid: string,
    order: number,
    materialCardId: AppId
}

const adapter = createEntityAdapter<MaterialCardItem>({
    selectId: (materialCardItem) => materialCardItem.id,
})

export type DraftMaterialCardItem = Omit<MaterialCardItem, "id" | "guid" | "order">

export type MaterialCardItemsState = EntityState<MaterialCardItem> & { loaded: boolean }

const initialState: MaterialCardItemsState = adapter.getInitialState({ loaded: false })


export const loadMaterialCardItems = createAsyncThunk<MaterialCardItem[], void, AppThunkAPIType>
("materialcarditems/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/MaterialItem"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as MaterialCardItem[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createMaterialCardItem = createAsyncThunk<MaterialCardItem, DraftMaterialCardItem, AppThunkAPIType>(
    "materialcarditems/create", 
    async (item: DraftMaterialCardItem, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/MaterialItem",
            payload: item,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MaterialCardItem
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateMaterialCardItem = createAsyncThunk<MaterialCardItem, MaterialCardItem, AppThunkAPIType>(
    "materialcarditems/update", 
    async (item: MaterialCardItem, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/MaterialItem/${item.id}`,
            payload: item,
        }))
        const { payload } = result

        if (putWithAuth.fulfilled.match(result)) {
            return item
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteMaterialCardItem = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "materialcarditems/delete",
    async (id: AppId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/MaterialItem/${id}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const materialCardItemSlice = createSlice({
    name: "materialcarditems",
    initialState,
    reducers: {
        clearMaterialCardItemsSlice: (state) => {
            adapter.removeAll(state)
            state.loaded = false
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createMaterialCardItem.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteMaterialCardItem.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateMaterialCardItem.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<MaterialCardItem, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(loadMaterialCardItems.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })

        builder.addCase(logout.fulfilled, state => {
            adapter.removeAll(state)
            state.loaded = false
        })
    },
})

export const { clearMaterialCardItemsSlice } = materialCardItemSlice.actions

export const selectMaterialCardItems = (state: RootState): MaterialCardItemsState => state.materialCardItems

export const 
    { selectAll: selectAllMaterialCardItems
    , selectById: selectMaterialCardItemById 
    , selectTotal: selectTotalMaterialCardItem
    } = adapter.getSelectors<RootState>(selectMaterialCardItems)

export default materialCardItemSlice.reducer;
