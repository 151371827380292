import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { RootState } from "../../app/store";

export interface Supplier {
    id: AppId,
    companyId: AppId,
    name: string,
    shortName: string,
    address1: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    country: string,
    contactPerson: string,
    email: string,
    phone: string,
    mobile: string,
   
}
const adapter = createEntityAdapter<Supplier>({
    selectId: (supplier) => supplier.id,
})

export type DraftSupplier = Omit<Supplier, "id">

export type SuppliersState = EntityState<Supplier> & { loaded: boolean }

const initialState: SuppliersState = adapter.getInitialState({ loaded: false })
export const loadSuppliers = createAsyncThunk<Supplier[], void, AppThunkAPIType>("suppliers/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/suppliers"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Supplier[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})
export const createSupplier = createAsyncThunk<Supplier, DraftSupplier, AppThunkAPIType>
    ("suppliers/create", async (supplier: DraftSupplier, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/suppliers",
            payload: supplier,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as Supplier
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
export const updateSupplier = createAsyncThunk<Supplier, Supplier, AppThunkAPIType>(
    "suppliers/update", 
    async (supplier: Supplier, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/suppliers/${supplier.id}`,
            payload: supplier,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return supplier as Supplier
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteSupplier = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "suppliers/delete",
    async (supplierId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/suppliers/${supplierId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return supplierId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
export const suppliersSlice = createSlice({
    name: "suppliers",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createSupplier.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
    
        builder.addCase(deleteSupplier.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });
        builder.addCase(updateSupplier.fulfilled, (state, action) => {
            const { id } = action.payload;
            const changes = action.payload as DraftSupplier;
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        builder.addCase(loadSuppliers.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        });
    },
})
export const selectSuppliers = (state: RootState): SuppliersState => state.suppliers

export const 
    { selectAll: selectAllSuppliers
    , selectById: selectSupplierById 
    } = adapter.getSelectors<RootState>(selectSuppliers)

export default suppliersSlice.reducer