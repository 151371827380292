import { Localized } from "@fluent/react";
import { DocumentWorkflowActions } from "../documentsApi";


export const ActionTitle = ({ action }: { action: DocumentWorkflowActions }) => {
  switch (action) {
    case "FinishOpinion":
      return <Localized id="document-action-finish-opinion">
        <span>_Zakończ opiniowanie</span>
      </Localized>
    case "FinishEdition":
      return <Localized id="document-action-finish-edition">
        <span>_Zakończ edytowanie</span>
      </Localized>
    case "Approve":
      return <Localized id="document-action-approve">
        <span>_Zatwierdź dokument</span>
      </Localized>
    case "Reject":
      return <Localized id="document-action-reject">
        <span>_Odrzuć dokument</span>
      </Localized>
    case "SendToOpinion":
      return <Localized id="document-action-send-to-opinion">
        <span>_Wyślij do opiniowania</span>
      </Localized>
    case "SendToApprove":
      return <Localized id="document-action-send-to-approve">
        <span>_Wyślij do zatwierdzenia</span>
      </Localized>
    case "WithdrawFromOpinion":
      return <Localized id="document-action-withdraw-from-opinion">
        <span>_Wycofaj z opiniowania</span>
      </Localized>
    default:
      return <span></span>
  }
};

export const ActionContent = ({ action }: { action: DocumentWorkflowActions }) => {
  switch (action) {
    case "FinishOpinion":
      return <Localized id="document-action-finish-opinion-question">
        <span>_Czy napewno chcesz przesłać dokument do opiniowania?</span>
      </Localized>
      return <></>
    case "FinishEdition":
      return <Localized id="document-action-finish-edition-question">
        <span>_Czy napewno zakończyc edycje dokumentu?</span>
      </Localized>
    case "Approve":
      return <Localized id="document-action-approve-question">
        <span>_Czy napewno zatwierdzić dokument?</span>
      </Localized>
    case "Reject":
      return <Localized id="document-action-reject-question">
        <span>_Czy napewno odrzucić dokument?</span>
      </Localized>
    case "SendToOpinion":
      return <Localized id="document-action-sent-to-opinion-question">
        <span>_Czy napewno chcesz przesłać dokument do opiniowania?</span>
      </Localized>
    case "SendToApprove":
      return <Localized id="document-action-sent-to-approve-question">
        <span>_Czy napewno chcesz przesłać dokument do zatwierdzenia?</span>
      </Localized>
    case "WithdrawFromOpinion":
      return <Localized id="document-action-withdraw-from-opinion-question">
        <span>_Czy napewno chcesz wycofać dokument z opiniowania? Wycofanie anuluje wykonane opinie.</span>
      </Localized>
    default:
      return <span></span>
  }
};