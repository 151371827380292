import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType, AppId } from "../../../app/appTypes";
import { getWithAuth } from "../../../http";

export type SystemEventId = string;

export interface SystemEvent {
    id: SystemEventId;
    createdBy: string;
    eventType: string;
    eventDate: Date | null;
    objectType: string | null;
    objectId: string | null;
    description: string;
    companyId: number;
}

export type DraftSystemEvent = Omit<SystemEvent, "id">

interface LoadSystemEventsParams {
    companyId: AppId,
    query: string
}

export const loadSystemEvents = createAsyncThunk<SystemEvent[], string, AppThunkAPIType>(
    "system-events/load",
    async (query, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-events${query}` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemEvent[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadSystemEventsForCompany = createAsyncThunk<SystemEvent[], LoadSystemEventsParams, AppThunkAPIType>(
    "system-events/load",
    async ({ companyId, query }, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-events/${companyId}${query}` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as SystemEvent[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const getSystemEventsTypes = createAsyncThunk<string[], void, AppThunkAPIType>(
    "system-events/load-types",
    async (_, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/system-events/types` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as string[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)