import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { Urs, URSRequirement } from "../urs/UrsSlice";
import { putWithAuth } from "../../http";

export interface RiskAnalysisRequirement {
    id: AppId
    requirementId: AppId
    severity: number
    frequency: number
    detection: number
    defect: string
    cause: string 
    effect: string 
}

export interface RiskAnalysis {
    id: AppId
    description: string
    treshold: number
    requirements: RiskAnalysisRequirement[]
}

const adapter = createEntityAdapter<RiskAnalysis>({
    selectId: risk => risk.id,
})

export const updateRiskRequirement = createAsyncThunk<Urs, URSRequirement, AppThunkAPIType>(
    "risk/updateRequirement", 
    async (data, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/Urs/document/requirement/${data.id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as Urs
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const updateRiskLimit = createAsyncThunk<Urs, { ursId: AppId, value: string }, AppThunkAPIType>("urs/updateDescriptionField", async (data, { dispatch, getState, rejectWithValue }) => {
    const { ursId, value } = data
    const result = await dispatch(putWithAuth({
        url: `api/Urs/document/${ursId}/descriptionfields`,
        payload: { fieldName: "Limit", text: value },
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as Urs
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export type RiskAnalysisState = EntityState<RiskAnalysis> & { state: AppDataState }

export const loadRiskAnalysis = createAsyncThunk<RiskAnalysis[], void, AppThunkAPIType>("riskAnalysis/load", async () => {
    return [{
        id: "1",
        description: "",
        treshold: 10,
        requirements: [{
            id: "1",
            requirementId: "1",
            severity: 2,
            frequency: 5,
            detection: 4,
            defect: "",
            cause: "",
            effect: "", 
        }, {
            id: "2",
            requirementId: "2",
            severity: 2,
            frequency: 5,
            detection: 4,
            defect: "",
            cause: "",
            effect: "", 
        }, {
            id: "3",
            requirementId: "1",
            severity: 2,
            frequency: 5,
            detection: 4,
            defect: "",
            cause: "",
            effect: "", 
        }, {
            id: "4",
            requirementId: "2",
            severity: 2,
            frequency: 5,
            detection: 4,
            defect: "",
            cause: "",
            effect: "", 
        }],
    }]
})

const initialState = adapter.getInitialState({ state: "empty" })

export const riskAnalysisSlice = createSlice({
    name: "riskAnalysis",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadRiskAnalysis.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.state = "loaded"
        })
    },
})

export const selectRiskAnalysis = (state: RootState) => state.riskAnalysis

export const {
    selectById: selectRiskAnalysisById,
} = adapter.getSelectors<RootState>(selectRiskAnalysis)

export default riskAnalysisSlice.reducer