import {createAsyncThunk, createEntityAdapter, createSlice, EntityState} from "@reduxjs/toolkit";
import {AppDataState, AppId, AppThunkAPIType, unknownError} from "../../app/appTypes";
import {RootState} from "../../app/store";
import {getWithAuth, postWithAuth, putWithAuth} from "../../http";

// N - New (default), R - Resolved, U - Unresolved
export type CommentStatus = "N" | "R" | "U"

export interface URSComment {
    id               : AppId
    comment          : string
    createdBy        : string
    createdByFullName: string
    createDate       : string
    modifyDate       : string
    modifiedBy       : string
    ursRequirementId : AppId | null
    ursId            : AppId
    status           : CommentStatus
    parentCommentId  : AppId | null
    subComments      : URSComment[]
}

export interface UpdateComment {
    id               : AppId
    comment          : string
}

export interface ChangeCommentStatus {
    id               : AppId
    status           : CommentStatus
}

export const loadURSComments 
    = createAsyncThunk<URSComment[], AppId, AppThunkAPIType>("URSComments/load", async (ursId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/urs-requirement-comment/urs/${ursId}`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as URSComment[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }, {
        condition: (_, { getState }) => {
            const { state } = getState().ursComments 
            if (state.type === "loading") {
                return false
            } 
        },
    })

export const createURSComment
    = createAsyncThunk<URSComment, URSComment, AppThunkAPIType>("URSComment/create", async (comment, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/urs-requirement-comment`,
            payload: comment,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as URSComment
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const updateURSComment
    = createAsyncThunk<URSComment, UpdateComment, AppThunkAPIType>("URSComment/update", async (comment, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/urs-requirement-comment/${comment.id}`,
            payload: comment,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as URSComment
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

    export const changeCommentStatus
    = createAsyncThunk<URSComment, ChangeCommentStatus, AppThunkAPIType>("URSComment/changeCommentStatus", async (comment, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/urs-requirement-comment/change-status/${comment.id}`,
            payload: comment,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as URSComment
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

const adapter = createEntityAdapter<URSComment>({
    selectId: comment => comment.id
})

export type CommentsState = EntityState<URSComment> & {
    state: AppDataState
    ursId: AppId | undefined
}

const initialState: CommentsState = adapter.getInitialState({
    state: { type: 'empty' },
    ursId: undefined,
})

export const ursCommentsSlice = createSlice({
    name: 'ursComments',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadURSComments.pending, (state, action) => {
            state.state = {
                type      : 'loading',
                requestId : action.meta.requestId,
            }
            state.ursId = action.meta.arg
        })
        builder.addCase(loadURSComments.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.state = {
                type: 'loaded',
            }
        })
        builder.addCase(loadURSComments.rejected, (state, action) => {
            state.state = {
                type: 'error',
                error: action.payload ?? unknownError(),
            }
        })
        builder.addCase(createURSComment.fulfilled, (state, action) => {
            const comment = action.payload
            const { parentCommentId  } = comment
            adapter.addOne(state, action.payload)
            if (parentCommentId !== null) {
                const parent = state.entities[parentCommentId]
                if (parent) {
                    const { subComments } = parent
                    adapter.updateOne(state, {
                        id: parentCommentId,
                        changes: {
                            subComments: [...subComments, comment],
                        }
                    })
                }
            }
        })
        builder.addCase(updateURSComment.fulfilled, (state, action) => {
            const { id, comment } = action.payload
            adapter.updateOne(state, {
                id ,
                changes: {
                    comment
                }
            })
        })
        builder.addCase(changeCommentStatus.fulfilled, (state, action) => {
            const { id, status } = action.payload
            adapter.updateOne(state, {
                id ,
                changes: {
                    status
                }
            })
        })
    }
})

export const selectURSComments = (state: RootState) => state.ursComments

export const {
    selectAll  : selectAllURSComments,
    selectById : selectURSCommentById,
} = adapter.getSelectors(selectURSComments)

export default ursCommentsSlice.reducer

