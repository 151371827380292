import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks"
import { DocumentDescriptionField, updateDescriptionField } from "../documentsApi";

const Label = ({ fieldName }: { fieldName: "description" | "area" | "purpose" | "title" }) => {
    switch (fieldName) {
        case "description": return <Localized id=''>Opis</Localized>
        case "area": return <Localized id=''>Obszar</Localized>
        case "purpose": return <Localized id=''>Cel</Localized>
        case "title": return <Localized id=''>Tytuł</Localized>
    }
}

export interface EditDescriptionFieldProps {
    documentId: AppId
    fieldName: DocumentDescriptionField
    value: string
    onSuccess: (fieldName: DocumentDescriptionField, newValue: string) => void
    onCancel: () => void
}

export const EditDescriptionField = (props: EditDescriptionFieldProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState(props.value)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateDescriptionField({
            documentId: props.documentId,
            fieldName: props.fieldName,
            value
        }))
            .then(unwrapResult)
            .then(() => { props.onSuccess( props.fieldName, value) })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
                setSaving(false)
            })
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <TextField
                sx={{ mt: 2 }}
                fullWidth
                value={value}
                onChange={e => setValue(e.target.value)}
                label={<Label fieldName={props.fieldName} />}
            />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}><Localized id='ok'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
