import { Localized, useLocalization } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Action, deleteAIAccess, updateAIAccess } from "./aiQuestionsSlice"


interface StatusResponse {
    id: string;
    actionId: string;
    status: string;
    limit: number;
}

export interface ActionRunnerProps {
    action: Action & { name: string } 
    onSuccess: (data: StatusResponse) => void
    onError: () => void
    onCancel: () => void
}

export const ActionRunner = ({ action, onSuccess, onError, onCancel }: ActionRunnerProps) => {
    const dispatch = useAppDispatch()
    const [running, setRunning] = useState(false)
    const { id, value } = action
    const { l10n } = useLocalization();
    const [limit, setLimit] = useState<string>(value ?? "100000");

    const handleLimitFieldChange: AppTextFieldHandler = useCallback(e => {
        setLimit(e.target.value)
    }, [])

    const getValue = (actionId) => {
        switch(actionId) {
            case "activeAccess":
                return "ACTIVE";
            case "holdAccess":
                return "HOLD";
            case "changeLimitAccess":
                return limit;
            default:
                return "";
        }
    }

    const run = async() => {
        if (action && action.type === "DELETE") {
            setRunning(true)


            if (action.actionId === "deleteAccess"){
                const result = await dispatch(deleteAIAccess(id))

                if (deleteAIAccess.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    let response:StatusResponse = { id: action.id, actionId: action.actionId, status: "", limit: 0 };
                    onSuccess(response);
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }
        } else if(action && action.type === "PUT") {
            if(action.actionId === "activeAccess" || action.actionId === "holdAccess" || action.actionId === "changeLimitAccess") {
                const result = await dispatch(updateAIAccess({ 
                    id: id,
                    args: { fieldName: action.actionId === "changeLimitAccess" ? "limit" : "status", text: getValue(action.actionId) } 
                }))

                console.log(result)

                if (updateAIAccess.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    let response:StatusResponse = { id: action.id, actionId: action.actionId, status: result.payload.status, limit: result.payload.limit };
                    onSuccess(response);
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }
        }
    }

    let title = <></>
    let content = <></>
    switch (action?.actionId) {
        case "deleteAccess":
            title = <>{ l10n.getString("ai-delete-access", { companyName: action?.name }, "Czy na pewno chcesz usunąć dostęp?") }</>
            break;
        case "activeAccess":
            title = <>{ l10n.getString("ai-active-access", { companyName: action?.name }, "Czy na pewno chcesz zezwolić na dostęp do AI?") }</>
            break;
        case "holdAccess":
            title = <>{ l10n.getString("ai-hold-access", { companyName: action?.name }, "Czy na pewno chcesz wstrzymać dostęp do AI?") }</>
            break;
        case "changeLimitAccess":
            title = <>{ l10n.getString("ai-change-limit-access", { companyName: action?.name }, "Czy na pewno chcesz zmienić limit dostępu do AI?") }</>
            break;
        default:
            title = <></>
            break;
    }

    return <Dialog open={true} maxWidth="xs" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{content}</DialogContentText>
            {action.actionId === "changeLimitAccess" && <TextField 
                sx={{
                    marginTop: 1
                }}
                fullWidth
                autoFocus
                type="text"
                value={limit}
                onChange={handleLimitFieldChange}
                label={<span>Limit</span>}
                autoComplete="off"
            />}
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={run}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

