import { Localized } from "@fluent/react";
import { Box, Button, Divider, ListItemText, Menu, MenuItem, MenuList } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";

const InvalidDateError = () => <Localized id="field-invalid-date"><span>Data jest nieprawidłowa</span></Localized>

export const DateMenuFilter = ({ onSubmit }) => {
    const currentDate = new Date();
    var dateFromFilter = new Date();
    dateFromFilter.setDate(currentDate.getDate() - 30);

    const [validationErrors, setValidationErrors] = useState(false);
    const [dateFrom, setDateFrom] = useState(dateFromFilter)
    const [dateTo, setDateTo] = useState(new Date())
    const [type, setType] = useState("mail-last-30-days");
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openMenu = Boolean(anchorEl);

    const dateFromFilterError = validationErrors && (isNaN(dateFrom.getTime()) || dateFrom === null);
    const dateToFilterError = validationErrors && (isNaN(dateTo.getTime()) || dateTo === null);

    const handleClick = (type) => {
        if(type === 'mail-last-30-days') {
            let newDateFrom = new Date();
            newDateFrom.setDate(currentDate.getDate() - 30);
            onSubmit(newDateFrom, new Date());
            setAnchorEl(null);
        } else if(type === 'mail-current-month') {
            let newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            onSubmit(newDateFrom, new Date());
            setAnchorEl(null);
        } else if(type === "mail-previous-month") {
            let newDateFrom = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
            let newDateTo = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0);
            onSubmit(newDateFrom, newDateTo);
            setAnchorEl(null);
        }
        setType(type);
    }

    const handleChange = (date1, date2) => {
        setValidationErrors(true);
        if(!dateFromFilterError && !dateToFilterError) {
            setValidationErrors(false);
            onSubmit(date1, date2);
        }
        setType("mail-custom-date");
    }

    return (
        <>
            <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget) } color="secondary" variant="outlined">
                <Localized id={type}>Akcje</Localized>
            </Button>
            <Menu anchorEl={anchorEl} open={openMenu} onClose={() => setAnchorEl(null) }>
                <MenuList>
                    <MenuItem onClick={() => handleClick("mail-last-30-days") }>
                        <ListItemText>
                            <Localized id="mail-last-30-days">Ostatnie 30 dni</Localized>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClick("mail-current-month") }>
                        <ListItemText>
                            <Localized id="mail-current-month">Bieżący miesiąc</Localized>
                        </ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => handleClick("mail-previous-month") }>
                        <ListItemText>
                            <Localized id="mail-previous-month">Poprzedni miesiąc</Localized>
                        </ListItemText>
                    </MenuItem>
                    <Divider />
                    <MenuItem>
                        <Box display="flex" flexDirection="column" width="100%" gap={2}>
                            <ListItemText>
                                <Localized id="mail-custom-date">Własna data</Localized>
                            </ListItemText>
                            <DateTimePicker
                                label={<Localized id="system-events-from">Od</Localized>}
                                views={['year', 'day', 'hours', 'minutes']}
                                value={dateFrom}
                                slotProps={{
                                textField: {
                                    helperText: dateFromFilterError ? <InvalidDateError /> : ""
                                }
                                }}
                                onChange={(newVal) => { console.log('change'); newVal && setDateFrom(newVal); newVal && handleChange(newVal, dateTo); } }
                            />
                            <DateTimePicker
                                label={<Localized id="system-events-to">Do</Localized>}
                                views={['year', 'day', 'hours', 'minutes']}
                                value={dateTo}
                                slotProps={{
                                textField: {
                                    helperText: dateToFilterError ? <InvalidDateError /> : ""
                                }
                                }}
                                onChange={(newVal) => { newVal && setDateTo(newVal); newVal && handleChange(dateFrom, newVal); } }
                            />
                        </Box>
                    </MenuItem>
                </MenuList>
            </Menu>
        </>
    )
}