import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { load } from "../../app/crud";
import { RootState } from "../../app/store";
import { postWithAuth, putWithAuth, deleteWithAuth } from "../../http"
import { selectStructureAndParents } from "../device/structureRelationshipSlice";

export interface Requirement {
   id: AppId 
   code: string 
   name: string
   name_EN: string
   description: string
   typeId: string
   deviceTypeId: AppId | null
   structureId: AppId,
   isSystem: boolean
}

const adapter = createEntityAdapter<Requirement>({
    selectId: (requirement) => requirement.id,
})

export type RequirementState = EntityState<Requirement> & { state: AppDataState, moduleId: AppId | undefined }

const initialState: RequirementState = adapter.getInitialState({
    state: { type: "empty" },
    moduleId: undefined,
})

export const loadRequirements 
    = load<{ moduleId: AppId }, Requirement>(
        "requirements/load",
        ({ moduleId }) => `api/requirements?moduleId=${moduleId}`,
        state => selectRequirements(state).state)

export const loadSysRequirements
    = load<void, Requirement>(
        "sysrequirements/load",
        () => `api/sys/requirements`,
        state => selectRequirements(state).state,
    )

export const createRequirement = createAsyncThunk<Requirement, Requirement, AppThunkAPIType>(
    "requirements/new", 
    async (requirement: Requirement,  { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/requirements", payload: requirement }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Requirement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }       
    }
)

export const createSysRequirement = createAsyncThunk<Requirement, Requirement, AppThunkAPIType>(
    "sysrequirements/new", 
    async (requirement: Requirement,  { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/sys/requirements", payload: requirement }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Requirement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }       
    }
)

export const updateRequirement = createAsyncThunk<Requirement, Requirement, AppThunkAPIType>(
    "requirements/edit", 
    async (requirement: Requirement, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/requirements/${requirement.id}`, payload: requirement }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return requirement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSysRequirement = createAsyncThunk<Requirement, Requirement, AppThunkAPIType>(
    "sysrequirements/edit", 
    async (requirement: Requirement, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/sys/requirements/${requirement.id}`, payload: requirement }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return requirement
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteRequirement = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "requirement/delete",
    async (requirementId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/requirements/${requirementId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return requirementId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSysRequirement = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "sysrequirement/delete",
    async (requirementId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/sys/requirements/${requirementId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return requirementId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const requirementsSlice = createSlice({
    name: "requirements",
    initialState,
    reducers: {
        clearRequirementsSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
            state.moduleId = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createRequirement.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(createSysRequirement.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(updateRequirement.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<Requirement, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(updateSysRequirement.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<Requirement, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(deleteRequirement.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });
        builder.addCase(deleteSysRequirement.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });
        builder.addCase(loadRequirements.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSysRequirements.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadRequirements.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
                state.moduleId = action.meta.arg.moduleId
            }
        });
        builder.addCase(loadSysRequirements.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
                state.moduleId = undefined
            }
        });
        builder.addCase(loadRequirements.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadSysRequirements.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
    },
})

export const selectRequirements = (state: RootState): RequirementState => state.requirements

export const 
    { selectAll: selectAllRequirements
    , selectById: selectRequirementById 
    , selectTotal: selectTotalRequirements
    , selectEntities: selectRequirementsEntities
    } = adapter.getSelectors<RootState>(selectRequirements)

export const selectRequirementsForStructureNode = createSelector(
    (state) => selectAllRequirements(state),
    (state, structureId: AppId) => selectStructureAndParents(state, structureId),
    (requirements: Requirement[], structure) => requirements.filter(({ structureId }) => structure.has(structureId))
)

export default requirementsSlice.reducer;
