import { URSComment } from "./URSCommentSlice";
import { Urs } from "./UrsSlice";

// BEGIN GENERAL PREDICATES
export const isUrsAuthor = (username: string, urs: Urs): boolean => {
    return urs.createdBy === username
}

export const isDraftStatus = (urs: Urs): boolean => {
    return urs.status === 'D'
}

// TODO: add more status getters
export const isOpinionStatus = (urs: Urs): boolean => {
    return urs.status === 'O'
}

// END GENERAL PREDICATES
// BEGIN USER PERMISSIONS

export const canUserEditURS = (urs: Urs): boolean => {
    return urs.links['save'] !== undefined
}

export const canUserComment = (urs: Urs): boolean => {
    return urs.links['comment'] !== undefined
}

export const canUserResolveComment = (urs: Urs): boolean => {
    return urs.links['resolvingcomment'] !== undefined
}

export const canUserEditComment = (username: string, urs: Urs, comment: URSComment): boolean => {
    return urs.links['comment'] !== undefined && comment.createdBy === username
}

// END USER PERMISSIONS
