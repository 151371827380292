import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { useCallback, useEffect, useState } from "react"
import { Box, Button, ButtonGroup, Dialog, Fab } from "@mui/material"
import { FlexDiv } from "../../../app/Utils"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { ProjectProcessStatusLabel } from "./ProjectProcessStatusLabel"
import { unwrapResult } from "@reduxjs/toolkit"
import { ProjectProcessHeader, loadProjectProcesses } from "./ProjectProcessApiClient"
import { LoadingContainer } from "../../../app/LoadingContainer"
import { useNavigate } from "react-router-dom"
import { CreateProjectProcess } from "../Dialogs/CreateProjectProcess"
import { selectSettings } from "../../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"

export const ProjectProcessList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [processes, setProcesses] = useState<ProjectProcessHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [createProcessDialogVisible, setCreateProcessDialogVisible] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadProjectProcesses())
            .then(unwrapResult)
            .then(setProcesses)
            .catch((error) => console.error(error))
            .finally(() => setIsLoading(false));
    }, [])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const openCreateProcessDialog = useCallback(() => {
        setCreateProcessDialogVisible(true)
    }, [setCreateProcessDialogVisible])

    const handleHideDialog = useCallback(() => setCreateProcessDialogVisible(false), [])

    const onSuccess = useCallback((proc: ProjectProcessHeader) => {
        navigate(`/projectprocess/edit/${proc.id}`);
    }, [dispatch])

    const handleGoToClick = (id) => {
        navigate(`/projectprocess/edit/${id}`)
    };

    const processColumns: GridColDef[] = [
        {
            field: 'name',
            headerName: l10n.getString('project-process', null, 'Proces'),
            flex: 1
        },
        {
            field: 'createDate',
            headerName: l10n.getString('project-create-date', null, 'Data Utworzenia'),
            width: 200,
            valueGetter: (_, row) => {
                return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
            }
        },
        {
            field: 'status',
            headerName: l10n.getString('project-status', null, 'Status'),
            width: 150,
            renderCell: (params) => (
                <div>
                    <ProjectProcessStatusLabel status={params.row.status ?? ""} />
                </div>
            )
        },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleGoToClick(params.id)} color="secondary" variant="outlined">
                            <Localized id="go-to">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ];

    if (isLoading){
        return <>
            <LoadingContainer/>
        </>
    }
    
    return (
        <>
        <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 200px)' }} localeText={getCustomLocaleText()} rows={processes} columns={processColumns} onRowDoubleClick={({ row }) => handleGoToClick(row.id)} />
                </div>
            </div>
            <FlexDiv>
                <Fab onClick={() => openCreateProcessDialog()} color="secondary" sx={{marginTop:2}}>
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </Box>
        <Dialog open={createProcessDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
            <CreateProjectProcess
                projectId={null}
                no={""}
                name={""}
                onSucces={onSuccess}
                onCancel={handleHideDialog}
            />
        </Dialog>
        </>
    )
}
