import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { getWithAuth, postWithAuth, putWithAuth } from "../../http";

export type CompanyToRegisterId = string;

export interface CompanyToRegister {
    id: CompanyToRegisterId,
    companyName: string,
    shortName: string,
    isProducer: boolean,
    isCustomer: boolean,
    address: string,
    address2: string,
    city: string,
    state: string,
    zipcode: string,
    country: string,
    firstName: string,
    lastName: string,
    contactPerson: string,
    email: string,
    phone: string,
    mobile: string,
    areas: Array<{areaId: AppId }>,
    objectType: string | null;
    objectId: string | null;
}

export interface ContactPerson {
    firstName: string,
    lastName: string,
    email: string,
    phone: string
}

export type DraftCompany = Omit<CompanyToRegister, "id">

export const loadCompaniesToRegister = createAsyncThunk<CompanyToRegister[], void, AppThunkAPIType>(
    "companies-to-register/load",
    async (_, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/companies-to-register` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanyToRegister[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const loadCompanyToRegister = createAsyncThunk<CompanyToRegister, CompanyToRegisterId, AppThunkAPIType>(
    "company-to-register/load",
    async (companyId, { dispatch, rejectWithValue, requestId, getState }) => {

        const result = await dispatch(getWithAuth({ url: `api/companies-to-register/${companyId}` }))
        const { payload } = result
                if (getWithAuth.fulfilled.match(result)) {
            return payload as CompanyToRegister
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)