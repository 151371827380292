import { createAsyncThunk } from "@reduxjs/toolkit";
import { AppThunkAPIType, Problem } from "./app/appTypes";
import { invalidateToken, selectBearerToken } from "./features/user/userSlice";

export async function post(url: string, payload: object, headers: object = {}) {
    const response = await fetch(process.env.REACT_APP_REST_API + url, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            'Content-Type': "application/json",
            ...headers,
        },
        redirect: "manual",

        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
    });

    return response
}

export const postWithAuth = createAsyncThunk<any, { url: string, payload: object, }, AppThunkAPIType>(
    "http/postWithAuth", 
    async ({ url, payload }, { getState, dispatch, rejectWithValue }) => {
        const authHeader = selectBearerToken(getState())
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': "application/json",
                    ...authHeader,
                },
                redirect: "manual",

                referrerPolicy: "no-referrer",
                body: JSON.stringify(payload),
            })
            if (response.ok) {
                return await response.json()
            } else {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken())
                        break
                    default:
                        break
                }

                const mimeType = response.headers.get('content-type')

                if (mimeType?.startsWith('application/problem+json')) {
                    const problem = await response.json() as Problem

                    return rejectWithValue({
                        kind: 'http',
                        status: response.status,
                        problem,
                    })
                }

                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                })
            }
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            })
        }
    }
)


export const getWithAuth = createAsyncThunk<any, { url: string }, AppThunkAPIType>(
    "http/getWithAuth", 
    async ({ url }, { getState, dispatch, rejectWithValue }) => {
        const authHeader = selectBearerToken(getState())
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': "application/json",
                    ...authHeader,
                },
                redirect: "manual",

                referrerPolicy: "no-referrer",
            })
            if (response.ok) {
                return await response.json()
            } else {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken())
                        //dispatch(showError("session-expired"))
                        break
                    default:
                        break
                }
                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                })
            }
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            })
        }
    }
)


export async function get(url: string, headers: object = {}) {
    const response = await fetch(process.env.REACT_APP_REST_API + url, {
        method: "GET",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            'Content-Type': "application/json",
            ...headers,     
        },
        redirect: "manual",

        referrerPolicy: "no-referrer",
    })

    return response
}

export async function put(url: string, payload: object, headers: object = {}) {
    const response = await fetch(process.env.REACT_APP_REST_API + url, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            'Content-Type': "application/json",
            ...headers,
        },
        redirect: "manual",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload)
    })

    return response
}

export const putWithAuth = createAsyncThunk<any, { url: string, payload: object }, AppThunkAPIType>(
    "http/putWithAuth", 
    async ({ url, payload }, { rejectWithValue, dispatch, getState }) => {
        const authHeader = selectBearerToken(getState())
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "PUT",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': "application/json",
                    ...authHeader,
                },
                redirect: "manual",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(payload)
            })
            if (response.ok) {
                try {
                    return await response.json()
                } catch (error) {
                    // in case of no body
                    return {}
                }
            } else {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken())
                        //dispatch(showError("session-expired"))
                        break
                    default:
                        break
                }
                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                })
            }
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            })
        }
    }
)

export async function del(url: string, headers: object = {}) {
    const response = await fetch(process.env.REACT_APP_REST_API + url, {
        method: "DELETE",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
            'Content-Type': "application/json",
            ...headers,
        },
        redirect: "manual",
        referrerPolicy: "no-referrer",
    })

    return response
}

export const deleteWithAuth = createAsyncThunk<any, { url: string }, AppThunkAPIType>(
    "http/deleteWithAuth", 
    async ({ url }, { getState, rejectWithValue, dispatch }) => {
        const authHeader = selectBearerToken(getState())
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "DELETE",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': "application/json",
                    ...authHeader,
                },
                redirect: "manual",
                referrerPolicy: "no-referrer",
            })
            if (!response.ok) {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken())
                        //dispatch(showError("session-expired"))
                        break
                    default:
                        break
                }
                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                })
            }
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            })
        }
    }
) 

export const deleteWithAuthAndBody = createAsyncThunk<any, { url: string }, AppThunkAPIType>(
    "http/deleteWithAuth", 
    async ({ url }, { getState, rejectWithValue, dispatch }) => {
        const authHeader = selectBearerToken(getState())
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "DELETE",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': "application/json",
                    ...authHeader,
                },
                redirect: "manual",
                referrerPolicy: "no-referrer",
            })
            if (response.ok) {
                return await response.json()
            } else {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken())
                        //dispatch(showError("session-expired"))
                        break
                    default:
                        break
                }
                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                })
            }
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            })
        }
    }
) 

export interface BlobResponse {
    blobUrl: string;
    fileName: string;
    fileType: string;
  }

export const getBlobWithAuth = createAsyncThunk<BlobResponse, { url: string }, AppThunkAPIType>(
    "http/getblobwithauth",
    async ({ url }, { getState, dispatch, rejectWithValue }) => {
        const authHeader = selectBearerToken(getState());
        try {
            const response = await fetch(process.env.REACT_APP_REST_API + url, {
                method: "GET",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    'Content-Type': 'application/octet-stream',
                    ...authHeader,
                },
                redirect: "manual",
                referrerPolicy: "no-referrer",
            });

            if (!response.ok) {
                switch (response.status) {
                    case 401:
                        dispatch(invalidateToken());
                        //dispatch(showError("session-expired"))
                        break;
                    default:
                        break;
                }
                return rejectWithValue({
                    kind: 'http',
                    status: response.status,
                });
            }

            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);

            const contentDisposition = response.headers.get('Content-Disposition');

            let fileName = 'unknown';
            const fileType = blob.type;
      
            if (contentDisposition) {
                // Szukamy fragmentu zawierającego "filename="
                const dispositionParts = contentDisposition.split(';');
                for (const part of dispositionParts) {
                  const trimmedPart = part.trim();
                  if (trimmedPart.startsWith('filename=')) {
                    fileName = trimmedPart.substring('filename='.length).replace(/^"|"$/g, '');
                    break;
                  }
                }
              }
           
            return { blobUrl, fileName, fileType };
        } catch (error) {
            return rejectWithValue({
                kind: "connection",
            });
        }
    }
);

