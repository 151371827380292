import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../../app/appTypes";
import { isSameRequestId, load } from "../../../app/crud";
import { RootState } from "../../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";
import { logout } from "../../user/userSlice";

export interface MailMapping {
   id: number, 
   mail: string, 
   redirectToMail: string
}

const adapter = createEntityAdapter<MailMapping>({
    selectId: (mailmapping) => mailmapping.id,
})


export type DraftMailMapping = Omit<MailMapping, "id">

export type MailMappingState = EntityState<MailMapping> & { loaded: boolean }

const initialState: MailMappingState = adapter.getInitialState({ loaded: false })


export const loadMailMappings = createAsyncThunk<MailMapping[], void, AppThunkAPIType>("mailMappings/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/test-mail-mapping"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as MailMapping[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createMapping = createAsyncThunk<MailMapping, MailMapping, AppThunkAPIType>
    ("mailMappings/create", async (company: MailMapping, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/test-mail-mapping",
            payload: company,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as MailMapping
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateMapping = createAsyncThunk<MailMapping, MailMapping, AppThunkAPIType>(
    "mailMappings/update", 
    async (company: MailMapping, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/test-mail-mapping/${company.id}`,
            payload: company,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return company as MailMapping
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteMapping = createAsyncThunk<number, number, AppThunkAPIType>(
    "mailMappings/delete",
    async (mappingId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/test-mail-mapping/${mappingId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return mappingId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
export const mailMappingsSlice = createSlice({
    name: "mailMappings",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createMapping.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteMapping.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateMapping.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<MailMapping, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        builder.addCase(loadMailMappings.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })
    }
})

export const selectMailMappings = (state: RootState): MailMappingState => state.mailMappings

export const 
    { selectAll: selectAllMailMappings
    , selectById: selectMailMappingById 
    , selectTotal: selectTotalMailMappings
    } = adapter.getSelectors<RootState>(selectMailMappings)

export default mailMappingsSlice.reducer;
