import {Localized} from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@mui/material"
import { useState } from "react"
import {AppId} from "../../../app/appTypes"
import {useAppDispatch} from "../../../app/hooks"
import {deleteURSRequirement, URSRequirement} from "../UrsSlice"

export interface DeleteRequirementDialogProps {
    entity        : URSRequirement
    onSuccess : () => void
    onCancel  : () => void
    typeId    : AppId
    group     : string
}
export const DeleteRequirementDialog = ({ entity, onSuccess, onCancel, typeId, group }: DeleteRequirementDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const handleConfirmDelClick = async () => {
        setSaving(true)
        dispatch(deleteURSRequirement(entity)).unwrap().catch(e => console.log(e)).then(() => setSaving(false)).finally(() => onSuccess())
    }
    const handleCancelDelClick = () => {
        onCancel()
    }

    return <Dialog open={true}>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmDelClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={handleCancelDelClick}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
