import SaveIcon from '@mui/icons-material/Save'
import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import { CircularProgress, IconButton, InputAdornment } from "@mui/material"
import { useAppDispatch, useAppSelector, useQueryStructureId } from "../../app/hooks"
import Paper from "@mui/material/Paper"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { Grid, MenuItem } from '@mui/material'
import { createValidation, ValidationStage } from './validationsSlice'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { AppId } from '../../app/appTypes'
import { AppLoader } from '../../app/AppLoader'
import { AppRequiredMessage } from '../../app/AppRequiredMessage'
import { Skeleton } from '@mui/material'
import { DeviceTypeCombobox } from '../device/DeviceTypeCombobox'
import { selectModule } from '../modules/moduleSlice'
import { CreateSysValidation } from './CreateSysValidation'
import { LoadDeviceTypes, LoadValidations } from '../../app/AppDataLoader'
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'
import { postWithAuth } from "../../http"
import { If } from "../../app/If"
import { updateURSRequirement, URSRequirement } from '../urs/UrsSlice'
import { useLocation, useNavigate } from 'react-router-dom'
import { RiskAnalysisFunction, updateFunction } from '../documents/riskAnalysis/riskAnalysisApi'

const PREFIX = 'CreateValidation';

const classes = {
    root: `${PREFIX}-root`,
    flex: `${PREFIX}-flex`,
    paper: `${PREFIX}-paper`,
    field: `${PREFIX}-field`,
    stage: `${PREFIX}-stage`,
    deviceTypes: `${PREFIX}-deviceTypes`
};

export const CreateValidation = () => {
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    if (currentModule === null) return null

    switch (currentModule.code) {
        case "super":
            return <CreateSysValidation />
        case "admin":
        case "validation":
            return <CreateCustomValidation />
        default:
            return null
    }
}

const CreateCustomValidation = () => {
    return <LoadDeviceTypes component={<Skeleton variant="rectangular" animation="wave" height="75vh" />}>
        <LoadValidations component={<Skeleton variant="rectangular" animation="wave" height="75vh" />}>
            <Form />
        </LoadValidations>
    </LoadDeviceTypes>
}

const Form = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const structureId = useQueryStructureId()
    const { state } = useLocation()

    const ursRequirement : URSRequirement | undefined = state && state[`ursRequirement`] ? state[`ursRequirement`] as URSRequirement : undefined
    const riskAnalysisFn : RiskAnalysisFunction | undefined = state && state[`function`] ? state[`function`] as RiskAnalysisFunction : undefined
    const docId : AppId | undefined = state && state['docId'] ? state['docId'] as AppId : undefined
    const [name, setName] = useState(() => {
        if (state && state[`defaultName`]) {
            return state[`defaultName`]
        } else {
            return ""
        }
    })
    const [description, setDescription] = useState("")
    const [stage, setStage] = useState<ValidationStage>("IQ")
    const [deviceTypeId, setDeviceTypeId] = useState<AppId | undefined>(undefined)
    const [id, setId] = useState<AppId | undefined>(undefined)
    const [validationErrors, setValidationErrors] = useState(false)
    const [saving, setSaving] = useState(false)
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);    

    useEffect(() => {
        console.log(`Doc id: ${docId}`)
        console.log(riskAnalysisFn)
    }, [])

    const handleSaveClick = async () => {
        setValidationErrors(true)
        if (name) {
            setSaving(true)
            try {
                const result = await dispatch(createValidation({ 
                    name, 
                    description, 
                    stage, 
                    structureId: deviceTypeId ?? "",
                    isSystem: false,
                }));
                if (createValidation.fulfilled.match(result)) {
                    const { id: newId } = result.payload;
                    if (ursRequirement) {
                        await dispatch(updateURSRequirement({...ursRequirement, testDefinitionId: newId, }))
                    }
                    if (docId && riskAnalysisFn) {
                        const { defects, ...data } = riskAnalysisFn
                        await dispatch(updateFunction({
                            ...data,
                            docId,
                            testDefinitionId: newId,
                            isTestable: true,
                        }))
                    }
                    dispatch(showSuccess("saved"))
                    const editURL = structureId ? `/validation/edit/${newId}?structureId=${structureId}` : `/validation/edit/${newId}`
                    navigate(editURL, { replace: true })
                }
                else{
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                            dispatch(showError(errorMsg))
                        }
                    }
                }
               
            } catch (error) {
                
                dispatch(showError("error"))
            }
            setSaving(false)
        }
    }

    const handleGenerateDescriptionClick = async () => {
        setWaitingForAnswer(true)
        try{
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask`,
                payload: {
                    Type : 'test-for-requirement',
                    Question: name
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setDescription(payload.answer)
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setName(e.target.value), [setName]);
    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setDescription(e.target.value), [setDescription]);
    const handleStageChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setStage(e.target.value as ValidationStage), [setStage]);

    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const nameError = validationErrors && name === ""
    const deviceError = validationErrors && deviceTypeId === undefined

    return <>
        <Container component={Paper} maxWidth="lg">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        error={nameError}
                        helperText={nameError && <AppRequiredMessage />}
                        value={name}
                        inputProps={{ maxLength: 200 }}
                        multiline={true}
                        onChange={handleNameChange}
                        label={<Localized id="validation-name">Nazwa</Localized>}
                    />

                </Grid>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        className={classes.field}
                        value={description}
                        onChange={handleDescriptionChange}
                        multiline={true}
                        inputProps={{ maxLength: 1000 }}
                        InputProps={{ 
                            endAdornment: (
                                <InputAdornment position="end">
                                    <If condition={!waitingForAnswer} otherwise={
                                                <CircularProgress />
                                                }>
                                        <IconButton onClick={handleGenerateDescriptionClick}>
                                            <AutoFixHighIcon />
                                        </IconButton>
                                    </If>
                                </InputAdornment>
                              )
                        
                        }}
                        minRows={2}
                        maxRows={16}
                        label={<Localized id="validation-description">Opis</Localized>}
                    />

                </Grid>
                <Grid item xs={2}>
                    <TextField 
                        fullWidth
                        select
                        value={stage}
                        onChange={handleStageChange}
                        label={<Localized id="validation-stage">Etap</Localized>} >
                            {["IQ", "OQ", "DQ", "PQ"].map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)}
                    </TextField>

                </Grid>
                <Grid item xs={10}>
                    <DeviceTypeCombobox 
                        fullWidth
                        onlyLeafs={false}
                        error={deviceError}
                        value={deviceTypeId ?? ""}
                        onChange={setDeviceTypeId}
                    />
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup sx={{
                        marginBottom: 2,
                    }} color="secondary" variant="contained">
                        <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                        </Button>
                        <Button onClick={handleBackClick} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>

                </Grid>
            </Grid>
        </Container> 
        <AppLoader open={saving} />
    </>
}
