import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { LoadingContainer } from "../../app/LoadingContainer"
import { ErrorContainer } from "../../app/ErrorContainer"
import { loadMeasurement, Measurement } from "./measurementsApi"
import { unwrapResult } from "@reduxjs/toolkit";
import { useAppDispatch } from "../../app/hooks"
import { AppBar, Button, Container, Grid, Tab } from "@mui/material"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { MeasurementFormHeader } from "./MeasurementFormHeader"
import { Localized, useLocalization } from "@fluent/react"
import { ImportSolairDataDialog } from "./Utils/ImportSolairDataDialog"
import { DataGrid } from "@mui/x-data-grid"
import { NotesList } from "../project/Notes/NotesList"
import { LocationMappingDialog } from "./Utils/LocationMappingDialog"
import { AddEditMeasurementDialog } from "./AddEditMeasurementDialog"

type FormTabs = "entries" | "attachments" | "team"

export const MeasurementForm = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''

    const [measurement, setMeasurement] = useState<Measurement>();
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const dispatch = useAppDispatch()

    useEffect(() => {
        setIsLoading(true)
        dispatch(loadMeasurement(id))
            .then(unwrapResult)
            .then(setMeasurement)
            .catch((error) => { console.error(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false))
    }, [])

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (measurement) {
        return <MeasurementFormRoot data={measurement} />
    }

    return <>
    </>
}

export interface MeasurementFormRootProps {
    data: Measurement
}
export const MeasurementFormRoot = ({ data }: MeasurementFormRootProps) => {
    const [measurement, setMeasurement] = useState<Measurement>(data);
    const [tab, setTab] = useState<FormTabs>("entries");
    const [dialogVisible, setDialogVisible] = useState<boolean>(false)
    const [editDialogVisible, setEditDialogVisible] = useState<boolean>(false)
    const [locationNameOpen, setLocationNameOpen] = useState<boolean>(false)
    const { l10n } = useLocalization()

    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const onSucces = (measurement: Measurement) => {
        setDialogVisible(false)
        setEditDialogVisible(false)
        setLocationNameOpen(false)
        setMeasurement(measurement)
    };

    const onCancel = () => {
        setDialogVisible(false)
        setEditDialogVisible(false)
        setLocationNameOpen(false)
    };

    const handleAddClick = useCallback(() => {
        setDialogVisible(true)
    }, [])

    const handleUpdateLocationNameOpen = useCallback(() => {
        setLocationNameOpen(true)
    }, [])

    const handleMainEditClick = useCallback(() => {
        setEditDialogVisible(true)
    }, [])


    const handleLocationSave = async (measurement: Measurement) => {
        setDialogVisible(false)
        setEditDialogVisible(false)
        setLocationNameOpen(false)        
        setMeasurement(measurement)
    };

    const columns = [
        {
            field: 'timestamp',
            headerName: l10n.getString('measurement-timestamp', null, '_Timestamp'),
            width: 200,
            valueFormatter: (value) => {
                if (value) {
                    const [date, time] = value.split('T');
                    return `${date} ${time}`;
                }
                return 'Invalid Date';

            }
        },
        { field: 'locationId', headerName: l10n.getString('measurement-locationId', null, '_Location ID'), width: 150 },
        { field: 'locationName', headerName: l10n.getString('measurement-locationName', null, '_Location Name'), width: 200 },
        { field: 'sampleTime', headerName: l10n.getString('measurement-sampleTime', null, '_Sample Time'), width: 150 },
        { field: 'volume', headerName: l10n.getString('measurement-volume', null, '_Volume'), width: 150 },
        { field: 'count', headerName: l10n.getString('measurement-count', null, '_Count'), width: 100 },
        { field: 'alarm', headerName: l10n.getString('measurement-alarm', null, '_Alarm'), width: 150 },
        { field: 'flow', headerName: l10n.getString('measurement-flow', null, '_Flow'), width: 150 },
        { field: 'laser', headerName: l10n.getString('measurement-laser', null, '_Laser'), width: 150 },
        { field: 'service', headerName: l10n.getString('measurement-service', null, '_Service'), width: 150 },
    ];

    return <>
        <Container maxWidth={false} sx={{ padding: "2px", paddingLeft: "2px!important", paddingRight: "2px!important" }}>
            <MeasurementFormHeader measurement={measurement} />
            <Grid container >
                <Grid item xs={12}>
                    <TabContext value={tab}>
                        <TabList onChange={handleTabChange}>
                            <Tab
                                key={0}
                                value="entries"
                                label={<Localized id="measurement-entries">Pomiary</Localized>} />
                            <Tab
                                key={1}
                                value="attachments"
                                label={<Localized id="attachments">Załączniki</Localized>} />
                            <Tab
                                key={2}
                                value={"notes"}
                                label={<Localized id="scratchpad">Zapiski</Localized>} />
                        </TabList>
                        <TabPanel value="entries">
                            <DataGrid
                                style={{ minHeight: 100, height: 'calc(100vh - 400px)' }}
                                rows={measurement.solairEntries}
                                columns={columns}
                            />
                        </TabPanel>
                        <TabPanel value={"attachments"}>

                        </TabPanel>
                        <TabPanel value="notes">
                            <NotesList
                                objectId={measurement.guid} canEdit={true}
                            />
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Grid>
            <AppBar position="fixed" color="primary" sx={{ top: 'auto', bottom: 0, p: 0.5 }}>
                <div className="flex justify-end">
                    <Button onClick={handleAddClick} color="primary" size="large" variant="contained">
                        <Localized id="">
                            <span>Importuj z csv</span>
                        </Localized>
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleUpdateLocationNameOpen}>
                        Przypisz lokacje
                    </Button>
                    <Button color="primary" variant="contained" onClick={handleMainEditClick}>
                        Edytuj
                    </Button>
                    <Button variant='contained' color="primary" size="small"><Localized id='back'>Wróć</Localized></Button>
                </div>
            </AppBar>

            {
                locationNameOpen &&
                <LocationMappingDialog measurement={measurement} onSuccessfulSave={handleLocationSave} onClose={onCancel} />
            }

            {
                dialogVisible &&
                <ImportSolairDataDialog measureId={measurement.id} onSucces={onSucces} onCancel={onCancel} />
            }
            {
                editDialogVisible &&
                <AddEditMeasurementDialog measurement={measurement} mode={"EDIT"} onSucces={onSucces} onCancel={onCancel}  />
            }
        </Container>

    </>
}