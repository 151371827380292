import { useCallback, useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { AppId } from './appTypes';
import type { RootState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}

export const useQueryStructureId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("structureId")) {
        return query.get("structureId") ?? undefined
    } else {
        return undefined
    }
}

export const useQueryId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("id")) {
        return query.get("id") ?? undefined
    } else {
        return undefined
    }
}

export const useQueryProjectId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("projectId")) {
        return query.get("projectId") ?? undefined
    } else {
        return undefined
    }
}

export const useQueryBusinessCaseId = (): AppId | undefined => {
    const query = useQuery()

    if (query.has("businessCaseId")) {
        return query.get("businessCaseId") ?? undefined
    } else {
        return undefined
    }
}
