import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks"
import { updateDescriptionField } from "../UrsSlice"

const Label = ({ fieldName }: { fieldName: "description" | "area" | "purpose" | "title" }) => {
    switch (fieldName) {
        case "description": return <Localized id='urs-description'>Opis</Localized>
        case "area": return <Localized id='urs-area'>Obszar</Localized>
        case "purpose": return <Localized id='urs-purpose'>Cel</Localized>
        case "title": return <Localized id='urs-title'>Tytuł</Localized>
    }
}

export interface EditDescriptionFieldProps {
    ursId: AppId
    fieldName: "description" | "area" | "purpose" | "title"
    value: string
    onSuccess : () => void
    onCancel : () => void
}

export const EditSimpleDescriptionField = (props: EditDescriptionFieldProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState(props.value)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateDescriptionField({
            ursId: props.ursId,
            fieldName: props.fieldName,
            value
        }))
        .then(unwrapResult)
        .then(() => { props.onSuccess() })
        .catch(err => {
            console.error(err)
        })
        .finally(() => {
            setSaving(false)
        })
    }
    
    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <TextField
                sx={{ mt: 2 }}
                fullWidth
                value={value}
                onChange={e => setValue(e.target.value)}
                label={<Label fieldName={props.fieldName} />}
            />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}><Localized id='ok'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
