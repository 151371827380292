import { Localized, useLocalization } from '@fluent/react'
import TableContainer from '@mui/material/TableContainer'
import Paper from '@mui/material/Paper'
import { useAppSelector } from '../../app/hooks'
import { selectSettings } from '../settings/settingsSlice'
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { Button, ButtonGroup } from '@mui/material'
import { Link } from 'react-router-dom'

export const SupplierBudgetOfferList = () => {
    const { l10n } = useLocalization()
    const { locale } = useAppSelector(selectSettings);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'Numer oferty', width: 150 },
        { field: 'client', headerName: 'Klient', width: 200 },
        { field: 'generationDate', headerName: 'Data wygenerowania', width: 200 },
        { field: 'sentDate', headerName: 'Data wysłania oferty', width: 200 },
        { field: 'preparedBy', headerName: 'Osoba przygotowująca', width: 200 },
        {
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button component={Link} to={`/supplierBudgetOffer/edit/${params.row.id}`} color="secondary" variant="outlined">
                            <Localized id="edit">
                                <span>Edytuj</span>
                            </Localized>
                        </Button>

                    </ButtonGroup>
                )
            }
        }
    ];

    const rows = [
        { id: 1, client: 'Firma ABC', generationDate: '2024-02-25', sentDate: '2024-02-26', preparedBy: 'Jan Kowalski' },
        { id: 2, client: 'Firma XYZ', generationDate: '2024-02-24', sentDate: '2024-02-25', preparedBy: 'Anna Nowak' },
    ];

    return <>

        <TableContainer component={Paper}>
            <div style={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    autoHeight
                    columns={columns}
                />
            </div>
        </TableContainer>

    </>
}
