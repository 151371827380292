import { Localized } from "@fluent/react"
import SaveIcon from '@mui/icons-material/Save'
import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import Button from '@mui/material/Button'
import ButtonGroup from '@mui/material/ButtonGroup'
import Container from '@mui/material/Container'
import Paper from "@mui/material/Paper"
import TextField from "@mui/material/TextField"
import { unwrapResult } from "@reduxjs/toolkit"
import { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"
import { LoadRequirements, LoadRequirementsTypes, LoadURSTemplates } from '../../app/AppDataLoader'
import { AppLoader } from "../../app/AppLoader"
import { AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { DeviceTypeCombobox } from '../device/DeviceTypeCombobox'
import { selectModule } from "../modules/moduleSlice"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { selectUserCompanyId } from "../user/userSlice"
import { createSysUrsTemplate, createUrsTemplate, selectAllUrsTemplates, UrsTemplate } from './ursTemplatesSlice'
import { If } from "../../app/If"
import { ArrowDropDownIcon } from "@mui/x-date-pickers"
import { StructureTableCell } from "../device/Structure"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>
type UrsNumberingMode = "ONLY_SIMPLE_REQ" | "UNIQUE_REQ" | "DECIMAL_HIERARCHICAL"
type CreateFromOptions = "Empty" | "Template" | "Catalog"

export const UrsTemplateForm = () => {
    return <LoadRequirementsTypes component={<Skeleton height='50vh' />}>
        <LoadRequirements component={<Skeleton height='50vh' />}>
            <LoadURSTemplates component={<Skeleton height='50vh' />}>
                <UrsTemplateFormInternal />
            </LoadURSTemplates>
        </LoadRequirements>
    </LoadRequirementsTypes>
}

export const UrsTemplateFormInternal = () => {    
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const module = useAppSelector(state => selectModule(state).currentModule?.code)
    const companyId = useAppSelector(selectUserCompanyId)
    const urlPrefix = module === "super" ? "/sys" : ""
    const isSuperModule = module === "super";
              
    const [ursNo, setUrsNo] = useState<string>("");    
    const [structureId, setStructureId] = useState<string>("");    
    const [docDesignation, setDocDesignation] = useState<string>("");    
    const [language, setLanguage] = useState("PL")
    const [numberingMode, setNumberingMode] = useState<UrsNumberingMode>("DECIMAL_HIERARCHICAL");

    const [validationErrors, setValidationErrors] = useState(false)
    const [saving, setSaving] = useState(false)
    const [createMode, setCreateMode] = useState<CreateFromOptions>("Empty");
    const [selectedTemplate, setSelectedTemplate] = useState<UrsTemplate | undefined>(undefined);
    const [templateDialogVisible, setTemplateDialogVisible] = useState<boolean>(false);
    const allURSTemplates = useAppSelector(selectAllUrsTemplates)

    const handleDocDesignationChange: AppTextFieldHandler = useCallback(e => setDocDesignation(e.target.value), [setDocDesignation])
    const handleStructureIdChange  = useCallback(structureId => setStructureId(structureId), [setStructureId])

    const handleCancelUrsTemplateClick = useCallback(() => {
        setTemplateDialogVisible(false)
    }, [])

    const handleNumberingModeChange = (value) => {
        setNumberingMode(value);
    };
    const handleChooseTemplateClick = useCallback((template: UrsTemplate) => {
        setSelectedTemplate(template)
        setTemplateDialogVisible(false)
    }, [])

    const handleLanguageChange = (value) => {
        setLanguage(value);
    };
 
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)
      
        if ( structureId ){
                
            try {
                let actionResult; 
                if ( isSuperModule ){
                    actionResult = await dispatch(createSysUrsTemplate({      
                        createMode: createMode,
                        templateId : selectedTemplate?.id,   
                        documentNo: ursNo,
                        structureId : structureId,
                        docDesignation  : docDesignation,
                        language: language,
                        numberingMode: numberingMode,
                    }));
                    if (!createSysUrsTemplate.fulfilled.match(actionResult)) {
                        let errorMsg = "error"
                        if (actionResult.payload) {
                            if (actionResult.payload.kind === 'http') {
                                const problem = actionResult.payload.problem
                                if (problem) {
                                    errorMsg = problem.title
                                }
                                dispatch(showError(errorMsg))
                            }
                        }
                        setSaving(false)
                        return;
                    }
                }
                else{
                    actionResult = await dispatch(createUrsTemplate({     
                        createMode: createMode,
                        templateId : selectedTemplate?.id,   
                        documentNo: ursNo,
                        structureId : structureId,
                        docDesignation  : docDesignation,
                        language: language,
                        numberingMode: numberingMode,
                    }));
                    if (!createUrsTemplate.fulfilled.match(actionResult)) {
                        let errorMsg = "error"
                        if (actionResult.payload) {
                            if (actionResult.payload.kind === 'http') {
                                const problem = actionResult.payload.problem
                                if (problem) {
                                    errorMsg = problem.title
                                }
                                dispatch(showError(errorMsg))
                            }
                        }
                        setSaving(false)    
                        return;
                    }
                }
                
                const { id: newId } = unwrapResult(actionResult);

                dispatch(showSuccess("saved"));
                
                navigate(`${urlPrefix}/urstemplates/edit/${newId}?structureId=${structureId}`, { replace: true });
                
            } catch (error) {
                dispatch(showError("error"));
            }
        }
        setSaving(false)
    }

    const hadleEmptyClick = () => {
        setCreateMode('Empty')
    }

    const handleTemplateClick = () => {
        setCreateMode('Template')
    }

    const handleCatalogClick = () => {
        setCreateMode('Catalog')
    }
    
    const handleGoBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const deviceTypeError = validationErrors && structureId === ""

    return <>
        <Container component={Paper} maxWidth={false} sx={{paddingTop:2}}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <DeviceTypeCombobox
                        onlyLeafs={true}
                        fullWidth
                        error={deviceTypeError}
                        helperText={deviceTypeError && <RequiredError />}                        
                        value={structureId}  
                        onChange={handleStructureIdChange} 
                        label={<Localized id="urs-device-type">Typ</Localized>}
                    />
                </Grid>                         
                <Grid item xs={12}>
                    <TextField                                                
                        value={docDesignation}
                        fullWidth
                        inputProps={{ maxLength: 1000 }}
                        multiline                   
                        minRows={2}
                        maxRows={12}
                        onChange={handleDocDesignationChange}
                        label={
                            <Localized id="urs-template-description">
                                <span>Opis</span>
                            </Localized>
                        }
                    />
                </Grid> 
                <Grid item xs={12}>
                    <FormControl sx={{ width: '10%' }} >
                        <InputLabel id="app-language-label"><Localized id='document-language'>Język</Localized></InputLabel>
                        <Select
                            labelId="app-language-label"
                            id="app-language"
                            value={language}
                            onChange={e => handleLanguageChange(e.target.value)}
                            label={<Localized id='document-language'>Język</Localized>}
                            fullWidth
                        >
                            <MenuItem value={'PL'}>PL</MenuItem>
                            <MenuItem value={'EN'}>EN</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        value={numberingMode}
                        sx={{ width: '20%' }}
                        select
                        label={ <Localized id="">Sposób numerowania</Localized>}
                        onChange={e => handleNumberingModeChange(e.target.value)}
                    >

                        <MenuItem value={"DECIMAL_HIERARCHICAL"}><Localized id=""><span>Hierarchiczne</span></Localized></MenuItem>
                        <MenuItem value={"UNIQUE_REQ"}><Localized id=""><span>Unikalne</span></Localized></MenuItem>
                        <MenuItem value={"ONLY_SIMPLE_REQ"}><Localized id=""><span>Proste</span></Localized></MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={2}>
                    <TextField
                        fullWidth={true}
                        InputLabelProps={{
                            shrink: true,

                        }}
                        InputProps={{
                            readOnly: true,
                            startAdornment: <InputAdornment position="start"
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <Button
                                    size="small"
                                    color={createMode === 'Empty' ? "secondary" : "primary"}
                                    onClick={() => hadleEmptyClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="urs-create-empty">Pusty</Localized>
                                </Button>
                                <Button
                                    size="small"
                                    color={createMode === 'Template' ? "secondary" : "primary"}
                                    onClick={() => handleTemplateClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="urs-create-template">Szablon</Localized>
                                </Button>
                                <Button
                                    size="small"
                                    color={createMode === 'Catalog' ? "secondary" : "primary"}
                                    onClick={() => handleCatalogClick()}
                                    style={{ marginRight: '10px' }}
                                >
                                    <Localized id="urs-create-catalog">Catalog</Localized>
                                </Button>
                            </InputAdornment>
                        }}
                        label={ <Localized id="urs-create-question">Jak wygenerować Szablon URS?</Localized>
                        }
                    >

                    </TextField>
                </Grid>
                <If condition={createMode == "Template"}>
                    <Grid item xs={6}>
                        <LoadURSTemplates component={<Skeleton />}>
                            <TextField
                                label={<Localized id="urs-template"><span>Szablon URS</span></Localized>}
                                value={selectedTemplate?.ursNo}
                                fullWidth
                                required
                                InputLabelProps={{
                                    shrink: true
                                }}
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: <InputAdornment position="end"
                                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                        <IconButton
                                            onClick={() => setTemplateDialogVisible(true)}
                                        >
                                            <ArrowDropDownIcon />
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            >
                            </TextField>
                        </LoadURSTemplates>
                    </Grid>
                </If>

                   
            </Grid> 
                <Box sx={{paddingBottom:2}}>
                    <ButtonGroup sx={{marginTop:4}} color="secondary" variant="contained">
                        <Button startIcon={<SaveIcon />} 
                                onClick={handleSaveClick}>
                            <Localized id="save">Zapisz</Localized>
                        </Button>
                        <Button onClick={handleGoBackClick} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </Box>
            </Container>
        <AppLoader open={saving} />

        <Dialog open={templateDialogVisible} fullWidth maxWidth="md">
                <DialogTitle>
                    <Localized id="urs-template">
                        <span>Szablon URS</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>

                    <TableContainer
                        component={Paper}
                        sx={{ display: 'flex', height: 'calc(100vh - 440px)' }}
                    >
                        <Table stickyHeader size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-template-code">
                                                <span>Kod</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell >
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-template-description">
                                                <span>Opis</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="urs-device-type">
                                                <span>Nazwa typu rządzenia</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                        <Typography variant="subtitle2" color="textSecondary">
                                            <Localized id="system-item">
                                                <span>Systemowy</span>
                                            </Localized>
                                        </Typography>
                                    </TableCell>
                                    <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allURSTemplates.map(row => {
                                    return <TableRow key={row.id}>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}><Typography component="span">{row.ursNo}</Typography></TableCell>
                                        <TableCell ><Typography component="span">{row.docDesignation}</Typography></TableCell>
                                        <TableCell sx={{ minWidth: 300, width: 300, maxWidth: 300 }}><StructureTableCell structureId={row.deviceTypeId} /></TableCell>
                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Checkbox defaultChecked={row.isSystem} disabled />
                                        </TableCell>

                                        <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                            <Button color="primary" variant="outlined" onClick={() => handleChooseTemplateClick(row)} >
                                                <Localized id="select">
                                                    <span>Wybierz</span>
                                                </Localized>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUrsTemplateClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
    </>;
}

