import { Localized } from "@fluent/react"
import { Button, IconButton, Menu, MenuItem, TableCell, TableRow } from "@mui/material"
import { AppId } from "../../app/appTypes"
import { UrsGroup } from "./UrsSlice"
import AddIcon from '@mui/icons-material/Add'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useState } from "react"
import { If } from "../../app/If"

export interface GroupProps {
    group: UrsGroup
    handleAddRequirement: (groupId: AppId) => void
    handleAddFromCatalog: (groupId: AppId) => void
    handleEditGroup: (group: UrsGroup) => void
    handleDeleteGroup: (group: UrsGroup) => void
    hasAnyRequirement: boolean
    canEdit: boolean
    showSupplierComments: boolean
}

export const Group = (props: GroupProps) => {
    const { group, showSupplierComments } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [rightMenuAnchorEl, setRightMenuAnchorEl] = useState<null | HTMLElement>(null);
    const menuOpen = Boolean(anchorEl)
    const rightMenuOpen = Boolean(rightMenuAnchorEl)

    return <TableRow key={group.id} sx={{ backgroundColor: '#f1f1f1' }}>
        <TableCell colSpan={showSupplierComments ? 4 : 2}>{`${group.numeration}. ${group.name}`}&nbsp;
            <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)}><MoreVertIcon /></IconButton>
            {props.canEdit && <Menu anchorEl={anchorEl} open={menuOpen} onClose={() => setAnchorEl(null)}>
                <MenuItem onClick={() => { props.handleEditGroup(group); setAnchorEl(null) }}><Localized id='edit'>Edytuj</Localized></MenuItem>
                <MenuItem disabled={props.hasAnyRequirement} onClick={() => { props.handleDeleteGroup(group); setAnchorEl(null) }}><Localized id='delete'>Usuń</Localized></MenuItem>
            </Menu>}
        </TableCell>
        <TableCell sx={{ textAlign: 'right' }} colSpan={2}>
            <If condition={props.canEdit}>
                <Button size="small" onClick={() => props.handleAddRequirement(group.id)} startIcon={<AddIcon />}><Localized id='add'>Dodaj</Localized></Button>
                <IconButton onClick={(event: React.MouseEvent<HTMLButtonElement>) => setRightMenuAnchorEl(event.currentTarget)}><MoreVertIcon /></IconButton>
                {<Menu anchorEl={rightMenuAnchorEl} open={rightMenuOpen} onClose={() => setRightMenuAnchorEl(null)}>
                    <MenuItem onClick={() => { props.handleAddFromCatalog(group.id); setRightMenuAnchorEl(null) }}><Localized id='urs-add-from-catalog'>Dodaj wymaganie z katalogu</Localized></MenuItem>
                </Menu>}
            </If>
        </TableCell>
    </TableRow>

}
