import {Localized} from "@fluent/react"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper} from "@mui/material"
import { UrsOfferRequirement } from "./ursOfferSlice"
import { Attachements } from "../urs/Attachements"


interface OfferAttachementsDialogProps {
    onClose       : () => void
    canUpload     : boolean
    canDelete     : boolean
    requirement   : UrsOfferRequirement | null
}

export const OfferAttachementsDialog = ({ requirement, onClose, canUpload, canDelete} : OfferAttachementsDialogProps) => {
   
    if (requirement === null) return <div></div>

   return <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>
            <Localized id="attachements">Załączniki</Localized>
        </DialogTitle>
        <DialogContent>
            <Paper sx={{ padding: 2, marginBottom: 2 }} elevation={1}>
                <DialogContentText><div dangerouslySetInnerHTML={{ __html: requirement.name }}></div></DialogContentText>
            </Paper>
            <Attachements resourceId={requirement.guid} canUpload={canUpload} canDelete={canDelete}/> 
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                <Localized id="close">Zamknij</Localized>
            </Button>
        </DialogActions>
    </Dialog>
}
