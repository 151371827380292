import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppId, AppThunkAPIType } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { selectModule } from "../modules/moduleSlice";
import { selectAllStructureNodes, selectStructureNodeById, Structure } from "./structureSlice";

export interface Relationship {
    node: RelationshipItem
    preds: RelationshipItem[]
    succs: RelationshipItem[]
}

export interface RelationshipItem {
    structureId: AppId
    leaf: boolean
}

const extractStructureId = ({ structureId }: RelationshipItem): AppId => structureId

export const buildRelationship = createAsyncThunk<Relationship[], void, AppThunkAPIType>("relationship/build", async (_, { getState }) => {
    const state = getState()

    const topDownNodes = function* () {
        const queue = selectAllStructureNodes(state).filter(n => n.parentStructureId === null)
        while (queue.length > 0) {
            const node = queue.shift() as Structure
            const { subStructureIds } = node
            subStructureIds.forEach(x => {
                const childNode = selectStructureNodeById(state, x)
                if (childNode) {
                    queue.push(childNode)
                } else {
                    console.log(`Cannot find structure node: ${x}`)
                }
            })
            yield node
        }
    }
    const isLeaf = (structId: AppId): boolean => {
        return selectStructureNodeById(state, structId)?.subStructureIds.length === 0 ?? false
    }

    const succs = new Map<AppId, RelationshipItem[]>()
    const preds = new Map<AppId, RelationshipItem[]>()

    for (const node of topDownNodes()) {
        const { parentStructureId, id, subStructureIds } = node
        if (parentStructureId !== null) {
            const parentPreds = preds.get(parentStructureId) as RelationshipItem[]
            preds.set(id, [...parentPreds, { structureId: parentStructureId, leaf: isLeaf(parentStructureId) }])
            parentPreds.forEach(p => {
                succs.set(p.structureId, [...(succs.get(p.structureId) as RelationshipItem[]), { structureId: id, leaf: isLeaf(id) }])
            })
            succs.set(id, subStructureIds.map(structureId => {
                return { 
                    structureId,
                    leaf: isLeaf(structureId),
                }
            }))
        } else {
            preds.set(id, [])
            succs.set(id, subStructureIds.map(structureId => {
                return {
                    structureId,
                    leaf: isLeaf(structureId),
                }
            }))
        }
    }

    return selectAllStructureNodes(state).map(({ id, subStructureIds }) => {
        return {
            node: {
                structureId: id,
                leaf: subStructureIds.length === 0,
            },
            preds: preds.get(id) ?? [],
            succs: succs.get(id) ?? [],
        }
    })
}, {
    condition: (_, { getState }) => {
        if (selectModule(getState()).currentModule?.code === undefined) {
            return false
        }
    }
})

const relationshipAdapter = createEntityAdapter<Relationship>({
    selectId: x => x.node.structureId
})

export type StructureRelationshipState 
    = EntityState<Relationship>

const initialState: StructureRelationshipState = relationshipAdapter.getInitialState()

export const structureRelationshipSlice = createSlice({
    name: "structureRelationship",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(buildRelationship.fulfilled, (state, action) => {
            relationshipAdapter.addMany(state, action.payload)
        })
    },
})

export const selectStructureRelationship = (state: RootState): EntityState<Relationship> => state.structureRelationship

export const {
    selectById: selectRelationshipByStructureId,
} = relationshipAdapter.getSelectors(selectStructureRelationship)

export const selectAllStructureFamily = createSelector(
    (state, structureId: AppId) => selectRelationshipByStructureId(state, structureId),
    (relationship) => {
        if (relationship === undefined) {
            return new Set([])
        } else {
            const { preds, node: structureId, succs } = relationship as Relationship
            return new Set([...preds, structureId, ...succs].map(extractStructureId))
        }
    }
)

export const selectStructureAndParents = createSelector(
    (state, structureId: AppId) => selectRelationshipByStructureId(state, structureId),
    (relationship) => {
        if (relationship === undefined) {
            return new Set([])
        } else {
            const { preds, node: structureId } = relationship as Relationship
            return new Set([...preds, structureId].map(extractStructureId))
        }
    }
)

export const selectStructureAndChildren = createSelector(
    (state, structureId: AppId) => selectRelationshipByStructureId(state, structureId),
    (relationship): Set<AppId> => {
        if (relationship === undefined) {
            return new Set([])
        } else {
            const { node: structureId, succs } = relationship as Relationship
            return new Set([structureId, ...succs].map(extractStructureId))
        }
    }
)

export const selectStructureLeafs = createSelector(
    (state, structureId: AppId) => selectRelationshipByStructureId(state, structureId),
    (relationship): Set<AppId> => {
        if (relationship === undefined) {
            return new Set([])
        } else {
            const { node: structureId, succs } = relationship as Relationship
            return new Set([structureId, ...succs]
                .filter(({ leaf }) => leaf)
                .map(extractStructureId)
            )
        }
    }
)

export const selectAllStructureLeafs = createSelector(
    (state) => selectAllStructureNodes(state),
    (nodes): Set<AppId> => {
        return new Set(nodes.filter(({ subStructureIds }) => subStructureIds.length === 0).map(({ id }) => id))
    }
)

export const selectAllStructure = createSelector(
    (state) => selectAllStructureNodes(state),
    (nodes): Set<AppId> => new Set(nodes.map(({ id }) => id))
)

export default structureRelationshipSlice.reducer
