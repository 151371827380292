import { Box, Container, Paper } from "@mui/material"
import { DataGrid, GridColDef, GridToolbarQuickFilter } from "@mui/x-data-grid"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { loadChc, selectAllChc, selectChc } from "./chcSlice"

const columns: GridColDef[] = [{
    field: 'requestNumber',
    headerName: 'Document number',
    flex: 1,
}, {
    field: 'applicant',
    headerName: 'Applicant',
    flex: 1,
}, {
    field: 'requestDate',
    headerName: 'Request date',
    flex: 1,
}, {
    field: 'status',
    headerName: 'Status',
    flex: 1,
}, {
    field: 'department',
    headerName: 'Department',
    flex: 1,
}]

const QuickSearchToolbar = () => {
    return <Box sx={{ p: 0.5, pb: 0 }}>
        <GridToolbarQuickFilter />
    </Box>
}

export const ChcList = () => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { state } = useAppSelector(selectChc)
    const allChc = useAppSelector(selectAllChc)

    useEffect(() => {
        if (state.type === 'empty') {
            dispatch(loadChc())
        }
    }, [state])

    switch (state.type) {
        case 'empty':
        case 'loading':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Loading data...</h5>
            </Container>
        case 'error':
            return <Container component={Paper} maxWidth='lg'>
                <h5>Data not loaded due to error!</h5>
            </Container>
        case 'loaded':
            return <>
                <Container maxWidth='xl'>
                    <DataGrid
                        columns={columns}
                        rows={allChc}
                        onRowDoubleClick={({ row }) => navigate(`/chc/edit/${row.id}`)}
                        slots={{ toolbar: QuickSearchToolbar }}
                        slotProps={{
                            toolbar: {
                                showQuickFilter: false,
                            }
                        }}
                        initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
                    />
                </Container>
            </>
    }
}
