import { Localized } from "@fluent/react"
import { Alert, Skeleton, Typography } from "@mui/material"
import { ReactNode, useEffect } from "react"
import { LoadModules } from "../../app/AppDataLoader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { AnyModule, ModuleCode, selectModule, selectModuleByCode, setCurrentModule } from "./moduleSlice"

export const CheckModuleAccess = ({ code, children }: { code: ModuleCode | AnyModule, children: ReactNode }) => {
    if (code === "*") return <>{children}</>

    return <LoadModules component={<Skeleton />}>
        <CheckModuleAccessInternal code={code}>
            {children}
        </CheckModuleAccessInternal>
    </LoadModules>
}

const CheckModuleAccessInternal = ({ code, children }: { code: ModuleCode, children: ReactNode }) => {
    const dispatch = useAppDispatch()
    const module = useAppSelector(state => selectModuleByCode(state, code))
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    useEffect(() => {
        if (module !== undefined) {
            if (currentModule === null || currentModule.code !== module.code) {
                dispatch(setCurrentModule(module))
            }
        }
    }, [dispatch, currentModule, module])

    return <LoadModules component={<Skeleton />}>
        <If condition={module !== undefined} otherwise={<AccessDenied />}>
            {currentModule !== null ? children : null}
        </If>
    </LoadModules>
}

const AccessDenied = () => {
    return <Alert severity="error">
        <Localized id="access-denied">
            <Typography variant="h5" component="span">Brak dostępu</Typography>
        </Localized>
    </Alert>
}