import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId } from "../../app/crud";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";

export interface Spec {
    id: AppId
    guid: string
    name: string
    created: string | null
    createdBy: string | null
}

export interface Cashflow {
    year: number
    specId: number
    cfValue: number
    dcfValue: number
}

export interface AssesmentItem {
    id: number
    name: string
    specId: AppId
    clientId: string
}

export interface SpecDetails {
}

export type SpecWithDetails = Spec & SpecDetails

const adapter = createEntityAdapter<Spec>({
    selectId: spec => spec.id,
})

export type SpecState = EntityState<Spec> & { state: AppDataState }

const initialState: SpecState = adapter.getInitialState({
    state: { type: "empty" }
})

// GET /api/spec
export const loadSpecs = createAsyncThunk<Spec[], void, AppThunkAPIType>('spec/load', async (_, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: "api/spec"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Spec[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})

export const loadSpec = createAsyncThunk<SpecWithDetails, AppId, AppThunkAPIType>('spec/single-load', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(getWithAuth({ url: `api/spec/${id}`}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
    
})

export const defaultSpec: SpecWithDetails = {
    id: '',
    guid: '',
    name: '',
    created: '',
    createdBy: '',
}

export const createSpec = createAsyncThunk<SpecWithDetails, SpecWithDetails, AppThunkAPIType>('spec/create', async (spec, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(postWithAuth({
        url: "api/spec",
        payload: spec
    }))
    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const updateSpec = createAsyncThunk<SpecWithDetails, SpecWithDetails, AppThunkAPIType>('spec/update', async (spec, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(putWithAuth({
        url: `api/spec/${spec.id}`,
        payload: spec
    }))
    const { payload } = result
    if (putWithAuth.fulfilled.match(result)) {
        return payload as SpecWithDetails
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteSpec = createAsyncThunk<void, AppId, AppThunkAPIType>('spec/delete', async (id, api) => {
    const { dispatch, rejectWithValue } = api
    const result = await dispatch(deleteWithAuth({
        url: `api/spec/${id}`,
    }))
    if (deleteWithAuth.fulfilled.match(result)) {
        return
    } else {
        return rejectWithValue(result.payload ?? { kind: 'unknown' })
    }
})

export const specSlice = createSlice({
    name: "specs",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadSpecs.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSpecs.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadSpecs.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(createSpec.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })
        builder.addCase(updateSpec.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteSpec.fulfilled, (state, action) => {
            adapter.removeOne(state, action.meta.arg)
        })
    }
})

export const selectSpecs = (state: RootState) => state.specs

export const
    { selectAll: selectAllSpecs
    , selectById: selectSpecById
    } = adapter.getSelectors(selectSpecs)

export default specSlice.reducer
