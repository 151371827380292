import { Localized } from "@fluent/react"
import { Alert, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { useCallback, useState } from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { LoadingButton } from "@mui/lab"
import { AppId, AppTextFieldHandler } from "../../../../app/appTypes"
import { DocumentTeamMember, DocumentTeamMemberRole, inviteDocumentTeamMember } from "../teamMemberApi"
import { showError, showSuccess } from "../../../notifications/notificationsSlice"
import { TeamMemberRoleMenuField } from "./TeamMemberRoleMenuField"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export interface InviteTeamMemberDialogProps {
    documentId: AppId
    onSuccessfulSave: (teamMember: DocumentTeamMember) => void
    onCancelClick: () => void,
}

export const InviteTeamMemberDialog = ({ documentId, onCancelClick, onSuccessfulSave }: InviteTeamMemberDialogProps) => {
    const dispatch = useAppDispatch()
    const [role, setRole] = useState<DocumentTeamMemberRole>("EXT_R")
    const [userEmail, setUserEmail] = useState<string>("")

    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)
    const [serverError, setServerError] = useState("")
    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setRole(e.target.value as DocumentTeamMemberRole), [setRole])
    const handleUserEmailChange: AppTextFieldHandler = useCallback(e => setUserEmail(e.target.value), [setUserEmail])
    const userEmailError = validationErrors && userEmail === ""

    const handleOkClick = async () => {
        setValidationErrors(true)
        setSaving(true)
        if (userEmail) {
            try {
                const result = await dispatch(inviteDocumentTeamMember({
                    documentId: documentId,
                    userEmail: userEmail,
                    role: role
                }))

                if (inviteDocumentTeamMember.fulfilled.match(result)) {
                    const newTeamMember = result.payload as DocumentTeamMember;
                    dispatch(showSuccess("saved"))
                    onSuccessfulSave(newTeamMember)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }

        setSaving(false)
    }

    return <>
        <Dialog key="inviteTMDialog" open={true} maxWidth="md" fullWidth keepMounted={false}>
            <DialogTitle>
                <Localized id="document-invite-team-member">
                    <span>Zaproś użytkownika</span>
                </Localized>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
            }}>
                <TextField
                    sx={{
                        marginTop: 1,
                    }}
                    error={userEmailError}
                    helperText={userEmailError && <RequiredError />}
                    value={userEmail}
                    fullWidth
                    type="email"
                    autoComplete="email"
                    required={true}
                    label={<Localized id="email-address"><span>Email</span></Localized>}
                    onChange={handleUserEmailChange}
                >
                </TextField>
                <TeamMemberRoleMenuField
                    value={role}
                    onChange={handleUserRoleChange}
                />
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={saving}
                    color="secondary"
                    variant="contained"
                    loadingIndicator={<Localized id="saving" />}
                    onClick={handleOkClick}>
                    <Localized id="save">
                        <span>save</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton
                    color="primary"
                    variant="outlined"
                    loading={saving}
                    onClick={onCancelClick}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}
