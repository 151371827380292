import { Localized } from "@fluent/react";
import { ProcessStatus } from "./processSlice";

export const ProcessStatusLabel = ({ status }: { status: ProcessStatus | undefined}) => {
    switch (status) {
        case "D":
            return <Localized id="process-status-d">
                <span>Draft</span>
            </Localized>
        case "I":
            return <Localized id="process-status-i">
                <span>In progress</span>
            </Localized>
        case "F":
            return <Localized id="process-status-f">
                <span>Finished</span>
            </Localized>
        case "R":
            return <Localized id="process-status-r">
                <span>Rejected</span>
            </Localized>
        case "C":
            return <Localized id="process-status-c">
                <span>Cancelled</span>
            </Localized>
        default:
            return <span>{status}</span>
    }
}