import { LoadingButton } from "@mui/lab"
import { Box, Button, ClickAwayListener, Popper, Tooltip } from "@mui/material"
import { MouseEvent, useCallback, useLayoutEffect, useRef, useState } from "react"
import AddCommentIcon from '@mui/icons-material/AddComment'
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { changeDocCommentStatus, createComment, deleteDocComment, DocComment } from "../documents/commentsAPI"
import { AppId } from "../../app/appTypes"
import { showError } from "../notifications/notificationsSlice"
import { parseAndFormatISODate } from "../../app/Utils"
import ReplyIcon from '@mui/icons-material/Reply'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import RemoveDoneIcon from '@mui/icons-material/RemoveDone'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { selectLoggedUser } from "../user/userSlice"
import produce from "immer"

export interface CommentsProps {
    documentId: AppId
    sectionGuid: string
    comments: DocComment[]
}

export const CommentsSection = (props: CommentsProps) => {
    const { documentId, sectionGuid, comments: defComments } = props
    const dispatch = useAppDispatch()

    const [commentsOpen, setCommentsOpen] = useState<null | 'add' | 'show'>(null)
    const [commentsAnchorEl, setCommentsAnchorEl] = useState<null | HTMLButtonElement | HTMLSpanElement>(null)
    const [sending, setSending] = useState(false)
    const [comments, setComments] = useState<DocComment[]>(defComments.filter(c => c.sectionGuid === sectionGuid))

    const txtRef = useRef<HTMLTextAreaElement | null>(null)

    const handleOpenCommentsClick = (e: MouseEvent<HTMLButtonElement>) => {
        setCommentsAnchorEl(e.currentTarget)
        setCommentsOpen(prev => prev ? null : 'add')
    }
    const handleSendClick = async () => {
        if (txtRef.current) {
            setSending(true)
            try {
                const newComment = await dispatch(createComment({
                    comment: txtRef.current.value,
                    context: '',
                    documentId,
                    sectionGuid,
                    parentCommentId: null,
                    sectionType: 'GROUP',
                    type: 'INTERNAL',
                })).unwrap()
                setComments(prev => [...prev, newComment])
                setCommentsOpen(null)
                setCommentsAnchorEl(null)
            } catch (error) {
                dispatch(showError('error'))
            } finally {
                setSending(false)
            }
        }
    }
    const handleShowCommentsClick = (e: MouseEvent<HTMLSpanElement>) => {
        if (comments.length > 0) {
            setCommentsAnchorEl(e.currentTarget)
            setCommentsOpen(prev => prev ? null : 'show')
        }
    }

    const handleCloseCommentsClick = () => {
        setCommentsOpen(null)
        setCommentsAnchorEl(null)
    }

    const handleDeleteClick = async (item: DocComment) => {
        try {
            const deletedId = await dispatch(deleteDocComment(item)).unwrap()
            setComments(cs => cs.filter(c => c.id !== deletedId))
        } catch (error) {
            console.log(error)
            dispatch(showError('error'))
        }
    }

    const updateComment = useCallback(
      (item: DocComment) => {
            setComments(produce(draft => {
                const i = draft.findIndex(x => x.id === item.id)
                if (i >= 0) {
                    const { status, comment } = item
                    draft[i].status = status
                    draft[i].comment = comment
                }
            }))
        },
      [],
    )

    const handleClickAway = () => {
        setCommentsOpen(null)
    }

    const isAnyNew = comments.findIndex(c => c.status === 'NEW') >= 0

    return <ClickAwayListener onClickAway={handleClickAway}>
        <div className="flex mt-1 p-0 items-end ra-bottom-toolbar">
            {isAnyNew && <span className='text-xs text-red-600 font-bold mr-1'>&#33;</span>}
            <span className='text-xs cursor-pointer mr-2 underline decoration-gray-500 text-gray-500' onClick={handleShowCommentsClick}>{`${comments.length} komentarz(y)`}</span>
            <Tooltip placement="top-end" title='Dodaj komentarz'>
                <button onClick={handleOpenCommentsClick} className='m-0 p-0 border-none bg-transparent cursor-pointer h-4'>
                    <AddCommentIcon sx={{
                        height: '16px',
                        width: '16px',
                        color: '#9ca3af',
                    }} />
                </button>
            </Tooltip>
            <Popper open={commentsOpen !== null} anchorEl={commentsAnchorEl}>
                {commentsOpen === 'add' && <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    mt: .5,
                }}>
                    <textarea className="ra-add-comment" ref={txtRef} placeholder='Wprowadź komentarz...'></textarea>
                    <LoadingButton sx={{ mt: .25, }} loading={sending} size='small' variant="contained" onClick={handleSendClick}>Wyślij</LoadingButton>
                </Box>}
                {commentsOpen === 'show' && <Box sx={{
                        bgcolor: '#e1e1e1',
                        width: '400px',
                        p: 0,
                        boxShadow: '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
                        mt: .5,
                    }}>{comments.map(c => <Comm data={c} delete={handleDeleteClick} onChange={updateComment} />)}
                    <Button size='small' onClick={handleCloseCommentsClick}>Zamknij</Button>
                </Box>}
            </Popper>
        </div>
    </ClickAwayListener>
}

interface CommentProps {
    data: DocComment
    delete: (item: DocComment) => Promise<void>
    onChange: (item: DocComment) => void
}
const Comm = (props: CommentProps) => {
    const { data, delete: handleDeleteClick, onChange } = props
    const myUserName = useAppSelector(selectLoggedUser)
    const dispatch = useAppDispatch()
    const { id, comment, createdByFullName, createdBy, createDate, documentId, sectionGuid, replies: defReplies, status } = data
    const d = parseAndFormatISODate(createDate)

    const txtRef = useRef<null | HTMLTextAreaElement>(null)

    const [replay, setReplay] = useState(false)
    const [loading, setLoading] = useState(false)
    const [replies, setReplies] = useState(defReplies)

    const handleSendReplyClick = async () => {
        if (txtRef.current && txtRef.current.value) {
            setLoading(true)
            try {
                const msg = await dispatch(createComment({
                    comment: txtRef.current.value,
                    context: '',
                    documentId,
                    sectionGuid,
                    parentCommentId: data.id,
                    sectionType: 'GROUP',
                    type: 'INTERNAL',
                })).unwrap()
                setReplies(rs => [...rs, msg])
                setReplay(false)
            } catch (error) {
                dispatch(showError('error'))
            } finally {
                setLoading(false)
            }
        }
    }

    const handleDelReplyClick = async (item: DocComment) => {
        try {
            await dispatch(deleteDocComment(item))
            setReplies(rs => rs.filter(r => r.id !== item.id))
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const handleResolveClick = async () => {
        try {
            onChange(await dispatch(changeDocCommentStatus({
                id,
                documentId,
                status: 'RESOLVED',
            })).unwrap())
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const handleUnresolveClick = async () => {
        try {
            onChange(await dispatch(changeDocCommentStatus({
                id,
                documentId,
                status: 'WONTFIX',
            })).unwrap())
        } catch (error) {
            dispatch(showError('error'))
        }
    }

    const mine = myUserName === createdBy

    return <Box sx={{
        bgcolor: 'transparent',
        border: '1px solid var(--gray-400)',
        mb: 1,
        // p: 1,
        px: 1,
        pb: 1,
        boxShadow: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1)',
    }}>
        <div className='ra-comment'>
            <Box sx={{ fontWeight: 700, position: 'relative' }}>
                <span>{createdByFullName}</span>
                <Box sx={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        fontSize: '0.75rem',
                        color: 'var(--gray-500)',
                    }}>
                    <span data-value={status}>{status}</span>
                </Box>
            </Box>
            <div>{comment}</div>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row-reverse',
            }}>
                <button className="ra-comment-btn" onClick={() => setReplay(true)}><ReplyIcon sx={{ width: '16px', hwight: '16px' }} /></button>
                <button className="ra-comment-btn" onClick={handleResolveClick}><DoneAllIcon sx={{ width: '16px', hwight: '16px' }} /></button>
                <button className="ra-comment-btn" onClick={handleUnresolveClick}><RemoveDoneIcon sx={{ width: '16px', hwight: '16px' }}/></button>
                {mine && <button className="ra-comment-btn"><EditIcon sx={{ width: '16px', hwight: '16px' }}/></button>}
                {mine && replies.length === 0 && <button className="ra-comment-btn" onClick={() => handleDeleteClick(data)}><DeleteIcon sx={{ width: '16px', hwight: '16px' }}/></button>}
            </Box>
        </div>
        {replies.length > 0 && <Box sx={{
            ml: 8,
            display: 'flex',
            flexDirection: 'column',
            pr: .5,
        }}>
            {replies.map(r => <div className="ra-comment">
                <Box sx={{ fontWeight: 700 }}>{r.createdByFullName}</Box>
                <Box>{r.comment}</Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row-reverse',
                }}>
                    {r.createdBy === myUserName && <button className="ra-comment-btn"><EditIcon sx={{ width: '16px', hwight: '16px' }}/></button>}
                    {r.createdBy === myUserName && <button className="ra-comment-btn" onClick={() => handleDelReplyClick(r)}><DeleteIcon sx={{ width: '16px', hwight: '16px' }}/></button>}
                </Box>
            </div>)}
        </Box>}
        {replay && <Box sx={{
            mt: 1,
            ml: 8,
            display: 'flex',
            flexDirection: 'column',
            pr: .5,
            [`& textarea`]: {
                fontSize: '0.75rem',
            },
        }}>
            <textarea ref={txtRef} placeholder="Odpowiedz..."></textarea>
            <div>
                <LoadingButton loading={loading} size='small' onClick={handleSendReplyClick}>Wyślij</LoadingButton>
                <LoadingButton loading={loading} size='small' onClick={() => setReplay(false)}>Anuluj</LoadingButton>
            </div>
        </Box>}
    </Box>
}
