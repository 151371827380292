import { Localized } from "@fluent/react"
import { Autocomplete, SxProps, TextField, Theme } from "@mui/material"
import { UrsGroup } from "./UrsSlice"

export interface GroupFieldProps {
    allGroups : UrsGroup[]
    value : UrsGroup | null
    onChange : (newValue : UrsGroup | null) => void
    sx?: SxProps<Theme>
}

export const GroupField = (props: GroupFieldProps) => {
    return <Autocomplete
        options={props.allGroups}
        fullWidth
        getOptionLabel={(option) => option.name}
        value={props.value}
        onChange={(_, newValue) => props.onChange(newValue)}
        sx={props.sx}
        renderInput={(params) => <TextField {...params} label={<Localized id='group'>Grupa</Localized>}/>}
    />
}
