import { Button } from "@mui/material"
import { useState } from "react"
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { Localized } from "@fluent/react"

export interface RequirementDescriptionProps {
    description: string
}

type RequirementDescriptionStyle
    = { tag: 'single-paragraph', fullContent: string }
    | { tag: 'multi-paragraph', firstParagraph: string, fullContent: string }
    | { tag: 'unknown-format', fullContent: string }

function parseStyle(description: string): RequirementDescriptionStyle {
    const matches = description.match(/<p>.*?<\/p>|<ol>.*?<\/ol>|<ul>.*?<\/ul>/g)
    if (matches === null) {
        return { tag: 'unknown-format', fullContent: description }
    } else if (matches.length === 1) {
        return { tag: 'single-paragraph', fullContent: description }
    } else {
        return { tag: 'multi-paragraph', firstParagraph: matches[0], fullContent: description }
    }
}

export const RequirementDescription = ({ description }: RequirementDescriptionProps) => {
    const styledDescription = parseStyle(description)
    switch (styledDescription.tag) {
    case 'single-paragraph':
        return <SingleParagraph fullContent={styledDescription.fullContent} />
    case 'multi-paragraph':
        return <MultiParagraph firstParagraph={styledDescription.firstParagraph} fullContent={styledDescription.fullContent} />
    case 'unknown-format':
        return <span>{description}</span>
    }
}

const SingleParagraph = ({ fullContent }: { fullContent: string }) => {
    return <div dangerouslySetInnerHTML={{ __html: fullContent }}></div>
}

const MultiParagraph = ({ firstParagraph, fullContent }: { firstParagraph: string, fullContent: string }) => {
    const [expanded, setExpanded] = useState(false)
    const handleShowMoreClick = () => {
        setExpanded(true)
    }
    const handleShowLessClick = () => {
        setExpanded(false)
    }
    return <>
        <div dangerouslySetInnerHTML={{ __html: expanded ? fullContent : firstParagraph }}></div>
        {!expanded && <Button startIcon={<ExpandMoreIcon />} onClick={handleShowMoreClick} size="small"><Localized id="show-more"><span>Show more</span></Localized></Button>}
        {expanded && <Button startIcon={<ExpandLessIcon />} onClick={handleShowLessClick} size="small"><Localized id="show-less"><span>Show less</span></Localized></Button>}
    </>
}

const Empty = () => {
    return <></>
}
