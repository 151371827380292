import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { useState } from "react"
import { DocumentHeader } from "../documentsApi"

export interface AdminDocumentEditProps {
    document: DocumentHeader
    onSucces: (item: DocumentHeader) => void
    onCancel: () => void
}

export const AdminDocumentEdit = (props: AdminDocumentEditProps) => {
    const [saving, setSaving] = useState(false)

    const [document, setDocument] = useState<DocumentHeader>(props.document)

    const [title, setTitle] = useState(document.title)
    const [priority, setPriority] = useState(document.no)
    const [status, setStatus] = useState(document.status)
    const [owner, setOwner] = useState("")
    const [clearActions, setClearActions] = useState(true)
    const [addUser, setAddUser] = useState("")

    const handleClickSave = async () => {

    }

    return <>
    <Dialog open={true} maxWidth='sm' sx={{ minHeight: '80%' }} fullWidth keepMounted={false}>
        <DialogTitle>
            <Localized id=''>Edycja dokumentu</Localized>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        autoFocus
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{ maxLength: 500 }}
                        sx={{ marginTop: "10px" }}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        label={<Localized id=''>Tytuł</Localized>}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        autoFocus
                        InputLabelProps={{
                            shrink: true
                        }}
                        inputProps={{ maxLength: 500 }}
                        sx={{ marginTop: "10px" }}
                        value={title}
                        onChange={e => setTitle(e.target.value)}
                        label={<Localized id=''>Tytuł</Localized>}
                    />
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleClickSave}>
                    <Localized id='save'>Save</Localized>
                </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog >
</>
}