import { createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../app/store";

export interface NotificationModel {
    type: "success" | "error";
    message: string;
}

export interface NotificationsState {
    queue: Array<NotificationModel>;
}

const initialState: NotificationsState = {
    queue: [],
}

export const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        showSuccess: (state, action) => {
            state.queue.push({
                type: "success",
                message: action.payload,
            });
        },
        showError: (state, action) => {
            state.queue.push({
                type: "error",
                message: action.payload,
            });
        },
        dequeueNotification: (state) => {
            state.queue.shift();
        },
    }
})

export const { showError, showSuccess, dequeueNotification } = notificationsSlice.actions

export const selectNotifications = (state: RootState) => state.notifications

export default notificationsSlice.reducer