import Container from '@mui/material/Container'
import TextField from "@mui/material/TextField"
import { Localized } from "@fluent/react"
import { Autocomplete, Button, ButtonGroup, Grid, Paper } from "@mui/material"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { Link } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import SaveIcon from '@mui/icons-material/Save'
import { useAppDispatch } from '../../../app/hooks'
import { createMaterialCard } from './materialCardSlice'
import { AppLoader } from '../../../app/AppLoader'
import { useNavigate } from 'react-router-dom'

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

export const CreateMaterialCard = () => {
    const [name, setName] = useState<string>("");   
    const [contractor, setContractor] = useState<string>("");   
    const [samples, setSamples] = useState<string>("");   
    const [installationPlace, setInstallationPlace] = useState<string>("");   
    const [description, setDescription] = useState<string>("");
    const [address, setAdress] = useState<string>("");
    const [industrySector, setIndustrySector] = useState<string>("");    
    const [cardNumber, setCardNumber] = useState<string>("");


    const [saving, setSaving] = useState(false)
    const [validationErrors, setValidationErrors] = useState(false)

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    
    const handleSaveClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if(name){
                try {
                    const actionResult = await dispatch(createMaterialCard({                     
                        name,  
                        contractor, 
                        cardNumber, 
                        address,
                        description, 
                        installationPlace,
                        industrySector,
                        samples
                    }));
                    const { id: newId } = unwrapResult(actionResult);

                    dispatch(showSuccess("saved"));

                    navigate(`/materialcards/edit/${newId}`, { replace: true });
                } catch (error) {
                    dispatch(showError("error"));
                }
            } 
        setSaving(false)
    }

    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setName(e.target.value), [setName]);

    const handleInstallationPlaceChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setInstallationPlace(e.target.value), [setInstallationPlace]);
          
    const handleSamplesChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setSamples(e.target.value), [setSamples]);
    
    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setDescription(e.target.value), [setDescription]);
              
    const handleCardNoChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setCardNumber(e.target.value), [setCardNumber]);
  
    const handleContractorChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setContractor(e.target.value), [setContractor]);  

    const handleIndustrySectorChange: (e: ChangeEvent<HTMLInputElement>) => void = 
        useMemo(() => 
            (e) => setIndustrySector(e.target.value), [setIndustrySector]);  

    const nameError = validationErrors && name === ""
    const contractorError = validationErrors && contractor === ""
    const industrySectorError = validationErrors && industrySector === ""
    const cardNumberError = validationErrors && cardNumber === ""
    
    const projects = [
         "Projekt Budowa Hali 2023/05",
        "Project Budowa Mostu "
    ]

    const [selectedProject, setSelectedProject] = useState<string>("Projekt Budowa Hali 2023/05")

    const handleProjectChange = useCallback((_, newproject) => setSelectedProject(newproject), [])


    return (
        <>
            <Container maxWidth="md" sx={{paddingTop: 2, paddingBottom: 2}}>
                <Paper >
                <Grid container spacing={2} sx={{padding:2}}>
                    <Grid item xs={12}>
                        <Autocomplete
                            disablePortal
                            options={projects}
                            fullWidth
                            value={selectedProject}
                            onChange={handleProjectChange}

                            getOptionLabel={item => item}
                            renderInput={params => <TextField {...params} label={<Localized id="">Projekt</Localized>} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={contractorError}
                            helperText={contractorError && <RequiredError />}
                            inputProps={{ maxLength: 200 }}                                 
                            value={contractor}   
                            onChange={handleContractorChange}                        
                            label={<Localized id="">Wykonawca</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={industrySectorError}
                            helperText={industrySectorError && <RequiredError />}
                            inputProps={{ maxLength: 200 }}                                 
                            value={industrySector}   
                            onChange={handleIndustrySectorChange}                        
                            label={<Localized id="">Branża</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}>
                        <TextField      
                            fullWidth    
                            required
                            error={cardNumberError}
                            helperText={cardNumberError && <RequiredError />}
                            inputProps={{ maxLength: 200 }}                                 
                            value={cardNumber}   
                            onChange={handleCardNoChange}                        
                            label={<Localized id="">Numer Karty</Localized>}>
                        </TextField>  
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth 
                            required             
                            value={name}    
                            error={nameError}  
                            helperText={nameError && <RequiredError />}           
                            onChange={handleNameChange}                                    
                            label={<Localized id="">Nazwa materiału</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={installationPlace}                 
                            onChange={handleInstallationPlaceChange}                                    
                            label={<Localized id="">Określenie miejsca wbudowania:</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={samples}                 
                            onChange={handleSamplesChange}                                    
                            label={<Localized id="">Próbki</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <TextField               
                            fullWidth              
                            value={description}                 
                            onChange={handleDescriptionChange}                                    
                            label={<Localized id="">Opis</Localized>}>
                        </TextField>
                    </Grid>
                    <Grid item xs={12}> 
                        <ButtonGroup color="secondary" variant="contained">
                            <Button startIcon={<SaveIcon />} onClick={handleSaveClick}>
                                <Localized id="save">Zapisz</Localized>
                            </Button>
                            <Button  component={Link} to={`/materialcards`} color="secondary" variant="outlined">
                                <Localized id="back">Wróć</Localized>
                            </Button>
                        </ButtonGroup>
                    </Grid>
                </Grid>                    
                </Paper>
            </Container>
            <AppLoader open={saving} />
        </>
    );
}
