import { alpha, styled } from '@mui/material/styles';
import React from "react";
import { TreeItem, TreeItemProps, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { SimpleTreeView } from '@mui/x-tree-view';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'

const CustomTreeItem = React.forwardRef(
    (props: TreeItemProps, ref: React.Ref<HTMLLIElement>) => (
        <TreeItem {...props} ref={ref} />
    ),
)

const StyledTreeItem = styled(CustomTreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    '& .close': {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.groupTransition}`]: {
    marginLeft: 15,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
  [`& .${treeItemClasses.label}:hover`]: {
      '& button': {
          visibility: 'visible',
      }
  },
  [`& .${treeItemClasses.label}`]: {
      '& button': {
          visibility: 'hidden',
      }
  },
}));

export const SpecItemsTree = () => {
    return <SimpleTreeView
        slots = {{ collapseIcon: ArrowDropDownIcon, expandIcon: ArrowRightIcon }}
        expandedItems={['root', 'color', 'engine']}
    >
        <StyledTreeItem itemId='root' label={<span>Honda Civic Type R</span>}>
            <StyledTreeItem itemId='color' label={<span>Color</span>}>
                <StyledTreeItem itemId='red' label={<span>Red</span>} />
                <StyledTreeItem itemId='white' label={<span>White</span>} />
                <StyledTreeItem itemId='yellow' label={<span>Yellow</span>} />
            </StyledTreeItem>
            <StyledTreeItem itemId='sportPack' label={<span>Sport pack</span>}></StyledTreeItem>
            <StyledTreeItem itemId='led' label={<span>LED lights</span>}></StyledTreeItem>
            <StyledTreeItem itemId='engine' label={<span>Engine</span>}>
                <StyledTreeItem itemId='250KM' label={<span>1.8 VTEC 250KM</span>} />
                <StyledTreeItem itemId='310KM' label={<span>2.0 VTEC 310KM</span>} />
            </StyledTreeItem>
        </StyledTreeItem>
    </SimpleTreeView>
}
