import { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import { Localized } from "@fluent/react"
import { useAppSelector } from "../../app/hooks"
import { AppId } from '../../app/appTypes'
import { Device, selectDeviceById } from './deviceSlice'
import { Alert, Skeleton } from '@mui/material';
import { LoadDevices } from '../../app/AppDataLoader'

const PREFIX = 'DeviceInfo';

const classes = {
    root: `${PREFIX}-root`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`
};

const StyledCard = styled(Card)(( { theme }) => ({
    [`&.${classes.root}`]: {
      width:"100%",
      flexGrow: 1,
      marginBottom: theme.spacing(1)
    },

    [`& .${classes.expand}`]: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
      }),
    },

    [`& .${classes.expandOpen}`]: {
        transform: 'rotate(180deg)',
    }
}));



export const DeviceInfo = ({ id }: { id: AppId }) => {
    return (
        <LoadDevices component={<Skeleton variant="rectangular" height={100} />}>
                <DeviceCardIfExists id={id} />
            </LoadDevices>
    );
}

const DeviceCardIfExists = ({ id }: { id: AppId }) => {
    const device = useAppSelector(state => selectDeviceById(state, id))

    if (!device) {
        return <Alert severity="warning">
            <Localized id="error-entity-not-exists">
                <span>Obiekt nie istnieje</span>
            </Localized>
        </Alert>
    } else {
        return <DeviceCard {...device} />
    }
}

const DeviceCard = ({ name, producerName, productionYear, model, description, serialNo, deviceTypeName }: Device) => {
    const [deviceInfoEexpanded, setDeviceInfoExpanded] = useState(false)

    const handleDeviceInfoExpandClick = useCallback(() => {
        setDeviceInfoExpanded(value => !value)
    }, [])



    return (
        <StyledCard className={classes.root}>
                
            <CardHeader
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: deviceInfoEexpanded,
                        })}
                        onClick={handleDeviceInfoExpandClick}
                        size="large">
                        <ExpandMoreIcon />
                    </IconButton>
                }

                title={name}
                subheader={<Localized id="process-device-name">Nazwa urządzenia</Localized>} 
            />

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                {<Localized id="process-device-description">Opis urzadzenia</Localized>}                               
                </Typography>
            </CardContent>
            <Collapse in={deviceInfoEexpanded} timeout="auto" unmountOnExit>
                <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="process-device-type">Typ urzadzenia</Localized>}:  {deviceTypeName}
                </Typography> 
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="process-device-producer">Producent</Localized>}:   {producerName}
                </Typography>               
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="process-device-year">Rok produkcji</Localized>}:   {productionYear}
                </Typography>  
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="devices-serial-number">Numer fabryczny</Localized>}:   {serialNo}
                </Typography>     
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="devices-model">Model</Localized>}:   {model}
                </Typography>                                                                    
                <Typography variant="body2" color="textSecondary" component="p">{<Localized id="process-device-description">Opis urządzenia</Localized>}</Typography>
                <Typography paragraph>
                    {description}
                </Typography>
                </CardContent>
            </Collapse>
        </StyledCard>
    );
}