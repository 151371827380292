import { ReactNode } from "react"
import { useAppSelector } from "../../app/hooks"
import { selectLoggedUser, selectUserRole } from "./userSlice"

export interface AuthorizeProps {
    children: ReactNode
    users: Set<string>
}

export const anyone = new Set<string>()

export const Authorize = ({ children, users }: AuthorizeProps) => {
    const loggedUser = useAppSelector(selectLoggedUser)

    const role = useAppSelector(selectUserRole)

    if (role === "admin") return <>{children}</>

    return users.has(loggedUser) ? <>{children}</> : null
}