import { Localized } from "@fluent/react";
import { Grid, MenuItem, Paper, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { parseAndFormatISODate } from "../../../app/Utils";
import { Notes } from "./NotesSlice";

export interface CompareNotesProps {
    note: Notes;
    leftNoteId: string;
    rightNoteId: string;
}

export const CompareNotes = ({ note, leftNoteId, rightNoteId }: CompareNotesProps) => {
    const [currentLeftNoteId, setCurrentLeftNoteId] = useState(leftNoteId)
    const [currentRightNoteId, setCurrentRightNoteId] = useState(rightNoteId)

    const [leftNoteContent, leftNoteTitle] = findNote(note, currentLeftNoteId)
    const [rightNoteContent, rightNoteTitle] = findNote(note, currentRightNoteId)

    return <>
        <Grid item xs={6}>
            <TextField 
                size="small"
                sx={{ mt: 2 }}
                label={<Localized id='version'>Wersja</Localized>}
                value={currentLeftNoteId}
                onChange={(e) => setCurrentLeftNoteId(e.target.value)}
                select
                fullWidth
            >
                <MenuItem key={note.id} value={note.id}><Localized id='current-version'>Aktualna wersja</Localized></MenuItem>
                {note.history.map(h => <MenuItem key={h.id} value={h.id}>{`${parseAndFormatISODate(h.createDate)}, ${h.createdByFullName}`}</MenuItem>)}
            </TextField>
            <Paper sx={{ p: 2, mt: 1 }}>
                <Typography variant="h6">{leftNoteTitle}</Typography>
                <div dangerouslySetInnerHTML={{ __html: leftNoteContent }}></div>
            </Paper>
        </Grid>
        <Grid item xs={6}>
            <TextField 
                size="small"
                sx={{ mt: 2 }}
                label={<Localized id='version'>Wersja</Localized>}
                value={currentRightNoteId}
                onChange={(e) => setCurrentRightNoteId(e.target.value)}
                select
                fullWidth
            >
                {note.history.map(h => <MenuItem key={h.id} value={h.id}>{`${parseAndFormatISODate(h.createDate)}, ${h.createdByFullName}`}</MenuItem>)}
            </TextField>
            <Paper sx={{ p: 2, mt: 1 }}>
                <Typography variant="h6">{rightNoteTitle}</Typography>
                <div dangerouslySetInnerHTML={{ __html: rightNoteContent }}></div>
            </Paper>
        </Grid>
    </>
}

const findNote = (note: Notes, noteId: string): [string, string] => {
    if (note.id === noteId) {
        return [note.content, note.title]
    } else {
        const n = note.history.find(note => note.id === noteId)
        return n ? [n.content, n.title] : ["", ""]
    }
}
