import { createAsyncThunk } from "@reduxjs/toolkit"

export interface NPVResult {
    cfs : number[]
    dcfs : number[]
    pv : number
    npv : number
    irr : number | undefined
    payback : number
}

export type NPVManualResult = Omit<NPVResult, 'cfs'>

export interface NPVInput {
    investementCost: number
    forcastedYearlySaving: number
    growthYearlyFactor: number
    discountRate: number
    numberOfYears: number
    initialGuess: number
}

export type NPVManualInput = Pick<NPVInput, 'discountRate' | 'initialGuess'> & { cfs: number[] }

export const calculateNPV = createAsyncThunk<NPVResult, NPVInput>('npv/auto-calculation', async (args) => {
    const { investementCost, forcastedYearlySaving, growthYearlyFactor, discountRate, numberOfYears, initialGuess } = args
    const result: NPVResult = {
        cfs: [],
        dcfs: [],
        pv: 0,
        npv: 0,
        irr: 0,
        payback: 0
    }
    result.cfs = [-investementCost]
    for (let i = 1; i <= numberOfYears; i++) {
        result.cfs.push(forcastedYearlySaving * Math.pow(1 + (growthYearlyFactor / 100), i-1))
    }
    result.dcfs = result.cfs.map((cf, i) => cf / Math.pow(1 + discountRate / 100, i))
    result.npv = calcNPV(result.cfs, discountRate / 100)
    result.pv = result.npv + investementCost
    result.irr = irr(result.cfs, initialGuess)
    result.payback = calcPayback(result.cfs)

    return result
})

export const calculateNPVManually = createAsyncThunk<NPVManualResult, NPVManualInput>('npv/manual-calculation', async (args) => {
    const { discountRate, initialGuess, cfs } = args
    const result: NPVManualResult = {
        dcfs: [],
        pv: 0,
        npv: 0,
        irr: 0,
        payback: 0
    }
    result.dcfs = cfs.map((cf, i) => cf / Math.pow(1 + discountRate / 100, i))
    result.npv = calcNPV(cfs, discountRate / 100)
    result.pv = result.npv + cfs[0]
    result.irr = irr(cfs, initialGuess)
    result.payback = calcPayback(cfs)

    return result
})

const calcNPV = (cashflow: number[], discountRate: number) => cashflow
  .reduce((acc, c, i) => acc + c / Math.pow((1 + discountRate), i), 0)

type Poly = number[]

function evalPoly(p: Poly, x: number): number {
    return p.reduce((acc, c, i) => acc + c * Math.pow(x, i), 0)
}

function derivative(p: Poly): Poly {
    return p.slice(1).map((c, i) => c * (i + 1))
}

function newtonRaphson(p: Poly, x0: number, eps: number, maxIter: number): number | undefined {
    let x = x0
    for (let i = 0; i < maxIter; i++) {
        let f = evalPoly(p, x)
        let df = evalPoly(derivative(p), x)
        x = x - f / df
        if (Math.abs(f) < eps) {
            return x
        }
    }

    return undefined
}

function irr(cashflow: number[], initialGuess: number = 2.1): number | undefined {
    const poly = cashflow.slice().reverse()
    const g = newtonRaphson(poly, initialGuess, 1e-6, 1000)
    if (g === undefined) {
        return undefined
    } else {
        return g - 1
    }
}

function calcPayback(cashflow: number[]): number {
    const monthlyCfs: number[] = []
    cashflow.forEach((cf) => {
        for (let j = 0; j < 12; j++) {
            monthlyCfs.push(cf / 12)
        }
    })
    let payback = 0
    let cumulative = 0
    for (let i = 0; i < monthlyCfs.length; i++) {
        cumulative += monthlyCfs[i]
        if (cumulative >= 0) {
            payback = i + 1
            break
        }
    }
    return payback
}
