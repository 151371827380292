import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Typography } from "@mui/material"
import { useCallback, useState } from "react"
import { AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { ActionLink, deleteUrs, postURSAction, Urs } from "./UrsSlice"
import { useNavigate } from "react-router-dom"


export interface ActionRunnerProps {
    action: ActionLink & { name: string, description: string } 
    onSuccess: (urs: Urs) => void
    onError: () => void
    onCancel: () => void
}

export const ActionRunner = ({ action, onSuccess, onError, onCancel }: ActionRunnerProps) => {
    const dispatch = useAppDispatch()
    const [password, setPassword] = useState("")
    const today = getLocalISODate(new Date().setDate(new Date().getDate() + 7));
    const [deadline, setDeadline] = useState(today)
    const [running, setRunning] = useState(false)
    const { authorize, hasParams } = action
    const navigate = useNavigate()

    const run = async() => {
        if (action && action.type === "POST") {
            setRunning(true)


            if (action.name == "del"){
                const result = await dispatch(deleteUrs({ 
                    url: action.href, 
                    args: authorize ? { password, deadline } : { password:'', deadline} 
                }))

                if (deleteUrs.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    navigate(-1)

                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }
            else{
                const result = await dispatch(postURSAction({ 
                    url: action.href, 
                    args: authorize ? { password, deadline } : { password:'', deadline} 
                }))

                if (postURSAction.fulfilled.match(result)) {
                    dispatch(showSuccess("saved"))
                    onSuccess(result.payload)
                } else  {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                    setRunning(false)
                    onError()
                }
            }     
        }
    }

    let title = <></>
    let content = <></>
    switch (action?.name) {
        case "finishOpinion":
            title = <Localized id="urs-action-finish-opinion">
                <span>Zakończ opiniowanie</span>
            </Localized>
            break;
        case "FinishEdition":
            title = <Localized id="urs-action-finish-edition">
                <span>Zakończ edytowanie</span>
            </Localized>
            break;
        case "approve":
            title = <Localized id="urs-action-approve">
                <span>Zatwierdź dokument</span>
            </Localized>
            break;
        case "reject":
            title = <Localized id="urs-action-reject">
                <span>Odrzuć dokument</span>
            </Localized>
            break;
        case "sendToOpinion":
            title = <Localized id="urs-action-send-to-opinion">
                <span>Wyślij do opiniowania</span>
            </Localized>
            content = <Localized id="urs-action-sent-to-opinion-question">
                <span>Czy napewno chcesz przesłać dokument do opiniowania?</span>
            </Localized>
            break;
        case "sendToApprove":
            title = <Localized id="urs-action-send-to-approve">
                <span>Wyślij do zatwierdzenia</span>
            </Localized>
            content = <Localized id="urs-action-sent-to-approve-question">
                <span>Czy napewno chcesz przesłać dokument do zatwierdzenia?</span>
            </Localized>
            break;
        case "newversion":
            title = <Localized id="urs-action-new-version">
                <span>Utwórz nową wersję URS</span>
            </Localized>
            break;
        default:
            title = <></>
            break;
    }

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])

    const handleDeadlineFieldChange: AppTextFieldHandler = useCallback(e => {
        setDeadline(e.target.value)
    }, [])

    function getLocalISODate(date) {
        const tzOffset = (new Date()).getTimezoneOffset() * 60000; // offset w milisekundach
        const localISOTime = (new Date(date - tzOffset)).toISOString().slice(0,-1);
        return localISOTime.split('T')[0];
    }

    return <Dialog open={true} maxWidth="xs" fullWidth>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            <DialogContentText>{content}</DialogContentText>
            <Typography>{action?.description}</Typography>
            {authorize && <TextField 
                sx={{
                    marginTop: 1
                }}
                fullWidth
                autoFocus
                type="password"
                value={password}
                onChange={handlePasswordFieldChange}
                label={<Localized id="password"><span>Hasło</span></Localized>}
                helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}
                autoComplete="off"
            />}
            {hasParams && <TextField 
                sx={{
                    marginTop: 2
                }}
                type="date"
                fullWidth
                value={deadline}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                    min: getLocalISODate(new Date())
                }}
                onChange={handleDeadlineFieldChange}
                label={<Localized id="deadline"><span>Termin:</span></Localized>}
            />}
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={running} onClick={run}>
                <Localized id="ok">
                    <span>OK</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={running} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}

