import { Fab, Button, ButtonGroup } from "@mui/material"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { BusinessCase, loadBusinessCasesForProject } from "./businessCaseSlice"
import AddIcon from '@mui/icons-material/Add'
import { Localized } from "@fluent/react"
import { Link } from "react-router-dom"
import { unwrapResult } from "@reduxjs/toolkit"
import { LoadingContainer } from "../../app/LoadingContainer"
import { ErrorContainer } from "../../app/ErrorContainer"
import { useNavigate } from "react-router-dom"
import { selectSettings } from "../settings/settingsSlice"
import { enUS, plPL } from "@mui/x-data-grid/locales"

const columns: GridColDef[] = [
    {
        field: 'title',
        headerName: 'Tytuł',
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Status'
    },
    {
        field: 'createDate', 
        headerName: 'Utworzono',
        valueGetter: (_, row) => {
            return row.createDate ? new Date(row.createDate).toISOString().split('T')[0] : "";
        }
    },
    {
        field: 'createdBy',
        headerName: 'Utworzył'
    },
    {
        field: 'actions',
        type: 'actions',
        width: 200,
        renderCell: (params) => {
            return (
                <ButtonGroup>
                    <Button component={Link} to={`/businessCase/edit/${params.row.id}`} color="secondary" variant="outlined">
                        <Localized id="go-to">
                            <span>Go to</span>
                        </Localized>
                    </Button>
                </ButtonGroup>
            )
        }
    }
]

export const BusinessCaseListForProject = (props: { projectId: string | null, projectStatus: string }) => {
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const { locale } = useAppSelector(selectSettings);
    const [businessCases, setBusinessCases] = useState<BusinessCase[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);

    const handleCreateNewBusinessCaseClick = async () => {
        navigate(`/businessCase/create?projectId=${props.projectId}`)
    }

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadBusinessCasesForProject(props.projectId))
        .then(unwrapResult)
        .then(setBusinessCases)
        .catch((error) => { console.log(error); setIsError(true); setError(error); })
        .finally(() => setIsLoading(false));
    }, [])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    if (isError){
        return <>
            <ErrorContainer error={error}/>
        </>
    }

    if (isLoading){
        return <>
            <LoadingContainer/>
        </>
    }
    
    return (
        <>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 425px)' }}
                localeText={getCustomLocaleText()}
                columns={columns}
                rows={businessCases}
                onRowDoubleClick={({ row }) => navigate(`/businessCase/edit/${row.id}`)}
                initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            />
            <Fab sx={{ mt: 2 }} color="secondary" onClick={handleCreateNewBusinessCaseClick} disabled={props.projectStatus === "C" || props.projectStatus === "F" || props.projectStatus === "H"}>
                <AddIcon />
            </Fab>
        </>
    )
}
