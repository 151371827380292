import { Localized } from "@fluent/react";
import { MinuteMeetingStatus } from "./meetingMinutesSlice";

export const MeetingStatusLabel = ({ status }: { status: MinuteMeetingStatus}) => {
    switch (status) {
        case "INMEETING":
            return <Localized id="meeting-minutes-status-inmeeting">
                <span>W trakcie spotkania</span>
            </Localized>        
        case "CLOSED":
            return <Localized id="meeting-minutes-status-closed">
                <span>Ostatnie spotkanie</span>
            </Localized>
       
        default:
            return <span></span>
    }
}