import { Grid } from '@mui/material';
import { styled } from '@mui/system';
import { AppId } from '../../../app/appTypes';
import MeetingMinutesWigdet from './widgets/MeetingMinutesWigdet';
import { ProjectsWidget } from './widgets/ProjectsWidget';
import BusinessCaseWidget from './widgets/BusinessCaseWidget';

const Root = styled('div')(({ theme }) => ({
    flexGrow: 1,
    padding: theme.spacing(2),
}));

interface ProjectOverviewProps {
    projectGuid: AppId;
    projectId: AppId;
}

export const ProjectOverviewDashboard = ({ projectGuid, projectId }: ProjectOverviewProps) => {
    return (
        <Root>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                    <ProjectsWidget projectGuid={projectGuid} projectId={projectId}></ProjectsWidget>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <MeetingMinutesWigdet projectGuid={projectGuid} projectId={projectId}></MeetingMinutesWigdet>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                    <BusinessCaseWidget projectId={projectId}></BusinessCaseWidget>
                </Grid>
            </Grid>
        </Root>

    );
};


export default ProjectOverviewDashboard;