import { Localized, useLocalization } from "@fluent/react";
import { styled } from '@mui/material/styles';
import {
    Accordion,
    AccordionActions,
    AccordionDetails,
    AccordionSummary,
    Button,
    CardContent,
    Checkbox,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from "@mui/material";
import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { LoadProcesses, LoadProcessTests, LoadProcessTestSteps } from "../../app/AppDataLoader";
import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { DeviceInfo } from "../device/DeviceInfo";
import { ValidationStage } from "../validations/validationsSlice";
import { Process, selectProcessById } from "./processSlice";
import { approveProcessTest, finishProcessTest, ProcessTest, selectProcessTestById } from "./processTestsSlice";
import { selectAllProcessTestSteps, selectProcessTestStepById, TestCriteria, TestStep, updateTestStep } from "./processTestStepSlice";
import { TestInfo } from "./TestInfo";
import produce from "immer";
import { unwrapResult } from "@reduxjs/toolkit";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { If } from "../../app/If";
import { Authorize } from "../user/Authorize";
import { AppLoader } from "../../app/AppLoader";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";


const PREFIX = 'TestResult';

const classes = {
    resultCard: `${PREFIX}-resultCard`,
    flexColumn: `${PREFIX}-flexColumn`,
    checkColumn: `${PREFIX}-checkColumn`,
    flexRowReverse: `${PREFIX}-flexRowReverse`
};

const StyledAccordion = styled(Accordion)(( { theme }) => ({
    [`& .${classes.resultCard}`]: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },

    [`& .${classes.flexColumn}`]: {
        flexDirection: "column",
    },

    [`& .${classes.checkColumn}`]: {
        width: "120px",
    },

    [`& .${classes.flexRowReverse}`]: {
        display: "flex",
        flexDirection: "row-reverse",
    }
}));

export const TestResult = ({ stage }: { stage: ValidationStage }) => {
    const { id: paramId, processId: paramProcId } = useParams<{ id: AppId, processId: AppId, }>()
    const id = paramId ?? ''
    const processId = paramProcId ?? ''

    return (
        <LoadProcesses component={<Skeleton variant="rectangular" />}>
            <LoadProcessTests stage={stage} processId={processId} component={<Skeleton variant="rectangular" />}>
                <ResultsForm processId={processId} testId={id} />
            </LoadProcessTests>
        </LoadProcesses>
    );
}

const ResultsForm = ({ testId, processId }: { testId: AppId, processId: AppId }) => {
    const dispatch = useAppDispatch()
    const { deviceId, stages } = useAppSelector(state => selectProcessById(state, processId)) as Process
    
    const test = useAppSelector(state => selectProcessTestById(state, testId)) as ProcessTest
    const testStage = stages.find(x=> x.stage === test.stage);
    
    const { status, testUser, resultApproverUser } = test
    const steps = useAppSelector(selectAllProcessTestSteps)
    const navigate = useNavigate()

    const [saving, setSaving] = useState(false)

    const testers = useMemo(() => new Set<string>([testUser]), [testUser])
    const approvers = useMemo(() => new Set<string>([resultApproverUser]), [resultApproverUser])
    const editors = useMemo(() => new Set<string>([testUser, resultApproverUser]), [testUser, resultApproverUser])
  
    const [showPwdDialog, setShowPwdDialog] = useState(false)
    const [password, setPassword] = useState("")

    const [running, setRunning] = useState(false)

    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const handleSendToApproveClick = async () => {
        setSaving(true)

        try {
            unwrapResult(
                await dispatch(finishProcessTest(test))
            )
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }

        setSaving(false)
    }

    const handleApproveClick = useCallback(() => {
        setPassword("")
        setShowPwdDialog(true)
    }, [])
    const handleApproveDialogCancelClick = useCallback(() => {
        setShowPwdDialog(false)
    }, [])
    const handleApproveDialogOkClick = async () => { 
        setRunning(true)
        const result = await dispatch(approveProcessTest({ process: test, password }))

        if (approveProcessTest.fulfilled.match(result)) {
            dispatch(showSuccess("saved"))
            setShowPwdDialog(false)
        } else {
            let errorMsg = "error"
            if (result.payload) {
                if (result.payload.kind === 'http') {
                    const problem = result.payload.problem
                    if (problem) {
                        errorMsg = problem.title
                    }
                }
            }
            dispatch(showError(errorMsg))
            setRunning(false)
        }
    }

    const handlePasswordFieldChange: AppTextFieldHandler = useCallback(e => {
        setPassword(e.target.value)
    }, [])



    return (
        <Container component={Paper} maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={8}>
                    <TestInfo {...test} />
                </Grid>
                <Grid item xs={4}>
                    <DeviceInfo id={deviceId} />
                </Grid>
                <Grid item xs={12}>
                    <LoadProcessTestSteps testId={testId} component={<Skeleton variant="rectangular" height={256} />}>
                        <CardContent>
                            <Typography variant="subtitle2" component="div" color="textSecondary">
                                <Localized id="test-steps">
                                    <span>Kroki testu</span>
                                </Localized>
                            </Typography>
                            {steps.map(({ id: stepId }) => <StepResult 
                                                                key={stepId}
                                                                id={stepId} 
                                                                editable={(status === "P" || status === "F") && testStage?.status === "T"} 
                                                                editors={editors}
                                                            />)}
                        </CardContent>
                    </LoadProcessTestSteps>
                </Grid>
                <Grid item xs={4}>
                    <Button onClick={handleBackClick} variant="contained" color="secondary">
                        <Localized id="back">
                            <span>Wróć</span>
                        </Localized>
                    </Button>
                </Grid>
                <Grid item xs={4}></Grid>
                <Grid item xs={4} className={classes.flexRowReverse}>
                    <If condition={status === "P" && testStage?.status === "T"}>
                        <Authorize users={testers}>
                            <Button onClick={handleSendToApproveClick} variant="contained" color="primary">
                                <Localized id="test-action-send-to-approve">
                                    <span>Wyślij do zatwierdzenia</span>
                                </Localized>
                            </Button>
                        </Authorize>
                    </If>
                    <If condition={status === "F" && testStage?.status === "T"}>
                        <Authorize users={approvers}>
                            <Button onClick={handleApproveClick} variant="contained" color="primary">
                                <Localized id="test-action-approve">
                                    <span>Zatwierdź</span>
                                </Localized>
                            </Button>
                        </Authorize>
                    </If>
                </Grid>
            </Grid>
            <Dialog open={showPwdDialog} maxWidth="xs" fullWidth>
                <DialogTitle>
                    <Localized id="stage-action-approve">
                    <span>Zatwierdź</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <TextField 
                        sx={{
                            marginTop: 1
                        }}
                        fullWidth
                        autoFocus
                        type="password"
                        value={password}
                        onChange={handlePasswordFieldChange}
                        label={<Localized id="password"><span>Hasło</span></Localized>}
                        helperText={<Localized id="autorization-password-required"><span>Wymagana autoryzacja hasłem</span></Localized>}
                        autoComplete="off"
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={running} onClick={handleApproveDialogOkClick}>
                        <Localized id="ok">
                            <span>OK</span>
                        </Localized>
                    </LoadingButton>
                    <LoadingButton loading={running} onClick={handleApproveDialogCancelClick}>
                        <Localized id="cancel">
                            <span>Anuluj</span>
                        </Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <AppLoader open={saving} />
        </Container>
    );
}

const StepResult = ({ id, editable, editors }: { id: AppId, editable: boolean, editors: Set<string> }) => {
    const dispatch = useAppDispatch()
    const { testId, name, testCriterias: entityCriterias } = useAppSelector(state => selectProcessTestStepById(state, id)) as TestStep

    const [testCriterias, setTestCriterias] = useState<TestCriteria[]>([])

    const [saving, setSaving] = useState(false)

    useEffect(() => {
        setTestCriterias(entityCriterias)
    }, [entityCriterias])

    const handleFulfillCheck = useCallback((i: number) => {
        setTestCriterias(
            produce(draft => {
                draft[i].status = "P"
            })
        )
    }, []) 
    const handleRejectCheck = useCallback((i: number) => {
        setTestCriterias(
            produce(draft => {
                draft[i].status = "R"
            })
        )
    }, [])
    const handleDeviationChange = useCallback((value: string, i: number) => {
        setTestCriterias(
            produce(
                draft => {
                    draft[i].comment = value
                }
            )
        )
    }, [])
    const handleSaveClick = async () => {
        setSaving(true)
        try {
            unwrapResult(await dispatch(updateTestStep({
                id,
                name,
                testCriterias,
                testId,
            })))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }

        setSaving(false)
    }


    const { l10n } = useLocalization()

    return (
        <StyledAccordion key={id}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2">{name}</Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.flexColumn}>
                <TableContainer component="div">
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography gutterBottom variant="subtitle2" color="textSecondary">
                                        <Localized id="test-acceptance-criteria">
                                            <span>Kryterium akceptacji</span>
                                        </Localized>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography gutterBottom variant="subtitle2" color="textSecondary">
                                        <Localized id="test-result-comment">
                                            <span>Komentarz</span>
                                        </Localized>                                        
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.checkColumn}>
                                    <Typography gutterBottom variant="subtitle2" color="textSecondary">
                                        <Localized id="test-result-ok">
                                            <span>Spełnia</span>
                                        </Localized> 
                                    </Typography>
                                </TableCell>
                                <TableCell className={classes.checkColumn}>
                                    <Typography gutterBottom variant="subtitle2" color="textSecondary">
                                        <Localized id="test-result-note-ok">
                                            <span>Nie spełnia</span>
                                        </Localized> 
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {testCriterias.map(({ name, comment, status }, idx) => <TableRow key={idx}>
                                <TableCell>{name}</TableCell>
                                <TableCell>
                                    <If condition={editable}
                                        otherwise={<span>{comment ?? "n.d."}</span>}>
                                        <TextField 
                                            multiline 
                                            fullWidth 
                                            value={comment ?? ""}
                                            onChange={e => handleDeviationChange(e.target.value, idx)}
                                            placeholder={l10n.getString("not-applicable", null, "n.d.")} />
                                    </If>
                                </TableCell>
                                <TableCell>
                                    <If condition={editable}
                                        otherwise={<Checkbox checked={status === "P"} />}>
                                        <Checkbox checked={status === "P"} onClick={() => handleFulfillCheck(idx)} />
                                    </If>
                                </TableCell>
                                <TableCell>
                                    <If condition={editable}
                                        otherwise={<Checkbox checked={status === "R"} />}>
                                        <Checkbox checked={status === "R"} onClick={() => handleRejectCheck(idx)} />
                                    </If>
                                </TableCell>
                            </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </AccordionDetails>
            <If condition={editable}>
                <Authorize users={editors}>
                    <AccordionActions>
                        <Button disabled={saving} onClick={handleSaveClick} size="small">
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </Button>
                    </AccordionActions>
                </Authorize>
            </If>
        </StyledAccordion>
    );
}
