import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppThunkAPIType } from "../../../app/appTypes";
import { RootState } from "../../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http";

export interface HelpArticle {
   id: string, 
   location: string, 
   title: string, 
   title_EN: string, 
   description: string, 
   description_EN: string
 }

const adapter = createEntityAdapter<HelpArticle>({
    selectId: (helpArticles) => helpArticles.id,
})


export type DraftHelpArticle = Omit<HelpArticle, "id">

export type HelpArticleState = EntityState<HelpArticle> & { loaded: boolean }

const initialState: HelpArticleState = adapter.getInitialState({ loaded: false })


export const loadHelpArticles = createAsyncThunk<HelpArticle[], void, AppThunkAPIType>("helpArticle/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/help-articles"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as HelpArticle[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createHelpArticle = createAsyncThunk<HelpArticle, DraftHelpArticle, AppThunkAPIType>
    ("helpArticle/create", async (article: DraftHelpArticle, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/help-articles",
            payload: article,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as HelpArticle
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateHelpArticle = createAsyncThunk<HelpArticle, HelpArticle, AppThunkAPIType>(
    "helpArticle/update", 
    async (article: HelpArticle, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/help-articles/${article.id}`,
            payload: article,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return article as HelpArticle
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteHelpArticle = createAsyncThunk<string, string, AppThunkAPIType>(
    "helpArticle/delete",
    async (id, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/help-articles/${id}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return id
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const helpArticlesSlice = createSlice({
    name: "helpArticles",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createHelpArticle.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        })
        builder.addCase(deleteHelpArticle.fulfilled, (state, action) => {
            state = adapter.removeOne(state, action.payload)
        })
        builder.addCase(updateHelpArticle.fulfilled, (state, action) => {
            const { id } = action.payload
            const changes: Omit<HelpArticle, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })
        builder.addCase(loadHelpArticles.fulfilled, (state, action) => {
            adapter.setAll(state, action.payload)
            state.loaded = true
        })
    }
})

export const selectHelpArticles = (state: RootState): HelpArticleState => state.helpArticles

export const 
    { selectAll: selectAllHelpArticles
    , selectById: selectHelpArticleById 
    , selectTotal: selectTotalHelpArticles
    } = adapter.getSelectors<RootState>(selectHelpArticles)

export default helpArticlesSlice.reducer;
