import { Localized } from "@fluent/react";
import { Snackbar } from "@mui/material";
import { Alert } from '@mui/material';
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { dequeueNotification, NotificationModel, selectNotifications } from "./notificationsSlice";


type State = "hidden" | "visible"

export const Notifications = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [state, setState] = useState<State>("hidden");
    const [msg, setMsg] = useState<NotificationModel | undefined>(undefined);

    const dispatch = useAppDispatch();
    const { queue } = useAppSelector(selectNotifications);

    useEffect(() => {
        if (!open && state === "hidden" && queue.length) {
            const [notification] = queue;
            setMsg(notification);
            setOpen(true);
            dispatch(dequeueNotification());
        }
    }, [open, queue, dispatch, state, setMsg, setOpen]);

    const handleClose = (_: any, reason: string) => { 
        if (reason !== "clickaway") 
            setOpen(false); 
    }

    const handleEnter = () => setState("visible")

    const handleExited = () => setState("hidden")

    return (
        <Snackbar
            open={open}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            onClose={handleClose}
            autoHideDuration={3500}
            TransitionProps={{
                onEnter: handleEnter,
                onExited: handleExited
            }}>{msg && <Alert variant="filled" severity={msg.type}>
                    <Localized id={msg.message}>
                        <span>{msg.message}</span>
                    </Localized>
                </Alert>}</Snackbar>
    )
}