import { Localized } from "@fluent/react"
import { Badge, ButtonGroup, IconButton, TableCell, TableRow, Tooltip } from "@mui/material"
import { useMemo } from "react"
import { useAppSelector } from "../../app/hooks"
import { If } from "../../app/If"
import { NoteLabel } from "../ursOffer/NoteLabel"
import { ReqCriticalityLabel } from "./ReqCriticalityLabel"
import { URSComment } from "./URSCommentSlice"
import { UrsGroup, URSRequirement, UrsRequirementType } from "./UrsSlice"
import { selectBlobsByResourceId } from "./ursAttachementsSlice"
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import CommentIcon from '@mui/icons-material/Comment'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import AttachmentIcon from '@mui/icons-material/Attachment'
import EditIcon from '@mui/icons-material/Edit'
import { AppId } from "../../app/appTypes"
import { RequirementDescription } from "./RequirementDescription"
import AddCircleIcon from '@mui/icons-material/AddCircle';

export interface URSRequirementRowProps {
    id: AppId
    onAddChildRequirmentClick: (parentRequirement: URSRequirement) => void
    onEditClick: (id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) => void
    onCopyClick: (id: AppId, group: UrsGroup | null, requirement: URSRequirement, rt: UrsRequirementType) => void
    onDeleteClick: (entity: URSRequirement) => void
    canEdit: boolean
    canAddChildRequirement: boolean
    canDelete: boolean
    showSupplierComment: boolean
    onCommentsClick: (requirement: URSRequirement) => void
    comments: URSComment[]
    onAttachementsClick: (requirement: URSRequirement) => void
    template: boolean
    requirement: URSRequirement
    group: UrsGroup & { typeDescription : string } | null
    rt : UrsRequirementType
}
export const URSRequirementRow = (props: URSRequirementRowProps) => {
  const { id, onAddChildRequirmentClick, onEditClick, onCopyClick, onDeleteClick, canEdit, canAddChildRequirement, canDelete, showSupplierComment, onCommentsClick, comments, onAttachementsClick, template, requirement, group } = props
  const commentsCount = useMemo(() => {
    return comments.filter(c => c.ursRequirementId === requirement.id && c.parentCommentId === null).length
  }, [requirement, comments])
  const unresolvedCommentsCount = useMemo(() => {
    return comments.filter(c => c.ursRequirementId === requirement.id && c.parentCommentId === null && c.status === 'N').length
  }, [requirement, comments])
  const blobs = useAppSelector(state => selectBlobsByResourceId(state, requirement.guid))
  const attachementsCount = blobs?.blobs?.length ?? 0
  const isNew: boolean = requirement.id === undefined || requirement.id.startsWith("new")
  return <TableRow id={requirement.guid} className="target:shadow-lg target:bg-slate-100 target:shadow-inner">
  <TableCell
      sx={{
        paddingRight: requirement.parentRequirementId ? '20px' : '2px',
        textAlign: requirement.parentRequirementId ? 'right' : 'left',
        backgroundColor: requirement.parentRequirementId ? '#fafafa' : 'inherit',
      }}
    >
      {requirement.numeration ?? 'null'}
    </TableCell>
    <TableCell><RequirementDescription description={requirement.name} /></TableCell>  
    <TableCell>{<ReqCriticalityLabel criticality={requirement.criticality ?? ""} />}</TableCell>
    <If condition={showSupplierComment}>
      <TableCell sx={{ textAlign: "center" }}>
        <NoteLabel note={requirement.note} />
      </TableCell>
      <TableCell>{requirement.comment}</TableCell>
    </If>
    <TableCell>
      <ButtonGroup variant="outlined" color="secondary" size="small">
        <If condition={requirement.parentRequirementId == null && canAddChildRequirement} otherwise={
          ( <IconButton size="small" style={{ visibility: 'hidden' }}>
            <AddCircleIcon />
          </IconButton>)
        }>
          <Tooltip title={<Localized id="">Dodaj podwymaganie</Localized>}>
            <IconButton size="small" onClick={() => onAddChildRequirmentClick(requirement)}><AddCircleIcon /></IconButton>
          </Tooltip>
        </If>
        <If condition={template || canEdit}>
          <Tooltip title={<Localized id="edit">Edytuj</Localized>}>
            <IconButton size="small" onClick={() => onEditClick(id, group, requirement, props.rt)}><EditIcon /></IconButton>
          </Tooltip>
          <Tooltip title={<Localized id="copy">Kopiuj</Localized>}>
            <IconButton size="small" onClick={() => onCopyClick(id, group, requirement, props.rt)}><ContentCopyIcon /></IconButton>
          </Tooltip>
        </If>
        <If condition={template || canDelete}>
          <Tooltip title={<Localized id="delete">Usuń</Localized>}>
            <IconButton size="small" onClick={() => onDeleteClick(requirement)}><DeleteForeverIcon /></IconButton>
          </Tooltip>
        </If>
        <If condition={!template}>
          <Badge color="info" badgeContent={attachementsCount} invisible={attachementsCount === 0}>
            <Tooltip title={<Localized id="attachements">Załączniki</Localized>}>
              <IconButton disabled={isNew} size="small" onClick={() => onAttachementsClick(requirement)}>
                <AttachmentIcon />
              </IconButton>
            </Tooltip>
          </Badge>
          <Badge color="info" badgeContent={commentsCount} invisible={commentsCount === 0}>
            <Badge color="error" anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} badgeContent={unresolvedCommentsCount} invisible={unresolvedCommentsCount === 0}>
              <Tooltip title={<Localized id="comments">Komentarze</Localized>}>
                <IconButton disabled={isNew} size="small" onClick={() => onCommentsClick(requirement)}><CommentIcon /></IconButton>
              </Tooltip>
            </Badge>
          </Badge>
        </If>
      </ButtonGroup>
    </TableCell>
  </TableRow>
}

