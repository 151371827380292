import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { Box, ButtonGroup, Dialog } from '@mui/material'
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { showSuccess } from '../notifications/notificationsSlice'
import { AppId } from '../../app/appTypes'
import { selectModule } from '../modules/moduleSlice'
import { If } from '../../app/If'
import { LoadingContainer } from '../../app/LoadingContainer'
import { selectSettings } from '../settings/settingsSlice'
import { enUS, plPL } from '@mui/x-data-grid/locales'
import { RestoreSysVersionDialog } from './RestoreSysVersionDialog'
import { AiQuestionEditDialog } from './AiQuestionEditDialog'
import { loadAiQuestions, selectAiQuestions, selectAllAiQuestions } from './aiQuestionsSlice'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import { ErrorContainer } from '../../app/ErrorContainer'

type DialogState =
    | { type: "none" }
    | { type: "update", templateId: AppId | undefined, editable: boolean }
    | { type: "restore", templateId: AppId }

   


const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])

    switch (state.type) {
        case "none":
            return null

        case "update":
            return <AiQuestionEditDialog
                id={state.templateId}
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
            />

        case "restore":
            return <RestoreSysVersionDialog
            id={state.templateId}
            onSaveSuccess={onSuccess}
            onCancel={onCancel}
            />
    }
}

export const ClientAIQuestions = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const { state } = useAppSelector(selectAiQuestions)
    const allQuestions = useAppSelector(selectAllAiQuestions)
    const currentModule = useAppSelector(state => selectModule(state).currentModule)

    const isSuper = currentModule?.code === "super"

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })

    const handleEdit = useCallback((templateId: AppId) => {
        setDialog({
            type: "update",
            templateId,
            editable: isSuper
        })
    }, [setDialog])

    const handleRestore = useCallback((templateId: AppId) => {
        setDialog({
            type: "restore",
            templateId
        })
    }, [setDialog])


    useEffect(() => {
        if (state.type === "empty") {
            dispatch(loadAiQuestions())
        }
    }, [dispatch, state])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };


    const columns: GridColDef[] =
        [
            {
                field: 'code',
                headerName: l10n.getString("question-code", null, "Kod"),
                width: 300
            },
            {
                field: "question",
                headerName: l10n.getString("question", null, "Pytanie"),
                flex: 1
            },
            {
                field: 'isCompanyTemplate',
                headerName: l10n.getString("mail-template-overwritten", null, "Czy nadpisano systemowy szablon?"),
                flex: 0,
                renderCell: (params) => {
                    return (
                        <Box display="flex" alignItems="center">
                            <If condition={params.row.companyId} otherwise={
                                <>
                                    <CloseIcon color="error"/>
                                    <Localized id="no">Nie</Localized>
                                </>
                            }>
                                <CheckIcon color="success"/>
                                <Localized id="yes">Tak</Localized>
                            </If>
                        </Box>
                    )
                }
            },
            {
                field: 'actions', type: 'actions', width: 350,
                renderCell: (params) => {
                    return (
                        <ButtonGroup>
                            { params.row.companyId ?
                                (<Button color="primary" onClick={() => handleRestore(params.row.id)} >
                                <Localized id="mail-restore-system">
                                    <span>Przywróć systemowy</span>
                                </Localized>
                            </Button>) : null
                            }
                            <Button color="primary" onClick={() => handleEdit(params.row.id)} >
                                <Localized id="edit">
                                    <span>Edytuj</span>
                                </Localized>
                            </Button>
                        </ButtonGroup>
                    )
                }
            }
        ];


    switch(state.type) {
        case "empty":
        case "loading":
            return <LoadingContainer/>
        case "error":
            return <ErrorContainer error={state.error}/>
        case "loaded":
            return (
                <>
                    <div style={{ display: 'flex', height: '100%' }}>
                        <div style={{ flexGrow: 1 }}>
                            <DataGrid style={{ minHeight: 100, height: 'calc(100vh - 160px)' }} localeText={getCustomLocaleText()} rows={allQuestions} columns={columns} onRowDoubleClick={({ row }) => handleEdit(row.id)} />
                        </div>
                    </div>
                    <Dialog open={dialog.type !== "none"} fullWidth maxWidth="lg" keepMounted={false}>
                        <DialogContentDispatcher state={dialog} setter={setDialog} />
                    </Dialog>
                </>
            )
    }
}
