import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { ColorChangingDot } from './ColorChangingDot';
import { Stack } from '@mui/material';
import { Gauge, gaugeClasses } from '@mui/x-charts';
import { MeetingStatusLabel } from '../MeetingStatusLabel';
import { MinuteMeetingStatus } from '../meetingMinutesSlice';

interface SimpleTimerProps {
    startDate: string;
    plannedMinutes: number;
    status: MinuteMeetingStatus;
}

export const SimpleTimer: React.FC<SimpleTimerProps> = ({ startDate, plannedMinutes, status }) => {
    const [currentTime, setCurrentTime] = useState<Date>(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentTime(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const timeDifference = currentTime.getTime() - new Date(startDate).getTime();
    const totalSeconds = Math.floor(timeDifference / 1000);
    const seconds = totalSeconds % 60;
    const totalMinutes = Math.floor(totalSeconds / 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    const leftMinutes = (plannedMinutes - totalMinutes);
    const leftMinutesFormatted = leftMinutes.toString();// + ":" + (60 - seconds);
    
    const redShades = ['#FF0000', '#FAA0A0']
    const redColor = redShades[Math.floor(Math.random() * redShades.length)]; // Losowanie koloru
    const orangeShades = ['#FFA500', '#FFE5B4']
    const orangeColor = orangeShades[Math.floor(Math.random() * orangeShades.length)]; // Losowanie koloru

    const color = leftMinutes > 10 ? '#52b202' : leftMinutes < 0 ?  redColor : orangeColor;

    const formatTime = (value: number): string => {
        return value.toString().padStart(2, '0');
    };

    return (
        <Stack direction="row-reverse">
            <Gauge sx={(theme) => ({
                [`& .${gaugeClasses.valueText}`]: {
                    fontSize: 20,
                },
                [`& .${gaugeClasses.valueArc}`]: {
                    fill: color
                },
                marginTop: "-12px"
            })} outerRadius="100%" innerRadius="80%" width={75} height={75} value={totalMinutes} valueMax={plannedMinutes} text={leftMinutesFormatted} />
            <Typography variant="button">
                <div>
                    <Stack direction="column">
                        <Typography variant="button" gutterBottom>
                            <span><MeetingStatusLabel status={status}></MeetingStatusLabel></span>
                        </Typography>
                        <Stack direction="row">
                        <div style={{marginTop:"7px"}}><ColorChangingDot /></div>
                            <Typography variant="button" gutterBottom>

                            {formatTime(hours)}:{formatTime(minutes)}:{formatTime(seconds)}
                            </Typography>

                        </Stack>
                    </Stack>


                </div>

            </Typography>
        </Stack>
    );
}