import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, EntityState } from "@reduxjs/toolkit"
import { RootState } from "../../app/store"
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes"
import { postWithAuth, putWithAuth, deleteWithAuth } from "../../http"
import { loadValidations } from "../validations/validationsSlice"
import { isSameRequestId, load } from "../../app/crud"

export interface DeviceType {
    id: AppId
    code: string
    name: string
    description: string
    areaId: string | null
}

export const loadDeviceTypes = load<void, DeviceType>(
    "deviceTypes/load",
    () => `api/device-types`,
    state => selectDeviceTypes(state).state,
)

export const createDeviceType = 
    createAsyncThunk<DeviceType, Omit<DeviceType, "id">, AppThunkAPIType>(
        "deviceTypes/create", 
        async (data, { rejectWithValue, dispatch }) => {
            const result = await dispatch(postWithAuth({ url: "api/device-types", payload: data }))
            const { payload } = result
            if (postWithAuth.fulfilled.match(result)) {
                return payload as DeviceType
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
    })

export const updateDeviceType = createAsyncThunk<DeviceType, DeviceType, AppThunkAPIType>(
    "deviceTypes/update", 
    async (deviceType: DeviceType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/device-types/${deviceType.id}`, payload: deviceType }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return deviceType
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteDeviceType = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "deviceTypes/delete",
    async (deviceTypeId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/device-types/${deviceTypeId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            await dispatch(loadValidations())
            return deviceTypeId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

const deviceTypeAdapter = createEntityAdapter<DeviceType>({
    selectId: deviceType => deviceType.id
})


export type DeviceTypeState = EntityState<DeviceType> & { state: AppDataState, }

const initialState: DeviceTypeState = deviceTypeAdapter.getInitialState({
    state: { type: "empty" },
})

export const deviceTypesSlice = createSlice({
    name: "deviceTypes",
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(deleteDeviceType.fulfilled, (state, action) => {
            deviceTypeAdapter.removeOne(state, action.payload)
        })
        builder.addCase(updateDeviceType.fulfilled, (state, action) => {
            const entity = action.payload
            deviceTypeAdapter.upsertOne(state, entity)
        })
        builder.addCase(createDeviceType.fulfilled, (state, action) => {
            const entity = action.payload
            deviceTypeAdapter.upsertOne(state, entity)
        })
        builder.addCase(loadDeviceTypes.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadDeviceTypes.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadDeviceTypes.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                deviceTypeAdapter.setAll(state, action.payload)  
                state.state = { type: "loaded" }
            }
        });
    },
})

export const selectDeviceTypes = (state: RootState): DeviceTypeState => state.deviceTypes;

export const 
    { selectAll: selectAllDeviceTypes
    , selectById: selectDeviceTypeById
    , selectTotal: selectTotalDeviceTypes
    , selectEntities: selectDeviceTypeEntities
    } = deviceTypeAdapter.getSelectors<RootState>(state => state.deviceTypes)

export const selectAreasDeviceTypes = createSelector(
    selectAllDeviceTypes, 
    deviceTypes => {
        const index = new Map<AppId | null, AppId[]>()
        for (const entity of deviceTypes) {
            const { areaId, id } = entity
            if (index.has(areaId)) {
                index.get(areaId)?.push(id)
            } else {
                index.set(areaId, [id])
            }
        }
        return index
    },
)

export default deviceTypesSlice.reducer