import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Alert, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { useState } from "react";
import { deleteDocumentTeamMember } from "../teamMemberApi";
import { useAppDispatch } from "../../../../app/hooks";
import { AppId } from "../../../../app/appTypes";
import { showError, showSuccess } from "../../../notifications/notificationsSlice";

export interface DeleteTeamMemberDialogProps {
    documentId: AppId
    teamMemberId: AppId
    userFullName: string
    onSuccessfulSave: (teamMemberId: AppId) => void
    onCancel: () => void
}

export const DeleteTeamMemberDialog = ({ documentId, teamMemberId, userFullName, onSuccessfulSave, onCancel }: DeleteTeamMemberDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")

    const handleOkClick = async () => {
        setSaving(true)
        if (documentId && teamMemberId) {
            try {
                const result = await dispatch(deleteDocumentTeamMember({ documentId, teamMemberId }))
                console.log(result)
                if (deleteDocumentTeamMember.fulfilled.match(result)) {
                    dispatch(showSuccess("deleted"))
                    onSuccessfulSave(teamMemberId)
                }
                else {
                    let errorMsg = "error"

                    if (result.payload && result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title

                        }
                    }
                    setServerError(errorMsg)
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
        setSaving(false)
    }

    return <Dialog open={true} fullWidth maxWidth='xs'>
        <DialogTitle>
            <Localized id="document-team-member-delete-confirmation" vars={{ userName: userFullName }}>
                <span>Czy napewno chcesz usunąć?</span>
            </Localized>
        </DialogTitle>
        <DialogContentText>
            {
                serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                    <Localized id={serverError}>
                        <span>Server error</span>
                    </Localized>
                </Alert>
            }
        </DialogContentText>
        <DialogActions>
            <LoadingButton
                loading={saving}
                color="error"
                variant="contained"
                loadingIndicator={<Localized id="saving" />}
                onClick={handleOkClick}>
                <Localized id="delete">
                    <span>delete</span>
                </Localized>
            </LoadingButton>
            <LoadingButton
                color="primary"
                variant="outlined"
                loading={saving}
                onClick={onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
