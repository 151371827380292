import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Dialog, DialogActions, DialogContentText, DialogTitle } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks";
import { deleteUrsTeamMember } from "../UrsSlice";

export interface DeleteTeamMemberDialogProps {
    id: AppId
    userFullName: string
    onSuccess: () => void
    onCancel: () => void
}

export const DeleteTeamMemberDialog = (props: DeleteTeamMemberDialogProps) => {
    const dispatch = useAppDispatch()
    const [errorMsg, setErrorMsg] = useState<string | null>(null)
    const [pending, setPending] = useState(false)

    const handleOkClick = () => {
        setPending(true)
        dispatch(deleteUrsTeamMember(props.id))
            .then(unwrapResult)
            .then(props.onSuccess)
            .catch(err => {
                if (typeof err === 'object' && err.kind && err.status) {
                    setErrorMsg(`Server error: ${err.status}`)
                } else {
                    setErrorMsg(`Unknown error: ${err}`)
                }
            })
            .finally(() => setPending(false))
    }

    return <Dialog open={true} fullWidth maxWidth='xs'>
        <DialogTitle>{props.userFullName}</DialogTitle>
        <DialogContentText>
            <Box sx={{ ml: 2, mr: 2 }}>
                <Localized id="confirm-delete">Czy napewno chcesz usunąć?</Localized>
                {errorMsg && <Alert severity="error">{errorMsg}</Alert>}
            </Box>
        </DialogContentText>
        <DialogActions>
            <LoadingButton loading={pending} onClick={handleOkClick}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={pending} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
