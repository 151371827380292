import { createAsyncThunk } from "@reduxjs/toolkit"
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../../http"
import { AppId, AppThunkAPIType } from "../../../app/appTypes"
import { Meeting } from "./meeting"


export type MinuteMeetingStatus =  'INMEETING' | 'CLOSED';

export interface MeetingMinuteSummary {
    id: string,
    title: string,
    status: MinuteMeetingStatus,
    taskCount: number
    itemCount : number
    overdueTaskCount : number
    lastMeetingId: string
    lastMeetingEndDate: string
}

export interface MeetingMinute {
    id: string,
    title: string,
    content: string,
    status: MinuteMeetingStatus,
    parentObjectGuid: string,
    parentObjectType: string,
    createdByFullName: string,
    createDate: string,
    modifiedByFullName: string,
    modifyDate: string,
    items: MeetingMinuteItem[]
    meetings: Meeting[]
    lastMeetingId: number | null
}


export interface MeetingMinuteItem {
    id: string,
    meetingMinutesId: string,
    projectProcessId: number,
    sharedGuid: string,
    name: string,
    description: string,
    priority: string,
    status: string,
    no: number,
    createdByFullName: string,
    createDate: string,
    modifiedByFullName: string,
    modifyDate: string,
    deadline: string | null,
    responsiblePerson: string,
    responsibleCompany: string,
    itemType: string
    parentItemId: string,
    history: ItemHistory[]
    tasks: MeetingMinuteItem[]
}

export interface ItemHistory {
    id: string,
    createDate : string,
    description: string,
    createdByFullName: string
}
export interface CreateMeetingMinutePayload {
    title: string,
    parentObjectGuid: string,
    parentObjectType: string
}

export interface CloseMeetingMinutePayload {
    meetingMinuteId: string,
    sendGeneralReport: boolean,
    listOfRecipients: string[],
    attachNoteAttachments: boolean,
    language: string
}

export interface SendReportPayload{
    meetingMinuteId: string,
    listOfRecipients: string[],
    attachNoteAttachments: boolean,
    language: string
}

export interface MeetingMinuteStatistics {
    id: string,
    averageMeetingTime: string
    maxMeetingTime: string
    minMeetingTime: string
    meetingsCount: number
    meetingsEndedBeforeTime: number
    meetingsEndedAfterTime: number
    meetingsEndedOnTime: number
}

export const loadSummaryForObject = createAsyncThunk<MeetingMinuteSummary[], { objectId: string, objectType: string }, AppThunkAPIType>(
    "meetingminutes/loadForObject", async ({ objectId, objectType }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/meeting-minutes/summary/${objectId}/${objectType}`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinuteSummary[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })


export const loadMeetingMinutesForObject = createAsyncThunk<MeetingMinute[], { objectId: string, objectType: string }, AppThunkAPIType>(
    "meetingminutes/loadForObject", async ({ objectId, objectType }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/meeting-minutes/load-for-object/${objectId}/${objectType}`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinute[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadMeetingMinutes = createAsyncThunk<MeetingMinute, string, AppThunkAPIType>(
    "meetingminutes/load", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/meeting-minutes/${id}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinute
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })



export const createMeetingMinute = createAsyncThunk<MeetingMinute, CreateMeetingMinutePayload, AppThunkAPIType>(
    "meetingminutes/create",
    async (createPayload: CreateMeetingMinutePayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/meeting-minutes",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinute
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const updateMeetingMinute = createAsyncThunk<MeetingMinute, MeetingMinute, AppThunkAPIType>(
    "meetingminutes/update",
    async (note: MeetingMinute, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: `api/meeting-minutes/${note.id}`,
            payload: note,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinute
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)



export interface CreateItemPayload {
    meetingMinutesId: string
    name: string
    projectProcessId: string
    description: string
    priority: string
}

export interface CreateItemTaskPayload {
    meetingMinutesId: string
    name: string
    description: string
    priority: string
    responsiblePerson: string
    responsibleCompany: string
    deadline: string
    parentItemId: string
}

export const createMeetingMinuteItem = createAsyncThunk<MeetingMinuteItem, CreateItemPayload, AppThunkAPIType>(
    "meetingminutesitems/create",
    async (createItemPayload: CreateItemPayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/meeting-minutes-items",
            payload: createItemPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinuteItem
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateMeetingMinuteItem = createAsyncThunk<MeetingMinuteItem, MeetingMinuteItem, AppThunkAPIType>
    ("meetingminutesitems/update", async (data, { dispatch, rejectWithValue }) => {

        const result = await dispatch(putWithAuth({
            url: `api/meeting-minutes-items/${data.id}`,
            payload: data,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinuteItem
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

    export const deleteMeetingMinuteItem = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
        "meetingminutesitems/delete",
        async (itemId, { rejectWithValue, dispatch }) => {
            const result = await dispatch(deleteWithAuth({
                url: `api/meeting-minutes-items/${itemId}`
            }))
            const { payload } = result
    
            if (deleteWithAuth.fulfilled.match(result)) {
                return itemId
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
    )

export const loadMeetingMinutesItemTasks = createAsyncThunk<MeetingMinuteItem[], string, AppThunkAPIType>(
    "meeting-minutes-items/loadtasks", async (id, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/meeting-minutes-items/item-tasks/${id}/`,
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as MeetingMinuteItem[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

    export const createMeetingMinuteItemTask = createAsyncThunk<MeetingMinuteItem, CreateItemTaskPayload, AppThunkAPIType>(
        "meetingminutesitems/createtask",
        async (createItemPayload: CreateItemTaskPayload, { rejectWithValue, dispatch }) => {
            const result = await dispatch(postWithAuth({
                url: "api/meeting-minutes-items/item-tasks",
                payload: createItemPayload,
            }))
            const { payload } = result
    
            if (postWithAuth.fulfilled.match(result)) {
                return payload as MeetingMinuteItem
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
    )

    export const updateMeetingMinuteItemTask = createAsyncThunk<MeetingMinuteItem, MeetingMinuteItem, AppThunkAPIType>(
        "meetingminutesitems/updatetask",
        async (updatePayload: MeetingMinuteItem, { rejectWithValue, dispatch }) => {
            const result = await dispatch(putWithAuth({
                url: "api/meeting-minutes-items/item-tasks",
                payload: updatePayload,
            }))
            const { payload } = result
    
            if (putWithAuth.fulfilled.match(result)) {
                return payload as MeetingMinuteItem
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
    )
    
    export const closeMeeting = createAsyncThunk<MeetingMinute, CloseMeetingMinutePayload, AppThunkAPIType>(
        'meetingminutes/closemeeting',
        async (closeMeetingMinutePayload: CloseMeetingMinutePayload, { dispatch, rejectWithValue }) => {
            const response = await dispatch(postWithAuth({
                url: `api/meeting-minutes/close-meeting`,
                payload: closeMeetingMinutePayload,
            }))
            const { payload } = response
            if (postWithAuth.fulfilled.match(response)) {
                return payload as MeetingMinute
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
    })
        
    export const sendGeneralReport = createAsyncThunk<void, SendReportPayload, AppThunkAPIType>(
        'meetingminutes/send-report',
        async (sendReportPayload: SendReportPayload, { dispatch, rejectWithValue }) => {
            const response = await dispatch(postWithAuth({
                url: `api/meeting-minutes/send-report`,
                payload: sendReportPayload
            }))
            const { payload } = response
            if (postWithAuth.fulfilled.match(response)) {
                return;
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
    })
        
    export const loadMeetingMinutesStatistics = createAsyncThunk<MeetingMinuteStatistics, string, AppThunkAPIType>(
        "meetingminutes/statistics", async (id, { dispatch, rejectWithValue }) => {
            const result = await dispatch(getWithAuth({
                url: `api/meeting-minutes/statistics/${id}/`,
            }))
            const { payload } = result
            if (getWithAuth.fulfilled.match(result)) {
                return payload as MeetingMinuteStatistics
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        })
    