import { ReactNode } from "react"
import { MeasurementHeader } from "./measurementsApi"
import { formatDate, parseISODate } from "../../app/Utils"

interface HeaderFieldProps {
    children: ReactNode
}
const HeaderField = (props: HeaderFieldProps) => {
    return <div className="px-2 py-1 text-lg">{props.children}</div>
}
interface HeaderLabelProps {
    children: ReactNode
}
const HeaderLabel = (props: HeaderLabelProps) => {
    return <div className="text-xs font-bold px-2 py-1 bg-gray-100">{props.children}</div>
}

export const MeasurementFormHeader = ({ measurement }: { measurement: MeasurementHeader }) => {

    return <>
        <div className="grid grid-cols-6 border-solid border rounded border-gray-300 shadow">

            <HeaderLabel>Nazwa pomiaru</HeaderLabel>
            <HeaderLabel>Miejsce pomiaru</HeaderLabel>
            <HeaderLabel>Klient</HeaderLabel>
            <HeaderLabel>Data pomiaru</HeaderLabel>
            <HeaderLabel>Przeprowadził</HeaderLabel>
            <HeaderLabel>Status</HeaderLabel>
            <HeaderField>{measurement.name}</HeaderField>
            <HeaderField>{measurement.location}</HeaderField>
            <HeaderField>{measurement.clientCompany}</HeaderField>
            <HeaderField>{formatDate(parseISODate(measurement.date))}</HeaderField>
            <HeaderField>{measurement.performedBy}</HeaderField>
            <HeaderField>{measurement.status}</HeaderField>

        </div>
    </>
}