import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Paper, Tooltip } from "@mui/material"
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { showDialog } from "./dialogSlice"
import { useCallback, useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { getWithAuth } from "../../http"
import TranslateIcon from '@mui/icons-material/Translate';
import { If } from "../../app/If"
import { selectSettings } from "../settings/settingsSlice"

export interface HelpDialogProps {
    id: string;
}

type Language = "pl" | "en-US";

export const HelpDialog = (props: HelpDialogProps) => {
    const dispatch = useAppDispatch()
    const { locale } = useAppSelector(selectSettings);

    const [language, setLanguage] = useState<Language>(locale);
    const [titlePL, setTitlePL] = useState<string>("");
    const [descriptionPL, setDescriptionPL] = useState<string>("");
    const [titleEN, setTitleEN] = useState<string>("");
    const [descriptionEN, setDescriptionEN] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const fetchArticle = async () => {
        setLoading(true);
        try {
            const result = await dispatch(getWithAuth({
                url: `api/help-articles/${props.id}`,
            }))
            
            if (getWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setTitlePL(payload.title)
                setDescriptionPL(payload.description)
                setTitleEN(payload.title_EN)
                setDescriptionEN(payload.description_EN)
            }
        }
        finally {
            setLoading(false)
        }
    }

    const handleLanguageChange = () => {
        if(language === "pl") {
            setLanguage("en-US");
        } else {
            setLanguage("pl");
        }
    }

    useEffect(() => {
        fetchArticle();
    }, [])

    const closeHelpGenerateDialog = useCallback(() => { dispatch(showDialog({ tag: 'none' })) }, [])

    return <Dialog open={true}>
        <If condition={!loading} otherwise={<DialogContent><CircularProgress/></DialogContent>}>
            <DialogTitle>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <span>{language === "pl" ? "Pomoc" : "Help"}</span>
                    <Tooltip title={language === "pl" ? "Translate" : "Przetłumacz"}>
                        <IconButton onClick={handleLanguageChange}>
                            <TranslateIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    <span>{language === "pl" ? titlePL : titleEN}</span>
                    <Paper sx={{ padding: 1 }}>
                        <Content content={language === "pl" ? descriptionPL : descriptionEN} />
                    </Paper>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={closeHelpGenerateDialog} color="primary">
                    <span>{language === "pl" ? "Zamknij" : "Close"}</span>
                </Button>
            </DialogActions>
        </If>
    </Dialog>
}


export const Help = (props: HelpDialogProps) => {
    const dispatch = useAppDispatch()

    const openHelpGenerateDialog = useCallback(() => {
        dispatch(showDialog({ tag: 'help', props }))
    }, [props])

    return <IconButton size="small" onClick={openHelpGenerateDialog}>
        <InfoOutlinedIcon fontSize="small" color="info" />
    </IconButton>
}

const Content = (props: { content: string | JSX.Element }) => {
    if (typeof props.content === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: props.content }} />
    } else {
        return props.content
    }
}

