import { Localized } from "@fluent/react";
import { IconButton } from "@mui/material";
import DangerousIcon from '@mui/icons-material/Dangerous';
import CheckIcon from '@mui/icons-material/Check';
import WarningIcon from '@mui/icons-material/Warning';
import MarkEmailUnread from "@mui/icons-material/MarkEmailUnread";
import { PersonOff } from "@mui/icons-material";

export const UserStatusLabel = ({ status }: { status: string }) => {
    switch (status) {
        case "INVITED":
            return <>
                <IconButton >
                    <MarkEmailUnread color='warning' />
                </IconButton > <Localized id="user-status-invited">
                    <span>Invited</span>
                </Localized>
            </>
        case "LOCKED":
            return <>
                <IconButton >
                    <DangerousIcon color='error' />
                </IconButton > <Localized id="user-status-locked">
                    <span>Locked</span>
                </Localized>
            </>
        case "NOT_CONFIRMED":
            return <>
                <IconButton >
                    <WarningIcon color='warning' />
                </IconButton >
                <Localized id="user-status-not-confirmed">
                    <span>
                        Not confirmed
                    </span>
                </Localized>
            </>
        case "ARCHIVED":
            return <>
                <IconButton >
                    <PersonOff color='error' />
                </IconButton > <Localized id="user-status-archived">
                    <span>Archived</span>
                </Localized>      </>
        case "ACTIVE":
            return <>
                <IconButton >
                    <CheckIcon color='success' />
                </IconButton > <Localized id="user-status-active">
                    <span>Active</span>
                </Localized>      </>
        default:
            return <span></span>
    }
}