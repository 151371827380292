import { Localized, useLocalization } from "@fluent/react"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { loadMaterialCards, selectAllMaterialCards, selectMaterialCards } from "./materialCardSlice"
import { useCallback, useEffect, useState } from "react"
import { AppId } from "../../../app/appTypes"
import { Box, Button, ButtonGroup, Fab } from "@mui/material"
import { FlexDiv } from "../../../app/Utils"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import AddIcon from '@mui/icons-material/Add'
import { Link } from 'react-router-dom'
import { MaterialCardStatusLabel } from "./MaterialCardStatusLabel"

export const MaterialCardList = () => {

    const { loaded } = useAppSelector(selectMaterialCards)
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const rows = useAppSelector(state => selectAllMaterialCards(state))
    const [deleting, setDeleting] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
   
  
    
    useEffect(() => {
        if (!loaded) {
            dispatch(loadMaterialCards())
        }
    }, [dispatch, loaded])


    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const columns: GridColDef[] = 
    [
        { 
            field: 'cardNumber', 
            width: 150,
            headerName:l10n.getString('',null,'Numer'),  

        },
        { 
            field: 'name', 
            headerName: l10n.getString('',null,'Nazwa materiału'),   
            flex:1 
        },
        { 
            field: 'contractor', 
            headerName: l10n.getString('',null,'Wykonawca'),   
            flex:1 
        },
        { 
            field: 'industrySector', 
            headerName: l10n.getString('',null,'Branża'),   
            flex:1 
        },
        { 
            field: '', 
            headerName: l10n.getString('',null,'Project'),   
            width: 150,
            valueGetter: (params) => {
                return 'Projekt Budowa Hali 2023/05'
            }
        },
        { 
            field: 'date', 
            headerName: l10n.getString('',null,'Data utworzenia'),   
            width: 150,
            valueGetter: (_, row) => {
                const date = new Date(row.date);
                const formatedDate =  date.toISOString().split('T')[0];
                return formatedDate;
            }
        },
        { 
            field: 'status', 
            headerName: l10n.getString('',null,'Status'),   
            width: 150,
            renderCell:(params) => (
                <div>
                    <MaterialCardStatusLabel status={params.value ?? ""}/>
                </div>
                
            )
        },
        { 
            field: 'actions', type: 'actions', width: 200,
            renderCell: (params) => {
                return(
                <ButtonGroup>
                    <Button component={Link} to={`/materialcards/edit/${params.id}`} color="secondary" variant="outlined">
                            <Localized id="edit">
                            <span>Edytuj</span> 
                        </Localized>
                    </Button>
                </ButtonGroup>
                )
            }
        }
    ];
    
    return (
        <>
        <Box sx={{ width: '100%' }}>
            <div style={{ display: 'flex', height: '100%' }}>
                <div style={{ flexGrow: 1 }}>
                <DataGrid autoHeight rows={rows} columns={columns} />
                </div>
            </div>
            <FlexDiv>
                <Fab component={Link} to="/materialcards/create" color="secondary" sx={{marginTop:2}}>
                    <AddIcon />
                </Fab>
            </FlexDiv>
        </Box>
        </>
    )
}
