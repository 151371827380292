import { AppId, AppTextFieldHandler } from "../../app/appTypes";
import Paper from "@mui/material/Paper"
import Button from '@mui/material/Button'
import { useCallback, useState } from "react"
import { Localized } from "@fluent/react"
import { useAppSelector } from "../../app/hooks"
import { Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Skeleton } from '@mui/material';
import { useLocalStorage } from "../../useLocalStorage";
import { selectAllAreas, selectAreas } from "./areaSlice";

export const AreasDialogBrowser = (
    { selection, open, itemChecked, itemUnchecked, handleOkClick, handleCancelClick }: {        
        selection: Set<AppId>,
        open: boolean,        
        itemChecked: (areaId: AppId) => void,
        itemUnchecked: (areadId: AppId) => void,
        handleOkClick: () => void,
        handleCancelClick: () => void
    }) => {
               
        return <>
            <Dialog 
                open={open}
                maxWidth="lg" 
                fullWidth={true}
                PaperProps={{
                    sx: {      
                        height: "80%"      
                    }
                }}>
            <DialogTitle>
                <Typography variant="subtitle2" color="textSecondary" gutterBottom >
                    <Localized id="menu-areas">
                        <span>Obszary</span>
                    </Localized>
                </Typography>
            </DialogTitle>
            <DialogContent>
                <AreasBrowser 
                    selection={selection} 
                    itemChecked={itemChecked}   
                    itemUnchecked={itemUnchecked}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleOkClick} color="secondary" variant="contained">
                    <Localized id="ok">
                        <span>OK</span>
                    </Localized>
                </Button>
                <Button onClick={handleCancelClick}>
                    <Localized id="cancel"><span>Anuluj</span></Localized>
                </Button>
            </DialogActions>
        </Dialog>
    </>;
}


export const AreasBrowser = (
    { selection, itemChecked, itemUnchecked }: {        
        selection: Set<AppId>,
        itemChecked: (id: AppId) => void,
        itemUnchecked: (id: AppId) => void         
    }) => {
    const { state: areasState } = useAppSelector(selectAreas)
    const allAreas = useAppSelector(selectAllAreas)    
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useLocalStorage("AreasDialog_RowPerPage", "50");        
    const count = allAreas.length
    const curPage = allAreas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)   

    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [ setPage ])
    
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        let val = parseInt(event.target.value, 10);
        setRowsPerPage(val)
        setPage(0)                
    }, [ setPage, setRowsPerPage ])
   
    const handleCheckboxChange = (areaId: AppId, checked: boolean) => {
        if (checked) {
            itemChecked(areaId)
        } else {
            itemUnchecked(areaId)
        }
    } 
 

    switch (areasState.type) {
        case "empty":
        case "error":
        case "loading":
            return <Skeleton animation="wave" variant="rectangular" height="64vh" ></Skeleton>;
        case "loaded":
            return <>                                      
                                           
                <TableContainer  component={Paper}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell sx = {{minWidth:80, width:80, maxWidth:80}}>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        <Localized id="area-code">
                                            <span>Kod</span>
                                        </Localized>
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="subtitle2" color="textSecondary">
                                        <Localized id="area-name">
                                            <span>Nazwa </span>
                                        </Localized>
                                    </Typography>
                                </TableCell>                                                                                           
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {curPage.map(({ id, code, name }) => {
                                return <TableRow key={id}>
                                    <TableCell padding="checkbox">
                                        <Checkbox checked={selection.has(id)} onChange={(_, checked) => handleCheckboxChange(id, checked)}/>
                                    </TableCell>
                                    <TableCell>{code}</TableCell>
                                    <TableCell>{name}</TableCell>                                    
                                  
                                </TableRow>
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    component="div"
                    count={count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </>
    }
}