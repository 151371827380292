import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogTitle, DialogContent, Grid, TextField, Alert, DialogActions } from "@mui/material"
import { useState, useCallback } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { If } from "../../../app/If"
import { showSuccess } from "../../notifications/notificationsSlice"
import { selectActivityById, Activity, AcceptanceCriteria } from "../activitiesSlice"
import { UpdateStepAction } from "../types"

export interface UpdateCriteriaDialogFormProps {
    stepId: AppId
    id: AppId
    close: () => void
    update: UpdateStepAction
}

export const UpdateCriteriaDialogForm = ({ stepId, id: criteriaId, update, close }: UpdateCriteriaDialogFormProps) => {
    const dispatch = useAppDispatch()
    const step = useAppSelector(state => selectActivityById(state, stepId)) as Activity
    const { acceptanceCriteria } = step
    const criteria = acceptanceCriteria.find(({ id }) => id === criteriaId ) as AcceptanceCriteria
    const [description, setDescription] = useState(criteria.description)

    const [error, setError] = useState<string | undefined>(undefined)
    const [saving, setSaving] = useState(false)

    const handleDescriptionChange: AppTextFieldHandler = useCallback(e => setDescription(e.target.value), [])

    const handleOKClick = async () => {
        if (description) {
            try {
                setSaving(true)
                const rest = acceptanceCriteria.filter(({ id }) => id !== criteriaId)
                await dispatch(update({ ...step, acceptanceCriteria: [...rest, { ...criteria, description }] })).unwrap()
                close()
                dispatch(showSuccess("saved"))
            } catch (error) {
                setError("error")
                setSaving(false)
            }
        }
    }

    return <>
        <DialogTitle>
            <Localized id="activity-criteria-edit">Edycja kryterium akceptacji</Localized>
        </DialogTitle>
        <DialogContent>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField 
                        fullWidth
                        sx={{ marginTop: 2 }}
                        value={description}
                        inputProps={{ maxLength: 200 }}
                        onChange={handleDescriptionChange}
                        label={<Localized id="activity-criteria-description">Opis</Localized>}
                    />
                </Grid>
                <If condition={error !== undefined}>
                    <Grid item xs={12}>
                        <Alert severity="error">
                            <Localized id="error">Błąd</Localized>
                        </Alert>
                    </Grid>
                </If>
            </Grid>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOKClick}>
                <Localized id="ok">OK</Localized>
            </LoadingButton> 
            <LoadingButton loading={saving} onClick={close}>
                <Localized id="cancel">Anuluj</Localized>
            </LoadingButton> 
        </DialogActions>
    </>
}