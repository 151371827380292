import { Badge, Box, Button, ButtonGroup, Link, Card, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, InputAdornment, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Stack, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Typography, FormControlLabel, Checkbox } from "@mui/material";
import { Skeleton } from '@mui/material';
import { useCallback, useEffect, useMemo, useState, MouseEvent } from "react";
import { APIError, AppId, AppTextFieldHandler } from "../../app/appTypes";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import Check from '@mui/icons-material/Check';
import Clear from '@mui/icons-material/Clear';
import { Localized } from "@fluent/react";
import { LoadRequirementsTypes, LoadValidations } from "../../app/AppDataLoader";
import { loadUrs, selectUrs, updateURS, URSRequirement, Urs, updateURSRequirement } from "../urs/UrsSlice";
import { If } from "../../app/If";
import { selectAllValidations, selectValidationEntities, Validation, ValidationStage } from "../validations/validationsSlice";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { LoadingButton } from "@mui/lab";
import { DeviceType, selectDeviceTypeById } from "../device/deviceTypesSlice";
import { Link as RouterLink } from 'react-router-dom';
import { UrsStatusLabel } from "../urs/UrsStatusLabel";
import { selectSettings } from '../settings/settingsSlice';
import { ReqCriticalityLabel } from "../urs/ReqCriticalityLabel";
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { postWithAuth } from "../../http";
import { updateRiskLimit, updateRiskRequirement } from "./riskAnalysisSlice";
import JoinInnerIcon from '@mui/icons-material/JoinInner';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import LinkIcon from '@mui/icons-material/Link';
import { AppLink } from "../../app/AppLink";
import { AssociateTest } from "./AssociateTest";
import { ServerErrorMsg } from "../../app/ServerErrorMsg";
import { ValidationTooltip } from "../validations/ValdationTooltip";
import { useNavigate, useParams } from "react-router-dom";

const LoadComponent = () => <Skeleton variant="rectangular" height="50vh" />

interface URSRequirementRowProps {
    requirement: URSRequirement,
    limit: number,
    onEditClick: (req: URSRequirement) => void
    assocTest: (req: URSRequirement) => void
    deleteTestAssoc: (req: URSRequirement) => void
    canEdit: boolean
}

const URSRequirementRow = ({ requirement,limit, onEditClick, assocTest, deleteTestAssoc }: URSRequirementRowProps) => {
    const { testDefinitionId } = requirement
    return <TableRow>
        <TableCell>{requirement.numeration ?? 'null'}</TableCell>
        <TableCell>
            <div dangerouslySetInnerHTML={{ __html: requirement?.name ?? "" }}></div>
        </TableCell>
        <TableCell>{<ReqCriticalityLabel criticality={requirement.criticality ?? ""} />}</TableCell>
        <TableCell sx={{
            textAlign: 'right',
        }}>{requirement.severity}</TableCell>
        <TableCell sx={{
            textAlign: 'right',
        }}>{requirement.frequency}</TableCell>
        <TableCell sx={{
            textAlign: 'right',
        }}>{requirement.detection}</TableCell>
        <TableCell sx={{
            textAlign: 'center',
        }}>
            <If condition={testDefinitionId !== null} otherwise={<Clear fontSize="small" color='error' />}>
                {testDefinitionId && <ValidationTooltip testId={testDefinitionId}>
                    <Check fontSize="small" color='success' />
                </ValidationTooltip>}
            </If>
        </TableCell>
        <TableCell sx={{
            textAlign: 'center',
        }}>
            <Badge badgeContent={requirement.severity * requirement.detection * requirement.frequency} 
            color={(requirement.severity * requirement.detection * requirement.frequency) > limit ? "error" : "default"} />
        </TableCell>
        <TableCell sx={{ textAlign: "center" }}>
            <ButtonGroup>
                <TestButtonMenu requirement={requirement} associateTest={assocTest} deleteAssociation={() => { deleteTestAssoc(requirement) }} />
                <Button onClick={() => onEditClick(requirement)} color="secondary" variant="outlined">
                    <Localized id="edit">
                        <span>Edytuj</span> 
                    </Localized>
                </Button> 
            </ButtonGroup>
        </TableCell>
    </TableRow>
}
interface TestButtonManuProps {
    requirement: URSRequirement
    associateTest: (requirement: URSRequirement) => void
    deleteAssociation: (requirementId: AppId, testId: AppId) => void
}
const TestButtonMenu = ({ requirement, associateTest, deleteAssociation  }: TestButtonManuProps) => {
    const { id: requirementId } = requirement
    const { testDefinitionId } = requirement
    const mapping = requirement.testDefinitionId !== null
    const noMapping = requirement.testDefinitionId === null
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, [])
    const handleClick = useCallback((e: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(e.currentTarget)
    }, [])
    const handleAssociateTest = useCallback(() => {
        associateTest(requirement)
        setAnchorEl(null)
    }, [requirementId, setAnchorEl, associateTest])
    const handleDeleteAssoc = useCallback(() => {
        if (requirement.testDefinitionId) {
            deleteAssociation(requirementId, requirement.testDefinitionId)
            setAnchorEl(null)
        } 
    }, [requirementId, deleteAssociation, setAnchorEl, mapping])

    return <Box>
        <Button color="secondary" variant={mapping ? "contained" : "outlined"} onClick={handleClick}>Test</Button>
        <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {[noMapping && <MenuItem key="create">
                    <ListItemIcon><NoteAddIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>
                        <Link 
                            component={AppLink}
                            to={{ pathname: "/validation/new", 
                                  // search: `?ursRequirementId=${requirement.id}`,
                                  state: { defaultName: requirement.name, ursRequirement: requirement, },
                               }}
                        >Utwórz nowy test</Link>
                    </ListItemText>
                </MenuItem>
            , noMapping && <MenuItem key="assoc" onClick={handleAssociateTest}>
                    <ListItemIcon><JoinInnerIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>Skojarz z testem</ListItemText>
                </MenuItem>
            , mapping   && <MenuItem key="deleteAssoc" onClick={handleDeleteAssoc}>
                    <ListItemIcon><DeleteForeverIcon fontSize="small" color="error" /></ListItemIcon>
                    <ListItemText>Usuń skojarzenie</ListItemText>
                </MenuItem>
            , mapping   && <MenuItem key="goToTest">
                    <ListItemIcon><LinkIcon fontSize="small" /></ListItemIcon>
                    <ListItemText>
                        <Link component={AppLink} to={{ pathname: `/validation/edit/${testDefinitionId}` }}>Przejdź do testu</Link>
                    </ListItemText>
                </MenuItem>
            ]}
        </Menu>
    </Box>
}

const getDeviceTypeName = (dt: DeviceType) => dt.name

export const EditRiskAnalysis = () => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const dispatch = useAppDispatch()
    const urs = useAppSelector(selectUrs)

    useEffect(() => {
        const fetchData = async () => {
            if (urs.data.id !== id) {
                
                try {
                    await dispatch(loadUrs(id)).unwrap()
                } catch (err) {
                    
                }
            }
        }
        fetchData()
    }, [dispatch, urs, id])

    switch (urs.data.id !== id) {
        case true:
            return <LoadComponent />
        case false:
            return <LoadRequirementsTypes component={<LoadComponent />}>
                <LoadValidations component={<LoadComponent />}>
                    <Form entity={urs.data} />
                </LoadValidations>
            </LoadRequirementsTypes>
    }
}

enum FormTab {
    Reqs,
    Description,
}

const Form = ({ entity }: { entity: Urs }) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const allValidations = useAppSelector(selectAllValidations)
    const validationEntities = useAppSelector(selectValidationEntities)
    const deviceType = useAppSelector(state => selectDeviceTypeById(state, entity.deviceTypeId))

    const [draftEntity, setDraftEntity] = useState(entity)
    const [requirement, setRequirement] = useState<URSRequirement | undefined>(undefined)

    const [err, setErr] = useState<APIError | null>(null)
    const [saving, setSaving] = useState(false)
    const [dialog, setDialog] = useState(false)
    const [assocTestDialog, setAssocTestDialog] = useState(false)
    const [reqToAssocWithTest, setReqToAssocWithTest] = useState<URSRequirement | undefined>(undefined)
    const [limitDialog, setLimitDialog] = useState(false)
    const [tab, setTab] = useState<FormTab>(FormTab.Reqs)

    const [description] = useState(draftEntity.description)
    const [limit, setLimit] = useState<number>(draftEntity.limit)

    const [reqId, setReqId] = useState<AppId | undefined>(undefined)
    const [potentialDefect, setPotentialDefect] = useState("")
    const [consequence, setConsequence] = useState("")
    const [reason, setReason] = useState("")
    const [comment, setComment] = useState("")
    const [frequency, setFrequency] = useState(0)
    const [skipTest, setSkipTest] = useState(false);

    const [detection, setDetection] = useState(0)
    const [severity, setSeverity] = useState(0)
    const [reqTypeId, setReqTypeId] = useState<AppId | undefined>(undefined)
    const [reqDescription, setReqDescription] = useState("")
    const [reqStage, setReqStage] = useState<ValidationStage | undefined>(undefined)
    const [validation, setValidation] = useState<Validation | undefined>(undefined)
    const { locale } = useAppSelector(selectSettings);
    const [waitingForAnswer, setWaitingForAnswer] = useState<boolean>(false);

    const [contextTypeId, setContextTypeId] =
        useState<AppId | undefined>(entity.types.length > 0 ? entity.types[0].id : undefined)
    const contextType = useMemo(() => draftEntity.types.find(x => x.id === contextTypeId), [contextTypeId, draftEntity.types])

    const handleReqsTabChange = useCallback((_, newType) => {
        setContextTypeId(newType)
    }, [])

    const handlePotentialDeffectChange: AppTextFieldHandler =
        useCallback(e => { setPotentialDefect(e.target.value) }, [])
    const handleConsequenceChange: AppTextFieldHandler =
        useCallback(e => { setConsequence(e.target.value) }, [])
    const handleReasonChange: AppTextFieldHandler =
        useCallback(e => { setReason(e.target.value) }, [])
    const hadnleCommentChange: AppTextFieldHandler =
        useCallback(e => { setComment(e.target.value) }, [])    
    const handleDetectionChange: AppTextFieldHandler =
        useCallback(e => { setDetection(Number.parseInt(e.target.value)) }, [])
    const handleFrequencyChange: AppTextFieldHandler =
        useCallback(e => { setFrequency(Number.parseInt(e.target.value)) }, [])
    const handleSeverityChange: AppTextFieldHandler =
        useCallback(e => { setSeverity(Number.parseInt(e.target.value)) }, [])

    const handleSkipTest = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSkipTest(event.target.checked);
    };
    
        
    const handleLimitChange: AppTextFieldHandler =
        useCallback(e => { setLimit(Number.parseInt(e.target.value)) }, [])

    const handleEditLimitClick = useCallback(() => {
        setLimitDialog(true)
    }, [])

    const handleEditLimitClose = useCallback(() => {
        setLimitDialog(false)
    }, [])

    const handleAskRiskQuestionClick = async () => {
        setWaitingForAnswer(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/ai/ask/risk`,
                payload: {
                    Type: 'risk-for-requirement',
                    Question: reqDescription
                }
            }))
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setPotentialDefect(payload.potentialDefect)
                setConsequence(payload.consequence)
                setReason(payload.reason)
            }
        }
        finally {
            setWaitingForAnswer(false)
        }
    }

    const handleDialogCancelClick = useCallback(() => {
        setDialog(false)
    }, [])
    const handleEditReqButtonClick = useCallback((r: URSRequirement) => {
        const { id, potentialDefect, consequence, reason, frequency, detection, severity, typeId, name, stage, testDefinitionId } = r
        setRequirement(r)
        setReqId(id)
        setPotentialDefect(potentialDefect)
        setConsequence(consequence)
        setReason(reason)
        setFrequency(frequency)
        setDetection(detection)
        setSeverity(severity)
        setReqTypeId(typeId ?? '')
        setReqDescription(name)
        setReqStage(stage ?? "DQ")
        setValidation(validationEntities[testDefinitionId ?? ""])
        setDialog(true)
    }, [validationEntities])

    const handleDialogOkButtonClick = async () => {
        if (requirement ) {
            const newUrs = await dispatch(updateRiskRequirement({
                ...requirement,
                potentialDefect,
                consequence,
                reason,
                severity,
                frequency,
                detection,
                stage: reqStage ?? null,
                typeId: "",
                group: "",
                testDefinitionId: validation?.id ?? null,
            })).unwrap()

            dispatch(showSuccess("saved"));

            setDraftEntity(newUrs)
        }
        setDialog(false)
    }
    const handleDeleteTestAssoc: (requirement: URSRequirement) => void = async (requirement) => {
        const data = await dispatch(updateURSRequirement({ ...requirement, testDefinitionId: null })).unwrap()
        setDraftEntity(data)
    }

    const handleSaveLimitClick = async () => {
        if (limit ) {
            const newUrs = await dispatch(updateRiskLimit({
                ursId: draftEntity.id,
                value: limit.toString()
            })).unwrap()

            dispatch(showSuccess("saved"));

            setDraftEntity(newUrs)
        }
        setLimitDialog(false)
    }

    const handleSaveButtonClick = async () => {
        setSaving(true)
        try {
            const updated = await dispatch(updateURS(draftEntity)).unwrap()
            setDraftEntity(updated)
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }
        setSaving(false)
    }
    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const stageValidations = useMemo(() => {
        if (reqStage) {
            return allValidations.filter(({ stage }) => stage === reqStage)
        } else {
            return []
        }
    }, [allValidations, reqStage])
    const handleReqStageChange: AppTextFieldHandler = useCallback(e => {
        setReqStage(e.target.value as ValidationStage)
        setValidation(undefined)
    }, [])
    const handleValidationChange = useCallback((_, v) => {
        setValidation(v)
    }, [])
    const handleMainTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    const handlePrintReportClick = async () => {
        if (draftEntity.id) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/risk-analysis/${draftEntity.id}?language=${locale}`, '_blank')
        }
    }

    const p = frequency * detection * severity

    if (contextTypeId === undefined || contextType === undefined) return <div>Nothing to display</div>


    return (
        <Box sx={{
            bgcolor: 'background.paper',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <ServerErrorMsg err={err} />
            <Grid container spacing={4}>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={draftEntity.ursNo}
                            subheader={<Localized id="risk-analysis-urs-number"><span>Symbol dokumentu URS</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={4}>
                    <Card>
                        <CardHeader
                            title={deviceType ? getDeviceTypeName(deviceType) : ""}
                            subheader={<Localized id="urs-device-type"><span>Typ urządzenia</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={<UrsStatusLabel status={draftEntity.status}></UrsStatusLabel>}
                            subheader={<Localized id="urs-status"><span>Status</span></Localized>}
                        />
                    </Card>
                </Grid>
                <Grid item xs={2}>
                    <Card>
                        <CardHeader
                            title={draftEntity.limit}
                            subheader={<Localized id="risk-analisys-threshold"><span>Próg</span></Localized>}
                            action={
                                <IconButton aria-label="Edytuj" onClick={handleEditLimitClick} >
                                    <EditIcon />
                                </IconButton>
                            }
                        />
                    </Card>
                </Grid>
                <Grid item xs={12}>
                    <Tabs value={tab} onChange={handleMainTabChange}>
                        <Tab
                            key={0}
                            value={FormTab.Reqs}
                            label={<Localized id="risk-analysis-requirements">Lista wymagań</Localized>} />
                      
                    </Tabs>
                </Grid>
                <Grid item xs={12}>
                    <Box sx={{
                        display: tab === FormTab.Reqs ? 'flex' : 'none',
                        flexGrow: 1,
                    }}>
                        <Tabs
                            orientation="vertical"
                            variant="standard"
                            value={contextTypeId}
                            onChange={handleReqsTabChange}
                            sx={{
                                borderRight: 1,
                                borderColor: 'divider',
                            }}
                        >
                            {Array.from(draftEntity.types, t => <Tab
                                key={t.id}
                                label={t.code}
                                value={t.id}
                            />)}
                        </Tabs>
                        <Stack sx={{
                            paddingLeft: 4,
                            width: '100%',
                        }} direction="column" spacing={2}>
                            <Typography variant="subtitle2">
                                <If condition={contextTypeId !== undefined}
                                    otherwise={<></>}
                                >{`${contextType.code} ${contextType.name}`}</If>
                            </Typography>
                            <TableContainer sx={{
                                         maxHeight: 'calc(100vh - 400px)',
                                         overflowY: 'auto',
                                    }} component={Paper}>
                                <Table stickyHeader>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ minWidth: 50, width: 50, maxWidth: 50 }}>
                                                <Typography component="span" variant="subtitle2" color="textSecondary">
                                                    <Localized id="urs-item-lp">Lp</Localized>
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography component="span" variant="subtitle2" color="textSecondary">
                                                    <Localized id="urs-item-name">Nazwa</Localized>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ minWidth: 120, width: 120, maxWidth: 120 }}>
                                                <Typography component="span" variant="subtitle2" color="textSecondary">
                                                    <Localized id="urs-item-criticality">Krytyczność</Localized>
                                                </Typography>
                                            </TableCell>
                                            <TableCell sx={{ width: 88 }}>
                                                <Localized id="risk-analysis-req-severity">Srogość</Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: 124 }}>
                                                <Localized id="risk-analysis-req-frequency">Częstotliwość</Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: 88 }}>
                                                <Localized id="risk-analysis-req-detection">Detekcja</Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: 64 }}>
                                                <Localized id="risk-analysis-req-test">Test</Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: 64 }}>
                                                P
                                            </TableCell>
                                            <TableCell sx={{ width: 64 }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {contextType.requirements.map(requirement => <URSRequirementRow
                                            key={requirement.id}
                                            limit={draftEntity.limit}
                                            requirement={requirement}
                                            onEditClick={handleEditReqButtonClick}
                                            assocTest={() => {
                                                setAssocTestDialog(true)
                                                setReqToAssocWithTest(requirement)
                                            }}
                                            deleteTestAssoc={handleDeleteTestAssoc}
                                            canEdit={true}
                                        />)}
                                        {contextType.groups.map(group => 
                                            <>
                                                <TableRow sx={{ bgcolor: '#f1f1f1' }}>
                                                    <TableCell colSpan={10}>{`${group.numeration} ${group.name}`}</TableCell>
                                                </TableRow>
                                                {group.requirements.map(
                                                    requirement => <URSRequirementRow
                                                        key={requirement.id}
                                                        requirement={requirement}
                                                        limit={draftEntity.limit}
                                                        onEditClick={handleEditReqButtonClick}
                                                        assocTest={() => {
                                                            setAssocTestDialog(true)
                                                            setReqToAssocWithTest(requirement)
                                                        }}
                                                        deleteTestAssoc={handleDeleteTestAssoc}
                                                        canEdit={true} />)
                                                }
                                            </>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Stack>
                    </Box>
                </Grid>
                <Grid sx={{
                    display: tab === FormTab.Description ? 'block' : 'none',
                }} item xs={12}>
                    <Typography variant="body1" component="p">{description}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <ButtonGroup variant="contained" color="secondary">
                        <LoadingButton variant="contained" loading={saving} onClick={handleSaveButtonClick} startIcon={<SaveIcon />} style={{ display: draftEntity.status === "A" ? "inline-flex" : "none" }} >
                            <Localized id="save">
                                <span>Zapisz</span>
                            </Localized>
                        </LoadingButton>
                        <Button onClick={handleBackButtonClick} variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                        <Button component={RouterLink}
                            state={{
                                deviceId: "",
                                structureId: draftEntity.deviceTypeId,
                                ursId: draftEntity.id
                            }}
                            to={{
                                pathname: "/process/new",
                            }}
                            color="secondary"
                            variant="outlined"
                            //style={{ display: draftEntity.status === "A" ? "inline-flex" : "none" }}
                        >
                            <Localized id="devices-run-validation">
                                <span>Waliduj</span>
                            </Localized>
                        </Button>
                        <Button color="primary" onClick={() => handlePrintReportClick()} variant="outlined"  >
                            <Localized id="urs-action-report">
                                <span>Wydruk</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
            <Dialog open={limitDialog} fullWidth maxWidth='md'>
                <DialogTitle>
                    <Localized id='edit'>Edycja</Localized>
                </DialogTitle>
                <DialogContent>
                    <TextField
                        sx={{ mt: 2 }}
                        fullWidth
                        value={limit}
                        onChange={handleLimitChange}
                        label={<Localized id='risk-analisys-threshold'>Limit</Localized>}
                    />
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleSaveLimitClick}><Localized id='ok'>Ok</Localized></LoadingButton>
                    <LoadingButton loading={saving} onClick={handleEditLimitClose}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={dialog} fullWidth maxWidth="lg">
                <DialogTitle>
                    {/* <RequirementTypeName requirementTypeId={reqTypeId ?? ""} /> */}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText sx={{
                        marginBottom: 2,
                    }}><div dangerouslySetInnerHTML={{ __html: reqDescription }} />
                    </DialogContentText>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={potentialDefect}
                                onChange={handlePotentialDeffectChange}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <If condition={waitingForAnswer} otherwise={
                                                <IconButton onClick={handleAskRiskQuestionClick}>
                                                    <AutoFixHighIcon />
                                                </IconButton>
                                            }>
                                                <CircularProgress />
                                            </If>
                                        </InputAdornment>
                                    )
                                }}
                                label={<Localized id="risk-analysis-potential-defect"><span>Potencjalna wada</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={consequence}
                                onChange={handleConsequenceChange}
                                label={<Localized id="risk-analysis-potential-consequences"><span>Skutki</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={reason}
                                onChange={handleReasonChange}
                                label={<Localized id="risk-analysis-potential-reason"><span>Przyczyna</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                fullWidth
                                InputLabelProps={{ shrink: true }}
                                value={comment}
                                onChange={hadnleCommentChange}
                                label={<Localized id="risk-analysis-comment"><span>Komentarz</span></Localized>}
                            />
                        </Grid>
                       
                        
                        {/* <Grid item xs={3}> */}
                        {/*     <TextField */}
                        {/*         fullWidth */}
                        {/*         select */}
                        {/*         value={reqStage} */}
                        {/*         onChange={handleReqStageChange} */}
                        {/*         label={<Localized id="validation-stage">Etap</Localized>} > */}
                        {/*         {stages.map(stage => <MenuItem key={stage} value={stage}>{stage}</MenuItem>)} */}
                        {/*     </TextField> */}
                        {/* </Grid> */}
                        {/* <Grid item xs={9}> */}
                        {/*     <Autocomplete */}
                        {/*         fullWidth */}
                        {/*         value={validation} */}
                        {/*         options={stageValidations} */}
                        {/*         renderInput={params => <TextField {...params} label={<Localized id="urs-item-test"><span>Test</span></Localized>} />} */}
                        {/*         onChange={handleValidationChange} */}
                        {/*         getOptionLabel={v => v.name} */}
                        {/*     /> */}
                        {/* </Grid> */}
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                fullWidth
                                value={severity}
                                onChange={handleSeverityChange}
                                label={<Localized id="risk-analysis-req-severity"><span>Srogość</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                fullWidth
                                value={frequency}
                                onChange={handleFrequencyChange}
                                label={<Localized id="risk-analysis-req-frequency"><span>Częstotliwość</span></Localized>}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                type="number"
                                fullWidth
                                value={detection}
                                onChange={handleDetectionChange}
                                label={<Localized id="risk-analysis-req-detection"><span>Detekcja</span></Localized>}
                            />
                        </Grid>
                        <Grid sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }} item xs={3}>
                            <Typography variant="h5">P = {p}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControlLabel control={<Checkbox 
                            checked={skipTest} onChange={handleSkipTest} />} label='Nie podlega testom' />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogOkButtonClick}>
                        <Localized id="ok">
                            <span>Ok</span>
                        </Localized>
                    </Button>
                    <Button onClick={handleDialogCancelClick}>
                        <Localized id="cancel">
                            <span>Cancel</span>
                        </Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            {assocTestDialog && reqToAssocWithTest && <AssociateTest
                ursRequirement={reqToAssocWithTest}
                onSuccess={(data) => {
                    setAssocTestDialog(false)
                    setReqToAssocWithTest(undefined)
                    setDraftEntity(data)
                }}
                onError={(err) => {
                    setErr(err)
                    setAssocTestDialog(false)
                    setReqToAssocWithTest(undefined)
                }}
                onCancel={() => { setAssocTestDialog(false) }}
            />}
        </Box>
    );

}
