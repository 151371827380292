import { Localized } from "@fluent/react";
import { StageStatus } from "./processSlice";

export const StageStatusLabel = ({ status }: { status: StageStatus }) => {
    switch (status) {
        case "N":
            return <Localized id="stage-status-n">
                <span>Nowy</span>
            </Localized>
        case "A":
            return <Localized id="stage-status-a">
                <span>Zatwierdzanie</span>
            </Localized>
        case "F":
            return <Localized id="stage-status-f">
                <span>Zakończony</span>
            </Localized>
        case "P":
            return <Localized id="stage-status-p">
                <span>Planowanie</span>
            </Localized>
        case "R":
            return <Localized id="stage-status-r">
                <span>Ponowne planowanie</span>
            </Localized>
        case "T":
            return <Localized id="stage-status-t">
                <span>Testowanie</span>
            </Localized>
        case "V":
            return <Localized id="stage-status-v">
                <span>Weryfikacja</span>
            </Localized>
        case "S":
            return <Localized id="stage-status-s">
                <span>Skipped</span>
            </Localized>
        default:
            return <span>{status}</span>
    }
}