import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Autocomplete, Container, Paper, Skeleton, Stack, TextField } from "@mui/material"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useCallback, useEffect, useState } from "react"
import { LoadStructure } from "../../app/AppDataLoader"
import { AppId, AppThunkAPIType } from "../../app/appTypes"
import { BottomAppBar } from "../../app/BottomAppBar"
import { useAppDispatch } from "../../app/hooks"
import { If } from "../../app/If"
import { getWithAuth, putWithAuth } from "../../http"
import { StructureTreeComponent } from "../device/Structure"
import { checkStructureNodes, selectStructure, uncheckAllStructureNodes } from "../device/structureSlice"
import { loadAllModules, Module } from "./moduleSlice"
import SaveIcon from '@mui/icons-material/Save'
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { store } from "../../app/store"

interface ModuleStruct {
    moduleId: AppId
    structureId: AppId
}

const loadModuleStructure = 
    createAsyncThunk<ModuleStruct[], AppId, AppThunkAPIType>("module_structure_access/load", async (moduleId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/module-structure-access/${moduleId}`,
        }))

        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as ModuleStruct[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

const saveModuleStructure = 
    createAsyncThunk<ModuleStruct[], ModuleStruct[], AppThunkAPIType>("module_structure_access/save", async (items, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({
            url: `api/sys/module-structure-access`,
            payload: items,
        }))
        if (putWithAuth.fulfilled.match(result)) {
            return items
        } else {
            const { payload } = result 
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const ModuleStructure = () => {
    const dispatch = useAppDispatch()

    const [modules, setModules] = useState<Module[] | undefined>(undefined)
    const [module, setModule] = useState<Module | null>(null)
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)

    useEffect(() => {
        const load = async () => {
            try {
               return await dispatch(loadAllModules()).unwrap() 
            } catch (error) {
                return []
            }
        }
        load().then(x => setModules(x))
    }, [dispatch])

    useEffect(() => {
        const load = async (moduleId: AppId) => {
            return await dispatch(loadModuleStructure(moduleId)).unwrap()
        }
        if (module !== null) {
            setLoading(true)
            load(module.moduleId).then(x => {
                dispatch(uncheckAllStructureNodes())
                dispatch(checkStructureNodes(x.map(({ structureId }) => structureId)))
            }).finally(() => {
                setLoading(false)
            })
        }
    }, [dispatch, module])

    const handleModuleChange = useCallback((_: any, newModule: Module | null) => {
        if (newModule) {
            setModule(newModule)
        }
    }, [])

    const handleSaveClick = async () => {
        if (module !== null) {
            setSaving(true)
            const checkNodes = selectStructure(store.getState()).tree.multiselection
            const items: ModuleStruct[] = Object.entries(checkNodes).map(([structureId, _]) => {
                return {
                    structureId,
                    moduleId: module.moduleId
                }
            })
            try {
                await dispatch(saveModuleStructure(items))
                dispatch(showSuccess("saved"))
            } catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return <>
        <If condition={modules !== undefined} otherwise={<Skeleton />}>
            <Autocomplete 
                sx={{
                    width: "400px",
                }}
                fullWidth={false}
                options={modules ?? []}
                getOptionLabel={({ name }) => name}
                value={module}
                onChange={handleModuleChange}
                renderInput={params =>
                    <TextField {...params} />
                }
            />
        </If>
        <LoadStructure component={<Skeleton />}>
            <If condition={!loading} otherwise={<Skeleton />}>
                <StructureTreeComponent editable={false} multiselect={true}/>
            </If>
        </LoadStructure>

        <BottomAppBar>
            <LoadingButton 
                loading={saving} 
                variant="contained"
                color="secondary" 
                startIcon={<SaveIcon />}
                onClick={handleSaveClick}
            >
                <Localized id="save">Zapisz</Localized>
            </LoadingButton>
        </BottomAppBar>
    </>
}
