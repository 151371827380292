import { AppTextFieldHandler } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { CircularProgress, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Stack, TextField, Tooltip } from "@mui/material"
import { Localized } from "@fluent/react"
import { If } from "../../../app/If"
import { LoadingButton } from "@mui/lab"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { useCallback, useState } from "react"
import { createHelpArticle, selectHelpArticleById, updateHelpArticle } from "./helpArticlesSlice"
import { RichTextEditor } from "../../../app/RichTextEditor"
import ArrowRightIcon from '@mui/icons-material/ArrowRightAlt';
import { postWithAuth } from "../../../http"

export interface HelpArticleEditDialogProps {
    id: string | undefined
    onSaveSuccess: () => void
    onCancel: () => void
}

export const HelpArticleEditDialog = ({ id,  onSaveSuccess, onCancel }: HelpArticleEditDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)

    const entity = useAppSelector(state => selectHelpArticleById(state, id ?? ""));

    const [location, setLocation] = useState<string>(entity?.location ?? "");    
    const [title, setTitle] = useState<string>(entity?.title ?? "");    
    const [title_EN, setTitleEN] = useState<string>(entity?.title_EN ?? "");    
    const [description, setDescription] = useState<string>(entity?.description ?? "");    
    const [description_EN, setDescriptionEN] = useState<string>(entity?.description_EN ?? "");
    const [loadingTranslation, setLoadingTranslation] = useState<boolean>(false);    
    
    const handleLocationChange: AppTextFieldHandler = useCallback(e => setLocation(e.target.value), [setLocation]);
    const handleTitleChange: AppTextFieldHandler = useCallback(e => setTitle(e.target.value), [setTitle]);
    const handleTitleENChange: AppTextFieldHandler = useCallback(e => setTitleEN(e.target.value), [setTitleEN]);

    const handleSaveClick = async () => {
        setSaving(true)
        try {
            if (entity){
                let articleToSave ={...entity, 
                    location, title, title_EN, description, description_EN
                };
        
                await dispatch(updateHelpArticle(articleToSave)).unwrap()
                
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
            else {
                const id = 0;
                let articleToSave ={ 
                    location, title, title_EN, description, description_EN
                };
        
                await dispatch(createHelpArticle(articleToSave)).unwrap()
                
                dispatch(showSuccess("saved"));
                onSaveSuccess()
            }
        }
        catch (error) {
                dispatch(showError("error"));
            } 
        finally {
                setSaving(false)
            }
    }
    
    const translateEn = async () => {
        setLoadingTranslation(true)
        try {
            const result = await dispatch(postWithAuth({
                url: `api/translation/translate`,
                payload: {
                    Text: description,
                    LanguageTo: 'en-US'
                }
            }))
            
            if (postWithAuth.fulfilled.match(result)) {
                const { payload } = result
                setDescriptionEN(payload.text)
            }
        }
        finally {
            setLoadingTranslation(false)
        }
    }

    return <>
        <DialogTitle>
            <If condition={id == undefined} otherwise={
                 <Localized id="help-edit-article">
                    <span>Edycja pomocy</span>
                 </Localized>
                }>
                <Localized id="help-new-article">
                    <span>Nowy artykuł pomocy</span>
                </Localized>
            </If>
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} padding={2}>
                <TextField 
                    fullWidth
                    value={id}
                    disabled={true}
                    label={<Localized id="help-article-id">Id</Localized>}>
                </TextField>
                <TextField 
                    fullWidth
                    value={location}
                    inputProps={{ maxLength: 200 }}
                    onChange={handleLocationChange}
                    label={<Localized id="help-article-location">Location</Localized>}>
                </TextField>
                <Grid container>
                    <Grid item md={5}>
                        <TextField 
                            fullWidth
                            value={title}
                            inputProps={{ maxLength: 200 }}
                            onChange={handleTitleChange}
                            label={<Localized id="help-article-title">Title</Localized>}>
                        </TextField>
                        <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                            <RichTextEditor label={<Localized id="help-article-description"><span>Description</span></Localized>} value={description} onValueChange={setDescription} />
                        </If>
                    </Grid>
                    <Grid item md={2} textAlign="center">
                        <Tooltip title={<Localized id="translate-to-en">Przetłumacz</Localized>}>
                            <IconButton 
                                size="large"
                                onClick={() => translateEn()}
                                disabled={loadingTranslation}
                            >
                                <ArrowRightIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item md={5}>
                        <TextField 
                            fullWidth
                            value={title_EN}
                            inputProps={{ maxLength: 200 }}
                            onChange={handleTitleENChange}
                            label={<Localized id="help-article-title-en">Title EN</Localized>}>
                        </TextField>
                        <If condition={!loadingTranslation} otherwise={<CircularProgress />}>
                            <RichTextEditor label={<Localized id="help-article-description-en"><span>Description EN</span></Localized>} value={description_EN} onValueChange={setDescriptionEN} />
                        </If>
                    </Grid>
                </Grid>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton onClick={handleSaveClick} loading={saving}>
                <Localized id="save">
                    <span>Zapisz</span>
                </Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
                <Localized id="cancel">
                    <span>Anuluj</span>
                </Localized>
            </LoadingButton>
        </DialogActions>
    </>
}