import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks";
import { deleteGroup, Urs } from "../UrsSlice";

export interface DeleteGroupDialogProps {
    id: AppId
    onSuccess: (data: Urs) => void
    onCancel: () => void
}

export const DeleteGroupDialog = (props: DeleteGroupDialogProps) => {
    const { id, onSuccess, onCancel } = props
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const handleConfirmDelClick = () => {
        setSaving(true)
        dispatch(deleteGroup(id))
            .then(unwrapResult)
            .then(data => onSuccess(data))
            .finally(() => setSaving(false))
    }
    return <Dialog open={true}>
        <DialogTitle>
        <Localized id='delete'>Usuń</Localized>
        </DialogTitle>
        <DialogContent>
            <Localized id="confirm-delete">
                <span>Czy napewno chcesz usunąć?</span>
            </Localized>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleConfirmDelClick}>
            <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={onCancel}>
            <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
