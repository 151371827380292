import { Localized } from "@fluent/react"
import { TestStatus } from "./processTestsSlice"

export const TestStatusLabel = ({ status }: { status: TestStatus}) => {
    switch (status) {
        case "P":
            return <Localized id="test-status-p">
                <span>Zaplanowany</span>
            </Localized>
        case "F":
            return <Localized id="test-status-f">
                <span>Wykonany</span>
            </Localized>
        case "V":
            return <Localized id="test-status-v">
                <span>Sprawdzony</span>
            </Localized>
    }
}