import { Localized } from "@fluent/react"
import { Alert, Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Skeleton, TextField } from "@mui/material"
import { useCallback, useMemo, useState } from "react"
import { If } from "../../../../app/If"
import { store } from "../../../../app/store"
import { selectAllUsers, selectUserByName, User } from "../../../users/usersSlice"
import { LoadingButton } from "@mui/lab"
import { AppId, AppTextFieldHandler } from "../../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { LoadUsers } from "../../../../app/AppDataLoader"
import { TeamMemberRoleMenuField } from "./TeamMemberRoleMenuField"
import { addDocumentTeamMember, DocumentTeamMember, DocumentTeamMemberRole, substituteDocumentTeamMember } from "../teamMemberApi"
import { showError, showSuccess } from "../../../notifications/notificationsSlice"

export interface AddEditTeamMemberDialog {
    documentId: AppId
    mode: "create" | "edit" | "substitute"
    userToSubstitute: string
    excludedUsers: Set<string>
    onSuccessfulSave: (teamMember: DocumentTeamMember) => void
    onCancelClick: () => void,
}
export const AddEditTeamMemberDialog = ({ documentId, mode, userToSubstitute, onCancelClick, onSuccessfulSave, excludedUsers }: AddEditTeamMemberDialog) => {
    const dispatch = useAppDispatch()
    const allUsers = useAppSelector(selectAllUsers)
    const [user, setUser] = useState<User | null>(() => mode != "substitute" ? (selectUserByName(store.getState(), userToSubstitute) ?? null) : null)
    const [role, setRole] = useState<DocumentTeamMemberRole>()
    const [saving, setSaving] = useState(false)
    const [serverError, setServerError] = useState("")

    const handleUserChange = useCallback((_, newUser) => {
        setUser(newUser)
    }, [])

    const handleUserRoleChange: AppTextFieldHandler = useCallback(e => setRole(e.target.value as DocumentTeamMemberRole), [setRole])


    const availableUsers =
        useMemo(() => allUsers.filter(u => !excludedUsers.has(u.userName) || u.userName === userToSubstitute), [allUsers, excludedUsers, userToSubstitute])

    const handleOkClick = async () => {
        if (documentId && user && role) {
            setSaving(true)
            try {
                switch (mode) {
                    case "create":

                        const result = await dispatch(addDocumentTeamMember({ documentId: documentId, userName: user.userName, role: role }))
                        if (addDocumentTeamMember.fulfilled.match(result)) {
                            const newTeamMember = result.payload as DocumentTeamMember;
                            dispatch(showSuccess("saved"))
                            onSuccessfulSave(newTeamMember)
                        } else {
                            let errorMsg = "error"
                            if (result.payload && result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title

                                }
                            }
                            setServerError(errorMsg)
                        }

                        break
                    case "edit":
                        break
                    case "substitute":
                        const result2 = await dispatch(substituteDocumentTeamMember({ documentId: documentId, userName: userToSubstitute, substitution: user.userName }))
                        if (substituteDocumentTeamMember.fulfilled.match(result2)) {
                            const newTeamMember = result2.payload as DocumentTeamMember;
                            dispatch(showSuccess("saved"))
                            onSuccessfulSave(newTeamMember)
                        }
                        else {
                            let errorMsg = "error"
                            if (result2.payload && result2.payload.kind === 'http') {
                                const problem = result2.payload.problem
                                if (problem) {
                                    errorMsg = problem.title

                                }
                            }
                            setServerError(errorMsg)
                        }
                        break


                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }

        }
        setSaving(false)
    }

    return <>
        <Dialog key="addEditTeamMemberDialog" open={true} maxWidth="md" fullWidth keepMounted={false}>
            <DialogTitle>
                <If condition={mode === "create"}>
                    <Localized id="document-add-team-member">
                        <span>Nowy członek zespołu</span>
                    </Localized>
                </If>
                <If condition={mode === "edit"}>
                    <Localized id="document-edit-team-member">
                        <span>Edycja członka zespołu</span>
                    </Localized>
                </If>
                <If condition={mode === "substitute"}>
                    <Localized id="document-appoint-substitution" vars={{userName: userToSubstitute}}>
                        <span>Wyznacz zastępstwo</span>
                    </Localized>
                </If>
            </DialogTitle>
            <DialogContent sx={{
                padding: 2,
            }}>
                <LoadUsers component={<Skeleton />}>
                    <Autocomplete
                        sx={{
                            marginTop: 1,
                        }}
                        options={availableUsers}
                        value={user}
                        onChange={handleUserChange}
                        getOptionLabel={({ firstName, lastName, email }) => `${firstName} ${lastName} (${email}))`}
                        renderInput={params =>
                            <TextField {...params}
                                helperText={user?.position ?? " "}
                                label={
                                    <Localized id="username">
                                        <span>Nazwa użytkownika</span>
                                    </Localized>
                                }
                            />
                        }
                    />
                </LoadUsers>
                <TeamMemberRoleMenuField
                    value={role}
                    onChange={handleUserRoleChange}
                />
                {
                    serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
                        <Localized id={serverError}>
                            <span>Server error</span>
                        </Localized>
                    </Alert>
                }
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    loading={saving}
                    color="secondary"
                    variant="contained"
                    loadingIndicator={<Localized id="saving" />}
                    onClick={handleOkClick}>
                    <Localized id="save">
                        <span>save</span>
                    </Localized>
                </LoadingButton>
                <LoadingButton
                    color="primary"
                    variant="outlined"
                    loading={saving}
                    onClick={onCancelClick}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
    </>
}