import { Localized } from "@fluent/react"
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from "@mui/material"
import { If } from "../../../../app/If"
import { LoadingButton } from "@mui/lab"
import { useState } from "react"
import { useAppDispatch } from "../../../../app/hooks"
import { createMeetingMinute, MeetingMinute } from "../meetingMinutesSlice"
import { showError, showSuccess } from "../../../notifications/notificationsSlice"

const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>


export interface AddMeetingMinuteProps {
    objectId: string;
    objectType: string
    canEdit: boolean
    onSucces: (meetingMinute: MeetingMinute) => void
    onCancel: () => void
}

export const AddMeetingMinute = (props: AddMeetingMinuteProps) => {
    const { objectId, objectType, canEdit } = props
    const dispatch = useAppDispatch()
    const [validationErrors, setValidationErrors] = useState(false)
    const [title, setTitle] = useState("")
    const [saving, setSaving] = useState(false)

    const titleError = validationErrors && title === ""

    const handleClickSave = async () => {
        setValidationErrors(true)
        if (title) {
            setSaving(true)

            try {
                const result = await dispatch(createMeetingMinute({
                    title: title,
                    parentObjectGuid: objectId,
                    parentObjectType: objectType
                }))

                if (createMeetingMinute.fulfilled.match(result)) {
                    const meetingMinute = result.payload as MeetingMinute;
                    dispatch(showSuccess("saved"))
                    props.onSucces(meetingMinute)
                }
                else {
                    let errorMsg = "error"
                    if (result.payload) {
                        if (result.payload.kind === 'http') {
                            const problem = result.payload.problem
                            if (problem) {
                                errorMsg = problem.title
                            }
                        }
                    }
                    dispatch(showError(errorMsg))
                }
            }
            catch (error) {
                dispatch(showError("error"))
            }
            finally {
                setSaving(false)
            }
        }
    }

    return <>
        <Dialog open={true} maxWidth='sm' sx={{ minHeight: '80%' }} fullWidth keepMounted={false}>
            <DialogTitle>
                <Localized id='meeting-minutes-add-new'>Nowa notatka</Localized>
            </DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            autoFocus
                            error={titleError}
                            InputLabelProps={{
                                shrink: true
                            }}
                            helperText={titleError && <RequiredError />}
                            inputProps={{ maxLength: 500 }}
                            sx={{ marginTop: "10px" }}
                            value={title}
                            onChange={e => setTitle(e.target.value)}
                            label={<Localized id='notes-title'>Tytuł</Localized>}
                        />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <If condition={canEdit}>
                    <LoadingButton loading={saving} onClick={handleClickSave}>
                        <Localized id='save'>Save</Localized>
                    </LoadingButton>
                </If>
                <LoadingButton loading={saving} onClick={props.onCancel}>
                    <Localized id='cancel'>Anuluj</Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog >
    </>
}
