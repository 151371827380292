import { Localized } from "@fluent/react";
import { LoadingButton } from "@mui/lab";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { unwrapResult } from "@reduxjs/toolkit";
import { useState } from "react";
import { AppId } from "../../../app/appTypes";
import { useAppDispatch } from "../../../app/hooks";
import { RichTextEditor } from "../../../app/RichTextEditor";
import { DescriptionField, updateDescriptionField } from "../UrsSlice";

const Label = ({ fieldName }: { fieldName: DescriptionField }) => {
    switch (fieldName) {
        case "description": return <Localized id='urs-description'>Opis</Localized>
        case "area": return <Localized id='urs-area'>Obszar</Localized>
        case "purpose": return <Localized id='urs-purpose'>Cel</Localized>
        case "title": return <Localized id='urs-title'>Tytuł</Localized>
    }
}

export interface EditDescriptionFieldProps {
    ursId: AppId
    fieldName: DescriptionField
    value: string
    onSuccess : () => void
    onCancel : () => void
}

export const EditDescriptionField = (props: EditDescriptionFieldProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)
    const [value, setValue] = useState(props.value)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateDescriptionField({
            ursId: props.ursId,
            fieldName: props.fieldName,
            value
        }))
        .then(unwrapResult)
        .then(() => { props.onSuccess() })
        .catch(err => {
            console.error(err)
        })
        .finally(() => {
            setSaving(false)
        })
    }
    
    return <Dialog open={true} fullWidth maxWidth='lg'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <RichTextEditor label={<Label fieldName={props.fieldName} />} value={value} onValueChange={setValue} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}><Localized id='ok'>Ok</Localized></LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
