import { Localized } from "@fluent/react";

export const RolesLabel = ({ role }: { role: string}) => {
    switch (role) {
        case "M":
            return <Localized id="role-manager">
                <span>Manager</span>
            </Localized>        
        case "C":
            return <Localized id="role-collaborator">
                <span>Collaborator</span>
            </Localized>
        case "O":
            return <Localized id="role-observer">
                <span>Observer</span>
            </Localized>           
        default:
            return <span></span>
    }
}