import { Localized } from '@fluent/react'
import { useCallback, useState } from 'react'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { MailTemplates } from './MailTemplates'
import { MailLogs } from './MailLogs'
import { Container, Paper, Tab } from '@mui/material'

type FormTabs = "templates" | "logs"

export const MailSettings = () => {
    const [tab, setTab] = useState<FormTabs>("templates");
    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])

    return (
        <>
            <Container component={Paper} maxWidth={false} sx={{ paddingTop: 2 }}>
                <TabContext value={tab}>
                    <TabList onChange={handleTabChange}>
                        <Tab
                            key={0}
                            value={"templates"}
                            label={<Localized id="mail-settings-templates">Szablony maili</Localized>} />
                        <Tab
                            key={1}
                            value={"logs"}
                            label={<Localized id="mail-settings-logs">Historia wysyłania</Localized>} />
                    </TabList>
                    <TabPanel value="templates">
                        <MailTemplates></MailTemplates>
                    </TabPanel>
                    <TabPanel value="logs">
                        <MailLogs></MailLogs>
                    </TabPanel>
                </TabContext>
            </Container>

        </>
    );
}
