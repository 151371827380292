import { TabContext, TabList, TabPanel } from "@mui/lab"
import Tab from "@mui/material/Tab"
import { ReactElement, useState } from "react"
import { RichTextEditor } from "../../app/RichTextEditor"
import { SpecItemsTree } from "./SpecItemsTree"
import { SpecTests } from "./SpecTests"

type SpecTab = 'fs' | 'ds' | 'tests' | 'images'

export const DeviceSpec = () => {
    const [description, setDescription] = useState<string>('<p></p>')
    const [tab, setTab] = useState<SpecTab>('fs')

    return <div className="container mx-auto space-y-2">
        <LabeledContainer label={<></>}>
            <div className="text-lg font-semibold">Honda Civic Type R</div>
        </LabeledContainer>
        <div className="flex flex-row gap-4">
            <div className="basis-1/4 p-2 shadow-md">
                <SpecItemsTree />
            </div>
            <div className="basis-3/4">
                <TabContext value={tab}>
                    <TabList onChange={(_, newTab) => setTab(newTab)}>
                        <Tab label='FS' value='fs' />
                        <Tab label='DS' value='ds' />
                        <Tab label='Tests' value='tests' />
                        <Tab label='Images' value='images' />
                    </TabList>
                    <TabPanel sx={{ px: 0, py: 0.5 }} value='fs'>
                        <RichTextEditor value={'<h1>Honda Civic Type R</h1>'} onValueChange={val => setDescription(val)} label={<span>Functional spec</span>} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py: 0.5 }} value='ds'>
                        <RichTextEditor value={'<h1>Honda Civic Type R</h1>'} onValueChange={val => setDescription(val)} label={<span>Design spec</span>} />
                    </TabPanel>
                    <TabPanel sx={{ px: 0, py: 0.5 }} value='tests'>
                        <SpecTests />
                    </TabPanel>
                </TabContext>
            </div>
        </div>
    </div>
}

const LabeledContainer = (props: { label: ReactElement, children : ReactElement}) : ReactElement => {
    return <div className="rounded border-solid border border-slate-100">
        <div className='text-xs bg-slate-100 px-2 py-1'>Nazwa urządzenia</div>
        <div className='px-2 py-1'>
            {props.children}
        </div>
    </div>
}
