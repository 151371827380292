import { AppId } from "../../app/appTypes"
import { DocumentActions } from "../documents/documentsApi"
import { RiskAnalysisConsequence, RiskAnalysisDefect, RiskAnalysisDocument, RiskAnalysisFunction, RiskAnalysisReason } from "../documents/riskAnalysis/riskAnalysisApi"

export const findFunction = (doc: RiskAnalysisDocument, fnId: AppId) : RiskAnalysisFunction | undefined => {
    // const topItem = doc.functions.find(fn => fn.id === fnId)
    // if (topItem) return topItem

    for (let i = 0; i < doc.groups.length; i++) {
        const gr = doc.groups[i]
        const groupItem = gr.functions.find(fn => fn.id === fnId)
        if (groupItem) return groupItem

        for (let j = 0; j < gr.groups.length; j++) {
            const subGr = gr.groups[j]
            const subGroupItem = subGr.functions.find(fn => fn.id === fnId)
            if (subGroupItem) return subGroupItem
        }
    }
    return undefined
}

export const findConsequence = (fn: RiskAnalysisFunction, consquenceId: AppId) : RiskAnalysisConsequence | undefined => {
    for (let i = 0; i < fn.defects.length; i++) {
        const defect = fn.defects[i]
        for (let j = 0; j < defect.consequences.length; j++) {
            const consquence = defect.consequences[j]
            if (consquence.id === consquenceId) {
                return consquence
            }
        }
    }
    return undefined
}

export const findReason = (fn : RiskAnalysisFunction, reasonId: AppId) : RiskAnalysisReason | undefined => {
    for (let i = 0; i < fn.defects.length; i++) {
        const defect = fn.defects[i]
        for (let j = 0; j < defect.consequences.length; j++) {
            const consequence = defect.consequences[j]
            for (let k = 0; k < consequence.reasons.length; k++) {
                const r = consequence.reasons[k]
                if (reasonId === r.id) {
                    return r
                }
            }
        }
    }
    return undefined
}

export const findDefect = (fn : RiskAnalysisFunction, defectId: AppId) : RiskAnalysisDefect | undefined => {
    for (let i = 0; i < fn.defects.length; i++) {
        const defect = fn.defects[i]
        if (defect.id === defectId) {
            return defect
        }
    }
    return undefined
}

export function isEditable (act: DocumentActions) : boolean {
    return act.documentContentActions.findIndex(a => a === 'Edit') >= 0
}
