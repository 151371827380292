import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppId } from "../../app/appTypes"
import { useAppDispatch } from "../../app/hooks"
import { RichTextEditor } from "../../app/RichTextEditor"
import { ProjectHeader, updateProjectDescriptionField } from "./projectSlice"

export interface EditDescriptionFieldProps {
    projectId: AppId
    field: string
    value: string
    onSave : (project: ProjectHeader) => void
    onCancel : () => void
}

const Label = ({ fieldName }: { fieldName: string }) => {
    switch (fieldName) {
        case "description": return <Localized id='project-description'>Opis</Localized>
        default: return <>{fieldName}</>
    }
}

export const EditDescriptionField = (props: EditDescriptionFieldProps) => {
    const dispatch = useAppDispatch()
    const { projectId, field, value } = props

    const [newValue, setNewValue] = useState(value)
    const [saving, setSaving] = useState(false)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateProjectDescriptionField({
            id: projectId,
            data: {
                fieldName: field,
                text: newValue,
            },
        }))
            .then(unwrapResult)
            .then(project => props.onSave(project))
            .catch(err => console.error(err))
            .finally(() => setSaving(false))
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <RichTextEditor label={<Label fieldName={field} />} value={value} onValueChange={setNewValue} />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
