import React from 'react';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import clsx from 'clsx';
import { Localized } from "@fluent/react"
import { Process } from '../processSlice';
import { ValidationStage } from '../../validations/validationsSlice';


const PREFIX = 'StagePurposeInfo';

const classes = {
  root: `${PREFIX}-root`,
  expand: `${PREFIX}-expand`,
  expandOpen: `${PREFIX}-expandOpen`
};

const StyledCard = styled(Card)(( { theme }) => ({
  [`&.${classes.root}`]: {
    width:"100%",
    flexGrow: 1,
    marginBottom: theme.spacing(1)
  },

  [`& .${classes.expand}`]: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
  },

  [`& .${classes.expandOpen}`]: {
      transform: 'rotate(180deg)',
  }
}));

export const StagePurposeInfo = (props: { process: Process, stage: ValidationStage }) => {
    const { processNo, stages } = props.process
    const stageEntity = stages.find(s => s.stage === props.stage)    

    
    
    const [detailsExpanded, setDetailsExpanded] = React.useState(false);  
    const handleDetailsClicked = () => {
      setDetailsExpanded(!detailsExpanded);
    };  
         
    return (
      <StyledCard className={classes.root} >
        <CardHeader
          action={
          <IconButton
            className={clsx(classes.expand, {
              [classes.expandOpen]: detailsExpanded,
            })}
            onClick={handleDetailsClicked}
            size="large">
            <ExpandMoreIcon />
          </IconButton>
        }
          title={`Proces ${stageEntity?.stage}`}
          subheader={`${stageEntity?.stage}-${processNo}`}
        />                   
        <CardContent>
          <Typography variant="body2" color="textSecondary" component="p">
            <Localized id="process-purpose">Cel procesu</Localized>                 
          </Typography>
        </CardContent>
        <Collapse in={detailsExpanded} timeout="auto" unmountOnExit>
          <CardContent>                
            <Typography >
            <span>{stageEntity?.purpose}</span>
        </Typography>
          </CardContent>
        </Collapse>
      </StyledCard>
    );          
  }