import { Localized, useLocalization } from '@fluent/react'
import Button from '@mui/material/Button'
import Fab from '@mui/material/Fab'
import AddIcon from '@mui/icons-material/Add'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react'
import { FlexDiv } from '../../../app/Utils';
import { showError, showSuccess } from '../../notifications/notificationsSlice'
import { ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { deleteHelpArticle, HelpArticle, loadHelpArticles, selectAllHelpArticles, selectHelpArticles } from './helpArticlesSlice'
import { HelpArticleEditDialog } from './HelpArticleEditDialog'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import { LoadingContainer } from '../../../app/LoadingContainer'
import { selectSettings } from '../../settings/settingsSlice'
import { enUS, plPL } from '@mui/x-data-grid/locales'

type DialogState =
    | { type: "none" }
    | { type: "createUpdate", helpArticleId: string | undefined }


const DialogContentDispatcher = ({ state, setter }: { state: DialogState, setter: Dispatch<SetStateAction<DialogState>> }) => {
    const dispatch = useAppDispatch()
    const onSuccess = useCallback(() => {
        setter({ type: "none" })
        dispatch(showSuccess("saved"))
    }, [setter, dispatch])
    const onCancel = useCallback(() => {
        setter({ type: "none" })
    }, [setter])
    const onError = useCallback(() => {
        setter({ type: "none" })
        dispatch(showError("error"))
    }, [setter, dispatch])

    switch (state.type) {
        case "none":
            return null

        case "createUpdate":
            return <HelpArticleEditDialog
                id={state.helpArticleId}
                onSaveSuccess={onSuccess}
                onCancel={onCancel}
            />
    }
}

export const HelpArticles = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const { loaded } = useAppSelector(selectHelpArticles)
    const allHelpArticles = useAppSelector(selectAllHelpArticles)
    const dispatch = useAppDispatch()
    const [isLoading, setIsLoading] = useState<boolean>(false)


    useEffect(() => {
        if (!loaded) {
            setIsLoading(true);
            dispatch(loadHelpArticles())
        } else {
            setIsLoading(false);
        }
    }, [dispatch, loaded])

    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const [dialog, setDialog] = useState<DialogState>({ type: "none" })
    const [itemToDelete, setItemToDelete] = useState<string | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)

    const handleDeleteClick = useCallback((id: string) => {
        setItemToDelete(id)
    }, [])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])

    const handleDeleteHelpArticleClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                await dispatch(deleteHelpArticle(itemToDelete))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    const handleAddHelpArticleClick = useCallback( () => {
        setDialog({
            type: "createUpdate",
            helpArticleId: undefined
        })
    }, [setDialog])

    const handleEditHelpArticleClick = useCallback((helpArticleId: string) => {
        setDialog({
            type: "createUpdate",
            helpArticleId
        })
    }, [setDialog])

    const columns: GridColDef<HelpArticle, any, any>[] = [
        {
            field: "id",
            headerName: l10n.getString("help-article-id", null, "ID"),
            width: 300
        },
        {
            field: 'location',
            headerName: l10n.getString("help-article-location", null, "Lokalizacja pomocy"),
            width: 300
        },
        {
            field: "title",
            headerName: l10n.getString("help-article-title", null, "Tytuł pomocy"),
            flex: 1
        },
        {
            field: "actions",
            type: "actions",
            width: 250,
            renderCell: (params) => {
                return (
                    <ButtonGroup>
                        <Button onClick={() => handleEditHelpArticleClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="edit">
                            <span>Edytuj</span>
                        </Localized>
                        </Button>
                        <Button onClick={() => handleDeleteClick(params.row.id)} color="secondary" variant="outlined">
                            <Localized id="delete">
                                <span>Usuń</span>
                            </Localized>
                        </Button>
                    </ButtonGroup>
                )
            }
        }
    ]

    if (isLoading) {
        return <>
            <LoadingContainer/>
        </>
    }

    return (
        <>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 280px)' }}
                localeText={getCustomLocaleText()}
                rows={allHelpArticles}
                columns={columns}
            />
            <FlexDiv>
                <Fab sx={{
                    marginTop: 2,
                }}
                    onClick={() => handleAddHelpArticleClick()}
                    color="secondary"
                >
                    <AddIcon />
                </Fab>
            </FlexDiv>
            <Dialog open={dialog.type !== "none"} fullWidth maxWidth="xl" keepMounted={false}>
                <DialogContentDispatcher state={dialog} setter={setDialog} />
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="confirm-delete">
                            <span>Czy napewno chcesz usunąć?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={deleting} onClick={handleDeleteHelpArticleClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    );
}