import {Localized} from "@fluent/react"
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper} from "@mui/material"
import {AppId} from "../../../app/appTypes"
import {useAppSelector} from "../../../app/hooks"
import {Attachements} from "../Attachements"
import {URSRequirement} from "../UrsSlice"


interface AttachementsDialogProps {
    onClose       : () => void
    requirementId : AppId
    canUpload     : boolean
    canDelete     : boolean
    group         : string
    typeId        : AppId
    requirement   : URSRequirement
}

export const AttachementsDialog = ({ typeId, requirement, group, onClose, requirementId, canUpload, canDelete} : AttachementsDialogProps) => {
    return <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle>
            <Localized id="attachements">Załączniki</Localized>
        </DialogTitle>
        <DialogContent>
            <Paper sx={{ padding: 2, marginBottom: 2 }} elevation={1}>
                <DialogContentText><div dangerouslySetInnerHTML={{ __html: requirement.name }}></div></DialogContentText>
            </Paper>
            <Attachements resourceId={requirement.guid} canUpload={canUpload} canDelete={canDelete}/> 
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                <Localized id="close">Zamknij</Localized>
            </Button>
        </DialogActions>
    </Dialog>
}
