import { Fab, Button, Dialog, DialogTitle, DialogContent, DialogActions, MenuList, Menu, MenuItem, ListItemIcon, ListItemText, Divider } from "@mui/material"
import { DataGrid, GridColDef, useGridApiRef } from "@mui/x-data-grid"
import { useEffect, useState } from "react"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import AddIcon from '@mui/icons-material/Add'
import { Localized, useLocalization } from "@fluent/react"
import { unwrapResult } from "@reduxjs/toolkit"
import { LoadingContainer } from "../../app/LoadingContainer"
import { ErrorContainer } from "../../app/ErrorContainer"
import { useNavigate } from "react-router-dom"
import { Action, AIAccess, createAIAccess, loadAIAccess } from "./aiQuestionsSlice"
import { CompaniesBrowser } from "../company/CompaniesBrowser"
import { If } from "../../app/If"
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import { ActionRunner } from "./ActionRunner"
import produce from "immer";
import { enUS, plPL } from "@mui/x-data-grid/locales"
import { selectSettings } from "../settings/settingsSlice"

export const AIAccessGrid = () => {
    const { locale } = useAppSelector(selectSettings);
    const { l10n } = useLocalization()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [aiAccess, setAIAccess] = useState<AIAccess[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [companiesDialogOpen, setCompaniesDialogOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [openMenu, setOpenMenu] = useState(null);
    const [actionToRun, setActionToRun] = useState<Action & { name: string } | null>(null)

    const companiesGridRef = useGridApiRef();

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadAIAccess())
        .then(unwrapResult)
        .then(setAIAccess)
        .catch((error) => { console.log(error); setIsError(true); setError(error); })
        .finally(() => setIsLoading(false));
    }, [])
    
    const getCustomLocaleText = () => {
        const defaultEnLocaleText = enUS.components.MuiDataGrid.defaultProps.localeText;

        if (locale === 'pl') {
            return plPL.components.MuiDataGrid.defaultProps.localeText;
        } else {
            return defaultEnLocaleText;
        }
    };

    const onConfirmClick = () => {
        companiesGridRef.current.getSelectedRows().forEach((f) => {
            dispatch(createAIAccess({ companyId: f.id })).then(unwrapResult).then(result => {
                setAIAccess(produce(draft => {
                    if(draft) {
                        draft.push(result);
                    }
                }))
            });
        })
        setCompaniesDialogOpen(false);
    }

    const columns: GridColDef[] = [
        {
            field: 'companyName',
            headerName: l10n.getString("companies-name", null, "Nazwa firmy"),
            flex: 1
        },
        {
            field: 'limit',
            headerName: 'Limit'
        },
        {
            field: 'status', 
            headerName: 'Status'
        },
        {
            field: 'actions',
            type: 'actions',
            width: 200,
            renderCell: (params) => {
                return (
                    <>
                        <Button onClick={(event: React.MouseEvent<HTMLButtonElement>) => { setAnchorEl(event.currentTarget); setOpenMenu(params.row.id) }} color="secondary" variant="outlined">
                            <Localized id="actions">Akcje</Localized>
                        </Button>
                        <Menu anchorEl={anchorEl} open={openMenu === params.row.id} onClose={() => { setAnchorEl(null); setOpenMenu(null); } }>
                        <MenuList>
                            <If condition={params.row.status !== 'ACTIVE'}>
                                <MenuItem onClick={() => { setActionToRun({ id: params.row.id, actionId: "activeAccess", value: "", type: "PUT", name: params.row.companyName }); setAnchorEl(null); setOpenMenu(null); } }>
                                    <ListItemIcon>
                                        <PlayArrowIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Localized id="ai-activate">Aktywuj</Localized>
                                    </ListItemText>
                                </MenuItem>
                            </If>
                            <If condition={params.row.status !== 'HOLD'}>
                                <MenuItem onClick={() => { setActionToRun({ id: params.row.id, actionId: "holdAccess", value: "", type: "PUT", name: params.row.companyName }); setAnchorEl(null); setOpenMenu(null); } }>
                                    <ListItemIcon>
                                        <PauseIcon fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Localized id="ai-hold">Wstrzymaj</Localized>
                                    </ListItemText>
                                </MenuItem>
                            </If>
                            <MenuItem onClick={() => { setActionToRun({ id: params.row.id, actionId: "changeLimitAccess", value: String(params.row.limit), type: "PUT", name: params.row.companyName }); setAnchorEl(null); setOpenMenu(null); } }>
                                <ListItemIcon>
                                    <TimelapseIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Localized id="ai-change-limit">Zmień limit</Localized>
                                </ListItemText>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={() => { setActionToRun({ id: params.row.id, actionId: "deleteAccess", value: "", type: "DELETE", name: params.row.companyName }); setAnchorEl(null); setOpenMenu(null); } }>
                                <ListItemIcon>
                                    <DeleteIcon fontSize="small" />
                                </ListItemIcon>
                                <ListItemText>
                                    <Localized id="delete">Delete</Localized>
                                </ListItemText>
                            </MenuItem>
                        </MenuList>
                    </Menu>
                </>
                )
            }
        }
    ]

    if (isError){
        return <>
            <ErrorContainer error={error}/>
        </>
    }

    if (isLoading){
        return <>
            <LoadingContainer/>
        </>
    }
    
    return (
        <>
            <DataGrid
                style={{ minHeight: 100, height: 'calc(100vh - 300px)' }}
                localeText={getCustomLocaleText()}
                columns={columns}
                rows={aiAccess}
                initialState={{ pagination: { paginationModel: { pageSize: 20 } } }}
            />
            <Fab sx={{ mt: 2 }} color="secondary" onClick={() => setCompaniesDialogOpen(true)}>
                <AddIcon />
            </Fab>
            <Dialog open={companiesDialogOpen} onClose={() => setCompaniesDialogOpen(false) } fullWidth maxWidth='lg'>
                <DialogTitle>
                    <Localized id="companies">Firmy</Localized>
                </DialogTitle>
                <DialogContent>
                    <CompaniesBrowser gridRef={companiesGridRef}/>
                </DialogContent>
                <DialogActions>
                    <Button onClick={onConfirmClick}>
                        <Localized id='ok'>OK</Localized>
                    </Button>
                    <Button onClick={() => setCompaniesDialogOpen(false)}>
                        <Localized id='cancel'>Anuluj</Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <>{actionToRun && <ActionRunner
                action={actionToRun}
                onError={() => { }}
                onSuccess={result => {
                    setActionToRun(null)
                    if(result.actionId === "activeAccess" || result.actionId === "holdAccess" || result.actionId === "changeLimitAccess") {
                        setAIAccess(produce(draft => {
                            if(draft) {
                                const element = draft.find(f => f.id === result.id);
                                if(element) {
                                    element.status = result.status;
                                    element.limit = result.limit;
                                }
                            }
                        }))
                    } else if(result.actionId === "deleteAccess") {
                        setAIAccess(produce(draft => {
                            if(draft) {
                                const element = draft.findIndex(f => f.id === result.id);
                                if(element !== -1) {
                                    draft.splice(element, 1);
                                }
                            }
                        }))
                    }
                }}
                onCancel={() => setActionToRun(null)} />}</>
        </>
    )
}
