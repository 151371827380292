import { Localized } from "@fluent/react"
import { MenuItem, TextField } from "@mui/material"
import { ChangeEvent } from "react"
import { DocumentTeamMemberRole } from "../teamMemberApi"

export interface TeamMemberRoleMenuFieldProps {
    value: DocumentTeamMemberRole | undefined
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
}

export const TeamMemberRoleMenuField = ({ value, onChange }: TeamMemberRoleMenuFieldProps) => {
    return <>
        <TextField
            sx={{ marginTop: 2 }}
            value={value}
            fullWidth
            required={true}
            select
            label={<Localized id="document-user-role"><span>Rola</span></Localized>}
            onChange={onChange}
        >
            <MenuItem value={"E"}><Localized id="document-role-editor"><span>Edytor</span></Localized></MenuItem>
            <MenuItem value={"R"}><Localized id="document-role-reviever"><span>Opiniujący</span></Localized></MenuItem>
            <MenuItem value={"A"}><Localized id="document-role-approver"><span>Zatwierdzający</span></Localized></MenuItem>
            <MenuItem value={"AR"}><Localized id="document-role-reviever-and-approver"><span>Opiniujący + Zatwierdzający</span></Localized></MenuItem>
            <MenuItem value={"V"}><Localized id="document-role-viewer"><span>Obserwator</span></Localized></MenuItem>
            <MenuItem value={"EXT_R"}><Localized id="document-role-external-reviever"><span>Zewnętrzny opiniujący</span></Localized></MenuItem>
        </TextField>
    </>
}