import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { DatePicker, DateValidationError, PickerChangeHandlerContext } from "@mui/x-date-pickers"
import { createMeasurement, Measurement, updateMeasurementHeader } from "./measurementsApi";
import { useAppDispatch } from "../../app/hooks";
import { useCallback, useState } from "react";
import { AppTextFieldHandler } from "../../app/appTypes";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { LoadingButton } from "@mui/lab";
import { parseISODate } from "../../app/Utils";
import { Mode } from "@mui/icons-material";

export type Mode = 'ADD' | 'EDIT';

export interface AddEditMeasurementDialogProps {
    measurement: Measurement | null
    mode: Mode
    onSucces: (item: Measurement) => void
    onCancel: () => void
}

export const AddEditMeasurementDialog = (props: AddEditMeasurementDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)

    const [name, setName] = useState(props.measurement?.name ?? "")
    const [location, setLocation] = useState(props.measurement?.location ?? "")
    const [performedBy, setPerformedBy] = useState(props.measurement?.performedBy ?? "")
    const [clientCompany, setClientCompany] = useState(props.measurement?.clientCompany ?? "")
    const [measuringDevice, setMeasuringDevicm] = useState(props.measurement?.measuringDevice ?? "")
    const [date, setDate] = useState(props.measurement?.date ?? "")

    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handleLocationChange: AppTextFieldHandler = useCallback(e => setLocation(e.target.value), [setLocation])
    const handlePerformedByChange: AppTextFieldHandler = useCallback(e => setPerformedBy(e.target.value), [setPerformedBy])
    const handleClientCompanyChange: AppTextFieldHandler = useCallback(e => setClientCompany(e.target.value), [setClientCompany])
    const handleMeasuringDevicmChange: AppTextFieldHandler = useCallback(e => setMeasuringDevicm(e.target.value), [setMeasuringDevicm])

    const handleMeasurementDateChange = (date: Date | null, c: PickerChangeHandlerContext<DateValidationError>) => {
        if (date === null || c.validationError !== null) {
            setDate('')
        } else {
            setDate(date.toISOString())
        }
    }

    const measurementDate: Date | null = date ? parseISODate(date) : null;

    const handleOk = async () => {
        if (date && name && location && performedBy && measuringDevice) {
            setSaving(true)
            try {
                if (props.mode == "ADD") {
                    const result =
                        await dispatch(createMeasurement({
                            name: name,
                            location: location,
                            performedBy: performedBy,
                            measuringDevice: measuringDevice,
                            clientCompany: clientCompany,
                            date: date
                        }))

                    if (createMeasurement.fulfilled.match(result)) {
                        const proc = result.payload as Measurement;
                        dispatch(showSuccess("saved"))
                        props.onSucces(proc)
                    }
                    else {
                        let errorMsg = "error"
                        if (result.payload) {
                            if (result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title
                                }
                            }
                        }
                        dispatch(showError(errorMsg))
                    }
                }
                else if (props.measurement) {
                    const result =
                        await dispatch(updateMeasurementHeader({
                            id: props.measurement.id,
                            header: {
                                name: name,
                                location: location,
                                performedBy: performedBy,
                                measuringDevice: measuringDevice,
                                clientCompany: clientCompany,
                                date: date
                            }
                        }))
                    
                    if (updateMeasurementHeader.fulfilled.match(result)) {
                        const proc = result.payload as Measurement;
                        dispatch(showSuccess("saved"))
                        props.onSucces(proc)
                    }
                    else {
                        let errorMsg = "error"
                        if (result.payload) {
                            if (result.payload.kind === 'http') {
                                const problem = result.payload.problem
                                if (problem) {
                                    errorMsg = problem.title
                                }
                            }
                        }
                        dispatch(showError(errorMsg))
                    }
                }
            }
            catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setSaving(false)
        }
    }
}

return <Dialog open={true} fullWidth maxWidth='sm'>
    <DialogTitle><Localized id=''>Rejestracja pomiarów</Localized></DialogTitle>
    <DialogContent>
        <Stack sx={{ mt: 2 }} spacing={2}>
            <Box sx={{ overflowY: "auto" }}>
                <DatePicker
                    sx={{ marginTop: "10px" }}
                    slotProps={{
                        textField: { error: date == "", InputLabelProps: { shrink: true } }
                    }}
                    label={<Localized id=''>Data przeprowadzenia pomiarów</Localized>}
                    value={measurementDate}
                    onChange={handleMeasurementDateChange}
                />
                <TextField
                    error={name === ""}
                    inputProps={{ maxLength: 1000 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={name}
                    onChange={handleNameChange}
                    sx={{ marginTop: "10px" }}
                    label={<Localized id="">Nazwa grupy pomiarów</Localized>}
                />
                <TextField
                    error={location === ""}
                    inputProps={{ maxLength: 1000 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={location}
                    onChange={handleLocationChange}
                    sx={{ marginTop: "10px" }}
                    label={<Localized id="">Miejsce pomiarów</Localized>}
                />
                <TextField
                    error={performedBy === ""}
                    inputProps={{ maxLength: 1000 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={performedBy}
                    onChange={handlePerformedByChange}
                    sx={{ marginTop: "10px" }}
                    label={<Localized id="">Kto przeprowadził pomiary</Localized>}
                />
                <TextField
                    error={measuringDevice === ""}
                    inputProps={{ maxLength: 1000 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={measuringDevice}
                    onChange={handleMeasuringDevicmChange}
                    sx={{ marginTop: "10px" }}
                    label={<Localized id="">Urządzenie pomiarowe</Localized>}
                />
                <TextField
                    inputProps={{ maxLength: 1000 }}
                    fullWidth
                    InputLabelProps={{
                        shrink: true
                    }}
                    value={clientCompany}
                    onChange={handleClientCompanyChange}
                    sx={{ marginTop: "10px" }}
                    label={<Localized id="">Klient dla którego wykonano pomiary</Localized>}
                />
            </Box>
        </Stack>
    </DialogContent>
    <DialogActions>
        <LoadingButton loading={saving} onClick={handleOk}>
            <Localized id='save'>OK</Localized>
        </LoadingButton>
        <LoadingButton loading={saving} onClick={props.onCancel}>
            <Localized id='cancel'>Anuluj</Localized>
        </LoadingButton>
    </DialogActions>
</Dialog>
}
