import { useCallback, useState } from 'react'
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import IconButton from '@mui/material/IconButton'
import CardContent from '@mui/material/CardContent'
import Collapse from '@mui/material/Collapse'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import clsx from 'clsx'
import { Localized } from "@fluent/react"
import { useAppSelector } from "../../../app/hooks"
import { AppId } from '../../../app/appTypes'
import { Alert, Link, Skeleton } from '@mui/material';
import { LoadURSs } from '../../../app/AppDataLoader'
import { selectUrs, UrsHeader } from "../../urs/UrsSlice";
import {
  Link as RouterLink,
} from 'react-router-dom';
import { selectUrsById } from '../../urs/ursListSlice';

const PREFIX = 'ursInfoEexpanded';

const classes = {
    root: `${PREFIX}-root`,
    expand: `${PREFIX}-expand`,
    expandOpen: `${PREFIX}-expandOpen`
};

const StyledCard = styled(Card)(( { theme }) => ({
    [`&.${classes.root}`]: {
      width:"100%",
      flexGrow: 1,
      marginBottom: theme.spacing(1)
    },

    [`& .${classes.expand}`]: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
      }),
    },

    [`& .${classes.expandOpen}`]: {
        transform: 'rotate(180deg)',
    }
}));

export const UrsInfo = ({ id }: { id: AppId }) => {
    return (
        <LoadURSs component={<Skeleton variant="rectangular" height={100} />}>
            <UrsCardIfExists id={id} />
        </LoadURSs>
    );
}

const UrsCardIfExists = ({ id }: { id: AppId }) => {
    const urs = useAppSelector(state => selectUrsById(state , id))

    if (!urs) {
        return <Alert severity="warning">
            <Localized id="error-entity-not-exists">
                <span>Obiekt nie istnieje</span>
            </Localized>
        </Alert>
    } else {
       
        return <UrsCard {...urs} />
    }
}

const UrsCard = ({ id, ursNo, description, purpose }: UrsHeader) => {
    const [ursInfoEexpanded, setUrsInfoExpanded] = useState(false)

    const handleUrsInfoExpandClick = useCallback(() => {
        setUrsInfoExpanded(value => !value)
    }, [])


    return (
        <StyledCard className={classes.root}>
                
            <CardHeader
                action={
                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: ursInfoEexpanded,
                        })}
                        onClick={handleUrsInfoExpandClick}
                        size="large">
                        <ExpandMoreIcon />
                    </IconButton>
                }

                title={ <Link component={RouterLink} color="CaptionText" underline="none" to={`/urs/edit/${id}`}>{ursNo}</Link>}
                subheader={<Localized id="">URS</Localized>} 
            />

            <CardContent>
                <Typography variant="body2" color="textSecondary" component="p">
                    {<Localized id="urs-details">Szczegóły</Localized>}                               
                </Typography>
            </CardContent>
            <Collapse in={ursInfoEexpanded} timeout="auto" unmountOnExit>
                <CardContent>
                                                                       
                <Typography variant="body2" color="textSecondary" component="p">
                    <Localized id="urs-purpose">
                        Cel
                    </Localized>
                </Typography>
                <Typography paragraph>
                    {purpose}
                </Typography>                
                <Typography variant="body2" color="textSecondary" component="p">
                    <Localized id="urs-description">
                        Opis
                    </Localized>
                </Typography>
                <Typography paragraph>
                    {description}
                </Typography>
                </CardContent>
            </Collapse>
        </StyledCard>
    );
}
