import { Box } from "@mui/material";
import FlagIcon from '@mui/icons-material/Flag';

export const MeetingMinutesItemPriorityLabel = ({ priority }: { priority: string }) => {
  switch (priority) {
    case "L":
      return (
        <Box display="flex" alignItems="center">
          <FlagIcon fontSize="small" color="disabled"></FlagIcon>
        </Box>
      );
    case "M":
      return (
        <Box display="flex" alignItems="center">
          <FlagIcon fontSize="small" color="info"></FlagIcon>
        </Box>
      );
    case "H":
      return (
        <Box display="flex" alignItems="center">
          <FlagIcon fontSize="small" color="error"></FlagIcon>
        </Box>
      );
    default:
      return <span></span>
  }
}