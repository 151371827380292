import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { getWithAuth, postWithAuth, putWithAuth, deleteWithAuth } from "../../http";
import { isSuper } from "../modules/moduleSlice";
import { Urs } from "../urs/UrsSlice";
import { logout } from "../user/userSlice";

export type UrsTemplate = Urs

export interface CreateURSPayload {
    createMode: string
    templateId : AppId | undefined
    documentNo: string
    structureId : AppId
    docDesignation  : string
    language: string
    numberingMode: string
}

export interface CreateTemplateFromUrsPayload {
    ursId: AppId
    templateNo: string
    description: string
}


const adapter = createEntityAdapter<UrsTemplate>({
    selectId: (urstemplate) => urstemplate.id,
})

export type UrsTemplateState = EntityState<UrsTemplate> & { state: AppDataState , moduleId: AppId | undefined}

const initialState: UrsTemplateState = adapter.getInitialState({
    state: {
        type: "empty", 
    },
    moduleId: undefined,
})

export const loadUrsTemplate = 
    createAsyncThunk<UrsTemplate, AppId, AppThunkAPIType>("URSTemplate/load", 
    async (id, { dispatch, rejectWithValue, getState, }) => {
        const currentModule = getState().module.currentModule
        const url = currentModule !== null && currentModule.code === 'super' ?
            `api/sys/urs-templates/${id}` :
            `api/UrsTemplate/${id}` 
        const result = await dispatch(getWithAuth({ url }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const loadUrsTemplatesList = 
    createAsyncThunk<UrsTemplate[], AppId, AppThunkAPIType>("URSTemplates/load", 
    async (moduleId, { dispatch, rejectWithValue, getState, requestId }) => {
        const state = getState().ursTemplates.state
        if (state.type === "loading" && state.requestId === requestId) {
            const result = await dispatch(getWithAuth({ 
                url: `api/UrsTemplate?moduleId=${moduleId}`
            }))
            const { payload } = result
            if (getWithAuth.fulfilled.match(result)) {
                return payload as UrsTemplate[]
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return []
        }
})

export const loadSysUrsTemplatesList = 
    createAsyncThunk<UrsTemplate[], void, AppThunkAPIType>("SysURSTemplates/load", 
    async (_, { dispatch, rejectWithValue, getState, requestId }) => {
        const state = getState().ursTemplates.state
        if (state.type === "loading" && state.requestId === requestId) {
            const result = await dispatch(getWithAuth({ 
                url: `api/sys/urs-templates`
            }))
            const { payload } = result
            if (getWithAuth.fulfilled.match(result)) {
                return payload as UrsTemplate[]
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        }
        return []
})

export const createUrsTemplate = createAsyncThunk<UrsTemplate, CreateURSPayload, AppThunkAPIType>(
    "urstemplate/new", 
    async (data: CreateURSPayload,  { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/UrsTemplate", 
            payload: data,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as UrsTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }             
    }
)

export const createSysUrsTemplate = createAsyncThunk<UrsTemplate, CreateURSPayload, AppThunkAPIType>(
    "sysurstemplate/new", 
    async (data: CreateURSPayload,  { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/sys/urs-templates", payload: data }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as UrsTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }             
    }
)

export const updateUrsTemplate = createAsyncThunk<UrsTemplate, UrsTemplate, AppThunkAPIType>(
    "urstemplate/edit", 
    async (ursTemplate: UrsTemplate, { dispatch, rejectWithValue, getState }) => {
        const url = isSuper(getState()) ? `api/sys/urs-templates/${ursTemplate.id}` : `api/UrsTemplate/${ursTemplate.id}`
        const result = await dispatch(putWithAuth({ 
            url,
            payload: ursTemplate,
        }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return payload
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSysUrsTemplate = createAsyncThunk<UrsTemplate, UrsTemplate, AppThunkAPIType>(
    "sysurstemplate/edit", 
    async (ursTemplate: UrsTemplate, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: ``, payload: ursTemplate }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return ursTemplate
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteUrsTemplate = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "urstemplate/delete",
    async (ursTemplateId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/UrsTemplate/${ursTemplateId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return ursTemplateId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSysUrsTemplate = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "sysurstemplate/delete",
    async (ursTemplateId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/sys/urs-templates/${ursTemplateId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return ursTemplateId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createTemplateFromUrs = createAsyncThunk<UrsTemplate, CreateTemplateFromUrsPayload, AppThunkAPIType>( 
    "urstemplate/createTemplateFromUrs", 
    async (data: CreateTemplateFromUrsPayload, { dispatch, rejectWithValue }) => {

    const result = await dispatch(postWithAuth({ 
        url: `api/UrsTemplate/create-from-urs`,
        payload: data,
    }))

    const { payload } = result
    if (postWithAuth.fulfilled.match(result)) {
        return payload as UrsTemplate
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }             
}
)


export const ursTemplateSlice = createSlice({
    name: "urstemplate",
    initialState,
    reducers: {
        clearUrsSlice: (state) => {
            adapter.removeAll(state)
            state.state = {
                type: "empty",
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createUrsTemplate.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(createTemplateFromUrs.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        
        builder.addCase(createSysUrsTemplate.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(updateUrsTemplate.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<UrsTemplate, "id"> = action.payload
            
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(updateSysUrsTemplate.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<UrsTemplate, "id"> = action.payload
            
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(deleteUrsTemplate.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });

        builder.addCase(deleteSysUrsTemplate.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });
        builder.addCase(loadUrsTemplatesList.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSysUrsTemplatesList.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadUrsTemplatesList.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
                state.moduleId = action.meta.arg
            }
        });
        builder.addCase(loadSysUrsTemplatesList.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
                state.moduleId = undefined
            }
        });

        builder.addCase(loadUrsTemplatesList.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadSysUrsTemplatesList.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectUrsTemplates = (state: RootState): UrsTemplateState => state.ursTemplates

export const 
    { selectAll: selectAllUrsTemplates
    , selectById: selectUrsTemplateById 
    , selectTotal: selectTotalUrsTemplates
    } = adapter.getSelectors<RootState>(selectUrsTemplates)

export default ursTemplateSlice.reducer;

