import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, nanoid } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { getWithAuth, postWithAuth, putWithAuth, deleteWithAuth } from "../../http"

export interface RequirementType {
   id       : AppId,
   code     : string,
   name     : string,
   name_EN  : string
   order    : number,
   isSystem : boolean
}


const adapter = createEntityAdapter<RequirementType>({
    selectId: (requirementType) => requirementType.id,
})

export type RequirementTypeState = EntityState<RequirementType> & { state: AppDataState }

const initialState: RequirementTypeState = adapter.getInitialState({
    state: { type: "empty" },
})

export const loadRequirementsTypes = createAsyncThunk<RequirementType[], void, AppThunkAPIType>("requirementsTypes/load", async (_, { getState, requestId, dispatch, rejectWithValue }) => {
    const { state: sliceState } = getState().requirementsTypes

    if(sliceState.type === "loading" && requestId === sliceState.requestId) {
        const result = await dispatch(getWithAuth({ url: "api/requirements-types" }))
        const { payload } = result

        if(getWithAuth.fulfilled.match(result)) {
            return payload as RequirementType[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    } 
    return []
})

export const loadSysRequirementsTypes = createAsyncThunk<RequirementType[], void, AppThunkAPIType>("sysrequirementsTypes/load", async (_, { getState, requestId, dispatch, rejectWithValue }) => {
    const { state } = getState().requirementsTypes
    if (state.type === "loading" && state.requestId === requestId) {
        const result = await dispatch(getWithAuth({ url: "api/sys/requirements-types" }))
        const { payload } = result

        if(getWithAuth.fulfilled.match(result)) {
            return payload as RequirementType[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
    return []
})

export const createRequirementType = createAsyncThunk<RequirementType, RequirementType, AppThunkAPIType>(
    "requirementsTypes/new", 
    async (requirementType: RequirementType, { dispatch, rejectWithValue }) => {
        requirementType.id = nanoid()
        const result = await dispatch(postWithAuth({ url: "api/requirements-types", payload: requirementType }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as RequirementType
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }        
    }
)

export const createSysRequirementType = createAsyncThunk<RequirementType, RequirementType, AppThunkAPIType>(
    "sysrequirementsTypes/new", 
    async (requirementType: RequirementType, { dispatch, rejectWithValue }) => {
        requirementType.id = nanoid()
        const result = await dispatch(postWithAuth({ url: "api/sys/requirements-types", payload: requirementType }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as RequirementType
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }        
    }
)


export const updateRequirementType = createAsyncThunk<RequirementType, RequirementType, AppThunkAPIType>(
    "requirementsTypes/edit", 
    async (requirementType: RequirementType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/requirements-types/${requirementType.id}`, payload: requirementType }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return requirementType
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateSysRequirementType = createAsyncThunk<RequirementType, RequirementType, AppThunkAPIType>(
    "sysrequirementsTypes/edit", 
    async (requirementType: RequirementType, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/sys/requirements-types/${requirementType.id}`, payload: requirementType }))
        const { payload } = result
        if(putWithAuth.fulfilled.match(result)) {
            return requirementType
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteRequirementType = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "requirementType/delete",
    async (requirementTypeId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/requirements-types/${requirementTypeId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return requirementTypeId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteSysRequirementType = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "sysrequirementType/delete",
    async (requirementTypeId, { rejectWithValue, dispatch }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/sys/requirements-types/${requirementTypeId}` }))
        const { payload } = result 
        if (deleteWithAuth.fulfilled.match(result)) {
            return requirementTypeId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const requirementsSlice = createSlice({
    name: "requirementsTypes",
    initialState,
    reducers: {
        clearRequirementsTypesSlice: (state) => {
            adapter.removeAll(state)
            state.state = { type: "empty" }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createRequirementType.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(createSysRequirementType.fulfilled, (state, action) => {
            state = adapter.addOne(state, action.payload)
        });
        builder.addCase(updateRequirementType.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<RequirementType, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(updateSysRequirementType.fulfilled, (state, action) => {           
            const { id } = action.payload
            const changes: Omit<RequirementType, "id"> = action.payload
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        });
        builder.addCase(deleteRequirementType.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });

        builder.addCase(deleteSysRequirementType.fulfilled, (state, action) => {           
            const  id  = action.payload
           
            state = adapter.removeOne(state, id);
        });

        builder.addCase(loadRequirementsTypes.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadSysRequirementsTypes.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })

        builder.addCase(loadRequirementsTypes.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSysRequirementsTypes.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })

        builder.addCase(loadRequirementsTypes.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadSysRequirementsTypes.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
    },
})

export const selectRequirementsTypes = (state: RootState): RequirementTypeState => state.requirementsTypes

export const 
    { selectAll: selectAllRequirementsTypes
    , selectById: selectRequirementTypeById 
    , selectTotal: selectTotalRequirementsTypes
    , selectEntities: selectRequirementsTypesEntities
    } = adapter.getSelectors<RootState>(selectRequirementsTypes)

export default requirementsSlice.reducer;
