import { Button, ButtonGroup, Chip, Collapse, Dialog, DialogActions, DialogContent, IconButton, List, ListItem, ListItemText, Paper, TextField, Tooltip, Typography } from "@mui/material"
import { Box, Stack } from "@mui/system"
import { format, parseISO } from "date-fns"
import { useCallback, useMemo, useState } from "react"
import { APIError, AppId, AppTextFieldHandler } from "../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { changeCommentStatus, CommentStatus, createURSComment, selectURSCommentById, updateURSComment, URSComment } from "./URSCommentSlice"
import { Localized } from "@fluent/react"
import { If } from "../../app/If"
import SendIcon from '@mui/icons-material/Send'
import ReplyIcon from '@mui/icons-material/Reply'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import RemoveDoneIcon from '@mui/icons-material/RemoveDone'
import CancelIcon from '@mui/icons-material/Cancel'
import { URSRequirement } from "./UrsSlice"
import EditIcon from '@mui/icons-material/Edit'
import { selectLoggedUser } from "../user/userSlice"
import { LoadingButton } from "@mui/lab"
import { unwrapResult } from "@reduxjs/toolkit"
import { ServerErrorMsg } from "../../app/ServerErrorMsg"

export interface CommentsProps {
    ursId         : AppId
    requirement   : URSRequirement | null
    comments      : URSComment[]
    isCommentEnabled   : () => boolean
    isEditEnabled : (comment: URSComment) => boolean
    isResolvingEnabled : () => boolean
}

export const Comments = ({ ursId, requirement, comments, isCommentEnabled, isResolvingEnabled, isEditEnabled }: CommentsProps) => {
    const dispatch = useAppDispatch()
    
    const [content, setContent] = useState("")
    const handleContentChange: AppTextFieldHandler = useCallback(e => setContent(e.target.value), [setContent])

    const handlePostClick = async () => {
        if (content) {
            try {
                await dispatch(createURSComment({
                    id: "",
                    comment: content,
                    createdBy: "",
                    createDate: "",
                    createdByFullName: "",
                    modifiedBy: "",
                    modifyDate: "",
                    ursRequirementId: requirement?.id ?? null,
                    ursId: ursId,
                    status: 'N',
                    parentCommentId: null,
                    subComments: [],
                })).unwrap()
                setContent("")
            } catch (e) {
                /* handle error */
            }
        }
    }
    const commentsToShow = useMemo(() => {
        if (requirement === null) {
            return comments.filter(c => c.ursRequirementId === null && c.parentCommentId === null)
        } else {
            return comments.filter(c => c.ursRequirementId === requirement.id && c.parentCommentId === null)
        }
    }, [comments, requirement])
    const sendEnabled = isCommentEnabled()
    const actionEnabled = isResolvingEnabled()

    return <Stack spacing={2}>
        <Typography component="div" variant="button">
            <Localized id='comments'>Komentarze</Localized>:
        </Typography>
        {commentsToShow.length > 0 ? commentsToShow.map(c => <Comment editEnabled={isEditEnabled} key={c.id} entity={c} replyEnabled={sendEnabled} actionsEnabled={actionEnabled}/>) : <NoComments />}
        <If condition={sendEnabled}>
            <Stack direction="row" spacing={1}>
                <TextField value={content} onChange={handleContentChange} placeholder="Komentarz..." fullWidth />
                <IconButton onClick={handlePostClick} size="large">
                    <SendIcon />
                </IconButton> 
            </Stack>
        </If>
    </Stack>
}

const NoComments = () => {
    return <Typography sx={{ opacity: 0.5 }} variant="body2"><Localized id="urs-no-comments">Brak komentarzy</Localized></Typography>
}

export interface CommentProps {
    entity: URSComment
    replyEnabled: boolean
    actionsEnabled: boolean
    editEnabled: (comment: URSComment) => boolean
}

const Comment = ({entity, replyEnabled, actionsEnabled, editEnabled }: CommentProps) => {
    const { comment, createdByFullName, createDate, ursId, id, ursRequirementId, subComments, status, createdBy } = entity
    const dispatch = useAppDispatch()
    const currentUser = useAppSelector(selectLoggedUser)
    const date = parseISO(createDate)
    const formatedDate = format(date, "dd-LL-uuuu HH:mm")
    const parentComment = useAppSelector(state => selectURSCommentById(state, id))

    const [reply, setReply] = useState(false)
    const [msg, setMsg] = useState("")
    const [showAll, setShowAll] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const [draftContent, setDraftContent] = useState(comment)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState<APIError | null>(null)

    // BEGIN HANDLERS
    //
    // clicking
    const handleReplyClick = useCallback(() => setReply(true), [])
    const handleCancelReply = useCallback(() => setReply(false), [])
    const handleShowAllClick = useCallback(() => setShowAll(true), [])
    const handleHideClick = useCallback(() => setShowAll(false), [])
    const handleSendReply = async () => {
        if (msg === "") return
        try {
            await dispatch(createURSComment({
                id: "",
                comment: msg,
                createdBy: "",
                createDate: "",
                createdByFullName: "",
                modifiedBy: "",
                modifyDate: "",
                ursRequirementId,
                ursId: ursId,
                status: 'N',
                parentCommentId: id,
                subComments: [],
            })).unwrap() 
            setMsg("")
            setReply(false)
            if (parentComment) {
                await dispatch(changeCommentStatus({id: parentComment.id, status: 'N' })).unwrap()
            }
        } catch (error) {
            
        }        
    }
    const handleResolveClick = async () => {
        try {
            await dispatch(changeCommentStatus({id: entity.id, status: "R"}))
        } catch (error) {
            
        }
    }
    const handleUnresolveClick = async () => {
        try {
            await dispatch(changeCommentStatus({id: entity.id, status: "U"}))
        } catch (error) {
            
        }
    }
    const handleUpdateCommentClick = async () => {
        setSaving(true)
        dispatch(updateURSComment({id: entity.id, comment: draftContent}))
            .then(unwrapResult)
            .catch((e: APIError) => setError(e))
            .then(() => {
                setEditMode(false)
            })
            .finally(() => setSaving(false))
    }
    //
    // changing
    const handleMsgChange: AppTextFieldHandler = useCallback(e => setMsg(e.target.value), [])
    //
    // END HANDLERS
    //

    const hasAnyReply = subComments.length > 0
    const editButtonVisible = editEnabled(entity) && editMode === false

    return <Paper sx={{ padding: 2 }} elevation={4}>
        <Stack>
            <ServerErrorMsg err={error} />
            <Stack direction='row'>
                <Typography color="text.secondary" component="div" variant="caption">
                    <span>{formatedDate} by </span><span>{createdByFullName}</span>
                </Typography>
                <CommentStatusLabel status={status} /> 
            </Stack>
            {!editMode && <Typography component="div" gutterBottom variant="body1">{comment}</Typography>}
            {editMode && <Stack direction='row'>
                <TextField sx={{ width: '60%' }} multiline value={draftContent} onChange={e => setDraftContent(e.target.value)} hiddenLabel size="small" variant="outlined"/>
                <LoadingButton loading={saving} onClick={handleUpdateCommentClick} size='small'><Localized id="save">Zapisz</Localized></LoadingButton>
                <LoadingButton loading={saving} onClick={() => {
                    setEditMode(false)
                    }} size='small'><Localized id="cancel">Anuluj</Localized></LoadingButton>
            </Stack>}
            <Stack direction="row">
                <ButtonGroup>
                    <If condition={replyEnabled}>
                        <Tooltip title={<Localized id="answer">Odpowiedz</Localized>}>
                            <IconButton onClick={handleReplyClick} size="small">
                                <ReplyIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </If>
                    <If condition={actionsEnabled}>
                        <Tooltip title={<Localized id="mark-as-resolved">Oznacz jako rozwiązany</Localized>}>
                            <IconButton onClick={handleResolveClick} size="small">
                                <DoneAllIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={<Localized id="mark-as-rejected">Oznacz jako odrzucony</Localized>}>
                            <IconButton onClick={handleUnresolveClick} size="small">
                                <RemoveDoneIcon fontSize="small" />
                            </IconButton>
                        </Tooltip>
                    </If>
                    <If condition={editButtonVisible}>
                        <IconButton onClick={() => {
                            setDraftContent(comment)
                            setEditMode(true)
                        }} size="small">
                            <EditIcon fontSize="small" />
                        </IconButton>
                    </If>
                </ButtonGroup>
                {hasAnyReply && !showAll && <Button onClick={handleShowAllClick} sx={{ marginLeft: 'auto' }} size='small'>
                    Pokaż odpowiedzi
                </Button>}
                {hasAnyReply && showAll && <Button onClick={handleHideClick} sx={{ marginLeft: 'auto' }} size='small'>
                    Ukryj odpowiedzi
                </Button>}
            </Stack>
            {reply && <Stack direction="row">
                <TextField sx={{ minWidth: '50%' }} value={msg} onChange={handleMsgChange} size="small" variant="filled" margin="none" hiddenLabel />
                <ButtonGroup>
                    <Tooltip title={<Localized id="send">Wyślij</Localized>}>
                        <IconButton onClick={handleSendReply}><SendIcon fontSize="small" /></IconButton>
                    </Tooltip>
                    <Tooltip title={<Localized id="cancel">Anuluj</Localized>}>
                        <IconButton onClick={handleCancelReply}><CancelIcon fontSize="small" /></IconButton>
                    </Tooltip>
                </ButtonGroup>
            </Stack>}
            <Collapse sx={{ minWidth: '50%', alignSelf: 'flex-end', }} in={showAll}>
                <Replies comments={subComments} />
            </Collapse>
        </Stack>
    </Paper>
}

const CommentStatusLabel = ({ status }: { status: CommentStatus }) => {
    switch (status) {
        case "N":
                return <Chip sx={{ marginLeft: 'auto' }} size="small" color="info" label={<Localized id="new">Nowy</Localized>} />
        case "R":
                return <Chip sx={{ marginLeft: 'auto' }} size="small" color="success" label={<Localized id="resolved">Rozwiązany</Localized>} />
        case "U":
                return <Chip sx={{ marginLeft: 'auto' }} size="small" color="error" label={<Localized id="unresolved">Odrzucony</Localized>} />
    }
}

export interface CommentsDialogProps extends CommentsProps {
    onClose: () => void
}

export const CommentsDialog = (props: CommentsDialogProps) => {
    const { onClose, ...commentsProps } = props
    const { requirement } = commentsProps
    return <Dialog open={true} fullWidth maxWidth="md">
        <DialogContent>
            <If condition={commentsProps.requirement !== null}>
                <Typography component="div" variant="button">
                    <Localized id='requirements-name'>Nazwa wymagania</Localized>:
                </Typography>
                <div dangerouslySetInnerHTML={{ __html: requirement?.name ?? "" }}></div>
            </If>
            <Box sx={{ marginTop: 2 }}>
                <Comments {...commentsProps} />
            </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose}>
                <Localized id="close">Close</Localized>
            </Button>
        </DialogActions>
    </Dialog>
}

const Replies = ({ comments }: { comments: URSComment[] }) => {
    return <List>{comments.map(({ id, comment, createDate, createdByFullName }) => {
            const date = parseISO(createDate)
            const formatedDate = format(date, "dd-LL-uuuu HH:mm")
            return <ListItem key={id} sx={{ bgcolor: '#f1f1f1' }}  alignItems="flex-start">
                <ListItemText primary={comment} secondary={
                    <> <span>{formatedDate} by </span><span>{createdByFullName}</span></>
                }/>
            </ListItem>
        })}
    </List>
}

