import { Box } from '@mui/material';


export const ColorChangingDot = ({ }: { }) => {
  return (
    <Box
        sx={{
            marginRight: 1, // odstęp między kołem a tekstem
            width: 9, // szerokość i wysokość koła
            height: 9,
            borderRadius: '50%', // zaokrąglenie do formy koła
            backgroundColor: 'green', // kolor tła koła
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}
    />
)};