import { Localized } from "@fluent/react"
import { Box, LinearProgress, Typography } from "@mui/material"

export const LoadingContainer = () => {
    return <Box sx={{ width: '100%' }}>
     <Typography variant="button" color="CaptionText" gutterBottom>                  
        <Localized id='loading-data'>Wczytywanie danych...</Localized>
    </Typography>
    <LinearProgress/>
</Box>
}