import { Localized } from "@fluent/react"
import { Alert, Button, Card, CardContent, Chip, Divider, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material"
import { Container, Stack } from "@mui/system"
import { useParams } from "react-router-dom"
import { LoadActivities, LoadValidations } from "../../app/AppDataLoader"
import { useAppSelector } from "../../app/hooks"
import { AcceptanceCriteria, Activity, selectAllActivities } from "./activitiesSlice"
import { selectValidationById, Validation } from "./validationsSlice"
import { useCallback } from "react"
import { selectModule } from "../modules/moduleSlice"
import ButtonGroup from '@mui/material/ButtonGroup'
import { useNavigate } from "react-router-dom"


export const ViewValidation = () => {
    return <LoadValidations component={<Skeleton />}>
        <LoadActivities component={<Skeleton />}>
            <Edit />
        </LoadActivities>
    </LoadValidations>
}

const Edit = () => {
    const { id: paramId } = useParams<{ id: string }>()
    const id = paramId ?? ''
    const test = useAppSelector(state => selectValidationById(state, id))
    const steps = useAppSelector(state => selectAllActivities(state).filter(({ validationId }) => validationId === id))
    const module = useAppSelector(state => selectModule(state).currentModule?.code)

    if (module === undefined) return null

    if (!test) return <Alert severity="warning">
        <Localized id="error-entity-not-exists">
            <div>Obiekt nie istnieje</div>
        </Localized>
    </Alert>

    return <TestView
        test={test}
        steps={steps}
    />
}

interface ValidationFormProps {
    test: Validation
    steps: Activity[]
}

const TestView = ({ test }: ValidationFormProps) => {
    const { id, name, description, stage } = test
    const steps = useAppSelector(state => selectAllActivities(state).filter(({ validationId }) => validationId === id))
    const navigate = useNavigate()

    const handleBackClick = useCallback(() => {
        navigate(-1)
    }, [navigate])


    return <Container maxWidth="lg">
        <Stack spacing={2}>
            <Card>
                <CardContent>
                    <Typography component="div" color="text.secondary" gutterBottom>
                        <Localized id="validation-test-name">Nazwa testu</Localized>&nbsp;<Chip size="small" color="primary" variant="outlined" label={stage} />
                    </Typography>
                    <Typography variant="h5">{name}</Typography>
                    <Divider sx={{
                        marginTop: 1,
                    }}>
                        <Localized id="validation-description">Opis</Localized>
                    </Divider>
                    <Typography variant="body2">{description}</Typography>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <Typography color="text.secondary" gutterBottom>
                        <Localized id="validation-test-steps">Kroki testu</Localized>
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ width: "60px" }}>
                                        <Localized id="lp">L.p.</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="validation-description">Opis</Localized>
                                    </TableCell>
                                    <TableCell>
                                        <Localized id="validation-acceptance-criteria">Kryteria akceptacji</Localized>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>{steps.map((step, i) => <TestStepView
                                key={step.id}
                                seq={i + 1}
                                step={step}
                            />)}</TableBody>
                        </Table>
                    </TableContainer>
                </CardContent>
            </Card>
            <Card>
                <CardContent>
                    <ButtonGroup>
                        <Button onClick={handleBackClick} color="secondary" variant="outlined">
                            <Localized id="back">Wróć</Localized>
                        </Button>
                    </ButtonGroup>
                </CardContent>
            </Card>
        </Stack>
    </Container>
}

interface TestStepViewProps {
    step: Activity
    seq: number
}

const TestStepView = ({ step, seq }: TestStepViewProps) => {
    const { id, name, acceptanceCriteria } = step
    const rowsNum = acceptanceCriteria.length === 0 ? 1 : acceptanceCriteria.length
    const [fst, ...rest] = acceptanceCriteria

    return <>
        <TableRow key={`${id}_a`}>
            <TableCell rowSpan={rowsNum}>{seq}.</TableCell>
            <TableCell rowSpan={rowsNum}>{name}&nbsp;</TableCell>
            <TableCell>{fst === undefined ? <EmptyCriteria /> : <CriteriaCellView criterion={fst} />}</TableCell>
        </TableRow>
        {rest.map((x) => <TableRow key={`${id}_${x.id}`}><TableCell><CriteriaCellView criterion={x} /></TableCell></TableRow>)}
        <TableRow key={`${id}_z`}>
            <TableCell sx={{
                textAlign: "right"
            }} colSpan={3}></TableCell>
        </TableRow>
    </>
}

interface CriteriaCellViewProps {
    criterion: AcceptanceCriteria
}

const CriteriaCellView = ({ criterion }: CriteriaCellViewProps) => {
    const { description } = criterion
    return <>{description}&nbsp;</>
}

const EmptyCriteria = () => <Typography color="text.secondary"><span>N\A</span></Typography>
