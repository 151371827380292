import { createAsyncThunk, createEntityAdapter, createSlice, Draft, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { load } from "../../app/crud";
import { RootState } from "../../app/store";
import { deleteWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";
import { ValidationStage } from "../validations/validationsSlice";
import { loadProcess } from "./processSlice";

export type TestStatus = "P" | "F" | "V"

export interface ProcessTest {
    id: AppId
    name: string
    description: string 
    stage: ValidationStage
    status: TestStatus
    testUser: string
    resultApproverUser: string
    plannedDate: string
    processId: AppId,
    hasErrors: Boolean
}

const adapter = createEntityAdapter<ProcessTest>({
    selectId: (test) => test.id,
})  

type ProcTestKey = {
    processId: AppId,
    stage: ValidationStage,
}

export type ProcessTestState = EntityState<ProcessTest> & { status: AppDataState, key: ProcTestKey | undefined }

const initialState: ProcessTestState = adapter.getInitialState({
    status: { type: "empty" },
    key: undefined,
})

export const loadProcessTests = load<{ procId: AppId, stage: ValidationStage }, ProcessTest>(
    "processTests/load", 
    ({ procId, stage }) => `api/ProcessTest/${procId}/${stage}`,
    state => selectProcessTests(state).status,
)
    
export const changeAllTestTester = createAsyncThunk<ProcessTest[], { procId: AppId, stage: ValidationStage, userName: string }, AppThunkAPIType>(
    "processTest/changeAllTestTester",
    async ({ procId, stage, userName }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/change-tests-tester/${procId}/${stage}`,
            payload: {
                userName,
            }
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as  ProcessTest[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }       
    }
)

export const changeAllTestApprover = createAsyncThunk<ProcessTest[], { procId: AppId, stage: ValidationStage, userName: string }, AppThunkAPIType>(
    "processTest/changeAllTestApprover",
    async ({ procId, stage, userName }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/change-tests-approver/${procId}/${stage}`,
            payload: {
                userName,
            }
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as  ProcessTest[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }       
    }
)

export const changeAllTestPlannedDate = createAsyncThunk<ProcessTest[], { procId: AppId, stage: ValidationStage, plannedDate: string }, AppThunkAPIType>(
    "processTest/changeAllTestApprover",
    async ({ procId, stage, plannedDate }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/change-tests-plan-date/${procId}/${stage}`,
            payload: {
                "date": plannedDate,
            }
        }))
        const { payload } = result 
        if (postWithAuth.fulfilled.match(result)) {
            return payload as  ProcessTest[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }       
    }
)

export const finishProcessTest = createAsyncThunk<ProcessTest, ProcessTest, AppThunkAPIType>(
    "processTest/sendToApprove",
    async ({ id, processId }, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/finish/${id}`,
            payload: {},
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            await dispatch(loadProcess(processId))

            return payload as ProcessTest
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const approveProcessTest = createAsyncThunk<ProcessTest, { process: ProcessTest, password: string}, AppThunkAPIType>(
    "processTest/approve",
    async ({ process, password }, { rejectWithValue, dispatch }) => {
        const { id, processId } = process
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/verified/${id}`,
            payload: {
                password,
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            await dispatch(loadProcess(processId))

            return payload as ProcessTest
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateProcessTest = createAsyncThunk<ProcessTest, ProcessTest, AppThunkAPIType>(
    "processTest/update",
    async (test, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/ProcessTest/${test.id}`,
            payload: test,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return test
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createProcessTest = 
    createAsyncThunk<ProcessTest, ProcessTest, AppThunkAPIType>(
    "processTest/create",
    async (test, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ProcessTest/`,
            payload: test,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProcessTest
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const copyProcessTestFromCatalog = 
    createAsyncThunk<ProcessTest, { processId: AppId, stage: ValidationStage, validationId: AppId }, AppThunkAPIType>(
        "processTest/copy", 
        async ({ processId, stage, validationId }, { dispatch, rejectWithValue }) => {
            const result = await dispatch(postWithAuth({
                url: `api/ProcessTest/copy-test/${processId}/${stage}`,
                payload: {
                    validationId,
                },
            }))
            const { payload } = result
            if (postWithAuth.fulfilled.match(result)) {
                return payload as ProcessTest
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
    })

export const deleteTest = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "processTest/delete",
    async (testId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/ProcessTest/${testId}`}))
        if (deleteWithAuth.fulfilled.match(result)) {
            return testId
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }
    }
)

export const processTestsSlice = createSlice({
    name: "processTests",
    initialState,
    reducers: {
        clearProcessTests: (state) => {
            state.status.type = "empty"
            adapter.removeAll(state)
            state.key = undefined
        },
    },
    extraReducers: builder => {
        builder.addCase(loadProcessTests.pending, (state, action) => {
            if (state.status.type === "empty") {
                const { procId, stage } = action.meta.arg
                state.status = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
                state.key = {
                    processId: procId,
                    stage,
                }
            }
        })       
        builder.addCase(loadProcessTests.fulfilled, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                adapter.setMany(state, action.payload)
                state.status = { type: "loaded" }
            }
        })
        builder.addCase(loadProcessTests.rejected, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                state.status = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(updateProcessTest.fulfilled, (state, action) => {
            upsertIfProperStageIsInSlice(state, action.payload)
        })
        builder.addCase(createProcessTest.fulfilled, (state, action) => {
            upsertIfProperStageIsInSlice(state, action.payload)
        })
        builder.addCase(copyProcessTestFromCatalog.fulfilled, (state, action) => {
            upsertIfProperStageIsInSlice(state, action.payload)
        })
        builder.addCase(finishProcessTest.fulfilled, (state, action) => {
            upsertIfProperStageIsInSlice(state, action.payload)
        })
        builder.addCase(approveProcessTest.fulfilled, (state, action) => {
            upsertIfProperStageIsInSlice(state, action.payload)
        })
        builder.addCase(deleteTest.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)
        })   
        builder.addCase(logout.fulfilled, (state) => {
            adapter.removeAll(state)
            state.status = { type: "empty" }
            state.key = undefined
        })
        builder.addCase(changeAllTestTester.fulfilled, (state, action) => {
            adapter.setMany(state, action.payload)
            state.status = { type: "loaded" }
        })
        builder.addCase(changeAllTestApprover.fulfilled, (state, action) => {
            adapter.setMany(state, action.payload)
            state.status = { type: "loaded" }
        })
    },
})

const upsertIfProperStageIsInSlice = (state: Draft<ProcessTestState>, test: ProcessTest) => {
    const { key, status } = state
    const { processId, stage } = key ?? {}
    const entity = test 
    if (status.type === "loaded" && processId === entity.processId && stage === entity.stage) {
        adapter.upsertOne(state, test)
    }
}

export const { clearProcessTests } = processTestsSlice.actions

export const selectProcessTests = (state: RootState) => state.processTests

export const {
    selectAll: selectAllProcessTests,
    selectById: selectProcessTestById,
} = adapter.getSelectors(selectProcessTests)

export default processTestsSlice.reducer
