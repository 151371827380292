import { useCallback, useEffect, useRef, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import { AppId } from '../../../../app/appTypes';
import { Localized } from '@fluent/react';
import { useAppDispatch } from '../../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { loadSummaryForObject, MeetingMinute, MeetingMinuteSummary, MinuteMeetingStatus } from '../../mettingMinutes/meetingMinutesSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import { MeetingStatusLabel } from '../../mettingMinutes/MeetingStatusLabel';
import { ErrorContainer } from '../../../../app/ErrorContainer';
import { LoadingContainer } from '../../../../app/LoadingContainer';
import ForwardIcon from '@mui/icons-material/Forward';
import { parseAndFormatISODate } from "../../../../app/Utils"
import { Button, Dialog, Link } from '@mui/material';
import { AddMeetingMinute } from '../../mettingMinutes/dialogs/AddMeetingMinute';
import EventNoteIcon from '@mui/icons-material/EventNote';

interface MeetingMinutesWigdetProps {
    projectGuid: AppId;
    projectId: AppId;
}

const EmptyWidgetNotice = () => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
                p={3}
                borderRadius={2}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    <Localized id='meeting-minutes-empty-list'>Brak notatek</Localized>
                </Typography>
                <EventNoteIcon style={{ fontSize: 80, color: '#a0a0a0' }} />

                <Typography variant="body1" gutterBottom>
                    <Localized id='meeting-minutes-empty-list-message'>Brak notatek</Localized> 
                </Typography>
            </Box>
        </>
    );
};

const MeetingMinutesWigdet = (props: MeetingMinutesWigdetProps) => {

    const { projectGuid, projectId} = props
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const [notes, setNotes] = useState<MeetingMinuteSummary[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [addNewNoteDialogVisible, setAddNewNoteDialogVisible] = useState(false);

    const activeMeetingsCount = notes.filter(note => note.status === 'INMEETING').length;
    const newItemGuid = useRef<string | null>(null);
    const newItemRef = useRef<HTMLLIElement | null>(null);


    const sortNotes = (notes) => {
        return notes.sort((a, b) => a.title.localeCompare(b.title));
    };

    const loadData = () => {
        setIsLoading(true);
        dispatch(loadSummaryForObject({ objectId: projectGuid, objectType: "PROJECT" }))
            .then(unwrapResult)
            .then(sortNotes)
            .then(setNotes)
            .catch((error) => {
                console.error(error);
                setIsError(true);
                setError(error);
            })
            .finally(() => setIsLoading(false));
    };

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        if (newItemGuid.current && newItemRef.current) {
            newItemRef.current.scrollIntoView({ behavior: 'smooth' });
            newItemGuid.current = null;
        }
    }, [notes]);


    const getStatusColor = (status: MinuteMeetingStatus) => {
        switch (status) {
            case 'INMEETING':
                return 'green';
            case 'CLOSED':
                return 'lightblue';
            default:
                return 'grey';
        }
    };

    const handleOpenMeetingForm = (id: string) => {
        navigate(`/meetingMinutes/edit/${id}`)
    };

    const generateHref = (projectId) => {
        return `/projects/edit/${projectId}?tab=meetingMinutes`;
    };

    const handleAddNewNote = useCallback(() => {
        setAddNewNoteDialogVisible(true)
    }, [setAddNewNoteDialogVisible])

    const onSuccess = useCallback((note: MeetingMinute) => {
        newItemGuid.current = note.id;
        setAddNewNoteDialogVisible(false)
        loadData();
    }, [dispatch])

    const handleCancelDialog = useCallback(() => setAddNewNoteDialogVisible(false), [])

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (<>
        <Paper sx={{ flexGrow: 1, padding: 2, height: '60vh', maxHeight: '60vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" gutterBottom>
                    <Localized id='meeting-minutes-minute-list'>Lista notatek</Localized>
                </Typography>
            </Box>
            <Divider />
            {
                notes.length === 0 ? (
                    <EmptyWidgetNotice />
                ) : (
                    <List sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: 1 }}>
                        {notes.map((note) => (
                            <ListItem
                                key={note.id}
                                ref={note.id === newItemGuid.current ? newItemRef : null}

                                sx={{
                                    backgroundColor: '#f5f5f5',
                                    marginBottom: 2,
                                    '&:hover': { backgroundColor: '#e0e0e0' },
                                    borderRadius: 1,
                                }}
                                onDoubleClick={() => handleOpenMeetingForm(note.id)}
                            >
                                <ListItemText
                                    primary={note.title}
                                    primaryTypographyProps={{ fontWeight: 'bold' }}
                                    secondary={
                                        <>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <CircleIcon sx={{ color: getStatusColor(note.status), mr: 1, fontSize: 'small' }} />
                                                <Typography component="span" variant="body2" fontWeight="bold">
                                                    {note.status === 'INMEETING' ? (
                                                        <MeetingStatusLabel status={note.status} />
                                                    ) : note.lastMeetingId ? (
                                                        <Typography component="span" variant="body2" fontWeight="bold">
                                                            <MeetingStatusLabel status={note.status} /> <Typography component="span" variant="body2" fontWeight="bold">{parseAndFormatISODate(note.lastMeetingEndDate, true)}</Typography>
                                                        </Typography>
                                                    ) : (
                                                        <Typography component="span" variant="body2" sx={{ color: 'green', fontWeight: 'bold' }}>
                                                            <Localized id='meeting-minutes-new-no-meetings'>No meetings</Localized>
                                                        </Typography>
                                                    )}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                <Typography component="span" variant="body2">
                                                    <Localized id='meeting-minutes-active-topics'>Aktywne tematy</Localized>: <Typography component="span" variant="body2" fontWeight="bold">{note.itemCount}</Typography>
                                                    <span>, </span>
                                                    <Localized id='meeting-minutes-active-tasks'>Aktywne zadania</Localized>: <Typography component="span" variant="body2" fontWeight="bold">{note.taskCount}</Typography>
                                                    <span>, </span>
                                                    <Localized id='meeting-minutes-overdue'>Po terminie</Localized>: <Typography component="span" variant="body2" fontWeight="bold" color={note.overdueTaskCount > 0 ? 'error' : 'inherit'}
                                                    >{note.overdueTaskCount}{note.overdueTaskCount > 0 && '!'}</Typography>
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                />
                                <Tooltip title={<Localized id='meeting-minutes-go-to-form'>Przejdz</Localized>}>
                                    <IconButton edge="end" aria-label="details"
                                        sx={{
                                            color: 'default',
                                            '&:hover': {
                                                color: 'success.main',
                                            },
                                        }}
                                        onClick={() => handleOpenMeetingForm(note.id)}
                                    >
                                        <ForwardIcon />
                                    </IconButton>
                                </Tooltip>
                            </ListItem>
                        ))}
                    </List>
                )}
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 }}>
                <Box>
                    <Tooltip title={<Localized id='meeting-minutes-add-new-tooltip'>Dodaj nowa notatkę</Localized>}>
                        <Button
                            color="primary"
                            size='small'
                            variant="outlined"
                            onClick={handleAddNewNote}
                            startIcon={<AddIcon />}>
                            <Localized id='add'>Dodaj</Localized>
                        </Button>
                    </Tooltip>
                </Box>
                <Link href={generateHref(projectId)} variant="body2">
                    <Localized id='go-to-list'>Przejdź do listy</Localized>
                </Link>
                <Typography variant="body2">
                    <Localized id='active'>Aktywne spotkania</Localized>:  {activeMeetingsCount} / {notes.length}
                </Typography>
            </Box>
        </Paper>
        <Dialog open={addNewNoteDialogVisible === true} maxWidth='sm' sx={{ minHeight: '80%' }} fullWidth keepMounted={false}>
            <AddMeetingMinute
                objectId={projectGuid}
                objectType='PROJECT'
                canEdit={true}
                onSucces={onSuccess}
                onCancel={handleCancelDialog} />
        </Dialog >
    </>
    );
};

export default MeetingMinutesWigdet;
