import { Alert, Box, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material";
import { DataGrid, GridColDef, GridRowsProp } from "@mui/x-data-grid";
import { useState } from "react";
import { Measurement, MeasurementSolairEntry, SolairEntryLocationMapping, updateSolairLocationMapping } from "../measurementsApi";
import { LoadingButton } from "@mui/lab";
import { useAppDispatch } from "../../../app/hooks";
import { showError, showSuccess } from "../../notifications/notificationsSlice";
import { Localized, useLocalization } from "@fluent/react";

const getUniqueLocationIds = (entries: MeasurementSolairEntry[]): SolairEntryLocationMapping[] => {
  const uniqueLocations: Record<string, SolairEntryLocationMapping> = {};
  entries.forEach((entry) => {
    if (!uniqueLocations[entry.locationId]) {
      uniqueLocations[entry.locationId] = { id: entry.locationId, name: entry.locationName };
    }
  });
  return Object.values(uniqueLocations);
};

interface LocationMappingDialogProps {
  measurement: Measurement,
  onSuccessfulSave: (newMeasurement: Measurement) => void;
  onClose: () => void;
}

export const LocationMappingDialog: React.FC<LocationMappingDialogProps> = ({ measurement, onSuccessfulSave, onClose, }) => {
  const [locations, setLocations] = useState<SolairEntryLocationMapping[]>(getUniqueLocationIds(measurement.solairEntries));
  const [rows, setRows] = useState<GridRowsProp<SolairEntryLocationMapping>>(locations);
  const [saving, setSaving] = useState<boolean>(false)
  const [serverError, setServerError] = useState("")
  const { l10n } = useLocalization()
  const dispatch = useAppDispatch()


  const handleChange = (id: number, field: keyof SolairEntryLocationMapping, value: string) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === id ? { ...row, [field]: value } : row))
    );
  };

  const handleLocationSave = async (updatedLocations: SolairEntryLocationMapping[]) => {
    if (updatedLocations && updatedLocations.length > 0) {
      setSaving(true)
      try {
        const result = await dispatch(updateSolairLocationMapping({
          id: measurement.id,
          mapping: updatedLocations
        }))
        if (updateSolairLocationMapping.fulfilled.match(result)) {
          const newMeasurement = result.payload as Measurement;
          dispatch(showSuccess("saved"))
          onSuccessfulSave(newMeasurement)
        }
        else {
          let errorMsg = "error"
          if (result.payload && result.payload.kind === 'http') {
            const problem = result.payload.problem
            if (problem) {
              errorMsg = problem.title
            }
          }
          setServerError(errorMsg)
        }
      } catch (error) {
        dispatch(showError("error"))
      }
      finally {
        setSaving(false)
      }
    }
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: l10n.getString('measurement-locationId', null, '_Location ID'), width: 150 },
    {
      field: 'name',
      headerName: l10n.getString('measurement-locationName', null, '_Location ID'),
      flex: 1,
      renderCell: (params) => (

        <TextField
          value={params.row.name}
          size="small"
          margin="dense"
          fullWidth
          onChange={(e) => handleChange(params.row.id, 'name', e.target.value)}
        />

      ),
    },
  ];

  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Assign Names to Locations</DialogTitle>
      <DialogContent>
        <Box
          sx={{
            height: 500,
            width: '100%',
            '& .actions': {
              color: 'text.secondary',
            },
            '& .textPrimary': {
              color: 'text.primary',
            },
          }}
        >
          <DataGrid
            style={{ minHeight: 400, height: '100%' }}
            rows={rows}
            columns={columns}
          />
        </Box>
        {
          serverError && <Alert sx={{ marginTop: 1, }} onClose={() => { setServerError("") }} severity="error">
            <Localized id={serverError}>
              <span>Server error</span>
            </Localized>
          </Alert>
        }
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={saving} size="small" variant="contained" onClick={() => handleLocationSave([...rows])} color="primary">
          <Localized id="save">save</Localized>
        </LoadingButton>
        <LoadingButton loading={saving}  size="small" variant="outlined" onClick={onClose} color="primary">
          <Localized id="cancel">Anuluj</Localized>
        </LoadingButton>

      </DialogActions>
    </Dialog>
  );
};
