import React, { useCallback } from 'react';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box, Dialog, DialogActions, DialogContent, DialogContentText, Divider, Grid, ListItemIcon, StepContent, TextField } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import IconButton, { IconButtonProps } from '@mui/material/IconButton';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PrintIcon from '@mui/icons-material/Print';
import { activateStage, deleteProcess, Process, ProcessId, selectProcessById, skipStage, Stage, startProcess, updateStageFinalApprover, updateStagePlanApprover, updateStagePlanner } from './processSlice';
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Localized, useLocalization } from '@fluent/react';
import { selectSettings } from '../settings/settingsSlice';
import { unwrapResult } from "@reduxjs/toolkit";
import { showError, showSuccess } from "../notifications/notificationsSlice";
import { AppLoader } from '../../app/AppLoader';
import { ProcessStatusLabel } from './ProcessStatusLabel';
import { AppId } from '../../app/appTypes';
import { selectAllUsers, User } from "../users/usersSlice";
import { Alert, Autocomplete, Skeleton } from '@mui/material';
import { Person } from '@mui/icons-material';
import { DeviceInfo } from '../device/DeviceInfo';
import { LoadProcesses, LoadUsers } from '../../app/AppDataLoader';
import { UrsInfo } from './tiles/UrsInfo';
import { If } from '../../app/If';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { AppLink } from '../../app/AppLink';
import { useNavigate } from 'react-router-dom';

interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));


function GetStageStepInfo(index: number, stageEntity: Stage | undefined) {
  let isStepActive = false;
  let isStepCompleted = false;
  let stepUser = '';
  let stepDate;
  let hasError = false;
  let message = '';
  const { l10n } = useLocalization();

  if (index === 0) {
   
    stepUser = stageEntity?.plannerFullName || '';
    stepDate = stageEntity?.planningEndDate;      

    if (stepUser === '')
    {
      hasError = true;
      message = l10n.getString("process-assign-user", null, "przypisz użytkownika")      
    }

    if (stageEntity?.status === "P" || stageEntity?.status === "R") {
      isStepActive = true;
    }
    else if(stageEntity?.status !== "N") {
      isStepCompleted = true;
    }
  }

  if (index === 1) {
    stepUser = stageEntity?.approverFullName || '';
    stepDate = stageEntity?.approvalDate;   
    if (stepUser === '')
    {
      hasError = true;
      message = l10n.getString("process-assign-user", null, "przypisz użytkownika")      
    }
    if (stageEntity?.status === "A") {
      isStepActive = true;  
    }
    else if (stageEntity?.status !== "P" && stageEntity?.status !== "R" && stageEntity?.status !== "N") {
      isStepCompleted = true;
    }
    if (stageEntity?.status === "R")
    {
      hasError = true; 
      message = l10n.getString("process-planing-rejected", null, "Planowanie odrzucono")            
    }
  }

  if (index === 2) {
    stepUser = '';
    hasError = false;
    isStepActive = false;
    message = "skipped";
  }

  if (index === 3) {
    stepUser = '';
       
    if (stageEntity?.status === "T") {
      isStepActive = true;
    }
    else if (stageEntity?.status === "F" || stageEntity?.status === "V") {
      isStepCompleted = true;
    }   
  }

  if (index === 4) {    
    stepUser = stageEntity?.finalApproverFullName || '';
    stepDate = stageEntity?.verifiedDate;  
    if (stepUser === '')
    {
      hasError = true;
      message = l10n.getString("process-assign-user", null, "przypisz użytkownika")      
    }   
    if (stageEntity?.status === "V") {
      isStepActive = true;
    }
    else if (stageEntity?.status === "F") {
      isStepCompleted = true;
    }   
  }

  return { isStepActive, isStepCompleted , stepUser, stepDate, hasError, message };
}


export  const ProcessDashboard2 = () => {
  const { id: paramId } = useParams(); const id = paramId ?? ''

  return (
    <LoadProcesses component={<Skeleton variant="rectangular" height={400} />}>
      <DashboardIfProcessExists processId={id} />
    </LoadProcesses>
  );
}



const DashboardIfProcessExists = ({ processId }: { processId: AppId }) => {
  const entity = useAppSelector(state => selectProcessById(state, processId));

  if (entity) {
    return <Dashboard entity={entity} />
  } else {
    return <Alert severity="warning">
      <Localized id="error-entity-not-exists">
        <span>Obiekt nie istnieje</span>
      </Localized>
    </Alert>
  }
}

export const Dashboard = ({ entity }: { entity: Process }) => {
  const { id, deviceId, ursId } = entity
  const navigate = useNavigate()
  const allUsers = useAppSelector(selectAllUsers)
  const { l10n } = useLocalization();

  const iqStageEntity = entity.stages.find(x=>x.stage === 'IQ') as Stage
  const pqStageEntity = entity.stages.find(x=>x.stage === 'PQ') as Stage
  const oqStageEntity = entity.stages.find(x=>x.stage === 'OQ') as Stage 
  const dqStageEntity = entity.stages.find(x=>x.stage === 'DQ') as Stage 
  
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [currentStageId, setCurrentStageId] =  React.useState<AppId | undefined>(undefined)
  const dispatch = useAppDispatch()

  const [newUser, setNewUser] = React.useState<User | undefined>(undefined)

  const steps = getSteps();

  const [clientInfoExpanded, setClientInfoExpanded] = React.useState(false);
  const { locale } = useAppSelector(selectSettings);
  const [processing, setProcessing] = React.useState(false)
  const [deleted, setDeleted] = React.useState(false)
  const [showActivateStageConfDialog, setShowActivateStageConfDialog] = React.useState(false)
  const [showSkipStageConfDialog, setShowSkipStageConfDialog] = React.useState(false)

  
  const handleExpandInfoClientClick = () => {
    setClientInfoExpanded(!clientInfoExpanded);
  };
  const handleGoBackclick = useCallback(() => navigate(-1), [navigate])


  const handleUserChange = useCallback((_: any, newUser: User | null) => {
        if (newUser) {
          setNewUser(newUser)
        }
    }, []);

  const handleOpenMenuClick = (id: AppId | undefined, event: React.MouseEvent<HTMLButtonElement>) => {    
    
    setCurrentStageId(id);

    setAnchorEl(event.currentTarget);    
    setShowDeleteConfMessage(false);
  };

  const SkippedStage = ({ title, subheader, stageId  }: {  title: string,  subheader: string, stageId: AppId }) => {
    
    return <>
      <CardHeader
          title={title}
          subheader={subheader}         
        />
        <Grid container alignContent={'center'} xs={12}>
          <Grid container item xs={12} justifyContent={'center'}> 
            <Typography variant="h6" align='center'>
              <Localized id="stage-skipped">
                <span>Etap pominięty</span>
              </Localized>
            </Typography> 
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}> 
            <RemoveCircleOutlineIcon sx={{marginTop:"10px"}} fontSize='large' color='warning'/>
          </Grid>
          <Grid container item xs={12} justifyContent={'center'}> 
            <Button
                onClick={(event) => handleConfirmActivateStageClick(stageId, event)}
                sx={{marginTop:"40px"}} color="secondary" variant="outlined">
              <Localized id="stage-activate">
                <span>Aktywuj</span>
              </Localized>
            </Button>
          </Grid>
        </Grid>
    </>
  }


  const handleCloseMenu = () => {
    setAnchorEl(null);    
  };

  const [showDeleteConfMessage, setShowDeleteConfMessage] = React.useState(false);  
  const [showChangePlannerDialog, setShowChangePlannerDialog] = React.useState(false);
  const [showChangePlanApproverDialog, setShowChangePlanApproverDialog] = React.useState(false);
  const [showChangeFinalApproverDialog, setShowChangeFinalApproverDialog] = React.useState(false);

  const handleProcessDeleteClick = useCallback((id: ProcessId) => {
    setShowDeleteConfMessage(true)
  }, [setShowDeleteConfMessage])

  const handlePrintProtocolClick = async () => {  
    handleCloseMenu()  
    if (currentStageId){
      let stage = entity.stages.find(x=>x.id === currentStageId);
      if (stage){
        window.open(`${process.env.REACT_APP_REST_API}api/reports/protocol/${id}/${stage.stage}?language=${locale}`, '_blank')    
      }
    }    
  }

  const handlePrintReportClick  = async () =>  {
    handleCloseMenu()
    if (currentStageId){
      let stage = entity.stages.find(x=>x.id === currentStageId);
      if (stage){
        window.open(`${process.env.REACT_APP_REST_API}api/reports/report/${id}/${stage.stage}?language=${locale}`, '_blank')    
      }
    }
  }

  const handleChangePlannerClick = useCallback(() => {
    handleCloseMenu()
    setShowChangePlannerDialog(true)
  }, [setShowChangePlannerDialog])  
  
  const handleChangePlanApproverClick = useCallback(() => {
    handleCloseMenu()
    setShowChangePlanApproverDialog(true)
  }, [setShowChangePlanApproverDialog])

  const handleChangeFinalStageApproverClick = useCallback(() => {
    handleCloseMenu()
    setShowChangeFinalApproverDialog(true)
  }, [setShowChangeFinalApproverDialog])
  

  const handleConfirmChangeUserClick = async () => {
    if(currentStageId !== '' && newUser?.userName !== null){
      try {
        if (showChangePlannerDialog){
          unwrapResult(await dispatch(updateStagePlanner({
            stageId: String(currentStageId),           
            userName: String(newUser?.userName)
          })));
        } 
        else if (showChangePlanApproverDialog) {
          unwrapResult(await dispatch(updateStagePlanApprover({
            stageId: String(currentStageId),           
            userName: String(newUser?.userName)
          })));
        }
        else if (showChangeFinalApproverDialog){
          unwrapResult(await dispatch(updateStageFinalApprover({
            stageId: String(currentStageId),           
            userName: String(newUser?.userName)
          })));
        }
       
        dispatch(showSuccess("saved"))
      } 
      catch (error) {
        dispatch(showError("error"))
      }

      setShowChangePlannerDialog(false)
      setShowChangePlanApproverDialog(false)      
      setShowChangeFinalApproverDialog(false)
    }
  }
  
  const handleSkipStageClick = useCallback(() => {
    handleCloseMenu()
    setShowSkipStageConfDialog(true)
  }, [setShowSkipStageConfDialog])

  const handleConfirmStageSkipClick = async () => {    
    setShowSkipStageConfDialog(false);
    try {
        unwrapResult(await dispatch(skipStage(String(currentStageId))))
        dispatch(showSuccess("saved"))
    }catch (error) {
        dispatch(showError("error"))
    }                   
  }  

  const handleCancelStageSkipClick = useCallback(() => {
    setShowSkipStageConfDialog(false)
  }, [])


  const handleConfirmActivateStageClick = (stageId: AppId | undefined, event: React.MouseEvent<HTMLButtonElement>) => {        
    setCurrentStageId(stageId);
    setShowActivateStageConfDialog(true);
  }

  const handleConfirmStageActivationClick = async () => {    
      setShowActivateStageConfDialog(false);
      try {
          unwrapResult(await dispatch(activateStage(String(currentStageId))))
          dispatch(showSuccess("saved"))
      }catch (error) {
          dispatch(showError("error"))
      }                   
  }  

  const handleCancelStageActivationClick = useCallback(() => {
    setShowActivateStageConfDialog(false)
  }, [setShowActivateStageConfDialog])


  

  const handleCancelChangeUserClick = useCallback(() => {
    setShowChangePlannerDialog(false)
    setShowChangePlanApproverDialog(false)
    setShowChangeFinalApproverDialog(false)
    setNewUser(undefined)
  }, [setShowChangePlannerDialog, setShowChangePlanApproverDialog, setShowChangeFinalApproverDialog])

  const handleConfirmDelClick = async () => {
    if (showDeleteConfMessage) {
        setProcessing(true)
        try {
            unwrapResult(await dispatch(deleteProcess(id)))
            dispatch(showSuccess("deleted"))
        } catch (error) {
            dispatch(showError("error"))
        }


        setProcessing(false)
        setDeleted(true)
        setShowDeleteConfMessage(false)           
        handleGoBackclick()
             
    }
  }

function getSteps() {
  return [
    l10n.getString("stage-status-p", null, "Planowanie"),
    l10n.getString("stage-status-a", null, "Zatwierdzanie"),         
    l10n.getString("stage-status-d", null, "Dokumentacja"),        
    l10n.getString("stage-status-t", null, "Testowanie"),        
    l10n.getString("stage-status-v", null, "Weryfikacja")
  ];
}

function CanStartStage(stage: Stage) {
    if (stage.status !== 'S' && 
        (stage?.approverUserName === '' || stage?.plannerUserName === '' ||  stage?.finalApproverUserName === '')
      )
    {
      console.log('F ' +stage)
      return false;
    }
   
    console.log('O ' +stage)
    return true;
}



function CanStartProcess() {
  if( !CanStartStage(iqStageEntity) || 
      !CanStartStage(oqStageEntity) || 
      !CanStartStage(pqStageEntity) || 
      !CanStartStage(dqStageEntity)   
     )
     {
       dispatch(showError("process-start-error-no-users"))

       return false;
     }
  return true;
}

const handleProcessStartClick = async () => {  
  if(CanStartProcess()){  
    if (id) {      
        try {
            unwrapResult(await dispatch(startProcess(id)))
            dispatch(showSuccess("saved"))
        } catch (error) {
            dispatch(showError("error"))
        }                     
    }
  }
}

const handleCancelDelClick = useCallback(() => {
  setShowDeleteConfMessage(false)
}, [setShowDeleteConfMessage])

  return (
    <Box sx={{
      width: "100%",
      flexGrow: 1,
      marginBottom: 1,
    }}>

{/** header */}
      <Grid container spacing={1}>       

      {!ursId && deviceId &&
       <Grid item xs={7}>
         <DeviceInfo id={deviceId} />
        </Grid>
      }
      {ursId && !deviceId && 
      <>       
        <Grid item xs={7}>
          <UrsInfo id={ursId}/>
        </Grid>
      </>
      } 
      {ursId && deviceId && 
      <>       
        <Grid item xs={4}>
          <DeviceInfo id={deviceId} />
        </Grid>
        <Grid item xs={3}>
          <UrsInfo id={ursId}/>
        </Grid>
      </>
      }   
       
        <Grid item xs={3}>
          <Card sx={{
            width: "100%",
            flexGrow: 1,
            marginBottom: 1,
          }}>
            <CardHeader
              action={
                <ExpandMore expand={clientInfoExpanded} onClick={handleExpandInfoClientClick}>
                  <ExpandMoreIcon />
                </ExpandMore>
              }

              title={entity.companyName}
              subheader={<Localized id="process-client">Klient</Localized>} 
            />

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                <Localized id="process-description">Opis</Localized>
              </Typography>
            </CardContent>
            <Collapse in={clientInfoExpanded} timeout="auto" unmountOnExit>
              <CardContent>                
                <Typography >
                  {entity.purpose}
                </Typography>
              </CardContent>
            </Collapse>
          </Card>
        </Grid>

        <Grid item xs={2}>
          <Card sx={{
            width: "100%",
            flexGrow: 1,
            marginBottom: 1,
          }}>
            <CardHeader
              title={<ProcessStatusLabel status={entity.status} />} 
              subheader= {entity.processNo}
            />

            <CardContent>
              <Typography variant="body2" color="textSecondary" component="p">
                {entity.createDate}
                <If condition={entity.finishDate !== undefined} >               
                  &nbsp;&nbsp;-&nbsp;&nbsp;{entity.finishDate}               
                </If>
              </Typography>
              
            </CardContent>
          </Card>
        </Grid>
      </Grid>

    <Grid container spacing={1}>
                
{/** etat DQ */}
        <Grid item xs={3}>          
            <Card sx={{
              width:"100%",
              flexGrow: 1,
              minHeight:570,
              marginBottom: 1,
            }}>
            <If condition={dqStageEntity.status !== "S"}
                  otherwise={<SkippedStage title="DQ"  subheader="Design Qualification" stageId={dqStageEntity?.id} />}>
              <CardHeader
                title="DQ"
                subheader="Design Qualification"
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={(event) =>handleOpenMenuClick(dqStageEntity?.id, event)}
                    size="large">
                    <MoreVertIcon />
                  </IconButton>
                }
              />
                                                                                                              
              <Stepper sx={{
                minHeight: '320px',
                marginLeft: '20px',
              }} orientation="vertical">
              {steps.map((label, index) => {               
               const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

                let {isStepActive, isStepCompleted , stepUser, stepDate, hasError, message } 
                  = GetStageStepInfo(index, dqStageEntity);
                labelProps.error = hasError;
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                );
                return (
                <Step key={label} expanded={true}    active={isStepActive} completed={isStepCompleted}>
                  <StepLabel  {...labelProps} >{label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{stepUser}</Typography>
                    <Typography variant="body2">{stepDate}</Typography>
                  </StepContent>
                </Step>
                );
                })}
              </Stepper>
              <CardActions disableSpacing>               
                <IconButton
                  component={AppLink}
                  to={{ pathname: `/process/stage/DQ/${id}`}}
                  structureId={undefined}
                  aria-label="edit"
                  size="large">
                  <ExitToAppIcon />
                  <Typography variant="body1" color="textSecondary" component="p"> 
                    <Localized id="proces-stage-to-go">
                      <span>Podgląd</span>
                    </Localized> 
                  </Typography>
                </IconButton>
              </CardActions>
              </If>
            </Card>
          </Grid>


{/** etat IQ */}
        <Grid item xs={3}>
          <Card sx={{
            width:"100%",
            flexGrow: 1,
            minHeight:570,
            marginBottom: 1,
          }}>
            <If condition={iqStageEntity.status !== "S"}
                  otherwise={<SkippedStage title="IQ"  subheader="Installation Qualification" stageId={iqStageEntity?.id}/>}>
            <CardHeader
              title="IQ"
              subheader="Installation Qualification"
              action={
                <IconButton
                  aria-label="settings"
                  onClick={(event) =>handleOpenMenuClick(iqStageEntity?.id, event)}
                  size="large">
                  <MoreVertIcon />
                </IconButton>
              }
            />            
            <Stepper sx={{
              minHeight: '320px',
              marginLeft: '20px',
            }} orientation="vertical">
              {steps.map((label, index) => {
               const stepProps: { completed?: boolean } = {};
               const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

                let {isStepActive, isStepCompleted , stepUser, stepDate, hasError, message } = GetStageStepInfo(index, iqStageEntity);
                labelProps.error = hasError;
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                );
                return (
                <Step key={label}  {...stepProps} expanded={true} active={isStepActive} completed={isStepCompleted}>
                  <StepLabel  {...labelProps} >{label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{stepUser}</Typography>
                    <Typography variant="body2">{stepDate}</Typography>
                  </StepContent>
                </Step>
               );
              })}
            </Stepper>

            <CardActions disableSpacing>                                      
              <IconButton
                component={AppLink}
                to={{
                  pathname: `/process/stage/IQ/${id}`,
                }}
                structureId={undefined}
                aria-label="edit"
                size="large">
                <ExitToAppIcon />
                <Typography variant="body1" color="textSecondary" component="p"> 
                  <Localized id="proces-stage-to-go">
                    <span>Podgląd</span>
                  </Localized> 
                </Typography>
              </IconButton>
            </CardActions>
            </If>
          </Card>
        </Grid>


{/** etat OQ */}
        <Grid item xs={3}>
          <Card sx={{
            width:"100%",
            flexGrow: 1,
            minHeight:570,
            marginBottom: 1,
          }}>
            <If condition={oqStageEntity.status !== "S"}
                  otherwise={<SkippedStage title="OQ"  subheader="Operational Qualification" stageId={oqStageEntity?.id}/>}>
            <CardHeader
              title="OQ"
              subheader="Operational Qualification"
              action={
                <IconButton
                  aria-label="settings"
                  onClick={(event) =>handleOpenMenuClick(oqStageEntity?.id, event)}
                  size="large">
                  <MoreVertIcon />
                </IconButton>
              }
            />
          
            <Stepper sx={{
              minHeight: '320px',
              marginLeft: '20px',
            }} orientation="vertical">
              {steps.map((label, index) => {               
               const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

                let {isStepActive, isStepCompleted , stepUser, stepDate, hasError, message } 
                  = GetStageStepInfo(index, oqStageEntity);
                labelProps.error = hasError;
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                );
                return (
                <Step key={label} expanded={true}  active={isStepActive} completed={isStepCompleted}>
                  <StepLabel  {...labelProps} >{label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{stepUser}</Typography>
                    <Typography variant="body2">{stepDate}</Typography>
                  </StepContent>
                </Step>
               );
              })}
            </Stepper>
            <CardActions disableSpacing>
              <IconButton
                component={AppLink}
                to={{
                  pathname: `/process/stage/OQ/${id}`,
                }}
                structureId={undefined}
                aria-label="edit"
                size="large">
                <ExitToAppIcon />
                <Typography variant="body1" color="textSecondary" component="p"> 
                  <Localized id="proces-stage-to-go">
                    <span>Podgląd</span>
                  </Localized> 
                </Typography>
              </IconButton>
            </CardActions>
            </If>
          </Card>
        </Grid>

{/** etat PQ */}

        <Grid item xs={3}>
          <Card sx={{
            width:"100%",
            flexGrow: 1,
            minHeight:570,
            marginBottom: 1,
          }}>
            <If condition={pqStageEntity.status !== "S"}
                  otherwise={<SkippedStage title="PQ"  subheader="Performance Qualification" stageId={pqStageEntity?.id}/>}>
            <CardHeader
              title="PQ"
              subheader="Performance Qualification"
              action={
                <IconButton
                  aria-label="settings"
                  onClick={(event) =>handleOpenMenuClick(pqStageEntity?.id, event)}
                  size="large">
                  <MoreVertIcon />
                </IconButton>
              }
            />
            
            <Stepper sx={{
              minHeight: '320px',
              marginLeft: '20px',
            }} orientation="vertical">
              {steps.map((label, index) => {               
               const labelProps: { optional?: React.ReactNode; error?: boolean } = {};

                let {isStepActive, isStepCompleted , stepUser, stepDate, hasError, message } 
                  = GetStageStepInfo(index, pqStageEntity);
                labelProps.error = hasError;
                labelProps.optional = (
                  <Typography variant="caption" color="error">
                    {message}
                  </Typography>
                );
                return (
                <Step key={label} expanded={true}    active={isStepActive} completed={isStepCompleted}>
                  <StepLabel  {...labelProps} >{label}</StepLabel>
                  <StepContent>
                    <Typography variant="body2">{stepUser}</Typography>
                    <Typography variant="body2">{stepDate}</Typography>
                  </StepContent>
                </Step>
               );
              })}
            </Stepper>
            <CardActions disableSpacing>
              <IconButton
                component={AppLink}
                to={{
                  pathname: `/process/stage/PQ/${id}`,
                }}
                structureId={undefined}
                aria-label="edit"
                size="large">
                <ExitToAppIcon />
                <Typography variant="body1" color="textSecondary" component="p">
                  <Localized id="proces-stage-to-go">
                    <span>Podgląd</span>
                  </Localized>                  
                </Typography>
              </IconButton>
            </CardActions>
            </If>
          </Card>
          </Grid>


      </Grid>
      <Divider />
      <div>  
            <Button sx={{
              marginRight: 1,
              marginTop: 2,
            }} onClick={handleGoBackclick}  color="secondary" variant="outlined">
                  <Localized id="back">Wróć</Localized>
            </Button>           
            <Button sx={{
              marginRight: 1,
              marginTop: 2,
            }} color="secondary" variant="contained"
                onClick={() => handleProcessStartClick()} 
                style={{ display: entity.status !== "D" ? "none" : "inline" }}>
              <Localized id="process-start">
                <span>Rozpocznij proces</span>
              </Localized>
            </Button>            
            <Button sx={{
              marginRight: 1,
              marginTop: 2,

            }} variant="outlined"
                onClick={() =>handleProcessDeleteClick(id)}
                style={{ display: entity.status !== "D" ? "none" : "inline" }}>                 
              <Localized id="process-deletes">
                <span>Usuń proces</span>
              </Localized>
            </Button>
            <Dialog open={showDeleteConfMessage}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="confirm-delete">
                                <span>Czy napewno chcesz usunąć?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmDelClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelDelClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
              </Dialog>

              <Dialog open={showActivateStageConfDialog}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="stage-activate-confirm-question">
                                <span>Czy napewno chcesz aktywować etap?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmStageActivationClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelStageActivationClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
              </Dialog>

              <Dialog open={showSkipStageConfDialog}>
                    <DialogContent>
                        <DialogContentText>
                            <Localized id="stage-skip-confirm-question">
                                <span>Czy napewno chcesz pominąć etap?</span>
                            </Localized>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmStageSkipClick}>
                            <Localized id="yes"><span>Tak</span></Localized>
                        </Button>
                        <Button onClick={handleCancelStageSkipClick}>
                            <Localized id="no"><span>Nie</span></Localized>
                        </Button>
                    </DialogActions>
              </Dialog>

              
            <Menu
              id="simple-menu1"
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={handleCloseMenu}
            >
              <MenuItem onClick={handleChangePlannerClick}>
                <ListItemIcon>
                  <Person fontSize="small" />                  
                </ListItemIcon>
                <Localized id="stage-change-planner">Planer</Localized>
              </MenuItem>
              <MenuItem onClick={handleChangePlanApproverClick}>
                <ListItemIcon>
                  <Person fontSize="small" />                  
                </ListItemIcon>
                <Localized id="stage-change-plan-approver">Approver</Localized>
              </MenuItem>
              <MenuItem onClick={handleChangeFinalStageApproverClick}>
                <ListItemIcon>
                  <Person fontSize="small" />                  
                </ListItemIcon>
                <Localized id="stage-change-final-approver">Stage approver</Localized>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handleSkipStageClick}>
                <ListItemIcon>
                  <RemoveCircleOutlineIcon fontSize="small" />                  
                </ListItemIcon>
                <Localized id="stage-skip">Skip stage</Localized>
              </MenuItem>
              <Divider />
              <MenuItem onClick={handlePrintProtocolClick}>
                <ListItemIcon>
                  <PrintIcon fontSize="small" />
                </ListItemIcon>
                <Localized id="process-print-protocol">Drukuj protokół</Localized>
              </MenuItem>
              <MenuItem onClick={handlePrintReportClick}>
                <ListItemIcon>
                  <PrintIcon fontSize="small" />
                </ListItemIcon>
                <Localized id="process-print-report">Drukuj raport</Localized>
              </MenuItem>
            </Menu>


            <Dialog open={showChangePlanApproverDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="stage-choose-plan-approver">
                            <span>Wybierz użytkownika</span>
                        </Localized>
                    </DialogContentText>
                    <LoadUsers component={<Skeleton variant="rectangular" />}>
                      <Autocomplete                         
                          options={allUsers}
                          sx={{minWidth: "400px", marginTop: "10px"}}
                          value={newUser}
                          onChange={handleUserChange}
                          getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                          renderInput={params =>
                            <TextField {...params}                               
                            />
                        }
                      />                        
                    </LoadUsers>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmChangeUserClick}>
                        <Localized id="ok"><span>Ok</span></Localized>
                    </Button>
                    <Button onClick={handleCancelChangeUserClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={showChangePlannerDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="stage-choose-planner">
                            <span>Wybierz użytkownika</span>
                        </Localized>
                    </DialogContentText>
                    <LoadUsers component={<Skeleton variant="rectangular" />}>
                      <Autocomplete 
                          
                          options={allUsers}
                          value={newUser}
                          sx={{minWidth: "400px", marginTop: "10px"}}
                          onChange={handleUserChange}
                          getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                          renderInput={params =>
                            <TextField {...params}                               
                            />
                        }
                      />                        
                    </LoadUsers>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmChangeUserClick}>
                        <Localized id="ok"><span>Ok</span></Localized>
                    </Button>
                    <Button onClick={handleCancelChangeUserClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
              </Dialog>
          
              <Dialog open={showChangeFinalApproverDialog}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="stage-choose-final-approver">
                            <span>Wybierz użytkownika</span>
                        </Localized>
                    </DialogContentText>
                    <LoadUsers component={<Skeleton variant="rectangular" />}>
                      <Autocomplete                         
                          options={allUsers}
                          value={newUser}
                          sx={{minWidth: "400px", marginTop: "10px"}}
                          onChange={handleUserChange}
                          getOptionLabel={({ firstName, lastName }) => `${firstName} ${lastName}`}
                          renderInput={params =>
                            <TextField {...params}                               
                            />
                        }
                      />                        
                    </LoadUsers>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmChangeUserClick}>
                        <Localized id="ok"><span>Ok</span></Localized>
                    </Button>
                    <Button onClick={handleCancelChangeUserClick}>
                        <Localized id="cancel"><span>Anuluj</span></Localized>
                    </Button>
                </DialogActions>
              </Dialog>
            <AppLoader open={processing} />           
      </div>
    </Box>
  );
}
