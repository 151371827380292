import { Localized } from "@fluent/react"
import { Avatar, Box, Button, Divider, IconButton, Link, Menu, MenuItem, TextField, Typography } from "@mui/material"
import { Stack } from "@mui/system"
import ReplyIcon from '@mui/icons-material/Reply'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import { useCallback, useEffect, useRef, useState } from "react"
import PersonIcon from '@mui/icons-material/Person'
import AttachmentIcon from '@mui/icons-material/Attachment'
import { AppId, AppTextFieldHandler } from "./appTypes"
import { If } from "./If"
import { formatDate } from "./Utils"
import { green, grey } from "@mui/material/colors"
import { BlobMeta, loadBlobs, Uuid, deleteBlob } from "../features/urs/ursAttachementsSlice"
import { useAppDispatch } from "./hooks"

export type CommentKind = 'text' | 'file'

export interface DiscussProps {
    postComment : ((message: string, parentCommentId: AppId | null, kind: CommentKind, files : File[] | null) => Promise<Uuid | undefined>) | null
    deleteComment : ((commentId: AppId) => Promise<void>) | null
    updateComment : ((commentId: AppId, message: string) => Promise<void>) | null
    allComments : Comment[]
    currentUserId: string | null
    showTitle?: boolean
    multiThread?: boolean
    readOnly: boolean
}

export const Discuss = (props: DiscussProps) => {
    const { postComment, deleteComment, updateComment, allComments, currentUserId, showTitle } = props
    const readOnly = props.readOnly
    const [message, setMessage] = useState("")

    const handleMessageChange: AppTextFieldHandler = (e) => setMessage(e.target.value)

    const handlePublishMessage = () => {
        if (postComment !== null) {
            postComment(message, null, 'text', null)!.then(_ => {
                setMessage("")
            })
        }
    }

    const threads = allComments.filter(c => c.parentId === null)
    const canPublishThread = (props.multiThread === true || threads.length === 0) && !readOnly

    return <Stack spacing={2}>
        {showTitle && <Typography component="div" variant="h6">
            <Localized id="comments-count" vars={{ count: allComments.length }}></Localized>
        </Typography>}
        {threads.filter(c => c.parentId === null).map(comment => 
            <TextComment 
                key={comment.someId}
                comment={comment} 
                allComments={allComments} 
                postComment={postComment} 
                deleteComment={deleteComment}
                updateComment={updateComment}
                currentUserId={currentUserId}
                readOnly={readOnly}
            />)}
        <If condition={canPublishThread}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
            }}>
                <Avatar><PersonIcon /></Avatar>
                <TextField
                    fullWidth
                    autoFocus={true}
                    value={message}
                    onChange={handleMessageChange}
                    placeholder="Komentarz..."
                />
                <Button variant='contained'size='large' onClick={handlePublishMessage}>
                    <Localized id="post">Opublikuj</Localized>
                </Button>
            </Box>
        </If>
    </Stack>
}

export interface Comment {
    someId   : AppId
    author   : string
    authorId : string
    companyName : string
    content  : string
    parentId : AppId | null
    date     : Date
    guid     : string
    kind     : CommentKind
}

export interface CommentProps extends Omit<DiscussProps, 'showTitle' | 'multiThread'> {
    comment: Comment
}

const Comment = (props: CommentProps) => {
    const { kind } = props.comment
    if (kind === 'file') {
        return <FileContent {...props} />
    } else {
        return <TextComment {...props} />
    }
}

const TextComment = (props : CommentProps) => {
    const { comment, postComment, deleteComment, updateComment, allComments, currentUserId } = props
    const readOnly = props.readOnly
    const { author, content, someId, authorId, date, companyName } = comment
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [edit, setEdit] = useState(false)
    const [editableContent, setEditableContent] = useState(content)
    const [reply, setReply] = useState(false)
    const [replyMessage, setReplyMessage] = useState("")
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const formRef = useRef<HTMLFormElement | null>(null)

    const handleSelectFilesClick = useCallback(() => {
        if (fileInputRef) {
            fileInputRef.current?.click()
        }
    }, [fileInputRef])
    const handleInputFileChange = useCallback(async () => {
        if (postComment !== null) {
            if (fileInputRef.current) {
                const dt = fileInputRef.current.files
                if (dt && dt.length > 0) {
                    const files: File[] = []
                    for (const file of dt) {
                        files.push(file)
                    }
                    await postComment('some file', comment.someId, 'file', files)
                }
            } 
        }
    }, [fileInputRef])

    const handleReplyMessageChange: AppTextFieldHandler = e => setReplyMessage(e.target.value)
    const handleContentChange: AppTextFieldHandler = e => setEditableContent(e.target.value)
    const handleEditClick = useCallback(() => {
        setAnchorEl(null)
        setEdit(true)
    }, [])
    const handleEditCancelClick = useCallback(() => setEdit(false), [])
    const handleUpdateClick = async () => {
        if (updateComment !== null) {
            await updateComment(someId, editableContent)
            setEdit(false)
        }
    }

    const handleReplyClick = () => {
        if (postComment !== null) {
            postComment(replyMessage, comment.someId, 'text', null)!.then(_ => {
                setReply(false)
                setReplyMessage("")
            })
        }
    }
    const handleReplyCancel = useCallback(() => setReply(false), [])
    const handleDeleteClick = useCallback(() => {
        if (deleteComment !== null) {
            setAnchorEl(null)
            deleteComment(comment.someId)
        }
    }, [comment.someId])

    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const subComments = allComments.filter(c => c.parentId === comment.someId)

    const canDelete = subComments.length === 0 && currentUserId === authorId
    const canEdit = currentUserId === authorId

    const createdAt = formatDate(date)

    return <Box sx={{
        display: 'flex',
        flexDirection: 'row',
        gap: 1,
    }}>
        <Avatar sx={{
            bgcolor: currentUserId === authorId ? green[300] : grey,
        }}><PersonIcon /></Avatar>
        <Stack>
            <Typography variant='body1' fontWeight={500}>{author}</Typography>
            <Typography variant='body2' fontSize='0.8em' color='text.secondary'>{companyName}</Typography>
            <Typography variant='body2' fontSize='0.8em' color='text.secondary'>{createdAt}</Typography>
            {!edit && <Typography variant='body1'>{content}</Typography>}
            {edit && <Stack direction='row' spacing={1}>
                <TextField sx={{ minWidth: '400px' }} onChange={handleContentChange} value={editableContent} size="small" />
                <Button variant="contained" size='small' onClick={handleEditCancelClick}><Localized id='cancel'>Anuluj</Localized></Button>
                <Button variant="contained" size='small' onClick={handleUpdateClick}><Localized id='save'>Zapisz</Localized></Button>
            </Stack>}
            <Stack direction='row' spacing={2}>
                {!reply && postComment !== null && !readOnly && <Button sx={{ maxWidth: '120px' }} size='small' startIcon={<ReplyIcon />} onClick={() => setReply(true)}>
                    <Localized id="reply">Odpowiedz</Localized>
                </Button>}
                <form ref={formRef}>
                    <input 
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        type="file"
                        multiple
                        value=''
                        onChange={handleInputFileChange}
                        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx,.ppt,.pptx"
                    />
                </form>
                {!reply && postComment !== null && !readOnly && <Button sx={{ maxWidth: '120px' }} size='small' startIcon={<UploadFileIcon />} onClick={handleSelectFilesClick}>
                        <Localized id="file">Plik</Localized>
                    </Button>}
            </Stack>
            {reply && <Box sx={{
                marginTop: 1,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                gap: 1,
            }}>
                <Avatar sx={{ width: '32px', height: '32px', fontSize: '0.9em' }}><PersonIcon /></Avatar>
                <TextField size='small' value={replyMessage} onChange={handleReplyMessageChange} />
                <Button variant='contained' size='small' onClick={handleReplyCancel}><Localized id='cancel'>Anuluj</Localized></Button>
                <Button variant='contained' size='small' onClick={handleReplyClick}><Localized id='post'>Opublikuj</Localized></Button>
            </Box>}
            <Stack sx={{ marginTop: 2 }} direction='row' spacing={2}>
                <Divider orientation='vertical' />
                <Stack spacing={2}>{subComments.map(x => <Comment 
                                                key={x.someId} 
                                                comment={x} 
                                                allComments={allComments} 
                                                postComment={postComment} 
                                                deleteComment={deleteComment} 
                                                updateComment={updateComment} 
                                                readOnly={readOnly}
                                                currentUserId={currentUserId} />)}
                </Stack>
            </Stack>
        </Stack>
        <If condition={readOnly === false && subComments.length === 0 && canEdit}>
            {!edit && <IconButton sx={{ height: '40px' }} onClick={handleClick}>
                <MoreVertIcon />
            </IconButton>}
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {<MenuItem  onClick={handleEditClick}><Localized id="edit">Edytuj</Localized></MenuItem>}
                {<MenuItem  onClick={handleDeleteClick}><Localized id="delete">Usuń</Localized></MenuItem>}
            </Menu>
        </If>
    </Box>
}

const FileContent = (props: CommentProps) => {
    const dispatch = useAppDispatch()
    const readOnly = props.readOnly
    const { comment, deleteComment, updateComment } = props
    const { author, date } = comment
    const [blobs, setBlobs] = useState<BlobMeta[] | null>(null)
    useEffect(() => {
        (async () => {
            try {
                setBlobs(await dispatch(loadBlobs(comment.guid)).unwrap())
            } catch (error) {
                
            }
        })()
    }, [])
    const createdAt = formatDate(date)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }
    const handleDeleteClick = () => {
        if (deleteComment !== null) {
            setAnchorEl(null)
            deleteComment(comment.someId)
            for (const blob of blobs!) {
                dispatch(deleteBlob([comment.guid, blob.blobId]))
            }
        }
    }

    if (blobs === null) {
        return <>loading...</>
    } else {
        return <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 1,
        }}>
            <Avatar><AttachmentIcon /></Avatar>
            <Stack>
                <Typography variant='body1' fontWeight={500}>{author}</Typography>
                <Typography variant='body2' fontSize='0.8em' color='text.secondary'>{createdAt}</Typography>
                <Stack direction='column' spacing={1}>
                    {blobs.map(({ fileMeta, blobId }) => {
                        return <div>
                            <Link href={`${process.env.REACT_APP_BLOB_API}/blobs/${comment.guid}/${blobId}`} key={blobId} target='_blank' rel="noreferrer">{fileMeta.fileName}</Link>
                            <Typography variant='body2' color='text.secondary'>{fileMeta.contentType}</Typography>
                        </div>
                    })}
                </Stack>
            </Stack>
            <If condition={readOnly === false}>
                {<IconButton sx={{ height: '40px' }} onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>}
                <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                    {deleteComment !== null && <MenuItem disabled={false} onClick={handleDeleteClick}><Localized id="delete">Usuń</Localized></MenuItem>}
                </Menu>
            </If>
        </Box>
    }
}
