import { Button } from "@mui/material"
import { createAsyncThunk } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppThunkAPIType } from "./appTypes"
import { useAppDispatch } from "./hooks"
import { RichTextEditor } from "./RichTextEditor"

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."

interface Diff {
    prev: string
    curr: string
}

const getDiff = createAsyncThunk<Diff, Diff, AppThunkAPIType>("diff/getDiff", async ({ prev, curr }, thunkAPI) => {
    const { dispatch, rejectWithValue } = thunkAPI
    const response = await fetch('http://localhost:8080/diff', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ prev, curr })
    })

    return await response.json()
})

export const DiffTest = () => {
    const dispatch = useAppDispatch()
    const [left, setLeft] = useState(lorem)
    const [right, setRight] = useState(lorem)
    const [leftDiff, setLeftDiff] = useState<string>(lorem)
    const [rightDiff, setRightDiff] = useState<string>(lorem)

    const handleGetDiffClick = async () => {
        const { prev, curr } = await dispatch(getDiff({ prev: left, curr: right })).unwrap()
        setLeftDiff(prev)
        setRightDiff(curr)
    }

    return <div className="container mx-auto">
        <div className="grid grid-cols-2 gap-4">
            <div>
                <RichTextEditor label={null} value={left} onValueChange={setLeft} />
            </div>
            <div>
                <RichTextEditor label={null} value={right} onValueChange={setRight} />
            </div>
            <div dangerouslySetInnerHTML={{ __html: leftDiff }}></div>
            <div dangerouslySetInnerHTML={{ __html: rightDiff }}></div>
            <div>
                <Button variant='outlined' onClick={handleGetDiffClick}>GET DIFF</Button>
            </div>
        </div>
    </div>
}
