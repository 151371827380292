import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectModule } from "../../modules/moduleSlice"
import { deleteSysRequirementTest, selectTestIdByRequirementId } from "../requirementTestSlice"

export interface DeleteRequirementTestProps {
    requirementId: AppId
    onSuccess: () => void
    onCancel: () => void
    onError: () => void
}

export const DeleteRequirementTest = ({ requirementId, onSuccess, onCancel, onError }: DeleteRequirementTestProps) => {
    const dispatch = useAppDispatch()
    const mapping = useAppSelector(state => selectTestIdByRequirementId(state, requirementId))
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const [deleting, setDeleting] = useState(false)

    const handleConfirmDelClick = async () => {
        if (currentModule && currentModule.code === "super" && mapping) {  
            setDeleting(true)             
            try {
                await dispatch(deleteSysRequirementTest(mapping)).unwrap()
                onSuccess()
            } catch (error) {
                onError()
            }
        }
    }

    return <>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={deleting} onClick={handleConfirmDelClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={deleting} onClick={onCancel}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </>
}