import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { load } from "../../app/crud";
import { RootState } from "../../app/store";
import { deleteWithAuth, postWithAuth } from "../../http";
import { logout } from "../user/userSlice";

export type RemarkStatus = "N" | "C" | "R"


export interface ProcessStageRemark {
    id: AppId
    remark: string
    createdBy: string 
    createdByFullName: string 
    remarkDate: string
    status: RemarkStatus
    processStageId: string 
    testId: string
    comments: RemarkComment[]  
}

export interface RemarkComment {
    id: AppId
    comment: string
    commentDate: string
    createdBy: string
    createdByFullName: string    
    remarkId: string
}


const adapter = createEntityAdapter<ProcessStageRemark>({
    selectId: (stageRemark) => stageRemark.id,
})

export type StageRemarkState = EntityState<ProcessStageRemark> & { status: AppDataState, key: string | undefined }


const initialState: StageRemarkState = adapter.getInitialState({
    status: { type: "empty" },
    key: undefined,
})

export const loadRemarks = load<{ stageId: AppId }, ProcessStageRemark>(
    "stageRemarks/load",
    ({ stageId }) => `api/process-stage-remarks/${stageId}`,
    state => selectRemarks(state).status,
)

export const createRemark = createAsyncThunk<ProcessStageRemark, {stageId: string, remark: string, testId: string}, AppThunkAPIType>(
    "remark/create",
    async (remark , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/process-stage-remarks/${remark.stageId}`,
            payload: remark,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProcessStageRemark
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const closeRemark = createAsyncThunk<ProcessStageRemark, {remarkId: string, comment: string}, AppThunkAPIType>(
    "remark/close",
    async (input , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/process-stage-remarks/close/${input.remarkId}`,
            payload: { comment: input.comment, },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProcessStageRemark
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const reopenRemark = createAsyncThunk<ProcessStageRemark, {remarkId: string, comment: string}, AppThunkAPIType>(
    "remark/reopen",
    async ({ remarkId, comment } , { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/process-stage-remarks/reopen/${remarkId}`,
            payload: { comment, },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as ProcessStageRemark
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const deleteRemark = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "remark/delete",
    async (remarkId , { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/process-stage-remarks/${remarkId}`}))
        if (deleteWithAuth.fulfilled.match(result)) {
            return remarkId
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }
    }
)


export const ProcessStageRemarksSlice = createSlice({
    name: "processStageRemarks",
    initialState,
    reducers: {
        clearRemarksSlice: (state) => {
            adapter.removeAll(state)
            state.status = { type: "empty" }
            state.key = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createRemark.fulfilled, (state, action) => {            
            adapter.addOne(state, action.payload)
        })

        builder.addCase(closeRemark.fulfilled, (state, action) => {            
            adapter.upsertOne(state, action.payload)
        })

        builder.addCase(deleteRemark.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)
        })     

        builder.addCase(reopenRemark.fulfilled, (state, action) => {            
            adapter.upsertOne(state, action.payload)
        })        
        builder.addCase(loadRemarks.pending, (state, action) => {
            if (state.status.type === "empty") {
                const { stageId } = action.meta.arg
                state.status = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
                state.key = stageId
            }
        })
       
        builder.addCase(loadRemarks.rejected, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                state.status = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }

        })
        builder.addCase(loadRemarks.fulfilled, (state, action) => {
            if (state.status.type === "loading" && state.status.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.status = { type: "loaded" }
            }
        })
        builder.addCase(logout.fulfilled, (state) => {
            adapter.removeAll(state)
            state.status = { type: "empty" }
        })
    },
})

export const { clearRemarksSlice } = ProcessStageRemarksSlice.actions

export const selectRemarks = (state: RootState): StageRemarkState => state.processStageRemarks

export const 
    { selectAll: selectAllRemarks
    , selectById: selectRemarkById     
    } = adapter.getSelectors<RootState>(selectRemarks)

export default ProcessStageRemarksSlice.reducer;
