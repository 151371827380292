import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, isAnyOf } from "@reduxjs/toolkit";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { RootState } from "../../app/store";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { logout } from "../user/userSlice";
import { copySysValidation, copyValidation } from "./validationsSlice";

export interface Activity {
    id: AppId
    name: string
    acceptanceCriteria: Array<AcceptanceCriteria>
    validationId: AppId
}

export interface AcceptanceCriteria {
    id?: AppId
    description: string
}

export type ActivitiesState = EntityState<Activity> & { state: AppDataState }

const adapter = createEntityAdapter<Activity>({
    selectId: (activity) => activity.id,
})

export const loadActivities = 
    createAsyncThunk<Activity[], void, AppThunkAPIType>("activities/load", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/tests-definition-step" }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            if (Array.isArray(payload)) {
                return payload.map(({ id, name, testId, definitionCriterias }) => {
                    const acceptanceCriteria = 
                        Array.isArray(definitionCriterias) ? definitionCriterias.map(({ id, name }) => {
                            return {
                                id,
                                description: name,
                            }
                        }) : []
                    return {
                        id,
                        name,
                        validationId: testId,
                        acceptanceCriteria,
                    } as Activity
                })
            } else {
                return []
            }
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const loadSysActivities = 
    createAsyncThunk<Activity[], void, AppThunkAPIType>("sysActivities/load", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({ url: "api/sys/tests-definition-step" }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            if (Array.isArray(payload)) {
                return payload.map(({ id, name, testId, definitionCriterias }) => {
                    const acceptanceCriteria = 
                        Array.isArray(definitionCriterias) ? definitionCriterias.map(({ id, name }) => {
                            return {
                                id,
                                description: name,
                            }
                        }) : []
                    return {
                        id,
                        name,
                        validationId: testId,
                        acceptanceCriteria,
                    } as Activity
                })
            } else {
                return []
            }
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const createActivity = createAsyncThunk<Activity, Omit<Activity, "id">, AppThunkAPIType>(
    "activities/create", 
    async (activity, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/tests-definition-step",
            payload: {
                name: activity.name,
                testId: activity.validationId,
                definitionCriterias: activity.acceptanceCriteria.map(({ description }) => {
                    return {
                        name: description,
                    }
                }),
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const createSysActivity = createAsyncThunk<Activity, Omit<Activity, "id">, AppThunkAPIType>(
    "sysActivities/create", 
    async (activity, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: "api/sys/tests-definition-step",
            payload: {
                name: activity.name,
                testId: activity.validationId,
                definitionCriterias: activity.acceptanceCriteria.map(({ description }) => {
                    return {
                        name: description,
                    }
                }),
            },
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)

export const updateActivity = createAsyncThunk<Activity, Activity, AppThunkAPIType>(
    "activities/update", 
    async ({ id, name, validationId, acceptanceCriteria }: Activity, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/tests-definition-step/${id}`,
            payload: {
                id,
                name,
                testId: validationId,
                definitionCriterias: acceptanceCriteria.map(({ id, description }) => {
                    return {
                        id,
                        name: description,
                    }
                }),
            },
        }))
        if (putWithAuth.fulfilled.match(result)) {
            const readResult = await dispatch(getWithAuth({ url: `api/tests-definition-step/${id}`}))
            const { payload } = readResult
            if (getWithAuth.fulfilled.match(readResult)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }  
    }
)

export const updateSysActivity = createAsyncThunk<Activity, Activity, AppThunkAPIType>(
    "sysActivities/update", 
    async ({ id, name, validationId, acceptanceCriteria }: Activity, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/sys/tests-definition-step/${id}`,
            payload: {
                id,
                name,
                testId: validationId,
                definitionCriterias: acceptanceCriteria.map(({ id, description }) => {
                    return {
                        id,
                        name: description,
                    }
                }),
            },
        }))
        if (putWithAuth.fulfilled.match(result)) {
            const readResult = await dispatch(getWithAuth({ url: `api/sys/tests-definition-step/${id}`}))
            const { payload } = readResult
            if (getWithAuth.fulfilled.match(readResult)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }  
    }
)


export const deleteSysActivity = createAsyncThunk<AppId, AppId, AppThunkAPIType>("sysActivities/delete", 
    async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuth({ url: `api/sys/tests-definition-step/${id}` }))

    const { payload } = result 
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const deleteActivity = createAsyncThunk<AppId, AppId, AppThunkAPIType>("activities/delete", 
    async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(deleteWithAuth({ url: `api/tests-definition-step/${id}` }))

    const { payload } = result 
    if (deleteWithAuth.fulfilled.match(result)) {
        return id
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const deleteSysStepCriteria = createAsyncThunk<Activity, {stepId: AppId, id: AppId}, AppThunkAPIType>(
    "sysActivities-criteria/delete", 
    async ({id, stepId}, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/sys/tests-definition-step-criteria/${id}` }))

        if (deleteWithAuth.fulfilled.match(result)) {
            const readResult = await dispatch(getWithAuth({ url: `api/sys/tests-definition-step/${stepId}`}))
            const { payload } = readResult
            if (getWithAuth.fulfilled.match(readResult)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }  
    }
)

export const deleteStepCriteria = createAsyncThunk<Activity, {stepId: AppId, id: AppId}, AppThunkAPIType>(
    "activities-criteria/delete", 
    async ({id, stepId}, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/tests-definition-step-criteria/${id}` }))

        if (deleteWithAuth.fulfilled.match(result)) {
            const readResult = await dispatch(getWithAuth({ url: `api/tests-definition-step/${stepId}`}))
            const { payload } = readResult
            if (getWithAuth.fulfilled.match(readResult)) {
                const { id, name, testId, definitionCriterias } = payload
                const acceptanceCriteria: AcceptanceCriteria[] = 
                    (Array.isArray(definitionCriterias) ? definitionCriterias : []).map(({ id, name }) => {
                        return {
                            id,
                            description: name,
                        }
                    })
                return {
                    id,
                    name,
                    validationId: testId,
                    acceptanceCriteria,
                } as Activity
            } else {
                return rejectWithValue(payload ?? { kind: 'unknown' })
            }
        } else {
            return rejectWithValue(result.payload ?? { kind: 'unknown' })
        }  
    }
)

const initialState: ActivitiesState = adapter.getInitialState({
    state: { type: "empty" }
})

export const activitiesSlice = createSlice({
    name: "activities",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(createActivity.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })
        builder.addCase(createSysActivity.fulfilled, (state, action) => {
            adapter.addOne(state, action.payload)
        })
        builder.addCase(updateActivity.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(updateSysActivity.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteSysStepCriteria.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        builder.addCase(deleteStepCriteria.fulfilled, (state, action) => {
            adapter.upsertOne(state, action.payload)
        })
        
        builder.addCase(loadActivities.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadSysActivities.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadActivities.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
            }
        })
        builder.addCase(loadSysActivities.fulfilled, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                adapter.setAll(state, action.payload)
                state.state = {
                    type: "loaded",
                }
            }
        })
        builder.addCase(loadActivities.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(loadSysActivities.rejected, (state, action) => {
            if (state.state.type === "loading" && state.state.requestId === action.meta.requestId) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(deleteSysActivity.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)
        })
        builder.addCase(deleteActivity.fulfilled, (state, action) => {
            adapter.removeOne(state, action.payload)
        })
        builder.addMatcher(isAnyOf(logout.fulfilled, copyValidation.fulfilled, copySysValidation.fulfilled), (state, action) => {
            adapter.removeAll(state)
            state.state = {
                type: "empty",
            }
        })
    },
})

export const selectActivities = (state: RootState) => state.activities

export const {
    selectAll: selectAllActivities,
    selectById: selectActivityById,
    selectEntities: selectActivitiesEntities,
} = adapter.getSelectors<RootState>(selectActivities)

export default activitiesSlice.reducer