import { Localized } from "@fluent/react"
import { Button, ButtonGroup, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, Paper, Skeleton, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField } from "@mui/material"
import { LoadAreas } from "../../app/AppDataLoader"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { createArea, deleteArea, selectAllAreas, selectAreasEntities, selectAreasTotal, updateArea } from "./areaSlice"
import AddIcon from '@mui/icons-material/Add'
import { useCallback, useState } from "react"
import { If } from "../../app/If"
import { LoadingButton } from "@mui/lab"
import { AppId, AppTextFieldHandler } from "../../app/appTypes"
import { AppRequiredMessage } from "../../app/AppRequiredMessage"
import { BottomAppBar } from "../../app/BottomAppBar"
import { showError, showSuccess } from "../notifications/notificationsSlice"
import { styled } from '@mui/system'
import { toolbarMinHeight } from "../.."

const CustomPaper = styled(Paper)(({ theme }) => ({
    maxHeight: `calc(100vh - ${theme.spacing(6)} - ${2 * toolbarMinHeight}px)`,
}))

export const Areas = () => {
    const allAreas = useAppSelector(selectAllAreas)
    const entities = useAppSelector(selectAreasEntities)
    const total = useAppSelector(selectAreasTotal)
    const dispatch = useAppDispatch()

    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(25)

    const [saving, setSaving] = useState(false)

    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)
    const [deleting, setDeleting] = useState(false)

    const view = allAreas.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const [dialog, setDialog] = useState<"none" | "create" | "edit">("none")
    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [id, setId] = useState<AppId>("")

    const openDialog : (itemId: AppId) => void = useCallback(itemId => {
        const entity = entities[itemId]
        setId(itemId)
        setCode(entity?.code ?? "")
        setName(entity?.name ?? "")
        setDialog(entity ? "edit" : "create")
    }, [entities])

    const handleDialogCancelClick = useCallback(() => { 
        setDialog("none") 
    }, [])
    const handleCodeChange: AppTextFieldHandler = useCallback(e => {
        setCode(e.target.value)
    }, [])
    const handleNameChange: AppTextFieldHandler = useCallback(e => {
        setName(e.target.value)
    }, [])
    const handleAddNewAreaButtonClick = useCallback(() => {
        setCode("")
        setName("")
        setId("")
        setDialog("create")
    }, [])
    const handleChangePage = useCallback((_: unknown, newPage: number) => {
        setPage(newPage)
    }, [])
    const handleChangeRowsPerPage: AppTextFieldHandler = useCallback((event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }, [])
    const handleDialogOkClick = async () => {
        if (code && name) {
            setSaving(true)
            try {
                switch (dialog) {
                    case "create":
                        await dispatch(createArea({
                            id,
                            code,
                            name,
                            parentAreaId: null,
                            subAreaIds: [],
                            deviceIds: [],
                        })) 
                        break;
                    case "edit":
                        await dispatch(updateArea({
                            id,
                            code,
                            name,
                            parentAreaId: null,
                            subAreaIds: [],
                            deviceIds: [],
                        }))
                        break;
                    case "none":
                        break;
                }
                dispatch(showSuccess("saved"))
                setDialog("none")
            } catch (error) {
                dispatch(showError("error"))    
            }
            setSaving(false)
        }
    }
    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [])
    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [])
    const handleConfirmDelClick = async () => {
        if (itemToDelete) {
            setDeleting(true)
            try {
                await dispatch(deleteArea(itemToDelete))
                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }
            setItemToDelete(undefined)
            setDeleting(false)
        }
    }

    return <LoadAreas component={<Skeleton animation="wave" variant="rectangular" height="64vh" />}>
        <TableContainer component={CustomPaper}>
            <Table stickyHeader={true}>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{
                            width: '160px',
                        }}>
                            <Localized id="area-code">
                                <span>Kod obszaru</span>
                            </Localized>
                        </TableCell>
                        <TableCell id="area-name">
                            <span>Nazwa obszaru</span>
                        </TableCell>
                        <TableCell sx={{
                            width: '160px',
                        }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {view.map(({ id, code, name }) => {
                        return <TableRow key={id}>
                            <TableCell>{code}</TableCell>
                            <TableCell>{name}</TableCell>
                            <TableCell>
                                <ButtonGroup size="small">
                                    <Button onClick={() => openDialog(id)}>
                                        <Localized id="edit">
                                            <span>Edytuj</span>
                                        </Localized>
                                    </Button>
                                    <Button onClick={() => handleDeleteClick(id)}>
                                        <Localized id="delete">
                                            <span>Usuń</span>
                                        </Localized>
                                    </Button>
                                </ButtonGroup>
                            </TableCell>
                        </TableRow>
                    })}
                </TableBody>
            </Table>
        </TableContainer>
        <Dialog open={dialog !== "none"} fullWidth maxWidth="sm">
            <DialogTitle>
                <If 
                    condition={id === ""} 
                    otherwise={
                        <Localized id="area-edit">
                            <span>Edycja obszaru</span>
                        </Localized>}>
                    <Localized id="area-new">
                        <span>Nowy obszar</span>
                    </Localized>
                </If>
            </DialogTitle>
            <DialogContent>
                <Stack direction="column" spacing={2}>
                    <TextField sx={{
                        marginTop: 2,
                        maxWidth: 240,
                    }} 
                        error={code === ""}
                        label={<Localized id="area-code">
                            <span>Kod obszaru</span>
                        </Localized>}
                        inputProps={{ maxLength: 20, }}
                        value={code}
                        onChange={handleCodeChange}
                        helperText={code ? " " : <AppRequiredMessage />}
                    />
                    <TextField
                        error={name === ""}
                        label={<Localized id="area-name">
                            <span>Nazwa obszaru</span>
                        </Localized>}
                        inputProps={{ maxLength: 200, }}
                        fullWidth
                        value={name}
                        onChange={handleNameChange}
                        helperText={name ? " " : <AppRequiredMessage />}
                    />
                </Stack>

            </DialogContent>
            <DialogActions>
                <LoadingButton loading={saving} onClick={handleDialogOkClick}>
                    <Localized id="ok">
                        <span>Ok</span>
                    </Localized>
                </LoadingButton>
                <Button onClick={handleDialogCancelClick}>
                    <Localized id="cancel">
                        <span>Anuluj</span>
                    </Localized>
                </Button>
            </DialogActions>
        </Dialog>
        <Dialog open={itemToDelete !== undefined}>
            <DialogContent>
                <DialogContentText>
                    <Localized id="confirm-delete">
                        <span>Czy napewno chcesz usunąć?</span>
                    </Localized>
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton loading={deleting} onClick={handleConfirmDelClick}>
                    <Localized id="yes"><span>Tak</span></Localized>
                </LoadingButton>
                <LoadingButton loading={deleting} onClick={handleCancelDelClick}>
                    <Localized id="no"><span>Nie</span></Localized>
                </LoadingButton>
            </DialogActions>
        </Dialog>
        <BottomAppBar>
            <Fab color="secondary" onClick={handleAddNewAreaButtonClick}>
                <AddIcon />
            </Fab>
            <TablePagination 
                sx={{
                    marginLeft: 'auto',
                }}
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={total}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </BottomAppBar>
    </LoadAreas>
}