import { Localized } from "@fluent/react";

export const MaterialCardStatusLabel = ({ status }: { status: string}) => {
    switch (status) {
        case "N":
            return <Localized id="">
                <span>Nowa</span>
            </Localized>        
        case "A":
            return <Localized id="">
                <span>Zatwierdzona</span>
            </Localized>
        case "W":
            return <Localized id="">
                <span>W obiegu</span>
            </Localized>        
        default:
            return <span></span>
    }
}