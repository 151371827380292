import { useParams } from "react-router-dom"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { Localized } from "@fluent/react"
import { Alert, Autocomplete, Box, Button, ButtonGroup, Card, CardContent, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Grid, Paper, Skeleton, Stack, Step, StepContent, StepLabel, Stepper, Tab, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, ToggleButton, ToggleButtonGroup, Tooltip, Typography, styled } from "@mui/material"
import { LoadMaterialCardItem, LoadMaterialCards } from "../../../app/AppDataLoader"
import { FlexDiv } from "../../../app/Utils"
import AddIcon from '@mui/icons-material/Add'
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react"
import { AppId, AppTextFieldHandler } from "../../../app/appTypes"
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab"
import { showError, showSuccess } from "../../notifications/notificationsSlice"
import { deleteMaterialCard, selectMaterialCardById, sendToApprove, updateMaterialCard } from "./materialCardSlice"
import { Attachements } from "../../urs/Attachements"
import PrintIcon from '@mui/icons-material/Print'
import EditIcon from '@mui/icons-material/Edit'
import { selectSettings } from "../../settings/settingsSlice"
import { MaterialCardItem, createMaterialCardItem, deleteMaterialCardItem, selectAllMaterialCardItems, updateMaterialCardItem } from "./materialCardItemSlice"
import { loadManyBlobs } from "../../urs/ursAttachementsSlice"
import DeleteIcon from '@mui/icons-material/Delete'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import { MaterialCardStatusLabel } from "./MaterialCardStatusLabel"
import FirstPageIcon from '@mui/icons-material/FirstPage'
import { useNavigate } from "react-router-dom"

type FormTabs = "details" | "items" | "attachments" | "workflow"
const LoadComponent = () => <Skeleton variant="rectangular" height="50vh" />
const RequiredError = () => <Localized id="field-is-required"><span>Pole jest wymagane</span></Localized>

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
    '& .MuiToggleButtonGroup-grouped': {
        margin: theme.spacing(0.5),
        border: 0,
        '&.Mui-disabled': {
            border: 0,
        },
        '&:not(:first-of-type)': {
            borderRadius: theme.shape.borderRadius,
        },
        '&:first-of-type': {
            borderRadius: theme.shape.borderRadius,
        },
    },
}));


export const MaterialCardForm = () => {
    return <LoadMaterialCards component={<Skeleton />}>
        <LoadMaterialCardItem component={<Skeleton />}>
            <EditForm />
        </LoadMaterialCardItem>
    </LoadMaterialCards>
}

const EditForm = () => {
    const dispatch = useAppDispatch()
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const { locale } = useAppSelector(selectSettings);
    const navigate = useNavigate()
    const materialCard = useAppSelector(state => selectMaterialCardById(state, id))
    const materialCardItems = useAppSelector(state => selectAllMaterialCardItems(state).filter(({ materialCardId }) => materialCardId == id))

    // loading attachments
    useEffect(() => {
        if (materialCard) {
            dispatch(loadManyBlobs([{ docType: 'materialCard', docId: id }, [materialCard.guid]]))
        }
    }, [])

    const [tab, setTab] = useState<FormTabs>("details");

    const cardCreateDate = materialCard?.date ? new Date(materialCard.date).toISOString().split('T')[0] : "";
    ///header
    const [name, setName] = useState<string>(materialCard?.name ?? "");
    const [contractor, setContractor] = useState<string>(materialCard?.contractor ?? "");
    const [samples, setSamples] = useState<string>(materialCard?.samples ?? "");
    const [installationPlace, setInstallationPlace] = useState<string>(materialCard?.installationPlace ?? "");
    const [description, setDescription] = useState<string>(materialCard?.description ?? "");
    const [address, setAdress] = useState<string>(materialCard?.address ?? "");
    const [industrySector, setIndustrySector] = useState<string>(materialCard?.industrySector ?? "");
    const [cardNumber, setCardNumber] = useState<string>(materialCard?.cardNumber ?? "");

    ///detail
    const [itemRow, setItemRow] = useState<MaterialCardItem | undefined>(undefined)
    const [itemId, setItemId] = useState("")
    const [itemName, setItemName] = useState<string>("");
    const [itemProducer, setItemProducer] = useState<string>("");
    const [itemDescription, setItemDescription] = useState<string>("");


    const [showItemDialog, setShowItemDialog] = useState(false)
    const [showSendToapproveQuestion, setShowSendToapproveQuestion] = useState(false)
    const [showDeleteMaterialCardMsg, setShowDeleteMaterialCardMsg] = useState(false)
    const [showCopyCard, setShowCopyCard] = useState(false)
    
    const [showEditDialog, setShowEditDialog] = useState(false)
    const [saving, setSaving] = useState(false)
    const [itemToDelete, setItemToDelete] = useState<AppId | undefined>(undefined)


    const projects = [
        "Projekt Budowa Hali 2023/05",
       "Project Budowa Mostu "
   ]

   const [selectedProject, setSelectedProject] = useState<string>("Projekt Budowa Hali 2023/05")

   const handleProjectChange = useCallback((_, newproject) => setSelectedProject(newproject), [])


    const handleTabChange = useCallback((_, newTab) => {
        setTab(newTab)
    }, [])


    const handleItemNameChange: AppTextFieldHandler = useCallback(e => {
        setItemName(e.target.value)
    }, [setItemName])

    const handleItemDescriptionChange: AppTextFieldHandler = useCallback(e => {
        setItemDescription(e.target.value)
    }, [setItemDescription])

    const handleItemProducerChange: AppTextFieldHandler = useCallback(e => {
        setItemProducer(e.target.value)
    }, [setItemProducer])

    const handleAddDetail = useCallback(() => {
        setItemRow(undefined)
        setItemName("")
        setItemProducer("")
        setItemDescription("")
        setShowItemDialog(true)
    }, [])

    const handleEditItemClick = useCallback((row: MaterialCardItem) => {
        setItemRow(row)
        setItemName(row.id)
        setItemProducer(row.name)
        setItemDescription(row.description)
        setShowItemDialog(true)
    }, [])

    const handleConfirmItemSaveClick = async () => {
        setSaving(true)
        try {
            if (itemRow) {
                await dispatch(updateMaterialCardItem({
                    id: itemRow.id,
                    name: itemName,
                    description: itemDescription,
                    materialCardId: id,
                    producer: itemProducer,
                    guid: itemRow?.guid,
                    order: itemRow?.order
                })).unwrap();

                dispatch(showSuccess("saved"))
                setShowItemDialog(false)
            } else {
                await dispatch(createMaterialCardItem({
                    name: itemName,
                    description: itemDescription,
                    producer: itemProducer,
                    materialCardId: id
                })).unwrap();

                dispatch(showSuccess("saved"))
                setShowItemDialog(false)
            }
        }
        catch (error) {
            dispatch(showError("error"));
        }
        finally {
            setSaving(false)
        }
    }

    const handleConfirmDeleteCardClick = async () => {
        if (id) {
            setSaving(true)
            try {
                await dispatch(deleteMaterialCard(id)).unwrap()
                dispatch(showSuccess("deleted"))
                navigate(`/materialcards/`, { replace: true });
            } catch (error) {
                dispatch(showError("error"))
            }

            setSaving(false)
            setItemToDelete(undefined)
        }
    }

    const handleConfirmSendToApproveClick = async () => {
        if (id) {
            setSaving(true)
            try {
                await dispatch(sendToApprove(id)).unwrap()
                dispatch(showSuccess("sent"))
            } catch (error) {
                dispatch(showError("error"))
            }

            setSaving(false)
            setShowSendToapproveQuestion(false)
            setItemToDelete(undefined)
        }
    }
    const handleConfirmEditClick = async () => {
        setSaving(true)
        setValidationErrors(true)

        if(materialCard){
                try {
                    await dispatch(updateMaterialCard({                     
                        id: materialCard.id,
                        date: materialCard.date,
                        guid: materialCard.guid,
                        status: materialCard.status,
                        name,  
                        contractor, 
                        cardNumber, 
                        address: "",
                        description, 
                        installationPlace,
                        industrySector,
                        samples
                    }));

                    setShowEditDialog(false)
                    dispatch(showSuccess("saved"));
                } catch (error) {
                    dispatch(showError("error"));
                }
            } 
    
        setShowEditDialog(false)

        setSaving(false)
    }
    

    const handleConfirmItemDelClick = async () => {
        if (itemToDelete) {
            setSaving(true)
            try {
                await dispatch(deleteMaterialCardItem(itemToDelete)).unwrap()

                dispatch(showSuccess("deleted"))
            } catch (error) {
                dispatch(showError("error"))
            }

            setSaving(false)
            setItemToDelete(undefined)
        }
    }

    const handleCancelItemClick = useCallback(() => {
        setItemRow(undefined)
        setItemName("")
        setItemProducer("")
        setItemDescription("")
        setShowItemDialog(false)
    }, [])

    const handleDeleteClick = useCallback((id: AppId) => {
        setItemToDelete(id)
    }, [setItemToDelete])

    const handleCancelDelClick = useCallback(() => {
        setItemToDelete(undefined)
    }, [setItemToDelete])

    const handleCancelClick = useCallback(() => {
        setShowDeleteMaterialCardMsg(false)
        setShowEditDialog(false)
        setShowSendToapproveQuestion(false)
        setShowCopyCard(false)
    }, [setShowEditDialog, setShowSendToapproveQuestion, setShowDeleteMaterialCardMsg])

    
    const handleCopyCardClick = useCallback(() => {
        setShowCopyCard(true)
    }, [setShowCopyCard])

    const handleDeleteCardClick = useCallback(() => {
        setShowDeleteMaterialCardMsg(true)
    }, [setShowDeleteMaterialCardMsg])

    const handleSendToApproveClick = useCallback(() => {
        setShowSendToapproveQuestion(true)
    }, [setShowSendToapproveQuestion])

    const handleEditCardClick = useCallback(() => {
        setShowEditDialog(true)
    }, [setShowEditDialog])
    

    const handlePrintUrsClick = async () => {
        if (materialCard?.id) {
            window.open(`${process.env.REACT_APP_REST_API}api/reports/material-card/${materialCard.id}?language=${locale}`, '_blank')
        }
    }
    const handleBackButtonClick = useCallback(() => {
        navigate(-1)
    }, [navigate])

    const [validationErrors, setValidationErrors] = useState(false)
    const handleNameChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setName(e.target.value), [setName]);

    const handleInstallationPlaceChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setInstallationPlace(e.target.value), [setInstallationPlace]);

    const handleSamplesChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setSamples(e.target.value), [setSamples]);

    const handleDescriptionChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setDescription(e.target.value), [setDescription]);

    const handleCardNoChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setCardNumber(e.target.value), [setCardNumber]);

    const handleContractorChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setContractor(e.target.value), [setContractor]);

    const handleIndustrySectorChange: (e: ChangeEvent<HTMLInputElement>) => void =
        useMemo(() =>
            (e) => setIndustrySector(e.target.value), [setIndustrySector]);

    const nameError = validationErrors && name === ""
    const contractorError = validationErrors && contractor === ""
    const industrySectorError = validationErrors && industrySector === ""
    const cardNumberError = validationErrors && cardNumber === ""

    const steps = [
        {
            label: 'Przygotowanie',
            description: 'Etap tworzenia Karty Materiałowej',
            team: [
                { imie: "Rafał Zdun", stanowisko: "Specjalista Kart" }
            ]
        },
        {
            label: 'Zatwierdzanie',
            description: 'Etap zatwierdzania Karty Materiałowej',
            team: [
                { imie: "Anna Pomidor", stanowisko: "Kierownik Projektu" },
                { imie: "Jan Seler", stanowisko: "Inżynier Oprogramowania" },
                { imie: "Ewa Brokuł", stanowisko: "Analityk Biznesowy" },
                { imie: "Marek Korzonek", stanowisko: "Specjalista ds. Marketingu" },
                { imie: "Alicja Ananas", stanowisko: "Project Manager" },
            ]
        },
        {
            label: 'Zatwierdzanie końcowe',
            description: 'Zatwierdzanie Inwestorskie',
            team: [
                { imie: "Łukasz Berbec", stanowisko: "Inwestor" }
            ]
        },
    ];

    if (!materialCard) return <Alert severity="warning">
        <Localized id="error-entity-not-exists">
            <div>Obiekt nie istnieje</div>
        </Localized>
    </Alert>


    return <>
        <Container maxWidth={false}>
            <Stack spacing={2}>
                <Card>
                    <CardContent>
                        <Grid container spacing={1}>
                            <Grid item xs={1}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="">
                                        <span>Numer karty</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5">{materialCard.cardNumber}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="">
                                        <span>Wykonawca</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5">{materialCard.contractor}</Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="">
                                        <span>Branża</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5">{materialCard.industrySector}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="">
                                        <span>Data</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5">{cardCreateDate}</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography component="div" color="text.secondary" gutterBottom>
                                    <Localized id="">
                                        <span>Status</span>
                                    </Localized>
                                </Typography>
                                <Typography variant="h5"><MaterialCardStatusLabel status={materialCard.status ?? ""} />
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
                <Grid container spacing={2}>
                    <Grid item xs={10}>
                        <TabContext value={tab}>
                            <TabList onChange={handleTabChange}>
                                <Tab
                                    key={0}
                                    value={"details"}
                                    label={<Localized id="">Informacje</Localized>} />
                                <Tab
                                    key={1}
                                    value={"items"}
                                    label={<Localized id="">Lista materiałów</Localized>} />
                                <Tab
                                    key={2}
                                    value={"attachments"}
                                    label={<Localized id="">Załączniki</Localized>} />
                                <Tab
                                    key={3}
                                    value={"workflow"}
                                    label={<Localized id="">Obieg dokumentu</Localized>} />

                            </TabList>

                            <TabPanel value="details">
                                <Card>
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={materialCard?.name}
                                                    label={<Localized id="">Nazwa materiału</Localized>}>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={materialCard?.installationPlace}
                                                    label={<Localized id="">Określenie miejsca wbudowania</Localized>}>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={materialCard?.samples}
                                                    label={<Localized id="">Próbki do akceptacji</Localized>}>
                                                </TextField>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    fullWidth
                                                    multiline={true}
                                                    minRows={4}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                    value={materialCard?.description}
                                                    label={<Localized id="">Opis</Localized>}>
                                                </TextField>
                                            </Grid>

                                        </Grid>
                                    </CardContent>
                                </Card>
                            </TabPanel>
                            <TabPanel value="items">
                                <TableContainer component={Paper} sx={{
                                    maxHeight: 'calc(100vh - 400px)',
                                }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ width: "60px" }}>
                                                <Localized id="lp">
                                                    <span>L.p.</span>
                                                </Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: "200px" }}>
                                                <Localized id="">
                                                    <span>Nazwa</span>
                                                </Localized>
                                            </TableCell>
                                            <TableCell sx={{ width: "200px" }}>
                                                <Localized id="">
                                                    <span>Producent</span>
                                                </Localized>
                                            </TableCell>
                                            <TableCell>
                                                <Localized id="">
                                                    <span>Opis</span>
                                                </Localized>
                                            </TableCell>
                                            <TableCell>

                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {materialCardItems.map((row, index) =>
                                            <TableRow key={row.id}>
                                                <TableCell>{index + 1}</TableCell>
                                                <TableCell style={{ minWidth: "150px" }}>{row.name}</TableCell>
                                                <TableCell style={{ minWidth: "200px" }}>{row.producer}</TableCell>
                                                <TableCell style={{
                                                    width: '100%',
                                                    maxWidth: 1000,
                                                    whiteSpace: 'nowrap',
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis'
                                                }}>{row.description}</TableCell>
                                                <TableCell align="right">
                                                    <ButtonGroup>
                                                        <Button color="primary" onClick={() => handleEditItemClick(row)} >
                                                            <Localized id="edit">
                                                                <span>Edytuj</span>
                                                            </Localized>
                                                        </Button>
                                                        <Button onClick={() => handleDeleteClick(row.id)} >
                                                            <Localized id="delete">
                                                                <span>Usuń</span>
                                                            </Localized>
                                                        </Button>
                                                    </ButtonGroup>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </TableContainer>
                                <FlexDiv>
                                    <Fab color="secondary" onClick={handleAddDetail} sx={{ marginTop: 2, marginLeft: 2, marginBottom: 2 }}>
                                        <AddIcon />
                                    </Fab>
                                </FlexDiv>

                            </TabPanel>
                            <TabPanel value="attachments">
                                <div>Zalaczniki</div>
                                <Attachements resourceId={materialCard.guid} canDelete={true} canUpload={true} />
                            </TabPanel>
                            <TabPanel value="workflow">

                                <Box
                                    display="flex"
                                    justifyContent="center"
                                    alignItems="center"
                                    style={{ height: '100%' }}
                                >
                                    <Typography variant="caption">
                                        Obieg dokumentu "Karta materiałowa"
                                    </Typography>
                                    <Stepper activeStep={0} orientation="vertical">
                                        {steps.map((step, index) => (
                                            <Step key={step.label} expanded={true} active={index === 0 || (index === 1 && materialCard.status==="W")}>
                                                <StepLabel
                                                    optional={

                                                        <Typography variant="caption">{step.description}</Typography>

                                                    }
                                                >
                                                    {step.label}
                                                </StepLabel>
                                                <StepContent>
                                                    <div>
                                                        {step.team.map((row) => (
                                                            <div key={index}>
                                                                <Typography variant="body2">{row.imie} </Typography>
                                                                <Typography variant="body2">{row.stanowisko}</Typography>
                                                                <br />
                                                            </div>
                                                        ))}
                                                    </div>
                                                </StepContent>
                                            </Step>
                                        ))}
                                    </Stepper>
                                </Box>
                            </TabPanel>
                        </TabContext>
                    </Grid>
                    <Grid item xs={2}>
                        <Box display="flex" flexDirection="column" gap={2}>
                            <Stack direction="row" spacing={4} sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}>
                                <Paper
                                    elevation={0}
                                    sx={{
                                        display: 'flex',
                                        border: (theme) => `1px solid ${theme.palette.divider}`,
                                        flexWrap: 'wrap'
                                    }}
                                >
                                    <StyledToggleButtonGroup
                                        value={'left'}
                                        exclusive
                                        aria-label="text alignment"
                                    >
                                        <Tooltip title={<Localized id="">Edytuj</Localized>}>
                                            <ToggleButton value="edit" aria-label="edit" onClick={() => handleEditCardClick()}>
                                                <EditIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title={<Localized id="">Raport</Localized>}>
                                            <ToggleButton value="report" aria-label="report" onClick={() => handlePrintUrsClick()}>
                                                <PrintIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title={<Localized id="">Kopiuj</Localized>}>
                                            <ToggleButton value="copy" aria-label="copy" onClick={() => handleCopyCardClick()}>
                                                <ContentCopyIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title={<Localized id="">Usuń</Localized>}>
                                            <ToggleButton value="delete" aria-label="delete" onClick={() => handleDeleteCardClick()}>
                                                <DeleteIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title={<Localized id="">Wróć</Localized>}>
                                            <ToggleButton value="return" aria-label="return" onClick={() => handleBackButtonClick()}>
                                                <FirstPageIcon />
                                            </ToggleButton>
                                        </Tooltip>

                                    </StyledToggleButtonGroup>
                                </Paper>
                            </Stack>
                            <Divider />
                            <Button key="publish" color="info" variant="contained" onClick={() => handleSendToApproveClick()}>
                                <Localized id="">
                                    <span>Wyślij do zatwierdzenia</span>
                                </Localized>
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
            </Stack>
            <Dialog open={showItemDialog} fullWidth maxWidth="lg">
                <DialogTitle>
                    <Localized id="">
                        <span>Materiał</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Stack direction="column" spacing={2} sx={{ marginTop: 2 }}>
                        <TextField
                            fullWidth
                            label={<Localized id="">
                                <span>Nazwa</span>
                            </Localized>}
                            value={itemName}
                            onChange={handleItemNameChange}
                        />
                        <TextField
                            fullWidth
                            label={<Localized id="">
                                <span>Producent</span>
                            </Localized>}
                            value={itemProducer}
                            onChange={handleItemProducerChange}
                        />
                        <TextField
                            fullWidth
                            label={<Localized id="">
                                <span>Opis</span>
                            </Localized>}
                            multiline={true}
                            minRows={8}
                            value={itemDescription}
                            onChange={handleItemDescriptionChange}
                        />

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <LoadingButton loading={saving} onClick={handleConfirmItemSaveClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </LoadingButton>
                    <LoadingButton loading={saving} onClick={handleCancelItemClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog open={itemToDelete !== undefined}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="confirm-delete">
                            <span>Czy napewno chcesz usunąć?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmItemDelClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelDelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showSendToapproveQuestion}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="">
                            <span>Czy wysłać dokument do zatwierdzenia?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmSendToApproveClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showDeleteMaterialCardMsg}>
                <DialogContent>
                    <DialogContentText>
                        <Localized id="">
                            <span>Czy napewno usunać kartę?</span>
                        </Localized>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmDeleteCardClick}>
                        <Localized id="yes"><span>Tak</span></Localized>
                    </Button>
                    <Button onClick={handleCancelClick}>
                        <Localized id="no"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={showEditDialog}>
                <DialogTitle>
                    <Localized id="">
                        <span>Edycja danych</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={contractorError}
                                helperText={contractorError && <RequiredError />}
                                inputProps={{ maxLength: 200 }}
                                value={contractor}
                                onChange={handleContractorChange}
                                label={<Localized id="">Wykonawca</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={industrySectorError}
                                helperText={industrySectorError && <RequiredError />}
                                inputProps={{ maxLength: 200 }}
                                value={industrySector}
                                onChange={handleIndustrySectorChange}
                                label={<Localized id="">Branża</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={cardNumberError}
                                helperText={cardNumberError && <RequiredError />}
                                inputProps={{ maxLength: 200 }}
                                value={cardNumber}
                                onChange={handleCardNoChange}
                                label={<Localized id="">Numer Karty</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                value={name}
                                error={nameError}
                                helperText={nameError && <RequiredError />}
                                onChange={handleNameChange}
                                label={<Localized id="">Nazwa materiału</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={installationPlace}
                                onChange={handleInstallationPlaceChange}
                                label={<Localized id="">Określenie miejsca wbudowania:</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={samples}
                                onChange={handleSamplesChange}
                                label={<Localized id="">Próbki</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                value={description}
                                onChange={handleDescriptionChange}
                                label={<Localized id="">Opis</Localized>}>
                            </TextField>
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleConfirmEditClick}>
                        <Localized id="save"><span>Zapisz</span></Localized>
                    </Button>
                    <Button onClick={handleCancelClick}>
                        <Localized id="cancel"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>    
            </Dialog>
            <Dialog open={showCopyCard}>
                <DialogTitle>
                    <Localized id="">
                        <span>Kopiowanie Karty Materiałowej</span>
                    </Localized>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{ padding: 2 }}>
                    <Grid item xs={12}>
                        <Autocomplete
                                disablePortal
                                options={projects}
                                fullWidth
                                value={selectedProject}
                                onChange={handleProjectChange}

                                getOptionLabel={item => item}
                                renderInput={params => <TextField {...params} label={<Localized id="">Projekt</Localized>} />}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                value={contractor}
                                disabled={true}
                                label={<Localized id="">Wykonawca</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={industrySectorError}
                                helperText={industrySectorError && <RequiredError />}
                                inputProps={{ maxLength: 200 }}
                                value={industrySector}
                                onChange={handleIndustrySectorChange}
                                label={<Localized id="">Branża</Localized>}>
                            </TextField>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                error={cardNumberError}
                                helperText={cardNumberError && <RequiredError />}
                                inputProps={{ maxLength: 200 }}
                                value={""}
                                onChange={handleCardNoChange}
                                label={<Localized id="">Numer Karty</Localized>}>
                            </TextField>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelClick}>
                        <Localized id="save"><span>Zapisz</span></Localized>
                    </Button>
                    <Button onClick={handleCancelClick}>
                        <Localized id="cancel"><span>Nie</span></Localized>
                    </Button>
                </DialogActions>    
            </Dialog>
            
        </Container>
    </>
}
