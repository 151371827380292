import { Localized } from "@fluent/react";
import { Skeleton } from '@mui/material';
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useEffect, useState } from "react";
import { loadUrsOffer, loadUrsOfferComments, selectAllOfferRequirements, UrsOffer, UrsOfferMessage } from "./ursOfferSlice";
import { loadManyBlobs, selectAllBlobs } from "../urs/ursAttachementsSlice";
import { useParams } from "react-router-dom";
import { LoadDeviceTypes, LoadRequirements, LoadRequirementsTypes, LoadSupplierDeviceDetails, LoadSupplierDevices, LoadUsers } from "../../app/AppDataLoader";
import { ClientsOfferForm } from "./ClientsOfferForm";
import { SuppliersOfferForm } from "./SuppliersOfferForm";



const LoadComponent  = () => <Skeleton variant="rectangular" height="50vh" />

export const UrsOfferForm = ({ mode }: { mode: string }) => {
    const { id: paramId } = useParams(); const id = paramId ?? ''
    const dispatch = useAppDispatch()

    const [urs, setUrs] = useState<undefined | "error" | UrsOffer>(undefined)
    const [comments, setComments] = useState<UrsOfferMessage[]>([])
    const blobs = useAppSelector(selectAllBlobs)
    const module = useAppSelector(state => state.module.currentModule)

    function* allURSRequirements(urs: UrsOffer) {
        for (const type of urs.types) {
            for (const req of type.requirements) {
                yield req
            }
            for (const group of type.groups) {
                for (const req of group.requirements) {
                    yield req
                }
            }
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            if (urs === undefined) {
                try {
                    const entity = await dispatch(loadUrsOffer(id)).unwrap()                                      
                    const comments = await dispatch(loadUrsOfferComments(entity.id)).unwrap()
                    setUrs(entity)
                    setComments(comments)
                  
                    const allRequirements = Array.from(allURSRequirements(entity))
                    await dispatch(loadManyBlobs([{
                        docType: 'offer',
                        docId: entity.id
                    }, [entity.guid, ...allRequirements.map(x => x.guid)]])).unwrap()
                } catch (err) {
                    console.log(err)
                    if (err !== null && typeof err === 'object' && err['name'] && err['name'] === 'ConditionError') {
                        // dispatch cancelled
                        console.log(err)
                    } else {
                        setUrs("error")
                    }
                }
            }
        }
        fetchData()
    }, [dispatch, urs, id])

    switch (urs) {
        case undefined:
            return <LoadComponent />
        case "error":
            return <Localized id="error"><span>Błąd</span></Localized>
    }

    if (module === null) return <>No module</>

    const Form = module.code === 'offering' ? SuppliersOfferForm : ClientsOfferForm
    
    return <LoadDeviceTypes component={<LoadComponent />}>
        <LoadRequirementsTypes component={<LoadComponent />}>
            <LoadRequirements component={<LoadComponent />}>
                <LoadSupplierDevices component={<LoadComponent />}>
                    <LoadSupplierDeviceDetails component={<LoadComponent />}>
                        <LoadUsers component={<LoadComponent />}>
                            <Form entity={urs} mode={mode} comments={comments} blobs={blobs} />
                        </LoadUsers>
                    </LoadSupplierDeviceDetails>
                </LoadSupplierDevices>
            </LoadRequirements>
        </LoadRequirementsTypes>
    </LoadDeviceTypes>
}
