import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { DialogActions, DialogContent, DialogContentText } from "@mui/material"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import { selectModule } from "../../modules/moduleSlice"
import { deleteRequirement, deleteSysRequirement } from "../RequirementsDictSlice"

export interface DeleteRequirementProps {
    requirementId: AppId
    onSuccess: () => void
    onCancel: () => void
    onError: () => void
}

export const DeleteRequirement = ({ requirementId, onSuccess, onCancel, onError }: DeleteRequirementProps) => {
    const dispatch = useAppDispatch()
    const currentModule = useAppSelector(state => selectModule(state).currentModule)
    const [deleting, setDeleting] = useState(false)

    const handleConfirmDelClick = async () => {
        if (currentModule) {  
            setDeleting(true)             
            try {
                if (currentModule.code === "super"){
                    await dispatch(deleteSysRequirement(requirementId))
                }
                else{
                    await dispatch(deleteRequirement(requirementId))
                }
                onSuccess()
            } catch (error) {
                onError()
            }
            
        }
    }

    return <>
        <DialogContent>
            <DialogContentText>
                <Localized id="confirm-delete">
                    <span>Czy napewno chcesz usunąć?</span>
                </Localized>
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={deleting} onClick={handleConfirmDelClick}>
                <Localized id="yes"><span>Tak</span></Localized>
            </LoadingButton>
            <LoadingButton loading={deleting} onClick={onCancel}>
                <Localized id="no"><span>Nie</span></Localized>
            </LoadingButton>
        </DialogActions>
    </>
}
