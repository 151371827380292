
import { Avatar, Grid, Skeleton, Typography } from '@mui/material';
import { useAppSelector } from '../../app/hooks';
import { selectLoggedUser, selectUserCompanyId } from './userSlice';
import { ReactComponent as ALuk } from './avatar.svg';
import { ReactComponent as APrim } from './1.svg';
import { ReactComponent as AOther } from './2.svg';
import { LoadCompanies } from '../../app/AppDataLoader';
import { selectCompanyById } from '../company/companiesSlice';

export const AvatarImage = ({ user }: { user: string }) => {
    if (user.toLowerCase() === 'lukasz') {
        return <ALuk />;
    }
    else if (user.toLowerCase() === 'primoz') {
        return <APrim />
    }
      return <AOther />;
}

export const LoggedUserCard = () => {
    
    const loggedUser = useAppSelector(selectLoggedUser)
    const companyId = useAppSelector(selectUserCompanyId) as string
    const company =  useAppSelector(state => selectCompanyById(state, companyId))
    
    return (
        <LoadCompanies component={<Skeleton variant="rectangular" />}>
            <Grid  sx={{ display: 'flex' }} alignItems="center">
                <Grid item>
                <Avatar
                    alt="I"                
                    sx={{ width: 40, height: 40 }}>    
                </Avatar>
                </Grid>
                <Grid item sx={{marginLeft: 1}}>
                
                    <Typography  variant="button"  noWrap component="div" >                          
                        {loggedUser}                   
                    </Typography>
                    <Typography  variant="caption"  noWrap component="div" >                          
                        {company?.shortName}                    
                    </Typography>
                    
                </Grid>
            </Grid>
        </LoadCompanies>
    );
}
