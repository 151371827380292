import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, nanoid } from "@reduxjs/toolkit";
import { AppDataState, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { isSameRequestId } from "../../app/crud";
import { RootState } from "../../app/store";
import { Chc } from "./chcTypes";

const adapter = createEntityAdapter<Chc>({
    selectId: chc => chc.id
})

export type ChcState = EntityState<Chc> & { state: AppDataState }

const initialState: ChcState = adapter.getInitialState({
    state: { type: 'empty' }
})

export const loadChc = createAsyncThunk<Chc[], void, AppThunkAPIType>('chc/load', async (_, api) => {
    return [{
        id: nanoid(),
        status: 'Not_implemented',
        requestNumber: 'CHC 0001',
        requestDate: '01-05-2024',
        applicant: 'John Doe',
        department: 'Production',
        requestFromContractor: false,
        changeDescription: 'Some description',
        companyName: 'Creaftech Sp. z o.o.',
        equipment: 'Production',
        product: 'Therapeutic',
        packagingMaterial: 'Printed',
        startingMaterial: 'API',
        otherChange: 'Business_quality_process',
        supportingSystem: 'Purified_water',
        batchNumberingAndNamingSystem: '',
        stability: '',
        cleaningProcedure: '',
        formulation: '',
        research: 'Analytical_methods',
        additionalInformation: 'Some additional informations',
    }]
})

export const chcSlice = createSlice({
    name: 'chc',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(loadChc.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadChc.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        })
        builder.addCase(loadChc.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
    },
})

export const selectChc = (state: RootState) => state.chc

export const
    { selectAll: selectAllChc
    , selectById: selectChcById
    } = adapter.getSelectors(selectChc)

export default chcSlice.reducer
