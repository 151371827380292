import { useCallback, useEffect, useRef, useState } from 'react';
import { Paper, Typography, List, ListItem, ListItemText, IconButton, Box, Divider, Tooltip, Button, Dialog, Link } from '@mui/material';
import { AppId } from '../../../../app/appTypes';
import { loadForProject, ProjectProcess, ProjectProcessHeader } from '../../projectProcess/ProjectProcessApiClient';
import { unwrapResult } from '@reduxjs/toolkit';
import { Localized } from '@fluent/react';
import { useAppDispatch } from '../../../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { LoadingContainer } from '../../../../app/LoadingContainer';
import { ErrorContainer } from '../../../../app/ErrorContainer';
import ForwardIcon from '@mui/icons-material/Forward';
import CircleIcon from '@mui/icons-material/Circle';
import AddIcon from '@mui/icons-material/Add';
import { ProjectProcessStatusLabel } from '../../projectProcess/ProjectProcessStatusLabel';
import { CreateProjectProcess } from '../../Dialogs/CreateProjectProcess';
import produce from "immer"
import AccountTreeIcon from '@mui/icons-material/AccountTree';

interface ProjectOverviewProps {
    projectId: AppId;
    projectGuid: AppId;
}

const EmptyWidgetNotice = () => {
    return (
        <>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                textAlign="center"
                p={3}
                borderRadius={2}
            >
                <Typography variant="h6" component="h2" gutterBottom>
                    <Localized id='project-process-empty-list'>Brak procesów</Localized>
                </Typography>
                <AccountTreeIcon style={{ fontSize: 80, color: '#a0a0a0' }} />

                <Typography variant="body1" gutterBottom>
                    <Localized id='project-process-empty-list-message'>Brak procesow</Localized>
                </Typography>
            </Box>
        </>
    );
};


export const ProjectsWidget = (props: ProjectOverviewProps) => {
    const { projectGuid, projectId } = props

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const [processes, setProcesses] = useState<ProjectProcessHeader[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isError, setIsError] = useState<boolean>(false)
    const [error, setError] = useState(null);
    const [addNewDialogVisible, setAddNewDialogVisible] = useState(false);

    const activeItemsCount = processes.filter(x => x.status === 'I').length;
    const newItemGuid = useRef<string | null>(null);
    const newItemRef = useRef<HTMLLIElement | null>(null);

    useEffect(() => {
        setIsLoading(true);
        dispatch(loadForProject(projectGuid))
            .then(unwrapResult)
            .then(setProcesses)
            .catch((error) => { console.log(error); setIsError(true); setError(error); })
            .finally(() => setIsLoading(false));
    }, [])

    useEffect(() => {
        if (newItemGuid.current && newItemRef.current) {
            newItemRef.current.scrollIntoView({ behavior: 'smooth' });
            newItemGuid.current = null;
        }
    }, [processes]);


    const handleAddNewItem = useCallback(() => {
        setAddNewDialogVisible(true)
    }, [setAddNewDialogVisible])

    const handleHideAddNewDialog = useCallback(() => setAddNewDialogVisible(false), [])


    const onSuccess = useCallback((process: ProjectProcess) => {
        newItemGuid.current = process.id;
        setProcesses(produce(draft => {
            draft.push(process)
        }))
        setAddNewDialogVisible(false)
    }, [dispatch])

    const handleOpenDetails = (id: string) => {
        navigate(`/projectprocess/edit/${id}`)
    };

    const generateHref = (projectId) => {
        return `/projects/edit/${projectId}?tab=process`;
    };

    const getStatusColor = (status: string) => {
        switch (status) {
            case 'I':
                return 'green';
            case 'H':
                return 'lightblue';
            default:
                return 'grey';
        }
    };

    if (isError) {
        return <>
            <ErrorContainer error={error} />
        </>
    }

    if (isLoading) {
        return <>
            <LoadingContainer />
        </>
    }

    return (
        <>
            <Paper sx={{ flexGrow: 1, padding: 2, height: '60vh', maxHeight: '60vh', display: 'flex', flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" gutterBottom>
                        <Localized id='project-process-list'>Lista procesów</Localized>
                    </Typography>
                </Box>
                <Divider />
                {
                    processes.length === 0 ? (
                        <EmptyWidgetNotice />
                    ) : (
                        <List sx={{ flexGrow: 1, overflowY: 'auto', paddingRight: 1 }}>
                            {processes.map((process) => (
                                <ListItem
                                    key={process.id}
                                    ref={process.id === newItemGuid.current ? newItemRef : null}

                                    sx={{
                                        backgroundColor: '#f5f5f5',
                                        marginBottom: 2,
                                        '&:hover': { backgroundColor: '#e0e0e0' },
                                        borderRadius: 1,
                                    }}
                                    onDoubleClick={() => handleOpenDetails(process.id)}
                                >
                                    <ListItemText
                                        primary={process.name}
                                        primaryTypographyProps={{ fontWeight: 'bold' }}
                                        secondary={
                                            <>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <CircleIcon sx={{ color: getStatusColor(process.status), mr: 1, fontSize: 'small' }} />
                                                    <Typography component="span" variant="body2" fontWeight="bold">
                                                        <ProjectProcessStatusLabel status={process.status} />
                                                    </Typography>
                                                </Box>
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography component="span" variant="body2">
                                                        Numer: {process.no ? process.no : '{brak numeru}'}
                                                    </Typography>
                                                </Box>
                                            </>
                                        }
                                    />
                                    <Tooltip title={<Localized id='go'>Przejdz</Localized>}>
                                        <IconButton edge="end" aria-label="details"
                                            sx={{
                                                color: 'default',
                                                '&:hover': {
                                                    color: 'success.main',
                                                },
                                            }}
                                            onClick={() => handleOpenDetails(process.id)}
                                        >
                                            <ForwardIcon />
                                        </IconButton>
                                    </Tooltip>
                                </ListItem>
                            ))}
                        </List>
                    )}
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 1 }}>
                    <Box>
                        <Tooltip title={<Localized id='add'>Nowy proces</Localized>}>
                            <Button
                                color="primary"
                                size='small'
                                variant="outlined"
                                onClick={handleAddNewItem}
                                sx={{ marginRight: 1 }}
                                startIcon={<AddIcon />}>
                                <Localized id='add'>Nowy proces</Localized>
                            </Button>
                        </Tooltip>

                    </Box>
                    <Link href={generateHref(projectId)} variant="body2">
                        <Localized id='go-to-list'>Przejdź do listy</Localized>
                    </Link>
                    <Typography variant="body2">
                        <Localized id='active'>Aktywne procesy</Localized>:  {activeItemsCount} / {processes.length}
                    </Typography>
                </Box>
            </Paper>
            <Dialog open={addNewDialogVisible === true} maxWidth="md" fullWidth keepMounted={false}>
                <CreateProjectProcess
                    projectId={projectId}
                    no={""}
                    name={""}
                    onSucces={onSuccess}
                    onCancel={handleHideAddNewDialog}
                />
            </Dialog>
        </>
    );
};


export default ProjectsWidget;