import { Box, Dialog, DialogActions, DialogContent, DialogTitle, ListSubheader, MenuItem, Stack, TextField } from "@mui/material"
import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { useAppDispatch } from "../../../../app/hooks"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import { showError, showSuccess } from "../../../notifications/notificationsSlice"
import { MeetingMinuteItem, createMeetingMinuteItem } from "../meetingMinutesSlice"
import { AppTextFieldHandler } from "../../../../app/appTypes"
import { ProjectProcessHeader } from "../../projectProcess/ProjectProcessApiClient"
import { If } from "../../../../app/If"


export interface CreateMeetingMinutesItemDialogProps {
    meetingMinutesId: string
    processes: ProjectProcessHeader[] | undefined
    onSucces: (item: MeetingMinuteItem) => void
    onCancel: () => void
}

export const CreateMeetingMinutesItem = (props: CreateMeetingMinutesItemDialogProps) => {
    const dispatch = useAppDispatch()
    const [saving, setSaving] = useState(false)

    const [name, setName] = useState("")
    const [description, setDescription] = useState("")
    const [priority, setPriority] = useState("M")
    const processes = props.processes || [];
    const [selectedProcessId, setSelectedProcessId] = useState<string | undefined>(undefined)

    const handleSelectedProcessChange: (e: ChangeEvent<HTMLInputElement>) => void = 
    useMemo(() => 
        (e) => setSelectedProcessId(e.target.value), [setSelectedProcessId]);  


    const [validationErrors, setValidationErrors] = useState(false)
    const handleNameChange: AppTextFieldHandler = useCallback(e => setName(e.target.value), [setName])
    const handlePriorityChange: AppTextFieldHandler = useCallback(e => setPriority(e.target.value), [setPriority])


    const handleOk = async () => {
        setValidationErrors(true)
        setSaving(true)
        try {
            const result =
                await dispatch(createMeetingMinuteItem({
                    description: "",
                    name: name,
                    projectProcessId: selectedProcessId || "",
                    priority: priority,
                    meetingMinutesId: props.meetingMinutesId
                }))

            if (createMeetingMinuteItem.fulfilled.match(result)) {
                const proc = result.payload as MeetingMinuteItem;
                dispatch(showSuccess("saved"))
                props.onSucces(proc)
            }
            else {
                let errorMsg = "error"
                if (result.payload) {
                    if (result.payload.kind === 'http') {
                        const problem = result.payload.problem
                        if (problem) {
                            errorMsg = problem.title
                        }
                    }
                }
                dispatch(showError(errorMsg))
            }
        }
        catch (error) {
            dispatch(showError("error"))
        }
        finally {
            setSaving(false)
        }
    }

    return <Dialog open={true} fullWidth maxWidth='sm'>
        <DialogTitle><Localized id=''>Nowy Temat/Problem</Localized></DialogTitle>
        <DialogContent>
            <Stack sx={{ mt: 2 }} spacing={2}>
                <Box sx={{ overflowY: "auto" }}>
                    <TextField
                        error={name === ""}
                        inputProps={{ maxLength: 1000 }}
                        fullWidth
                        InputLabelProps={{
                            shrink: true
                        }}
                        value={name}
                        onChange={handleNameChange}
                        sx={{ marginTop: "10px" }}
                        label={<Localized id="">Temat/Problem</Localized>} 
                    />
                    <If condition={processes?.length > 0}>
                        <TextField
                            sx={{ marginTop: "10px" }}
                            fullWidth
                            select
                            InputLabelProps={{
                                shrink: true
                            }}
                            value={selectedProcessId}
                            onChange={handleSelectedProcessChange}
                            label={<Localized id="">Process</Localized>} >
                                <MenuItem value="">
                                    <span>Brak procesu</span>
                                </MenuItem>
                                <ListSubheader>Procesy</ListSubheader>

                                {processes.map((process) => <MenuItem key={process.id} value={process.id}>{process.name}</MenuItem>)}
                        
                              
                        </TextField>
                    </If>
                </Box>
            </Stack>
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOk}>
                <Localized id='save'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}><Localized id='cancel'>Anuluj</Localized></LoadingButton>
        </DialogActions>
    </Dialog>
}
