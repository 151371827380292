import { ReactNode } from "react"
import { formatDate, parseISODate } from "../../../app/Utils"
import { DocumentStatusLabel } from "../DocumentStatusLabel"
import { DocumentHeader, DocumentStatus } from "../documentsApi"
import { selectStructureNodeById } from "../../device/structureSlice"
import { store } from "../../../app/store"

interface HeaderFieldProps {
    children: ReactNode
}
const HeaderField = (props: HeaderFieldProps) => {
    return <div className="px-2 py-1 text-lg">{props.children}</div>
}
interface HeaderLabelProps {
    children: ReactNode
}
const HeaderLabel = (props: HeaderLabelProps) => {
    return <div className="text-xs font-bold px-2 py-1 bg-gray-100">{props.children}</div>
}


export const DocumentFormHeader = ({ doc }: { doc: DocumentHeader }) => {
    const { no, status, createdByFullName, createDate, companyName, structureId } = doc

    const structure = selectStructureNodeById(store.getState(), structureId)

    const getColor = (status) => {
        switch (status) {
            case 'N':
                return 'bg-green-200';
            default:
                return 'bg-red-200';
        }
    };

    return <>
        <div className="grid grid-cols-6 border-solid border rounded border-gray-300 shadow">
            <HeaderLabel>Dokument nr</HeaderLabel>
            <HeaderLabel>Struktura</HeaderLabel>
            <HeaderLabel>Firma</HeaderLabel>
            <HeaderLabel>Utworzono</HeaderLabel>
            <HeaderLabel>Utworzył</HeaderLabel>
            <HeaderLabel>Status</HeaderLabel>
            <HeaderField>{no}</HeaderField>
            <HeaderField>{structure?.name}</HeaderField>
            <HeaderField>{companyName}</HeaderField>
            <HeaderField>{formatDate(parseISODate(createDate))}</HeaderField>
            <HeaderField>{createdByFullName}</HeaderField>
            <HeaderField>
                <span
                    className={`inline-block px-2 py-1 rounded bg-red-100 ${getColor(status)}`}
                >
                    <DocumentStatusLabel status={status as DocumentStatus} />
                </span>
            </HeaderField>
        </div>
    </>
}