import { Localized } from "@fluent/react"
import { LoadingButton } from "@mui/lab"
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from "@mui/material"
import { unwrapResult } from "@reduxjs/toolkit"
import { useState } from "react"
import { AppId } from "../../../app/appTypes"
import { ProjectProcessHeader, updateProjectProcessName } from "./ProjectProcessApiClient"
import { useAppDispatch } from "../../../app/hooks"


export interface EditSimpleDescriptionFieldProps {
    processId: AppId
    field: string
    value: string
    onSave : (project: ProjectProcessHeader) => void
    onCancel : () => void
}

const Label = ({ fieldName }: { fieldName: string }) => {
    switch (fieldName) {
        case "no": return <Localized id='process-no'>Numer procesu</Localized>
        case "name": return <Localized id="process-name">Nazwa procesu</Localized>
        case "description": return <Localized id="process-description">Opis</Localized>
        case "generalContractor": return <Localized id='project-general-contractor'>Generalny wykonawca</Localized>
        case "investor": return <Localized id='project-investor'>Inwestor</Localized>
        default: return <>{fieldName}</>
    }
}

export const EditSimpleDescriptionField = (props: EditSimpleDescriptionFieldProps) => {
    const dispatch = useAppDispatch()
    const { processId, field, value } = props

    const [newValue, setNewValue] = useState(value)
    const [saving, setSaving] = useState(false)

    const handleOkClick = () => {
        setSaving(true)
        dispatch(updateProjectProcessName({
            id: processId,
            data: {
                fieldName: field,
                text: newValue,
            },
        }))
            .then(unwrapResult)
            .then(project => props.onSave(project))
            .catch(err => console.error(err))
            .finally(() => setSaving(false))
    }

    return <Dialog open={true} fullWidth maxWidth='md'>
        <DialogTitle>
            <Localized id='edit'>Edycja</Localized>
        </DialogTitle>
        <DialogContent>
            <TextField
                sx={{ mt: 1 }}
                value={newValue}
                onChange={e => setNewValue(e.target.value)}
                fullWidth
                label={<Label fieldName={field} />}
            />
        </DialogContent>
        <DialogActions>
            <LoadingButton loading={saving} onClick={handleOkClick}>
                <Localized id='ok'>OK</Localized>
            </LoadingButton>
            <LoadingButton loading={saving} onClick={props.onCancel}>
                <Localized id='cancel'>Anuluj</Localized>
            </LoadingButton>
        </DialogActions>
    </Dialog>
}
