import { RequirementNote, } from "./ursOfferSlice";
import { Check, Clear } from "@mui/icons-material";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';  
import { Localized } from "@fluent/react";

export const NoteLabel = ({ note }: { note: RequirementNote | null }) => {
    if (note === null) {
        return null
    }

    switch (note) {
        case "F":
            return             <>
                <Check fontSize="small" color='success' />
                
            </>
                 
        case "U":
            return             <>
            <Clear fontSize="small" color='error' />
            
            </>
        case "D":
            return             <>
            <QuestionMarkIcon fontSize="small" color='warning' />
           
            </>       
        default:
            return <span>{note}</span>
    }
}

export const NoteLabelWithText = ({ note }: { note: RequirementNote }) => {
    if (note === null) {
        return null
    }

    switch (note) {
        case "F":
            return <div className="flex flex-row gap-4">
                <Localized id='urs-offer-req-f'>Spełnia</Localized>
                <Check fontSize="small" color='success' />
            </div>
                 
        case "U":
            return <div className="flex flex-row gap-4">
                <Localized id='urs-offer-req-u'>Nie spełnia</Localized>
                <Clear fontSize="small" color='error' />
            </div>
        case "D":
            return <div className="flex flex-row gap-4">
                <Localized id='urs-offer-req-d'>Do oceny</Localized>
                <QuestionMarkIcon fontSize="small" color='warning' />
            </div>
        default:
            return <span>{note}</span>
    }
}
