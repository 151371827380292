import { createAsyncThunk, createEntityAdapter, createSlice, EntityState } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes";
import { deleteWithAuth, getWithAuth, postWithAuth, putWithAuth } from "../../http";
import { isSameRequestId } from "../../app/crud";

export interface AiQuestion {
    id: AppId,
    code: string,
    companyId: number | null,
    question: string,
    role: string,
    maxTokens: number,
    temperature: number,
    topP: number
}

export interface ExtendedAiQuestion extends AiQuestion {
    originalId: AppId;
}

export interface AIAccess {
    id: AppId;
    companyId: number;
    companyName: string;
    limit: number;
    status: string;
}

interface AIAccessPayload {
    companyId: number;
}

interface UpdateAIAccessPayload {
    id: AppId;
    args: { fieldName: string, text: string }
}

export interface Statistics {
    id: string;
    label: string;
    value: number
}

export interface Action {
    id: AppId,
    actionId: string,
    value: string,
    type: string
}

const adapter = createEntityAdapter<AiQuestion>({
    selectId: (aiQuestions) => aiQuestions.id,
})

export type DraftAiQuestion = Omit<AiQuestion, "id">
export type AiQuestionsState = EntityState<AiQuestion> & { state: AppDataState }

const initialState: AiQuestionsState = adapter.getInitialState({ state: { type: "empty" } })

export const loadAiQuestions = createAsyncThunk<AiQuestion[], void, AppThunkAPIType>("aiQuestions/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/ai/questions"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as AiQuestion[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const loadAIAccess = createAsyncThunk<AIAccess[], void, AppThunkAPIType>("aiAccess/load", async (_, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({ url: "api/ai/access"}))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as AIAccess[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})

export const createClientQuestion = createAsyncThunk<ExtendedAiQuestion, AiQuestion, AppThunkAPIType>(
    "aiQuestions/create", 
    async (aiQuestion: AiQuestion, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ 
            url: `api/ai/questions`,
            payload: aiQuestion,
        }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            payload.originalId = aiQuestion.id;
            return payload as ExtendedAiQuestion
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const updateAiQuestions = createAsyncThunk<AiQuestion, AiQuestion, AppThunkAPIType>(
    "aiQuestions/update", 
    async (aiQuestion: AiQuestion, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ 
            url: `api/ai/questions/${aiQuestion.id}`,
            payload: aiQuestion,
        }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return aiQuestion as AiQuestion
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteClientQuestion = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "aiQuestions/delete",
    async (id: AppId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/ai/questions/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as AppId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const createAIAccess = createAsyncThunk<AIAccess, AIAccessPayload, AppThunkAPIType>(
    "aiAccess/create",
    async (aiAccessPayload: AIAccessPayload, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({ url: "api/ai/access", payload: aiAccessPayload }))
        const { payload } = result
        if (postWithAuth.fulfilled.match(result)) {
            return payload as AIAccess
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const updateAIAccess = createAsyncThunk<AIAccess, UpdateAIAccessPayload, AppThunkAPIType>(
    "aiAccess/update",
    async (aiAccessPayload: UpdateAIAccessPayload, { dispatch, rejectWithValue }) => {
        const result = await dispatch(putWithAuth({ url: `api/ai/access/${aiAccessPayload.id}`, payload: aiAccessPayload.args ?? {} }))
        const { payload } = result
        if (putWithAuth.fulfilled.match(result)) {
            return payload as AIAccess
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})

export const deleteAIAccess = createAsyncThunk<AppId, AppId, AppThunkAPIType>(
    "aiAccess/delete",
    async (id: AppId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({ url: `api/ai/access/${id}` }))
        const { payload } = result
        if (deleteWithAuth.fulfilled.match(result)) {
            return payload as AppId
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
})


export const aiQuestionsSlice = createSlice({
    name: "aiQuestions",
    initialState,
    reducers: {},
    extraReducers: (builder) => {

        builder.addCase(updateAiQuestions.fulfilled, (state, action) => {
            const { id } = action.payload;
            const changes = action.payload as DraftAiQuestion;
            state = adapter.updateOne(state, {
                id,
                changes,
            });
        })

        builder.addCase(loadAiQuestions.pending, (state, action) => {
            if (state.state.type === "empty") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })

        builder.addCase(loadAiQuestions.fulfilled, (state, action) => {
            if(isSameRequestId(state.state, action.meta.requestId)) {
                adapter.setAll(state, action.payload)
                state.state = { type: "loaded" }
            }
        });

        builder.addCase(loadAiQuestions.rejected, (state, action) => {
            if(isSameRequestId(state.state, action.meta.requestId)) {
                state.state = { 
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })

        builder.addCase(createClientQuestion.fulfilled, (state, action) => {
            const { originalId, ...data } = action.payload;
            state = adapter.removeOne(state, originalId);
            state = adapter.addOne(state, data)
        });

        builder.addCase(deleteClientQuestion.fulfilled, (state, action) => {
            state.state = {
                type: "empty"
            }
        })
    },
})

export const selectAiQuestions = (state: RootState): AiQuestionsState => state.aiquestions

export const 
    { selectAll: selectAllAiQuestions
    , selectById: selectAiQuestionById 
    } = adapter.getSelectors<RootState>(selectAiQuestions)

export default aiQuestionsSlice.reducer
