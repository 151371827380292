import { createAsyncThunk, createEntityAdapter, createSlice, EntityState, PayloadAction } from "@reduxjs/toolkit"
import { AppDataState, AppId, AppThunkAPIType, unknownError } from "../../app/appTypes"
import { isSameRequestId } from "../../app/crud"
import { RootState } from "../../app/store"
import { deleteWithAuth, getWithAuth, postWithAuth } from "../../http"
import { logout, selectUserRole } from "../user/userSlice"

export type ModuleCode 
    = "admin" 
    | "validation" 
    | "urs"
    | "measure"
    | "offering"
    | "project"
    | "super"

export type AnyModule = "*"

export interface Module {
    sysModuleId: AppId
    moduleId: AppId
    code: ModuleCode
    name: string
    description: string
}

export interface ModuleActive { 
    moduleId: AppId
    code: ModuleCode
    name: string
    description: string
    active: boolean
}

export const activateModule = 
    createAsyncThunk<void, { companyId: AppId, moduleId: AppId }, AppThunkAPIType>("module/activate", async ({ moduleId, companyId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(postWithAuth({
            url: `api/sys/modules/activate/${companyId}/${moduleId}`,
            payload: {},
        }))
        if (!postWithAuth.fulfilled.match(result)) {
            const { payload } = result
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const deactivateModule = 
    createAsyncThunk<void, { companyId: AppId, moduleId: AppId }, AppThunkAPIType>("module/deactivate", async ({ moduleId, companyId }, { dispatch, rejectWithValue }) => {
        const result = await dispatch(deleteWithAuth({
            url: `api/sys/modules/disable/${companyId}/${moduleId}`,
        }))
        if (!deleteWithAuth.fulfilled.match(result)) {
            const { payload } = result
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadAllCompanyModules =
    createAsyncThunk<ModuleActive[], AppId, AppThunkAPIType>("company_modules/load", async (companyId, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/modules/company/${companyId}`
        }))
        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as ModuleActive[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadUserModules =
    createAsyncThunk<Module[], AppId, AppThunkAPIType>("user_modules/load", async (companyId, { dispatch, rejectWithValue, getState }) => {
        const result = await dispatch(getWithAuth({
            url: `api/user-access/modules`,
        }))

        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            if (selectUserRole(getState()) === "super") {
                return [...payload as Module[], {
                    sysModuleId: "",
                    moduleId: "",
                    code: "super",
                    name: "Super",
                    description: "Super",
                }]
            }
            return payload as Module[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

export const loadAllModules =
    createAsyncThunk<Module[], void, AppThunkAPIType>("modules/load", async (_, { dispatch, rejectWithValue }) => {
        const result = await dispatch(getWithAuth({
            url: `api/sys/modules`,
        }))

        const { payload } = result
        if (getWithAuth.fulfilled.match(result)) {
            return payload as Module[]
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    })

const moduleAdapter = createEntityAdapter<Module>({
    selectId: m => m.code,
})

export type ModuleState = EntityState<Module> & { state: AppDataState } & { currentModule: Module | null }

const initialState: ModuleState = moduleAdapter.getInitialState({
    state: { type: "empty" },
    currentModule: null,
})

export const moduleSlice = createSlice({
    name: "module",
    initialState,
    reducers: {
        setCurrentModule: (state, action: PayloadAction<Module>) => {
            if (state.state.type === "loaded" && state.entities[action.payload.code] !== undefined) {
                state.currentModule = action.payload
            }
        },
    },
    extraReducers: builder => {
        builder.addCase(loadUserModules.pending, (state, action) => {
            if (state.state.type !== "loading") {
                state.state = {
                    type: "loading",
                    requestId: action.meta.requestId,
                }
            }
        })
        builder.addCase(loadUserModules.fulfilled, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                const modules = action.payload
                moduleAdapter.setAll(state, modules)
                state.state = { type: "loaded" }
                if (modules.length === 1) {
                    state.currentModule = modules[0]
                }
            }
        })
        builder.addCase(loadUserModules.rejected, (state, action) => {
            if (isSameRequestId(state.state, action.meta.requestId)) {
                state.state = {
                    type: "error",
                    error: action.payload ?? unknownError(),
                }
            }
        })
        builder.addCase(logout.fulfilled, (state, action) => {
            state.currentModule = null
            moduleAdapter.removeAll(state)
            state.state = { type: "empty" }
        })
    },
})

export const selectModule = (state: RootState): ModuleState => state.module

export const {
    selectAll: selectAllModules,
    selectById: selectModuleByCode,
} = moduleAdapter.getSelectors(selectModule)

export const { setCurrentModule } = moduleSlice.actions

export const isSuper = (state: RootState): boolean => state.module.currentModule?.code === 'super'

export default moduleSlice.reducer
