import { createAsyncThunk } from "@reduxjs/toolkit"
import { getWithAuth, postWithAuth } from "../../../http"
import { AppThunkAPIType } from "../../../app/appTypes"


export interface Notes {
    id: string,
    title: string,
    content: string,
    parentObjectGuid: string,
    parentObjectType: string,
    createdByFullName: string,
    createDate: Date,
    modifiedByFullName: string,
    modifyDate: string,
    history: NoteHistory[]
}


export interface NoteHistory {
    id: string,
    parentNoteId: string,
    title: string,
    content: string,
    createdByFullName: string,
    createDate: string,
}


export interface CreateNotePayload {
    title: string,
    content: string,
    parentObjectGuid: string
}

export const loadAllObjectNotes = createAsyncThunk<Notes[], string, AppThunkAPIType>("notes/loadForObject", async (id, { dispatch, rejectWithValue }) => {
    const result = await dispatch(getWithAuth({
        url: `api/Notes/load-for-object/${id}/`,
    }))
    const { payload } = result
    if (getWithAuth.fulfilled.match(result)) {
        return payload as Notes[]
    } else {
        return rejectWithValue(payload ?? { kind: 'unknown' })
    }
})


export const createNote = createAsyncThunk<Notes, CreateNotePayload, AppThunkAPIType>(
    "notes/create",
    async (createPayload: CreateNotePayload, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: "api/Notes",
            payload: createPayload,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Notes
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)


export const updateNote = createAsyncThunk<Notes, Notes, AppThunkAPIType>(
    "notes/update",
    async (note: Notes, { rejectWithValue, dispatch }) => {
        const result = await dispatch(postWithAuth({
            url: `api/Notes/${note.id}`,
            payload: note,
        }))
        const { payload } = result

        if (postWithAuth.fulfilled.match(result)) {
            return payload as Notes
        } else {
            return rejectWithValue(payload ?? { kind: 'unknown' })
        }
    }
)
